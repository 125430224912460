import React, { useState, useEffect } from 'react'
import './teacherMember.css'
import { Box, Container, Avatar, Button } from '@mui/material'
import CreateIcon from '@mui/icons-material/Create';
import { useLazyGetMemberDetailsByIdQuery } from '../../../app/services/memberServices';
import { useParams } from 'react-router-dom';
import { useLazyGetMemberShipDetailsByIdQuery } from '../../../app/services/memberShipServices'
import { useLazyGetChapterDetailsByIdQuery } from '../../../app/services/chapterServices'
import { useLazyGetSIGGroupByIdQuery } from '../../../app/services/SIGGroupServices'
import { useUpdateMemberMutation, useLazyGetPendingMemberByIdQuery, } from '../../../app/services/memberListServices'
import { useNavigate } from "react-router-dom";
import { selectUserId } from "../../../app/auth/authSlice";
import { useSelector } from "react-redux";
import { Salutation, CurrentlyTeaching, NatureOfWork, PrimarilyTeaching } from '../../../constant/constant'
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';



const TeacherMember = () => {
    const navigate = useNavigate();
    const userId = useSelector(selectUserId);
    const [updateMember] = useUpdateMemberMutation()
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [getMemberById] = useLazyGetMemberDetailsByIdQuery()
    const { id } = useParams();
    const [memberDetails, setMemberDetails] = useState()
    const [membershipbyId] = useLazyGetMemberShipDetailsByIdQuery()
    const [PriceDetails, setPriceDetails] = useState([])
    const [pricetype, setpriceType] = useState()
    const [chapter, setChapter] = useState()
    const [getChapterById] = useLazyGetChapterDetailsByIdQuery()
    const [getSIGById] = useLazyGetSIGGroupByIdQuery();
    const [imageUpdate, setImageUpdate] = useState()
    const [selectedImage, setSelectedImage] = useState(null);
    const [imageKey, setImageKey] = useState(0);
    const [showUploadButton, setShowUploadButton] = useState(false);
    const [getPendingMemberById] = useLazyGetPendingMemberByIdQuery()
    const dispatch = useDispatch();


    const [sig, setSig] = useState()

    useEffect(() => {
        getMemberById(id).then((res) => {
            if (res?.data.status) {
                setMemberDetails(res?.data.data)
                setPriceDetails(res?.data?.data?.plans)
            }
        }).catch((err) => console.error(err))
    }, [getMemberById, id])

    useEffect(() => {
        if (PriceDetails[0]?.planId) {
            membershipbyId(PriceDetails[0]?.planId).then((res) => {
                setpriceType(res?.data?.data);
            });
        }
        getChapterById(memberDetails?.chapter).then((res) => {
            if (res?.data?.status) {
                setChapter(res?.data?.data?.title)
            }
        }).catch(err => console.log(err))
        if (memberDetails?.sigGroups) {
            let groupPromises = memberDetails.sigGroups.map((id) =>
                getSIGById(id).then((res) => res?.data?.data?.groupName)
            );

            Promise.all(groupPromises).then((groupNames) => {
                setSig(groupNames);
            });
        }

    }, [memberDetails, PriceDetails, getSIGById, getChapterById, membershipbyId]);

    useEffect(() => {
        if (pricetype && chapter && sig) {
        }
    }, [pricetype, sig, chapter]);

    const formatDate = (isoDate) => {
        const date = new Date(isoDate);

        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const year = date.getFullYear();

        return `${day}-${month}-${year}`;
    };
    const calculateAge = (isoDate) => {
        const birthDate = new Date(isoDate);
        const today = new Date();

        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDiff = today.getMonth() - birthDate.getMonth();

        // If current month is before birth month or it's the birth month but current day is before birth day, subtract one year
        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    };
    // Update width on window resize
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        // Clean up the event listener
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const getPrimarilyTeaching = (id) => {
        const primary = PrimarilyTeaching.find(data => data.id === id)
        return primary ? primary?.label : "-"
    }

    const handleFormNavigation = (key) => {
        navigate(`/member/teachersProfile/${key}/${userId}`);
    };

    const getSalutation = (id) => {
        const salutation = Salutation.find(data => data?.id === id)
        return salutation ? salutation?.Name : "-"
    }
    const getCurrentlyTeaching = (id) => {
        const salutation = CurrentlyTeaching.find(data => data?.id === id)
        return salutation ? salutation?.name : "-"
    }
    const getNatureOfWork = (id) => {
        const salutation = NatureOfWork.find(data => data?.id === id)
        return salutation ? salutation?.name : "-"
    }

    const handleProfilePic = (event) => {
        const file = event.target.files[0];
        if (!file) {
            return;
        }
        setImageUpdate(file);  // Set the file in state
        const reader = new FileReader();
        reader.onloadend = () => {
            setSelectedImage(reader.result);  // Update the image preview
            setImageKey(prevKey => prevKey + 1);  // Re-render the image with a new key
            setShowUploadButton(true);  // Show the upload button after file selection
        };
        reader.readAsDataURL(file);  // Read the file for preview
    };


    const handleUpload = () => {
        const formData = new FormData();
        formData.append("imageUrl", imageUpdate);
        formData.append("_id", memberDetails._id);

        updateMember({ id: memberDetails._id, data: formData })
            .then((res) => {
                console.log(res);
                  if(res?.data?.status){
                    setShowUploadButton(false)
                    toast.success(res?.data?.message);
                  }else{
                    setShowUploadButton(false)
                    toast.error(res?.data?.message)
                  }
                if (res.data.status) {
                    return getPendingMemberById(memberDetails?._id);
                } else {
                    throw new Error("Image upload failed");
                }
            })
            .then((memberRes) => {
                dispatch(setMemberDetails(memberRes?.data?.data));
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const handleIconClick = () => {
        document.getElementById("uploadImage").click();
    };
    return (
        <div className='ourExecutiveCommittee-containter'>
            <Box>
                <div className='pt-1 '>
                    <div className='p-0'>
                        <div className=' Architects-header d-flex align-items-center justify-content-center '>
                            <h2 className='signika-fontOEC text-center '>Member Profile (Individual  Teacher)</h2>
                        </div>
                    </div>
                </div>
            </Box>
            <Container maxWidth="lg" className='pb-5'>
                <p className='signika-font fw-semibold teacher-member-header mt-5 mb-0'>BASIC DETAILS:</p>
                <div className='card teacher-member-card'>
                    <div className='row'>
                        <div className='col-md-2 m-3 d-flex justify-content-lg-start justify-content-center'>
                            <div className='teacher-avatar-containter'>
                                {!showUploadButton && (
                                    <div className='techer-member-image-editIcon' onClick={handleIconClick}>
                                        <CreateIcon sx={{ fontSize: '10px' }} />
                                    </div>
                                )}

                                <input
                                    accept="image/jpeg, image/png"
                                    style={{ display: "none" }}
                                    id="uploadImage"
                                    type="file"
                                    onChange={handleProfilePic}  // Handle file selection
                                />

                                {selectedImage ? (
                                    <Avatar
                                        key={imageKey}
                                        alt="Member Logo"
                                        className="member-avatar"
                                        src={selectedImage}
                                        sx={{ width: 100, height: 100 }}
                                        onClick={handleIconClick}
                                    />
                                ) : (
                                    <Avatar
                                        key={imageKey}
                                        alt="Member Logo"
                                        className="member-avatar"
                                        onClick={handleIconClick}
                                        src={memberDetails?.imageUrl}
                                        sx={{ width: 100, height: 100 }}
                                    />
                                )}

                                {showUploadButton && (
                                    <Button
                                        variant="contained"
                                        style={{ marginTop: "20px" }}
                                        onClick={handleUpload}
                                          className='menus-color'
                                    >
                                        Upload
                                    </Button>
                                )}
                            </div>

                        </div>
                        <div className='col-md-8  mt-3 text-center text-md-start'>
                            <p className='signika-font fw-semibold mt-4'>{getSalutation(memberDetails?.salutation)}. {memberDetails?.fullName}</p>
                            <p className='signika-font'>{memberDetails?.userId}</p>
                            <p className='signika-font'>{formatDate(memberDetails?.dateOfBirth)}</p>
                            <p className='signika-font'>{calculateAge(memberDetails?.dateOfBirth)} Year  - {(memberDetails?.gender === 0) ? "Male" : "Female"}</p>
                        </div>
                    </div>
                </div>
                <p className='signika-font fw-semibold mt-5 mb-0'>CONTACT DETAILS:</p>
                <div className='card teacher-member-card '>
                    <div className='row'>
                        <div className='d-flex justify-content-end'>
                            <Button variant='outlined' className='m-2 edit-button-profile-membership' onClick={() => handleFormNavigation("contact")}>Edit  <CreateIcon sx={{ fontSize: '10px' }} /></Button>
                        </div>
                        <Container maxWidth="lg">
                            <div className='row p-3'>
                                <div className='col-lg-5'>
                                    <div className='row'>
                                        <div className='col-4'>
                                            <p className='signika-font fw-semibold'>Address</p>
                                            <p className='signika-font fw-semibold mt-4'>City / Town</p>
                                            <p className='signika-font fw-semibold mt-4'>Pin Code</p>
                                            <p className='signika-font fw-semibold mt-4'>State</p>
                                            <p className='signika-font fw-semibold mt-4'>Country</p>
                                        </div>
                                        <div className='col-8'>
                                            <p className='signika-font'><span className='me-3' style={{ whiteSpace: "nowrap" }}>:</span>{memberDetails?.address}</p>
                                            <p className='signika-font mt-4'><span className='me-3'>:</span>{memberDetails?.city}</p>
                                            <p className='signika-font mt-4'><span className='me-3'>:</span>{memberDetails?.pinCode}</p>
                                            <p className='signika-font mt-4'><span className='me-3'>:</span>{memberDetails?.state}</p>
                                            <p className='signika-font mt-4'><span className='me-3'>:</span>{memberDetails?.country}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-7'>
                                    <div className='row'>
                                        <div className='col-4'>
                                            <p className='signika-font fw-semibold'>
                                                {windowWidth > 513 ? (
                                                    <p>Mobile Number</p>
                                                ) : (
                                                    <p>Mobile</p>
                                                )}
                                            </p>
                                            <p className='signika-font fw-semibold mt-4 '>
                                                {windowWidth > 513 ? (
                                                    <p>WhatsApp Number</p>
                                                ) : (
                                                    <p>whatsApp</p>
                                                )}
                                            </p>
                                            <p className='signika-font fw-semibold mt-4'>Email Id</p>
                                            <p className='signika-font fw-semibold mt-4'>
                                                {windowWidth > 513 ? (
                                                    <p>Telephone Number</p>
                                                ) : (
                                                    <p>Telephone</p>
                                                )}
                                            </p>
                                            <p className='signika-font fw-semibold mt-4'>Contact Information Visibility</p>
                                        </div>
                                        <div className='col-8'>
                                            <p className='signika-font'><span className='me-3'>:</span>{memberDetails?.mobileNumber}</p>
                                            <p className='signika-font mt-4'><span className='me-3'>:</span>{memberDetails?.whatsAppNumber}</p>
                                            <p className='signika-font mt-4'><span className='me-3'>:</span>{memberDetails?.email}</p>
                                            <p className='signika-font mt-4'><span className='me-3'>:</span>{memberDetails?.landlineNumber ? memberDetails?.landlineNumber : '-'}</p>
                                            <p className='signika-font mt-4'><span className='me-3'>:</span>
                                                Which contact information would
                                                you like to share with other
                                                members? (Please select all that
                                                apply)
                                            </p>
                                            <p className='signika-font  mt-4'>
                                                <div className='row'>

                                                    {memberDetails ? (memberDetails?.contactInformation && memberDetails.contactInformation.map((data, i) => (
                                                        <div key={i} className='col-md-6 col-sm-12'>
                                                            <ul className='signika-font'>
                                                                <li>{data}</li>
                                                            </ul>
                                                        </div>
                                                    ))) : "-"
                                                    }
                                                </div>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Container>
                    </div>
                </div>
                <p className='signika-font fw-semibold mt-5 mb-0'>YOUR ACADEMIC PROFILE LINKS:</p>
                <div className='card teacher-member-card  '>
                    <div className='row'>
                        <div className='d-flex justify-content-end'>
                            <Button variant='outlined' className='m-2 edit-button-profile-membership' onClick={() => handleFormNavigation("academic")}>Edit  <CreateIcon sx={{ fontSize: '10px' }} /></Button>
                        </div>
                        <Container maxWidth="lg">
                            <div className='row p-3'>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <p className='signika-font fw-semibold'>Your Google Scholar Profile</p>
                                    </div>
                                    <div className='col-sm-8'>
                                        <p className='signika-font'><span className='me-3'>:</span>{memberDetails?.scholarProfile ? memberDetails?.scholarProfile : "-"}</p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <p className='signika-font fw-semibold'>Your Scopus Profile link</p>
                                    </div>
                                    <div className='col-sm-8'>
                                        <p className='signika-font'><span className='me-3'>:</span>{memberDetails?.scopusProfileLink ? memberDetails?.scopusProfileLink : "-"}</p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <p className='signika-font fw-semibold'>Your LinkedIn Profile</p>
                                    </div>
                                    <div className='col-sm-8'>
                                        <p className='signika-font'><span className='me-3'>:</span>{memberDetails?.linkedInProfile ? memberDetails?.linkedInProfile : "-"}</p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <p className='signika-font fw-semibold'>Your ORICID id</p>
                                    </div>
                                    <div className='col-sm-8'>
                                        <p className='signika-font'><span className='me-3'>:</span>{memberDetails?.ORICID ? memberDetails.ORICID : "-"}</p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <p className='signika-font fw-semibold'>Your blog / website</p>
                                    </div>
                                    <div className='col-sm-8'>
                                        <p className='signika-font'><span className='me-3'>:</span>{memberDetails?.blog ? memberDetails?.blog : "-"}</p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <p className='signika-font fw-semibold'>Your Youtube channel</p>
                                    </div>
                                    <div className='col-sm-8'>
                                        <p className='signika-font'><span className='me-3'>:</span>{memberDetails?.youtubeChannel ? memberDetails?.youtubeChannel : "-"}</p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <p className='signika-font fw-semibold'>Consent to Share Information</p>
                                    </div>
                                    <div className='col-sm-8'>
                                        <p className='signika-font'><span className='me-3'>:</span>
                                            Do you
                                            consent to having your academic profile
                                            shared with other ELTAI members so
                                            they can search, find, and contact you for
                                            delivering talks, writing collaborative
                                            research papers, and other professional
                                            opportunities?
                                        </p>
                                        <ulspan>
                                        <li>{memberDetails?.shareInformation !== null ? (memberDetails?.shareInformation === 1 ? "Yes" : "No") : "-"}</li>
                                        </ulspan>
                                    </div>
                                </div>
                            </div>
                        </Container>
                    </div>
                </div>
                <p className='signika-font fw-semibold teacher-member-header  mt-5 mb-0'>Membership Details:</p>
                <div className='card teacher-member-card '>
                    <div className='row p-3'>
                        <Container maxWidth="lg">
                            <div className='row p-3'>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <p className='signika-font fw-semibold'>Date of first becoming a member</p>
                                    </div>
                                    <div className='col-sm-8'>
                                        <p className='signika-font'><span className='me-3'>:</span>{formatDate(PriceDetails[0]?.dummyStartDate)}</p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <p className='signika-font fw-semibold'>Type of Current Membership</p>
                                    </div>
                                    <div className='col-sm-8'>
                                        <p className='signika-font'><span className='me-3'>:</span>{pricetype?.planName}</p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <p className='signika-font fw-semibold'>Current Membership Valid from</p>
                                    </div>
                                    <div className='col-sm-4'>
                                        <p className='signika-font'><span className='me-3'>:</span>{formatDate(PriceDetails[0]?.dummyStartDate)}</p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <p className='signika-font fw-semibold'>Current Membership Valid till</p>
                                    </div>
                                    <div className='col-sm-8'>
                                        <p className='signika-font'><span className='me-3'>:</span>{formatDate(PriceDetails[0]?.dummyEndDate)}</p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <p className='signika-font fw-semibold'>Payment Detail</p>
                                    </div>
                                    <div className='col-sm-8 m-0'>
                                        <p className='signika-font mb-0'><span className='me-3'>:</span>Paid
                                            <uispan className='me-3'>
                                                <li>{PriceDetails[0]?.dummyAmount ? "Online Payment" : "Cash"}</li>
                                                <li>{formatDate(PriceDetails[0]?.dummyStartDate)}</li>
                                                <li>{PriceDetails[0]?.dummyCurrency === 1 ? "$" : "₹"}{PriceDetails[0]?.dummyAmount}</li>
                                            </uispan>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Container>
                    </div>
                </div>
                <p className='signika-font fw-semibold teacher-member-header mt-5 mb-0'>Your Network Details:</p>
                <div className='card teacher-member-card '>
                    <div className='row'>
                        {/* <div className='d-flex justify-content-end'>
                            <Button variant='outlined' className='m-2'>Edit  <CreateIcon sx={{ fontSize: '10px' }} /></Button>
                        </div> */}
                        <Container maxWidth="lg">
                            <div className='row p-3'>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <p className='signika-font fw-semibold mb-0'>Which chapter of ELTAI would
                                            you like to be part of?</p>
                                        <a style={{ color: 'darkgreen' }} target="_blank" rel="noopener noreferrer" href="/member/view-Chapter">Click here for Chapter</a>
                                    </div>
                                    <div className='col-sm-8'>
                                        <p className='signika-font d-flex'><span className='me-3'>:</span>
                                            {chapter ? chapter : '-'}
                                        </p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <p className='signika-font fw-semibold mb-0'>Which SIGs of ELTAI would
                                            you like to join?</p>
                                        <a style={{ color: 'darkgreen' }} target="_blank" rel="noopener noreferrer" href="/member/addEditSigGroups">Click here for SIG</a>
                                    </div>
                                    <div className='col-sm-8'>
                                        <p className='signika-font d-flex'><span className='me-3'>:</span>
                                            <ulspanspan>
                                                {sig && sig.length > 0 ? (sig && sig?.map((data, i) => {
                                                    return (<div key={i}>
                                                        <li >{data}</li>
                                                    </div>)
                                                })) : '-'}
                                            </ulspanspan>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Container>
                    </div>
                </div>
                <p className='signika-font fw-semibold mt-5 mb-0 teacher-member-header'>Professional Details:</p>
                <div className='card teacher-member-card '>
                    <div className='row'>
                        <div className='d-flex justify-content-end'>
                            <Button variant='outlined' className='m-2 edit-button-profile-membership' onClick={() => handleFormNavigation("profession")}>Edit  <CreateIcon sx={{ fontSize: '10px' }} /></Button>
                        </div>
                        <Container maxWidth="lg">
                            <div className='row p-3'>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <p className='signika-font fw-semibold'>Your Educational Qualification</p>
                                    </div>
                                    <div className='col-sm-8'>
                                        <p className='signika-font mb-1'><span className='me-3'>:</span>{memberDetails?.educationalQualification?.length > 0 ? <>(Select all that apply)</> : <>(Select all that apply) </>}
                                            <div className='row'>
                                                {memberDetails?.educationalQualification?.length > 0 ? (
                                                    memberDetails.educationalQualification.map((data, i) => (
                                                        <div className='col-md-2 col-sm-12' key={i}>
                                                            <ul className='signika-font'>
                                                                <li>{data}</li>
                                                            </ul>
                                                        </div>
                                                    ))
                                                ) : (
                                                    <div className='col-md-2 col-sm-12'>
                                                        {/* <div className='ml-5'>-</div> */}
                                                    </div>
                                                )}
                                            </div>
                                        </p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <p className='signika-font fw-semibold'>Have you got through any
                                            teacher eligibility examinations?</p>
                                    </div>
                                    <div className='col-sm-8'>
                                        <p className='signika-font'><span className='me-3'>:</span>{memberDetails?.eligibilityExamination?.length > 0 ? <>(Select all that apply) </> : <>(Select all that apply) </>}
                                            <div className='row'>
                                                {memberDetails?.eligibilityExamination?.length > 0 ? (
                                                    memberDetails.eligibilityExamination.map((data, i) => (
                                                        <div className='col-md-2 col-sm-12' key={i}>
                                                            <ul className='signika-font'>
                                                                <li>{data}</li>
                                                            </ul>
                                                        </div>
                                                    ))
                                                ) : (
                                                    <></>
                                                )}
                                            </div>
                                        </p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <p className='signika-font fw-semibold'>Teaching Experience</p>
                                    </div>
                                    <div className='col-sm-4'>
                                        <p className='signika-font'><span className='me-3'>:</span>(IN Years) {memberDetails?.teacherExperience}</p>

                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <p className='signika-font fw-semibold'>Are you currently teaching?</p>
                                    </div>
                                    <div className='col-sm-8'>
                                        <p className='signika-font'><span className='me-3'>:</span>
                                            {getCurrentlyTeaching(memberDetails?.teaching)}
                                        </p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <p className='signika-font fw-semibold'>Your Current Designation</p>
                                    </div>
                                    <div className='col-sm-8'>
                                        <p className='signika-font'><span className='me-3'>:</span>{memberDetails?.currentDesignation ? memberDetails?.currentDesignation : "-"}</p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <p className='signika-font fw-semibold'>Name of your Institution</p>
                                    </div>
                                    <div className='col-sm-8'>
                                        <p className='signika-font'><span className='me-3'>:</span>{memberDetails?.institution ? memberDetails?.institution : "-"}</p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <p className='signika-font fw-semibold'>Your Last Designation</p>
                                    </div>
                                    <div className='col-sm-8'>
                                        <p className='signika-font'><span className='me-3'>:</span>{memberDetails?.lastDesignation ? memberDetails?.lastDesignation : '-'}</p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <p className='signika-font fw-semibold'>Name of the Institution where
                                            you worked last</p>
                                    </div>
                                    <div className='col-sm-8'>
                                        <p className='signika-font'><span className='me-3'>:</span>{memberDetails?.institutionWorkedLast ? memberDetails?.institutionWorkedLast : "-"}</p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <p className='signika-font fw-semibold'>Level at which you are primarily
                                            teaching/taught:</p>
                                    </div>
                                    <div className='col-sm-8'>
                                        <p className='signika-font'><span className='me-3'>:</span>
                                        {getPrimarilyTeaching(memberDetails?.primarilyTeachingLevel)}
                                        </p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <p className='signika-font fw-semibold'>Nature of your work:</p>
                                    </div>
                                    <div className='col-sm-8'>
                                        <p className='signika-font'><span className='me-3'>:</span>
                                            {getNatureOfWork(memberDetails?.natureWork)}
                                        </p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <p className='signika-font fw-semibold'>Are you a Ph.D. Research
                                            Supervisor?</p>
                                    </div>
                                    <div className='col-sm-8'>
                                        <p className='signika-font'><span className='me-3'>:</span>
                                            {memberDetails?.phdResearchSupervisor === 1 ? "Yes" : "No"}
                                        </p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <p className='signika-font fw-semibold'>Name of the university where
                                            you are a research supervisor:</p>
                                    </div>
                                    <div className='col-sm-8'>
                                        <p className='signika-font'><span className='me-3'>:</span>{memberDetails?.nameResearchSupervisor ? memberDetails?.nameResearchSupervisor : '-'}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Container>
                    </div>
                </div>
                <p className='signika-font fw-semibold mt-5 mb-0 teacher-member-header'>Interest in your Professional Development</p>
                <div className='card teacher-member-card mb-3 '>
                    <div className='row'>
                        <div className='d-flex justify-content-end'>
                            <Button variant='outlined' className='m-2 edit-button-profile-membership' onClick={() => handleFormNavigation("professionalDev")}>Edit  <CreateIcon sx={{ fontSize: '10px' }} /></Button>
                        </div>
                        <Container maxWidth="lg">
                            <div className='row p-3'>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <p className='signika-font fw-semibold'>Are you a member of any other
                                            Teachers’ Association?</p>
                                    </div>
                                    <div className='col-sm-8'>
                                        <p className='signika-font mb-1'><span className='me-3'>:</span>{memberDetails?.teacherAssociation === "1" ? "Yes" : "No"}</p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <p className='signika-font fw-semibold'>If yes, mention the name of those
                                            associations</p>
                                    </div>
                                    <div className='col-sm-8'>
                                        <p className='signika-font'><span className='me-3'>:</span>{memberDetails?.associationName ? memberDetails?.associationName : "-"}
                                        </p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <p className='signika-font fw-semibold'>Which of the following would
                                            you like to do for your
                                            continuous professional
                                            development?</p>
                                    </div>
                                    <div className='col-sm-8'>
                                        <p className='signika-font'><span className='me-3'>:</span>(Select all that apply)</p>
                                        <div className='row'>
                                            {memberDetails?.professionalDevelopment?.length > 0 ? (
                                                memberDetails.professionalDevelopment.map((data, i) => (
                                                    <div className='col-md-4 col-sm-12' key={i}>
                                                        <ul className='signika-font'>
                                                            <li>{data}</li>
                                                        </ul>
                                                    </div>
                                                ))
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <p className='signika-font fw-semibold'>Your areas of expertise</p>
                                    </div>
                                    <div className='col-sm-8'>
                                        <p className='signika-font'><span className='me-3'>:</span>
                                            (Select all the areas in which you
                                            teach courses, publish research
                                            papers, and deliver talks)
                                        </p>
                                        <p className='signika-font  fw-bold'>Literary Studies  </p>
                                        <div className='row'>
                                            {memberDetails?.expertiseLiteraryStudies?.length > 0 ? (
                                                memberDetails.expertiseLiteraryStudies.map((data, i) => (
                                                    <div className='col-md-4 col-sm-12' key={i}>
                                                        <ul className='signika-font'>
                                                            <li>{data}</li>
                                                        </ul>
                                                    </div>
                                                ))
                                            ) : (
                                                "-"
                                            )}
                                        </div>
                                        <p className='fw-bold signika-font '>Linguistics</p>
                                        <div className='row'>
                                            {memberDetails?.expertiseLinguistics?.length > 0 ? (
                                                memberDetails.expertiseLinguistics.map((data, i) => (
                                                    <div className='col-md-4 col-sm-12' key={i}>
                                                        <ul className='signika-font'>
                                                            <li>{data}</li>
                                                        </ul>
                                                    </div>
                                                ))
                                            ) : (
                                                "-"
                                            )}
                                        </div>
                                        <p className='fw-bold signika-font '>English Language Teaching (ELT)</p>
                                        <div className='row'>
                                            {memberDetails?.expertiseELT?.length > 0 ? (
                                                memberDetails.expertiseELT.map((data, i) => (
                                                    <div className='col-md-4 col-sm-12' key={i}>
                                                        <ul className='signika-font'>
                                                            <li>{data}</li>
                                                        </ul>
                                                    </div>
                                                ))
                                            ) : (
                                                "-"
                                            )}
                                        </div>
                                        <p className='fw-bold signika-font '>Interdisciplinary Areas</p>
                                        <div className='row'>
                                            {memberDetails?.expertiseInterdisciplinaryAreas?.length > 0 ? (
                                                memberDetails.expertiseInterdisciplinaryAreas.map((data, i) => (
                                                    <div className='col-md-4 col-sm-12' key={i}>
                                                        <ul className='signika-font'>
                                                            <li>{data}</li>
                                                        </ul>
                                                    </div>
                                                ))
                                            ) : (
                                                "-"
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <p className='signika-font fw-semibold'>Areas of Expertise</p>
                                    </div>
                                    <div className='col-sm-8'>
                                        <p className='signika-font'><span className='me-3'>:</span>
                                            Are you
                                            willing to have your areas of
                                            expertise or interest listed publicly
                                            so that other members can identify
                                            potential collaboration
                                            opportunities?
                                            <ulspan><li>{memberDetails?.areaOfExpertise !== null ? (memberDetails?.areaOfExpertise === "1" ? "yes" : "No") : "-"}</li></ulspan>
                                        </p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <p className='signika-font fw-semibold'>Professional Engagement</p>
                                    </div>
                                    <div className='col-sm-8'>
                                        <p className='signika-font'><span className='me-3'>:</span>Are
                                            you interested in being contacted
                                            for the following activities?
                                            (Please select all that apply)</p>
                                        <div className='row'>
                                            {memberDetails?.professionalEngagement?.length > 0 ? (
                                                memberDetails.professionalEngagement.map((data, i) => (
                                                    <div className='col-md-4 col-sm-12' key={i}>
                                                        <ul className='signika-font'>
                                                            <li>{data}</li>
                                                        </ul>
                                                    </div>
                                                ))
                                            ) : (
                                                <></>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Container>
                    </div>
                </div>
            </Container>
            <p className='text-center signika-font fs-6 pb-3 mb-0'>
                <span className='fw-bold '>Withdrawal of Consent:</span>  Please note that you can withdraw your consent to share your information at any
                time by updating your privacy settings in your profile.
            </p>
        </div>
    )
}

export default TeacherMember
