import React from 'react';
import '../../src/style/css/IndiaReads.css';

const IndiaReads = () => {
  return (
    <div className='background pt-lg-5 pt-3'>
      <header className='Architects-header d-flex align-items-center justify-content-center'>
        <h2 className='signika-fontOEC text-center'>India Reads</h2>
      </header>
      <section className='signika-font-indiareads p-lg-5 p-3'>
        <p className='india-reads-firstpara'>
            The English Language Teachers’ Association of India (ELTAI) is proud to launch the “India Reads” 
            movement. This initiative aims to reignite the joy of reading among learners across India, addressing 
            the decline in reading habits brought about by the dominance of visual media. 
        </p><br/>
        <h3 className='india-reads-header-font'>The Importance of Reading </h3><br/>
        <p className='india-Read-content-size'>In today’s “visual generation”, the gradual shift away from book reading is leading to a significant 
            cognitive decline. Reading is not just a skill; it is the foundation for speaking, writing, listening,
            and thinking. The decline in reading habits jeopardizes the development of these crucial skills. Therefore,
            it is imperative to halt this decline and promote reading as an essential activity. </p>
            <p className=' india-reads-header-font'>Our Approach </p>
            <p className='india-Read-content-size'>ELTAI, leveraging the experiences of hundreds of teachers nationwide, has devised an innovative approach
                to encourage reading. The India Reads program is tailored to cater to different educational levels—primary,
                secondary, and tertiary. The program’s highlights include: </p><br/>
            <ul className='India-reads-Our-Approach-bullets india-Read-content-size'>
                <li><b className='india-reads-approach-bold'>100-Hour Modules:</b> Customized modules for different educational levels, featuring LMS, wiki, videos, 
                    audio texts, blogs, e-books, and reviews. </li>
                <li><b className='india-reads-approach-bold'>Bilingual Reading Promotion:</b> Encouraging reading in both English and other major languages, 
                    recognizing the transferability of reading skills. </li>
                <li><b className='india-reads-approach-bold'>Teacher Workshops:</b> Conducting workshops to equip teachers with strategies for promoting reading.</li>
                <li><b className='india-reads-approach-bold'>Online Events:</b> Engaging students with online events featuring writers, celebrities, and avid readers. </li>
                <li><b className='india-reads-approach-bold'>Competitions:</b> Organizing local, state, and national level competitions with certificates and prizes. </li>
                <li><b className='india-reads-approach-bold'>Classroom Libraries:</b> Establishing libraries sourced through CSR, parent contributions, and public donations,
                    turning schools into reading hubs. </li>
            </ul>        
            <p className='india-reads-header-font '>Objectives </p>
            <p className='india-Read-content-size'>The India Reads movement aims to:</p><br/>
            <ul className='India-reads-Our-Approach-bullets india-Read-content-size'>
                <li>Promote the joy of reading and nurture lifelong readers. </li>
                <li>Enhance comprehension, retention, and application of knowledge through reading. </li>
                <li>Familiarize students with diverse genres and reading strategies. </li>
                <li>Utilise web resources and social platforms to maintain reading interest. </li>
                <li>Encourage schools to integrate reading sessions into their schedules. </li>
                <li>Recognise and celebrate reading talents through various events and competitions</li>
                <li>Transform libraries into vibrant centres of reading activity </li>
            </ul>
            <p className='india-reads-header-font'>Resources </p>
            <p className='india-Read-content-size'>The program offers extensive resources to support its objectives:</p><br/>
            <ul className='India-reads-Our-Approach-bullets india-Read-content-size'>
                <li>Age-appropriate, cognitively challenging 100-hour modules for each educational level. </li>
                <li>Learning Management Systems (LMS) with a variety of reading materials and interactive content. </li>
                <li>A virtual library with professional librarians to assist mentors and students. </li>
                <li>Training programs for mentors and curated reading lists. </li>
                <li>Sponsored events and state/national level competitions to motivate students. </li>
                <li>Central monitoring and feedback to ensure effective implementation. </li>
            </ul>
            <p className='india-reads-header-font'>Partners and Participation </p>
            <p className='india-Read-content-size'>The success of India Reads relies on the active participation of all stakeholders, including teachers, 
               parents, alumni, administrators, writers, corporates, NGOs, celebrities, and the public. ELTAI invites
               schools, colleges, and universities to join this transformative movement and become long-term members 
               of the association to avail the benefits of the program. </p>
            <p className='india-reads-header-font'>Membership Benefits </p>
            <p className='india-Read-content-size'>Institutions enrolling in the India Reads program will enjoy numerous advantages: </p><br/>
            <ul className='India-reads-Our-Approach-bullets india-Read-content-size'> 
                <li>Comprehensive reading modules tailored for different educational levels. </li>
                <li>Access to ELTAI’s journals and reduced registration fees for conferences.</li>
                <li>Opportunities to establish classroom libraries and receive training for mentors. </li>
                <li>Membership benefits including access to the LMS and various online resources. 

 </li>
            </ul>
            <p className='india-reads-header-font'>Join the Movement </p>
            <p className='india-Read-content-size'>We call upon educational institutions to join hands with ELTAI in the India Reads movement.
               Your participation and support will play a pivotal role in revolutionizing the reading culture
               and learning environment for millions of students across the country. </p>
            <p className='india-Read-content-size'> www.eltai.in for more details on memberships and online applications.
               Together, let's foster a nation of avid readers and lifelong learners. </p>
      </section>
      </div>
  )
}

export default IndiaReads
