import React, { useEffect, useState } from "react";
import "../../src/style/css/announcements.css"
import speaker from "../../src/assets/images/home/announcements/speaker.png";
import excitingAnnouncement1 from "../../src/assets/images/home/announcements/excitingAnnouncement1.png";
import excitingAnnouncement2 from "../../src/assets/images/home/announcements/excitingAnnouncement2.png";
import excitingAnnouncement3 from "../../src/assets/images/home/announcements/excitingAnnouncement3.png";
import excitingAnnouncement4 from "../../src/assets/images/home/announcements/excitingAnnouncement4.png";
import { usePaginationImportantAnnouncementMutation } from '../../src/app/services/importantAnnouncementService';
import ReactPaginate from "react-paginate";
import { selectCurrentScope } from "../../src/app/auth/authSlice";
import { Button } from "@mui/material";
import { useSelector } from 'react-redux';




function ViewAnnouncement() {
    const [page, setPage] = useState(0);
    const rowsPerPage = 4;
    const [totalPage, setTotalPage] = useState(0);
    const [getAllAnnouncement] = usePaginationImportantAnnouncementMutation();
    const [announcement, setAnnouncement] = useState([])
    const currentScope = useSelector(selectCurrentScope);


    const announcementImages = [
        excitingAnnouncement1,
        excitingAnnouncement2,
        excitingAnnouncement3,
        excitingAnnouncement4,
    ];

    useEffect(() => {

        getAllAnnouncement({ page: page + 1, itemsPerPage: rowsPerPage, role: (currentScope) ? currentScope : '' })
            .then((res) => {
                if (res?.data?.status) {
                    setAnnouncement(res?.data?.data?.newAnnouncementDetails)
                    setTotalPage(res?.data?.data?.totalCount || 0);

                } else {
                    // Handle failure or empty response
                    console.error("No announcements found.");
                }
            })
            .catch((error) => {
                // Handle error
                console.error("Error fetching announcements: ", error);
            });
    }, [getAllAnnouncement, page, rowsPerPage, currentScope]); // Add dependencies if needed

    //handlePageClick
    const handlePageClick = ({ selected }) => {
        setPage(selected);
    };

    //calculate pagecount
    const pageCount = Math.ceil(totalPage / rowsPerPage);
    const colors = ['#c9e5ff', '#fdf9d4', '#e8e0ff', '#e3ffeb']

    return (
        <div>
            <div className='pt-1 mb-3 '>
                <div className='p-0'>
                    <div className=' Architects-header d-flex align-items-center justify-content-center '>
                        <h2 className='signika-fontOEC text-center '>Announcements</h2>
                    </div>
                </div>
            </div>
            <div className="container mt-3">
                <div className="row">
                    <div className="col-md-8">
                    </div>
                    <div className="col-md-4 mt-3">
                        <Button
                            onClick={() => window.history.back()}
                            variant="contained"
                            className="menus-color"
                            style={{ float: "right", marginRight: 58 }}
                        >
                            Back
                        </Button>
                    </div>
                </div>
            </div>

            <div className="container signika announce-container mt-5 mb-5">
                <div className="row">
                    {(announcement && announcement.length >= 1) ?
                        <>
                            {announcement.map((data, i) => {
                                return (
                                    <div className="col-md-12 mt-5 mt-2" key={i}>
                                        <div className="card announce-card mx-auto" >
                                            <div className="card-body p-3" >
                                                <div className="row">
                                                    <div className="col-md-3"  >
                                                        <img src={speaker} alt="speaker" style={{ backgroundColor: colors[i % colors.length], width: '70%' }} />
                                                    </div>
                                                    <div className="col-md-7">
                                                        <h5 className="announce-card-title">
                                                            <div
                                                                className="announce-card-title-image"
                                                                style={{ backgroundImage: `url(${announcementImages[i]})` }}
                                                            /> &nbsp;{data?.heading}&nbsp;&nbsp;
                                                            <div
                                                                className="announce-card-title-image"
                                                                style={{ backgroundImage: `url(${announcementImages[i]})` }}
                                                            />
                                                        </h5>
                                                        <p className="announce-card-text ml-3">
                                                            {data?.text}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                            {announcement?.length > 0 && (
                                <ReactPaginate
                                    pageCount={pageCount} // Calculate total pages
                                    pageRangeDisplayed={2}
                                    marginPagesDisplayed={1}
                                    onPageChange={handlePageClick}
                                    containerClassName={"pagination"}
                                    activeClassName={"active"}
                                    previousLabel={pageCount > 1 && page >= 1 ? "Previous" : null}
                                    nextLabel={
                                        pageCount > 1 && page + 1 !== pageCount ? "Next" : null
                                    }
                                    disabledClassName={"disablebtn"}
                                    forcePage={page}
                                />
                            )}
                        </>
                        : <></>
                    }
                </div>
            </div>
        </div>

    );
}

export default ViewAnnouncement;
