import { Box, Stack, Typography } from "@mui/material";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import React,{useState} from "react";
import { useForm, FormProvider, useWatch } from "react-hook-form";
import PersonalInfo from "../Component/RegisterForm/PersonalInfo";
import PaymentInfo from "../Component/RegisterForm/PaymentInfo";
import MemberShipInfo from "../Component/RegisterForm/MemberShipInfo";
import { useCreateMemberMutation } from "../app/services/memberServices";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from 'react-router-dom';
import Spinner from "../Component/spinner/spinner";
import { toast } from "react-toastify";

//Steps for registerform
const steps = ["Personal Info", "MemberShip Info", "Payment Info"];

const containerStyle = {
  backgroundColor: "#fff",
  borderRadius: "5px",
  width: "75%",
  marginInline: "auto",
  padding: "10px",
};

const RegisterForm = () => {
  const [activeStep, setActiveStep] = React.useState(0);
  const navigate = useNavigate();
  const [createMember] = useCreateMemberMutation();
  const { planIdFromRoute, planTypeIdFromRoute } = useParams();
  const [loading, setLoading] = useState(false);

  const methods = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      password: "",
      confirmPassword: "",
      gender: "",
      phone: "",
      email: "",
      role: "PORTAL_MEMBER",
      address: "",
      city: "",
      state: "",
      zipcode: "",
      termsCondition: false,
      planTypeId: "",
      planId: "",
      amount: "",
      currency: "INR",
      orderId: "",
      sigGroups: [],
      paymentStatus: 0,
      memberId: "",
      chapter: "",
    },
  });

  const { control } = methods;

  const paymentStatus = useWatch({
    control,
    name: 'paymentStatus',
  });

  //Form submission
  const handleNext = (formData) => {
      const formValid = methods.formState.isValid;
    if (activeStep === steps.length - 2) {
      if (formValid) {
        setLoading(true)
        const data={...formData,plans:[{dummyPlanId:formData.planId,dummyPlanTypeId:formData.planTypeId,dummyAmount:formData.amount,dummyCurrency:formData.currency}]}
        const {dummyPlanId,dummyPlanTypeId,dummyAmount,dummyCurrency,...updatedData}=data;        
        createMember(updatedData).then((res) => {
          if (res?.data?.status) {
            setLoading(false)
            if (res.data?.data?.purchaseData?.orderId) { 
              setLoading(false)
              setActiveStep((prevActiveStep) => prevActiveStep + 1); 
              methods.setValue(
                "orderId",
                res.data?.data?.purchaseData?.orderId
              );
              methods.setValue("memberId", res.data?.data?._id);
            }
          } else {
            setLoading(false)
            toast.error(res?.data?.err?.message)
          }
        });
      }
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  //Handling step back
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    methods.reset();
    navigate("/login");
  };

  //Active step
  const getActiveContent = (step) => {
    if (step === 0) {
      return <PersonalInfo />;
    }
    if (step === 1) {
      return (
        <MemberShipInfo
          planIdFromRoute={planIdFromRoute}
          planTypeIdFromRoute={planTypeIdFromRoute}
        />
      );
    }
    if (step === 2) {
      return <PaymentInfo />;
    }
  };

  return (
    <>
      <div
        style={{
          height: "375px",
          backgroundImage: 'url("assets/images/banner/form_banner.png")',
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="position-relative" style={{ top: "75%" }}>
          <h1 className="text-white text-center">
            {" "}
            Membership Registration Form
          </h1>
        </div>
      </div>
      <div className="container mt-2">
        <Link to="/">
        <Button
          variant="contained"
          className="menus-color"
          style={{ float: "right" }}
        >
          Back
        </Button>
        </Link>
      </div>
      {loading ? (
        <Spinner />
      ) : (
        <div className="mb-5 mt-4" style={containerStyle}>    
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        {activeStep === steps.length ? (
          <React.Fragment>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                pt: 2,
                justifyContent: "center",
              }}
            >
              <h2 className="text-success text-center">
                Thank You For Registering with us
              </h2>
              <Button
                variant="contained"
                sx={{ width: "20px", mx: "auto" }}
                onClick={handleReset}
                className = "menus-color"
              >
                Login
              </Button>
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography>
            <FormProvider {...methods}>
              <Box
                width={"50%"}
                className="mx-auto"
                component="form"
                onSubmit={methods.handleSubmit(handleNext)}
                noValidate
              >
                <Stack spacing={3} direction={"column"}>
                  {getActiveContent(activeStep)}
                </Stack>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginInline: "auto",
                    my: "20px",
                  }}
                >
                  {activeStep !== 0 && (
                    <Button
                      className="menus-color"
                      variant="contained"
                      onClick={handleBack}
                      disabled={paymentStatus ? true : false}
                      sx={{ mr: 1 }}
                    >
                      Back
                    </Button>
                  )}
                  <Box sx={{ flex: "1 1 auto" }} />
                  <Button
                    className="menus-color"
                    variant="contained"
                    disabled={
                      activeStep === steps.length - 1
                        ? !paymentStatus 
                        : false
                    }
                    type="submit"
                  >
                    {activeStep === steps.length - 1 ? "Finish" : "Next"}
                  </Button>
                </Box>
              </Box>
            </FormProvider>
          </React.Fragment>
        )}
      </div>
       )}   
    </>
  );
};

export default RegisterForm;
