import {
    Autocomplete,
    FormHelperText,
    FormLabel,
    Radio,
    RadioGroup,
    TextField,
    Typography,
} from "@mui/material";
import {
    Box,
    Button,
    FormControlLabel,
    InputLabel,
    MenuItem,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm, Controller, } from "react-hook-form";
import Select from "@mui/material/Select";
import "../../../style/css/eventForm.css";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import FormControl from "@mui/material/FormControl";
import { useGetAllSIGGroupQuery } from "../../../app/services/SIGGroupServices";
import { useLazyGetAllChaptersQuery } from "../../../app/services/chapterServices";
import Grid from "@mui/material/Grid";
import { toast } from "react-toastify";
import Spinner from "../../../Component/spinner/spinner";
import { industries } from '../../../constant/constant'
import { Country, State } from 'country-state-city';
import { useLazyGetAllMemberShipDetailsQuery } from '../../../app/services/memberShipServices'
import { useCreateMemberMutation, useLazyGetMemberDetailsByIdQuery } from '../../../app/services/memberServices'
import { useNavigate } from "react-router-dom";

const steps = [
    "Personal Information",
    "Payment Information",
];

function AddEditCorporate() {
    const [loading, setLoading] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [allSIG, setAllSIG] = React.useState([]);
    const [selectedChapters, setSelectedChapters] = useState([]);
    const { refetch: refetchSIG } = useGetAllSIGGroupQuery();
    const [getAllChapters] = useLazyGetAllChaptersQuery();
    const [getAllplans] = useLazyGetAllMemberShipDetailsQuery()
    const [planName, setPlanName] = useState([])
    const [amount, setAmount] = useState()
    const [currency, setCurrency] = useState()
    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedState, setSelectedState] = useState('');
    const [createMember] = useCreateMemberMutation()
    const countries = Country.getAllCountries();
    const states = selectedCountry ? State.getStatesOfCountry(selectedCountry) : [];
    const [gettMemberDetailsByIdQuery] = useLazyGetMemberDetailsByIdQuery();
    const navigate = useNavigate()
    const [costType, setCostType] = useState([]);

    // useForm hook for form data handling
    const {
        control,
        register,
        handleSubmit,
        watch,
        setValue,
        getValues,
        formState: { errors },
    } = useForm({
        mode: 'onChange',
        defaultValues: {
            type: 3,
            city: "",
            pinCode: "",
            state: "",
            country: "",
            mobileNumber: '',
            landlineNumber: '',
            address: '',
            email: '',
            role: "PORTAL_MEMBER",
            websiteAddressOrganisation: "",
            organisationEstablished: "",
            chapter: "",
            typeOfOrganisation: "",
            uploadOrganisationLogo: "",
            headOfficeLocated: "",
            addressedTo: '',
            employees: "",
            industry: '',
            dummyPlanId: "",
            dummyPlanTypeId: "",
            dummyAmount: "",
            dummyCurrency: "INR",
            organisationName: ""
        },
    });

    const selectedPlanName = watch("dummyPlanId");
    const selectedplantypeId = watch("dummyPlanTypeId")

    //Validate sigGroups and chapter
    const validateAtLeastOne = () => {
        const { sigGroups } = getValues();
        return (sigGroups && sigGroups.length > 0) || "Please select at least one of SIG Groups";
    };

    useEffect(() => {
        getAllplans().then((res) => {
            if (res?.data?.status) {
                const filteredPlan = res?.data?.data?.filter(item => item.isDeleted === 0)
                setPlanName(filteredPlan)
            }
        })
    }, [getAllplans])

    useEffect(() => {
        if (selectedPlanName) {
            const foundPlan = planName.find((plan) => plan._id === selectedPlanName);
            setCostType(foundPlan?.planType || []);
        }
    }, [selectedPlanName, planName]);

    useEffect(() => {
        costType.forEach((data) => {
            if (data._id === selectedplantypeId) {
                setAmount(data.amount)
                setCurrency(data.currencyType)
            }
        })
    }, [costType, selectedplantypeId])

    useEffect(() => {
    }, [costType])

    useEffect(() => {
        if (amount && currency) {
            setValue("dummyAmount", amount);
            setValue("dummyCurrency", currency);
        }
    }, [amount, currency, setValue]);

    //On submit for save
    const AddEditCorporate = (data) => {
        if (activeStep === steps.length - 1) {
            setLoading(false)
            const formData = new FormData();
            Object.entries(data).forEach(([key, value]) => {
                if (value instanceof FileList) {
                    formData.append(key, value[0]);
                } else if (key !== 'sigGroups' && key !== "dummyAmount" && key !== "dummyCurrency") {
                    formData.append(key, value);
                }
            });
            formData.append("dummyAmount", amount)
            formData.append("dummyCurrency", currency)
            if (data.sigGroups && data.sigGroups.length > 0) {
                data.sigGroups.forEach(item => {
                    formData.append("sigGroups", item)
                })
            }
            createMember(formData).then((res) => {
                if (res?.data?.data) {
                    var options = {
                        key: process.env.REACT_APP_RAZOR_PAY_KEY_ID, // Enter the Key ID generated from the Dashboard
                        amount: amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
                        currency: (amount === 0 ? "INR" : "USD"),
                        name: res?.data?.data?.fullName,
                        description: "Membership payment",
                        order_id: res?.data?.data?.purchaseData?.orderId, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
                        handler: function (response) {
                            gettMemberDetailsByIdQuery(res?.data?.data?._id).then((res) => {
                                if (res.data?.status) {
                                    const isPaid = res.data?.data?.plans[0]?.isPaid === 1;
                                    setValue("paymentStatus", isPaid);
                                    toast.success("Member Saved Successfully");
                                    navigate('/login')
                                }
                            });
                        },
                    };
                    var rzp1 = new window.Razorpay(options);
                    rzp1.open();
                    rzp1.on("payment.failed", function (response) {
                        alert("Error in Payment", response.error);
                        toast.error("Member Created Failed");
                    });
                }
                else {
                    toast.error(res?.error?.data?.error);
                }
            })
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    //Get all chapter
    React.useEffect(() => {
        refetchSIG().then((res) => {
            setAllSIG(res?.data?.data || []);
        });
        getAllChapters().then((res) => {
            setSelectedChapters(res?.data?.data || []);
        });
    }, [
        setValue,
        getAllChapters,
        refetchSIG,
    ]);

    //handle Back
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const validateFile = (file) => {
        const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg'];
        const maxSize = 5 * 1024 * 1024; // 5 MB size limit
        if (file.length === 0) {
            return "File is required!";
        }
        if (!allowedTypes.includes(file[0].type)) {
            return "Only JPG, JPEG or PNG images are allowed!";
        }
        if (file[0].size > maxSize) {
            return "File size should not exceed 5MB!";
        }
        return true;
    };

    return (
        <>
            <Box>
                <div className='pt-1  pb-3'>
                    <div className='p-0'>
                        <div className=' Architects-header d-flex align-items-center justify-content-center '>
                            <h2 className='signika-fontOEC text-center '>Corporates</h2>
                        </div>
                    </div>
                </div>
            </Box>
            <div className="container mt-3">
                <div className="row">
                    <div className="col-md-8">
                    </div>
                    <div className="col-md-4 mt-3">
                        <Button
                            onClick={() => window.history.back()}
                            variant="contained"
                            className="btn-color"
                            style={{ float: "right", marginRight: 58 }}
                        >
                            Back
                        </Button>
                    </div>
                </div>
            </div>
            {loading ? (
                <Spinner />
            ) : (
                <Box
                    className="container"
                    sx={{ width: "100%", marginTop: "50px", marginBottom: "50px" }}
                >
                    <Stepper activeStep={activeStep} className="mt-5">
                        {steps.map((label, index) => {
                            return (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>
                    <form className="mt-5" onSubmit={handleSubmit(AddEditCorporate)}>
                        {activeStep === 0 && (
                            <Box>
                                <div style={{ paddingLeft: 40, paddingRight: 40 }}>
                                    <Grid container rowSpacing={3} columnSpacing={5}>
                                        <Grid item xs={12}>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                InputLabelProps={{ shrink: true }}
                                                fullWidth
                                                label={
                                                    <Typography className="text-dark">
                                                        Name of your Organisation<span style={{ color: "red" }}>*</span>
                                                    </Typography>
                                                }
                                                {...register("organisationName", {
                                                    required: "Please Enter an Organisation",
                                                    minLength: { value: 3, message: 'Please Enter a valid organisation Name' }
                                                })}
                                                error={!!errors?.organisationName}
                                                helperText={errors?.organisationName?.message}
                                                placeholder="Name of your organisation"
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                InputLabelProps={{ shrink: true }}
                                                fullWidth
                                                label={
                                                    <Typography className="text-dark">
                                                        Who should be addressed to (when we send receipts &amp; journals)?<span style={{ color: "red" }}>*</span>
                                                    </Typography>
                                                }
                                                {...register("addressedTo", {
                                                    required: "Please Enter Who Should Be Addressed To",
                                                    minLength: { value: 3, message: 'Please Enter a Valid Name' }
                                                })}
                                                error={!!errors?.addressedTo}
                                                helperText={errors?.addressedTo?.message}
                                                placeholder="Who should be addressed to?"
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                InputLabelProps={{ shrink: true }}
                                                fullWidth
                                                label={
                                                    <Typography className="text-dark">
                                                        Your Address (for Communication)<span style={{ color: "red" }}>*</span>
                                                    </Typography>
                                                }
                                                {...register("address", {
                                                    required: "Please Enter a Address",
                                                    minLength: { value: 3, message: 'Please Enter a Valid Address' }
                                                })}
                                                error={!!errors?.address}
                                                helperText={errors?.address?.message}
                                                placeholder="Your Address"
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                InputLabelProps={{ shrink: true }}
                                                fullWidth
                                                label={
                                                    <Typography className="text-dark">
                                                        Your City/Town<span style={{ color: "red" }}>*</span>
                                                    </Typography>
                                                }
                                                {...register("city", {
                                                    required: "Please Enter a City/Town",
                                                    minLength: { value: 3, message: 'Please Enter a Valid Address' }
                                                })}
                                                error={!!errors?.city}
                                                helperText={errors?.city?.message}
                                                placeholder="Your City/Town"
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                InputLabelProps={{ shrink: true }}
                                                fullWidth
                                                label={
                                                    <Typography className="text-dark">
                                                        Pin Code<span style={{ color: "red" }}>*</span>
                                                    </Typography>
                                                }
                                                {...register("pinCode", {
                                                    required: "Please Enter Pin Code",
                                                    minLength: { value: 6, message: 'Pin Code must be 6 digits' },
                                                    maxLength: { value: 6, message: 'Pin Code must be 6 digits' }
                                                })}
                                                error={!!errors?.pinCode}
                                                helperText={errors?.pinCode?.message}
                                                placeholder="Pin Code"
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <FormControl fullWidth className="mt-0" error={!!errors.country}>
                                                <InputLabel>Country<span style={{ color: "red" }}>*</span></InputLabel>
                                                <Controller
                                                    name="country"
                                                    control={control}
                                                    defaultValue=""
                                                    rules={{
                                                        required: "Country is Required", // Validation rule
                                                    }}
                                                    render={({ field }) => (
                                                        <Select
                                                            {...field}
                                                            value={selectedCountry}
                                                            onChange={(e) => {
                                                                setSelectedCountry(e.target.value);
                                                                field.onChange(e);
                                                            }}
                                                            label={
                                                                <Typography className="text-dark">
                                                                    Country<span style={{ color: "red" }}>*</span>
                                                                </Typography>
                                                            }
                                                        >
                                                            <MenuItem value="">
                                                                <em>Select Country</em>
                                                            </MenuItem>
                                                            {countries.map((country) => (
                                                                <MenuItem key={country?.isoCode} value={country?.isoCode}>
                                                                    {country.name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    )}
                                                />
                                                {errors?.country && (
                                                    <FormHelperText>{errors?.country?.message}</FormHelperText>
                                                )}
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <FormControl fullWidth className="mt-0" disabled={!selectedCountry} error={!!errors.state}>
                                                <InputLabel>State<span style={{ color: "red" }}>*</span></InputLabel>
                                                <Controller
                                                    name="state"
                                                    control={control}
                                                    defaultValue=""
                                                    rules={{
                                                        required: selectedCountry ? "State is Required" : "Please Select a Country", // Only required if a country is selected
                                                    }}
                                                    render={({ field }) => (
                                                        <Select
                                                            {...field}
                                                            value={selectedState}
                                                            onChange={(e) => {
                                                                setSelectedState(e.target.value);
                                                                field.onChange(e);
                                                            }}
                                                            label={
                                                                <Typography className="text-dark">
                                                                    State<span style={{ color: "red" }}>*</span>
                                                                </Typography>
                                                            }
                                                        >
                                                            <MenuItem value="">
                                                                <em>Select State</em>
                                                            </MenuItem>
                                                            {states.map((state) => (
                                                                <MenuItem key={state.isoCode} value={state.name}>
                                                                    {state.name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    )}
                                                />
                                                {errors.state && (
                                                    <FormHelperText>{errors.state.message}</FormHelperText>
                                                )}
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                InputLabelProps={{ shrink: true }}
                                                fullWidth
                                                label={
                                                    <Typography className="text-dark">
                                                        Mobile Number <span style={{ color: "red" }}>*</span>
                                                    </Typography>
                                                }
                                                {...register("mobileNumber", {
                                                    required: "Please Enter a Mobile Number",
                                                    pattern: {
                                                        value: /^\d{10}$/,
                                                        message: 'Mobile Number must be 10 digits'
                                                    }
                                                })}
                                                error={!!errors?.mobileNumber}
                                                helperText={errors?.mobileNumber?.message}
                                                placeholder=" Mobile Number"
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                InputLabelProps={{ shrink: true }}
                                                fullWidth
                                                label={
                                                    <Typography className="text-dark">
                                                        Email Address<span style={{ color: "red" }}>*</span>
                                                    </Typography>
                                                }
                                                {...register("email", {
                                                    required: "Please Enter a Email Address",
                                                    pattern: {
                                                        value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                                        message: 'Enter a valid Email Id'
                                                    }
                                                })}
                                                error={!!errors?.email}
                                                helperText={errors?.email?.message}
                                                placeholder="Email Address"
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                InputLabelProps={{ shrink: true }}
                                                fullWidth
                                                label={
                                                    <Typography className="text-dark">
                                                        Landline Number
                                                    </Typography>
                                                }
                                                {...register("landlineNumber", {
                                                    pattern: {
                                                        value: /^(?:\+91[-\s]?)?(?:\(\d{3}\)|\d{3})[-\s]?\d{7}$/,
                                                        message: 'Please Enter a Valid Landline Number'
                                                    }
                                                })}
                                                error={!!errors?.landlineNumber}
                                                helperText={errors?.landlineNumber?.message}
                                                placeholder="Landline Number"
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                InputLabelProps={{ shrink: true }}
                                                fullWidth
                                                label={
                                                    <Typography className="text-dark">
                                                        Website Address Of Organisation(if any)
                                                    </Typography>
                                                }
                                                {...register("websiteAddressOrganisation", {
                                                    pattern: { value: /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[\w .-]*)*\/?$/, message: 'Please Enter a valid website address' }
                                                })}
                                                error={!!errors?.websiteAddressOrganisation}
                                                helperText={errors?.websiteAddressOrganisation?.message}
                                                placeholder="Website Address Of Organisation"
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                InputLabelProps={{ shrink: true }}
                                                fullWidth
                                                label={
                                                    <Typography className="text-dark">
                                                        When was your Organisation established?<span style={{ color: "red" }}>*</span>
                                                    </Typography>
                                                }
                                                variant="outlined"
                                                type="date"
                                                value={watch("organisationEstablished") ? watch("organisationEstablished").split("T")[0] : ""}
                                                {...register("organisationEstablished", {
                                                    required: "Please Select Organisation Established",
                                                    max: { value: new Date().toISOString().split('T')[0], message: 'Future Date is not allowed' }
                                                })}
                                                placeholder="When was your Organisation established?"
                                                error={!!errors?.organisationEstablished}
                                                helperText={errors?.organisationEstablished?.message}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Controller control={control} rules={{ required: "Please Select a Type Of Your Organisation", }} name="typeOfOrganisation" render={({ field, fieldState }) => (
                                                <FormControl fullWidth error={!!fieldState.error}>
                                                    <InputLabel id="event">
                                                        Type Of Your Organisation<span style={{ color: "red" }}>*</span>
                                                    </InputLabel>
                                                    <Select
                                                        style={{ backgroundColor: "#f0f0f0" }}
                                                        labelId="Country"
                                                        id="event"
                                                        label={
                                                            <Typography className="text-dark">
                                                                Type Of Your Organisation<span style={{ color: "red" }}>*</span>
                                                            </Typography>
                                                        }
                                                        value={field.value ? field.value : ""}
                                                        variant="outlined"
                                                        onChange={(e) => field.onChange(e.target.value)}
                                                        error={!!fieldState.error}
                                                    >
                                                        <MenuItem value="1">
                                                            Government
                                                        </MenuItem>
                                                        <MenuItem value="2">
                                                            Private
                                                        </MenuItem>
                                                        <MenuItem value="3">
                                                            Non-Profit
                                                        </MenuItem>
                                                    </Select>
                                                    {errors.typeOfOrganisation && (
                                                    <FormHelperText>{errors.typeOfOrganisation.message}</FormHelperText>
                                                )}
                                                </FormControl>
                                            )} />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Controller control={control} rules={{ required: "Please Select a Sector Of Your Organisation", }} name="industry" render={({ field, fieldState }) => (
                                                <FormControl fullWidth error={!!fieldState.error}>
                                                    <InputLabel id="event">
                                                        What industry sector does your organisation belong to?<span style={{ color: "red" }}>*</span>
                                                    </InputLabel>
                                                    <Select
                                                        style={{ backgroundColor: "#f0f0f0" }}
                                                        labelId="Country"
                                                        id="event"
                                                        label={
                                                            <Typography className="text-dark">
                                                                What industry sector does your organisation belong to?<span style={{ color: "red" }}>*</span>
                                                            </Typography>
                                                        }
                                                        value={field.value ? field.value : ""}
                                                        variant="outlined"
                                                        onChange={(e) => field.onChange(e.target.value)}
                                                        error={!!fieldState.error}
                                                    >
                                                        {industries.map((data) => (
                                                            <MenuItem key={data.id} value={data.id}>
                                                                {data.name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                    {errors.industry && (
                                                    <FormHelperText>{errors.industry.message}</FormHelperText>
                                                )}
                                                </FormControl>
                                            )} />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                InputLabelProps={{ shrink: true }}
                                                fullWidth
                                                label={
                                                    <Typography className="text-dark">
                                                        Where is your head office located in?
                                                    </Typography>
                                                }
                                                {...register("headOfficeLocated")}
                                                error={!!errors?.headOfficeLocated}
                                                helperText={errors?.headOfficeLocated?.message}
                                                placeholder="Where is your head office located in?"
                                            />
                                        </Grid>
                                        <Grid item xs={4} className="mt-4">
                                            <TextField
                                                InputLabelProps={{ shrink: true }}
                                                fullWidth
                                                label={
                                                    <Typography className="text-dark">
                                                        How many employees do you have approximately?
                                                    </Typography>
                                                }
                                                {...register("employees")}
                                                error={!!errors?.employees}
                                                helperText={errors?.employees?.message}
                                                placeholder="How many employees do you have approximately?"
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <div>
                                                <label htmlFor="file-upload" className="form-label mt-0">Upload the logo of your Organisation<span style={{ color: "red" }}>*</span></label>
                                                <input
                                                    className={`form-control ${errors.uploadOrganisationLogo ? 'is-invalid' : ''}`} // Apply Bootstrap styles
                                                    accept="image/*,"
                                                    id="file-upload"
                                                    type="file"
                                                    {...register("uploadOrganisationLogo", {
                                                        validate: validateFile, // Custom validation function
                                                    })}
                                                />
                                                <div><p className="text-secondary-new fs-6">(Your logo will be displayed on our website along with your organisation’s name. Please upload a clear JPG/JPEG/PNG only)</p></div>
                                                {errors.uploadOrganisationLogo && <div className="invalid-feedback">{errors.uploadOrganisationLogo.message}</div>} {/* Error message display */}
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Box>
                        )}
                        {activeStep === 1 && (
                            <div className="row p-4">
                                <div className="col-md-4 mb-3">
                                    <FormControl fullWidth error={!!errors.dummyPlanId}>
                                        <InputLabel>Membership Plan <span style={{ color: "red" }}>*</span></InputLabel>
                                        <Controller
                                            name="dummyPlanId"
                                            control={control}
                                            rules={{ required: "Please Select a Membership Plan" }}
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    label="Membership Type"
                                                    onChange={(e) => {
                                                        field.onChange(e);
                                                        setValue("dummyPlanTypeId", ""); // Reset the plan type if a new plan is selected
                                                    }}
                                                >
                                                    <MenuItem >
                                                        <em>Select a membership Plan</em>
                                                    </MenuItem>
                                                    {planName.map((data) => (
                                                        <MenuItem key={data?._id} value={data?._id}>
                                                            {data?.planName}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            )}
                                        />
                                        {errors.dummyPlanId && (
                                            <FormHelperText>{errors.dummyPlanId.message}</FormHelperText>
                                        )}
                                    </FormControl>
                                </div>
                                <div className="col-md-4">
                                    <FormControl className="mt-0"
                                        fullWidth
                                        error={!!errors.dummyPlanTypeId}
                                        disabled={!selectedPlanName}
                                        style={{ marginTop: 16 }}
                                    >
                                        <InputLabel>Membership Plan Type <span style={{ color: "red" }}>*</span></InputLabel>
                                        <Controller
                                            name="dummyPlanTypeId"
                                            control={control}
                                            rules={{
                                                required: selectedPlanName ? "Please Select a Membership Plan Type" : "Please Select a Membership Plan",
                                            }}
                                            render={({ field }) => (
                                                <Select {...field} label="Membership Plan">
                                                    <MenuItem >
                                                        <em>Select a membership plan type</em>
                                                    </MenuItem>
                                                    {selectedPlanName && costType.map((plan) => (
                                                        <MenuItem key={plan._id} value={plan._id}>
                                                            {plan.planTypeName} - ₹{plan.amount}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            )}
                                        />
                                        {errors?.dummyPlanTypeId && (
                                            <FormHelperText>{errors?.dummyPlanTypeId?.message}</FormHelperText>
                                        )}
                                    </FormControl>
                                </div>
                                <div className="col-md-4 ">
                                    <Controller
                                        control={control}
                                        rules={{ required: "Please Select a Chapter" }}
                                        name="chapter"
                                        render={({ field, fieldState }) => (
                                            <FormControl fullWidth>
                                                <InputLabel id="event">
                                                    Which chapter Would You Like To Join{" "}
                                                    <span style={{ color: "red" }}>*</span>
                                                </InputLabel>
                                                <Select
                                                    style={{ backgroundColor: "#f0f0f0" }}
                                                    labelId="event"
                                                    id="event"
                                                    label={
                                                        <Typography className="text-dark">
                                                            Chapter<span style={{ color: "red" }}>*</span>
                                                        </Typography>
                                                    }
                                                    value={field.value || ""}
                                                    variant="outlined"
                                                    onChange={(e) => field.onChange(e.target.value)}
                                                    error={!!fieldState.error}
                                                >
                                                    {selectedChapters.map((data) => (
                                                        <MenuItem key={data._id} value={data._id}>
                                                            {data.title}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        )}
                                    />
                                </div>
                                <div className="col-md-4">
                                    <Grid item xs={4}>
                                        <Controller
                                            control={control}
                                            name="sigGroups"
                                            rules={{
                                                validate: validateAtLeastOne,
                                            }}
                                            render={({ field, fieldState }) => (
                                                <Autocomplete
                                                    multiple
                                                    id="sig"
                                                    InputLabelProps={{ shrink: true }}
                                                    fullWidth
                                                    options={allSIG}
                                                    disableCloseOnSelect
                                                    getOptionLabel={(sig) => sig?.groupName}
                                                    filterSelectedOptions
                                                    getOptionSelected={(option, value) =>
                                                        option._id === value._id
                                                    }
                                                    onChange={(event, values) => {
                                                        const sigGroups = values.map(
                                                            (value) => value?._id
                                                        ); // Extract _id from selected options
                                                        field.onChange(sigGroups); // Pass array of selected _ids to onChange
                                                    }}
                                                    value={
                                                        field.value
                                                            ? field.value.map((id) =>
                                                                allSIG.find(
                                                                    (group) => group._id === id
                                                                )
                                                            )
                                                            : []
                                                    }
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            error={!!fieldState?.error}
                                                            helperText={fieldState?.error?.message}
                                                            label={
                                                                <Typography className="text-dark">
                                                                    SIG Groups{" "}
                                                                    <span style={{ color: "red" }}>
                                                                        *
                                                                    </span>
                                                                </Typography>
                                                            }
                                                            placeholder="Select SIG"
                                                        />
                                                    )}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </div>
                                <div className="col-md-4">
                                    <FormControl component="fieldset" error={!!errors.emailMessage}>
                                        <FormLabel component="legend">Would you like to receive updates from ELTAI by Email?</FormLabel>
                                        <Controller
                                            name="emailMessage"
                                            control={control}
                                            defaultValue=""
                                            rules={{ required: "Please select a option" }}
                                            render={({ field }) => (
                                                <RadioGroup row {...field}>
                                                    <FormControlLabel
                                                        value="1"
                                                        control={<Radio />}
                                                        label="Yes"
                                                    />
                                                    <FormControlLabel
                                                        value="0"
                                                        control={<Radio />}
                                                        label="No"
                                                    />
                                                </RadioGroup>
                                            )}
                                        />
                                        {errors.emailMessage && (
                                            <FormHelperText>{errors.emailMessage.message}</FormHelperText>
                                        )}
                                    </FormControl>
                                </div>
                                <div className="col-md-4">
                                    <div className="card ">
                                        <p className="fw-bold text-center p-3"> You have to Pay {(currency === 1) ? "$" : "₹"}{amount}</p>
                                    </div>
                                </div>
                            </div>
                        )}
                        <Box sx={{ display: "flex", flexDirection: "row", pt: 2, mt: 3 }}>
                            {activeStep !== 0 && (
                                <Button
                                    variant="contained"
                                    className="btn-color"
                                    onClick={handleBack}
                                    sx={{ mr: 1 }}
                                >
                                    Back
                                </Button>
                            )}
                            <Box sx={{ flex: "1 1 auto" }} />
                            <Button type="submit" variant="contained" className="btn-color">
                                {activeStep === steps.length - 1
                                    ? "Submit"
                                    : "Next"}
                            </Button>
                        </Box>
                    </form>
                </Box>
            )}
        </>
    );
}

export default AddEditCorporate;

