import React from "react";
import { Container } from "@mui/material";
import youtubebg from "../../assets/images/home/youtube/YTBG.png";
import videoFile from "../../videos/Eltai.mp4";
import "../../style/css/youtube.css";

function YouTubeSection() {
  return (
    <Container
      maxWidth="xl"
      className="youtubecontainer"
      style={{ backgroundImage: `url(${youtubebg})`, height: "450px",display:'flex' }}
    >
      <div className="row d-flex align-items-center justify-content-center">
        <div className="col-sm-12 col-lg-6 font d-block d-md-none">
          <div style={{ paddingTop: "10px", textAlign: "center", fontSize: "23px" }}>
            <p>What Does <span style={{ color: "#ed6b1b", fontWeight: "bold" }}>ELTAI</span> Stand For ?</p>
          </div>
        </div>
        <div className="col-sm-12 col-lg-6 font d-none d-xs-block d-md-none d-lg-block ">
          <div className="d-flex align-item-center justify-content-center">

            {/* <div>What</div>
            <div>
              Does{" "}
              <span style={{ color: "#ed6b1b", fontWeight: "bold" }}>
                ELTAI
              </span>
            </div>
            <div>STAND's For</div> */}
            <div className="row">
              <div className="col-12" style={{fontSize:'65px'}}>
                What
              </div>
              <div className="col-12" style={{fontSize:'65px'}}>
                <div>
                  Does{" "}
                  <span style={{ color: "#ed6b1b", fontWeight: "bold" }}>
                    ELTAI
                  </span>
                </div>
                <div>Stand For ?</div>
              </div>
              <div className="col-12">

              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-lg-6 font d-none d-md-block d-lg-none">
          <div style={{ paddingTop: "10px", textAlign: "center", fontSize: "23px" }}>
            <p>What Does <span style={{ color: "#ed6b1b", fontWeight: "bold" }}>ELTAI</span> Stand For ?</p>
          </div>
        </div>
        <div className="col-sm-12 col-lg-6">
          <div className="video" style={{ paddingBottom: "5px" }}>
            <video
              width="100%"
              height="auto"
              controls
              autoPlay
              muted
              style={{
                maxWidth: "100%",
                maxHeight: "100%",
              }}
            >
              <source src={videoFile} type="video/mp4" />
            </video>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default YouTubeSection;
