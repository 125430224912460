import React, { useState } from 'react'
import TopHeader from '../../../Component/Header/TopHeader';
import { useFieldArray, useForm, Controller } from 'react-hook-form';
import { useEffect } from 'react';
import { Container } from "react-bootstrap";
import { FormHelperText, TextField } from "@mui/material";
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSaveCategoryMutation, useLazyGetCategoryDetailsByIdQuery, useUpdateCategoryMutation } from '../../../app/services/categoryService';
import { useNavigate, useParams } from "react-router-dom";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select'; import { toast } from 'react-toastify';
import Spinner from '../../../Component/spinner/spinner';
import { Typography } from '@material-ui/core';

const Category = () => {
    const navigate = useNavigate();//route
    const [loading, setLoading] = useState(false);
    const [showHeader, seShowHeader] = useState(false);
    //categoryForm Initialization
    const { register, handleSubmit, setValue, watch, reset, control, formState: { errors } } = useForm({
        defaultValues: {
            categoryName: '',
            type: '',
            subCategory: [],
        }
    });
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'subCategory'
    })



    //adding Multiple Array
    const addSubCategory = () => {
        seShowHeader(true)
        append({})
    };

    // Removing Subcategory for each row except the first one
    const removeSubCategory = (index) => {
        remove(index)
    };

    const [saveCategory] = useSaveCategoryMutation();//API call for save
    const { categoryId } = useParams();//get Id from params
    const [categoryDetailByID] = useLazyGetCategoryDetailsByIdQuery();//API call of GET by ID
    const [updateCategory] = useUpdateCategoryMutation();//API call for update category

    //get by id
    useEffect(() => {
        const fetchCategoryData = async () => {
            try {
                setLoading(true);
                const response = await categoryDetailByID(categoryId);
                if (response.data.status) {
                    setLoading(false);
                    setValue('categoryName', response.data?.data?.categoryName);
                    setValue('type', response.data?.data?.type)
                    setValue("subCategory", response.data?.data?.subCategory);
                }
            } catch (error) {
                console.error('Error fetching chapter details:', error);
            }
        };
        if (categoryId) {
            fetchCategoryData(); // Call the function to fetch chapter data
        }
    }, [categoryId, categoryDetailByID, setValue]);
    const type = watch('type')

    //Form submit
    const handleFormSubmit = async (formData) => {
        setLoading(true);
        // Map subCategoryList to populate the subCategory array in the form data
        if (categoryId) {
            const data = { id: categoryId, formData }
            //update category
            updateCategory(data).then(res => {
                if (res.data.status) {
                    setLoading(false);
                    toast.success(res.data?.message)
                    navigate('/admin/viewCategory')
                    reset()
                }
                else {
                    toast.error(res.data?.err?.message)
                    setLoading(false);
                }
            })
        }
        else {
            //save Category
            saveCategory(formData).then(res => {
                if (res.data?.status) {
                    setLoading(false);
                    toast.success(res.data?.message)
                    navigate('/admin/viewCategory')
                    reset()
                }
                else {
                    toast.error(res.data?.err?.message)
                    setLoading(false);
                }
            })
        }
    }

    return (
        <div>
            <TopHeader />
            <div className='pt-1 '>
                <div className='p-0'>
                    <div className=' Architects-header d-flex align-items-center justify-content-center '>
                        <h2 className='signika-fontOEC text-center '>{categoryId ? "Edit Category" : "Add Category"}</h2>
                    </div>
                </div>
            </div>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-7'>
                        <span style={{ width: '160px' }}></span>
                    </div>
                    <div className='col-md-5 mt-3'>
                        <Button onClick={() => window.history.back()} variant="contained" className='menus-color' color="primary"
                            style={{ float: 'right' }}>Back</Button>
                    </div>
                </div>

            </div>
            {loading ? (
                <Spinner />
            ) : (
                <Container maxwidth="md" style={{ marginTop: '25px', marginBottom: '25px' }}>
                    <form onSubmit={handleSubmit(handleFormSubmit)}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
                            <TextField style={{ width: '45%' }} InputLabelProps={{ shrink: true }} id="title" label={(
                                <Typography className='text-dark'>
                                    Category Name <span style={{ color: 'red' }}>*</span>
                                </Typography>
                            )} variant="outlined" {...register('categoryName', { required: 'Please Enter a Category' })} error={!!errors?.categoryName} helperText={errors?.categoryName?.message} />
                           <Controller control={control} rules={{ required: "Please Select a Type", }} name="type" render={({ field, fieldState }) => (
                            <FormControl style={{ width: '45%' }} id="title">
                                <InputLabel id="type-label">Type{" "}<span style={{ color: 'red' }}>*</span></InputLabel>
                                <Select
                                    labelId="type-label"
                                    label={
                                        <Typography className="text-dark">
                                            Type <span style={{ color: "red" }}>*</span>
                                        </Typography>
                                    }
                                    id="type"
                                    value={type}                                    
                                    // {...register('type', { required: 'Please Select Type' })}
                                    onChange={(e) => field.onChange(e.target.value)}
                                    error={!!errors?.type}
                                    variant="outlined"
                                >
                                    <MenuItem value={0}>Job</MenuItem>
                                    <MenuItem value={1}>Forums</MenuItem>
                                </Select>
                                <FormHelperText className='text-danger'>{errors?.type?.message}</FormHelperText>
                            </FormControl>
                           )} />
                        </div>
                        <div className='mt-4'>
                            {showHeader && (<div>
                                <h3 className='heading_h3_bearers mt-4 '>Sub Category</h3>
                                <span className="animate-border-bearers border-black mb-4" style={{ width: '160px' }}></span>
                            </div>)}
                            {fields.map((subCategory, index) => (
                                <>
                                    <div key={subCategory?.id}>
                                        <div style={{ display: 'flex', marginBottom: '20px', marginTop: '20px' }}>
                                            <TextField
                                                style={{ width: '45%' }}
                                                InputLabelProps={{ shrink: true }}
                                                label={(
                                                    <Typography className='text-dark'>
                                                        Sub Category <span style={{ color: 'red' }}>*</span>
                                                    </Typography>
                                                )}
                                                {...register(`subCategory.${index}.subCategoryName`, { required: 'Please Enter a Sub Category' })}
                                                error={!!errors.subCategory?.[index]?.subCategoryName}
                                                helperText={errors.subCategory?.[index]?.subCategoryName?.message}
                                            />
                                            {index !== 0 && (
                                                <Button
                                                    style={{ marginLeft: '5px', color: 'red' }}
                                                    onClick={() => removeSubCategory(index)}
                                                >
                                                    <DeleteIcon />
                                                </Button>
                                            )}
                                        </div>
                                    </div>
                                </>
                            ))}
                            <Button variant="contained" className="menus-color" onClick={addSubCategory}>Add Sub Category</Button>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Button variant="contained" className="menus-color" type="submit">
                                {categoryId ? 'Update' : 'Submit'}</Button>
                        </div>
                    </form>
                </Container>
            )}

        </div>
    )
}

export default Category
