import React, { useEffect, useRef } from "react";
import "../../../src/style/css/footer.css";
import { Alert } from "@mui/material";
import { useForm } from "react-hook-form";
import Button from "@mui/material/Button";
import { useSaveMailMutation } from "../../app/services/subscription";
import logo from "../../assets/images/logos/ELTA@I Logo Source File2.png";
import bg from "../../assets/images/footer/footer.jpg";
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import XIcon from '@mui/icons-material/X';

function Footer() {
  const [saveSubscriptionMail] = useSaveMailMutation();
  const [successMessage, setSuccessMessage] = React.useState("");
  const sendButtonRef = useRef(null);

  const { register, handleSubmit, reset, watch } = useForm({
    defaultValues: {
      email: "",
    },
  });

  const saveMail = (FormData) => {
    saveSubscriptionMail(FormData).then((res) => {
      setSuccessMessage("Subscribed successfully.");
      reset();
      setTimeout(() => setSuccessMessage(false), 2000);
    });
  };

  useEffect(() => {
    const button = document.querySelector("#back-to-top button");
    button?.addEventListener("click", () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    });

    // Cleanup function to remove event listener when component unmounts
    return () => {
      button?.removeEventListener("click", () => {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      });
    };
  }, []);

  return (
    <footer style={{ backgroundImage: `url(${bg})`, backgroundSize: "cover", backgroundRepeat: "no-repeat", height: "auto" }}>
      <div className="row py-5 px-3">
        <div className="col-12 col-md-12 col-lg-4 d-flex justify-content-center">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3885.8350962890604!2d80.1966964779552!3d13.109631054262467!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a52646dcf38879d%3A0x66d3a4d2b75a093e!2sAnandam%20Flats%2C%201st%20Main%20Rd%2C%20South%20Jagannatha%20Nagar%2C%20Villivakkam%2C%20Chennai%2C%20Tamil%20Nadu%20600049!5e0!3m2!1sen!2sin!4v1708067003584!5m2!1sen!2sin" style={{ border: "0" }} loading="lazy" referrerPolicy="no-referrer-when-downgrade" title="footer"></iframe>
        </div>
        <div className="col-12 col-sm-6 col-md-6 col-lg-4 mt-5 px-md-5 px-lg-0 mt-lg-0">
          <div className="ms-4 ms-md-0">
            <h2 className=" signika-font fw-bold text-start text-light">Get in Touch</h2>
            <div className="row ">
              <div className="d-flex justify-content-start align-items-center mb-3">
                <div className="me-2"><EmailIcon className="text-light" /></div>
                <a
                  href="mailto:indiaeltai@gmail.com"
                  className="ps-0 signika-font text-light">
                  indiaeltai@gmail.com
                </a>
              </div>
              <div className="d-flex justify-content-start align-items-center mb-3">
                <div className="me-2">
                  <LocalPhoneIcon className="text-light" />
                </div>
                <a href="tel:+919344425159" className="ps-0 signika-font text-light">
                  +91-9344425159
                </a>
              </div>
              <div className="d-flex justify-content-start align-items-start">
                <div className="me-2">
                  <LocationOnIcon className="text-light" />
                </div>
                <div>
                  <p className="ps-0 signika-font mb-0 text-start text-light">
                    D-54 Third Floor,
                    <br /> Anandham Apartments,
                    <br /> 156, SIDCO Nagar Main Road,
                    <br /> Villivakkam, Chennai.
                    <br /> Tamilnadu, India - 600049.
                  </p>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div className="col-12 col-sm-6 col-md-6 col-lg-4 d-flex justify-content-center align-items-center ps-lg-5 mt-5 mt-lg-0">
          <div className="mt-4 mt-md-0 ps-lg-4">
            <img src={logo} alt="eltai logo" className="img-fluid" width="300px" />
            {successMessage && (
              <div className="row mb-2 d-flex justify-content-center w-100">
                <Alert style={{ width: "45%" }} variant="filled" severity="success">
                  {successMessage}
                </Alert>
              </div>
            )}
            <form action="#" onSubmit={handleSubmit(saveMail)} className="w-100 mt-3">
              <div className="form-row d-flex justify-content-center ">
                <div className="d-flex ">
                  <input
                    type="email"
                    className="p-2 subscription"
                    {...register("email", { required: true })}
                    placeholder="Subscribe Our News Letter"
                    style={{ color: "white", width: "100%", borderRadius: '5px 0px 0px 5px', background: '#0f3d20', border: '1px solid white' }}
                  />
                  <Button type="submit"
                    className="menus-color"
                    ref={sendButtonRef}
                    style={{
                      pointerEvents: watch('email') ? 'auto' : 'none', borderRadius: '0px 5px 5px 0px'// Disable click interaction when empty
                    }}>
                    <i className="fa fa-send text-light" ></i>
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="copyright" style={{ backgroundColor: "#1D924C" }}>
        <div className="row p-2 px-4">
          <div className="col-12 col-lg-6 text-lg-start text-center ">
            <span className="text-light ">
              Copyright © {new Date().getFullYear()} | Designed with by
              <a
                className="text-decoration-underline text-light p-0 ms-1"
                target="_blank"
                href="https://www.benhive.com"
                rel="noreferrer"
              >
                BenHive{" "}Technologies
              </a>
            </span>
          </div>
          <div className="col-12 col-lg-6 pt-2 pt-lg-0 d-flex justify-content-center justify-content-lg-end mt-1 ">
            <div className="d-flex justify-content-end" style={{ color: "#1d924c" }}>
              <ul className="d-flex list-unstyled mb-0 ul-foot">
                <li className="me-3"><a className='ps-0 a-className' href="https://www.facebook.com/ELTAI.India" target="blank"><i className="fa fa-facebook icon-config"></i></a></li>
                <li className="me-3"><a className='ps-0 a-className' href="https://www.instagram.com/eltai.india" target="blank"><i className="fa fa-instagram icon-config-insta"></i></a></li>
                <li className="me-3"><a className='ps-0 a-className' href="https://www.youtube.com/@ELTAIIndia" target="blank"><i className="fa fa-youtube icon-config-utube"></i></a></li>
                <li className="twitter-list"><a className='ps-0 a-className' href="https://x.com/EltaiIndia" target="blank"><XIcon className="social-media-footer-icon-header" /></a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer >
  );
}

export default Footer;
