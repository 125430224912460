import {
  Autocomplete,
  FormLabel,
  FormHelperText,
  TextField,
  Typography,
  FormControlLabel,
  Box,
  Button,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Checkbox
} from "@mui/material";
import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useForm, Controller } from "react-hook-form";
import Select from "@mui/material/Select";
import "../../style/css/eventForm.css";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import { toast } from "react-toastify";
import Spinner from "../../Component/spinner/spinner";
import { ContactInformationVisibility, Qualifications1, EligibilityExaminations, ProfessionalDevelopment, InterdisciplinaryAreas, EnglishLanguageTeaching, Linguistics, LiteraryStudies, OTHER_QUALIFICATIONS } from '../../constant/constant'
import { Country, State } from 'country-state-city';
import { useLazyGetMemberDetailsByIdQuery, useUpdateMemberMutation } from '../../app/services/memberServices'
import { selectUserId } from "../../app/auth/authSlice";
import { useSelector, useDispatch } from "react-redux";
import { setMemberDetails } from '../../app/features/memberSlice'
import { useParams, useNavigate } from "react-router-dom";

function Profile() {
  const [showStudyingOtherInput, setShowStudyingOtherInput] = useState(false);
  const [showEligibilityOtherInput, setShowEligibilityOtherInput] = useState(false);
  const [showProfessionOtherInput, setShowProfessionOtherInput] = useState(false);
  const [showLiteraryOtherInput, setShowLiteraryOtherInput] = useState(false);
  const [showLinguisticsOtherInput, setShowLinguisticsOtherInput] = useState(false);
  const [showELTOtherInput, setShowELTOtherInput] = useState(false);
  const [showInterOtherInput, setShowInterOtherInput] = useState(false);
  const navigate = useNavigate()

  //Student Contact Details
  /* ========================= */
  const [loading, setLoading] = useState(false);
  const userId = useSelector(selectUserId);
  const [gettMemberDetailsByIdQuery] = useLazyGetMemberDetailsByIdQuery();
  const [updateMember] = useUpdateMemberMutation();
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [getStudentDetails, setGetStudentDetails] = useState()
  const dispatch = useDispatch()
  const { key } = useParams();

  // useForm hook for form data handling
  const {
    control: controlContact,
    register: registerContact,
    handleSubmit: handleSubmitContact,
    setValue: setValueContact,
    formState: { errors: errorsContact },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      type: '1',
      address: "",
      city: "",
      pinCode: "",
      state: "",
      country: "",
      mobileNumber: '',
      whatsAppNumber: '',
      email: "",
      landlineNumber: '',
      contactInformation: "",
      role: "PORTAL_MEMBER",
    },
  });

  const countries = Country.getAllCountries();
  const states = selectedCountry ? State.getStatesOfCountry(selectedCountry) : [];

  // Update states whenever selectedCountry changes
  useEffect(() => {
    if (selectedCountry) {
      const fetchedStates = State.getStatesOfCountry(selectedCountry);
      setSelectedState(fetchedStates);
      // If we have getStudentDetails, set the state based on it
      if (getStudentDetails?.state) {
        setValueContact("state", getStudentDetails.state);
        setSelectedState(getStudentDetails.state); // Set the selected state
      } else {
        setValueContact("state", ""); // Reset state if no state exists
      }
    }
  }, [selectedCountry, setValueContact, getStudentDetails]);

  useEffect(() => {
    gettMemberDetailsByIdQuery(userId).then((res) => {
      if (res?.data?.status) {
        setGetStudentDetails(res?.data?.data)
      }
    })
  }, [gettMemberDetailsByIdQuery, userId])

  useEffect(() => {
  }, [getStudentDetails])

  useEffect(() => {
    if (getStudentDetails) {
      setValueContact("address", getStudentDetails.address)
      setValueContact("city", getStudentDetails.city)
      setValueContact("pinCode", getStudentDetails.pinCode)
      setValueContact("country", getStudentDetails.country)
      setSelectedCountry(getStudentDetails.country);
      setValueContact("mobileNumber", getStudentDetails.mobileNumber)
      setValueContact("whatsAppNumber", getStudentDetails.whatsAppNumber)
      setValueContact("email", getStudentDetails.email)
      setValueContact("landlineNumber", getStudentDetails.landlineNumber)
      setValueContact("contactInformation", getStudentDetails.contactInformation)
    }
  }, [getStudentDetails, setValueContact])

  //On submit for update
  const UpdateStudentMemberContact = (data, userId) => {
    setLoading(false)
    const formData = new FormData();
    // Iterate over the keys and values of the data object
    Object.entries(data).forEach(([key, value]) => {
      if (key !== 'contactInformation' && value !== undefined && value !== null) {
        formData.append(key, value);
      }
    });
    if (data.contactInformation && data.contactInformation.length > 0) {
      data.contactInformation.forEach(item => {
        formData.append("contactInformation", item)
      })
    }
    const updateData = { id: userId, formData }
    updateMember(updateData).then((res) => {
      if (res?.data?.data) {
        toast.success(res?.data?.message);
        navigate(`/member/student-member/${userId}`)
        gettMemberDetailsByIdQuery(userId).then((res) => {
          if (res?.data?.status) {
            dispatch(setMemberDetails(res?.data?.data))
          }
        })
      }
      else {
        toast.error(res?.data?.err?.message);
      }
    }).catch((err) => {
      console.error(err)
    })
  }

  //Student Academic Profile Links
  /* ========================= */

  // useForm hook for form data handling
  const {
    control: controlAcademic,
    register: registerAcademic,
    handleSubmit: handleSubmitAcademic,
    setValue: setValueAcademic,
    formState: { errors: errorsAcademic },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      type: '1',
      scholarProfile: "",
      scopusProfileLink: "",
      ORICID: "",
      linkedInProfile: "",
      blog: "",
      youtubeChannel: '',
      shareInformation: '',
      role: "PORTAL_MEMBER",
    },
  });

  useEffect(() => {
    gettMemberDetailsByIdQuery(userId).then((res) => {
      if (res?.data?.status) {
        setGetStudentDetails(res?.data?.data)
      }
    })
  }, [gettMemberDetailsByIdQuery, userId])

  useEffect(() => {
  }, [getStudentDetails])

  useEffect(() => {
    if (getStudentDetails) {
      setValueAcademic("scholarProfile", getStudentDetails.scholarProfile)
      setValueAcademic("scopusProfileLink", getStudentDetails.scopusProfileLink)
      setValueAcademic("ORICID", getStudentDetails.ORICID)
      setValueAcademic("linkedInProfile", getStudentDetails.linkedInProfile)
      setValueAcademic("blog", getStudentDetails.blog)
      setValueAcademic("youtubeChannel", getStudentDetails.youtubeChannel)
      setValueAcademic("shareInformation", getStudentDetails.shareInformation)
    }
  }, [getStudentDetails, setValueAcademic])

  //On submit for update
  const UpdateStudentMemberAcademic = (data, userId) => {
    setLoading(false)
    const formData = new FormData();
    // Iterate over the keys and values of the data object
    Object.entries(data).forEach(([key, value]) => {
      if (value !== undefined && value !== null) {
        formData.append(key, value);
      }
    });
    const updateData = { id: userId, formData }
    updateMember(updateData).then((res) => {
      if (res?.data?.data) {
        toast.success(res?.data?.message);
        navigate(`/member/student-member/${userId}`)
        if (res.data.status) {
          gettMemberDetailsByIdQuery(userId).then((res) => {
            if (res?.data?.status) {
              dispatch(setMemberDetails(res?.data?.data))
            }
          })
        }
      }
      else {
        toast.error(res?.data?.err?.message);
      }
    }).catch((err) => {
      console.error(err)
    })
  }

  //Student Educational Details
  /* ========================= */

  const [otherStudying, setOtherStudying] = useState('');
  const [otherEligibility, setOtherEligibility] = useState('');

  // useForm hook for form data handling
  const {
    control: controlEducation,
    register: registerEducation,
    handleSubmit: handleSubmitEducation,
    setValue: setValueEducation,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      type: '1',
      studying: "",
      otherStudying: "",
      eligibilityExamination: "",
      institution: "",
      addressInstitution: "",
      role: "PORTAL_MEMBER",
    },
  });

  const eligibilityOptions = useMemo(() => EligibilityExaminations.map(exam => exam.name), []);

  // Memoizing the options arrays
  const memoizedEligibilityOptions = useMemo(() => eligibilityOptions, [eligibilityOptions]);

  //Get all member details
  const getMemberDetails = useCallback(
    () => {
      gettMemberDetailsByIdQuery(userId).then((res) => {
        if (res?.data?.status) {
          const studentDetails = res?.data?.data;
          setGetStudentDetails(studentDetails);

          const studying = studentDetails?.studying;
          const isOtherStudying = studying === OTHER_QUALIFICATIONS ? true : false;
          setOtherStudying(isOtherStudying ? studentDetails?.otherStudying : '');
          setShowStudyingOtherInput(isOtherStudying);

          const eligibilityExams = studentDetails?.eligibilityExamination || [];
          const otherText = eligibilityExams.find(item => !memoizedEligibilityOptions.includes(item));
          setOtherEligibility(otherText || '');
          setShowEligibilityOtherInput(!!otherText);

          setValueEducation('otherEligibility', otherText);
        }
      });
    }, [gettMemberDetailsByIdQuery, userId, memoizedEligibilityOptions, setValueEducation]
  )

  //getAllNews
  useEffect(() => {
    getMemberDetails()
  }, [getMemberDetails])

  const handleOtherEligibilityChange = (e) => {
    const inputValue = e.target.value;
    setOtherEligibility(inputValue);
    if (inputValue && !eligibilityOptions.includes(inputValue)) {
      setShowEligibilityOtherInput(true);
    }
  };

  useEffect(() => {
  }, [getStudentDetails])

  useEffect(() => {
    if (getStudentDetails) {
      setValueEducation("studying", getStudentDetails.studying)
      setValueEducation("otherStudying", getStudentDetails.otherStudying)
      setValueEducation("eligibilityExamination", getStudentDetails.eligibilityExamination)
      setValueEducation("institution", getStudentDetails.institution)
      setValueEducation("addressInstitution", getStudentDetails.addressInstitution)
    }
  }, [getStudentDetails, setValueEducation])

  //On submit for update
  const UpdateStudentMemberEducation = (data, userId) => {
    setLoading(false)
    const formData = new FormData();
    // Iterate over the keys and values of the data object
    Object.entries(data).forEach(([key, value]) => {
      if (key !== 'eligibilityExamination' && key !== 'otherEligibility' && key !== 'studying' && key !== 'otherInputValue' && key !== "otherStudying" && value !== undefined && value !== null) {
        formData.append(key, value);
      }
    });
    let updatedEligibilityExams = [...data.eligibilityExamination];
    const otherInputIndex = updatedEligibilityExams.findIndex(value => value === 'Other')
    if (otherInputIndex !== -1 && otherEligibility.trim() !== "") {
      updatedEligibilityExams[updatedEligibilityExams.length] = otherEligibility
    }
    updatedEligibilityExams.filter(item => item.trim() !== "").forEach(item => {
      formData.append("eligibilityExamination", item);
    });

    // Handle studying and otherStudying
    const { studying } = data;
    formData.append("studying", studying);
    if (showStudyingOtherInput && otherStudying) {
      formData.append("otherStudying", otherStudying);
    } else {
      formData.append("otherStudying", "");
    }

    const updateData = { id: userId, formData }
    updateMember(updateData).then((res) => {
      if (res?.data?.data) {
        toast.success(res?.data?.message);
        navigate(`/member/student-member/${userId}`)
        gettMemberDetailsByIdQuery(userId).then((res) => {
          if (res?.data?.status) {
            dispatch(setMemberDetails(res?.data?.data))
          }
        })
      }
      else {
        toast.error(res?.data?.err?.message);
      }
    }).catch((err) => {
      console.error(err)
    })
  }

  //Student Professional Development
  /* ========================= */

  const [otherProfession, setOtherProfession] = useState('');
  const [otherLit, setOtherLit] = useState('');
  const [otherLing, setOtherLing] = useState('');
  const [otherELT, setOtherELT] = useState('');
  const [otherInter, setOtherInter] = useState('');

  // useForm hook for form data handling
  const {
    control: controlProfession,
    register: registerProfession,
    handleSubmit: handleSubmitProfession,
    setValue: setValueProfession,
    watch: watchProfession,
    errors: errorsProfession,
    trigger
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      type: '1',
      teacherAssociation: "",
      associationName: "",
      professionalDevelopment: "",
      expertiseLiteraryStudies: "",
      expertiseLinguistics: "",
      expertiseELT: "",
      expertiseInterdisciplinaryAreas: "",
      areaOfExpertise: "",
      role: "PORTAL_MEMBER",
    },
  });

  const professionOptions = useMemo(() => ProfessionalDevelopment.map(exam => exam.name), []);
  const litOptions = useMemo(() => LiteraryStudies.map(exam => exam.name), []);
  const lingOptions = useMemo(() => Linguistics.map(exam => exam.name), []);
  const ELTOptions = useMemo(() => EnglishLanguageTeaching.map(exam => exam.name), []);
  const interOptions = useMemo(() => InterdisciplinaryAreas.map(exam => exam.name), []);

  // Memoizing the options arrays
  const memoizedProfessionOptions = useMemo(() => professionOptions, [professionOptions]);
  const memoizedLitOptions = useMemo(() => litOptions, [litOptions]);
  const memoizedLingOptions = useMemo(() => lingOptions, [lingOptions]);
  const memoizedELTOptions = useMemo(() => ELTOptions, [ELTOptions]);
  const memoizedInterOptions = useMemo(() => interOptions, [interOptions]);

  const getMemberDetailsById = useCallback(() => {
    gettMemberDetailsByIdQuery(userId).then((res) => {
      if (res?.data?.status) {
        const studentDetails = res?.data?.data;
        setGetStudentDetails(studentDetails);

        const professionExams = studentDetails?.professionalDevelopment || [];
        const otherText = professionExams.find(item => !memoizedProfessionOptions.includes(item));
        setOtherProfession(otherText || '');
        setShowProfessionOtherInput(!!otherText);

        setValueProfession('otherProfession', otherText);

        const LitExams = studentDetails?.expertiseLiteraryStudies || [];
        const otherLitText = LitExams.find(item => !memoizedLitOptions.includes(item));
        setOtherLit(otherLitText || '');
        setShowLiteraryOtherInput(!!otherLitText);

        setValueProfession('otherLit', otherLitText);

        const LingExams = studentDetails?.expertiseLinguistics || [];
        const otherLingText = LingExams.find(item => !memoizedLingOptions.includes(item));
        setOtherLing(otherLingText || '');
        setShowLinguisticsOtherInput(!!otherLingText);

        setValueProfession('otherLing', otherLingText);

        const ELTExams = studentDetails?.expertiseELT || [];
        const otherELTText = ELTExams.find(item => !memoizedELTOptions.includes(item));
        setOtherELT(otherELTText || '');
        setShowELTOtherInput(!!otherELTText);

        setValueProfession('otherELT', otherELTText);

        const interExams = studentDetails?.expertiseInterdisciplinaryAreas || [];
        const otherInterText = interExams.find(item => !memoizedInterOptions.includes(item));
        setOtherInter(otherInterText || '');
        setShowInterOtherInput(!!otherInterText);

        setValueProfession('otherInter', otherInterText);
      }
    });
  }, [gettMemberDetailsByIdQuery, userId, memoizedProfessionOptions, memoizedLitOptions, memoizedLingOptions, memoizedELTOptions, memoizedInterOptions, setValueProfession])

  //Get member details by id
  useEffect(() => {
    getMemberDetailsById()
  }, [getMemberDetailsById])

  const handleOtherProfessionChange = (e) => {
    const inputValue = e.target.value;
    setOtherProfession(inputValue);
    if (inputValue && !professionOptions.includes(inputValue)) {
      setShowProfessionOtherInput(true);
    }
  };

  const handleOtherLitChange = (e) => {
    const inputValue = e.target.value;
    setOtherLit(inputValue);
    if (inputValue && !litOptions.includes(inputValue)) {
      setShowLiteraryOtherInput(true);
    }
  };

  const handleOtherLingChange = (e) => {
    const inputValue = e.target.value;
    setOtherLing(inputValue);
    if (inputValue && !lingOptions.includes(inputValue)) {
      setShowLinguisticsOtherInput(true);
    }
  };

  const handleOtherELTChange = (e) => {
    const inputValue = e.target.value;
    setOtherELT(inputValue);
    if (inputValue && !ELTOptions.includes(inputValue)) {
      setShowELTOtherInput(true);
    }
  };

  const handleOtherInterChange = (e) => {
    const inputValue = e.target.value;
    setOtherInter(inputValue);
    if (inputValue && !interOptions.includes(inputValue)) {
      setShowInterOtherInput(true);
    }
  };

  useEffect(() => {
  }, [getStudentDetails])

  useEffect(() => {
    if (getStudentDetails) {
      setValueProfession("teacherAssociation", getStudentDetails.teacherAssociation)
      if (getStudentDetails.teacherAssociation === 1) {
        trigger("associationName"); // Ensure validation is triggered when editing if "Yes" is selected
      }
      setValueProfession("associationName", getStudentDetails.associationName)
      setValueProfession("professionalDevelopment", getStudentDetails.professionalDevelopment)
      setValueProfession("expertiseLiteraryStudies", getStudentDetails.expertiseLiteraryStudies)
      setValueProfession("expertiseLinguistics", getStudentDetails.expertiseLinguistics)
      setValueProfession("expertiseELT", getStudentDetails.expertiseELT)
      setValueProfession("expertiseInterdisciplinaryAreas", getStudentDetails.expertiseInterdisciplinaryAreas)
      setValueProfession("areaOfExpertise", getStudentDetails.areaOfExpertise)
    }
  }, [getStudentDetails, setValueProfession, trigger])

  // Watch the value of the radio group
  const isTeachersAsso = watchProfession("teacherAssociation");

  // Automatically trigger validation on associationName when teacherAssociation changes
  useEffect(() => {
    if (isTeachersAsso === 1) {
      trigger("associationName"); // Trigger validation for associationName
    } else if (isTeachersAsso === 0) {
      setValueProfession("associationName", ""); // Clear the associationName field when "No" is selected
    }
  }, [isTeachersAsso, trigger, setValueProfession]);

  //On submit for update
  const UpdateStudentMemberProfession = (data, userId) => {
    setLoading(false)
    const formData = new FormData();
    // Iterate over the keys and values of the data object
    Object.entries(data).forEach(([key, value]) => {
      if (key !== 'professionalDevelopment' && key !== 'expertiseLiteraryStudies' && key !== 'expertiseLinguistics' && key !== 'expertiseELT' && key !== 'expertiseInterdisciplinaryAreas' && key !== 'otherProfession' && key !== 'otherLit' && key !== 'otherLing' && key !== 'otherELT' && key !== 'otherInter' && value !== undefined && value !== null) {
        formData.append(key, value);
      }
    });
    let updatedProfessionExams = [...data.professionalDevelopment];
    const otherInputIndex = updatedProfessionExams.findIndex(value => value === 'Other')
    if (otherInputIndex !== -1 && otherProfession.trim() !== "") {
      updatedProfessionExams[updatedProfessionExams.length] = otherProfession
    }
    updatedProfessionExams.filter(item => item.trim() !== "").forEach(item => {
      formData.append("professionalDevelopment", item);
    });

    let updatedLitExams = [...data.expertiseLiteraryStudies];
    const otherInputLitIndex = updatedLitExams.findIndex(value => value === 'Other')
    if (otherInputLitIndex !== -1 && otherLit.trim() !== "") {
      updatedLitExams[updatedLitExams.length] = otherLit
    }
    updatedLitExams.filter(item => item.trim() !== "").forEach(item => {
      formData.append("expertiseLiteraryStudies", item);
    });

    let updatedLingExams = [...data.expertiseLinguistics];
    const otherInputLingIndex = updatedLingExams.findIndex(value => value === 'Other')
    if (otherInputLingIndex !== -1 && otherLing.trim() !== "") {
      updatedLingExams[updatedLingExams.length] = otherLing
    }
    updatedLingExams.filter(item => item.trim() !== "").forEach(item => {
      formData.append("expertiseLinguistics", item);
    });

    let updatedELTExams = [...data.expertiseELT];
    const otherInputELTIndex = updatedELTExams.findIndex(value => value === 'Other')
    if (otherInputELTIndex !== -1 && otherELT.trim() !== "") {
      updatedELTExams[updatedELTExams.length] = otherELT
    }
    updatedELTExams.filter(item => item.trim() !== "").forEach(item => {
      formData.append("expertiseELT", item);
    });

    let updatedInterExams = [...data.expertiseInterdisciplinaryAreas];
    const otherInputInterIndex = updatedInterExams.findIndex(value => value === 'Other')
    if (otherInputInterIndex !== -1 && otherInter.trim() !== "") {
      updatedInterExams[updatedInterExams.length] = otherInter
    }
    updatedInterExams.filter(item => item.trim() !== "").forEach(item => {
      formData.append("expertiseInterdisciplinaryAreas", item);
    });

    const updateData = { id: userId, formData }
    updateMember(updateData).then((res) => {
      if (res?.data?.data) {
        toast.success(res?.data?.message);
        navigate(`/member/student-member/${userId}`)
        gettMemberDetailsByIdQuery(userId).then((res) => {
          if (res?.data?.status) {
            dispatch(setMemberDetails(res?.data?.data))
          }
        })
      }
      else {
        toast.error(res?.data?.err?.message);
      }
    }).catch((err) => {
      console.error(err)
    })
  }

  return (
    <>
      {/* Student Contact Details */}
      <>
        {key === "contact" && (
          <Box>
            <div className="pt-1 pb-3">
              <div className="p-0">
                <div className="Architects-header d-flex align-items-center justify-content-center">
                  <h2 className="signika-fontOEC text-center">Contact Details</h2>
                </div>
              </div>
            </div>
            <div className="container mt-3">
              <div className="row">
                <div className="col-md-8"></div>
                <div className="col-md-4 mt-3">
                  <Button
                    onClick={() => window.history.back()}
                    variant="contained"
                    className="menus-color"
                    style={{ float: "right", marginRight: 58 }}
                  >
                    Back
                  </Button>
                </div>
              </div>
            </div>

            {loading ? (
              <Spinner />
            ) : (
              <Box className="container" sx={{ width: "100%", marginTop: "0px", marginBottom: "50px" }}>
                <form
                  className="mt-2"
                  onSubmit={handleSubmitContact((data) => UpdateStudentMemberContact(data, userId))}
                >
                  <Box>
                    <div style={{ paddingLeft: 40, paddingRight: 40 }}>
                      <Grid container rowSpacing={3} columnSpacing={5}>
                        <Grid item xs={12}></Grid>

                        <Grid item xs={4}>
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            label={<Typography className="text-dark">Address <span style={{ color: "red" }}>*</span></Typography>}
                            {...registerContact("address", { required: "Please Enter an Address" })}
                            error={!!errorsContact?.address}
                            helperText={errorsContact?.address?.message}
                            placeholder="Address"
                          />
                        </Grid>

                        <Grid item xs={4}>
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            label={<Typography className="text-dark">Mobile Number <span style={{ color: "red" }}>*</span></Typography>}
                            {...registerContact("mobileNumber", { required: "Please Enter a Mobile Number" })}
                            error={!!errorsContact?.mobileNumber}
                            helperText={errorsContact?.mobileNumber?.message}
                            placeholder="Mobile Number"
                          />
                        </Grid>

                        <Grid item xs={4}>
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            label={<Typography className="text-dark">City/Town <span style={{ color: "red" }}>*</span></Typography>}
                            {...registerContact("city", { required: "Please Enter a City/Town" })}
                            error={!!errorsContact?.city}
                            helperText={errorsContact?.city?.message}
                            placeholder="City/Town"
                          />
                        </Grid>

                        <Grid item xs={4}>
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            label={<Typography className="text-dark">WhatsApp Number <span style={{ color: "red" }}>*</span></Typography>}
                            {...registerContact("whatsAppNumber", { required: "Please Enter a WhatsApp Number" })}
                            error={!!errorsContact?.whatsAppNumber}
                            helperText={errorsContact?.whatsAppNumber?.message}
                            placeholder="WhatsApp Number"
                          />
                        </Grid>

                        <Grid item xs={4}>
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            label={<Typography className="text-dark">PIN Code <span style={{ color: "red" }}>*</span></Typography>}
                            {...registerContact("pinCode", { required: "Please Enter a PIN Code" })}
                            error={!!errorsContact?.pinCode}
                            helperText={errorsContact?.pinCode?.message}
                            placeholder="PIN Code"
                          />
                        </Grid>

                        <Grid item xs={4}>
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            label={<Typography className="text-dark">Email Address <span style={{ color: "red" }}>*</span></Typography>}
                            {...registerContact("email", { required: "Please Enter an Email" })}
                            error={!!errorsContact?.email}
                            helperText={errorsContact?.email?.message}
                            placeholder="Email Address"
                            disabled
                          />
                        </Grid>

                        <Grid item xs={4}>
                          <FormControl fullWidth margin="normal" className="mt-0" disabled={!selectedCountry} error={!!errorsContact.state}>
                            <InputLabel>State <span style={{ color: "red" }}>*</span></InputLabel>
                            <Controller
                              name="state"
                              control={controlContact}
                              defaultValue=""
                              rules={{ required: selectedCountry ? "State is required" : false }}
                              render={({ field }) => (
                                <Select
                                  {...field}
                                  value={selectedState}
                                  onChange={(e) => {
                                    setSelectedState(e.target.value);
                                    field.onChange(e);
                                  }}
                                >
                                  {states.map((state) => (
                                    <MenuItem key={state.isoCode} value={state.name}>
                                      {state.name}
                                    </MenuItem>
                                  ))}
                                </Select>
                              )}
                              disabled
                            />
                            {errorsContact.state && <FormHelperText>{errorsContact.state.message}</FormHelperText>}
                          </FormControl>
                        </Grid>

                        <Grid item xs={4}>
                          <FormControl fullWidth margin="normal" className="mt-0" error={!!errorsContact.country}>
                            <InputLabel>Country <span style={{ color: "red" }}>*</span></InputLabel>
                            <Controller
                              name="country"
                              control={controlContact}
                              defaultValue=""
                              rules={{ required: "Country is required" }}
                              render={({ field }) => (
                                <Select
                                  {...field}
                                  value={selectedCountry}
                                  onChange={(e) => {
                                    setSelectedCountry(e.target.value);
                                    field.onChange(e);
                                  }}
                                >
                                  {countries.map((country) => (
                                    <MenuItem key={country.isoCode} value={country.isoCode}>
                                      {country.name}
                                    </MenuItem>
                                  ))}
                                </Select>
                              )}
                              disabled
                            />
                            {errorsContact.country && <FormHelperText>{errorsContact.country.message}</FormHelperText>}
                          </FormControl>
                        </Grid>

                        <Grid item xs={4}>
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ type: "number" }}
                            fullWidth
                            label={<Typography className="text-dark">Telephone Number <span style={{ color: "red" }}>*</span></Typography>}
                            {...registerContact("landlineNumber", { required: "Please Enter a Telephone Number" })}
                            error={!!errorsContact?.landlineNumber}
                            helperText={errorsContact?.landlineNumber?.message}
                            placeholder="Telephone Number"
                          />
                        </Grid>

                        <Grid item xs={4}>
                          <Controller
                            control={controlContact}
                            name="contactInformation"
                            render={({ field, fieldState }) => (
                              <Autocomplete
                                multiple
                                id="contactInformation"
                                fullWidth
                                options={ContactInformationVisibility}
                                disableCloseOnSelect
                                getOptionLabel={(option) => option?.name}
                                filterSelectedOptions
                                onChange={(event, values) => {
                                  const selectedValues = Array.isArray(values) ? values : [];
                                  const contactInfoNames = selectedValues.map((value) => value?.name);
                                  field.onChange(contactInfoNames);
                                }}
                                value={
                                  field.value ?
                                    ContactInformationVisibility.filter((info) => field.value.includes(info.name)) : []
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    error={!!fieldState?.error}
                                    helperText={fieldState?.error?.message}
                                    label={<Typography className="text-dark">Contact Information Visibility</Typography>}
                                    placeholder="Select Contact Information Visibility"
                                  />
                                )}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </div>
                  </Box>

                  <Box sx={{ display: "flex", flexDirection: "row", pt: 2, mt: 3 }}>
                    <Box sx={{ flex: "1 1 auto" }} />
                    <Button type="submit" variant="contained" className="menus-color">
                      Update
                    </Button>
                  </Box>
                </form>
              </Box>
            )}
          </Box>
        )}
      </>

      {/* Student Academic Profile Links */}
      <>
        {key === "academic" && (
          <>
            <Box>
              <div className='pt-1 pb-3'>
                <div className='p-0'>
                  <div className='Architects-header d-flex align-items-center justify-content-center'>
                    <h2 className='signika-fontOEC text-center'>Academic Profile Links</h2>
                  </div>
                </div>
              </div>
            </Box>
            <div className="container mt-3">
              <div className="row">
                <div className="col-md-8"></div>
                <div className="col-md-4 mt-3">
                  <Button
                    onClick={() => window.history.back()}
                    variant="contained"
                    className="menus-color"
                    style={{ float: "right", marginRight: 58 }}
                  >
                    Back
                  </Button>
                </div>
              </div>
            </div>
            {loading ? (
              <Spinner />
            ) : (
              <Box
                className="container"
                sx={{ width: "100%", marginTop: "0px", marginBottom: "50px" }}
              >
                <form className="mt-2" onSubmit={handleSubmitAcademic((data) => UpdateStudentMemberAcademic(data, userId))}>
                  <Box>
                    <div style={{ paddingLeft: 40, paddingRight: 40 }}>
                      <Grid container rowSpacing={3} columnSpacing={5}>
                        <Grid item xs={12}></Grid>

                        <Grid item xs={4}>
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            label={<Typography className="text-dark">Your Google Scholar Profile</Typography>}
                            {...registerAcademic("scholarProfile")}
                            error={!!errorsAcademic?.scholarProfile}
                            helperText={errorsAcademic?.scholarProfile?.message}
                            placeholder="Google Scholar Profile"
                          />
                        </Grid>

                        <Grid item xs={4}>
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            label={<Typography className="text-dark">Your Scopus Profile Link</Typography>}
                            {...registerAcademic("scopusProfileLink")}
                            error={!!errorsAcademic?.scopusProfileLink}
                            helperText={errorsAcademic?.scopusProfileLink?.message}
                            placeholder="Scopus Profile Link"
                          />
                        </Grid>

                        <Grid item xs={4}>
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            label={<Typography className="text-dark">Your ORICID Id</Typography>}
                            {...registerAcademic("ORICID")}
                            error={!!errorsAcademic?.ORICID}
                            helperText={errorsAcademic?.ORICID?.message}
                            placeholder="ORICID Id"
                          />
                        </Grid>

                        <Grid item xs={4}>
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            label={<Typography className="text-dark">Your LinkedIn Profile</Typography>}
                            {...registerAcademic("linkedInProfile")}
                            error={!!errorsAcademic?.linkedInProfile}
                            helperText={errorsAcademic?.linkedInProfile?.message}
                            placeholder="LinkedIn Profile"
                          />
                        </Grid>

                        <Grid item xs={4}>
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            label={<Typography className="text-dark">Your Blog/Website</Typography>}
                            {...registerAcademic("blog")}
                            error={!!errorsAcademic?.blog}
                            helperText={errorsAcademic?.blog?.message}
                            placeholder="Blog/Website"
                          />
                        </Grid>

                        <Grid item xs={4}>
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            label={<Typography className="text-dark">Your Youtube Channel</Typography>}
                            {...registerAcademic("youtubeChannel")}
                            error={!!errorsAcademic?.youtubeChannel}
                            helperText={errorsAcademic?.youtubeChannel?.message}
                            placeholder="Youtube Channel"
                          />
                        </Grid>

                        <Grid item xs={4}>
                          <FormControl fullWidth margin="normal" className="mt-0" error={!!errorsAcademic.shareInformation}>
                            <FormLabel component="legend">Consent To Share Information</FormLabel>
                            <Controller
                              name="shareInformation"
                              control={controlAcademic}
                              defaultValue=""
                              render={({ field }) => (
                                <RadioGroup
                                  {...field}
                                  row
                                  onChange={(e) => field.onChange(e.target.value)}
                                >
                                  <FormControlLabel value="1" control={<Radio />} label="Yes" />
                                  <FormControlLabel value="0" control={<Radio />} label="No" />
                                </RadioGroup>
                              )}
                            />
                            {errorsAcademic.shareInformation && (
                              <FormHelperText>{errorsAcademic.shareInformation.message}</FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                      </Grid>
                    </div>
                  </Box>

                  <Box sx={{ display: "flex", flexDirection: "row", pt: 2, mt: 3 }}>
                    <Box sx={{ flex: "1 1 auto" }} />
                    <Button type="submit" variant="contained" className="menus-color">
                      Update
                    </Button>
                  </Box>
                </form>
              </Box>
            )}
          </>
        )}
      </>

      {/* Student Educational Details */}
      <>
        {key === "education" && (
          <>
            <Box>
              <div className="pt-1 pb-3">
                <div className="p-0">
                  <div className="Architects-header d-flex align-items-center justify-content-center">
                    <h2 className="signika-fontOEC text-center">Educational Details</h2>
                  </div>
                </div>
              </div>
            </Box>
            <div className="container mt-3">
              <div className="row">
                <div className="col-md-8"></div>
                <div className="col-md-4 mt-3">
                  <Button
                    onClick={() => window.history.back()}
                    variant="contained"
                    className="menus-color"
                    style={{ float: "right", marginRight: 58 }}
                  >
                    Back
                  </Button>
                </div>
              </div>
            </div>
            {loading ? (
              <Spinner />
            ) : (
              <Box className="container" sx={{ width: "100%", marginTop: "0px", marginBottom: "50px" }}>
                <form className="mt-2" onSubmit={handleSubmitEducation((data) => UpdateStudentMemberEducation(data, userId))}>
                  <Box>
                    <div style={{ paddingLeft: 40, paddingRight: 40 }}>
                      <Grid container rowSpacing={3} columnSpacing={5}>
                        <Grid item xs={12}></Grid>

                        <Grid item xs={4}>
                          <Controller
                            control={controlEducation}
                            name="studying"
                            rules={{
                              required: 'Please select what you are studying at present',
                            }}
                            render={({ field, fieldState }) => (
                              <>
                                <FormControl fullWidth error={!!fieldState.error}>
                                  <InputLabel>
                                    What Are You Studying At Present?
                                    <span style={{ color: "red" }}>*</span>
                                  </InputLabel>
                                  <Select
                                    style={{ backgroundColor: "#f0f0f0" }}
                                    label={<Typography className="text-dark">What Are You Studying At Present?</Typography>}
                                    value={field.value}
                                    variant="outlined"
                                    onChange={(event, values) => {
                                      const selectedValue = event.target.value;
                                      field.onChange(selectedValue);
                                      const isOther = selectedValue === Qualifications1.find(option => option.label === 'Other').id;
                                      setShowStudyingOtherInput(isOther);
                                      // If 'Other' is selected, clear the 'otherPrimarily' value
                                      if (isOther) {
                                        setOtherStudying('');
                                      }
                                    }}
                                    renderValue={(selected) => {
                                      const selectedOption = Qualifications1.find((option) => option.id === selected);
                                      return selectedOption ? selectedOption.label : 'Select Studying At Present';
                                    }}
                                  >
                                    {Qualifications1.map((option) => (
                                      <MenuItem key={option.id} value={option.id}>
                                        <FormControlLabel
                                          value={option.id}
                                          control={<Radio checked={field.value === option.id} />}
                                          label={option.label}
                                        />
                                      </MenuItem>
                                    ))}
                                  </Select>
                                  {fieldState?.error && !showStudyingOtherInput && (
                                    <FormHelperText error>
                                      {fieldState.error.message}
                                    </FormHelperText>
                                  )}
                                </FormControl>

                                {/* Conditionally render input for "Other" option */}
                                {showStudyingOtherInput && (
                                  <Controller
                                    name="otherStudying"
                                    control={controlEducation}
                                    rules={{
                                      required: showStudyingOtherInput ? 'Please specify the other studying' : false,
                                    }}
                                    render={({ field: otherField, fieldState: otherFieldState }) => (
                                      <>
                                        <TextField
                                          fullWidth
                                          label={<Typography className="text-dark">Please specify</Typography>}
                                          placeholder="Specify other studying"
                                          variant="outlined"
                                          margin="normal"
                                          value={otherStudying}
                                          onChange={(e) => {
                                            otherField.onChange(e.target.value);
                                            setOtherStudying(e.target.value);
                                          }}
                                          error={!!otherFieldState.error}
                                          helperText={otherFieldState.error?.message}
                                        />
                                      </>
                                    )}
                                  />
                                )}
                              </>
                            )}
                          />
                        </Grid>

                        <Grid item xs={4}>
                          <Controller
                            control={controlEducation}
                            name="eligibilityExamination"
                            render={({ field, fieldState }) => (
                              <>
                                <Autocomplete
                                  multiple
                                  id="eligibilityExamination"
                                  fullWidth
                                  options={EligibilityExaminations}
                                  disableCloseOnSelect
                                  getOptionLabel={(option) => option?.name}
                                  filterSelectedOptions
                                  onChange={(event, values) => {
                                    const selectedValues = Array.isArray(values) ? values : [];
                                    const contactInfoIds = selectedValues.map((value) => value?.name);
                                    // If "Other" is removed, clear the 'otherEligibility' value
                                    if (!selectedValues.some((value) => value?.name === 'Other')) {
                                      setOtherEligibility('');  // Reset the "Other" input value
                                    }
                                    setShowEligibilityOtherInput(selectedValues.some((value) => value?.name === 'Other'));
                                    field.onChange(contactInfoIds);
                                  }}
                                  value={
                                    Array.isArray(field.value) ?
                                      EligibilityExaminations.filter((info) => field.value.includes(info.name)) : []
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      error={!!fieldState?.error}
                                      helperText={fieldState?.error?.message}
                                      label={
                                        <Typography className="text-dark">
                                          Have You Got Through Any Eligibility Examinations?
                                        </Typography>
                                      }
                                      placeholder="Select Any Eligibility Examinations"
                                    />
                                  )}
                                />

                                {/* Conditionally render input for "Other" option */}
                                {showEligibilityOtherInput && (
                                  <Controller
                                    name="otherEligibility"
                                    control={controlEducation}
                                    rules={{
                                      required: 'Please specify your other eligibility studies',
                                    }}
                                    render={({ field, fieldState }) => (
                                      <TextField
                                        fullWidth
                                        label={<Typography className="text-dark">Please specify</Typography>}
                                        placeholder="Specify other eligibility studies"
                                        variant="outlined"
                                        margin="normal"
                                        value={otherEligibility} 
                                        error={!!fieldState.error} // Show error if fieldState has an error
                                        helperText={fieldState.error?.message} // Display the error message
                                        onChange={(e) => {
                                          handleOtherEligibilityChange(e); // Update state for external handling (if needed)
                                          field.onChange(e.target.value); // Update field value
                                        }}
                                      />
                                    )}
                                  />
                                )}
                              </>
                            )}
                          />
                        </Grid>

                        <Grid item xs={4}>
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            label={<Typography className="text-dark">Name Of Your Institution<span style={{ color: "red" }}>*</span></Typography>}
                            {...registerEducation("institution")}
                            placeholder="Name Of Your Institution"
                          />
                        </Grid>

                        <Grid item xs={4}>
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            label={<Typography className="text-dark">Address Of Your Institution<span style={{ color: "red" }}>*</span></Typography>}
                            {...registerEducation("addressInstitution")}
                            placeholder="Address Of Your Institution"
                          />
                        </Grid>

                      </Grid>
                    </div>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "row", pt: 2, mt: 3 }}>
                    <Box sx={{ flex: "1 1 auto" }} />
                    <Button type="submit" variant="contained" className="menus-color">
                      Update
                    </Button>
                  </Box>
                </form>
              </Box>
            )}
          </>
        )}
      </>

      {/* Student Professional Development */}
      <>
        {key === "profession" && (
          <>
            <Box>
              <div className="pt-1 pb-3">
                <div className="p-0">
                  <div className="Architects-header d-flex align-items-center justify-content-center">
                    <h2 className="signika-fontOEC text-center">Professional Development</h2>
                  </div>
                </div>
              </div>
            </Box>

            <div className="container mt-3">
              <div className="row">
                <div className="col-md-8"></div>
                <div className="col-md-4 mt-3">
                  <Button
                    onClick={() => window.history.back()}
                    variant="contained"
                    className="menus-color"
                    style={{ float: "right", marginRight: 58, marginBottom: "15px" }}
                  >
                    Back
                  </Button>
                </div>
              </div>
            </div>

            {loading ? (
              <Spinner />
            ) : (
              <Box className="container" sx={{ width: "100%", marginTop: "0px", marginBottom: "50px" }}>
                <form className="mt-2" onSubmit={handleSubmitProfession((data) => UpdateStudentMemberProfession(data, userId))}>
                  <Box>
                    <div style={{ paddingLeft: 40, paddingRight: 40 }}>
                      <Grid container rowSpacing={3} columnSpacing={5}>
                        <Grid item xs={4}>
                          <FormControl component="fieldset">
                            <FormLabel component="legend">Are you a member of any other Teachers’ Association?</FormLabel>
                            <Controller
                              name="teacherAssociation"
                              control={controlProfession}
                              defaultValue=""
                              rules={{ required: "Please select an option" }}
                              render={({ field }) => (
                                <RadioGroup row
                                  {...field}
                                  onChange={(event) => {
                                    const selectedValue = event.target.value;
                                    field.onChange(selectedValue);
                                    // If "No" (value "0") is selected, clear associationName field
                                    if (selectedValue === "0") {
                                      setValueProfession('associationName', "");
                                    }
                                  }}
                                >
                                  <FormControlLabel value="1" control={<Radio />} label="Yes" />
                                  <FormControlLabel value="0" control={<Radio />} label="No" />
                                </RadioGroup>
                              )}
                            />
                            {errorsProfession?.teacherAssociation && (
                              <Typography color="error">{errorsProfession?.teacherAssociation?.message}</Typography>
                            )}
                          </FormControl>
                        </Grid>

                        <Grid item xs={4}>
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            label={
                              <Typography className="text-dark">
                                If yes, mention the name of those associations
                              </Typography>
                            }
                            {...registerProfession("associationName", {
                              validate: (value) =>
                                isTeachersAsso === 1 && !value ? "Please mention the name of those associations" : true,
                            })}
                            placeholder="Mention The Name Of Those Associations"
                            disabled={isTeachersAsso === 0}
                            error={!!errorsProfession?.associationName}
                            helperText={errorsProfession?.associationName?.message}
                            />
                        </Grid>

                        <Grid item xs={4}>
                          <Controller
                            control={controlProfession}
                            name="professionalDevelopment"
                            render={({ field, fieldState }) => (
                              <>
                                <Autocomplete
                                  multiple
                                  id="professionalDevelopment"
                                  fullWidth
                                  options={ProfessionalDevelopment}
                                  disableCloseOnSelect
                                  getOptionLabel={(option) => option?.name}
                                  filterSelectedOptions
                                  onChange={(event, values) => {
                                    const selectedValues = Array.isArray(values) ? values : [];
                                    const contactInfoIds = selectedValues.map((value) => value?.name);
                                    // Reset the otherProfession input when "Other" is selected
                                    if (!selectedValues.some((value) => value?.name === 'Other')) {
                                      setOtherProfession('');  // Reset the "Other" input value
                                    }
                                    const isOtherSelected = selectedValues.some((value) => value?.name === 'Other');
                                    setShowProfessionOtherInput(isOtherSelected);
                                    field.onChange(contactInfoIds);
                                  }}
                                  value={
                                    field.value ?
                                      ProfessionalDevelopment.filter((info) => field.value.includes(info.name)) : []
                                  }
                                  renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                      <Checkbox style={{ marginRight: 8 }} checked={selected} />
                                      {option.name}
                                    </li>
                                  )}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      error={!!fieldState?.error}
                                      helperText={fieldState?.error?.message}
                                      label={
                                        <Typography className="text-dark">
                                          Which of the following would you like to do for your continuous professional development?
                                        </Typography>
                                      }
                                      placeholder="Select Any Professional Development"
                                    />
                                  )}
                                />

                                {/* Conditionally render input for "Other" option */}
                                {showProfessionOtherInput && (
                                  <Controller
                                    name="otherProfession"
                                    control={controlProfession}
                                    rules={{
                                      required: 'Please specify your other professional studies',
                                    }}
                                    render={({ field, fieldState }) => (
                                      <TextField
                                        fullWidth
                                        label={<Typography className="text-dark">Please specify</Typography>}
                                        placeholder="Specify other professional studies"
                                        variant="outlined"
                                        margin="normal"
                                        value={otherProfession}
                                        error={!!fieldState.error} // Show error if fieldState has an error
                                        helperText={fieldState.error?.message} // Display the error message
                                        onChange={(e) => {
                                          handleOtherProfessionChange(e); // Update state for external handling (if needed)
                                          field.onChange(e.target.value); // Update field value
                                        }}
                                      />
                                    )}
                                  />
                                )}
                              </>
                            )}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <h2 className="menus-green-color">Your areas of Interest:</h2>
                        </Grid>

                        <Grid item xs={4}>
                          <Controller
                            control={controlProfession}
                            name="expertiseLiteraryStudies"
                            render={({ field, fieldState }) => (
                              <>
                                <Autocomplete
                                  multiple
                                  id="expertiseLiteraryStudies"
                                  fullWidth
                                  options={LiteraryStudies}
                                  disableCloseOnSelect
                                  getOptionLabel={(option) => option?.name}
                                  filterSelectedOptions
                                  onChange={(event, values) => {
                                    const selectedValues = Array.isArray(values) ? values : [];
                                    const contactInfoIds = selectedValues.map((value) => value?.name);
                                    const isOtherSelected = selectedValues.some((value) => value?.name === 'Other');
                                    setShowLiteraryOtherInput(isOtherSelected);
                                    field.onChange(contactInfoIds);
                                    // Reset the otherLit input when "Other" is selected
                                    if (!selectedValues.some((value) => value?.name === 'Other')) {
                                      setOtherLit('');  // Reset the "Other" input value
                                    }
                                  }}
                                  value={
                                    field.value ?
                                      LiteraryStudies.filter((info) => field.value.includes(info.name)) : []
                                  }
                                  renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                      <Checkbox style={{ marginRight: 8 }} checked={selected} />
                                      {option.name}
                                    </li>
                                  )}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      error={!!fieldState?.error}
                                      helperText={fieldState?.error?.message}
                                      label={<Typography className="text-dark">Literary Studies</Typography>}
                                      placeholder="Select Any Literary Studies"
                                    />
                                  )}
                                />

                                {/* Conditionally render input for "Other" option */}
                                {showLiteraryOtherInput && (
                                  <Controller
                                    name="otherLit"
                                    control={controlProfession}
                                    rules={{
                                      required: 'Please specify your other literary studies',
                                    }}
                                    render={({ field, fieldState }) => (
                                      <TextField
                                        fullWidth
                                        label={<Typography className="text-dark">Please specify</Typography>}
                                        placeholder="Specify other literary studies"
                                        variant="outlined"
                                        margin="normal"
                                        value={otherLit} 
                                        error={!!fieldState.error} // Show error if fieldState has an error
                                        helperText={fieldState.error?.message} // Display the error message
                                        onChange={(e) => {
                                          field.onChange(e.target.value); // Update field value
                                          handleOtherLitChange(e); // Update state for external handling (if needed)
                                        }}
                                      />
                                    )}
                                  />
                                )}
                              </>
                            )}
                          />
                        </Grid>

                        <Grid item xs={4}>
                          <Controller
                            control={controlProfession}
                            name="expertiseLinguistics"
                            render={({ field, fieldState }) => (
                              <>
                                <Autocomplete
                                  multiple
                                  id="expertiseLinguistics"
                                  fullWidth
                                  options={Linguistics}
                                  disableCloseOnSelect
                                  getOptionLabel={(option) => option?.name}
                                  filterSelectedOptions
                                  onChange={(event, values) => {
                                    const selectedValues = Array.isArray(values) ? values : [];
                                    const contactInfoIds = selectedValues.map((value) => value?.name);
                                    field.onChange(contactInfoIds);
                                    const isOtherSelected = selectedValues.some((value) => value?.name === 'Other');
                                    setShowLinguisticsOtherInput(isOtherSelected);
                                    field.onChange(contactInfoIds);
                                    // Reset the otherLing input when "Other" is selected
                                    if (!selectedValues.some((value) => value?.name === 'Other')) {
                                      setOtherLing('');  // Reset the "Other" input value
                                    }
                                  }}
                                  value={
                                    field.value ?
                                      Linguistics.filter((info) => field.value.includes(info.name)) : []
                                  }
                                  renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                      <Checkbox style={{ marginRight: 8 }} checked={selected} />
                                      {option.name}
                                    </li>
                                  )}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      error={!!fieldState?.error}
                                      helperText={fieldState?.error?.message}
                                      label={<Typography className="text-dark">Linguistics</Typography>}
                                      placeholder="Select Any Linguistics"
                                    />
                                  )}
                                />

                                {/* Conditionally render input for "Other" option */}
                                {showLinguisticsOtherInput && (
                                  <Controller
                                    name="otherLing"
                                    control={controlProfession}
                                    rules={{
                                      required: 'Please specify your other linguistics studies',
                                    }}
                                    render={({ field, fieldState }) => (
                                      <TextField
                                        fullWidth
                                        label={<Typography className="text-dark">Please specify</Typography>}
                                        placeholder="Specify other linguistics studies"
                                        variant="outlined"
                                        margin="normal"
                                        value={otherLing} 
                                        error={!!fieldState.error} // Show error if fieldState has an error
                                        helperText={fieldState.error?.message} // Display the error message
                                        onChange={(e) => {
                                          field.onChange(e.target.value); // Update field value
                                          handleOtherLingChange(e); // Update state for external handling (if needed)
                                        }}
                                      />
                                    )}
                                  />
                                )}
                              </>
                            )}
                          />
                        </Grid>

                        <Grid item xs={4}>
                          <Controller
                            control={controlProfession}
                            name="expertiseELT"
                            render={({ field, fieldState }) => (
                              <>
                                <Autocomplete
                                  multiple
                                  id="expertiseELT"
                                  fullWidth
                                  options={EnglishLanguageTeaching}
                                  disableCloseOnSelect
                                  getOptionLabel={(option) => option?.name}
                                  filterSelectedOptions
                                  onChange={(event, values) => {
                                    const selectedValues = Array.isArray(values) ? values : [];
                                    const contactInfoIds = selectedValues.map((value) => value?.name);
                                    const isOtherSelected = selectedValues.some((value) => value?.name === 'Other');
                                    setShowELTOtherInput(isOtherSelected);
                                    field.onChange(contactInfoIds);
                                    // Reset the otherELT input when "Other" is selected
                                    if (!selectedValues.some((value) => value?.name === 'Other')) {
                                      setOtherELT('');  // Reset the "Other" input value
                                    }
                                  }}
                                  value={
                                    field.value ?
                                      EnglishLanguageTeaching.filter((info) => field.value.includes(info.name)) : []
                                  }
                                  renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                      <Checkbox
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                      />
                                      {option.name}
                                    </li>
                                  )}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      error={!!fieldState?.error}
                                      helperText={fieldState?.error?.message}
                                      label={
                                        <Typography className="text-dark">
                                          English Language Teaching (ELT)
                                        </Typography>
                                      }
                                      placeholder="Select Any English Language Teaching (ELT)"
                                    />
                                  )}
                                />

                                {/* Conditionally render input for "Other" option */}
                                {showELTOtherInput && (
                                  <Controller
                                    name="otherELT"
                                    control={controlProfession}
                                    rules={{
                                      required: 'Please specify your other ELT studies',
                                    }}
                                    render={({ field, fieldState }) => (
                                      <TextField
                                        fullWidth
                                        label={<Typography className="text-dark">Please specify</Typography>}
                                        placeholder="Specify other ELT studies"
                                        variant="outlined"
                                        margin="normal"
                                        value={otherELT} 
                                        error={!!fieldState.error} // Show error if fieldState has an error
                                        helperText={fieldState.error?.message} // Display the error message
                                        onChange={(e) => {
                                          field.onChange(e.target.value); // Update field value
                                          handleOtherELTChange(e); // Update state for external handling (if needed)
                                        }}
                                      />
                                    )}
                                  />
                                )}
                              </>
                            )}
                          />
                        </Grid>

                        <Grid item xs={4}>
                          <Controller
                            control={controlProfession}
                            name="expertiseInterdisciplinaryAreas"
                            render={({ field, fieldState }) => (
                              <>
                                <Autocomplete
                                  multiple
                                  id="expertiseInterdisciplinaryAreas"
                                  fullWidth
                                  options={InterdisciplinaryAreas}
                                  disableCloseOnSelect
                                  getOptionLabel={(option) => option?.name}
                                  filterSelectedOptions
                                  onChange={(event, values) => {
                                    const selectedValues = Array.isArray(values) ? values : [];
                                    const contactInfoIds = selectedValues.map((value) => value?.name);
                                    const isOtherSelected = selectedValues.some((value) => value?.name === 'Other');
                                    setShowInterOtherInput(isOtherSelected);
                                    field.onChange(contactInfoIds);
                                    // Reset the otherInter input when "Other" is selected
                                    if (!selectedValues.some((value) => value?.name === 'Other')) {
                                      setOtherInter('');  // Reset the "Other" input value
                                    }
                                  }}
                                  value={
                                    field.value ?
                                      InterdisciplinaryAreas.filter((info) => field.value.includes(info.name)) : []
                                  }
                                  renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                      <Checkbox
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                      />
                                      {option.name}
                                    </li>
                                  )}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      error={!!fieldState?.error}
                                      helperText={fieldState?.error?.message}
                                      label={
                                        <Typography className="text-dark">
                                          Interdisciplinary Areas
                                        </Typography>
                                      }
                                      placeholder="Select Any Interdisciplinary Areas"
                                    />
                                  )}
                                />

                                {/* Conditionally render input for "Other" option */}
                                {showInterOtherInput && (
                                  <Controller
                                    name="otherInter"
                                    control={controlProfession}
                                    rules={{
                                      required: 'Please specify your other interdisciplinary areas',
                                    }}
                                    render={({ field, fieldState }) => (
                                      <TextField
                                        fullWidth
                                        label={<Typography className="text-dark">Please specify</Typography>}
                                        placeholder="Specify other interdisciplinary areas"
                                        variant="outlined"
                                        margin="normal"
                                        value={otherInter} 
                                        error={!!fieldState.error} // Show error if fieldState has an error
                                        helperText={fieldState.error?.message} // Display the error message
                                        onChange={(e) => {
                                          field.onChange(e.target.value); // Update field value
                                          handleOtherInterChange(e); // Update state for external handling (if needed)
                                        }}
                                      />
                                    )}
                                  />
                                )}
                              </>
                            )}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <h2 className="menus-green-color">Areas of Interest:</h2>
                        </Grid>

                        <Grid item xs={12}>
                          <FormControl component="fieldset">
                            <FormLabel component="legend">
                              Are you willing
                              to have your areas of interest listed
                              publicly so that other members can
                              identify potential collaboration
                              opportunities?
                            </FormLabel>
                            <Controller
                              name="areaOfExpertise"
                              control={controlProfession}
                              defaultValue=""
                              render={({ field }) => (
                                <RadioGroup row {...field}>
                                  <FormControlLabel value="1" control={<Radio />} label="Yes" />
                                  <FormControlLabel value="0" control={<Radio />} label="No" />
                                </RadioGroup>
                              )}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                    </div>
                  </Box>

                  <Box sx={{ display: "flex", flexDirection: "row", pt: 2, mt: 3 }}>
                    <Box sx={{ flex: "1 1 auto" }} />
                    <Button type="submit" variant="contained" className="menus-color">
                      Update
                    </Button>
                  </Box>
                </form>
              </Box>
            )}
          </>
        )}
      </>
    </>
  );
}

export default Profile;