import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useGetAllEventsQuery } from "../../app/services/eventService";
import { selectCurrentScope } from "../../app/auth/authSlice";
import { useSelector } from "react-redux";
import viewAll from "../../assets/images/gallery/viewall.png";
import "../../style/css/upcomingeventssection.css";
import leftArrow from "../../assets/images/Event/leftarrow.png";
import rightArrow from "../../assets/images/Event/rightarrow.png";
import { Carousel, Image } from "react-bootstrap";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

function UpComingEventsSection() {
  const { refetch: refetchEvents } = useGetAllEventsQuery();
  const [selectedEvents, setSelectedEvents] = useState([]);
  const currentScope = useSelector(selectCurrentScope);
  const [visibleSlides, setVisibleSlides] = useState(1);

  //getAll Event
  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const res = await refetchEvents();
        if (res?.data?.status) {
          const filterData = res.data.data.filter(
            (item) => item.isApproved === 1
          );
          setSelectedEvents(filterData.slice(0, 15)); // Ensure we select up to 15 records
        } else {
          console.error("Error fetching events:", res?.data?.error);
        }
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    };

    //Responsive with laptop and desktop
    fetchEvents();
    const handleResize = () => {
      const width = window.innerWidth;
      if (width >= 1200) {
        setVisibleSlides(3);
      } else if (width >= 992) {
        setVisibleSlides(2);
      } else if( width < 575){
        setVisibleSlides(1);
      } else {
        setVisibleSlides(1);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [refetchEvents]);

  //format Date
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { month: "short", day: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };

  //Format Time
  const formatTime = (timeString) => {
    if (!timeString) return "";
    const timeRegex = /^(\d{1,2}):(\d{2})\s([ap]m)$/i;
    const match = timeString.match(timeRegex);
    if (!match) return "Invalid Time";
    let hour = parseInt(match[1], 10);
    const minute = match[2];
    const period = match[3].toLowerCase();
    if (isNaN(hour) || hour < 1 || hour > 12) return "Invalid Time";
    return `${hour}:${minute} ${period}`;
  };

  //Slider Add
  const chunkArray = (arr, size) => {
    const result = [];
    for (let i = 0; i < arr.length; i += size) {
      result.push(arr.slice(i, size + i));
    }
    return result;
  };

  const slides = chunkArray(selectedEvents, visibleSlides);

  return (
    <div>
      <div className="d-flex align-items-center ms-2 ms-sm-5">
        <h2 className="custom-header ms-0 ">Upcoming Events</h2>
        <hr className="announcement-hr ms-2 me-2 flex-grow-1 d-none d-md-block" />
        <div className="ms-auto align-items-center mt-2 mt-md-0">
          {currentScope === "PORTAL_SUPER_ADMIN" && (
            <Link to="/admin/eventFeed">
              <div className="position-relative d-flex align-items-center me-1 me-md-5">
                <img src={viewAll} alt="announcements" className="custom-img d-none d-md-block" />
                <span className="custom-span">View All</span>
              </div>
            </Link>
          )}
          {currentScope === "PORTAL_SIG_ADMIN" && (
            <Link to="/sigAdmin/eventFeed">
              <div className="position-relative d-flex align-items-center me-1 me-md-5">
                <img src={viewAll} alt="announcements" className="custom-img d-none d-md-block" />
                <span className="custom-span">View All</span>
              </div>
            </Link>
          )}
          {currentScope === "PORTAL_CHAPTER_ADMIN" && (
            <Link to="/chapterAdmin/eventFeed">
              <div className="position-relative d-flex align-items-center me-1 me-md-5">
                <img src={viewAll} alt="announcements" className="custom-img d-none d-md-block" />
                <span className="custom-span">View All</span>
              </div>
            </Link>
          )}
          {currentScope === "PORTAL_MEMBER" && (
            <Link to="/member/eventFeed">
              <div className="position-relative d-flex align-items-center me-1 me-md-5">
                <img src={viewAll} alt="announcements" className="custom-img d-none d-md-block" />
                <span className="custom-span">View All</span>
              </div>
            </Link>
          )}
          {(currentScope === "" ||
            currentScope === undefined ||
            currentScope === null) && (
              <Link to="/eventFeed">
                <div className="position-relative d-flex align-items-center me-1 me-md-5">
                  <img src={viewAll} alt="announcements" className="custom-img d-none d-md-block" />
                  <span className="custom-span">View All</span>
                </div>
              </Link>
            )}
        </div>
      </div>
      <div className="container">
        <Carousel
          indicators={false}
          nextIcon={
            <img
              className="rightarrow-class"
              src={rightArrow}
              alt="rightarrow"
            />
          }
          prevIcon={
            <img className="leftarrow-class" src={leftArrow} alt="leftarrow" />
          }
          interval={3000}
        >
          {slides.map((slide, index) => (
            <Carousel.Item key={index}>
              <div className="row carousel-class">
                {slide.map((event, idx) => (
                  <div
                    className={`col-12 ${visibleSlides === 2
                      ? "col-sm-6"
                      : visibleSlides === 3
                        ? "col-lg-4"
                        : ""
                      } mb-4 carousel-card`}
                    key={idx}
                  >
                    <div
                      className="card upcoming-custom-card mx-auto position-relative"
                      style={{ backgroundColor: "#f5f1f1" }}
                    >
                      <div className="upcoming-card-body">
                        <div className="main-card-content text-center mt-2">
                          <h5 className="custom-card-title" style={{padding:'0px 16px'}} >
                            {event.title}
                          </h5>
                        </div>
                        <div className="overlay-card">
                          <div className="upcoming-card-body">
                            <Image
                              src={event.imageUrl}
                              alt="event"
                              className="overlay-card-image"
                              fluid
                            />
                          </div>
                        </div>

                        <div className="date-time-container">
                          <p className="date-container mb-0 mb-md-2">
                            {formatDate(event.startDate.split("T")[0])} to{" "}
                            {formatDate(event.endDate)}
                          </p>
                          {/* {cardWidth && 
                          <>
                          <br/>
                          </>
                          } */}
                          {event.startTime && event.endTime && (
                            <p className="time-container mb-0 mb-md-2">
                              {formatTime(event.startTime)} to{" "}
                              {formatTime(event.endTime)}
                            </p>
                          )}
                        </div>

                        <div className="organize-class">Organized By</div>
                        <div className="role-class">{event.organizerName}</div>
                        <div className="viewmore-class">
                          <div className="row">
                            <div className="col-12 ">
                              <button
                                className="viewMore-event-btn btn btn-primary"
                                // onClick={() => viewevent(event._id)}
                              >
                                View More
                                {/* <PlayArrowIcon  /> */}
                                <span className="d-none d-md-inline"> <PlayArrowIcon /></span>
                              </button>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
    </div>
  );
}

export default UpComingEventsSection;
