import { api } from "./api";

const membershipPlanServices = api.injectEndpoints({
  endpoints: (builder) => ({
    //getAllMemberShipDetails
    getAllMemberShipDetails: builder.query({
      query: () => "plans/getAll",
    }),

    //getMemberShipDetailsById
    getMemberShipDetailsById: builder.query({
      query: (id) => "plans/get/" + id,
    }),
    
    //CreateMemberShip
    createMemberShip: builder.mutation({
      query: (newMemberData) => ({
        url: "plans/savePlans",
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: newMemberData,
      }),
    }),

    //Delete Membership
    deleteMemberShip: builder.mutation({
      query: (id) => ({
        url: "plans/delete/"+id,
        method: "PUT",
      }),
    }),

    //disable Member Ship
    disableMemberShip: builder.mutation({
      query: (id) => ({
        url: "plans/enableDisable/"+id,
        method: "PUT",
      }),
    }),

    //UpdateMembership
    updateMemberShip: builder.mutation({
      query: (updatedData) => ({
        url: "plans/update/"+updatedData.id,
        method:"PUT",
        headers: { "Content-Type": "application/json" },
        body: updatedData.data,
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useLazyGetAllMemberShipDetailsQuery,
  useLazyGetMemberShipDetailsByIdQuery,
  useCreateMemberShipMutation,
  useDeleteMemberShipMutation,
  useUpdateMemberShipMutation,
  useDisableMemberShipMutation,
} = membershipPlanServices;
