import React, { useEffect } from 'react'
import TopHeader from '../../../Component/Header/TopHeader'
import Box from '@mui/material/Box';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { TextField } from "@mui/material";
import Button from '@mui/material/Button';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { FormControlLabel, Checkbox } from '@mui/material';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import { InputLabel, MenuItem } from '@material-ui/core';
import { useGetAllCategoryMutation, useLazyGetCategoryDetailsByIdQuery } from '../../../app/services/categoryService';
import { useSaveJobMutation } from '../../../app/services/jobService';
import Alert from '@mui/material/Alert';
import { useNavigate } from "react-router-dom";
import Spinner from "../../../Component/spinner/spinner"

const JobFrom = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();//route
  //jobForm Initialization
  const { register, handleSubmit, formState, getValues, setValue } = useForm({
    defaultValues: {
      // jobDetails
      title: "",
      description: "",
      jobUrl: "",
      positionStartsOn: "",
      location: "",
      skills: "",
      experience: "",
      education: "",
      level: "",
      period: "",
      contactMethod: "",
      positionReportTo: "",
      salaryFrom: "",
      salaryTo: "",
      isAgency: '',
      duration: "",
      activationDate: "",
      expirationDate: "",
      postDate: "",
      entity: "",
      //Company Details
      contactCompany: "",
      contactName: "",
      contactAddress: "",
      contactAddress2: "",
      contactPhone: "",
      contactFax: "",
      contactCity: "",
      contactState: "",
      contactZipCode: "",
      contactCountry: "",
      contactEmail: "",
      contactWebsite: "",
      companyLogo: '',
      //category Details
      categoryId: "",
      subCategoryId: "",
      //permission
      isPublic: '',
      //Admin only
      status: '',
      isRssFeed: ''
    }
  });

  // Dummy steps for the stepper
  const steps = [
    'Job Details',
    'Company Details',
    'Permission',
    'Category',
    'Administrator Only'

  ];

  const [activeStep, setActiveStep] = useState(0);//state for activeStep
  const [isAgency, setIsAgency] = useState(false);//state for checkbox value
  const [isPublic, setIsPublic] = useState(false);//state for checkbox value
  const [isRssFeed, setIsRssFeed] = useState(false);//state for checkbox value
  const [getAllCategory] = useGetAllCategoryMutation();//get All category API Call
  const [categoryValue, setCategoeyValue] = useState([]);//state for category Array
  const [selectedCategoryId, setSelectedCategoryId] = useState("")// state for selectedCategoryId 
  const [getCategoryById] = useLazyGetCategoryDetailsByIdQuery();//getCategoryById API Call
  const [selectedSubCategoryId, setSelectedSubCategoryId] = useState("")
  const [subCategoryData, setSubCategoryData] = useState([]);//state for subCategory Array
  const [saveJobApi] = useSaveJobMutation();//Save job API Call
  const [showSuccessAlert, setShowSuccessAlert] = useState(false); //state for success Alert
  const [showErrorAlert, setShowErrorAlert] = useState(false); //state for Error Alert
  const [alertMessage, setAlertMessage] = useState('');//state to show Alert message
  const [selectedImage, setSelectedImage] = useState('');//state to select image

  //get All category
  useEffect(() => {
    let categoryArray = [];
    let data = {
      type: 0
    }
    getAllCategory(data).then(res => {
      let allData = res.data?.data;
      allData?.forEach(value => {
        categoryArray.push({
          _id: value._id,
          categoryName: value.categoryName
        });
      });
      setCategoeyValue(categoryArray); // Only set state when categoryArray changes
    });
  }, [getAllCategory]);


  //handle Next
  const handleNext = () => {
    // Validate the form
    const isValid = validateForm();
    // If the form is valid, proceed to the next step
    if (isValid) {
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    }
  };

  //form validate
  const validateForm = () => {
    // Check if all required fields are filled
    const isValid = Object.keys(formState.errors).length === 0;
    // If not, display a message for each required field that is empty
    if (!isValid) {
      alert('Please fill in all required fields.');
    }
    return isValid;
  };

  //Handle Back
  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  //checkbox isAgency Change
  const handleisAgencyCheckboxChange = (event) => {
    setIsAgency(event.target.checked);
  };

  //checkbox isPublic Change
  const handleisPublicCheckboxChange = (event) => {
    setIsPublic(event.target.checked);
  };

  //checkbox isRssFeed Change
  const handleisRssFeedCheckboxChange = (event) => {
    setIsRssFeed(event.target.checked);
  };

  //categoryChange
  const handleCategoryChange = (event) => {
    let categoryId = event.target.value
    setSelectedCategoryId(event.target.value);
    let subCategoryData = [];
    //get category by Id
    getCategoryById(categoryId).then(res => {
      let categoryIdData = res.data.data
      categoryIdData.subCategory.forEach(ele => {
        subCategoryData.push({
          _id: ele._id,
          subCategoryName: ele.subCategoryName
        })
      })
      setSubCategoryData(subCategoryData)
    })
  };

  //Format Date
  const formatDate = (date) => {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    var correctDate = [year, month, day].join('-');
    return correctDate;
  }

  //save Job
  const saveUpdateJob = (formData) => {
    setLoading(true);
    let activationFormatDate = formatDate(formData.activationDate);
    let positionStartsOnFormatDate = formatDate(formData.positionStartsOn);
    let expirationFormatDate = formatDate(formData.expirationDate);
    let postFormatDate = formatDate(formData.postDate)
    formData.isPublic = isPublic;
    formData.isAgency = isAgency;
    formData.isRssFeed = isRssFeed;
    formData.expirationDate = expirationFormatDate;
    formData.positionStartsOn = positionStartsOnFormatDate;
    formData.activationDate = activationFormatDate;
    formData.postDate = postFormatDate;
    formData.categoryId = selectedCategoryId;
    formData.subCategoryId = selectedSubCategoryId;
    //save job Api
    saveJobApi(formData).then(res => {
      if (res.data.status) {
        setLoading(false);
        setShowSuccessAlert(true); // Show the success alert
        setShowErrorAlert(false); // hide the error alert
        setAlertMessage("Job Saved Successfully")
        // Hide the alerts after 4 seconds
        setTimeout(() => {
          setShowSuccessAlert(false);
          navigate("/viewJob");// Navigate after the success message disappears
        }, 4000);
      }
      else {
        setLoading(false);
        setAlertMessage("Fail to Save Job")
        setShowSuccessAlert(false); // Hide the success alert
        setShowErrorAlert(true); // Show the error alert
        // Hide the alerts after 4 seconds
        setTimeout(() => {
          setShowErrorAlert(false);
        }, 4000);
      }
    })
  }

  //Handle Image Change
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (!file.type.startsWith("image/")) {
      alert("Please select an image file.");
      return;
    }

    const reader = new FileReader();
    reader.onload = (e) => {
      let base64String = e.target.result;
      // Send the base64 string to the backend
      setSelectedImage(reader.result);
      setValue("companyLogo", base64String);
    };
    reader.readAsDataURL(file);
  };


  return (
    <div>
      <TopHeader />
      <Box className="banner_text"></Box>
      <div className='container'>
        <div className='row'>
          <div className='col-md-7'>
            <h3 className='heading_h3_bearers mt-4'>Add Job</h3>
            <span className="animate-border-bearers border-black"></span>
          </div>
          <div className='col-md-5 mt-3'>
            <Button onClick={() => window.history.back()} variant="contained" color="primary"
              style={{ float: 'right' }}>Back</Button>

          </div>
        </div>

      </div>

      <Stepper activeStep={activeStep} className='mt-5'>
        {steps.map((label, index) => {
          return (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {loading ? (
        <Spinner />
      ) : (
        <form className='mt-5' onSubmit={handleSubmit(saveUpdateJob)}>
          {activeStep === 0 && (
            <Box>
              <div style={{ display: 'flex', justifyContent: 'space-evenly', marginBottom: '20px' }}>
                <TextField InputLabelProps={{ shrink: true }} style={{ width: '30%' }} label="Title" {...register('title', { required: 'Please Enter a title' })} placeholder="Job Title" />
                <TextField InputLabelProps={{ shrink: true }} style={{ width: '30%' }} label='Description' {...register('description', { required: 'Please Enter a Description' })} placeholder='Job Description' />
                <TextField InputLabelProps={{ shrink: true }} style={{ width: '30%' }} label="Job Url" {...register('jobUrl', { required: 'Please Enter a Job Url' })} placeholder="Job Url" />
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-evenly', marginBottom: '20px' }}>
                <TextField InputLabelProps={{ shrink: true }} style={{ width: '30%' }} label="Position Starts On" variant='outlined' type="date" {...register('positionStartsOn', { required: 'Please Enter a positionStartsOn' })} />
                <TextField InputLabelProps={{ shrink: true }} style={{ width: '30%' }} label="Location" {...register('location', { required: 'Please Enter a Location' })} placeholder="Job Location" />
                <TextField InputLabelProps={{ shrink: true }} style={{ width: '30%' }} label='Skills' {...register('skills', { required: 'Please Enter a Skills' })} placeholder='Skills Required' />
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-evenly', marginBottom: '20px' }}>
                <TextField InputLabelProps={{ shrink: true }} style={{ width: '30%' }} label="Experience" {...register('experience', { required: 'Please Enter a Experience' })} placeholder="Job Experience" />
                <TextField InputLabelProps={{ shrink: true }} style={{ width: '30%' }} label='Education' {...register('education', { required: 'Please Enter a Education' })} placeholder='Education' />
                <TextField InputLabelProps={{ shrink: true }} style={{ width: '30%' }} label="Level" {...register('level', { required: 'Please Enter a Level' })} placeholder="Level" />
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-evenly', marginBottom: '20px' }}>
                <TextField InputLabelProps={{ shrink: true }} style={{ width: '30%' }} label="Period" {...register('period', { required: 'Please Enter a period' })} placeholder="Job period" />
                <TextField InputLabelProps={{ shrink: true }} style={{ width: '30%' }} label='Contact Method' {...register('contactMethod', { required: 'Please Enter a contactMethod' })} placeholder='Job contactMethod' />
                <TextField InputLabelProps={{ shrink: true }} style={{ width: '30%' }} label="Position Report To" {...register('positionReportTo', { required: 'Please Enter a positionReportTo' })} placeholder="positionReportTo" />
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-evenly', marginBottom: '20px' }}>
                <TextField InputLabelProps={{ shrink: true }} style={{ width: '30%' }} label="Salary From" {...register('salaryFrom', { required: 'Please Enter a Salary From' })} placeholder="Salary From" />
                <TextField InputLabelProps={{ shrink: true }} style={{ width: '30%' }} label='Salary To' {...register('salaryTo', { required: 'Please Enter a Salary To' })} placeholder='Salary To' />
                <TextField InputLabelProps={{ shrink: true }} style={{ width: '30%' }} label="Duration" {...register('duration', { required: 'Please Enter a Duration' })} placeholder="Duration" />
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-evenly', marginBottom: '20px' }}>
                <TextField InputLabelProps={{ shrink: true }} style={{ width: '30%' }} label="Activation Date" type='date' {...register('activationDate', { required: 'Please Enter a Activation Date' })} />
                <TextField InputLabelProps={{ shrink: true }} style={{ width: '30%' }} label="Expiration Date" type='date' {...register('expirationDate', { required: 'Please Enter a Expiration Date' })} />
                <TextField InputLabelProps={{ shrink: true }} style={{ width: '30%' }} label="Post Date" type='date' {...register('postDate', { required: 'Please Enter a Post Date' })} />
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-evenly', marginBottom: '20px' }}>
                <TextField InputLabelProps={{ shrink: true }} style={{ width: '30%' }} label="Entity" {...register('entity', { required: 'Please Enter a Entity' })} placeholder="Entity" />
                <FormControlLabel style={{ width: '30%' }} control={<Checkbox checked={isAgency} onChange={handleisAgencyCheckboxChange} />} label="isAgency" />
                <div style={{ width: '30%' }}></div>
              </div>
            </Box>
          )}
          {activeStep === 1 && (
            <Box>
              <div style={{ display: 'flex', justifyContent: 'space-evenly', marginBottom: '20px' }}>
                <TextField InputLabelProps={{ shrink: true }} style={{ width: '30%' }} label="Contact Company" {...register('contactCompany', { required: 'Please Enter a contact Company' })} placeholder="contact Company" />
                <TextField InputLabelProps={{ shrink: true }} style={{ width: '30%' }} label='Contact Name' {...register('contactName', { required: 'Please Enter a contact Name' })} placeholder='contact Name' />
                <TextField InputLabelProps={{ shrink: true }} style={{ width: '30%' }} label="Contact Address" {...register('contactAddress', { required: 'Please Enter a Contact Address' })} placeholder="contact Address" />
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-evenly', marginBottom: '20px' }}>
                <TextField InputLabelProps={{ shrink: true }} style={{ width: '30%' }} label="Contact Address2" {...register('contactAddress2', { required: 'Please Enter a contact Address2' })} placeholder="contact Address2" />
                <TextField InputLabelProps={{ shrink: true }} style={{ width: '30%' }} label='Contact Phone' {...register('contactPhone', { required: 'Please Enter a contact Phone' })} placeholder='contact Phone' />
                <TextField InputLabelProps={{ shrink: true }} style={{ width: '30%' }} label="Contact Fax" {...register('contactFax', { required: 'Please Enter a contact Fax' })} placeholder="contact Fax" />
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-evenly', marginBottom: '20px' }}>
                <TextField InputLabelProps={{ shrink: true }} style={{ width: '30%' }} label="Contact City" {...register('contactCity', { required: 'Please Enter a contact City' })} placeholder="contact City" />
                <TextField InputLabelProps={{ shrink: true }} style={{ width: '30%' }} label='Contact State' {...register('contactState', { required: 'Please Enter a contact State' })} placeholder='contact State' />
                <TextField InputLabelProps={{ shrink: true }} style={{ width: '30%' }} label="Contact ZipCode" {...register('contactZipCode', { required: 'Please Enter a contact ZipCode' })} placeholder="contact ZipCode" />
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-evenly', marginBottom: '20px' }}>
                <TextField InputLabelProps={{ shrink: true }} style={{ width: '30%' }} label="Contact Country" {...register('contactCountry', { required: 'Please Enter a contact Country' })} placeholder="contact Country" />
                <TextField InputLabelProps={{ shrink: true }} style={{ width: '30%' }} label='Contact Email' {...register('contactEmail', { required: 'Please Enter a contact Email' })} placeholder='contact Email' />
                <TextField InputLabelProps={{ shrink: true }} style={{ width: '30%' }} label="Contact Website" {...register('contactWebsite', { required: 'Please Enter a contact Website' })} placeholder="contact Website" />
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-evenly', marginBottom: '20px' }}>
                <div><label style={{ marginLeft: "30px" }}>Logo</label></div>
                <div style={{ width: '30%' }}>
                  {selectedImage ? (
                    <img src={selectedImage} alt="SelectedImage" height="150" />
                  ) : (
                    <img src={getValues('imageUrl')} alt="SelectedImage" height='150' />
                  )}
                  <input
                    accept="image/*"
                    id="companyLogo"
                    type="file"
                    style={{ display: "none" }}
                    onChange={handleImageChange}
                  />
                  <label htmlFor="companyLogo" style={{ marginLeft: '10px' }}>
                    <Button variant="contained" className="menus-color" component="span" style={{ whiteSpace: 'nowrap' }}>
                      Choose File
                    </Button>
                  </label>
                </div>
                <div style={{ width: '30%' }}>
                </div>
                <div style={{ width: '30%' }}>
                </div>
              </div>
            </Box>
          )}
          {activeStep === 2 && (
            <Box>
              <FormControlLabel style={{ width: '30%', marginLeft: '10px' }} control={<Checkbox checked={isPublic} onChange={handleisPublicCheckboxChange} />} label="Public Can View" />
            </Box>
          )}
          {activeStep === 3 && (
            <Box>
              <div style={{ display: 'flex', justifyContent: 'space-evenly', marginBottom: '20px' }}>
                <FormControl style={{ width: '45%' }}>
                  <InputLabel id="Category">Category</InputLabel>
                  <Select
                    labelId="Category"
                    id="Category"
                    label="Category"
                    value={selectedCategoryId} // Make sure to bind the selected value to state
                    onChange={handleCategoryChange} // Call the event handler function when category changes
                  >
                    {categoryValue.map((category) => (
                      <MenuItem key={category._id} value={category._id}>
                        {category.categoryName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl style={{ width: '45%' }}>
                  <InputLabel id="SubCategory">SubCategory</InputLabel>
                  <Select
                    labelId="SubCategory"
                    id="SubCategory"
                    label="SubCategory"
                    value={selectedSubCategoryId}
                    onChange={(event) => setSelectedSubCategoryId(event.target.value)}
                  >
                    {subCategoryData.map((subCategory) => (
                      <MenuItem key={subCategory._id} value={subCategory._id}>
                        {subCategory.subCategoryName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </Box>
          )}
          {activeStep === 4 && (
            <Box>
              <FormControlLabel style={{ width: '45%', marginLeft: '10px' }} control={<Checkbox checked={isRssFeed} onChange={handleisRssFeedCheckboxChange} />} label="Include in RSS Feed " />
              <FormControl style={{ width: '45%' }}>
                <InputLabel id="Status Detail">Status Detail</InputLabel>
                <Select
                  labelId="Status Detail"
                  id="Status Detail"
                  label="Status Detail"
                  defaultValue=""
                  {...register('status')}
                >
                  <MenuItem value='Active'>Active</MenuItem>
                  <MenuItem value='Inactive'>Inactive</MenuItem>
                  <MenuItem value='Pending'>Pending</MenuItem>
                </Select>
              </FormControl>
            </Box>
          )}
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, mt: 3, mb: 3 }}>
            <Button variant="contained" className="menus-color" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
              Back
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button variant="contained" className="menus-color" onClick={activeStep === steps.length - 1 ? handleSubmit(saveUpdateJob) : handleNext}>
              {activeStep === steps.length - 1 ? 'Submit' : 'Next'}
            </Button>

          </Box>
          <div className='mt-3'>
            {showSuccessAlert && (
              <Alert variant="filled" severity="success">
                {alertMessage}
              </Alert>
            )}
            {showErrorAlert && (
              <Alert variant="filled" severity="error">
                {alertMessage}
              </Alert>
            )}
          </div>
        </form>
      )}

    </div>
  )
}

export default JobFrom
