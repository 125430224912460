import React, { useEffect, useState } from 'react';
import image15 from '../../../assets/images/partner/150/15.png';
import { Container } from '@mui/material';
import { useLazyGetInstituteBasedOnStateQuery } from '../../../app/services/memberServices';

const MembershipInstitution = () => {
  const [getall] = useLazyGetInstituteBasedOnStateQuery();
  const [getInstitution, setGetInstitution] = useState([]);

  useEffect(() => {
    getall()
      .then((res) => {
        if (res?.data?.status) {
          setGetInstitution(res?.data?.data);
        }
      })
      .catch((err) => console.error(err));
  }, [getall]);

  return (
    <div className='ourExecutiveCommittee-containter pt-lg-5 pt-3'>
      <header className='Architects-header d-flex align-items-center justify-content-center'>
        <h2 className='signika-fontOEC text-center'>Our Membership Institution</h2>
      </header>

      {/* Loop through institutions */}
      {getInstitution.length > 0 ? (
        getInstitution.map((institution, i) => (
          <div key={i}>
            <div className='d-flex justify-content-center p-5'>
              <img src={image15} alt='our-partner' className='ourExecutiveCommittee_partner-image' />
              <h4 className='ourExecutiveCommittee_partner-image-header'>{institution?.state}</h4>
              <h4 className='ourExecutiveCommittee_partner-image-number'>{institution?.count}</h4>
            </div>

            {/* Display only the members of this specific institution */}
            <div className='mt-2 pb-3'>
              <Container maxWidth={'lg'}>
                <div className='row d-flex  justify-content-center'>
                  {institution.members && institution.members.length > 0 ? (
                    institution.members.map((member, index) => (
                      <div className='col-md-4 col-sm-6 col-xl-3 pt-1 pl-1 containter-partner' key={index}>
                        <div className='teacher-asso-card p-3'>
                          <div className='d-flex justify-content-center'>
                            <img
                              src={member?.uploadInstitutionLogo || member?.uploadOrganisationLogo || image15}
                              width={'100px'}
                              height={"100px"}
                              alt= {member?.institution || member?.organisationName}
                              className='our-partner-img'
                            />
                          </div>
                          <p className='signika-font details-partner text-center'>
                            {member?.institution || member?.organisationName}
                          </p>
                          <p className='signika-font details-partner text-center'>{member?.city}</p>
                          <p className='signika-font details-partner text-center'>{member?.websiteAddressOrganisation||member.websiteAddressOrganisation}</p>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p className='text-center'>No members found</p>
                  )}
                </div>
              </Container>
            </div>
          </div>
        ))
      ) : (
        <p className='text-center'>No institutions found</p>
      )}
    </div>
  );
};

export default MembershipInstitution;
