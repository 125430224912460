import React, { useEffect, useState } from "react";
import { Button, TextField, InputAdornment, Stack } from "@mui/material";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { useLazyGetAllMemberShipDetailsQuery } from "../../app/services/memberShipServices";
import { useLazyGetMemberDetailsByIdQuery } from "../../app/services/memberServices";
import { toast } from "react-toastify";

const PaymentInfo = () => {
  const { control, getValues, setValue, watch } = useFormContext();
  const [getAllMershipPlans] = useLazyGetAllMemberShipDetailsQuery();
  const [plan, setPlan] = useState({});
  const [planType, setPlanType] = useState({});
  const [gettMemberDetailsByIdQuery] = useLazyGetMemberDetailsByIdQuery();
  const paymentStatus = useWatch({
    control,
    name: 'paymentStatus',
  });

  //Get all membership plan and membership plan type
  useEffect(() => {
    const membershipPlanType = getValues("planId");
    const membershipType = getValues("planTypeId");
    getAllMershipPlans().then((res) => {
      const pl = res.data.data.find((x) => x._id === membershipPlanType);
      const plTy = pl.planType.find((item) => item._id === membershipType);
      setPlan(pl);
      setPlanType(plTy);
      setValue("amount", plTy.amount);
    });
  }, [getValues, setValue, getAllMershipPlans]);

  //payment
  const payment = () => {
    var options = {
      key: process.env.REACT_APP_RAZOR_PAY_KEY_ID, // Enter the Key ID generated from the Dashboard
      amount: getValues("amount"), // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: getValues("currency"),
      name: getValues("firstName") + " " + getValues("lastName"), //your business name
      description: "Membership payment",
      order_id: getValues("orderId"), //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
      handler: function (response) {
        gettMemberDetailsByIdQuery(watch("memberId")).then((res) => {
          if (res.data?.status) {
            const isPaid = res.data?.data?.plans[0]?.isPaid === 1;
            setValue("paymentStatus", isPaid);            
            toast.success("Member Saved Successfully");
          }
        });
      },
    };
    var rzp1 = new window.Razorpay(options);
    rzp1.open();
    rzp1.on("payment.failed", function (response) {
      alert("Error in Payment", response.error);
      toast.error("Member Created Failed");
    });
  };

  return (
    <>
      <Stack spacing={2} direction={"row"}>
        <TextField
          label="Membership Plan"
          InputLabelProps={{ shrink: true }}
          value={plan.planName}
          fullWidth
          readOnly
        ></TextField>
        <TextField
          label="Membership Plan Type"
          InputLabelProps={{ shrink: true }}
          value={planType.planTypeName}
          fullWidth
          readOnly
        ></TextField>
      </Stack>
      <Controller
        control={control}
        name="amount"
        render={({ field, formState }) => (
          <TextField
            label="Amount"
            id="payment"
            value={field.value}
            InputProps={{
              disabled: true,
              startAdornment: (
                <InputAdornment position="start">
                  <CurrencyRupeeIcon />
                </InputAdornment>
              ),
            }}
            sx={{
              '& .MuiInputBase-input': {
                fontWeight: '700', 
              },
            }}
            {...field}
          />
        )}
      />
      <Button
        variant="contained"
        disabled={paymentStatus}
        className="menus-color"
        onClick={() => payment()}
        style={{color:"white"}}
      >
        {paymentStatus ? "Paid" : "Pay Now"}
        </Button>
    </>
  );
};
export default PaymentInfo;
