import { Box, Button, IconButton, InputAdornment, Modal, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import TopHeader from '../../../Component/Header/TopHeader';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useLazyGetSIGByIdQuery, useMakeAdminSIGMutation, useRemoveAdminSIGMutation } from '../../../app/services/SIGServices';
import { toast } from 'react-toastify';
import Spinner from "../../../Component/spinner/spinner"
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";

//table features
function EnhancedTableHead() {
  const headCells = [
    { id: 'name', numeric: false, disablePadding: true, label: 'Name' },
    { id: 'calories', numeric: true, disablePadding: false, label: 'Email Id' },
    { id: 'role', numeric: true, disablePadding: false, label: 'Role' },
    { id: 'actions', numeric: true, disablePadding: false, label: 'Actions' },
  ];

  return (
    <>
      <TableHead>
        <TableRow className="menus-color">
          {headCells.map((headCell) => (
            <TableCell
              sx={{ textAlign: "center", color: "#ffff" }}
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
            >
              {headCell.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    </>
  );
}


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '5px',
  p: 4,
};
const MakeAdminSig = () => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false)
  const [selectedMember, setSelectedMember] = useState(null)
  const handleOpen = (id) => { setOpen(true); setSelectedMember(id) };
  const handleClose = () => setOpen(false);
  const { register, getValues, handleSubmit, formState: { errors },watch ,reset} = useForm()
  const { sigGroupId } = useParams()
  const [getSigById, { data: siGData }] = useLazyGetSIGByIdQuery()
  const [makeMemberAdmin] = useMakeAdminSIGMutation();
  const [removeMemberAdmin] = useRemoveAdminSIGMutation()
  const [showPassword,setShowPassword]=useState({password:false,confirmPassword:false})
  useEffect(() => {
    getSigById(sigGroupId)
  }, [getSigById, sigGroupId])

 //remove admin
  const removeAdmin = (member) => {
    const data = {
      sigGroupId,
      roleId: member._id,
      email: member.email,
    }
    setLoading(true);
    removeMemberAdmin(data).then(res => {
      if (res.data?.data) {
        setLoading(false);
        toast.success(res.data?.message)
        getSigById(sigGroupId)
      }
      else {
        setLoading(false);
        toast.error(res.data?.message)
      }
    })
  }

  //makeAdmin
  const makeAdmin = () => {
    const data = {
      sigGroupId,
      roleId: selectedMember._id,
      email: selectedMember.email,
      password: getValues('password')
    }
    setLoading(true);
    handleClose()
    reset()
    makeMemberAdmin(data).then(res => {
      if (res.data.status) {
        setLoading(false);
        toast.success(res.data?.message)
        getSigById(sigGroupId)
        reset()
      }
      else {
        setLoading(false);
        toast.error(res.data?.err?.message)
      }
    })
  }
  //showpassword
  const handleClickShowPassword = (type) => {
    setShowPassword({...showPassword,[type]:!showPassword[type]});
  };
  return (
    <>
      <TopHeader />
        <div className='pt-1 '>
                    <div className='p-0'>
                        <div className=' Architects-header d-flex align-items-center justify-content-center '>
                            <h2 className='signika-fontOEC text-center '>Make/Remove Admin</h2>
                        </div>
                    </div>
                </div>
      <span className="ms-4" style={{width: "20%"}}></span>
      <div className='container mt-2 mb-5'>
        <Button onClick={() => window.history.back()} variant="contained" className='menus-color mb-3'
          style={{ float: 'right',marginRight: "6rem" }}>Back</Button>
      </div>
      {loading ? (
        <Spinner />
      ) : (
        <Box sx={{ width: '80%', marginTop: 3 }} className="memberTable mb-5">
          <Paper sx={{ width: '100%', mb: 2 }}>
            {/* <EnhancedTableToolbar /> */}
            <TableContainer>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
              >
                <EnhancedTableHead />
                <TableBody>
                  {siGData?.data?.roleDetails.length > 0 ? siGData?.data?.roleDetails
                    .map((member, index) => (
                      <TableRow
                        hover
                        key={member._id}
                        sx={{ cursor: 'pointer', backgroundColor: index % 2 === 0 ? '#f0f0f0' : 'white' }}
                      >
                        <TableCell align="center">{member.name}</TableCell>
                        <TableCell align="center">{member.email}</TableCell>
                        <TableCell align="center">{member.role}</TableCell>
                        <TableCell align="center">
                          {member.isAdmin ? <Tooltip title="Remove Admin">
                              <IconButton
                                color="error"
                                onClick={() => removeAdmin(member)}
                                title="Remove Admin"
                                disabled={!member.isAdmin}
                                aria-label="approve"
                              >
                                <CloseIcon color="error"></CloseIcon>
                              </IconButton>
                            </Tooltip>: <Tooltip title="Make Admin">
                              <IconButton
                                onClick={() => handleOpen(member)}
                                aria-label="approve"
                                title="Make Admin"
                              >
                                <DoneIcon color="success"></DoneIcon>
                              </IconButton>
                            </Tooltip>}
                        </TableCell>
                      </TableRow>
                    )) : (<>
                      <TableRow>
                        <TableCell colSpan={4} align="center">No Data Available</TableCell>
                      </TableRow>
                    </>)}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} component={'form'} onSubmit={handleSubmit(makeAdmin)} className='d-grid place-items-center gap-3 mx-auto'>
          <Typography id="modal-modal-title" className='text-primary' variant="h6" component="h2">
            Enter a Password for this Admin
          </Typography>
          <TextField sx={{width:'195px'}} type={showPassword?.password?'text':'password'}  label={
            <Typography className="text-dark">
              Password <span style={{ color: "red" }}>*</span>
            </Typography>
          } InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={()=>handleClickShowPassword('password')}
                  edge="end"                 
                >
                  {showPassword?.password ? <VisibilityOffIcon sx={{color:'#A52A2A'}}/> : <VisibilityIcon sx={{color:'#A52A2A'}}/>}
                </IconButton>
              </InputAdornment>
            ),
          }}  error={!!errors?.password} helperText={errors?.password?.message} {...register('password', { required: 'Please Enter a New password', pattern: { value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,30}$/, message: 'Please Enter a Password with ateleast 1 Capital and 1 lower case and 1 special character and min length of 8' } })} ></TextField>
          <TextField  sx={{width:'195px'}} type={showPassword?.confirmPassword?'text':'password'} label={
            <Typography className="text-dark">
              Confirm Password <span style={{ color: "red" }}>*</span>
            </Typography>
          } InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={()=>handleClickShowPassword('confirmPassword')}
                  edge="end"
                 
                >
                  {showPassword?.confirmPassword ? <VisibilityOffIcon sx={{color:'#A52A2A'}}/> : <VisibilityIcon sx={{color:'#A52A2A'}}/>}
                </IconButton>
              </InputAdornment>
            ),
          }}  {...register('confirmPassword', {
            required: 'Please Re-enter Password', validate: (value) => {
              if (value !== watch('password')) {
                return 'Password do not match'
              }
              return true
            }
          })} error={!!errors?.confirmPassword} helperText={errors?.confirmPassword?.message} />
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Button variant="outlined" color="error" onClick={handleClose} style={{marginRight: '10px'}}>Cancel</Button>
          <Button className="menus-color" type='submit' variant='contained' style={{backgroundColor:"#601e1d"}}>Submit</Button>          
          </div>
        </Box>
      </Modal>
    </>
  )
}

export default MakeAdminSig