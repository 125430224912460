import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
// import TextField from "@mui/material/TextField";
import {
  selectCurrentMember,
  setMemberDetails,
} from "../../app/features/memberSlice";
import { useLazyGetCategoryDetailsByIdQuery } from '../../app/services/categoryService'
import { useDispatch, useSelector } from "react-redux";
import {
  useLazyGetAllMemberQuery,
  useUpdateMemberMutation,
  useLazyGetPendingMemberByIdQuery,
} from "../../app/services/memberListServices";
import { useLazyGetMemberShipDetailsByIdQuery } from "../../app/services/memberShipServices";
import Modal from "@mui/material/Modal";
import CardActions from "@mui/material/CardActions";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import "../../style/css/tab.css";
import { selectCurrentScope } from "../../app/auth/authSlice";
import "./viewmember.css";
// import ChapterAdminPortal from "../Chapter/ChapterAdminPortal";
// import SigAdminPortal from "../SigAdmin/SigAdminPortal";
import userlogo from "../../assets/images/login/userLogo.png";
// import Event from "../../Component/Portal/Event";
// import News from "../../Component/Portal/News";
// import Gallery from "../../Component/Portal/Gallery";
import Spinner from "../../Component/spinner/spinner";
import profileBg from "../../assets/images/interface/profile-bgImage.png";
// import EditIcon from "@mui/icons-material/Edit";
import { toast } from "react-toastify";
import PhoneRoundedIcon from '@mui/icons-material/PhoneRounded';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import { Avatar, Container, Stack } from "@mui/material";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useLazyGetChapterDetailsByIdQuery } from '../../app/services/chapterServices'
import { useLazyGetSIGGroupByIdQuery } from '../../app/services/SIGGroupServices'
import { useGetAllModulesMutation } from '../../app/services/getAllModule'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { useLazyGetChapterRoleDetailsByIdQuery } from '../../app/services/chapterServices'
import { useLazyGetSIGGroupRoleDetailsByIdQuery } from '../../app/services/chapterServices'
// import {useLazyGetSIGRoleDetailsByIdQuery} from '../../app/services/SIGGroupServices'
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import SearchIcon from '@mui/icons-material/Search';
import CircularProgress from '@mui/material/CircularProgress';
import buttonImage from '../../assets/images/memberships/Group 1239.png'
import { Qualifications, TypeOfInstitution, educationLevels, currently_teaching, PrimarilyTeaching, industries, Modules } from './../../constant/constant'
import { Controller, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { selectCurrenSigRole, setSigRoleDetails } from '../../app/features/sigSlice';
import { selectCurrenChapterRole } from "../../app/features/chapterAdminSlice";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function Viewmember() {
  const [getMembershipDetailsById] = useLazyGetMemberShipDetailsByIdQuery();
  const [getAllModule] = useGetAllModulesMutation()
  const [membershipDetails, setMembershipDetails] = useState();
  const [getAllMemberData, { data: allMemberDetails }] =
    useLazyGetAllMemberQuery();
  const [updateMember] = useUpdateMemberMutation();
  const [getPendingMemberById] = useLazyGetPendingMemberByIdQuery();
  const [selectedMember, setSelectedMember] = useState({});
  const [selectedMembership, setSelectedMembership] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [filterSearch, setFilterSearch] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const memberDetails = useSelector(selectCurrentMember);
  const [showUploadButton, setShowUploadButton] = useState(false);
  const dispatch = useDispatch();
  const currentScope = useSelector(selectCurrentScope);
  const [loading, setLoading] = useState(false);
  const [getChapterById] = useLazyGetChapterDetailsByIdQuery()
  const [getSigById] = useLazyGetSIGGroupByIdQuery()
  const [chapter, setChapter] = useState();
  const [sig, setSig] = useState([])
  const [allmodules, setAllModuces] = useState([])
  const [page, setPage] = useState(1)
  const [getChapterRoleDetailsById] = useLazyGetChapterRoleDetailsByIdQuery();
  const [newsChapter, setNewsChapter] = useState()
  const [newsSIG, setNewsSIG] = useState()
  const [eventChapter, setEventChapter] = useState()
  const [eventSIG, setEventSIG] = useState()
  const [forumChapter, setForumChapter] = useState()
  const [forumSIG, setForumSIG] = useState()
  const [getSIGRoleDetailsById] = useLazyGetSIGGroupRoleDetailsByIdQuery()
  const [hasMore, setHasMore] = useState(true);
  const [isFetching, setIsFetching] = useState(false);
  const [memberLoading, setMemberLoading] = useState(false);
  const [openTabMobile, setOpenMobile] = useState(false)
  const [openMemberTab, setOpenMemberTab] = useState(false)
  const [announcementChapter, setAnnouncementChapter] = useState()
  const [announcementSIG, setAnnouncementSIG] = useState()
  const [galleryChapter, setGalleryChapter] = useState()
  const [gallerySIG, setGallerySIG] = useState()
  const [planDetails, setPlanDetails] = useState([])
  const [imageKey, setImageKey] = useState(0);
  const [imageUpdate, setImageUpdate] = useState()
  const navigator = useNavigate()
  const [selectedType, setSelectedType] = useState()
  const sigRoleDetails = useSelector(selectCurrenSigRole);
  const chapterRoleDetails=useSelector(selectCurrenChapterRole)
  const [sigDetails, setSigDetails] = useState()
  const [chapterDetails, setChapterDetails] = useState()
  console.log(chapterRoleDetails._id,' selectCurrentMember')
  
  //handleopen member profile
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setSelectedMember(null);
    setSelectedMembership(null);
  };
  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    reset,
    trigger,
    formState: { errors },
  } = useForm()


  useEffect(() => {
    if (sigRoleDetails?._id) { // Ensure sigRoleDetails._id exists before making the API call
      getSIGRoleDetailsById(sigRoleDetails._id)
        .then((res) => {
          if (res?.data?.status) {
            setSigDetails(res?.data?.data);
          } else {
            // Handle cases where the response status indicates failure
          }
        })
        .catch((err) => console.log(err));
    }
    if(chapterRoleDetails._id){
      getChapterRoleDetailsById(chapterRoleDetails._id).then((res)=>{
        if(res?.data.status){
          setChapterDetails(res?.data.data)
        }
      }).catch((err)=>console.log(err))
    }
  }, [sigRoleDetails._id, getSIGRoleDetailsById,chapterRoleDetails._id]);
  

  useEffect(() => {
  }, [sigDetails,chapterDetails])
  //memberDetailsPlan 
  useEffect(() => {
    // setLoading(true);
    var memberDetailsPlan = memberDetails?.plans
    setPlanDetails(memberDetailsPlan)
    memberDetailsPlan?.forEach((memberDetail) => {
      getMembershipDetailsById(memberDetail.planId).then((res) => {
        if (res?.data?.status) {
          setLoading(false);
          setMembershipDetails(res?.data?.data);
        }
      });
    })
    //getAllmember
    getAllMemberData().then((res) => {
    });
  }, [getAllMemberData, getMembershipDetailsById, memberDetails?.plans, membershipDetails, setPlanDetails]);

  useEffect(() => {

  }, [])

  const getQualificationLabel = (id) => {
    const qualification = Qualifications.find((q) => q.id === id);
    return qualification ? qualification.label : "Unknown Qualification";
  };

  const getTypeOfInstitution = (id) => {
    const institution = TypeOfInstitution.find((q) => q.id === id);
    return institution ? institution.type : "Unknown institution";
  };
  const getindustries = (id) => {
    const industry = industries.find(data => data?.id === id);
    return industry ? industry.name : "-";
  };
  const getTypeOfEducation = (id) => {
    const Education = PrimarilyTeaching.find((q) => q.id === id);
    return Education ? Education.label : "";

  };
  const getOranginication = (id) => {
    const Education = PrimarilyTeaching.find((q) => q.id === id);
    return Education ? Education.label : "";
  };

  const getCurrentlyyTeaching = (id) => {
    const teaching = currently_teaching.find((q) => q.id === id);
    return teaching ? teaching.label : "";
  };
  const getPrimaryTeachingTeaching = (id) => {
    const teaching1 = PrimarilyTeaching.find((q) => q.id === id);
    return teaching1 ? teaching1.label : "";
  };
  useEffect(() => {


  }, [planDetails, membershipDetails])
  const handleTypesChange = (e) => {
    setPage(1)
    setSelectedType(e.target.value)
  }

  useEffect(() => {
    setMemberLoading(true);
    console.log(selectedType)
    const data = {
      page,
      itemsPerPage: 4,
      moduleType: selectedType || "",
    };

    const fetchModules = () => {
      return getAllModule(data);
    };

    const handleResponse = (res) => {
      const { data } = res;
      const { totalCount, modules } = data?.data || {};
      setMemberLoading(false);

      if (data?.status) {
        // Ensure totalCount and modules exist
        if (totalCount && modules) {
          const maxPages = Math.ceil(totalCount / 4); // Calculate total pages

          if (page <= maxPages) {
            // Only update the modules for the first page or if new data is available
            if (page === 1) {
              setAllModuces(modules);  // Replace previous state if on first page
            } else {
              setAllModuces((prev) => [...prev, ...modules]);  // Append to existing state if not first page
            }

            setIsFetching(false);  // Stop fetching indicator
          } else {
            console.warn('No more pages to fetch.');
          }
        } else {
          console.warn('No totalCount or modules found in response.');
        }
      } else {
        console.error('Error: Invalid data status');
      }
    };


    setTimeout(() => {
      fetchModules()
        .then(handleResponse)
        .catch((err) => {
          setMemberLoading(false);
          console.error(err);
        });
    }, 1000);
  }, [getAllModule, page, selectedType,]);


  //get All Divid By CreatebyId 
  useEffect(() => {
    // Check if there are more modules or chapters
    setHasMore(allmodules?.length > 0);
  
    const fetchRoleDetails = async (module) => {
      const createdBy = module?.createdBy;
      if (!createdBy?.id) {
        console.warn("Module with missing 'createdBy' ID", module);
        return; // Early return if ID is missing
      }
  
      try {
        if (createdBy.role === "PORTAL_CHAPTER_ADMIN") {
          const res = await getChapterRoleDetailsById(createdBy.id);
          const data = res?.data?.data;
          if (data) {
            console.log("Fetched Chapter Data:", data);
            setNewsChapter(data);
            setEventChapter(data);
            setForumChapter(data);
            setAnnouncementChapter(data);
            setGalleryChapter(data);
          } else {
            console.warn(`No data returned for Chapter ID ${createdBy.id}`);
          }
        } else {
          const res = await getSIGRoleDetailsById(createdBy.id);
          const data = res?.data?.data;
          if (data) {
            console.log("Fetched SIG Data:", data);
            setNewsSIG(data);
            setEventSIG(data);
            setForumSIG(data);
            setAnnouncementSIG(data);
            setGallerySIG(data);
          } else {
            console.warn(`No data returned for SIG ID ${createdBy.id}`);
          }
        }
      } catch (error) {
        console.error(`Error fetching role details for ID ${createdBy.id}:`, error);
      }
    };
  
    const modulesToProcess = allmodules;
  
    modulesToProcess?.forEach(fetchRoleDetails);
  }, [allmodules, currentScope, getChapterRoleDetailsById, getSIGRoleDetailsById]);
  
  useEffect(() => {
    console.log("Gallery Chapter:", galleryChapter);
    console.log("Gallery SIG:", gallerySIG);
    console.log("Announcement Chapter:", announcementChapter);
    console.log("Announcement SIG:", announcementSIG);
    console.log("News Chapter:", newsChapter);
    console.log("News SIG:", newsSIG);
    console.log("Event Chapter:", eventChapter);
    console.log("Event SIG:", eventSIG);
    console.log("Forum Chapter:", forumChapter);
    console.log("Forum SIG:", forumSIG);
  }, [galleryChapter, gallerySIG, announcementChapter, announcementSIG, newsChapter, newsSIG, eventChapter, forumChapter, eventSIG, forumSIG]);
  

  //when the scroll get the Data
  useEffect(() => {
    const memberFeed = document.querySelector(".member-customScroll");
    const handleScroll = () => {
      if (!memberFeed) return;
      const { scrollTop, scrollHeight, clientHeight } = memberFeed;
      // Check if scroll is near the bottom and if loading, hasMore, or isFetching are false
      if (scrollTop + clientHeight >= scrollHeight - 500 && !loading && hasMore && !isFetching) {
        setIsFetching(true);
        setPage(prevPage => prevPage + 1);
        console.log(selectedType, 'type')
        const data = {
          page: page + 1,
          itemsPerPage: 4,
          moduleType: selectedType || "",
        };
        getAllModule(data);
        // if(currentScope === "PORTAL_MEMBER"){
        //   getAllModule(data);
        // }else if(currentScope === "PORTAL_CHAPTER_ADMIN"){
        //   chapterFeed(data)
        // }
        // else{

        // } 
        memberFeed.removeEventListener('scroll', handleScroll);
      }
    };

    // Add scroll event listener
    if (memberFeed) {
      memberFeed.addEventListener('scroll', handleScroll);
    }

    // Cleanup scroll event listener
    return () => {
      if (memberFeed) {
        memberFeed.removeEventListener('scroll', handleScroll);
      }
    };
  }, [loading, hasMore, isFetching, page, getAllModule, selectedType]);

  //chapter Admin details
  useEffect(() => {
    if (memberDetails?.chapter) {
      getChapterById(memberDetails.chapter).then((res) => {
        setChapter(res?.data?.data?.title);
      });
    }

    //Sig Admin Details
    if (memberDetails?.sigGroups) {
      let groupName = [];
      memberDetails.sigGroups.forEach((id) => {
        getSigById(id).then((res) => {
          groupName.push(res?.data?.data?.groupName)
        });
      });
      setSig(groupName);
    }
  }, [getChapterById, memberDetails?.chapter, memberDetails?.sigGroups, getSigById]);

  //get planid byid
  const getMembership = (planId) => {
    setLoading(true);
    getMembershipDetailsById(planId).then((res) => {
      if (res.data?.status) {
        setLoading(false);
        const data = res.data?.data;
        setSelectedMembership(data);
      }
    });
  };

  //Date Format
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    // Check if the date is valid
    if (isNaN(date.getTime())) {
      return 'Invalid Date';
    }
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  //show theCard
  const showUserCard = (value) => {
    setSelectedMember(value);
    getMembership(value.planId);
    handleOpen();
  };

  // member Search
  const handleGetAllsearch = (e) => {
    const searchValue = e.target.value.toLowerCase(); // Convert search value to lowercase
    let filteredData = [];
    // Filter the data based on the search key
    if (searchValue.length > 0) {
      filteredData = allMemberDetails?.data.filter((item) =>
        item.firstName.toLowerCase().includes(searchValue)
      );
    } else {
      filteredData = [];
    }
    setFilterSearch(filteredData);
  };

  useEffect(() => {
  }, [filterSearch, chapter, sig]);

  useEffect(() => { }, [allMemberDetails]);

  //Gallery
  const Gallery = ({ item }) => {
    const [galleryList, setGalleryList] = useState([])
    const[category,setCategory]=useState([])
    useEffect(() => {
      // Set gallery list from item.list if it exists
      if (item?.list|| item) {
        setGalleryList(item.list);
        setCategory(item)
      }
    }, [item]);

    useEffect(() => {
      console.log(galleryList[0]?.createdBy, 'gl')
    }, [galleryList,category]);
    //getRoleId Divid The Style
    const getRoleStyle = (role) => {
      console.log(role,'galery')
      switch (role) {
        case 'PORTAL_SUPER_ADMIN':
          return 'portal-super-admin';
        case 'PORTAL_CHAPTER_ADMIN':
          return 'portal-chapter-admin';
        case 'PORTAL_SIG_ADMIN':
          return 'portal-sig-admin'
        default:
          return '';
      }
    };

    const handleGallery = (id) => {
      console.log(id, 'iddd')
      navigator(`/showGallery/${id}`)
    }
    // gallery 
    return (
      <div>
        {console.log(getRoleStyle(item?.createdBy?.role),'777')}
        <div key={item._id} className={getRoleStyle(galleryList[0]?.createdBy?.role)}>
          <div className="container-fluid">
            <div className="border w-100  h-auto gallery-bg-color border-radius-member-container mb-3">
              <div className="container p-1">
                <div className="row">
                  <div className="d-flex justify-content-between mb-2">
                    <div>
                      {
                        (galleryList[0]?.createdBy?.role === "PORTAL_CHAPTER_ADMIN" || galleryList[0]?.createdBy?.role === "PORTAL_SIG_ADMIN") ?
                          (<>
                            <Box display="flex" alignItems="center">
                              <Avatar
                                alt={galleryList[0]?.createdBy?.role === "PORTAL_CHAPTER_ADMIN" ? "Chapter Admin" : "SIG Admin"}
                                src={galleryList[0]?.createdBy?.role === "PORTAL_CHAPTER_ADMIN" ? galleryChapter?.imageUrl : gallerySIG?.imageUrl}
                              />
                              <Box >
                                <Stack >
                                  <Typography component="span" className="fw-bold" sx={{ marginLeft: 1, fontSize: '12px' }}>
                                    {galleryList[0]?.createdBy?.role === 'PORTAL_CHAPTER_ADMIN' ? <p className="Chapter-font-color">{galleryChapter?.roleDetails?.name}</p> : <p className="sig-feed-font-color">{gallerySIG?.roleDetails?.name}</p>}
                                  </Typography>
                                  <Typography component="span" className="  " sx={{ marginLeft: 1, fontSize: "12px" }} >
                                    {galleryList[0]?.createdBy.role === "PORTAL_CHAPTER_ADMIN" ? <p className="Chapter-font-color">Chapter </p> : <div className="sig-feed-font-color">SIG  Admin</div>}
                                  </Typography>
                                </Stack>
                              </Box>
                            </Box>
                          </>) : (
                            <>
                              <Box display="flex" alignItems="center" className="mt-2">
                                <Avatar alt="Admin" src="/path/to/avatar.jpg" className="ml-3" />
                                <Typography component="span" className="fs-6 fw-bold " sx={{ marginLeft: 1, color: '#ED6B1B' }} >
                                  Admin
                                </Typography>
                              </Box>
                            </>
                          )
                      }
                    </div>
                    <div className="news-badge-admin-container d-flex justify-content-end mt-1 ml-5">
                      <span class="badge badge-pill badge-primary news-badge-admin ">Gallery</span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <Container maxWidth="xl">
                    <div className="row">
                      <div className="col-md-5">
                        <img src={item?.list[0]?.imageUrl} width="100%" height="150px" alt="ffgh" />
                      </div>
                      <div className="col-md-6">
                        <h5 sx={{ marginLeft: 1, color: '#ED6B1B' }}>{category?.category}</h5>
                        <p sx={{ marginLeft: 1, color: '#ED6B1B' }}><span class="border border-secondary rounded px-3 py-1">{galleryList?.length}</span >{"     "} Available Images</p>

                        <div className="d-flex align-items-end justify-content-end">
                          <img src={buttonImage} onClick={() => handleGallery(item?._id)} alt="button" />
                        </div>
                      </div>
                    </div>
                  </Container>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const Article = ({ item }) => {
    useEffect(() => {
    }, [])

    //getRoleId Divid The Style
    const getRoleStyle = (role) => {
      switch (role) {
        case 'PORTAL_SUPER_ADMIN':
          return 'portal-super-admin';
        case 'PORTAL_CHAPTER_ADMIN':
          return 'portal-chapter-admin';
        case 'PORTAL_SIG_ADMIN':
          return 'portal-sig-admin'
        default:
          return '';
      }
    };
    // news 
    const handleViewNews = (id) => {
      navigator(`/viewJournal/${id}`)
    }
    return (
      <div>
        <div key={item._id} className={getRoleStyle(item.createdBy.role)}>
          <div className="container-fluid">
            <div className="border w-100   news-bg-color border-radius-member-container mb-3">
              <div className="container p-3">
                <div className="row">
                  <div className="d-flex justify-content-between mb-2">
                    <div>
                      {
                        (item.createdBy.role === "PORTAL_CHAPTER_ADMIN" || item.createdBy.role === "PORTAL_SIG_ADMIN") ?
                          (<>
                            <Box display="flex" alignItems="center">
                              <Avatar
                                alt={item?.createdBy?.role === "PORTAL_CHAPTER_ADMIN" ? "Chapter Admin" : "SIG Admin"}
                                src={item?.createdBy?.role === "PORTAL_CHAPTER_ADMIN" ? newsChapter?.imageUrl : newsSIG?.imageUrl}
                              />
                              <Box >
                                <Stack >
                                  <Typography component="span" className="fw-bold" sx={{ marginLeft: 1,  fontSize: '12px' }}>
                                    {item?.createdBy?.role === 'PORTAL_CHAPTER_ADMIN' ? <>{newsChapter?.roleDetails?.name}</> : <p className="sig-feed-font-color">{newsSIG?.roleDetails?.name}</p>}
                                  </Typography>
                                  <Typography component="span" className="  " sx={{ marginLeft: 1, color: '#ffff', fontSize: "12px" }} >
                                    {item.createdBy.role === "PORTAL_CHAPTER_ADMIN" ? <div className="Chapter-font-color">Chapter </div> : <div className="sig-font-color">SIG Admin </div>}
                                  </Typography>
                                </Stack>
                              </Box>
                            </Box>
                          </>) : (
                            <>
                              <Box display="flex" alignItems="center" className="mt-2">
                                <Avatar alt="Admin" src="/path/to/avatar.jpg" className="ml-3" />
                                <Typography component="span" className="fs-6 fw-bold " sx={{ marginLeft: 1, color: '#ED6B1B' }} >
                                  Admin
                                </Typography>
                              </Box>
                            </>
                          )
                      }
                    </div>
                    <div className="news-badge-admin-container d-flex justify-content-end mt-1 ml-5">
                      <span class="badge badge-pill badge-primary news-badge-admin ">News</span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <img src={item.thumbnailImage} alt="news" className="news-image-member-feed" width={"100%"} height={160} />
                  </div>
                  <div className="col-md-9 ps-0 ">
                    <div className="border  news-bg-artical-details-bg-color rounded h-auto" >
                      <Container>
                        <div className="fs-6 fw-bold  ">{item.headline}</div>
                        <div className="fs-6 news-author-color ">By {item.authorName}</div>
                        <div className="fs-6 news-body">{item?.summary.length > 175 ? item.summary.substring(0, 175) + "...." : item.summary}</div>

                      </Container>
                      <div className="fs-6  px-3 text-end news-color m-0 d-block">{formatDate(item.date)}</div>
                    </div>
                    <div className="d-flex justify-content-end mt-3">
                      <Button variant="contained" className="menus-color" onClick={() => handleViewNews(item._id)}
                      >View More</Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  //component for announcement
  const AnnouncementPost = ({ item }) => {
    const getRoleStyle = (role) => {
      switch (role) {
        case 'PORTAL_SUPER_ADMIN':
          return 'portal-super-admin-announcement';
        case 'PORTAL_CHAPTER_ADMIN':
          return 'portal-chapter-admin-announcement';
        case 'PORTAL_SIG_ADMIN':
          return 'portal-sig-admin-announcement'
        default:
          return '';
      }
    };
    return (
      <div>
        <div key={item._id} className={getRoleStyle(item.createdBy.role)}>
          <div className="container-fluid">
            <div className="border w-100   announcement-bg-color border-radius-member-container mb-3">
              <div className="container p-3">
                <div className="d-flex justify-content-between mb-2">
                  {/* <Box display="flex" alignItems="center">
                  <Avatar
                    alt={item?.createdBy?.role === "PORTAL_CHAPTER_ADMIN" ? "Chapter Admin" : "SIG Admin"}
                    src={item?.createdBy?.role === "PORTAL_CHAPTER_ADMIN" ? eventChapter?.imageUrl : eventSIG?.imageUrl}
                  />
                  <Box >
                    <Stack >

                      <Typography component="span" className="fw-bold" sx={{ marginLeft: 1, color: '#fff', fontSize: '12px' }}>
                        {item?.createdBy?.role === 'PORTAL_CHAPTER_ADMIN' ? <>{eventChapter?.roleDetails?.name}</> : <>{eventSIG?.roleDetails?.name}</>}
                      </Typography>
                      <Typography component="span" className="  " sx={{ marginLeft: 1, color: '#ffff', fontSize: "12px" }} >
                        {item.createdBy.role === "PORTAL_CHAPTER_ADMIN" ? <>Chapter </> : <>SIG </>}
                      </Typography>
                    </Stack>
                  </Box>
                </Box> */}
                  <div>
                    {
                      (item.createdBy.role === "PORTAL_CHAPTER_ADMIN" || item.createdBy.role === "PORTAL_SIG_ADMIN") ?
                        (<>
                          <Box display="flex" alignItems="center">
                            <Avatar
                              alt={item?.createdBy?.role === "PORTAL_CHAPTER_ADMIN" ? "Chapter Admin" : "SIG Admin"}
                              src={item?.createdBy?.role === "PORTAL_CHAPTER_ADMIN" ? announcementChapter?.imageUrl : announcementSIG?.imageUrl}
                            />
                            <Box >
                              <Stack >
                                <Typography component="span" className="fw-bold" sx={{ marginLeft: 1, color: '#104E29', fontSize: '12px' }}>
                                  {item?.createdBy?.role === 'PORTAL_CHAPTER_ADMIN' ? <>{announcementChapter?.roleDetails?.name}</> : <>{announcementSIG?.roleDetails?.name}</>}
                                </Typography>
                                <Typography component="span" className="  " sx={{ marginLeft: 1, color: '#104E29', fontSize: "12px" }} >
                                  {item.createdBy.role === "PORTAL_CHAPTER_ADMIN" ? <div className="Chapter-font-color">Chapter </div> : <div className="sig-font-color">SIG </div>}
                                </Typography>
                              </Stack>
                            </Box>
                          </Box>

                        </>) : (
                          <>
                            <Box display="flex" alignItems="center" className="mt-2">
                              <Avatar alt="Admin" src="/path/to/avatar.jpg" className="ml-3" />
                              <Typography component="span" className="fs-6 fw-bold " sx={{ marginLeft: 1, color: '#ED6B1B' }} >
                                Admin
                              </Typography>
                            </Box>
                          </>
                        )
                    }

                  </div>
                  <div className="news-badge-admin-container d-flex justify-content-end mt-1 ml-5">
                    <span class="badge badge-pill badge-primary news-badge-admin ">Announcement</span>
                  </div>
                </div>
                <div className="row">
                  <Container maxWidth="md">
                    <h5 className="text-center">{item?.heading}</h5>
                    <p className="text-center">{item?.text}</p>
                  </Container>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    )
  }
  // Component for Forum 1
  const ForumPost = ({ item }) => {
    const [category, setCategory] = useState()
    const [getCategoryById] = useLazyGetCategoryDetailsByIdQuery();
    useEffect(() => {
      getCategoryById(item.category).then((res) => {
        if (res?.data?.status) {
          setCategory(res?.data?.data.categoryName)
        }
      })
    }, [item.category, getCategoryById])

    useEffect(() => { }, [category])

    //get Role style
    const getRoleStyle = (role) => {
      switch (role) {
        case 'PORTAL_SUPER_ADMIN':
          return 'portal-super-admin-forum';
        case 'PORTAL_CHAPTER_ADMIN':
          return 'portal-chapter-admin-forum';
        case 'PORTAL_SIG_ADMIN':
          return 'portal-sig-admin-forum'
        default:
          return '';
      }
    };
    const handleViewForm = (id) => {
      navigator(`/readMoreForum/${id}`)
    }
    return (
      <>
        <div>
          <div key={item._id} className={getRoleStyle(item?.createdBy?.role)}>
            <div className="container-fluid">
              <div className="border w-100   forum-bg-color border-radius-member-container mb-3">
                <div className="container p-3">
                  <div className="row">
                    <div className="d-flex justify-content-between mb-2">
                      <div>
                        {
                          (item.createdBy.role === "PORTAL_CHAPTER_ADMIN" || item.createdBy.role === "PORTAL_SIG_ADMIN") ?
                            (<>
                              <Box display="flex" alignItems="center">
                                <Avatar
                                  alt={item?.createdBy?.role === "PORTAL_CHAPTER_ADMIN" ? "Chapter Admin" : "SIG Admin"}
                                  src={item?.createdBy?.role === "PORTAL_CHAPTER_ADMIN" ? newsChapter?.imageUrl : newsSIG?.imageUrl}
                                />
                                <Box >
                                  <Stack >
                                    <Typography component="span" className="fw-bold" sx={{ marginLeft: 1, color: '#fff', fontSize: '12px' }}>
                                      {item?.createdBy?.role === 'PORTAL_CHAPTER_ADMIN' ? <>{newsChapter?.roleDetails?.name}</> : <p className="sig-font-color">{newsSIG?.roleDetails?.name}</p>}
                                    </Typography>
                                    <Typography component="span" className="  " sx={{ marginLeft: 1, color: '#ffff', fontSize: "12px" }} >
                                      {item.createdBy.role === "PORTAL_CHAPTER_ADMIN" ? <div className="Chapter-font-color">Chapter </div> : <div className="sig-font-color">SIG Admin </div>}
                                    </Typography>
                                  </Stack>
                                </Box>
                              </Box>
                            </>) : (
                              <>
                                <Box display="flex" alignItems="center" className="mt-2">
                                  <Avatar alt="Admin" src="/path/to/avatar.jpg" className="ml-3" />
                                  <Typography component="span" className="fs-6 fw-bold " sx={{ marginLeft: 1, color: '#ED6B1B' }} >
                                    Admin
                                  </Typography>
                                </Box>
                              </>
                            )
                        }
                      </div>
                      <div className="news-badge-admin-container d-flex justify-content-end mt-1 ml-5">
                        <span class="badge badge-pill badge-primary news-badge-admin ">Forum</span>
                      </div>
                    </div>
                  </div>
                </div>
                <Container>
                  <div className="card mb-2 h-auto">
                    <div className="row forum-bg-artical-details-bg-color">
                      <div className="col-md-8">
                        <Container>
                          <h5 className=" mt-4 ">{item.headline}</h5>
                          <p className="text-justify mt-2 ">{item?.description.length > 75 ? item.description.substring(0, 75) + "...." : item.description}</p>
                        </Container>
                      </div>
                      <div className="col-md-4 ">
                        <Card className="border h-auto" >
                          <Card className="text-center border p-4 forum-bg-artical-details-bg-color">{category}</Card>
                          <Card className="text-center border p-4 forum-bg-artical-details-bg-color">{item.name}</Card>
                        </Card>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end">
                    <Button variant="contained" className="menus-color my-2" onClick={() => handleViewForm(item?._id)}
                    >View More</Button>
                  </div>
                </Container>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  //eventpost
  const EventPost = ({ item }) => {
    const getRoleStyle = (role) => {
      console.log(role,'role')
      switch (role) {
        case 'PORTAL_SUPER_ADMIN':
          return 'portal-super-admin-event';
        case 'PORTAL_CHAPTER_ADMIN':
          return 'portal-chapter-admin-event';
        case 'PORTAL_SIG_ADMIN':
          return 'portal-sig-admin-event'
        default:
          return '';
      }
    };
    const handlIndivalevent = (id) => {
      navigator(`/VieweventFeed/${id}`)
    }
    return (
      <div>

        <div key={item._id} className={getRoleStyle(item.createdBy.role)}>
          <div className="container-fluid">
            <div className="border w-100   event-bg-color border-radius-member-container mb-3">
              <div className="container p-3">
                <div className="d-flex justify-content-between mb-2">
                  {/* <Box display="flex" alignItems="center">
                    <Avatar
                      alt={item?.createdBy?.role === "PORTAL_CHAPTER_ADMIN" ? "Chapter Admin" : "SIG Admin"}
                      src={item?.createdBy?.role === "PORTAL_CHAPTER_ADMIN" ? eventChapter?.imageUrl : eventSIG?.imageUrl}
                    />
                    <Box >
                      <Stack >

                        <Typography component="span" className="fw-bold" sx={{ marginLeft: 1, color: '#fff', fontSize: '12px' }}>
                          {item?.createdBy?.role === 'PORTAL_CHAPTER_ADMIN' ? <>{eventChapter?.roleDetails?.name}</> : <>{eventSIG?.roleDetails?.name}</>}
                        </Typography>
                        <Typography component="span" className="  " sx={{ marginLeft: 1, color: '#ffff', fontSize: "12px" }} >
                          {item.createdBy.role === "PORTAL_CHAPTER_ADMIN" ? <>Chapter </> : <>SIG </>}
                        </Typography>
                      </Stack>
                    </Box>
                  </Box> */}
                  <div>
                    {
                      (item.createdBy.role === "PORTAL_CHAPTER_ADMIN" || item.createdBy.role === "PORTAL_SIG_ADMIN") ?
                        (<>
                          <Box display="flex" alignItems="center">
                            <Avatar
                              alt={item?.createdBy?.role === "PORTAL_CHAPTER_ADMIN" ? "Chapter Admin" : "SIG Admin"}
                              src={item?.createdBy?.role === "PORTAL_CHAPTER_ADMIN" ? newsChapter?.imageUrl : newsSIG?.imageUrl}
                            />
                            <Box >
                              <Stack >
                                <Typography component="span" className="fw-bold" sx={{ marginLeft: 1, fontSize: '12px' }}>
                                  {item?.createdBy?.role === 'PORTAL_CHAPTER_ADMIN' ? <>{newsChapter?.roleDetails?.name}</> : <p className="sig-feed-font-color">{newsSIG?.roleDetails?.name}</p>}
                                </Typography>
                                <Typography component="span" className="  " sx={{ marginLeft: 1, color: '#ffff', fontSize: "12px" }} >
                                  {item.createdBy.role === "PORTAL_CHAPTER_ADMIN" ? <div className="Chapter-font-color">Chapter </div> : <div className="sig-font-color">SIG Admin </div>}
                                </Typography>
                              </Stack>
                            </Box>
                          </Box>

                        </>) : (
                          <>
                            <Box display="flex" alignItems="center" className="mt-2">
                              <Avatar alt="Admin" src="/path/to/avatar.jpg" className="ml-3" />
                              <Typography component="span" className="fs-6 fw-bold " sx={{ marginLeft: 1, color: '#ED6B1B' }} >
                                Admin
                              </Typography>
                            </Box>
                          </>
                        )
                    }

                  </div>
                  <div className="news-badge-admin-container d-flex justify-content-end mt-1 ml-5">
                    <span class="badge badge-pill badge-primary news-badge-admin ">Event</span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-5 d-flex justify-content-center">
                    <img src={item.imageUrl} alt="i" className="news-image-member-feed" width={"100%"} height={160} />
                  </div>
                  <div className="col-md-7 ps-0 ">
                    <div className="event-member-details">
                      <div><h3 className="text-start mt-2 admin-font-event-color">{item.title}</h3></div>
                      <div> <p className="text-start admin-font-event-color"><CalendarMonthIcon style={{ color: "#000" }} />{"  " + new Date(item.startDate).toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                      })}, {"  " + item.place}</p></div>
                      {item.startDate && <div className="text-start admin-font-event-color"><p><AccessTimeIcon style={{ color: "#000" }} />{"  " + item.startTime}</p></div>}
                      <div className="d-flex justify-content-end">
                        <Button variant="contained" className="menus-color" onClick={() => handlIndivalevent(item._id)}
                        >View More</Button>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    )
  }
  //all module 
  const RenderComponent = ({ item }) => {
    switch (item.moduleType) {
      case 0:
        return <Article key={item._id} item={item} />;
      case 1:
        return <ForumPost key={item._id} item={item} />;
      case 2:
        return <EventPost key={item._id} item={item} />;
      case 3:
        return <Gallery key={item._id} item={item} />;
      case 4:
        return <AnnouncementPost key={item._id} item={item} />;
      default:
        return <p>No Data Found</p>;
    }
  };
  //profile Image
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setImageUpdate(event.target.files[0])
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
        setImageKey(prevKey => prevKey + 1);
        setShowUploadButton(true);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleIconClick = () => {
    document.getElementById("uploadImage").click();
  };

  //upload
  // const handleUpload = () => {
  //   if (!selectedImage) {
  //     console.error("No image selected");
  //     return;
  //   }
  //   const base64Regex = /^data:image\/(?:png|jpeg|jpg|gif);base64,/;
  //   const imageUrl = base64Regex.test(selectedImage) ? selectedImage : "";
  //   if (!imageUrl) {
  //     console.error("Selected image is not a valid base64 image");
  //     return;
  //   }
  //   const requestIdAndData = {
  //     id: memberDetails._id,
  //     data: {
  //       imageUrl,
  //     },
  //   };
  //   updateMember(requestIdAndData).then((res) => {
  //     if (res.data.status) {
  //       getPendingMemberById(memberDetails._id).then((res) => {
  //         if (res?.data?.status) {
  //           dispatch(setMemberDetails(res?.data?.data));
  //           setSelectedImage(imageUrl);
  //           toast.success("Image Uploded Successfully!!!");
  //           setShowUploadButton(false);

  //         } else {
  //           toast.error("Image Uploded Error!!!");
  //         }
  //       });
  //     }
  //   });
  // };
  const handleUpload = () => {
    const formData = new FormData();

    if (!imageUpdate) {
      console.error("No image selected");
      return;
    }

    // Log memberDetails.type to ensure correct type is being passed
    console.log("Member Type: ", memberDetails.type);

    // Append the member id
    formData.append("_id", memberDetails._id);
    const uploadField = memberDetails.type == 2
      ? "uploadInstitutionLogo"
      : memberDetails.type == 3
        ? "uploadOrganisationLogo"
        : "imageUrl";
    console.log(memberDetails?.type, uploadField)
    formData.append(uploadField, imageUpdate);
    // Use the updateMember API call to send FormData
    updateMember({ id: memberDetails?._id, data: formData }).then((res) => {
      console.log(res)
      if (res?.data?.status) {
        getPendingMemberById(memberDetails?._id).then((res) => {
          if (res?.data?.status) {
            dispatch(setMemberDetails(res?.data?.data));
            setSelectedImage(URL.createObjectURL(imageUpdate)); // Preview the uploaded image
            toast.success("Image Uploaded Successfully!!!");
            setShowUploadButton(false);
          }
          else {
            toast.error("Image Upload Error!!!");
          }
        });
      }
    });
  };
  const getMemberImage = (memberDetails) => {
    if (!memberDetails) return null;

    if (memberDetails.type === "2") {
      return memberDetails.uploadInstitutionLogo; // For Institutions
    } else if (memberDetails.type === "3") {
      return memberDetails.uploadOrganisationLogo; // For Organisations
    } else {
      return memberDetails.imageUrl || ''; // Default case (for type "0" and "1")
    }
  };

  // Helper function to get the member type label (Teacher, Student, etc.)
  const getMemberTypeLabel = (type) => {
    switch (type) {
      case "0":
        return "(Teacher)";
      case "1":
        return "(Student)";
      case "2":
        return "(Institution)";
      case "3":
        return "(Corporate)";
      default:
        return "(Unknown Type)";
    }
  };
  useEffect(() => {
    const fetchPendingMemberDetails = async () => {
      try {
        const res = await getPendingMemberById(memberDetails?._id);
        if (res?.data?.status) {
          let imageUrl;
          if (memberDetails.type === 3) {
            imageUrl = res?.data?.data?.uploadOrganisationLogo;
          } else if (memberDetails.type === 2) {
            imageUrl = res?.data?.data?.uploadInstitutionLogo;
          } else {
            imageUrl = res?.data?.data?.imageUrl;
          }
          setSelectedImage(imageUrl);
          dispatch(setMemberDetails(res?.data?.data));
        }
      } catch (error) {
        console.error("Failed to fetch member details:", error);
      }
    };

    if (memberDetails?._id) {
      fetchPendingMemberDetails();
    }
  }, [dispatch, memberDetails?._id, getPendingMemberById]);


  const handlingMobileProfileMember = () => {
    setOpenMobile(!openTabMobile)
    setOpenMemberTab(false)
  }
  const handlingMobileMember = () => {
    setOpenMemberTab(!openMemberTab)
    setOpenMobile(false)
  }
  return (
    <>
      <>
        <Box>
          {/* <TopHeader /> */}
          {/* <Box className="banner_text"></Box> */}
          {loading ? (
            <Spinner />
          ) : (
            <Container maxWidth="xl" className="view-member-container signika-font" >
              <div className="row p-0 ">
                <div className="col-12 d-none d-lg-block">

                  <div className="row">
                    <div className="col-lg-4"></div>
                    <div className="col-lg-4">
                      <div className="d-flex justify-content-center mt-3  "  >
                        <h2 className=" border px-5 text-center  border-shape p-2 " style={{ backgroundColor: '#4f8058', color: '#fff' }}>Your Wall</h2>
                      </div>
                    </div>
                    <div className="col-lg-2 mt-3">
                      <div className="form-group">
                        <select
                          id="modulesDropdown"
                          className="form-select"
                          value={selectedType}
                          onChange={handleTypesChange}
                        >
                          <option value="">--Choose --</option>
                          {Modules.map((module) => (
                            <option key={module.id} value={module.id}>
                              {module.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 d-block d-lg-none">
                  <div className="d-flex justify-content-between my-3"  >
                    <button className=" btn btn-primary Member-profile-Details-button px-3" onClick={handlingMobileProfileMember}>Profile Details</button>
                    <p className=" border  text-center  border-shape px-4 px-2 mobile-wall-post" style={{ backgroundColor: '#4f8058', color: '#fff' }}>Your Wall</p>
                    <button variant="contained" className="btn btn-primary Member-profile-Details-button px-3" onClick={handlingMobileMember}>Membership Details</button>
                  </div>
                </div>
                <div className="col-12 d-block d-lg-none">
                  <div className="d-flex justify-content-center mb-3">
                    <div className="form-group">
                      <select
                        id="modulesDropdown"
                        className="form-select"
                        value={selectedType}
                        onChange={handleTypesChange}
                      >
                        <option value="">--Choose --</option>
                        {Modules.map((module) => (
                          <option key={module.id} value={module.id}>
                            {module.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>

                {openTabMobile ? (
                  <div>
                    <div className="col-md-4">
                      <Card className="profile-container card w-100 ps-0">
                        <div className="position-relative member-log-container">
                          {/* <img src={profileBg} alt="profile-bg" id="profileImage" className="w-100" />
                          <div className=" position-absolute translate-middle member-logo">
                            {selectedImage ? (
                              <Avatar
                                key={imageKey} // Ensure the imageKey is used to force a re-render when the image changes
                                alt="Member Logo"
                                className="member-avatar"
                                src={selectedImage} // Prioritize selectedImage first for avatar
                                sx={{ width: 100, height: 100 }}
                              />
                            ) : (
                              <Avatar
                                key={imageKey}
                                alt="Member Logo"
                                className="member-avatar"
                                src={
                                  memberDetails?.type === "0"
                                    ? memberDetails?.imageUrl
                                    : memberDetails?.type === "1"
                                      ? memberDetails?.imageUrl
                                      : memberDetails?.type === "2"
                                        ? memberDetails?.uploadInstitutionLogo
                                        : memberDetails?.uploadOrganisationLogo
                                }
                                sx={{ width: 100, height: 100 }}
                              />
                            )}

                            <input
                              accept="image/jpeg, image/png"
                              style={{ display: "none", position: 'relative' }}
                              id="uploadImage"
                              type="file"
                              onChange={handleImageChange} // This will update selectedImage
                            />

                            {!showUploadButton && (
                              <CameraAltIcon
                                style={{
                                  width: "20%", cursor: "pointer", position: 'absolute', top: "70%", left: '50%', color: "white"
                                }}
                                onClick={handleIconClick} // Handle image upload icon click
                              />
                            )}

                            {showUploadButton && (
                              <Button
                                variant="contained"
                                style={{ marginTop: "20px" }}
                                onClick={handleUpload} // This triggers the upload process
                              >
                                Upload
                              </Button>
                            )}

                            <div className="position-absolute member-details member-name-color ">
                              <div className="row">
                                <h5 className="text-center fw-bold member-name-color ">
                                  {memberDetails?.type === "0"
                                    ? memberDetails?.fullName
                                    : memberDetails?.type === "1"
                                      ? memberDetails?.fullName
                                      : memberDetails?.type === "2"
                                        ? memberDetails?.institution
                                        : memberDetails?.organisationName}
                                </h5>
                                <p className="text-center member-name-color p-0">
                                  {memberDetails?.type === "0"
                                    ? "(Teacher)"
                                    : memberDetails?.type === "1"
                                      ? "(Student)"
                                      : memberDetails?.type === "2"
                                        ? "(Institution)"
                                        : "(Corporate)"}
                                </p>
                                <p style={{ textAlign: "center", fontSize: '12px' }} />
                                <p className="text-center ">
                                  {memberDetails?.userId}
                                </p>
                              </div>
                            </div>
                          </div> */}
                          <div className="membership-profile">
                            <div className="position-absolute translate-middle member-logo">
                              {/* Avatar: Show selectedImage first, fallback to memberDetails */}
                              <Avatar
                                key={imageKey} // Ensure the imageKey is used to force a re-render when the image changes
                                alt="Member Logo"
                                className="member-avatar"
                                src={selectedImage || 
                                  
                                  currentScope==="PORTAL_MEMBER"?getMemberImage(memberDetails):currentScope==="PORTAL_SIG_ADMIN"?sigDetails?.imageUrl:chapterDetails?.imageUrl} // Use helper function for image logic
                                sx={{ width: 100, height: 100 }}
                              />

                              {/* Hidden file input for image upload */}
                              <input
                                accept="image/jpeg, image/png"
                                style={{ display: "none" }}
                                id="uploadImage"
                                type="file"
                                onChange={handleImageChange} // This will update selectedImage
                              />

                              {/* Camera icon, visible only if not uploading */}
                              {!showUploadButton && (
                                <CameraAltIcon
                                  style={{
                                    width: "20%", cursor: "pointer", position: 'absolute', top: "70%", left: '50%', color: "white"
                                  }}
                                  onClick={handleIconClick} // Handle image upload icon click
                                />
                              )}

                              {/* Upload button, visible only if image is selected */}
                              {showUploadButton && (
                                <Button
                                  variant="contained"
                                  style={{ marginTop: "20px" }}
                                  onClick={handleUpload} // Trigger upload process
                                >
                                  Upload
                                </Button>
                              )}

                              {/* Member details (Name and Type) */}
                              <div className="position-absolute member-details member-name-color">
                                <div className="row">
                                  <h5 className="text-center fw-bold member-name-color pt-3">
                                    {
                                      memberDetails?.type === "0" || memberDetails?.type === "1"
                                        ? memberDetails?.fullName
                                        : memberDetails?.type === "3"
                                          ? memberDetails?.organisationName
                                          : memberDetails?.type === "2"
                                            ? memberDetails?.institution
                                            : sigDetails?.roleDetails?.name ? sigDetails?.roleDetails?.name : chapterDetails?.roleDetails?.name
                                    }
                                  </h5>
                                  <p className="text-center member-name-color">
                                 {currentScope==="PORTAL_MEMBER"?getMemberTypeLabel(memberDetails?.type):currentScope==="PORTAL_SIG_ADMIN"?"SIG Admin":"Chapter Admin"}   
                                  </p>
                                  <p className="text-center ">
                                    {memberDetails?.userId}
                                  </p>
                                  <p style={{ textAlign: "center", fontSize: '12px' }} />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="member-details-personal-container">
                          <div >
                            <div class="d-flex member-details-personal-container h-auto">
                              <div className="row">
                                <div >
                                  <p className="py-3"><span className="ms-3  " style={{ color: '#5c815c', border: '1px solid #5c815c', padding: "4px", borderRadius: "50%", marginLeft: "-5px" }} ><EmailRoundedIcon style={{ fontSize: '18px' }} /></span>
                                    <span span className="ms-3">{currentScope==="PORTAL_MEMBER"?memberDetails?.email:currentScope==="PORTAL_SIG_ADMIN"?sigDetails?.roleDetails?.email:chapterDetails?.roleDetails?.email}</span>
                                  </p>
                                  <p className="mt-3"><span className="ms-3 " style={{ color: '#5c815c', border: '1px solid #5c815c', padding: "4px", borderRadius: "50%", marginLeft: "-5px", }} ><PhoneRoundedIcon style={{ fontSize: '18px' }} /></span>
                                  <span span className="ms-3">+91{currentScope==="PORTAL_MEMBER"?memberDetails?.mobileNumber:currentScope==="PORTAL_SIG_ADMIN"?sigDetails?.roleDetails?.mobile:chapterDetails?.roleDetails?.mobile}</span>
                                  </p>
                                  <p><span className="ms-3" style={{ color: '#5c815c', border: '1px solid #5c815c', padding: "4px", borderRadius: "50%", marginLeft: "-5px" }} ><LocationOnIcon style={{ fontSize: '18px' }} /></span>
                                  <p span className="ms-3 m-0 d-block">{currentScope==="PORTAL_MEMBER"?memberDetails?.address:currentScope==="PORTAL_SIG_ADMIN"?sigDetails?.roleDetails?.address:chapterDetails?.roleDetails?.address}</p>
                                    <p span className="ms-3 m-0 d-block">{memberDetails?.city}</p>
                                    <p span className="ms-3 m-0 d-block">{memberDetails?.pinCode}</p>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex justify-content-center member-details-personal-container  mt-5 mb-2">
                            <hr className="w-75 member-details-personal-container"></hr>
                          </div>
                          <div className="  member-details-personal-container  ">
                            <div className="text-center"><span className="ms-3  mb-5" style={{ color: '#5c815c', border: '1px solid #5c815c', padding: "5px", borderRadius: "50%", marginLeft: "-5px" }} ><StarBorderIcon /></span></div>
                            <Container>
                              <h6 className="mb-3 mt-3 text-justify  ">
                                {/* {getQualificationLabel(memberDetails?.studying)} */}
                                {memberDetails?.type === "0" ? (
                                  getCurrentlyyTeaching(memberDetails?.teaching)
                                ) : memberDetails?.type === "1" ? (
                                  getQualificationLabel(memberDetails?.studying)
                                ) : memberDetails?.type === "2" ? (
                                  getTypeOfEducation(memberDetails.levelOfInstitution)
                                ) : (
                                  getOranginication(memberDetails?.industry)
                                )}

                              </h6>
                              <h6 className="mb-3 mt-3 text-justify  ">

                                {memberDetails?.type === "0" ? (
                                  memberDetails?.designation
                                ) : memberDetails?.type === "1" ? (
                                  <> {memberDetails?.course}</>
                                ) : memberDetails?.type === "2" ? (
                                  getTypeOfInstitution(memberDetails?.typeOfInstitution)
                                ) : (
                                  getTypeOfInstitution(memberDetails?.typeOfOrganisation)
                                )}

                              </h6>
                              <h6 className="mb-3 mt-3 text-justify ">
                                {memberDetails?.type === "0" ? (
                                  <>
                                    <p>{memberDetails?.institution}</p>
                                    <p>{getPrimaryTeachingTeaching(memberDetails?.primarilyTeachingLevel)}</p>
                                    <p>{getTypeOfInstitution(memberDetails?.natureWork)}</p>
                                  </>

                                ) : memberDetails?.type === "1" ? (
                                  <> <p>{memberDetails?.institution}</p>
                                    <p>{memberDetails?.institutionCity}</p>
                                    <p> {memberDetails?.institutionState}</p>
                                  </>
                                ) : memberDetails?.type === "2" ? (
                                  <><p className="fw-bold">Teacher Count:<span className="fw-light ml-2">{memberDetails?.teachersInInstitution}</span></p>
                                    <p className="fw-bold"> Student Count:<span className="fw-light ml-2">{memberDetails?.studentsInInstitution}</span></p>
                                    <p className="fw-bold">Website:<span className="fw-light ml-2">{memberDetails?.websiteAddressInstitution}</span></p>
                                    <p className="fw-bold">  Established Year:<span className="fw-light ml-2">{memberDetails?.institutionEstablished}</span></p>
                                  </>
                                ) : (
                                  <><p className="fw-bold"> Employees Count  :<span className="fw-light ml-2">{memberDetails?.employees}</span></p><br />
                                    <p className="fw-bold">Websites:<span className="fw-light ml-2">{memberDetails?.websiteAddressOrganisation}</span></p>
                                    <p className="fw-bold"> Located:<span className="fw-light ml-2">{memberDetails?.headOfficeLocated}</span></p>
                                    <p className="fw-bold">  Established  Year:<span className="fw-light ml-2">{memberDetails?.organisationEstablished
                                    }</span></p>
                                  </>
                                )}
                              </h6>
                            </Container>
                          </div>
                        </div>
                      </Card>
                    </div>
                  </div>
                ) : (<></>)}
                {openMemberTab ? (
                  <>
                    <div className="row p-0">
                      <div className="col-md-4"></div>
                      <div className="col-md-4"></div>
                      <div className="col-md-4 p-0">
                        {
                          planDetails && planDetails?.map((plan, i) => {
                            return (<>
                              <Card className="membershipDetails-card mt-2">
                                <CardContent sx={{ textAlign: "center", marginBottom: '1px' }}>
                                  <div className="border mt-4  p-2 member-ship-plan-details" style={{}}>
                                    <Typography className=" fw-bold mt-2  memberShip-name">
                                      {/* {membershipDetails && membershipDetails?.planName} */}
                                      Member Since
                                    </Typography>
                                    <p className="text-center m-0 " style={{ fontSize: '15px' }}>{membershipDetails?.planName}</p>
                                    <p className="text-center m-0 chapter-border-font-color">{plan.dummyStartDate.split("T")[0]
                                      .split("-")
                                      .reverse()
                                      .join("-")}
                                    </p>
                                    <div className="fw-bold" style={{ fontSize: '12px' }}>
                                      <Typography >
                                        <span className=" fw-bold" style={{ fontSize: '12px' }}>
                                          {" "}
                                          Expiry On:
                                        </span>{" "}
                                        {/* {memberDetails?.planEndDate &&
                                  memberDetails?.planEndDate
                                    .split("T")[0]
                                    .split("-")
                                    .reverse()
                                    .join("-")} */}
                                        <p className="text-center">{plan.dummyEndDate.split("T")[0]
                                          .split("-")
                                          .reverse()
                                          .join("-")} </p>
                                      </Typography>
                                      <p className="renew-membership" >Renew MemberShip</p>
                                    </div>
                                  </div>
                                  <div className="border mt-4  pt-3 report-non-member " style={{ backgroundColor: '#f39ea6', width: "100%", fontSize: "12px", borderRadius: '9px' }}>
                                    <p className="fw-bold ">Report Non Receipt of Journal Copy</p>
                                  </div>
                                  <div className="text-white">
                                  </div>
                                  <div style={{ position: 'relative', display: 'inline-block' }}>
                                    <SearchIcon
                                      style={{
                                        position: 'absolute',
                                        left: '85%',
                                        top: '50%',
                                        transform: 'translateY(-50%)',
                                        pointerEvents: 'none',
                                        color: 'grey',
                                      }}
                                    />
                                    <input type="text"
                                      className="customTextField"
                                      style={{}}
                                      fullWidth
                                      placeholder="Search Member"
                                      onChange={(e) => handleGetAllsearch(e)}
                                    />
                                  </div>
                                  {filterSearch && filterSearch.length > 0 ? (
                                    <ui>
                                      {filterSearch && filterSearch.map((item, i) => (
                                        <div key={i}>
                                          <li onClick={(e) => showUserCard(item)}>
                                            {item.firstName + " " + item.lastName}
                                          </li>
                                        </div>
                                      ))}
                                    </ui>
                                  ) : (
                                    <></>
                                  )}
                                  <div className="card mt-3" style={{ backgroundColor: '#d9d9d9' }}>
                                    <h5 className="mt-3">Your Chapter</h5>
                                    <p className="chapter-border-font-color">{chapter ? chapter : "---"}</p>
                                  </div>
                                  <hr style={{ height: "1px", color: "#333",  /* old IE */backgrounColor: "#333" }}></hr>
                                  {/* <h5 className="mb-2 member-name-color">Your SIG</h5> */}
                                  <div className="card mt-3" style={{ backgroundColor: '#d9d9d9' }}>
                                    <h5 className="mt-3">Your SIG</h5>
                                    <p className="chapter-border-font-color">{sig.length > 0 ? sig : "---"}</p>
                                  </div>
                                  <hr style={{ height: "1px", color: "#333",  /* old IE */backgrounColor: "#333" }}></hr>
                                  {/* <h5 className="mb-2 member-name-color">Library Resource</h5> */}
                                  <a
                                    href="https://journals.eltai.in/index.php/jelt/index"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="m-0 d-block"
                                  >
                                    <h5 className="mb-3 member-name-color">JELT</h5>
                                  </a>
                                  <a
                                    href="https://journals.eltai.in/index.php/jtrel"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="m-0 d-block"
                                  >
                                    <h5 className="mb-2 member-name-color">JTREL</h5>
                                  </a>
                                  <a
                                    href="https://journals.eltai.in/index.php/jtelt"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="m-0 d-block"
                                  >
                                    <h5 className="mb-2 member-name-color">JTELT</h5>
                                  </a>
                                  {/* <h5 className="mb-2 member-name-color">JTELT</h5> */}
                                  {/* <h5 className="mb-2 member-name-color">ELT Practiontor</h5>
                                  <h5 className="mb-2 member-name-color">News Letter</h5> */}
                                  <h5 className="mb-2 member-name-color" style={{ color: "white" }}><Link to="/India-Reads">India Reads</Link></h5>
                                  <h5 className="mb-2 member-name-color" style={{ color: "white" }}><Link to="/view-Gallery">Galleries</Link></h5>
                                </CardContent>
                              </Card>
                            </>)
                          })
                        }
                      </div>
                    </div>
                  </>
                ) : (<></>)}
                <div className="col-lg-2 d-none d-lg-block  p-0">
                  <Card className="profile-container card w-100 ps-0">
                    <div className="position-relative member-log-container">
                      {/* <img src={profileBg} alt="profile-bg" id="profileImage" className="w-100" /> */}
                      <div className="membership-profile">
                        <div className="position-absolute translate-middle member-logo">
                          {/* Avatar: Show selectedImage first, fallback to memberDetails */}
                          <Avatar
                            key={imageKey} // Ensure the imageKey is used to force a re-render when the image changes
                            alt="Member Logo"
                            className="member-avatar"
                            src={selectedImage ||currentScope==="PORTAL_MEMBER"?getMemberImage(memberDetails):currentScope==="PORTAL_SIG_ADMIN"?sigDetails?.imageUrl:chapterDetails?.imageUrl} 
                            sx={{ width: 100, height: 100 }}
                          />

                          {/* Hidden file input for image upload */}
                          <input
                            accept="image/jpeg, image/png"
                            style={{ display: "none" }}
                            id="uploadImage"
                            type="file"
                            onChange={handleImageChange} // This will update selectedImage
                          />

                          {/* Camera icon, visible only if not uploading */}
                          {!showUploadButton && (
                            <CameraAltIcon
                              style={{
                                width: "20%", cursor: "pointer", position: 'absolute', top: "70%", left: '50%', color: "white"
                              }}
                              onClick={handleIconClick} // Handle image upload icon click
                            />
                          )}

                          {/* Upload button, visible only if image is selected */}
                          {showUploadButton && (
                            <Button
                              variant="contained"
                              style={{ marginTop: "20px" }}
                              onClick={handleUpload} // Trigger upload process
                            >
                              Upload
                            </Button>
                          )}

                          {/* Member details (Name and Type) */}
                          <div className="position-absolute member-details member-name-color">
                            <div className="row">
                              <h5 className="text-center fw-bold member-name-color pt-3">
                                {
                                  memberDetails?.type === "0" || memberDetails?.type === "1"
                                    ? memberDetails?.fullName
                                    : memberDetails?.type === "3"
                                      ? memberDetails?.organisationName
                                      : memberDetails?.type === "2"
                                        ? memberDetails?.institution
                                        : sigDetails?.roleDetails?.name ? sigDetails?.roleDetails?.name :chapterDetails?.roleDetails?.name
                                }
                              </h5>
                              <p className="text-center member-name-color">
                              {currentScope==="PORTAL_MEMBER"?getMemberTypeLabel(memberDetails?.type):currentScope==="PORTAL_SIG_ADMIN"?"SIG Admin":"Chapter Admin"}
                              </p>
                              <p className="text-center ">
                                {memberDetails?.userId}
                              </p>
                              <p style={{ textAlign: "center", fontSize: '12px' }} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="member-details-personal-container">
                      <div >
                        <div class="d-flex member-details-personal-container h-auto">
                          <div className="row">
                            <div >
                              <p class="pt-3"><span className="ms-3 " style={{ color: '#5c815c', border: '1px solid #5c815c', padding: "4px", borderRadius: "50%", marginLeft: "-5px" }} ><EmailRoundedIcon style={{ fontSize: '18px' }} /></span>
                                <span span className="ms-3">{currentScope==="PORTAL_MEMBER"?memberDetails?.email:currentScope==="PORTAL_SIG_ADMIN"?sigDetails?.roleDetails?.email:chapterDetails?.roleDetails?.email}</span>
                              </p>
                              <p className="mt-3"><span className="ms-3 " style={{ color: '#5c815c', border: '1px solid #5c815c', padding: "4px", borderRadius: "50%", marginLeft: "-5px", }} ><PhoneRoundedIcon style={{ fontSize: '18px' }} /></span>
                                <span span className="ms-3">+91{currentScope==="PORTAL_MEMBER"?memberDetails?.mobileNumber:currentScope==="PORTAL_SIG_ADMIN"?sigDetails?.roleDetails?.mobile:chapterDetails?.roleDetails?.mobile}</span>
                              </p>
                              <p><span className="ms-3" style={{ color: '#5c815c', border: '1px solid #5c815c', padding: "4px", borderRadius: "50%", marginLeft: "-5px" }} ><LocationOnIcon style={{ fontSize: '18px' }} /></span>
                                <div style={{ marginLeft: '45px', marginTop: '-30px' }}>
                                  <p span className="ms-3 m-0 d-block">{currentScope==="PORTAL_MEMBER"?memberDetails?.address:currentScope==="PORTAL_SIG_ADMIN"?sigDetails?.roleDetails?.address:chapterDetails?.roleDetails?.address}</p>
                                  <p span className="ms-3 m-0 d-block">{memberDetails?.city}</p>
                                  <p span className="ms-3 m-0 d-block">{memberDetails?.pinCode}</p>
                                </div>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-center member-details-personal-container  mt-5 mb-2">
                        <hr className="w-75 member-details-personal-container"></hr>
                      </div>
                      <div className="  member-details-personal-container  ">
                        <div className="text-center"><span className="ms-3  mb-5" style={{ color: '#5c815c', border: '1px solid #5c815c', padding: "5px", borderRadius: "50%", marginLeft: "-5px" }} ><StarBorderIcon /></span></div>
                        <Container>
                          <h6 className="mb-3 mt-3 text-justify  ">
                            {/* {getQualificationLabel(memberDetails?.studying)} */}
                            {memberDetails?.type === "0" ? (
                              getCurrentlyyTeaching(memberDetails?.teaching)
                            ) : memberDetails?.type === "1" ? (
                              getQualificationLabel(memberDetails?.studying)
                            ) : memberDetails?.type === "2" ? (
                              getTypeOfEducation(memberDetails.levelOfInstitution)
                            ) : (
                              getOranginication(memberDetails?.industry)
                            )}

                          </h6>
                          <h6 className="mb-3 mt-3 text-justify  ">

                            {memberDetails?.type === "0" ? (
                              memberDetails?.designation
                            ) : memberDetails?.type === "1" ? (
                              <> {memberDetails?.course}</>
                            ) : memberDetails?.type === "2" ? (
                              getTypeOfInstitution(memberDetails?.typeOfInstitution)
                            ) : (
                              getindustries(memberDetails?.typeOfOrganisation)
                            )}

                          </h6>
                          <h6 className="mb-3 mt-3 text-justify ">
                            {memberDetails?.type === "0" ? (
                              <>
                                <p>{memberDetails?.institution}</p>
                                <p>{getPrimaryTeachingTeaching(memberDetails?.primarilyTeachingLevel)}</p>
                                <p>{getTypeOfInstitution(memberDetails?.natureWork)}</p>
                              </>

                            ) : memberDetails?.type === "1" ? (
                              <> <p>{memberDetails?.institution}</p>
                                <p>{memberDetails?.institutionCity}</p>
                                <p> {memberDetails?.institutionState}</p>
                              </>
                            ) : memberDetails?.type === "2" ? (
                              <><p className="fw-bold">Teacher Count:<span className="fw-light member-details-count">{memberDetails?.teachersInInstitution}</span></p>
                                <p className="fw-bold"> Student Count:<span className="fw-light member-details-count">{memberDetails?.studentsInInstitution}</span></p>
                                <p className="fw-bold">Website:<span className="fw-light member-details-count">{memberDetails?.websiteAddressInstitution}</span></p>
                                <p className="fw-bold " style={{ fontSize: "14px" }}>  Established Year:<span className="fw-light member-details-count">{memberDetails?.institutionEstablished}</span></p>
                              </>
                            ) : (
                              <><p className="fw-bold"> Employees Count :<span className="fw-light member-details-count">{memberDetails?.employees}</span></p>
                                <p className="fw-bold">Website:<span className="fw-light member-details-count">{memberDetails?.websiteAddressOrganisation}</span></p>
                                <p className="fw-bold"> Located:<span className="fw-light member-details-count">{memberDetails?.headOfficeLocated}</span></p>
                                <p className="fw-bold" style={{ fontSize: "14px" }}>  Established  Year:<span className="fw-light member-details-count">{memberDetails?.organisationEstablished
                                }</span></p>
                              </>
                            )}
                          </h6>
                        </Container>
                      </div>
                    </div>
                  </Card>
                </div>
                <div className="col-lg-8 ">
                  <Container maxWidth="xl" >
                    <div className="member-customScroll z-3">
                      {/* {console.log(chapterAllModules,'1263')}
                        {(currentScope === "PORTAL_MEMBER")?
                        <> {allmodules.map((item) => {
                          return (
                            <>
                              <RenderComponent key={item._id} item={item} />
                            </>
                          )
                        })}</>:(currentScope === "PORTAL_CHAPTER_ADMIN")?
                        <>{chapterAllModules.map((item) => {
                          return (
                            <>
                              <RenderComponent key={item._id} item={item} />
                            </>
                          )
                        })}
                        </>:<></>
                      }   */}
                      <> {allmodules && allmodules.map((item) => {
                        return (
                          <>
                            <RenderComponent key={item._id} item={item} />
                          </>
                        )
                      })}</>
                      {memberLoading && <div className="d-flex justify-content-center"><CircularProgress style={{ 'color': '#44733b' }} /></div>}
                    </div>
                  </Container>
                </div>
                <div
                  className="col-lg-2 d-none d-lg-block "
                  style={{ position: "relative" }}
                >
                  <div className="row">
                    <div className="border membership-title">
                      <p className="text-center mb-0 w-100 border-0" style={{ padding: "6px", textWrap: "nowrap" }}>Member Ship</p>
                    </div>
                    {
                      planDetails && planDetails?.map((plan, i) => {
                        return (<>
                          <Card className="membershipDetails-card mt-2">
                            <CardContent sx={{ textAlign: "center", marginBottom: '1px' }}>
                              <div className="border mt-4  p-2 member-ship-plan-details" style={{}}>
                                <Typography className=" fw-bold mt-2  memberShip-name">
                                  {/* {membershipDetails && membershipDetails?.planName} */}
                                  Member Since
                                </Typography>
                                <p className="text-center m-0 " style={{ fontSize: '15px' }}>{membershipDetails?.planName}</p>
                                <p className="text-center m-0 chapter-border-font-color">{plan.dummyStartDate.split("T")[0]
                                  .split("-")
                                  .reverse()
                                  .join("-")
                                }</p>
                                <div className="fw-bold" style={{ fontSize: '12px' }}>
                                  <Typography >
                                    <span className=" fw-bold" style={{ fontSize: '12px' }}>
                                      {" "}
                                      Expiry On:
                                    </span>{" "}
                                    {/* {memberDetails?.planEndDate &&
                                  memberDetails?.planEndDate
                                    .split("T")[0]
                                    .split("-")
                                    .reverse()
                                    .join("-")} */}
                                    <p className="text-center">{plan.dummyEndDate.split("T")[0]
                                      .split("-")
                                      .reverse()
                                      .join("-")}</p>
                                  </Typography>
                                  <p className="renew-membership" >Renew MemberShip</p>
                                </div>
                              </div>
                              <div className="border mt-4 mb-2  pt-3 report-non-member " style={{ backgroundColor: '#f39ea6', width: "100%", fontSize: "12px", borderRadius: '9px' }}>
                                <p className="fw-bold ">Report Non Receipt of Journal Copy</p>
                              </div>
                              <div className="text-white">
                              </div>
                              <div style={{ position: 'relative', display: 'inline-block' }}>
                                <SearchIcon
                                  style={{
                                    position: 'absolute',
                                    left: '85%',
                                    top: '50%',
                                    transform: 'translateY(-50%)',
                                    pointerEvents: 'none',
                                    color: 'grey',
                                  }}
                                />
                                <input type="text"
                                  className="customTextField"
                                  style={{}}
                                  fullWidth
                                  placeholder="Search Member"
                                  onChange={(e) => handleGetAllsearch(e)}
                                />
                              </div>
                              {filterSearch && filterSearch.length > 0 ? (
                                <ui>
                                  {filterSearch && filterSearch.map((item, i) => (
                                    <div key={i}>
                                      <li onClick={(e) => showUserCard(item)}>
                                        {item.firstName + " " + item.lastName}
                                      </li>
                                    </div>
                                  ))}
                                </ui>
                              ) : (
                                <></>
                              )}
                              <div className="card mt-3" style={{ backgroundColor: '#d9d9d9' }}>
                                <h5 className="mt-3">Your Chapter</h5>
                                <p className="chapter-border-font-color">{chapter ? chapter : "---"}</p>
                              </div>
                              <hr style={{ height: "1px", color: "#333",  /* old IE */backgrounColor: "#333" }}></hr>
                              {/* <h5 className="mb-2 member-name-color">Your SIG</h5> */}
                              <div className="card mt-3" style={{ backgroundColor: '#d9d9d9' }}>
                                <h5 className="mt-3">Your SIG</h5>
                                <p className="chapter-border-font-color">{sig.length > 0 ? sig : "---"}</p>
                              </div>
                              <hr style={{ height: "1px", color: "#333",  /* old IE */backgrounColor: "#333" }}></hr>
                              {/* <h5 className="mb-2 member-name-color">Library Resource</h5> */}
                              <a
                                href="https://journals.eltai.in/index.php/jelt/index"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="m-0 d-block"
                              >
                                <h5 className=" member-name-color">JELT</h5>
                              </a>
                              <a
                                href="https://journals.eltai.in/index.php/jtrel"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="m-0 d-block"
                              >
                                <h5 className=" member-name-color">JTREL</h5>
                              </a>
                              <a
                                href="https://journals.eltai.in/index.php/jtelt"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="m-0 d-block"
                              >
                                <h5 className="mb-2 member-name-color">JTELT</h5>
                              </a>
                              {/* <h5 className="mb-2 member-name-color">ELT Practiontor</h5>
                              <h5 className="mb-2 member-name-color">News Letter</h5> */}
                              <h5 className="mb-2 member-name-color"><Link to="/India-Reads">India Reads</Link></h5>
                              <h5 className="mb-2 member-name-color"><Link to="/view-Gallery">Galleries</Link></h5>
                            </CardContent>
                          </Card>
                        </>)
                      })
                    }
                  </div>
                  <div>
                  </div>
                </div>
              </div>
            </Container>
          )}
        </Box>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Card sx={{ maxWidth: 345, border: "none", boxShadow: "none" }}>
              <CardMedia
                sx={{ height: 200, width: 200, mx: "auto" }}
                image="/assets/images/login/userLogo.png"
                title="Member Details"
              />
              <CardContent>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  className="fw-bold fs-3"
                >
                  {selectedMember &&
                    selectedMember.firstName + " " + selectedMember.lastName}
                  (Member)
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  <span className="fw-bold">Phone:</span>{" "}
                  {selectedMember && selectedMember.phone}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  <span className="fw-bold">Email:</span>
                  {selectedMember && selectedMember.email}
                </Typography>
                <br />
                <Typography
                  variant="body2"
                  color="text.secondary"
                  className="fw-bold"
                >
                  {selectedMembership && selectedMembership.planName}
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  className="fw-bold"
                >
                  <span className="text-black"> Expiry On:</span>{" "}
                  {selectedMember?.planEndDate &&
                    selectedMember?.planEndDate
                      .split("T")[0]
                      .split("-")
                      .reverse()
                      .join("-")}
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  size="small"
                  className="ms-auto"
                  onClick={handleClose}
                >
                  close
                </Button>
              </CardActions>
            </Card>
          </Box>
        </Modal>
      </>
      {/* {currentScope === "PORTAL_CHAPTER_ADMIN" && (
        <>
          <ChapterAdminPortal></ChapterAdminPortal>
        </>
      )}
      {currentScope === "PORTAL_SIG_ADMIN" && (
        <>
          <SigAdminPortal></SigAdminPortal>
        </>
      )} */}
    </>
  );
}

export default Viewmember;
