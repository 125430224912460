import React from 'react'
import { Outlet } from 'react-router-dom'

const MemberRoute = () => {
  return (
    <>
      <Outlet></Outlet>
      </>
  )
}

export default MemberRoute
