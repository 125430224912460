import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { useLazyGetAllGalleryQuery } from '../../app/services/galleryService';
import { useNavigate } from 'react-router-dom';

export default function ImageBox({ itemData }) {
  const [allGalleryData, setAllGalleryData] = useState();
  const [getAllGallery] = useLazyGetAllGalleryQuery()
  const navigate=useNavigate()
  useEffect(() => {
    getAllGallery().then(res => {
      if (res?.data?.status) {
        setAllGalleryData(res?.data?.data)
      }
    })
  }, [getAllGallery])

  function routeToViewDescription(listId) {
    const album = allGalleryData.find(item =>{
      const index=item.list.findIndex(i => i._id === listId)
      if(index>=0){
        return item
      }
      return null
    })
    navigate('/showGallery/'+album._id)
  }

  return (
    <Box className="w-100 h-100 my-5">
      <ImageList variant="masonry" cols={3} gap={8}>
        {itemData && itemData.map((item) => (
          <ImageListItem key={item.img}>
            <img
              srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
              src={`${item.img}?w=248&fit=crop&auto=format`}
              alt={item.title}
              loading="lazy"
              className='rounded-5 box-shadow-100 cursor-pointer'
              onClick={() => { routeToViewDescription(item.id) }}
            />
          </ImageListItem>
        ))}
      </ImageList>
    </Box>
  );
}