import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { setCredentials, logOut } from '../auth/authSlice'


// base query for api
const baseQuery = fetchBaseQuery({
    baseUrl:process.env.REACT_APP_API_URL,
    // global configuration for the api
  refetchOnMountOrArgChange: 15,
// refetchOnFocus: true,
    prepareHeaders: (headers,{getState}) => {
        const token = getState().auth.accessToken
        if (token) {
            headers.set("authorization", `Bearer ${token}`)
        }
        return headers
    }
})

// wrapper with refresh basequery
const baseQueryWithReauth = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions)
    if (result?.error?.status === 401) {
        // send refresh token to get new access token 
        const refreshResult = await baseQuery('auth/refreshToken', api, extraOptions)
        if (refreshResult?.data) {
            const user = api.getState().auth.user
            // store the new token 
            api.dispatch(setCredentials({ ...refreshResult.data, user }))
            // retry the original query with new access token 
            result = await baseQuery(args, api, extraOptions)
        } else {
            api.dispatch(logOut())
        }
    }

    return result
}

// main api for all endpoints
export const api = createApi({
    baseQuery: baseQueryWithReauth,
    tagTypes: ['Chapter', 'Gallery','testimonial','SIGGROUP','Events',"Member","Contact","Announcement","AnnualConference"],
    endpoints: builder => ({})
})