import React from 'react'
import Box from '@mui/material/Box';
import './ourArchitects.css'
import { Container } from '@mui/material';
import image from '../../../assets/images/Architects/image.png'
const OurArchitects = () => {
  return (
    <div className='Architects-page-containter'>
      <Box className="pt-lg-5 pt-3">
        <header className='Architects-header d-flex align-items-center justify-content-center'>
          <h2 className='signika-fontOEC text-center'>Our Architects</h2>
        </header>
        <div className='row'>
          <div class="col-lg-4">
            <div className='d-none d-lg-block'>
              <div class="vl"></div>
            </div>
            <div className='d-flex justify-content-center align-items-center mt-5 architects-owner-image-container'>
              <img src={image} alt='owner' className='architects-owner-image' />
              <div className='d-none d-lg-block d-xl-block'>
                <div class="vl-bottom"></div>
              </div>
            </div>
          </div>
          <div class="col-lg-8">
            <div className='mt-5'>
              <div className='text-center'>
                <h3 className='architects-owner-name'> Padmashri S. Natarajan (1901-1977)</h3>
                <p className='our-founder'>(Our Founder)</p>
              </div>
              <Container>
                <div className='text-justify '>
                  <p className='signika-font-Architects text-justify'>
                    Padmashri S. Natarajan, a prominent figure in the realm of education in India,
                    was a trailblazer whose legacy continues to shape the teaching profession. Born in 1901, he embarked on his career as a science teacher at St. Gabriel’s High School, Broadway, Chennai. His passion for education, coupled with his dedication,
                    made him a beloved figure among his students, colleagues, and school administrators.
                  </p>
                  <p className='signika-font-Architects text-justify'>
                    Natarajan’s commitment to advancing the teaching profession was exemplified by his pivotal role in the formation of the English Language Teachers’ Association of India (ELTAI) in 1969. Under his guidance, ELTAI became a cornerstone in the development of English language education in the country. His vision extended beyond the confines of classrooms; he firmly believed
                    that teaching should be revered as a noble profession, akin to medicine or any other esteemed occupation.
                  </p>
                  <p className='signika-font-Architects text-justify'>
                    One of Natarajan’s significant contributions was his involvement with the South Indian Teachers’ Union (SITU), where he served as the Secretary. Collaborating with leaders like Shri M.S. Sabesan, he transformed SITU into a powerhouse advocating for teachers’ rights. Natarajan’s endeavours weren’t limited to India; he established international connections with teachers’
                    unions in countries like the UK and the US, broadening the horizons of Indian educators.
                  </p>
                  <p className='signika-font-Architects text-justify'>
                    A staunch advocate for teacher welfare, Natarajan played a pivotal role in the creation of the SITU Teachers’ Protection Fund. This initiative provided life insurance to teachers, ensuring their financial security. His proactive approach yielded remarkable results; the fund’s management was so exemplary that it earned accolades, even after nationalization under LIC.
                  </p>
                  <p className='signika-font-Architects text-justify'>
                    Natarajan’s influence extended to policy-making bodies. He actively participated in the Secondary Education Commission and the Kothari Commission, where he served on various sub-committees. His insights and expertise profoundly impacted the shaping of educational policies in post-independence India.
                  </p>
                  <p className='signika-font-Architects text-justify'>
                    His commitment to academic excellence was further evident in his founding of SITU Publications, a venture aimed at publishing supplementary materials for students. This initiative encouraged teachers to contribute, fostering a culture of knowledge-sharing within the profession.
                  </p>
                  <p className='signika-font-Architects text-justify'>Natarajan’s dedication wasn’t confined to administrative roles; he recognized the importance of fostering a spirit of camaraderie among educators. He played a pivotal role in the establishment of subject-specific teachers’ associations, including the Association for Mathematics Teachers of India. These associations, nurtured by Natarajan’s vision, continue to thrive today, enriching the educational landscape.</p>
                  <p className='signika-font-Architects text-justify'>
                    One of Natarajan’s most significant achievements was his involvement in the drafting of the UNESCO document on the rights, duties, and responsibilities of the Teaching Profession. Representing the World Confederation of the Organizations of Teaching Profession (WCOTP), his contributions resonated globally, shaping international perspectives on teaching.
                  </p>
                  <p className='signika-font-Architects text-justify'>Beyond his professional achievements, Natarajan was renowned for his ability to forge enduring friendships. His network included esteemed academicians and influential personalities, leveraging these connections to further the cause of education.</p>
                  <p className='signika-font-Architects text-justify mb-5'>S. Natarajan’s indomitable spirit, relentless dedication, and visionary leadership have left an indelible mark on the educational landscape of India. His legacy lives on through the institutions he helped establish and the generations of teachers he inspired. ELTAI, one of his cherished endeavours, stands as a testament to his enduring impact, serving as a beacon guiding English language teachers in India.</p>
                </div>
              </Container>
            </div>
          </div>
        </div>
      </Box>
    </div>
  )
}

export default OurArchitects