import React, { useEffect, useState } from 'react'
import { TextField,Typography,Button, Container} from '@mui/material'
import { useForm } from "react-hook-form";
import { useNavigate,useParams } from 'react-router-dom';
import {useCreateImportantAnnouncementMutation,useLazyGetImportantAnnouncementDetailsByIdQuery,
  useUpdateImportantAnnouncementMutation
} from '../../../app/services/importantAnnouncementService'
import { toast } from "react-toastify";
import Spinner from "../../../Component/spinner/spinner";
import { useSelector } from 'react-redux';
import {
  selectCurrentScope,
  selectUserId,
  // selectCurrentRoleId,
} from "../../../app/auth/authSlice";
import { selectCurrentSig } from "../../../app/features/sigSlice";
import { selectCurrentChapterAdmin } from "../../../app/features/chapterAdminSlice";

const AddEditImportAnnounment = () => {
   const {
    register,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {text:'',heading:''},})
    const [createAnnouncement]=useCreateImportantAnnouncementMutation()
    const [getAnnouncementById]=useLazyGetImportantAnnouncementDetailsByIdQuery()
    const [updateAnnouncement]=useUpdateImportantAnnouncementMutation()
    const [loading,setLoading]=useState(false)
    const navigate=useNavigate()
    const {id}=useParams()
    const memberScope = useSelector(selectCurrentScope);
    // const roleId = useSelector(selectCurrentRoleId);
    const userId = useSelector(selectUserId)
    const sigDetails = useSelector(selectCurrentSig);
    const sigId = sigDetails._id;
    const chapterDetails = useSelector(selectCurrentChapterAdmin);
    // const [getAnnouncementById]=useGetImportantAnnouncementByIdQuery()
    const chapterId = chapterDetails._id;

    //update Save
   const onSaveAnnounment=(data)=>{
  // var tableRoute='/';
    // switch (memberScope) {
    //   case "PORTAL_SUPER_ADMIN":
    //     tableRoute = "/admin/JournalTable";
    //     break;
    //   case "PORTAL_SIG_ADMIN":
    //     tableRoute = "/sigAdmin/JournalTable";
    //     break;
    //   case "PORTAL_CHAPTER_ADMIN":
    //     tableRoute = "/chapterAdmin/JournalTable";
    //     break;
    //   default:
    //     tableRoute = "/";
    //     break;
    // }
    if (
      (userId && memberScope === "PORTAL_SUPER_ADMIN") ||
      (userId && memberScope === "PORTAL_SIG_ADMIN") ||
      (userId && memberScope === "PORTAL_CHAPTER_ADMIN")
    ) {
      data.createdBy = {
        id: userId,
        role: memberScope,
      };
    }
    if (userId && memberScope === "PORTAL_SIG_ADMIN") {
      data.SIGGroupsId = [sigId];
    } else if (userId && memberScope === "PORTAL_CHAPTER_ADMIN") {
      data.chapterId = [chapterId];
    }
     const updateData={
      id:id,
      data:{
        ...data
      }
     }
     if(id){
      updateAnnouncement(updateData).then((res)=>{
        if(res?.data?.status){
          toast.success("Announcement Updated Successfully!!!");
          setLoading(false);
          reset();
          if (memberScope === "PORTAL_SUPER_ADMIN") {
            navigate("/admin/importannoucenment");
          }
          if (memberScope === "PORTAL_CHAPTER_ADMIN") {
            navigate("/chapterAdmin/importannoucenment");
          }
          if (memberScope === "PORTAL_SIG_ADMIN") {
            navigate("/sigAdmin/importannoucenment");
          }
        }
      })
     }
     else{
      createAnnouncement(data).then((res)=>{
        if(res?.data?.status){
          toast.success("Announcement Created Successfully!!!");
              setLoading(false);
           reset();
           if (memberScope === "PORTAL_SUPER_ADMIN") {
             navigate("/admin/importannoucenment");
           }
           if (memberScope === "PORTAL_CHAPTER_ADMIN") {
             navigate("/chapterAdmin/importannoucenment");
           }
           if (memberScope === "PORTAL_SIG_ADMIN") {
             navigate("/sigAdmin/importannoucenment");
           }
        }
        else{
          toast.error(res.data?.err?.message)
        }
      })
      .catch(err=>console.error(err))
     }
     }
  

   //GetById
   useEffect(()=>{
    getAnnouncementById(id).then((res)=>{
      if(res?.data?.status)
      {
        Object.entries(res?.data?.data).forEach(([key, value]) => {
          setValue(key, value)
        })
      }
    })
   },[getAnnouncementById,id,setValue])
  return (
    <div>
         <div className='pt-1 '>
          <div className='p-0'>
            <div className=' Architects-header d-flex align-items-center justify-content-center '>
              <h2 className='signika-font-aboutUs text-center '>{id?"Edit Announcement":"Add Announcement"}</h2>   
            </div>
          </div>
        </div>
       <Container maxWidth='xl'>
       <div className="row">
          <div className="col-md-9">
            <span style={{width:'250px',marginLeft:'20px'}}></span>
          </div>
          <div className="col-md-3 mt-3">
            <Button
              onClick={() => window.history.back()}
              variant="contained"
              className="menus-color"
              style={{ float: "right" }}
            >
              Back
            </Button>
          </div>
        </div>
       </Container>
       <div className='mt-5 mb-5'>
      
       {loading ?<Spinner/>:(
        <>
        <form onSubmit={handleSubmit(onSaveAnnounment)}>
         <Container maxWidth="xl">
         <div className="row mb-3">
              <div className="col-md-6">
                <TextField
                  InputLabelProps={{ shrink: true }}
                  style={{ width: "100%" }}
                  id="heading"
                  label={
                    <Typography className="text-dark">
                      Heading <span style={{ color: "red" }}>*</span>
                    </Typography>
                  }
                  variant="outlined"
                  {...register("heading", {
                    required: "Please Enter Heading",
                  })}
                  error={!!errors?.heading}
                  helperText={errors?.heading?.message}
                  placeholder='Please Enter Heading'
                />
              </div>
              <div className="col-md-6 ">
              <div className="col-md-12 position-relative">
                      <span style={{ left: '95%', top: '76%' }} class="position-absolute fs-5 badge translate-middle text-primary">
                        {150 - (watch('text')?.length != null || 'NAN' || undefined) ? 150 - watch('text')?.length : 150}                        
                        <span class="visually-hidden">Content length</span>
                      </span>
                      <textarea
                        style={{
                          border: !!errors?.text
                            ? "2px solid red"
                            : "2px solid lightgrey",
                        }}
                        cols="50"
                        rows="5"
                        id="message"
                        {...register("text", {
                          required: "Please Enter Your Text",
                        })}
                        placeholder="Please Enter Your Text"
                        className="form-control"
                        maxLength={150}
                      ></textarea>
                      <p className="text-danger">{errors?.text?.message}</p>
                    </div>
              </div>
            </div>
         </Container>
           
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 5,
              }}
            > 
              <Button
                variant="contained"
                className="menus-color"
                type="submit"
                sx={{ mr: 3 }}
              >{id?"Update":"Submit"}
              </Button>
            </div>
          </form>
        </>
       )}
       </div>
    </div>
  )
}

export default AddEditImportAnnounment