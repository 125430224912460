import { Modal, Container } from "@mui/material";
import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TopHeader from "../../Component/Header/TopHeader";
import {
  usePaginationNewsMutation,
  useDeleteJournalMutation,
} from "../../app/services/journalServices";
import { Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { selectCurrentScope, selectUserId } from "../../app/auth/authSlice";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import Tooltip from "@material-ui/core/Tooltip";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { selectCurrenSigRole } from "../../app/features/sigSlice";
import { selectCurrenChapterRole } from "../../app/features/chapterAdminSlice";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Spinner from "../../Component/spinner/spinner";
import "./journalTabel.css";


//table head for News
function EnhancedNewsTableHead() {
  const headCells = [
    {
      id: "headline",
      numeric: false,
      disablePadding: true,
      label: "Headline",
    },
    {
      id: "summary",
      numeric: false,
      disablePadding: true,
      label: "Summary",
    },
    {
      id: "authorName",
      numeric: false,
      disablePadding: true,
      label: "Author Name",
    },
    {
      id: "rejectReason",
      numeric: false,
      disablePadding: true,
      label: "Reject Reason",
    },
    {
      id: "status",
      numeric: false,
      disablePadding: true,
      label: "Status",
    },
    { id: "actions", numeric: true, disablePadding: false, label: "Actions" },
  ];

  return (
    <>
      <TableHead>
        <TableRow className="menus-color">
          {headCells.map((headCell) => (
            <TableCell
              sx={{ textAlign: "center", color: "#ffff" }}
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
            >
              {headCell.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    </>
  );
}

function JournalTable() {
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const sigRoleDetails = useSelector(selectCurrenSigRole);
  const roleDetails = useSelector(selectCurrenChapterRole);
  const memberScope = useSelector(selectCurrentScope);
  const userId = useSelector(selectUserId);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalPage, setTotalPage] = useState(0);
  const [deleteJournal] = useDeleteJournalMutation();
  const [getPaginationJournal] = usePaginationNewsMutation();
  const navigate = useNavigate();
  const [selectedNews, setSelectedNews] = useState(null);
  const [allNews, setAllNews] = useState([]);

  //Modal usage
  const handleOpen = (id) => {
    setOpen(true);
    setSelectedNews(id);
  };
  const handleClose = () => setOpen(false);

  //Based on memberscope
  const getLinkPath = (rowId) => {
    switch (memberScope) {
      case "PORTAL_SUPER_ADMIN":
        return rowId
          ? `/admin/addEditJournal/${rowId}`
          : "/admin/addEditJournal";
      case "PORTAL_SIG_ADMIN":
        return rowId
          ? `/sigAdmin/addEditJournal/${rowId}`
          : "/sigAdmin/addEditJournal";
      case "PORTAL_CHAPTER_ADMIN":
        return rowId
          ? `/chapterAdmin/addEditJournal/${rowId}`
          : "/chapterAdmin/addEditJournal";
      default:
        return "";
    }
  };

  //getAllPendingEventList
  const fetchData = useCallback(() => {
    var data = {
      type: 0,
      page: page + 1,
      itemsPerPage: rowsPerPage,
      role: (memberScope)? memberScope:""
    };
    getPaginationJournal(data)
      .then((res) => {
        setLoading(false);
        setAllNews(res?.data?.data?.newsDetails || []);
        setTotalPage(res?.data?.data.totalCount || 0);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [getPaginationJournal, page, rowsPerPage, memberScope])

  //calling event data
  useEffect(() => {
    setRowsPerPage(5);
    fetchData(); // Fetch data on component mount
  }, [page, rowsPerPage, fetchData]); // Update useEffect dependencies

  //Delete function
  const handleJournalDelete = () => {
    setLoading(true);
    deleteJournal(selectedNews).then(
      (res) => {
        if (res.data?.status) {
          setLoading(false);
          toast.success("News Deleted Successfully!!!")
          handleClose();
          fetchData();
        } else {
          setLoading(false);
          toast.error(res.data?.err?.message)
        }
      }
    );
  };

  //page Click for News
  const handleNewsPageClick = ({ selected }) => {
    setPage(selected);
  };

  //sliced News
  const slicedNews = allNews.slice(0, rowsPerPage); // Adjust slicing

  //calculate pagecount
  const pageCount = Math.ceil(totalPage / rowsPerPage);

  //Modal usage
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "5px",
    p: 4,
  };

  return (
    <>
      <TopHeader />
      <Box>
        <div className='pt-1 '>
          <div className='p-0'>
            <div className=' Architects-header d-flex align-items-center justify-content-center '>
              <h2 className='signika-fontOEC text-center '>News List</h2>
            </div>
          </div>
        </div>

      </Box>
      <Container maxWidth="xl">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <br />
            <span className="ms-2"></span>
          </div>
          <div className="d-flex justify-content-end">
            <Link to={getLinkPath()}>
              <Button
                variant="contained"
                className="menus-color"
                type="submit"
                style={{
                  backgroundColor: "#1769aa",
                  color: "#fff",
                  padding: "6px 16px",
                  borderRadius: "4px",
                }}
              >
                ADD News
              </Button>
            </Link>
          </div>
        </div>
      </Container>
      {loading ? (
        <Spinner />
      ) : (
        <Container maxWidth="lg">
          <Box className="memberTable ms-2">
            <Paper sx={{ width: "100%", marginBottom: "20px" }}>
              {/* <EnhancedNewsTableToolbar /> */}
              <TableContainer>
                <Table aria-labelledby="tableTitle">
                  <EnhancedNewsTableHead />
                  <TableBody>
                    {slicedNews.length > 0 ? (
                      slicedNews.map((news, index) => (
                        <TableRow
                          hover
                          key={news._id}
                          sx={{
                            cursor: "pointer",
                            backgroundColor:
                              index % 2 === 0 ? "#f0f0f0" : "white",
                          }}
                        >
                          <TableCell align="center">{news.headline}</TableCell>
                          <TableCell width={220} align="center"><p style={{ textAlign: 'justify' }} className="text-black">{news?.summary.length > 25 ? news?.summary.slice(0, 25) + "..." : news?.summary}</p></TableCell>
                          <TableCell align="center">
                            {news.authorName ? <p style={{ textAlign: 'justify', fontSize: 'inherit', lineHeight: 'inherit' }} className="text-black">{news.authorName}</p> : '--------'}
                          </TableCell>
                          <TableCell width={200} align="center">{news.rejectionReason ? <p style={{ textAlign: 'justify', fontSize: 'inherit', lineHeight: 'inherit' }} className="text-black text-center">{news.rejectionReason}</p> : "----------"}</TableCell>
                          <TableCell align="center">
                            {news.isApproved === 1 && (
                              <span style={{ color: "Green", fontWeight: "600" }}>
                                Approved
                              </span>
                            )}
                            {news.isApproved === 0 && (
                              <span
                                style={{
                                  color: "#766bcb",
                                  fontWeight: "600",
                                }}
                              >
                                Pending
                              </span>
                            )}
                            {news.isApproved === 2 && (
                              <span style={{ color: "Red", fontWeight: "600" }}>
                                Rejected
                              </span>
                            )}
                          </TableCell>
                          <TableCell align="center">
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "8px" }}>
                              <Tooltip title="View">
                                <IconButton
                                  aria-label="EditIcon"
                                  onClick={() =>
                                    navigate("/viewJournal/" + news._id)
                                  }
                                >
                                  <VisibilityIcon className="icon-vieweye-color" />
                                </IconButton>
                              </Tooltip>
                              {(memberScope === "PORTAL_SUPER_ADMIN"
                                ? news?.createdBy?.id === userId
                                : Object.keys(roleDetails).length !== 0
                                  ? news?.createdBy?.id === roleDetails._id &&
                                  news?.isApproved !== 1
                                  : news?.createdBy?.id === sigRoleDetails._id &&
                                  news?.isApproved !== 1) && (
                                  <>
                                    <Tooltip title="Delete">
                                      <IconButton
                                        aria-label="delete"
                                        onClick={() => {
                                          handleOpen(news._id);
                                        }}                                >
                                        <DeleteIcon style={{ color: "red" }} />
                                      </IconButton>
                                    </Tooltip>
                                    <Link to={getLinkPath(news?._id)} className="p-0">
                                      <Tooltip title="Edit">
                                        <IconButton aria-label="edit">
                                          <EditIcon style={{ color: "green" }} />
                                        </IconButton>
                                      </Tooltip>
                                    </Link>
                                  </>
                                )}
                            </div>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <>
                        <TableRow>
                          <TableCell colSpan={5}>
                            <p className="text-black text-center">
                              No Data Avaiable
                            </p>
                          </TableCell>
                        </TableRow>
                      </>
                    )}
                  </TableBody>
                  <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box
                      sx={style}
                      className="d-flex align-items-center flex-column gap-3"
                    >
                      <Typography
                        id="modal-modal-title"
                        style={{ color: "#611f1e" }}
                        variant="h6"
                        component="h2"
                      >
                        Are You Surely Want to Delete this News?
                      </Typography>
                      <div>
                        <Button
                          className="mx-2"
                          variant="contained"
                          style={{
                            backgroundColor: "#dc3545",
                            color: "white",
                            padding: "5px 15px",
                            borderRadius: "4px",
                          }}
                          onClick={() => handleJournalDelete()}
                        >
                          Yes
                        </Button>
                        <Button
                          variant="outlined"
                          style={{
                            color: "#2e7d32",
                            border: "1px solid rgba(46, 125, 50, 0.5)",
                            padding: "5px 15px",
                            borderRadius: "4px",
                          }}
                          onClick={handleClose}
                        >
                          No
                        </Button>
                      </div>
                    </Box>
                  </Modal>
                </Table>
              </TableContainer>
            </Paper>
            {slicedNews.length > 0 ? (
              <ReactPaginate
                pageCount={pageCount} // Calculate total pages
                pageRangeDisplayed={2}
                marginPagesDisplayed={1}
                onPageChange={handleNewsPageClick}
                containerClassName={"pagination"}
                activeClassName={"active"}
                disabledClassName={"disablebtn"}
                previousLabel={pageCount > 1 && page >= 1 ? "Previous" : null}
                nextLabel={pageCount > 1 && page + 1 !== pageCount ? "Next" : null}
                forcePage={page}
              />
            ) : (<></>)}
            <br/><br/>
          </Box>
        </Container>
      )}
    </>
  );
}

export default JournalTable;
