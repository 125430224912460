import React, { useCallback, useEffect, useState } from 'react'
import TopHeader from '../../../Component/Header/TopHeader'
import ImageBox from '../../../Component/HomePage/ImageBox'
import { usePaginationAllGalleryListMutation } from '../../../app/services/galleryService'
import Spinner from '../../../Component/spinner/spinner'
import ReactPaginate from "react-paginate";
import { Container } from '@mui/material';

function ViewGallery() {
  const [getAllGalleryList] = usePaginationAllGalleryListMutation()
  const [page, setPage] = useState(0)
  const itemsPerPage = 12;
  const [imageData, setImageData] = useState();
  const [loading, setLoading] = useState(false);
  let [totalCount, setTotalCount] = useState(0);
  const [isDisplay, setDisplay] = useState(false);

  const getAllGalleryListItem = useCallback(() => {
    const data = {
      page: page + 1,
      itemsPerPage: itemsPerPage
    }
    setLoading(true)
    getAllGalleryList(data).then(res => {
      setLoading(false)
      if (res?.data?.status) {
        setDisplay(true);
        const imgData = res?.data?.data?.list.map(item => ({ img: item.imageUrl, title: item.description, id: item._id }))
        setImageData((prev) => prev = imgData)
        if (imgData.length === 0 && page > 0) {
          setPage(page - 1);
        }
        setTotalCount(res?.data?.data.totalCount || 0);
      } else {
        setLoading(false);
        setDisplay(false);
        setImageData([]);
        setTotalCount(0);
      }
    }).catch(err => {
      setLoading(false)
      console.error(err);
    })
  }, [getAllGalleryList, page, itemsPerPage]);

  useEffect(() => {
    getAllGalleryListItem()
  }, [getAllGalleryListItem])

  //handle page click
  const handlePageClick = ({ selected }) => {
    setPage(selected);
  };

  //calculate pagecount
  const pageCount = Math.ceil(totalCount / itemsPerPage);

  return (
    <div className='Architects-page-containter pt-lg-5 pt-3'>
      <TopHeader />
      {loading ? (
        <Spinner />
      ) : (
        <>
          <header className='Architects-header d-flex align-items-center justify-content-center'>
            <h2 className='signika-fontOEC text-center'>Galleries</h2>
          </header>
          <Container maxWidth="lg">
            <ImageBox itemData={imageData} />
            {!isDisplay && (
              <h3 className="d-flex justify-content-center text-danger mt-5">
                No Data Available
              </h3>
            )}
          </Container>
        </>
      )}
      <div className='pb-lg-5 pb-3'>
        {imageData?.length > 0 ? (
          <ReactPaginate
            pageCount={pageCount}
            pageRangeDisplayed={itemsPerPage}
            marginPagesDisplayed={2}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            activeClassName={"active"}
            previousLabel={page > 0 ? "Previous" : null}
            nextLabel={page + 1 < pageCount ? "Next" : null}
            forcePage={page}
            disabledClassName={"disablebtn"}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}

export default ViewGallery