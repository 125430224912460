import { configureStore } from '@reduxjs/toolkit'
import { api } from './services/api'
import { setupListeners } from '@reduxjs/toolkit/query'
import authReducer from './auth/authSlice'
import memberReducer from './features/memberSlice'
import sigReducer from './features/sigSlice'
import { combineReducers } from '@reduxjs/toolkit'
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import chapterAdminSlice from './features/chapterAdminSlice'

const persistConfig={
  key:'root',
  version:1,
  storage,
}

//reducer in Module
const reducers=combineReducers({
  auth:authReducer,
  member:memberReducer,
  sig:sigReducer,
  chapter:chapterAdminSlice,
  [api.reducerPath]:api.reducer,
})

//persistedReducer
const persistedReducer=persistReducer(persistConfig,reducers)

//store
export const store = configureStore({
  reducer: persistedReducer,
  middleware:(getDefaultMiddleWare)=>getDefaultMiddleWare({serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },}).concat(api.middleware)
})

//dispatch
setupListeners(store.dispatch)