import React from 'react'

const Error = () => {
  return (
    <div className='d-flex justify-content-center align-items-center m-5' style={{height:'50vh'}}>
      <p className='text-danger fs-3 fw-bold'>Page you are requesting is not available !!!!</p>
    </div>
  )
}

export default React.memo(Error)