import React, { useEffect, useState } from 'react';
import '../../style/css/banner.css';
import Box from '@mui/material/Box';
import { selectCurrentScope } from '../../app/auth/authSlice';
import { useSelector } from 'react-redux';
import image from '../../assets/images/banner/mobile.jpg'

// Import your images
import banner1 from '../../assets/images/banner/eltai.jpg';
import banner2 from '../../assets/images/banner/eltai2.jpg';
import banner3 from '../../assets/images/banner/eltai3.jpg';
// import banner4 from '../../assets/images/banner/Eltai-four.png';

function NavBar() {
  const currentScope = useSelector(selectCurrentScope);

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [nextImageIndex, setNextImageIndex] = useState(1);
  const [animationClass, setAnimationClass] = useState('slide-in-from-right');
  const [textPositionClass, setTextPositionClass] = useState('text-left');
  const [textColour, setTextColour] = useState();
  const [images, setImages] = useState([]);
  const [bannerQuotes, setBannerQuotes] = useState({ quoteLine1: 'ELTAI brings teachers together, fostering growth through shared experiences,', quoteLine2: 'collaborative learning, and professional development that shapes the future.' });




  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize(); // Check on mount
  
    // Animation Slide
    const interval = setInterval(() => {
      setAnimationClass('slide-out-to-left');
  
      setTimeout(() => {
        setCurrentImageIndex(nextImageIndex);
        
        // Adjust this line to use images.length instead of a hardcoded value
        setNextImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  
        // Different Quotes
        if (nextImageIndex === 0) {
          setBannerQuotes({
            quoteLine1: "ELTAI brings teachers together, fostering growth through shared experiences,",
            quoteLine2: "collaborative learning, and professional development that shapes the future.",
          });
        } else if (nextImageIndex === 1) {
          setBannerQuotes({
            quoteLine1: "As an ELTAI member, you’re not just participating in a network—you’re leading",
            quoteLine2: "a movement that elevates the standard of English education across India.",
          });
        } else if (nextImageIndex === 2) {
          setBannerQuotes({
            quoteLine1: "With ELTAI, you gain more than membership—you join a thriving community ",
            quoteLine2: "of passionate educators committed to reshaping English teaching in India.",
          });
        }
  
        if (nextImageIndex === 1 || nextImageIndex === 2) {
          setTextColour('white');
        } else {
          setTextColour('white');
        }
  
        // Quotes Animation
        if (nextImageIndex === 0 || nextImageIndex === 2) {
          setAnimationClass('slide-in-from-right');
          setTextPositionClass('text-left');
        } else {
          setAnimationClass('slide-in-from-right');
          setTextPositionClass('text-right');
        }
      }, 1000);  // Wait for the slide-out animation to complete
    }, 5000);  // Change image every 5 seconds
  
    return () => clearInterval(interval);
  }, [nextImageIndex, currentImageIndex, images.length]);

  

  const handleResize = () => {
    if (window.innerWidth < 575) { // Adjust the width according to your breakpoints
      const images = [image, image, image, image ];
      setImages(images);
      // Quotes color 
        setTextColour('white');
    } else {
      const images = [banner1, banner2, banner3];
      setImages(images);
    }
  };

  // console.log("images", images);

  return (
    <>
      <Box className="text-center">
        <section className="section-notch">
          <section className="section-notch-down">
            <aside
              className="responsive-banner first "
              style={{ backgroundImage: `url(${images[currentImageIndex]})`,width:'100%',maxWidth:"100%"}}
              // style={{ backgroundImage: `url(${image})`, width: '100%', maxWidth: "100%" }}
            >
              <div className="container-envelope">
                <svg className="circle-a common-svg" height="160" width="160">
                  <circle cx="80" cy="80" r="80" />
                </svg>
                <svg className="circle-b common-svg" height="60" width="60">
                  <circle cx="30" cy="30" r="30" />
                </svg>
                <svg className="circle-c common-svg" height="600" width="600">
                  <circle cx="300" cy="300" r="300" />
                </svg>
                <svg className="circle-d common-svg" height="60" width="60">
                  <circle cx="30" cy="30" r="30" />
                </svg>
                <div className="col-xs-12">
                  <p className={`banners signika bnr-slide  ${animationClass} ${textPositionClass}`} style={{ color: `${textColour}` }}>
                    {bannerQuotes.quoteLine1}
                    <br />
                    {bannerQuotes.quoteLine2}
                    <br />
                    <span>{bannerQuotes.author}</span>
                    {(currentScope === 'PORTAL_SIG_ADMIN' || currentScope === 'PORTAL_CHAPTER_ADMIN' || currentScope === null || currentScope === undefined) && (
                      <div style={{ position: 'absolute', left: '45%', }}>
                        <a href='https://forms.gle/kthcZi1BQXjWtE5DA' target='blank'>
                        <button
                          
                          className="menus-colors btn"
                        >
                          Join
                        </button></a>
                      </div>
                    )}
                  </p>
                </div>
              </div>
            </aside>
            <img
              src={images[nextImageIndex]}
              alt=""
              style={{ display: 'none' }}
            />
          </section>
        </section>
      </Box>
    </>
  );
}

export default NavBar;




