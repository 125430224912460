import { api } from "./api";

const SIGServices = api.injectEndpoints({
  endpoints: (builder) => ({

    //getAllSIG
    getAllSIG: builder.query({
      query: () => "sigGroup/getAll",
      providesTags:['SIGGROUP']
    }),

    //getSIGById
    getSIGById: builder.query({
      query: (id) => "sigGroup/get/" + id,
      providesTags:['SIGGROUP']
    }),

    //CreateSig
    createSIG: builder.mutation({
      query: (newSIGData) => ({
        url: "sigGroup/saveSigGroup",
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: newSIGData,
      }),
      invalidatesTags:['SIGGROUP']
    }),

     //get All SIG with pagination
     getAllSIGPagination: builder.mutation({
      query: (SIGData) => ({
        url: "sigGroup/getAllSigGroupPagination",
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: SIGData,
      }),
      invalidatesTags: ['SIGGroup']
    }),

    //getpendingMember
    getPendingMemberList:builder.mutation({
      query:(data)=>({
         url:'sigGroup/getPendingMemberList',
         method: "POST",
         headers: { "Content-Type": "application/json" },
         body: data,
        }),
        invalidatesTags: ["SIGGroup"],
    }),

    //approvemember
    approveMember:builder.mutation({
      query:(data)=>({
        url:'sigGroup/approveMember',
        method:'put',
        header:{"Content-Type":"application/json"},
        body:data
      }),
      invalidatesTags:['SIGGROUP']
    }),

    //reject Member
    rejectMember:builder.mutation({
      query:(data)=>({
        url:'sigGroup/rejectMember',
        method:'put',
        header:{"Content-Type:":"application/json"},
        body:data
      }),
      invalidatesTag:["SIGGROUP"]
    }),

    //Make Admin SIG
    makeAdminSIG:builder.mutation({
      query:(data)=>({
        url:'sigGroup/makeSigAdmin',
        method:'put',
        header:{"Content-Type:":"application/json"},
        body:data
      }),
      invalidatesTag:["SIGGROUP"]
    }),

    //Remove AdminSIG
    removeAdminSIG:builder.mutation({
      query:(data)=>({
        url:'sigGroup/removeSigAdmin',
        method:'put',
        header:{"Content-Type:":"application/json"},
        body:data
      }),
      invalidatesTag:["SIGGROUP"]
    }),

    //switchjoinSIG
    switchJoinSIG:builder.mutation({
      query:(data)=>({
        url:'sigGroup/joinAnotherSig',
        method:'put',
        header:{"Content-Type:":"application/json"},
        body:data
      }),
      invalidatesTag:["SIGGROUP"]
    }),

    //ExistSIG
    exitSIG:builder.mutation({
      query:(data)=>({
        url:'sigGroup/leaveMemberFromSig',
        method:'put',
        header:{"Content-Type:":"application/json"},
        body:data
      }),
      invalidatesTag:["SIGGROUP"]
    }),

    //getSIGGroupById
    getSIGGroupRoleDetailsById: builder.query({
      query: (id) => "sigGroup/getRoleDetails/" + id,
      providesTags: ['SIGGroup']
    }),

  }),
  
  overrideExisting: false,
});

export const {
  useLazyGetAllSIGQuery,
  useGetAllSIGPaginationMutation,
  useLazyGetSIGByIdQuery,
  useCreateSIGMutation,
  useGetPendingMemberListMutation,
  useApproveMemberMutation,
  useRejectMemberMutation,
  useMakeAdminSIGMutation,
  useRemoveAdminSIGMutation,
  useSwitchJoinSIGMutation,
  useExitSIGMutation,
  useGetSIGGroupRoleDetailsByIdQuery
} = SIGServices;
