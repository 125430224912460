import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from "react-redux";
import { useGetAllJournalMutation } from "../../app/services/journalServices";
import { selectCurrentScope } from "../../app/auth/authSlice";
import ViewJournal from '../../Pages/JournalForms/ViewJournal';
import { selectCurrentMember } from '../../app/features/memberSlice';
import { Button, Card, CardContent, CardMedia, Grid, Typography } from '@mui/material';
import { selectCurrentSig } from '../../app/features/sigSlice';
import { selectCurrentChapterAdmin } from '../../app/features/chapterAdminSlice';
import { useLocation, useParams } from 'react-router-dom';
import Spinner from "../../Component/spinner/spinner";

const News = () => {
  const [getNewsJournal] = useGetAllJournalMutation();
  const [news, setNews] = useState([]);
  const [selectedNewsId, setSelectedNewsId] = useState(null)
  const memberDetails = useSelector(selectCurrentMember);
  const userSIG = memberDetails?.sigGroups;
  const userChapter = memberDetails?.chapter;
  const currentScope = useSelector(selectCurrentScope)
  const sigDetails = useSelector(selectCurrentSig)
  const chapterDetails = useSelector(selectCurrentChapterAdmin)
  const { sigGroupId } = useParams()
  const { chapterId } = useParams();
  const location = useLocation()
  const url = location.pathname
  const memberScope = useSelector(selectCurrentScope)
  const [loading, setLoading] = useState(true);
  const [chapterIdLocal, setChapterIdLocal] = useState();

  useEffect(() => {
    const storedChapterId = localStorage.getItem('chapterId');
    if (storedChapterId) {
      setChapterIdLocal(storedChapterId);
    }
  }, []);

  //Filter news based on role
  const filterNewsByRole = useCallback((news, memberScope) => {
    return news.filter(newss => {
      const createdByRole = newss?.createdBy?.role;
      if (memberScope === 'PORTAL_SIG_ADMIN') {
        if (chapterIdLocal) {
          return url?.includes('/view-Chapter') && (newss?.chaptersId?.includes(chapterId) && newss?.isApproved === 1)
        }
        if (sigGroupId) {
          return url?.includes('/viewSigDetails') && (newss?.SIGGroupsId?.includes(sigGroupId) && newss?.isApproved === 1)
        }
        return (createdByRole === 'PORTAL_SUPER_ADMIN' || newss?.SIGGroupsId?.includes(sigDetails?._id)) && newss?.isApproved === 1;
      }
      else if (memberScope === "PORTAL_CHAPTER_ADMIN") {
        if (chapterIdLocal) {
          return url?.includes('/view-Chapter') && (newss?.chaptersId?.includes(chapterId) && newss?.isApproved === 1)
        }
        if (sigGroupId) {
          return url?.includes('/viewSigDetails') && (newss?.SIGGroupsId?.includes(sigGroupId) && newss?.isApproved === 1)
        }
        return (createdByRole === 'PORTAL_SUPER_ADMIN' || newss?.chaptersId?.includes(chapterDetails?._id)) && newss.isApproved === 1;
      }
      else if (memberScope === "PORTAL_MEMBER") {
        if (chapterIdLocal) {
          return url?.includes('/view-Chapter') && (newss?.chaptersId?.includes(chapterId) && newss?.isApproved === 1)
        }
        if (sigGroupId) {
          return url?.includes('/viewSigDetails') && (newss?.SIGGroupsId?.includes(sigGroupId) && newss?.isApproved === 1)
        }
        return (createdByRole === 'PORTAL_SUPER_ADMIN' || (haveCommonElements(userSIG, newss?.SIGGroupsId) ||
          newss?.chaptersId?.includes(userChapter))) && newss?.isApproved === 1
      }
      else {
        if (chapterId) {
          return url?.includes('/view-Chapter') && (newss?.chaptersId?.includes(chapterId) && newss?.isApproved === 1)
        }
        if (sigGroupId) {
          return url?.includes('/viewSigDetails') && (newss?.SIGGroupsId?.includes(sigGroupId) && newss?.isApproved === 1)
        }
        return newss?.isApproved === 1
      }
    });
  }, [userChapter, chapterId, sigGroupId, url, userSIG, sigDetails?._id, chapterDetails?._id, chapterIdLocal])

  //haveCommon Element
  function haveCommonElements(arr1, arr2) {
    return arr1.some((item) => arr2?.includes(item));
  }

  // navigateTo journal
  const navigateToJournal = (journalId) => {
    setSelectedNewsId(journalId)
  }

  //getAllNewsJournal
  const getNews = useCallback(
    () => {
      getNewsJournal({ type: 0 }).then(res => {
        if (res.data?.status) {
          setLoading(false);
          const filteredNews = filterNewsByRole(res.data?.data, currentScope);
          setNews(filteredNews);
        } else {
          setLoading(false);
        }
      })
    }, [currentScope, filterNewsByRole, getNewsJournal]
  )

  //getAllNews
  useEffect(() => {
    getNews()
  }, [getNews])

  useEffect(() => {
  }, [news])

  //short Ten
  function shorten(text, maxLen) {
    return text && text.length > maxLen ? text.substring(0, maxLen) + '...' : text;
  }

  return (
    <>
      {selectedNewsId === null ? <div className="row mx-auto gap-2" style={{ maxWidth: '90%' }}>
        {loading ? (
          <Spinner />
        ) : (
          <div className="col-md-12" >
            {news.length > 0 ? (news.map((item) => (
              <>
                <Card
                  key={item?._id}
                  style={{
                    boxShadow: "none",
                    background: "transparent",
                  }}
                >
                  <CardContent>
                    <div
                      style={{
                        border: "1px solid #a52a2a ",
                        borderRadius: "15px",
                        padding: "10px",
                      }}
                    >
                      <CardMedia
                        component="img"
                        height="160"
                        image={item?.thumbnailImage}
                        alt="News thumbnail"
                        style={{
                          objectFit: "scale-down",
                          borderRadius: "15px",
                        }}
                      />
                      <Grid
                        container
                        spacing={2}
                        style={{
                          borderRadius: "15px",
                          padding: "10px",
                        }}
                      >
                        <Grid item xs={12}>
                          <div
                            className="d-flex justify-content-start"
                            style={{
                              color: "#a52a2a ",
                              fontWeight: "bold",
                            }}
                          >
                            <Typography variant="h5" color="#000000" fontFamily="poppins">
                              {item?.headline}
                            </Typography>
                          </div>
                          <div
                            style={{
                              marginTop: "auto",
                              color: "#000000",
                              display: 'flex',
                              justifyContent: 'space-between'
                            }}
                          >
                            By {item?.authorFirstName ? item?.authorFirstName + " " + item?.authorLastName : "Publisher's Release"} <span className='text-end ms-auto'>{item?.releaseDate}&nbsp;&nbsp;{item?.releaseTime}</span>
                          </div>
                          <Typography
                            variant="body1"
                            color="#000000"
                            fontFamily="roboto"
                            style={{
                              display: "-webkit-box",
                              WebkitBoxOrient: "vertical",
                              WebkitLineClamp: 4,
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              wordBreak: "break-word",
                            }}
                          >
                            {shorten(item?.summary, 100)}
                            {!(memberScope === null || memberScope === undefined) && (
                              <Button
                                variant="text"
                                style={{
                                  color: "#a52a2a ",
                                  textDecoration: "underline",
                                }}
                                onClick={() => navigateToJournal(item?._id)}>Read More</Button>
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                    </div>
                  </CardContent>
                </Card>
              </>
            ))) : (
              <div className='text-center w-100'>
                <p className="text-black fs-2 text-capitalize lead">No Data Available</p>
              </div>
            )}
          </div>
        )}
      </div> : <div className='row mx-auto'>
        <div className='text-end'><Button style={{ margin: "20px" }} variant='contained' onClick={() => setSelectedNewsId(null)} className='menus-color'>Back</Button></div>
        <ViewJournal journalId={selectedNewsId} />
      </div>}
    </>
  )
}

export default News