import { api } from "./api";

const importantAnnouncementServices = api.injectEndpoints({
    endpoints: (builder) => ({
        //createAnnouncement
        CreateImportantAnnouncement: builder.mutation({
            query: (data) => ({
                url: "newAnnouncement/saveNewAnnouncements",
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: data,
            }),
            invalidatesTags: ['Announcement']
        }),

        //updateAnnouncement
        // UpdateImportantAnnouncement: builder.mutation({
        //     query: (data) => (
        //         {
        //             url: "newAnnouncement/update/" + data.id,
        //             method: "PUT",
        //             headers: { "Content-Type": "application/json" },
        //             body: data
        //         })
        // }),
        //get All Announcement
        getAllImportantAnnouncement: builder.query({
            query: () => ({
                url: "newAnnouncement/getAll",
            }),
            providesTags: ['Announcement']
        }),
        UpdateImportantAnnouncement: builder.mutation({
            query: ({ id, data }) => ({
                url: `newAnnouncement/update/${id}`,
                method: "PUT",
                headers: { "Content-Type": "application/json" },
                body: data,
            }),
        }),



        //getApproveRejectAnnouncement
        getApproveRejectImportantAnnouncement: builder.mutation({
            query: (data) => ({
                url: "newAnnouncement/approveRejectNewAnnouncement",
                method: "PUT",
                headers: { "Content-Type": "application/json" },
                body: data,
            })
        }),
        //Get Announcement By Id 
        getImportantAnnouncementDetailsById: builder.query({
            query: (id) => ({ url: "newAnnouncement/get/" + id })
        }),

        //Get Announcement By EnableDisabl 
        getImportantAnnouncementEnableDisable: builder.query({
            query: (id) => ({
                url: "newAnnouncement/enableDisable/" + id,
                method: "put"
            })
        }),


        //deleteAnnouncementById
        deleteImportantAnnouncement: builder.mutation({
            query: (id) => ({
                url: "newAnnouncement/delete/" + id,
                method: "PUT",
                headers: { "Content-Type": "application/json" },
            }),
            invalidatesTags: ['Announcement']
        }),

        //pagination Announcement
        paginationImportantAnnouncement: builder.mutation({
            query: (data) => ({
                url: "newAnnouncement/getAllNewAnnouncementPagination",
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: data,
            }),
            invalidatesTags: ['Announcement']
        }),
    }),
    overrideExisting: false,
});

export const {
    useCreateImportantAnnouncementMutation,
    usePaginationImportantAnnouncementMutation,
    useLazyGetImportantAnnouncementDetailsByIdQuery,
    useUpdateImportantAnnouncementMutation,
    useDeleteImportantAnnouncementMutation,
    useLazyGetImportantAnnouncementEnableDisableQuery,
    useGetApproveRejectImportantAnnouncementMutation,
    useLazyGetAllImportantAnnouncementQuery,
    
} = importantAnnouncementServices;