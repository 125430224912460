import React, { useCallback, useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { FormHelperText, TextField } from "@mui/material";
import { Container } from "react-bootstrap";
import Button from "@mui/material/Button";
import "../../style/css/journalForm.css";
import TopHeader from "../../Component/Header/TopHeader";
import { useForm, Controller } from "react-hook-form";
import {
  useSaveForumMutation,
  useUpdateForumMutation,
  useLazyGetForumByIdQuery,
} from "../../app/services/forumServices";
import { useSavePdfForumMutation } from "../../app/services/forumPdfServices";
import {
  selectCurrentScope,
  selectUserId,
  selectCurrentRoleId,
} from "../../app/auth/authSlice";
import { selectCurrentSig } from "../../app/features/sigSlice";
import { selectCurrentChapterAdmin } from "../../app/features/chapterAdminSlice";
import { useSelector } from "react-redux";
import { useGetAllCategoryMutation } from "../../app/services/categoryService";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { toast } from "react-toastify";
import Spinner from "../../Component/spinner/spinner";
dayjs.extend(customParseFormat);

function ForumForm() {
  const [loading, setLoading] = useState(false);
  const memberScope = useSelector(selectCurrentScope);
  const userId = useSelector(selectUserId);
  const roleId = useSelector(selectCurrentRoleId);
  const sigDetails = useSelector(selectCurrentSig);
  const sigId = sigDetails._id;
  const chapterDetails = useSelector(selectCurrentChapterAdmin);
  const chapterId = chapterDetails._id;
  const { forumId } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [allCategory, setAllCategory] = useState([]);
  const [saveForum, { isLoading }] = useSaveForumMutation();
  const [savePdfForum] = useSavePdfForumMutation();
  const [getForumById] = useLazyGetForumByIdQuery(forumId);
  const [updateForum] = useUpdateForumMutation();
  const [getAllCategory] = useGetAllCategoryMutation();
  const [imageErrorMessage, setImageErrorMessage] = useState("");
  const [pdfErrorMessage, setPdfErrorMessage] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const fileInputRefImage = useRef(null);
  const fileInputRefPdf = useRef(null);
  const [pdfFile, setPdfFile] = useState(null);
  const [isImageError, setIsImageError] = useState(false);
  const [isPdfFileError, setIsPdfFileError] = useState(false);

  // useForm hook for form data handling
  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    reset,
    trigger,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      category: "",
      name: "",
      description: "",
      headline: "",
      uploadImage: "",
      pdfDocumentID: "",
      createdBy: {
        id: "",
        role: "",
      },
      SIGGroupsId: [],
      chaptersId: [],
      uploadPdf: "",
      pdflink: "",
    },
  });

  //Get all category
  const fetchCategory = useCallback(async () => {
    try {
      const response = await getAllCategory({ type: 1 });
      setAllCategory(response.data.data);
    } catch (error) { }
  }, [getAllCategory]);

  useEffect(() => {
    fetchCategory();
  }, [fetchCategory]);

  //Get forum by id
  useEffect(() => {
    if (forumId) {
      setLoading(true);
      getForumById(forumId).then((res) => {
        if (res.data.status) {
          setLoading(false);
          const data = res.data?.data;
          Object.entries(data).forEach(([key, value]) => {
            setValue(key, value);
          });
        }
      });
    }
  }, [forumId, getForumById, setValue]);

  const pdflink = watch("pdflink");

  //On submit for save and update
  const onSaveForum = (formData) => {
    if (isImageError || isPdfFileError) {
      return;
    }
    if (pdfFile) {
      const pdfFormData = new FormData();
      pdfFormData.append("uploadPdf", pdfFile);
      savePdfForum(pdfFormData).then((res) => {
        if (res.data.status) {
          if (res.data?.data?.documentID) {
            toast.success(res.data?.message);
            formData.pdfDocumentID = res.data?.data?.documentID;
          }
          setLoading(true);
          trigger().then((isValid) => {
            formData.createdBy = {
              id: roleId ? roleId : userId,
              role: memberScope,
            };
            if (isValid) {
              if (!formData.pdfDocumentID) {
                return;
              }
              if (userId && memberScope === "PORTAL_SIG_ADMIN") {
                formData.SIGGroupsId = [sigId];
              } else if (userId && memberScope === "PORTAL_CHAPTER_ADMIN") {
                formData.chaptersId = [chapterId];
              }
              setData([...data, formData]);
              if (forumId) {
                let requestIdAndData;
                requestIdAndData = {
                  id: forumId,
                  data: formData,
                };
                updateForum(requestIdAndData)
                  .then((res) => {
                    if (res.data.status) {
                      setLoading(false);
                      toast.success(res.data?.message);
                      reset();
                      if (memberScope === "PORTAL_SUPER_ADMIN") {
                        navigate("/admin/forumTable");
                      }
                      if (memberScope === "PORTAL_CHAPTER_ADMIN") {
                        navigate("/chapterAdmin/forumTable");
                      }
                      if (memberScope === "PORTAL_SIG_ADMIN") {
                        navigate("/sigAdmin/forumTable");
                      }
                    } else {
                      toast.error(res.data?.err?.message);
                    }
                  })
                  .catch((error) => {
                    toast.error(error);
                  });
              } else {
                saveForum(formData)
                  .then((res) => {
                    if (res.data?.status) {
                      setLoading(false);
                      toast.success(res.data?.message);
                      reset();
                      if (memberScope === "PORTAL_SUPER_ADMIN") {
                        navigate("/admin/forumTable");
                      }
                      if (memberScope === "PORTAL_CHAPTER_ADMIN") {
                        navigate("/chapterAdmin/forumTable");
                      }
                      if (memberScope === "PORTAL_SIG_ADMIN") {
                        navigate("/sigAdmin/forumTable");
                      }
                    }
                  })
                  .catch((error) => {
                    toast.error(error);
                  });
              }
            }
          });
        }
      });
    } else {
      trigger().then((isValid) => {
        if (isValid) {
          setLoading(true);
          formData.createdBy = {
            id: roleId ? roleId : userId,
            role: memberScope,
          };
          if (userId && memberScope === "PORTAL_SIG_ADMIN") {
            formData.SIGGroupsId = [sigId];
          } else if (userId && memberScope === "PORTAL_CHAPTER_ADMIN") {
            formData.chaptersId = [chapterId];
          }
          setData([...data, formData]);
          if (forumId) {
            let requestIdAndData;
            requestIdAndData = {
              id: forumId,
              data: formData,
            };
            const base64Regex =
              /^data:image\/(?:png|jpeg|jpg|gif);base64,[\w+/=]+$/;
            const uploadImg = formData?.uploadImage;
            if (base64Regex.test(formData?.uploadImage)) {
              formData.uploadImage = uploadImg; // No change needed if it's base64
            } else {
              formData.uploadImage = formData.uploadImage
                .split("/")
                .slice(-2)
                .join("/");
            }
            updateForum(requestIdAndData)
              .then((res) => {
                if (res.data.status) {
                  setLoading(false);
                  toast.success(res.data?.message);
                  reset();
                  if (memberScope === "PORTAL_SUPER_ADMIN") {
                    navigate("/admin/forumTable");
                  }
                  if (memberScope === "PORTAL_CHAPTER_ADMIN") {
                    navigate("/chapterAdmin/forumTable");
                  }
                  if (memberScope === "PORTAL_SIG_ADMIN") {
                    navigate("/sigAdmin/forumTable");
                  }
                } else {
                  toast.error(res.data?.err?.message);
                }
              })
              .catch((error) => {
                toast.error(error);
              });
          } else {
            saveForum(formData)
              .then((res) => {
                if (res.data?.status) {
                  setLoading(false);
                  toast.success(res.data?.message);
                  reset();
                  if (memberScope === "PORTAL_SUPER_ADMIN") {
                    navigate("/admin/forumTable");
                  }
                  if (memberScope === "PORTAL_CHAPTER_ADMIN") {
                    navigate("/chapterAdmin/forumTable");
                  }
                  if (memberScope === "PORTAL_SIG_ADMIN") {
                    navigate("/sigAdmin/forumTable");
                  }
                }
              })
              .catch((error) => {
                toast.error(error);
              });
          }
        }
      });
    }
  };

  // Image handling
  const handleImageFileChange = (event) => {
    const file = event.target.files[0];
    if (!file) {
      clearImage();
      return;
    }

    if (!file.type.startsWith("image/")) {
      clearImage("Please select a valid image file.");
      return;
    }

    if (file.size > 2 * 1024 * 1024) {
      clearImage("File size exceeds 2 MB.");
      return;
    }

    // File is valid, proceed with image loading
    const reader = new FileReader();
    reader.onload = (e) => {
      const base64String = e.target.result;
      setSelectedImage(base64String);
      setValue("uploadImage", base64String);
      setImageErrorMessage("");
      setIsImageError(false);
    };
    reader.readAsDataURL(file);
  };

  const handleInputImageClick = () => {
    // Clear the image state if no file is selected
    if (!fileInputRefImage.current || fileInputRefImage.current.files.length === 0) {
      clearImage();
    }
  };

  const clearImage = (errorMessage = "") => {
    setSelectedImage(null);
    setValue("uploadImage", "");
    setImageErrorMessage(errorMessage);
    setIsImageError(!!errorMessage);
  };

  //Pdf handling
  const handlePdfFileChange = async (event) => {
    const pdfFile = event.target.files[0];
    if (!pdfFile) {
      setPdfFile(null);
      setPdfErrorMessage("");
      setIsPdfFileError(false);
      setValue("pdfDocumentID", "");
      return;
    }
    if (pdfFile.type !== "application/pdf") {
      setPdfErrorMessage("Please upload a PDF file.");
      setPdfFile(null);
      setIsPdfFileError(true);
      setValue("pdfDocumentID", "");
      return;
    } else {
      setIsPdfFileError(false);
      setPdfErrorMessage("");
      setPdfFile(pdfFile);
    }
  };

  const handleInputPdfClick = () => {
    if (fileInputRefPdf.current.files.length === 0) {
      setPdfErrorMessage("");
      setIsPdfFileError(false);
      setValue("pdfDocumentID", "");
    }
  };

  return (
    <>
      <TopHeader />
      <div className='pt-1 '>
        <div className='p-0'>
          <div className=' Architects-header d-flex align-items-center justify-content-center '>
            <h2 className='signika-fontOEC text-center '>{forumId ? "Edit Forum" : "Add Forum"}</h2>
          </div>
        </div>
      </div>
      <div className="container-fluid px-5 mt-4">
        <div className="row align-items-center">
          <div className="col-md-7">
            {!forumId ? (
              <>
                <span
                  style={{ width: "140px" }}
                ></span>
              </>
            ) : (
              <>
                <span
                  style={{ width: "220px" }}
                ></span>
              </>
            )}
          </div>
          <div className="col-md-5">
            <Button
              onClick={() => window.history.back()}
              variant="contained"
              className="menus-color"
              style={{ float: "right" }}
            >
              Back
            </Button>
          </div>
        </div>
      </div>
      {loading ? (
        <Spinner />
      ) : (
        <Container
          maxWidth="md"
          style={{ marginTop: "25px", marginBottom: "50px" }}
        >
          <form onSubmit={handleSubmit(onSaveForum)}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "20px",
              }}
            >
              <Controller
                control={control}
                name="category"
                render={({ field, fieldState }) => (
                  <FormControl style={{ width: "30%" }}>
                    <InputLabel id="category-label">
                      Category <span style={{ color: "red" }}>*</span>
                    </InputLabel>
                    <Select
                      style={{ backgroundColor: "#f0f0f0" }}
                      labelId="category-label"
                      id="category"
                      label={
                        <Typography className="text-dark">
                          Category <span style={{ color: "red" }}>*</span>
                        </Typography>
                      }
                      value={field.value ? field.value : ""}
                      name={field.name}
                      onChange={field.onChange}
                      error={!!fieldState?.error}
                      variant="outlined"
                    >
                      {!isLoading && allCategory?.length > 0 ? (
                        allCategory?.filter(item => item.isDeleted === 0)
                          .map((item, index) => (
                            <MenuItem key={index} value={item._id}>
                              {item.categoryName}
                            </MenuItem>
                          ))
                      ) : (
                        <MenuItem>Loading....</MenuItem>
                      )}
                    </Select>
                    <FormHelperText className="text-danger">
                      {fieldState?.error?.message}
                    </FormHelperText>
                  </FormControl>
                )}
                rules={{ required: "Please Select a Category" }}
              />
              <TextField
                InputLabelProps={{ shrink: true }}
                style={{ width: "30%" }}
                id="name"
                label={
                  <Typography className="text-dark">
                    Name<span style={{ color: "red" }}>*</span>
                  </Typography>
                }
                variant="outlined"
                {...register("name", { required: "Please Enter a Name" })}
                error={!!errors?.name}
                helperText={errors?.name?.message}
              />
              <TextField
                InputLabelProps={{ shrink: true, style: { lineHeight: "1.5", paddingTop: "0" }}}
                style={{ width: "30%", overflowY: "hidden" }}
                id="description"
                label={
                  <Typography className="text-dark" style={{ lineHeight: "2.5" }}>
                    Description<span style={{ color: "red" }}>*</span>
                  </Typography>
                }
                variant="outlined"
                {...register("description", {
                  required: "Please Enter a Description",
                })}
                error={!!errors?.description}
                helperText={errors?.description?.message}
                multiline
                rows={10}
                inputProps={{
                  style: {
                    whiteSpace: "pre-line",
                    display: "block",
                    overflowY: "hidden",
                    textOverflow: "ellipsis",
                  },
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "20px",
              }}
            >
              <TextField
                InputLabelProps={{ shrink: true }}
                style={{ width: "30%" }}
                id="headline"
                label={
                  <Typography className="text-dark">
                    HeadLine <span style={{ color: "red" }}>*</span>
                  </Typography>
                }
                variant="outlined"
                {...register("headline", {
                  required: "Please Enter a Headline",
                })}
                error={!!errors?.headline}
                helperText={errors?.headline?.message}
              />
              <div
                style={{ width: "30%", display: "flex", alignItems: "center" }}
              >
                {forumId ? (
                  selectedImage || getValues("uploadImage") ? (
                    <>
                      <img
                        src={selectedImage || `${getValues("uploadImage")}?${new Date().getTime()}`}
                        height="100"
                        alt="forumform"
                      />
                      <input
                        ref={fileInputRefImage}
                        accept="image/jpeg, image/png" 
                        id="uploadImage"
                        type="file"
                        style={{ display: "none" }}
                        onChange={handleImageFileChange}
                        onClick={handleInputImageClick}
                      />
                      <label
                        htmlFor="uploadImage"
                        style={{ marginLeft: "10px" }}
                      >
                        <Button
                          variant="contained"
                          className="menus-color"
                          component="span"
                          style={{ whiteSpace: "nowrap" }}
                        >
                          Choose Image File
                        </Button>
                      </label>
                    </>
                  ) : (
                    <>
                      <div style={{ width: "100%", display: "flex" }}>
                        <div
                          style={{ marginRight: "10px", fontWeight: "bold" }}
                        >
                          <label htmlFor="uploadImage">Image</label>
                        </div>
                        <input
                          ref={fileInputRefImage}
                          accept="image/jpeg, image/png"
                          style={{ width: "100%", border: "2px solid #adb5bd" }}
                          id="uploadImage"
                          type="file"
                          onChange={handleImageFileChange}
                          onClick={handleInputImageClick}
                        />
                      </div>
                    </>
                  )
                ) : (
                  <div style={{ width: "100%", display: "flex" }}>
                    <div style={{ marginRight: "10px", fontWeight: "bold" }}>
                      <label htmlFor="uploadImage">Image</label>
                    </div>
                    <input
                      ref={fileInputRefImage}
                      accept="image/jpeg, image/png"
                      style={{ width: "100%", border: "2px solid #adb5bd" }}
                      id="uploadImage"
                      type="file"
                      onChange={handleImageFileChange}
                      onClick={handleInputImageClick}
                    />
                  </div>
                )}
                {imageErrorMessage && (
                  <div style={{ color: "red", marginTop: "10px" }}>
                    {imageErrorMessage}
                  </div>
                )}
              </div>
              {forumId && pdflink ? (
                <div
                  style={{
                    width: "30%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <a
                      href={pdflink}
                      cursor="pointer"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ display: "block", marginTop: "10px" }}
                    >
                      View Uploaded PDF
                    </a>
                  </div>
                  <div>
                    <input
                      ref={fileInputRefPdf}
                      accept="application/pdf"
                      style={{ display: "none" }}
                      id="pdfFile"
                      type="file"
                      onChange={(event) => {
                        handlePdfFileChange(event);
                      }}
                      onClick={handleInputPdfClick}
                    />
                    <label htmlFor="pdfFile" style={{ marginLeft: "10px" }}>
                      <Button
                        variant="contained"
                        className="menus-color"
                        component="span"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        Choose PDF File
                      </Button>
                    </label>
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    width: "30%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div style={{ marginRight: "10px", fontWeight: "bold" }}>
                    <label htmlFor="pdfFile">PDF</label>
                  </div>
                  <div style={{ width: "100%" }}>
                    <input
                      ref={fileInputRefPdf}
                      accept="application/pdf"
                      style={{ width: "100%", border: "2px solid #adb5bd" }}
                      id="pdfFile"
                      type="file"
                      onChange={(event) => {
                        handlePdfFileChange(event);
                      }}
                      onClick={handleInputPdfClick}
                    />
                  </div>
                </div>
              )}
            </div>
            {pdfErrorMessage && (
              <div style={{ color: "red", marginTop: "10px" }}>
                {pdfErrorMessage}
              </div>
            )}
            <div
              style={{ display: "flex", justifyContent: "center", gap: "10px" }}
            >
              <>
                <Button variant="contained" className="menus-color" type="submit">
                  {forumId ? "Update" : "Submit"}
                </Button>
              </>
            </div>
          </form>
        </Container>
      )}
    </>
  );
}

export default ForumForm;
