import { api } from "./api";

const chapterServices = api.injectEndpoints({
  endpoints: (builder) => ({
    //save chapter
    saveChapter: builder.mutation({
      query: (formData) => ({
        url: "chapter/saveChapter",
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: formData,
      }),
      invalidatesTags: ['Chapter']
    }),

    //get All Chapter with pagination
    getAllChaptersPagination: builder.mutation({
      query: (chapterData) => ({
        url: "chapter/getAllChapterPagination",
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: chapterData,
      }),
      invalidatesTags: ['Chapter']
    }),

    //get All Chapter
    getAllChapters: builder.query({
      query: () => ({
        url: "chapter/getAllChapter",
      }),
      providesTags: ['Chapter']
    }),


    //get all pending member
    getAllPendingMemberList: builder.mutation({
      query: (pendingMemberData) => ({
        url: "chapter/getPendingMemberList",
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: pendingMemberData,
      }),
      providesTags: ['Chapter']
    }),

    //get Chapter based on state
    getChapterBasedOnState: builder.mutation({
      query: (stateData) => ({
        url: "chapter/getChapterBasedOnState",
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: stateData,
      }),
    }),

    //get Chapter Details by Id
    getChapterDetailsById: builder.query({
      query: (id) => "chapter/get/" + id,
      providesTags: ['Chapter']
    }),
      //get Chapter Role Details by Id
      getChapterRoleDetailsById: builder.query({
        query: (id) => "chapter/getRoleDetails/" + id,
        providesTags: ['Chapter']
      }),
      getSIGGroupRoleDetailsById: builder.query({
        query: (id) => "sigGroup/getRoleDetails/" + id,
        providesTags: ['SIGGroup']
      }),

    //Update Chapter
    updateChapter: builder.mutation({
      query: (data) => ({
        url: "chapter/update/" + data.id,
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: data.formData,
      }),
      invalidatesTags: ['Chapter']
    }),

    //approve member
    aproveMember: builder.mutation({
      query: (formData) => ({
        url: "chapter/approveMember",
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: formData,
      }),
      invalidatesTags: ['Chapter']
    }),

    //delete Member
    deleteMember: builder.mutation({
      query: (formData) => ({
        url: "chapter/rejectMember",
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: formData,
      }),
      invalidatesTags: ['Chapter']
    }),

    //switch chapter
    switchChapter: builder.mutation({
      query: (formData) => ({
        url: "chapter/switchMember",
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: formData,
      }),
      invalidatesTags: ['Chapter']
    }),
    
    //make admin
    makeAdmin: builder.mutation({
      query: (formData) => ({
        url: "chapter/makeChapterAdmin",
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: formData,
      }),
      invalidatesTags: ['Chapter']
    }),

    //remove admin
    removeAdmin: builder.mutation({
      query: (formData) => ({
        url: "chapter/removeChapterAdmin",
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: formData,
      }),
      invalidatesTags: ['Chapter']
    }),

    //getjoinmemberList
    getJoinMemberList: builder.mutation({
      query: (formData) => ({
        url: "chapter/getJoinMemberList ",
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: formData,
      }),
      invalidatesTags: ['Chapter']
    }),


    getAllChapterFeed: builder.mutation({
      query: (data) => ({
        url: "chapterAllModules/getChapterAllModules",
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: data,
      }),
      providesTags: ['Chapter']
    }),

    //update chapter
    updateChapterProfile: builder.mutation({
      query: (data) => ({
        url: "chapter/updateChapterAdmin/" + data.id,
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: data.formData,
      }),
      invalidatesTags: ['Chapter']
    }),
  }),
  
  overrideExisting: false,
});


export const {
  useGetChapterBasedOnStateMutation,
  useGetAllChaptersPaginationMutation,
  useLazyGetAllChaptersQuery,
  useGetAllPendingMemberListMutation,
  useSaveChapterMutation,
  useDeleteMemberMutation,
  useAproveMemberMutation,
  useLazyGetChapterDetailsByIdQuery,
  useUpdateChapterMutation,
  useSwitchChapterMutation,
  useMakeAdminMutation,
  useRemoveAdminMutation,
  useGetJoinMemberListMutation,
  useUpdateChapterProfileMutation,
  useLazyGetChapterRoleDetailsByIdQuery,
  useLazyGetSIGGroupRoleDetailsByIdQuery,
  useGetAllChapterFeedMutation
} = chapterServices;
