import {api} from './api';
const GalleryService=api.injectEndpoints({
    endpoints:(builder)=>({

      //saveGallery
        saveGallery:builder.mutation({
            query:(data)=>({
                url:"gallery/saveGallery",
                method: "POST",
              headers: { "Content-Type": "application/json" },
             body: data,
            }),
            invalidatesTags:['Gallery']
        }),

        //getAllGallery
        getAllGallery: builder.query({
            query: () => "gallery/getAllGallery",
            providesTags:['Gallery']
          }),

          // //GelleryById
          // getGalleryById: builder.query({
          //   query: (id) => "gallery/get/" + id,
          //   providesTags:['Gallery']
          // }),
 

          GalleryById:builder.mutation({
              query:({id,data})=>({
                url:"gallery/get/" + id,
                method:"POST",
                headers: { "Content-Type": "application/json" },
                body: data,
              })
          }),
          //update Gallery
          updateGallery:builder.mutation({
            query: ({ id, data }) => ({
                url: `gallery/update/${id}`,
                method: "PUT",
                headers: { "Content-Type": "application/json" },
                body: data,
              }),
              invalidatesTags:['Gallery']
          }),
          
          //deleteGallery
          deleteGallery:builder.mutation({
                query:(data)=>({
                    url:`gallery/delete/galleryList`,
                    method: "PUT",
                    body: data
                }),
                invalidatesTags:['Gallery']
          }),

           //GetapproveRejectGallery
          getApproveRejectGallery: builder.mutation({
            query: (data) => ({
              url: "gallery/approveRejectGalleryList",
              method: "PUT",
              headers: { "Content-Type": "application/json" },
              body: data,
            }),
            invalidatesTags:['Gallery']
          }),
           
          //getPendingGalleryList
          getPendingGalleryList: builder.mutation({
            query: (data) => ({
              url: "gallery/getGalleryPendingList",
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: data,
            }),
            invalidatesTags:['Gallery']
          }),

          //pagninationGallery
          paginationGallery: builder.mutation({
            query: (newMemberListData) => ({
              url: "gallery/getAllGalleryPagination",
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: newMemberListData,
            }),
            invalidatesTags:['Gallery']
          }),

          //paginationAllGallery
          paginationAllGalleryList: builder.mutation({
            query: (newMemberListData) => ({
              url: "gallery/getAllGalleryListPagination",
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: newMemberListData,
            }),
            invalidatesTags:['Gallery']
          }),
    }),
    

})
export const{
    useSaveGalleryMutation,
    useLazyGetAllGalleryQuery,
    // useLazyGetGalleryByIdQuery,
    useGalleryByIdMutation,
    useUpdateGalleryMutation,
    useDeleteGalleryMutation,
    usePaginationGalleryMutation,
    useGetApproveRejectGalleryMutation,
    useGetPendingGalleryListMutation,
    usePaginationAllGalleryListMutation
}=GalleryService