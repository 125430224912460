import {api} from './api';
const GetAllModuleService=api.injectEndpoints({
    endpoints:(builder)=>({
      //AllModules
        getAllModules: builder.mutation({
            query: (data) => ({
              url: "allModules/getAllModules",
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: data,
            }),
            invalidatesTags:['ALLModule']
          }),
    })
})
export const {useGetAllModulesMutation}=GetAllModuleService