import React, { useState, useEffect } from 'react'
import TopHeader from "../../Component/Header/TopHeader";
import {
  Container, Button, TextField, Typography,
} from '@mui/material';
import { setRoleDetails, selectCurrenChapterRole } from "../../app/features/chapterAdminSlice";
import { useSelector } from 'react-redux';
import { useForm } from "react-hook-form";
import placeholder from '../../../src/assets/images/Profile/profile.jpg'
import { useParams } from 'react-router-dom';
import { useUpdateChapterProfileMutation, useLazyGetChapterDetailsByIdQuery } from '../../app/services/chapterServices'
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setChapterDetails } from '../../app/features/chapterAdminSlice'
import {useLazyGetChapterRoleDetailsByIdQuery} from './../../app/services/chapterServices'

const ChapterProfile = () => {

  const imageurl = useSelector(setRoleDetails);
  const chapterDetails = useSelector(selectCurrenChapterRole)
  const { id } = useParams()
  const [updateChapterProfile] = useUpdateChapterProfileMutation()
  const [getChapterById] = useLazyGetChapterDetailsByIdQuery()
  const dispatch = useDispatch()
  const [chapterRoleDetails]=useLazyGetChapterRoleDetailsByIdQuery()
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch
  } = useForm()
  const [selectedImage, setSelectedImage] = useState(null);

  //getChapterById
  useEffect(() => {
    getChapterById(id).then((res) => {
      if (res?.data?.status) {
        var roleDetails = res?.data?.data?.roleDetails
        roleDetails.forEach((role) => {
          chapterRoleDetails(role?._id).then((res)=>{
            if(res?.data?.status){
              setValue('imageUrl', res?.data?.data?.imageUrl)
        Object.entries(res?.data?.data?.roleDetails).forEach(([key, value]) => {
          setValue(key, value)
        })
            }
          })
        });
        dispatch(setChapterDetails(res.data.data));
      }
    })
  }, [setValue, imageurl?.payload?.auth?.imageUrl, chapterDetails, id, dispatch, getChapterById,chapterRoleDetails])

  //image upload
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (!file || !file.type.startsWith("image/")) {
      setSelectedImage(null);
      setValue(`imageUrl`, "")
      return;
    }
    if (file && file.size > 2 * 1024 * 1024) {
      setSelectedImage(null);
      setValue(`imageUrl`, "")
      return;
    }
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        let base64String = e.target.result;
        setSelectedImage(reader.result);
        setValue(`imageUrl`, base64String);
      };
      reader.readAsDataURL(file);
    }
  };

  //update ChapterProfile
  const chapterUpdateProfile = (data) => {
    const base64Regex = /^data:image\/(?:png|jpeg|jpg|gif);base64,[\w+/=]+$/;
    const updateData = {
      id: imageurl?.payload?.chapter?.chapterDetails._id,
      formData: {
        name: data.name,
        email: data.email,
        address: data.address,
        mobile: data.mobile,
        imageUrl: base64Regex.test(data?.imageUrl)
          ? data?.imageUrl
          : data?.imageUrl.split("/").slice(-2).join("/"),
        role: "PORTAL_CHAPTER_ADMIN",
        roleId: imageurl?.payload?.auth?.roleId,
        adminId: imageurl?.payload?.auth?.memberId,
      },

    };

    //update Chapter Profile
    updateChapterProfile(updateData).then((res) => {
      if (res?.data?.status) {
        toast.success(res?.data?.message);
        getChapterById(id).then((res) => {
          if (res?.data?.status) {
            dispatch(setChapterDetails(res?.data?.data))
            dispatch(setRoleDetails(res?.data?.data?.roleDetails[0]));
          }
        }
        )
      }
      else {
        toast.error(res?.data?.err?.message);
      }
    }).catch((err) => {
      console.error(err)
    })
  }

  return (
    <div>
      <TopHeader />
      <div className='pt-1 '>
                    <div className='p-0'>
                        <div className=' Architects-header d-flex align-items-center justify-content-center '>
                            <h2 className='signika-fontOEC text-center '>Chapter Profile</h2>
                        </div>
                    </div>
                </div>
      <Container maxWidth="xl">
        <div className='d-flex justify-content-between'>
          <div>
            <h3 className="heading_h3_bearers mt-4 ">Chapter Profile</h3>
            <span
              className="animate-border-bearers border-black mb-5"
              style={{ width: '180px' }}
            ></span>
          </div>
          <div>
            <Button
              onClick={() => window.history.back()}
              variant="contained"
              className="menus-color mt-4"
              style={{ float: "right" }}
            >
              Back
            </Button>
          </div>
        </div>
      </Container>
      <Container maxWidth="xl" className='mb-3'>
        <form onSubmit={handleSubmit(chapterUpdateProfile)}>
          <div className='row mb-3'>
            <div class="d-flex justify-content-center">
              <div>
                <img
                  src={selectedImage || watch("imageUrl") || placeholder}
                  height="100"
                  alt="eventform"
                  style={{
                    objectFit: "scale-down",
                    width: "200px",
                  }}
                />
                <TextField
                  sx={{ mt: 2 }}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ accept: "image/jpeg, image/png" }}
                  id="imageUrl"
                  label={
                    <Typography className="text-dark">
                      Image <span style={{ color: "red" }}>*</span>
                    </Typography>
                  }
                  style={{ display: 'none' }}
                  type="file"
                  {...register(`image`, {
                    required: { value: !watch(`imageUrl`), message: 'Please Select a image' }, validate: (value) => {
                      const file = value[0];
                      if (!watch(`imageUrl`)) {
                        if (!file || !file.type.startsWith("image/")) {
                          setValue(`imageUrl`, "")
                          return "Please Select a image file"
                        }
                        if (file && file.size > 2 * 1024 * 1024) {
                          setValue(`imageUrl`, "")
                          return "File size exceeds 2mb"
                        }
                      }
                      return true;
                    }, onChange: (e) => handleImageChange(e),
                  })}
                />
                <label
                  htmlFor="imageUrl"
                  style={{ marginLeft: "10px" }}
                >
                  <Button
                    variant="contained"
                    className="menus-color"
                    component="span"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    Choose File <span style={{ color: "white", paddingLeft: "5px" }}>*</span>
                  </Button>
                </label>
                {!!errors?.image && (
                  <div style={{ color: "red", marginTop: "10px" }}>
                    {errors?.image?.message}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-4'>
              <TextField
                InputLabelProps={{ shrink: true }}
                style={{ width: "100%" }}
                id="headline"
                label={
                  <Typography className="text-dark">
                    Name <span style={{ color: "red" }}>*</span>
                  </Typography>
                }
                variant="outlined"
                {...register("name", {
                  required: "Please Enter  Name",
                })}
                error={!!errors?.name}
                helperText={errors?.name?.message}
              />
              <TextField className='mt-3'
                InputLabelProps={{ shrink: true }}
                style={{ width: "100%" }}
                id="headline"
                label={
                  <Typography className="text-dark">
                    Address <span style={{ color: "red" }}>*</span>
                  </Typography>
                }
                variant="outlined"
                {...register("address", {
                  required: "Please Enter Address",
                })}
                error={!!errors?.address}
                helperText={errors?.address?.message}
              />
            </div>
            <div className='col-md-4'>
              <TextField
                style={{ width: "100%", }}
                InputLabelProps={{ shrink: true }}
                id="title"
                label={
                  <Typography className="text-dark">
                    Email ID <span style={{ color: "red" }}>*</span>
                  </Typography>
                }
                variant="outlined"
                disabled
                {...register("email", {
                  required: "Please Enter a email",
                  pattern: {
                    value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                    message: "Please Enter a valid Email",
                  },
                })}
                error={!!errors?.email}
                helperText={errors?.email?.message}
              />
            </div>
            <div className='col-md-4'>
              <TextField
                InputLabelProps={{ shrink: true }}
                style={{ width: "100%" }}
                id="headline"
                label={
                  <Typography className="text-dark">
                    Mobile <span style={{ color: "red" }}>*</span>
                  </Typography>
                }
                variant="outlined"
                {...register("mobile", {
                  required: "Please Enter Mobile",
                })}
                error={!!errors?.mobile}
                helperText={errors?.mobile?.message}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 5,
            }}
          >
            <Button
              variant="contained"
              className="menus-color"
              type="submit"
              sx={{ mr: 3 }}
            >
              Update
            </Button>
          </div>
        </form>
      </Container>
    </div>
  )
}

export default ChapterProfile