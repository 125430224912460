import React from 'react'
import NavBar from './NavBar'

function HeroSection() {
  return (
    <>
      <div className="container-fluid">
      <NavBar></NavBar>
      </div>
    </>
  )
}

export default HeroSection