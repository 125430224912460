// import React, { useState, useEffect } from 'react';
// import '../../style/css/banner.css';
// import { useGetAllAnnouncementQuery } from '../../app/services/announcementService';
// import { logOut, selectCurrentScope } from '../../app/auth/authSlice';
// import { useSelector, useDispatch } from 'react-redux';
// import { useNavigate, useLocation } from 'react-router-dom';
// import { selectCurrentMember, setMemberDetails } from '../../app/features/memberSlice';
// import { setRoleDetails, selectCurrenChapterRole, setChapterDetails } from "../../app/features/chapterAdminSlice";
// import { setSigRoleDetails, selectCurrenSigRole, setSigDetails } from '../../app/features/sigSlice';
// import { api } from '../../app/services/api';

function Marquee() {
  // const [topPosition, setTopPosition] = useState(0); // Default top position
  // // const [display, setDisplay] = useState('none'); 
  // const { data: getAllAnnouncement } = useGetAllAnnouncementQuery(0); //API call of get All announcement

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const scrollTop = window.pageYOffset;

  //     if (scrollTop > 50) {
  //       setTopPosition(-7); // Change top position while scrolling
  //     } else {
  //       setTopPosition(0); // Default top position
  //     }
  //   };

  //   window.addEventListener('scroll', handleScroll);

  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);

  // const navigate = useNavigate()
  // const location = useLocation()
  // const memberScope = useSelector(selectCurrentScope);
  // const [anchorEl, setAnchorEl] = React.useState(null);
  // const [role, setRole] = useState('')
  // const open = Boolean(anchorEl);
  // const disPatch = useDispatch();
  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };
  // const handleClose = () => {

  //   setAnchorEl(null);
  // };
  // const handleLogout = () => {
  //   disPatch(logOut())
  //   navigate('/login', { replace: true, state: { from: location } })
  //   disPatch(setMemberDetails({}))
  //   disPatch(setRoleDetails({}))
  //   disPatch(setSigRoleDetails({}))
  //   disPatch(setChapterDetails({}))
  //   disPatch(setSigDetails({}))
  //   disPatch(api.util.resetApiState())
  // }
  // const memberDetails = useSelector(selectCurrentMember);
  // const roleDetails = useSelector(selectCurrenChapterRole);
  // const sigRoleDetails = useSelector(selectCurrenSigRole);

  // useEffect(() => {
  //   if (memberScope === 'PORTAL_SUPER_ADMIN') {
  //     setRole('Admin');
  //   } else if (memberScope === 'PORTAL_MEMBER') {
  //     setRole('Member');
  //   }
  //   else if (memberScope === 'PORTAL_SIG_ADMIN') {
  //     setRole('SIG Admin');
  //   }
  //   else if (memberScope === 'PORTAL_CHAPTER_ADMIN') {
  //     setRole('Chapter Admin');
  //   }
  // }, [memberScope]);

  // const routeToPortal = () => {
  //   if (role === 'Member') {
  //     navigate('/member/viewMember')
  //   }
  //   else if (role === 'SIG Admin') {
  //     navigate('/sigAdmin/sigDetails')
  //   }
  //   else if (role === 'Chapter Admin') {
  //     navigate('/chapterAdmin/chapterDetail')
  //   }
  //   else {
  //     navigate('/')
  //   }
  // }

  return (
    <>
      {/* <img src={logo} style={{ display: display, position: 'fixed', zIndex: 100000000000000000000 }} className='side_logo' alt='' /> */}
      {/* <div className='row top-nav pt-2 pb-2' style={{ top: topPosition, position: 'fixed' }}>
        <div className='col-lg-2' >
        </div>
        <div className='col-lg-8'>
          <marquee>{getAllAnnouncement?.data[0].contents}</marquee>
        </div>
        <div style={{zIndex:'1300',backgroundColor:'#0072bb'}} className='col-lg-2' >
          <Box className="banner_img" >
          {memberScope === 'PORTAL_SUPER_ADMIN' || memberScope === 'PORTAL_CHAPTER_ADMIN' || memberScope === 'PORTAL_SIG_ADMIN' || memberScope === 'PORTAL_MEMBER' ? (
            <React.Fragment>
              <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                <Tooltip title="Profile">
                  <IconButton
                    onClick={handleClick}
                    size="small"
                    sx={{ ml: 2 }}
                    aria-controls={open ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                  >
                    <Avatar sx={{ width: 32, height: 32, bgcolor: '#0072bb' }}><ManageAccountsIcon className='accountIcon' /></Avatar>
                  </IconButton>
                </Tooltip>
              </Box>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    '&::before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: 'primary.main',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              >
                {memberScope === 'PORTAL_MEMBER' ? (
                  <MenuItem onClick={routeToPortal}>
                    <Avatar />
                    {memberDetails && memberDetails.firstName} ({role})
                  </MenuItem>
                ) : memberScope === 'PORTAL_CHAPTER_ADMIN' ? (<MenuItem onClick={routeToPortal}>
                  <Avatar />
                  {roleDetails && roleDetails.name}  ({role})
                </MenuItem>) : memberScope === 'PORTAL_SIG_ADMIN' ? (
                  <MenuItem onClick={routeToPortal}>
                    <Avatar />
                    {sigRoleDetails && sigRoleDetails.name} ({role})
                  </MenuItem>) : (<MenuItem onClick={routeToPortal}>
                    <Avatar />
                    ({role})
                  </MenuItem>)}
                <MenuItem onClick={handleClose}>
                                        <Avatar /> {memberDetails.firstName} ({role})
                                    </MenuItem>
                <Divider />
                <MenuItem onClick={handleLogout}>
                  <ListItemIcon>
                    <Logout fontSize="small" />
                  </ListItemIcon>
                  Logout
                </MenuItem>
              </Menu>
            </React.Fragment>

          ) : (
            <Box className="btn_container " >
              <Link to="/register" ><Button className='top_nav_btn_join'>Join</Button></Link>
              <Link to='/login' ><Button className='top_nav_btn'>Login</Button></Link>
            </Box>
          )}
          </Box>
          <Box>
            <marquee style={{ top: topPosition, position: 'fixed' }}><span>{getAllAnnouncement?.data[0].contents}</span></marquee>
          </Box>
        </div>
      </div> */}

    </>
  );
}

export default Marquee;
