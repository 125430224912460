import React, { useState, useEffect, useCallback } from "react";
import "../../style/css/news.css";
import { usePaginationNewsMutation } from "../../app/services/journalServices";
import { Container } from "react-bootstrap";
import { selectCurrentScope } from "../../app/auth/authSlice";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import ReactPaginate from "react-paginate"; // Import CSS file for pagination styles

function ViewAllNews() {
    const [getPaginationJournal] = usePaginationNewsMutation();
    const [allNews, setAllNews] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isPaused, setIsPaused] = useState(false);
    const currentScope = useSelector(selectCurrentScope);
    let [totalCount, setTotalCount] = useState(0);
    const navigate = useNavigate();
    const itemsPerPage = 12;
    const [currentPage, setCurrentPage] = useState(0);
    const handlePageClick = ({ selected }) => {
        setCurrentPage(selected);
    };

    const fetchData = useCallback(() => {
        const data = { type: 0, page: currentPage + 1, itemsPerPage: itemsPerPage, role: currentScope || "" }; // Fetch more items if needed
        getPaginationJournal(data)
            .then((res) => {
                setAllNews(res?.data?.data?.newsDetails || []);
                setTotalCount(res?.data.data.totalCount);
            })
            .catch(() => { });
    }, [getPaginationJournal, currentScope, currentPage, itemsPerPage]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    useEffect(() => {
        if (isPaused) return;
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % allNews.length);
        }, 2000);
        return () => clearInterval(interval);
    }, [allNews.length, isPaused]);

    if (allNews.length === 0) {
        return <div>Loading...</div>;
    }

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        if (isNaN(date.getTime())) {
            return 'Invalid date';
        }
        const options = { year: "numeric", month: "long", day: "numeric" };
        try {
            return new Intl.DateTimeFormat("en-US", options).format(date);
        } catch (error) {
            console.error('Error formatting date:', error);
            return 'Error formatting date';
        }
    };

    const viewevent = (newsId) => {
        navigate(`/viewJournal/${newsId}`);
    };

    const totalPages = Math.ceil(totalCount / itemsPerPage);

    return (
        <>
            <header className='Architects-header d-flex align-items-center justify-content-center mt-5'>
                <h2 className='signika-fontOEC text-center'>News</h2>
            </header>
            <div className="container mt-5 d-flex justify-content-end">
                <Button
                    onClick={() => window.history.back()}
                    variant="contained"
                    className="menus-color mx-md-5">
                    Back
                </Button>
            </div>
            <div className="mt-5">
                <Container maxWidth="xl">
                    <div className="row">
                        <div className="col-lg-9" style={{ paddingLeft: "80px", cursor: 'pointer' }}>
                            <div className="row">
                                <div className="col-lg-8 mb-2 " style={{ position: "relative", overflow: "hidden", height: "250px" }} onMouseEnter={() => setIsPaused(true)} onMouseLeave={() => setIsPaused(false)}>
                                    {allNews.map((data, index) => (
                                        <div
                                            key={data.id}
                                            style={{
                                                position: currentIndex === index ? "absolute" : "absolute",
                                                top: 0,
                                                left: 0,
                                                width: "98%",
                                                height: "100%",
                                                opacity: currentIndex === index ? 1 : 0,
                                                transition: "opacity 0.5s ease-in-out",
                                                cursor: "pointer",
                                                zIndex: currentIndex === index ? 1 : 0,
                                            }}
                                            onClick={() => { viewevent(data._id); setCurrentIndex(index); }}
                                        >
                                            <img
                                                src={data.thumbnailImage}
                                                alt="news"
                                                style={{
                                                    width: "100%",
                                                    height: "100%",
                                                    objectFit: "cover",
                                                }}
                                            />
                                            <div
                                                style={{
                                                    position: "absolute",
                                                    bottom: "0",
                                                    left: "0",
                                                    width: "100%",
                                                    color: "white",
                                                    padding: "10px",
                                                    background: " linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.8))"
                                                }}
                                            >
                                                <div style={{ paddingLeft: "15px" }}>
                                                    <i className="fa fa-calendar" aria-hidden="true" style={{ marginRight: "5px" }}></i>
                                                    <span style={{ fontSize: "12px" }}>
                                                        {formatDate(data?.date)}
                                                    </span>
                                                </div>
                                                <p style={{ color: "white", fontSize: "20px", lineHeight: "1.0", paddingLeft: "15px", overflow: "hidden", textOverflow: "ellipsis", display: "-webkit-box", WebkitLineClamp: 2, WebkitBoxOrient: "vertical" }}>
                                                    {data.summary && data.summary.length > 100
                                                        ? `${data.summary.substring(0, 100)}...`
                                                        : data.summary}
                                                </p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                {allNews.slice(0, 4).map((data, index) => (
                                    <div className="col-lg-4 mb-4 ps-0" key={data.id} style={{ position: "relative" }} onClick={() => { viewevent(data._id); setCurrentIndex(index); }}>
                                        <img src={data.thumbnailImage} alt="news" style={{ width: "100%", height: "250px", objectFit: "cover" }} />
                                        {/* <div style={{ position: "absolute", bottom: "0", left: "0", width: "100%", color: "white", padding: "10px" }}> */}
                                        <div style={{ position: "absolute", bottom: "0", left: "0", width: "95.8%", color: "white", padding: "10px", background: " linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.8))" }}>
                                            <div style={{ marginBottom: "5px", paddingLeft: "15px" }}>
                                                <i className="fa fa-calendar" aria-hidden="true" style={{ marginRight: "5px" }}></i>
                                                <span style={{ fontSize: "12px" }}>{formatDate(data.date)}</span>
                                            </div>
                                            <p style={{
                                                color: "white", fontSize: "20px", lineHeight: "1.0",
                                                paddingLeft: "15px", overflow: "hidden", textOverflow: "ellipsis",
                                                display: "-webkit-box", WebkitLineClamp: 2, WebkitBoxOrient: "vertical",
                                            }}>
                                                {data.summary && data.summary.length > 50 ? `${data.summary.substring(0, 50)}...` : data.summary}
                                            </p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="col-lg-3" style={{ height: "513px", position: "relative", cursor: 'pointer' }}>
                            {allNews.length > 5 && (
                                <img src={allNews[5].thumbnailImage} onClick={() => { viewevent(allNews[5]._id) }} alt="news" style={{ height: "102%", width: "100%", objectFit: "cover" }} />
                            )}
                            <div style={{ position: "absolute", bottom: "-46", left: "12", width: "92.4%", color: "white", padding: "10px", background: " linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.8))", top: '81.5%' }}>
                                <div style={{ marginBottom: "5px", paddingLeft: "15px" }}>
                                    <i className="fa fa-calendar" aria-hidden="true" style={{ marginRight: "5px" }}></i>
                                    <span style={{ fontSize: "12px" }}>{formatDate(allNews[5]?.date)}</span>
                                </div>
                                <p style={{
                                    color: "white", fontSize: "20px", lineHeight: "1.0",
                                    paddingLeft: "15px", overflow: "hidden", textOverflow: "ellipsis",
                                    display: "-webkit-box", WebkitLineClamp: 2, WebkitBoxOrient: "vertical",
                                }}>
                                    {allNews[5]?.summary && allNews[5]?.summary.length > 50 ? `${allNews[5]?.summary.substring(0, 50)}...` : allNews[5]?.summary}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row" style={{ paddingLeft: "44px", cursor: 'pointer' }}>
                        {allNews.slice(4).map((data, index) => (
                            <div className="col-lg-3 mb-4 ps-0" key={data.id} style={{ position: "relative" }} onClick={() => { viewevent(data._id); setCurrentIndex(index); }}>
                                <img src={data.thumbnailImage} alt="news" style={{ width: "100%", height: "250px", objectFit: "cover" }} />
                                <div style={{ position: "absolute", bottom: "0", left: "0", width: "95.8%", color: "white", padding: "10px", background: " linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.8))" }}>
                                    <div style={{ marginBottom: "5px", paddingLeft: "15px" }}>
                                        <i className="fa fa-calendar" aria-hidden="true" style={{ marginRight: "5px" }}></i>
                                        <span style={{ fontSize: "12px" }}>{formatDate(data.date)}</span>
                                    </div>
                                    <p style={{
                                        color: "white", fontSize: "20px", lineHeight: "1.0",
                                        paddingLeft: "15px", overflow: "hidden", textOverflow: "ellipsis",
                                        display: "-webkit-box", WebkitLineClamp: 2, WebkitBoxOrient: "vertical",
                                    }}>
                                        {data.summary && data.summary.length > 50 ? `${data.summary.substring(0, 50)}...` : data.summary}
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>
                </Container>
                <div className='pb-lg-5 pb-3'>
                    <ReactPaginate
                        pageCount={totalPages} // Replace with the total number of pages
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={2}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination"}
                        activeClassName={"active"}
                        previousLabel={(currentPage + 1) > 1 ? "Previous" : null}
                        nextLabel={((currentPage + 1) > (totalPages - 1)) ? null : "Next"}
                        disabledClassName={"disablebtn"}
                        forcePage={currentPage}
                    />
                </div>
            </div>
        </>
    );
}

export default ViewAllNews;

