import { api } from "./api";

const subscriptionServices = api.injectEndpoints({
  endpoints: (builder) => ({
    
     //save testimonial
     saveMail: builder.mutation({
      query: (formData) => ({
        url: "subscription/saveSubscription",
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: formData,
      }),
    }),


  }),
  overrideExisting: false,
});

export const {
    useSaveMailMutation
} = subscriptionServices