import React, { useState, useEffect, } from 'react'
import { Box, Container, TextField, Button, FormControl, InputLabel, Select, MenuItem, Alert } from '@mui/material'
import TopHeader from '../../../Component/Header/TopHeader'
import { useForm, Controller } from 'react-hook-form';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { useLazyGetByResumeIdQuery, useSaveResumeMutation, useUpdateResumeMutation } from '../../../app/services/resumeService'
import { Link, useParams } from 'react-router-dom';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import Spinner from "../../../Component/spinner/spinner"

const steps = ['Job Details', 'Contact', 'Permission', 'Administrator Only'];
const Resume = () => {
  const { register, handleSubmit, setValue, control, watch } = useForm({
    defaultValues: {
      title: '',
      description: '',
      resumeUrl: '',
      industry: '',
      location: '',
      skills: '',
      experience: '',
      awardsCertification: '',
      education: '',
      tags: '',
      duration: '',
      contactFirstName: '',
      contactLastName: '',
      contactAddress: '',
      contactAddress2: '',
      contactPhone: '',
      contactFax: '',
      contactCity: '',
      contactState: '',
      contactZipCode: '',
      contactCountry: '',
      contactEmail: '',
      contactWebsite: '',
      activationDate: '',
      activationTime: '',
      expirationDate: '',
      expirationTime: ''
    }
  });
  const [getByIdResume] = useLazyGetByResumeIdQuery()
  const [loading, setLoading] = useState(false);
  // const [isChecked, setIsChecked] = useState(false);
  const [saveResume] = useSaveResumeMutation()
  const [updateResume] = useUpdateResumeMutation()
  let [setFile] = useState()
  const { id } = useParams()
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  let [isPublic, setIsPublic] = useState(false)
  const [successMessage, setSuccessMessage] = useState();
  const [setFileChanged] = useState(false)
  const navigator = useNavigate()
  const isStepOptional = (step) => {
    return step === 1;
  };
  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  useEffect(() => {
    if (id) {
      setLoading(true);
      getByIdResume(id).then((res) => {
        if (res?.data?.status) {
          setLoading(false);
          Object.entries(res?.data?.data).forEach(([key, value]) => {
            setValue(key, value);
          });
        }
        setValue("isAgency", res.data.data.isAgency)
        setValue("status", res.data.data.status)
        setValue("duration", res.data.data.duration)
        // let isPublic = watch('isPublic', res.data.data.isPublic)
        let startTimeDayjs = dayjs(res.data.data.activationTime, "h:mm A");
        let endTimeDayjs = dayjs(res.data.data.expirationTime, "h:mm A");
        setValue("activationTime", startTimeDayjs);
        setValue("expirationTime", endTimeDayjs);
      })
    }


  }, [id, getByIdResume, setValue])

  const saveUpdateResume = (data) => {
    setLoading(true);
    const formData = new FormData();
    // Iterate over the keys and values of the data object
    Object.entries(data).forEach(([key, value]) => {
      if (value instanceof FileList) {
        formData.append(key, value[0]);
      } else if (key !== 'activationTime' && key !== 'expirationTime') {
        formData.append(key, value);
      }
    });
    if (data.activationTime) {
      formData.append("activationTime", formatTime(data.activationTime));
    }
    if (data.expirationTime) {
      formData.append("expirationTime", formatTime(data.expirationTime));
    }
    if (id) {
      const updateData = { id: id, formData }
      updateResume(updateData).then((res) => {
        if (res?.data?.status) {
          setLoading(false);
          setSuccessMessage("Resume Table update SucuessFully")
          setTimeout(() => setSuccessMessage(false), 4000)
          navigator('/resume-table')
        }
      })
    }
    else {
      saveResume(formData).then((res) => {
        if (res?.data?.status) {
          setLoading(false);
          setSuccessMessage("Resume Table Saved SucuessFully")
          setTimeout(() => setSuccessMessage(false), 4000)
          navigator('/resume-table')
        }
      }).catch()
    }
  }

  const formatTime = (timeString) => {
    // Parse the time string
    const time = dayjs(timeString);
    // Format the time in 12-hour format with AM/PM
    const formattedTime = time.format('hh:mm A');
    return formattedTime;
  }

  const Duration = [{
    id: 30,
    state: "30 days"
  },
  {
    id: 60,
    state: "60 days"
  },
  {
    id: 90,
    state: "90 days"
  }]
  const resumeStatus = [{
    id: 0,
    state: "Active"
  },
  {
    id: 1,
    state: "Pending"
  },
  {
    id: 2,
    state: "Inactive"
  },
  ]

  const durat = watch('duration')
  const uploadResume = watch('uploadResume')
  const IsPublic = watch('isPublic')
  const rssFeedWatch = watch('isRssFeed')
  const statusWatch = watch('status')
  const isAgency = watch('isAgency')

  return (
    <Box>
      <TopHeader />
      <Box className="banner_text"></Box>
      <div className='container mt-3 mb-2'>
        <Button onClick={() => window.history.back()} variant="contained" color="primary"
          style={{ float: 'right' }}>Back</Button>
      </div>
      {loading ? (
        <Spinner />
      ) : (
        <div className='Resume-container mt-3'>
          <Container maxWidth={'lg'}>
          </Container>
          <Container maxWidth="xl">
            {successMessage && (
              <Alert variant="filled" severity="success">
                {successMessage}
              </Alert>
            )}
            <Box sx={{ width: '100%' }}>
              <Stepper activeStep={activeStep} className='mt-5'>
                {steps.map((label, index) => {
                  const stepProps = {};
                  const labelProps = {};
                  if (isStepOptional(index)) {
                    labelProps.optional = (
                      <></>
                    );
                  }
                  if (isStepSkipped(index)) {
                    stepProps.completed = false;
                  }
                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
              <form className='mt-5' onSubmit={handleSubmit(saveUpdateResume)}>
                {activeStep === 0 && (
                  <Box>
                    <div className='row'>
                      <div className='col-md-4'>
                        <TextField id="outlined-basic" label="Title" InputLabelProps={{ shrink: true }} fullWidth
                          {...register('title', { required: true })} className='mt-3'
                          required />
                        <TextField id="outlined-basic" label="Description" InputLabelProps={{ shrink: true }} fullWidth
                          {...register('description', { required: true })} className='mt-3'
                          required />
                        {!id ?
                          <>
                            <input required style={{ border: "1px solid #81737a", width: "100%", padding: "15px" }}

                              type="file" className=' mt-3 secondry'   {...register('uploadResume', {
                                onChange: (e) => { setFile(e.target.files[0]); setFileChanged(true) }
                              }, { required: true })}

                            />
                          </>
                          :
                          <>
                            <Link to={uploadResume} target='_blank'>View</Link>
                            <input required
                              type="file" className='mt-4 border border-secondary'   {...register('uploadResume', {
                                onChange: (e) => { setFile(e.target.files[0]); setFileChanged(true) }
                              }, { required: true })}
                            />
                          </>
                        }
                        <TextField id="outlined-basic" label="Resume URL" InputLabelProps={{ shrink: true }} fullWidth
                          {...register('resumeUrl', { required: true })} className='mt-3'
                          required />
                      </div>
                      <div className='col-md-4'>
                        <TextField id="outlined-basic" label="Industry" InputLabelProps={{ shrink: true }} fullWidth
                          {...register('industry', { required: true })} className='mt-3'
                          required />
                        <TextField id="outlined-basic" label="Location" InputLabelProps={{ shrink: true }} fullWidth
                          {...register('location', { required: true })} className='mt-3'
                          required />
                        <TextField id="outlined-basic" label="Skills" InputLabelProps={{ shrink: true }} fullWidth
                          {...register('skills', { required: true })} className='mt-3'
                          required />
                        <TextField id="outlined-basic" label="Awards Certification" InputLabelProps={{ shrink: true }} fullWidth
                          {...register('awardsCertification', { required: true })} className='mt-3'
                          required />
                      </div>
                      <div className="col-md-4">
                        <TextField id="outlined-basic" label="Education" InputLabelProps={{ shrink: true }} fullWidth
                          {...register('education', { required: true })} className='mt-3'
                          required />
                        <TextField id="outlined-basic" label="Experience" InputLabelProps={{ shrink: true }} fullWidth
                          {...register('experience', { required: true })} className='mt-3'
                          required />
                        <TextField id="outlined-basic" label="Tags" InputLabelProps={{ shrink: true }} fullWidth
                          {...register('tags', { required: true })} className='mt-4'
                          required />
                        <FormControl style={{ width: '100%' }} className='mt-2' >
                          <InputLabel id="event">Duration</InputLabel>
                          <Select
                            labelId="event"
                            id="event"
                            value={durat}
                            {...register('duration')}
                          >
                            {Duration.map((event) => (
                              <MenuItem key={event.id} value={event.id}>{event.state}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  </Box>
                )}
                {activeStep === 1 && (
                  <Box>
                    <div className='row'>
                      <div className='col-md-4'>
                        <TextField id="outlined-basic" label="Contact First Name" InputLabelProps={{ shrink: true }} fullWidth
                          {...register('contactFirstName', { required: true })} className='mt-3'
                          required />
                        <TextField id="outlined-basic" label="Contact Last Name" InputLabelProps={{ shrink: true }} fullWidth
                          {...register('contactLastName', { required: true })} className='mt-3'
                          required />
                        <TextField id="outlined-basic" label="Contact Address" InputLabelProps={{ shrink: true }} fullWidth
                          {...register('contactAddress', { required: true })} className='mt-3'
                          required />
                        <TextField id="outlined-basic" label="Contact Address2" InputLabelProps={{ shrink: true }} fullWidth
                          {...register('contactAddress2', { required: true })} className='mt-3'
                          required />

                      </div>
                      <div className='col-md-4'>
                        <TextField id="outlined-basic" label="Contact Email" InputLabelProps={{ shrink: true }} fullWidth
                          {...register('contactEmail', { required: true })} className='mt-3'
                          required />
                        <TextField id="outlined-basic" label="Contact Phone" InputLabelProps={{ shrink: true }} fullWidth
                          {...register('contactPhone', { required: true })} className='mt-3'
                          required />
                        <TextField id="outlined-basic" label="Contact Fax" InputLabelProps={{ shrink: true }} fullWidth
                          {...register('contactFax', { required: true })} className='mt-3'
                          required />
                        <TextField id="outlined-basic" label="Contact State" InputLabelProps={{ shrink: true }} fullWidth
                          {...register('contactState', { required: true })} className='mt-3'
                          required />


                      </div>
                      <div className='col-md-4'>
                        <TextField id="outlined-basic" label="Contact Country" InputLabelProps={{ shrink: true }} fullWidth
                          {...register('contactCountry', { required: true })} className='mt-3'
                          required />
                        <TextField id="outlined-basic" label="Contact City" InputLabelProps={{ shrink: true }} fullWidth
                          {...register('contactCity', { required: true })} className='mt-3'
                          required />
                        <TextField id="outlined-basic" label="Contact ZipCode " InputLabelProps={{ shrink: true }} fullWidth
                          {...register('contactZipCode', { required: true })} className='mt-3'
                          required />
                        <TextField id="outlined-basic" label="Contact WebSite" InputLabelProps={{ shrink: true }} fullWidth
                          {...register('contactWebsite', { required: true })} className='mt-3'
                          required />
                      </div>
                    </div>

                  </Box>

                )}

                {activeStep === 2 && (

                  <Box>
                    <div className='row'>
                      <div className='col-md-6'>
                        <FormControlLabel style={{ width: '100%' }} control={<Checkbox checked={IsPublic}
                          {...register('isPublic', {
                            onChange: (e) => setIsPublic(isPublic)
                          })}
                        />} label="Public can View" />
                      </div>

                      <div className='col-md-6'>
                        <FormControlLabel style={{ width: '100%' }} control={<Checkbox checked={isAgency}
                          {...register('isAgency')}
                        />} label="isAgency" />
                        <div style={{ width: '%' }} ></div>
                        <labal>Are you an agency posting this resume?</labal>
                      </div>
                    </div>
                    {/* <input type='checkbox'checked={isPublic} onChange={(e)=>setIsPublic(e.target.checked)} {...register('ispublic')} /> */}
                  </Box>
                )}
                {activeStep === 3 && (
                  <Box>
                    <div className='row'>
                      <div className='col-md-4'>
                        <TextField InputLabelProps={{ shrink: true }} style={{ width: '100%' }} label="Activation Date" fullWidth type='date' {...register('activationDate', { required: 'Please Enter a Activation Date' })} />
                        <TextField InputLabelProps={{ shrink: true }} className="mt-3" style={{ width: '100%' }} label="Expiration Date" fullWidth type='date' {...register('expirationDate', { required: 'Please Enter a Expiration Date' })} />
                        <div>

                        </div>
                      </div>
                      <div className='col-md-4'>
                        <div style={{ width: '80%' }}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <div style={{ width: "60%" }}>
                              <Controller
                                control={control}
                                name="activationTime"
                                render={({ field: { onChange, value } }) => (
                                  <TimePicker
                                    label="Activation Time"
                                    InputLabelProps={{ shrink: true }}
                                    variant="outlined"
                                    value={value || null}
                                    onChange={(newTime) => {
                                      onChange(newTime);
                                    }}
                                    renderInput={(params) => (
                                      <TextField {...params} error={false} />
                                    )}
                                    viewRenderers={{
                                      hours: renderTimeViewClock,
                                      minutes: renderTimeViewClock,
                                      seconds: renderTimeViewClock,
                                    }}
                                  />
                                )}
                              />
                            </div>
                          </LocalizationProvider>
                        </div>
                        <div style={{ width: '80%', marginTop: '20px' }}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <div style={{ width: "60%" }}>
                              <Controller
                                control={control}
                                name="expirationTime"
                                render={({ field: { onChange, value } }) => (
                                  <TimePicker
                                    label="Expiration Time"
                                    InputLabelProps={{ shrink: true }}
                                    variant="outlined"
                                    value={value || null}
                                    onChange={(newTime) => {
                                      onChange(newTime);
                                    }}
                                    renderInput={(params) => (
                                      <TextField {...params} error={false} />
                                    )}
                                    viewRenderers={{
                                      hours: renderTimeViewClock,
                                      minutes: renderTimeViewClock,
                                      seconds: renderTimeViewClock,
                                    }}
                                  />
                                )}
                              />
                            </div>
                          </LocalizationProvider>
                        </div>
                      </div>
                      <div className='col-md-4'>
                        <FormControlLabel style={{ width: '80%', marginTop: '20px' }} control={<Checkbox checked={rssFeedWatch}   {...register('isRssFeed')} />} label="RssFeed" />
                        <FormControl style={{ width: '100%' }} className='mt-4' >
                          <InputLabel id="event">Status</InputLabel>
                          <Select
                            labelId="event"
                            id="event"
                            label="Event"
                            value={statusWatch}
                            // onChange={(event) => setIsStatus(event.target.value)}
                            {...register('status')}
                          >
                            {resumeStatus.map((event) => (
                              <MenuItem key={event.id} value={event.id}>{event.state}</MenuItem>
                            ))}

                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  </Box>

                )}

                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, mt: 3, mb: 3 }}>

                  <Button variant="" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>

                    Back

                  </Button>

                  <Box sx={{ flex: '1 1 auto' }} />

                  <Button variant="" onClick={activeStep === steps.length - 1 ? handleSubmit(saveUpdateResume) : handleNext}>

                    {activeStep === steps.length - 1 ? 'Submit' : 'Next'}

                  </Button>

                </Box>
              </form>
            </Box>
          </Container>
        </div>
      )}
    </Box>
  )

}

export default Resume