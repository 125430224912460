import {
    Autocomplete,
    FormHelperText,
    FormLabel,
    Radio,
    RadioGroup,
    TextField,
    Typography,
} from "@mui/material";
import {
    Box,
    Button,
    FormControlLabel,
    InputLabel,
    MenuItem,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm, Controller, } from "react-hook-form";
import Select from "@mui/material/Select";
import "../../../style/css/eventForm.css";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import FormControl from "@mui/material/FormControl";
import { useGetAllSIGGroupQuery } from "../../../app/services/SIGGroupServices";
import { useNavigate } from "react-router-dom";
import { useLazyGetAllChaptersQuery } from "../../../app/services/chapterServices";
import Grid from "@mui/material/Grid";
import { toast } from "react-toastify";
import Spinner from "../../../Component/spinner/spinner";
import { TypeOfInstitution, PrimarilyTeaching } from '../../../constant/constant'
import { Country, State } from 'country-state-city';
import { useLazyGetAllMemberShipDetailsQuery } from '../../../app/services/memberShipServices'
import { useCreateMemberMutation, useLazyGetMemberDetailsByIdQuery } from '../../../app/services/memberServices'

const steps = [
    "Personal Information",
    "Payment Information",
];

function AddEditInstitution() {
    const [loading, setLoading] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [allSIG, setAllSIG] = React.useState([]);
    const [selectedChapters, setSelectedChapters] = useState([]);
    const [getAllChapters] = useLazyGetAllChaptersQuery();
    const [getAllplans] = useLazyGetAllMemberShipDetailsQuery()
    const [planName, setPlanName] = useState([])
    const [amount, setAmount] = useState()
    const [currency, setCurrency] = useState()
    const [createMember] = useCreateMemberMutation()
    const [gettMemberDetailsByIdQuery] = useLazyGetMemberDetailsByIdQuery();
    const navigator = useNavigate()
    const [showPrimarilyOtherInput, setShowPrimarilyOtherInput] = useState(false);
    const [otherPrimarily, setOtherPrimarily] = useState('');
    const countries = Country.getAllCountries();
    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedState, setSelectedState] = useState('');
    const [costType, setCostType] = useState([]);
    const { refetch: refetchSIG } = useGetAllSIGGroupQuery();

    // useForm hook for form data handling
    const {
        control,
        register,
        handleSubmit,
        watch,
        setValue,
        getValues,
        formState: { errors },
    } = useForm({
        mode: 'onChange',
        defaultValues: {
            type: 2,
            cityTown: "",
            address: "",
            pinCode: "",
            state: "",
            country: "",
            uploadInstitutionLogo: '',
            mobileNumber: '',
            landlineNumber: '',
            email: '',
            role: "PORTAL_MEMBER",
            websiteAddressInstitution: "",
            institutionEstablished: "",
            chapter: '',
            typeOfInstitution: "",
            levelOfInstitution: "",
            otherLevelOfInstitution: "",
            teachersInInstitution: "",
            studentsInInstitution: "",
            emailMessage: '',
            dummyPlanId: "",
            dummyPlanTypeId: "",
            dummyAmount: "",
            dummyCurrency: ""
        },
    });

    const states = selectedCountry ? State.getStatesOfCountry(selectedCountry) : [];
    const selectedPlanName = watch("dummyPlanId");
    const selectedplantypeId = watch("dummyPlanTypeId")

    //Validate sigGroups and chapter
    const validateAtLeastOne = () => {
        const { sigGroups } = getValues();
        return (sigGroups && sigGroups.length > 0) || "Please select at least one of SIG Groups";
    };

    useEffect(() => {
        getAllplans().then((res) => {
            if (res?.data?.status) {
                const filteredPlan = res?.data?.data?.filter(item => item.isDeleted === 0)
                setPlanName(filteredPlan)
            }
        })
    }, [getAllplans])

    useEffect(() => {
        if (selectedPlanName) {
            const foundPlan = planName.find((plan) => plan._id === selectedPlanName);
            setCostType(foundPlan?.planType || []);
        }
    }, [selectedPlanName, planName]);

    useEffect(() => {
        costType.forEach((data) => {
            if (data._id === selectedplantypeId) {
                setAmount(data.amount)
                setCurrency(data.currencyType)
            }
        })
    }, [costType, selectedplantypeId])

    useEffect(() => {
    }, [costType])

    useEffect(() => {
        if (amount && currency) {
            // Set value for the amount and currency once they change
            setValue("Amount", amount);  // Patches the amount field instantly
            setValue("Currency", currency);
        }
    }, [amount, currency, setValue]);

    //On submit for save
    const AddEditInstitution = (data) => {
        if (activeStep === steps.length - 1) {
            setLoading(false)
            const formData = new FormData();
            Object.entries(data).forEach(([key, value]) => {
                if (value instanceof FileList) {
                    formData.append(key, value[0]);
                } else if (key !== 'sigGroups' && key !== "dummyAmount" && key !== "dummyCurrency" && key !== "otherPrimarily" && key !== "levelOfInstitution" && key !== 'otherLevelOfInstitution') {
                    formData.append(key, value);
                }
            });
            formData.append("dummyAmount", amount)
            formData.append("dummyCurrency", currency)
            if (data.sigGroups && data.sigGroups.length > 0) {
                data.sigGroups.forEach(item => {
                    formData.append("sigGroups", item)
                })
            }

            // Handle levelOfInstitution and otherLevelOfInstitution
            const { levelOfInstitution } = data;
            formData.append("levelOfInstitution", levelOfInstitution);

            if (showPrimarilyOtherInput && otherPrimarily) {
                formData.append("otherLevelOfInstitution", otherPrimarily);
            } else {
                formData.append("otherLevelOfInstitution", "");
            }

            createMember(formData).then((res) => {
                if (res?.data?.data) {
                    var options = {
                        key: process.env.REACT_APP_RAZOR_PAY_KEY_ID, // Enter the Key ID generated from the Dashboard
                        amount: amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
                        currency: (amount === 0 ? "INR" : "USD"),
                        name: res?.data?.data?.fullName,
                        description: "Membership payment",
                        order_id: res?.data?.data?.purchaseData?.orderId, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
                        handler: function (response) {
                            gettMemberDetailsByIdQuery(res?.data?.data?._id).then((res) => {
                                if (res.data?.status) {
                                    const isPaid = res.data?.data?.plans[0]?.isPaid === 1;
                                    setValue("paymentStatus", isPaid);
                                    toast.success("Member Saved Successfully");
                                    navigator('/login')
                                }
                            });
                        },
                    };
                    var rzp1 = new window.Razorpay(options);
                    rzp1.open();
                    rzp1.on("payment.failed", function (response) {
                        alert("Error in Payment", response.error);
                        toast.error("Member Created Failed");
                    });
                }
                else {
                    toast.error(res.error.data.error);
                }
            })
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    //Get all chapter and sig
    React.useEffect(() => {
        refetchSIG().then((res) => {
            setAllSIG(res?.data?.data || []);
        });
        getAllChapters().then((res) => {
            setSelectedChapters(res?.data?.data || []);
        });
    }, [
        setValue,
        getAllChapters,
        refetchSIG,
    ]);

    //handle Back
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const validateFile = (file) => {
        const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg'];
        const maxSize = 5 * 1024 * 1024; // 5 MB size limit
        if (file.length === 0) {
            return "File is required!";
        }
        if (!allowedTypes.includes(file[0].type)) {
            return "Only JPG, JPEG or PNG images are allowed!";
        }
        if (file[0].size > maxSize) {
            return "File size should not exceed 5MB!";
        }
        return true;
    };

    return (
        <>
            <Box>
                <div className='pt-1  pb-3'>
                    <div className='p-0'>
                        <div className=' Architects-header d-flex align-items-center justify-content-center '>
                            <h2 className='signika-fontOEC text-center '>Educational Institute</h2>
                        </div>
                    </div>
                </div>
            </Box>
            <div className="container mt-3">
                <div className="row">
                    <div className="col-md-8">
                    </div>
                    <div className="col-md-4 mt-3">
                        <Button
                            onClick={() => window.history.back()}
                            variant="contained"
                            className="btn-color"
                            style={{ float: "right", marginRight: 58 }}
                        >
                            Back
                        </Button>
                    </div>
                </div>
            </div>
            {loading ? (
                <Spinner />
            ) : (
                <Box
                    className="container"
                    sx={{ width: "100%", marginTop: "50px", marginBottom: "50px" }}
                >
                    <Stepper activeStep={activeStep} className="mt-5">
                        {steps.map((label, index) => {
                            return (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>
                    <form className="mt-5" onSubmit={handleSubmit(AddEditInstitution)}>
                        {activeStep === 0 && (
                            <Box>
                                <div style={{ paddingLeft: 40, paddingRight: 40 }}>
                                    <Grid container rowSpacing={3} columnSpacing={5}>
                                        <Grid item xs={12}>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                InputLabelProps={{ shrink: true }}
                                                fullWidth
                                                label={
                                                    <Typography className="text-dark">
                                                        Name of the Institution<span style={{ color: "red" }}>*</span>
                                                    </Typography>
                                                }
                                                {...register("institution", {
                                                    required: "Please Enter a Institution Name",
                                                    minLength: { value: 3, message: 'Please Enter a valid Institution Name' }
                                                })}
                                                error={!!errors?.institution}
                                                helperText={errors?.institution?.message}
                                                placeholder="Name of the Institution"
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                InputLabelProps={{ shrink: true }}
                                                fullWidth
                                                label={
                                                    <Typography className="text-dark">
                                                        Who should be addressed to (when we send receipts &amp; journals)?<span style={{ color: "red" }}>*</span>
                                                    </Typography>
                                                }
                                                {...register("addressedTo", {
                                                    required: "Please Enter a Full Name",
                                                    minLength: { value: 3, message: 'Please Enter a valid Name' }
                                                })}
                                                error={!!errors?.addressedTo}
                                                helperText={errors?.addressedTo?.message}
                                                placeholder="Who should be addressed to?"
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                InputLabelProps={{ shrink: true }}
                                                fullWidth
                                                label={
                                                    <Typography className="text-dark">
                                                        Your Address (for Communication)<span style={{ color: "red" }}>*</span>
                                                    </Typography>
                                                }
                                                {...register("address", {
                                                    required: "Please Enter a Address",
                                                    minLength: { value: 5, message: 'Please Enter a valid address' }
                                                })}
                                                error={!!errors?.address}
                                                helperText={errors?.address?.message}
                                                placeholder="Your Address"
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                InputLabelProps={{ shrink: true }}
                                                fullWidth
                                                label={
                                                    <Typography className="text-dark">
                                                        Your City/Town <span style={{ color: "red" }}>*</span>
                                                    </Typography>
                                                }
                                                {...register("city", {
                                                    required: "Please Enter a City/Town",
                                                    minLength: { value: 3, message: 'Please Enter a valid City/Town' }
                                                })}
                                                error={!!errors?.city}
                                                helperText={errors?.city?.message}
                                                placeholder="Your City/Town"
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                InputLabelProps={{ shrink: true }}
                                                fullWidth
                                                label={
                                                    <Typography className="text-dark">
                                                        Pin Code <span style={{ color: "red" }}>*</span>
                                                    </Typography>
                                                }
                                                {...register("pinCode", {
                                                    required: "Please Enter a Pin Code",
                                                    minLength: { value: 6, message: 'Please Enter a valid Pin Code' },
                                                    maxLength: { value: 6, message: 'Please Enter a valid Pin Code' }
                                                })}
                                                error={!!errors?.pinCode}
                                                helperText={errors?.pinCode?.message}
                                                placeholder="Pin Code"
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <FormControl fullWidth className="mt-0" error={!!errors.country}>
                                                <InputLabel>Country<span style={{ color: "red" }}>*</span></InputLabel>
                                                <Controller
                                                    name="country"
                                                    control={control}
                                                    defaultValue=""
                                                    rules={{
                                                        required: "Country is Required", // Validation rule
                                                    }}
                                                    render={({ field }) => (
                                                        <Select
                                                            {...field}
                                                            value={selectedCountry}
                                                            onChange={(e) => {
                                                                setSelectedCountry(e.target.value);
                                                                field.onChange(e);
                                                            }}
                                                        >
                                                            <MenuItem value="">
                                                                <em>Select Country</em>
                                                            </MenuItem>
                                                            {countries.map((country) => (
                                                                <MenuItem key={country.isoCode} value={country.isoCode}>
                                                                    {country.name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    )}
                                                />
                                                {errors.country && (
                                                    <FormHelperText>{errors.country.message}</FormHelperText>
                                                )}
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <FormControl fullWidth className="mt-0" disabled={!selectedCountry} error={!!errors.state}>
                                                <InputLabel>State<span style={{ color: "red" }}>*</span></InputLabel>
                                                <Controller
                                                    name="state"
                                                    control={control}
                                                    defaultValue=""
                                                    rules={{
                                                        required: selectedCountry ? "State is required" : 'Please Select a Country', // Only required if a country is selected
                                                    }}
                                                    render={({ field }) => (
                                                        <Select
                                                            {...field}
                                                            value={selectedState}
                                                            onChange={(e) => {
                                                                setSelectedState(e.target.value);
                                                                field.onChange(e);
                                                            }}
                                                        >
                                                            <MenuItem value="">
                                                                <em>Select State</em>
                                                            </MenuItem>
                                                            {states.map((state) => (
                                                                <MenuItem key={state.isoCode} value={state.name}>
                                                                    {state.name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    )}
                                                />
                                                {errors?.state && (
                                                    <FormHelperText>{errors?.state?.message}</FormHelperText>
                                                )}
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                InputLabelProps={{ shrink: true }}
                                                fullWidth
                                                label={
                                                    <Typography className="text-dark">
                                                        Mobile Number<span style={{ color: "red" }}>*</span>
                                                    </Typography>
                                                }
                                                {...register("mobileNumber", {
                                                    required: "Please Enter a Mobile Number ",
                                                    pattern: {
                                                        value: /^\d{10}$/,
                                                        message: 'Mobile Number must be 10 digits'
                                                    }
                                                })}
                                                error={!!errors?.mobileNumber}
                                                helperText={errors?.mobileNumber?.message}
                                                placeholder="Mobile Number"
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                InputLabelProps={{ shrink: true }}
                                                fullWidth
                                                label={
                                                    <Typography className="text-dark">
                                                        Email Address<span style={{ color: "red" }}>*</span>
                                                    </Typography>
                                                }
                                                {...register("email", {
                                                    required: "Please Enter a Email Address",
                                                    pattern: {
                                                        value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                                        message: 'Enter a valid Email Id'
                                                    }
                                                })}
                                                error={!!errors?.email}
                                                helperText={errors?.email?.message}
                                                placeholder="Email Address"
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                InputLabelProps={{ shrink: true }}
                                                fullWidth
                                                label={
                                                    <Typography className="text-dark">
                                                        Landline Number
                                                    </Typography>
                                                }
                                                {...register("landlineNumber", {
                                                    pattern: {
                                                        value: /^(?:\+91[-\s]?)?(?:\(\d{3}\)|\d{3})[-\s]?\d{7}$/,
                                                        message: 'Please Enter a valid landline number'
                                                    }
                                                })}
                                                error={!!errors?.landlineNumber}
                                                helperText={errors?.landlineNumber?.message}
                                                placeholder=" Landline Number"
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                InputLabelProps={{ shrink: true }}
                                                fullWidth
                                                label={
                                                    <Typography className="text-dark">
                                                        Website Address of your institution (if any)
                                                    </Typography>
                                                }
                                                {...register("websiteAddressInstitution", {
                                                    pattern: { value: /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[\w .-]*)*\/?$/, message: 'Please Enter a valid website address' }
                                                })}
                                                error={!!errors?.websiteAddressInstitution}
                                                helperText={errors?.websiteAddressInstitution?.message}
                                                placeholder="Institution Website Address"
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                InputLabelProps={{ shrink: true }}
                                                fullWidth
                                                label={
                                                    <Typography className="text-dark">
                                                        When was your institution established? <span style={{ color: "red" }}>*</span>
                                                    </Typography>
                                                }
                                                variant="outlined"
                                                type="date"
                                                value={watch("institutionEstablished") ? watch("institutionEstablished").split("T")[0] : ""}
                                                {...register("institutionEstablished", {
                                                    required: "Please Select a Institution Established",
                                                    max: { value: new Date().toISOString().split('T')[0], message: 'Future Date is not allowed' }
                                                })}
                                                error={!!errors?.institutionEstablished}
                                                helperText={errors?.institutionEstablished?.message}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Controller control={control} rules={{ required: "Please Select a Type Of Institution", }} name="typeOfInstitution" render={({ field, fieldState }) => (
                                                <FormControl fullWidth error={!!fieldState.error}>
                                                    <InputLabel id="event">
                                                        Type of your institution<span style={{ color: "red" }}>*</span>
                                                    </InputLabel>
                                                    <Select
                                                        style={{ backgroundColor: "#f0f0f0" }}
                                                        labelId="typeOfInstitution"
                                                        id="event"
                                                        label={
                                                            <Typography className="text-dark">
                                                                Type of your institution<span style={{ color: "red" }}>*</span>
                                                            </Typography>
                                                        }
                                                        value={field.value ? field.value : ""}
                                                        variant="outlined"
                                                        onChange={(e) => field.onChange(e.target.value)}
                                                        error={!!fieldState.error}
                                                    >
                                                        {TypeOfInstitution.map((data) => (
                                                            <MenuItem key={data.type} value={data.id}>
                                                                {data.type}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                    {errors.typeOfInstitution && (
                                                    <FormHelperText>{errors.typeOfInstitution.message}</FormHelperText>
                                                )}
                                                </FormControl>
                                            )} />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Controller control={control} rules={{ required: "Please Select a Level Of Institution", }} name="levelOfInstitution" render={({ field, fieldState }) => (
                                                <>
                                                    <FormControl fullWidth error={!!fieldState.error}>
                                                        <InputLabel id="event">
                                                            Level of your Institution<span style={{ color: "red" }}>*</span>
                                                        </InputLabel>
                                                        <Select
                                                            style={{ backgroundColor: "#f0f0f0" }}
                                                            label={
                                                                <Typography className="text-dark">
                                                                    Level of your Institution<span style={{ color: "red" }}>*</span>
                                                                </Typography>
                                                            }
                                                            value={field.value ? field.value : ""}
                                                            variant="outlined"
                                                            onChange={(event, values) => {
                                                                const selectedValue = event.target.value;
                                                                field.onChange(selectedValue);
                                                                const isOther = selectedValue === PrimarilyTeaching.find(option => option.label === 'Other').id;
                                                                setShowPrimarilyOtherInput(isOther);
                                                                // If 'Other' is selected, clear the 'otherPrimarilyInstitution' value
                                                                if (isOther) {
                                                                    setOtherPrimarily('');
                                                                }
                                                            }}
                                                            renderValue={(selected) => {
                                                                const selectedOption = PrimarilyTeaching.find((option) => option.id === selected);
                                                                return selectedOption ? selectedOption.label : 'Select Primarily Teaching/Taught';
                                                            }}
                                                            error={!!fieldState.error}
                                                        >
                                                            {PrimarilyTeaching.map((option) => (
                                                                <MenuItem key={option.id} value={option.id}>
                                                                    <FormControlLabel
                                                                        value={option.id}
                                                                        control={<Radio checked={field.value === option.id} />}
                                                                        label={option.label}
                                                                    />
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                        {fieldState?.error && !showPrimarilyOtherInput && (
                                                            <FormHelperText error>
                                                                {fieldState.error.message}
                                                            </FormHelperText>
                                                        )}
                                                    </FormControl>

                                                    {/* Conditionally render input for "Other" option */}
                                                    {showPrimarilyOtherInput && (
                                                        <Controller
                                                            name="otherLevelOfInstitution"
                                                            control={control}
                                                            rules={{
                                                                required: showPrimarilyOtherInput ? 'Please Specify The Other Level Of Institution' : false,
                                                            }}
                                                            render={({ field: otherField, fieldState: otherFieldState }) => (
                                                                <>
                                                                    <TextField
                                                                        fullWidth
                                                                        label={<Typography className="text-dark">Please specify</Typography>}
                                                                        placeholder="Specify other level of institution"
                                                                        variant="outlined"
                                                                        margin="normal"
                                                                        value={otherPrimarily}
                                                                        onChange={(e) => {
                                                                            otherField.onChange(e.target.value);
                                                                            setOtherPrimarily(e.target.value);
                                                                        }}
                                                                        error={!!otherFieldState.error}
                                                                        helperText={otherFieldState.error?.message}
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                    )}
                                                </>
                                            )} />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                InputLabelProps={{ shrink: true }}
                                                fullWidth
                                                label={
                                                    <Typography className="text-dark">
                                                        How many teachers are there in your institution approximately?<span style={{ color: "red" }}>*</span>
                                                    </Typography>
                                                }
                                                {...register("teachersInInstitution", {
                                                    required: "Please Enter Number Of Teachers In Your Institution",
                                                    min: { value: 1, message: 'No. of teachers must be greater than 0' }
                                                })}
                                                error={!!errors?.teachersInInstitution}
                                                helperText={errors?.teachersInInstitution?.message}
                                                placeholder="Your Teachers In Institution"
                                            />
                                        </Grid>
                                        <Grid item xs={4} className="mt-4">
                                            <TextField
                                                InputLabelProps={{ shrink: true }}
                                                fullWidth
                                                label={
                                                    <Typography className="text-dark">
                                                        How many students are there in your institution approximately?<span style={{ color: "red" }}>*</span>
                                                    </Typography>
                                                }
                                                {...register("studentsInInstitution", {
                                                    required: "Please Enter Number Of Students In Your Institution ",
                                                    min: { value: 1, message: 'No. of students must be greater than 0' }
                                                })}
                                                error={!!errors?.studentsInInstitution}
                                                helperText={errors?.studentsInInstitution?.message}
                                                placeholder="How many students are there in your institution approximately?"
                                            />
                                        </Grid>

                                        <Grid item xs={4}>
                                            <div>
                                                <label htmlFor="file-upload" className="form-label mt-0">Upload the logo of your Institution<span style={{ color: "red" }}>*</span></label>
                                                <input
                                                    className={`form-control ${errors.uploadInstitutionLogo ? 'is-invalid' : ''}`} // Apply Bootstrap styles
                                                    accept="image/*"
                                                    id="file-upload"
                                                    type="file"
                                                    {...register("uploadInstitutionLogo", {
                                                        validate: validateFile, // Custom validation function
                                                    })}
                                                />
                                                <div><p className="text-secondary-new fs-6">(Your logo will be displayed on
                                                    our website along with your
                                                    institution’s name. Please upload a
                                                    clear JPG/JPEG/PNG only)</p></div>
                                                {errors.uploadInstitutionLogo && <div className="invalid-feedback">{errors.uploadInstitutionLogo.message}</div>} {/* Error message display */}
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Box>
                        )}
                        {activeStep === 1 && (
                            <div className="row p-4">
                                <div className="col-md-4 mb-3">
                                    <FormControl fullWidth error={!!errors.dummyPlanId}>
                                        <InputLabel>Membership Plan <span style={{ color: "red" }}>*</span></InputLabel>
                                        <Controller
                                            name="dummyPlanId"
                                            control={control}
                                            rules={{ required: "Please select a membership plan" }}
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    label="Membership Type"
                                                    onChange={(e) => {
                                                        field.onChange(e);
                                                        setValue("dummyPlanTypeId", ""); // Reset the plan type if a new plan is selected
                                                    }}>
                                                    <MenuItem >
                                                        <em>Select a membership plan</em>
                                                    </MenuItem>
                                                    {planName.map((data) => (
                                                        <MenuItem key={data?._id} value={data?._id}>
                                                            {data?.planName}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            )}
                                        />
                                        {errors?.dummyPlanId && (
                                            <FormHelperText>{errors?.dummyPlanId?.message}</FormHelperText>
                                        )}
                                    </FormControl>
                                </div>
                                <div className="col-md-4">
                                    <FormControl className="mt-0"
                                        fullWidth
                                        error={!!errors.dummyPlanTypeId}
                                        disabled={!selectedPlanName}
                                        style={{ marginTop: 16 }}
                                    >
                                        <InputLabel>Membership Plan Type <span style={{ color: "red" }}>*</span></InputLabel>
                                        <Controller
                                            name="dummyPlanTypeId"
                                            control={control}
                                            rules={{
                                                required: selectedPlanName ? "Please select a membership plan type" : "Please select a membership plan",
                                            }}
                                            render={({ field }) => (
                                                <Select {...field} label="Membership Plan">
                                                    <MenuItem >
                                                        <em>Select a membership plan type</em>
                                                    </MenuItem>
                                                    {selectedPlanName && costType.map((plan) => (
                                                        <MenuItem key={plan._id} value={plan._id}>
                                                            {plan.planTypeName} - ₹{plan.amount}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            )}
                                        />
                                        {errors.dummyPlanTypeId && (
                                            <FormHelperText>{errors.dummyPlanTypeId.message}</FormHelperText>
                                        )}
                                    </FormControl>
                                </div>
                                <div className="col-md-4">
                                    <Controller
                                        control={control}
                                        rules={{ required: "Please Select a chapter" }}
                                        name="chapter"
                                        render={({ field, fieldState }) => (
                                            <FormControl fullWidth>
                                                <InputLabel id="event">
                                                    Which chapter Would You Like To Join{" "}
                                                    <span style={{ color: "red" }}>*</span>
                                                </InputLabel>
                                                <Select
                                                    style={{ backgroundColor: "#f0f0f0" }}
                                                    labelId="event"
                                                    id="event"
                                                    label={
                                                        <Typography className="text-dark">
                                                            Chapter<span style={{ color: "red" }}>*</span>
                                                        </Typography>
                                                    }
                                                    value={field.value || ""}
                                                    variant="outlined"
                                                    onChange={(e) => field.onChange(e.target.value)}
                                                    error={!!fieldState.error}
                                                >
                                                    {selectedChapters.map((data) => (
                                                        <MenuItem key={data._id} value={data._id}>
                                                            {data.title}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        )}
                                    />
                                </div>
                                <div className="col-md-4">
                                    <Grid item xs={4}>
                                        <Controller
                                            control={control}
                                            name="sigGroups"
                                            rules={{
                                                validate: validateAtLeastOne,
                                            }}
                                            render={({ field, fieldState }) => (
                                                <Autocomplete
                                                    multiple
                                                    id="sig"
                                                    InputLabelProps={{ shrink: true }}
                                                    fullWidth
                                                    options={allSIG}
                                                    disableCloseOnSelect
                                                    getOptionLabel={(sig) => sig?.groupName}
                                                    filterSelectedOptions
                                                    getOptionSelected={(option, value) =>
                                                        option._id === value._id
                                                    }
                                                    onChange={(event, values) => {
                                                        const sigGroups = values.map(
                                                            (value) => value?._id
                                                        ); // Extract _id from selected options
                                                        field.onChange(sigGroups); // Pass array of selected _ids to onChange
                                                    }}
                                                    value={
                                                        field.value
                                                            ? field.value.map((id) =>
                                                                allSIG.find(
                                                                    (group) => group._id === id
                                                                )
                                                            )
                                                            : []
                                                    }
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            error={!!fieldState?.error}
                                                            helperText={fieldState?.error?.message}
                                                            label={
                                                                <Typography className="text-dark">
                                                                    SIG Groups{" "}
                                                                    <span style={{ color: "red" }}>
                                                                        *
                                                                    </span>
                                                                </Typography>
                                                            }
                                                            placeholder="Select SIG"
                                                        />
                                                    )}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </div>
                                <div className="col-md-4">
                                    <FormControl component="fieldset" error={!!errors?.emailMessage}>
                                        <FormLabel component="legend">Would You Like To Receive Update From  By Email</FormLabel>
                                        <Controller
                                            name="emailMessage"
                                            control={control}
                                            defaultValue=""
                                            rules={{ required: "Please select a option" }}
                                            render={({ field }) => (
                                                <RadioGroup row {...field}>
                                                    <FormControlLabel
                                                        value="1"
                                                        control={<Radio />}
                                                        label="Yes"
                                                    />
                                                    <FormControlLabel
                                                        value="0"
                                                        control={<Radio />}
                                                        label="No"
                                                    />
                                                </RadioGroup>
                                            )}
                                        />
                                        {errors?.emailMessage && (
                                            <FormHelperText>{errors?.emailMessage?.message}</FormHelperText>
                                        )}
                                    </FormControl>
                                </div>
                                <div className="col-md-4">
                                    <div className="card ">
                                        <p className="fw-bold text-center p-3"> You have to Pay {currency === 0 ? "₹" : "$"}{amount}</p>
                                    </div>
                                </div>
                            </div>
                        )}
                        <Box sx={{ display: "flex", flexDirection: "row", pt: 2, mt: 3 }}>
                            {activeStep !== 0 && (
                                <Button
                                    variant="contained"
                                    className="btn-color"
                                    onClick={handleBack}
                                    sx={{ mr: 1 }}
                                >
                                    Back
                                </Button>
                            )}
                            <Box sx={{ flex: "1 1 auto" }} />
                            <Button type="submit" variant="contained" className="btn-color">
                                {activeStep === steps.length - 1
                                    ? "Submit"
                                    : "Next"}
                            </Button>
                        </Box>
                    </form>
                </Box>
            )}
        </>
    );
}

export default AddEditInstitution;
