import { api } from "./api";

const eventServices = api.injectEndpoints({
    endpoints: (builder) => ({
        //createEvent
        createEvent: builder.mutation({
            query: (newEventData) => ({
                url: "event/createEvent",
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: newEventData,
            }),
            invalidatesTags: ['Events']
        }),
       
        //updateEvent
        UpdateEvent: builder.mutation({
            query: (data) => (
                {
                    url: "event/update/" + data.id,
                    method: "PUT",
                    headers: { "Content-Type": "application/json" },
                    body: data
                })
        }),

        //Get All Events
        getAllEventsPagination: builder.mutation({
            query: (AllEventData) => ({
                url: "event/getAllEventPagination",
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: AllEventData,
            })
        }),

        //getApproveRejectEvent 
        getApproveRejectEvent: builder.mutation({
            query: (AllEventData) => ({
                url: "event/approveRejectEvent",
                method: "PUT",
                headers: { "Content-Type": "application/json" },
                body: AllEventData,
            })
        }),
        
        //Get All Events
        getPendingEventList: builder.mutation({
            query: (AllEventData) => ({
                url: "event/getPendingEventList",
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: AllEventData,
            })
        }),

        //Get EVents By Id 
        getEventDetailsById: builder.query({
            query: (id) => "event/get/" + id,
        }),

        //get All Events
        getAllEvents: builder.query({
            query: () => ({
                url: "event/getAll",
            }),
            providesTags: ['Events']
        }),
          
        //getEventById
        getEventById: builder.query({
            query: (id) => ({
                url: "event/get/" + id,
            }),
            providesTags: ['Events']
        }),

        //deleteEventById
        deleteEvent: builder.mutation({
            query: (id) => ({
                url: "event/delete/" + id,
                method: "PUT",
                headers: { "Content-Type": "application/json" },
            }),
            invalidatesTags: ['Events']
        }),

        //joinevent
        joinEvent: builder.mutation({
            query: (data) => ({
                url: "event/registerEvent",
                method: "PUT",
                headers: { "Content-Type": "application/json" },
                body: data
            }),
            // invalidatesTags:['Events']
        }),
    }),
    overrideExisting: false,
});

export const {
    useGetAllEventsQuery,
    useLazyGetAllEventsQuery,
    useCreateEventMutation,
    useGetAllEventsPaginationMutation,
    useJoinEventMutation,
    useGetEventByIdQuery,
    useLazyGetEventByIdQuery,
    useDeleteEventMutation,
    useGetEventDetailsByIdQuery,
    useUpdateEventMutation,
    useGetPendingEventListMutation,
    useGetApproveRejectEventMutation,
} = eventServices;