import { createSlice } from "@reduxjs/toolkit";
const authSlice=createSlice({
    name:'auth',
    initialState:{
        user:null,
        accessToken:null,
        refreshToken:null,
        scope:null,
        memberId:null,
        roleId:null,
        imageUrl:null
    },
    reducers:{
        setCredentials:(state,action)=>{
            const {user,accessToken,refreshToken,scope,id,sigId,chapterId,chapterRoleId,sigRoleId,imageUrl } = action.payload;
            state.user=user;
            state.accessToken=accessToken;
            state.refreshToken=refreshToken;
            state.scope=scope;
            state.memberId=id;
            state.sigId=sigId;
            state.chapterId=chapterId;
            state.roleId= (chapterRoleId) ? chapterRoleId : sigRoleId;
            state.imageUrl=imageUrl;
        },
        setImageUrl:(state,action)=>{
            const {imageUrl} = action.payload;
            state.imageUrl=imageUrl;
        },
        logOut:(state,action)=>{
            localStorage.removeItem('persist:root');
            localStorage.clear()
           state.user=null;
           state.accessToken=null;
           state.refreshToken=null;
           state.scope=null;
           state.memberId=null;
           state.roleId=null;
           state.imageUrl=null;
        }
    }
    
})

export default authSlice.reducer
export const {setCredentials,logOut,setImageUrl}=authSlice.actions
export const selectCurrentUser=(state)=>state.auth.user
export const selectUserId=state=>state.auth.memberId
export const selectCurrentScope=(state)=>state.auth.scope
export const selectCurrentAccessToken=(state)=>state.auth.accessToken
export const selectCurrentRefreshToken=(state)=>state.auth.refreshToken
export const selectCurrentRoleId=(state=> state.auth.roleId)
export const selectImageUrl=(state)=>state.auth.imageUrl

