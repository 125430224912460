import { api } from "./api";

const journalServices = api.injectEndpoints({
  tagTypes: ['journal'],
  endpoints: (builder) => ({
    //save Journal
    saveJournal: builder.mutation({
      query: (newJournalData) => ({
        url: "news/saveNews",
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: newJournalData,
      })
    }),

    //getAll journal
    getAllJournal: builder.mutation({
      query: (journalData) => ({
        url: "news/getall",
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: journalData,
      }),
      invalidatesTags: ["journal"],
    }),

    //getJournalById
    getJournalById: builder.query({
      query: (_id) => `news/get/${_id}`,
      providesTags: (_id) => [{ type: "Post", _id }],
    }),

    //pdateJournal
    updateJournal: builder.mutation({
      query: ({ id, data }) => ({
        url: `news/update/${id}`,
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: data,
      }),
    }),

    //GetApproeRejectNews
    getApproveRejectNews: builder.mutation({
      query: (data) => ({
        url: "news/approveRejectNews",
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: data,
      }),
    }),

    //getPendingNewsList
    getPendingNewsList: builder.mutation({
      query: (data) => ({
        url: "news/getNewsPendingList",
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: data,
      }),
    }),

    //delete journal
    deleteJournal: builder.mutation({
      query: (id) => ({
        url: "news/delete/"+ id,
        method: "PUT",
        headers: { "Content-Type": "application/json" },
      }),
    }),

    //pagination news
    paginationNews: builder.mutation({
      query: (data) => ({
        url: "news/getAllNewsPagination",
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: data,
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useSaveJournalMutation,
  useGetAllJournalMutation,
  useLazyGetJournalByIdQuery,
  useUpdateJournalMutation,
  useDeleteJournalMutation,
  useGetPendingNewsListMutation,
  useGetApproveRejectNewsMutation,
  usePaginationNewsMutation
} = journalServices;