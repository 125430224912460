import React, { useState, useEffect } from 'react'
import { Box, Container, Avatar, Button } from '@mui/material'
import CreateIcon from '@mui/icons-material/Create';
import { useLazyGetMemberDetailsByIdQuery } from '../../../app/services/memberServices';
import { useParams } from 'react-router-dom';
import { useLazyGetMemberShipDetailsByIdQuery } from '../../../app/services/memberShipServices'
import { useLazyGetChapterDetailsByIdQuery } from '../../../app/services/chapterServices'
import { useLazyGetSIGGroupByIdQuery } from '../../../app/services/SIGGroupServices'
import { TypeOfInstitution, PrimarilyTeaching } from '../../../constant/constant'
import { useNavigate } from "react-router-dom";
import { selectUserId } from "../../../app/auth/authSlice";
import { useSelector } from "react-redux";
import { useUpdateMemberMutation, useLazyGetPendingMemberByIdQuery, } from '../../../app/services/memberListServices'
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';


const ViewInstitution = () => {
    const navigate = useNavigate();
    const userId = useSelector(selectUserId);

    //handleProfilePic()
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [getMemberById] = useLazyGetMemberDetailsByIdQuery()
    const { id } = useParams();
    const [membershipbyId] = useLazyGetMemberShipDetailsByIdQuery()
    const [memberDetails, setMemberDetails] = useState()
    const [PriceDetails, setPriceDetails] = useState([])
    const [pricetype, setpriceType] = useState()
    const [chapter, setChapter] = useState()
    const [getChapterById] = useLazyGetChapterDetailsByIdQuery()
    const [getSIGById] = useLazyGetSIGGroupByIdQuery();
    const [sig, setSig] = useState()
    const [imageUpdate, setImageUpdate] = useState()
    const [selectedImage, setSelectedImage] = useState(null);
    const [imageKey, setImageKey] = useState(0);
    const [showUploadButton, setShowUploadButton] = useState(false);
    const [getPendingMemberById] = useLazyGetPendingMemberByIdQuery()
    const dispatch = useDispatch();
    const [updateMember] = useUpdateMemberMutation()

    useEffect(() => {
        getMemberById(id).then((res) => {
            if (res?.data.status) {
                setMemberDetails(res?.data.data)
                setPriceDetails(res?.data?.data?.plans)
            }
        }).catch((err) => console.error(err))
    }, [getMemberById, id])

    useEffect(() => {
        if (PriceDetails[0]?.planId) {
            membershipbyId(PriceDetails[0]?.planId).then((res) => {
                setpriceType(res?.data?.data);
            });
        }
        getChapterById(memberDetails?.chapter).then((res) => {
            if (res?.data?.status) {
                setChapter(res?.data?.data?.title)
            }
        }).catch(err => console.log(err))
        if (memberDetails?.sigGroups) {
            let groupPromises = memberDetails.sigGroups.map((id) =>
                getSIGById(id).then((res) => res?.data?.data?.groupName)
            );

            Promise.all(groupPromises).then((groupNames) => {
                setSig(groupNames);
            });
        }

    }, [memberDetails, PriceDetails, getSIGById, getChapterById, membershipbyId]);

    useEffect(() => {
        if (pricetype && chapter && sig) {
        }

    }, [pricetype, sig, chapter]);
    
    const formatDate = (isoDate) => {
        const date = new Date(isoDate);

        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const year = date.getFullYear();

        return `${day}-${month}-${year}`;
    };

    const getTypeOfInstitution = (id) => {
        const institution = TypeOfInstitution.find((data) => data.id === id)
        return institution ? institution.type : '-'
    }
    const getLevelOfWork = (id) => {
        const levelOfWork = PrimarilyTeaching.find(data => data.id === id)
        return levelOfWork ? levelOfWork.label : ""
    }

    // Update width on window resize
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        // Clean up the event listener
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleFormNavigation = (key) => {
        navigate(`/member/educationalprofile/${key}/${userId}`);
    };


    const handleProfilePic = (event) => {
        const file = event.target.files[0];
        if (!file) {
            return;
        }
        setImageUpdate(file);  // Set the file in state
        const reader = new FileReader();
        reader.onloadend = () => {
            setSelectedImage(reader.result);  // Update the image preview
            setImageKey(prevKey => prevKey + 1);  // Re-render the image with a new key
            setShowUploadButton(true);  // Show the upload button after file selection
        };
        reader.readAsDataURL(file);  // Read the file for preview
    };


    const handleUpload = () => {
        const formData = new FormData();
        formData.append("uploadInstitutionLogo", imageUpdate);
        formData.append("_id", memberDetails._id);

        updateMember({ id: memberDetails._id, data: formData })
            .then((res) => {
                console.log(res);
                if(res?.data?.status){
                    setShowUploadButton(false)
                    toast.success(res?.data?.message);
                  }else{
                    setShowUploadButton(false)
                    toast.error(res?.data?.message)
                  }
                if (res.data.status) {
                    return getPendingMemberById(memberDetails?._id);
                } else {
                    throw new Error("Image upload failed");
                }
            })
            .then((memberRes) => {
                dispatch(setMemberDetails(memberRes?.data?.data));
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const handleIconClick = () => {
        document.getElementById("uploadImage").click();
    };
    return (
        <div className='ourExecutiveCommittee-containter'>
            <Box>
                <div className='pt-1 '>
                    <div className='p-0'>
                        <div className=' Architects-header d-flex align-items-center justify-content-center '>
                            <h2 className='signika-fontOEC text-center '>View Institution</h2>
                        </div>
                    </div>
                </div>
            </Box>
            <Container maxWidth="lg" className='pb-2'>
                <p className='signika-font fw-semibold teacher-member-header mt-5 mb-0'>BASIC DETAILS:</p>
                <div className='card teacher-member-card'>
                    <div className='row'>
                        <div className='col-md-2 m-3 d-flex justify-content-lg-start justify-content-center'>
                            <div className='teacher-avatar-containter'>
                                {!showUploadButton && (
                                    <div className='techer-member-image-editIcon' onClick={handleIconClick}>
                                        <CreateIcon sx={{ fontSize: '10px' }} />
                                    </div>
                                )}

                                <input
                                    accept="image/jpeg, image/png"
                                    style={{ display: "none" }}
                                    id="uploadImage"
                                    type="file"
                                    onChange={handleProfilePic}  // Handle file selection
                                />

                                {selectedImage ? (
                                    <Avatar
                                        key={imageKey}
                                        alt="Member Logo"
                                        className="member-avatar"
                                        src={selectedImage}
                                        sx={{ width: 100, height: 100 }}
                                        onClick={handleIconClick}
                                    />
                                ) : (
                                    <Avatar
                                        key={imageKey}
                                        alt="Member Logo"
                                        className="member-avatar"
                                        onClick={handleIconClick}
                                        src={memberDetails?.uploadInstitutionLogo}
                                        sx={{ width: 100, height: 100 }}
                                    />
                                )}

                                {showUploadButton && (
                                    <Button
                                        variant="contained"
                                        style={{ marginTop: "20px" }}
                                        onClick={handleUpload}
                                          className='menus-color'
                                    >
                                        Upload
                                    </Button>
                                )}
                            </div>
                        </div>
                        <div className='col-md-8  mt-3 text-center text-md-start'>
                            <p className='signika-font fw-semibold mt-4'>{memberDetails?.institution
                            }</p>
                               <p className='signika-font'>{memberDetails?.userId}</p>
                            <p className='signika-font'>{formatDate(memberDetails?.institutionEstablished)}</p>
                            <p className='signika-font'>{memberDetails?.websiteAddressInstitution}</p>
                        </div>
                    </div>
                </div>
                <p className='signika-font fw-semibold mt-5 mb-0'>CONTACT DETAILS:</p>
                <div className='card teacher-member-card '>
                    <div className='row'>
                        <div className='d-flex justify-content-end'>
                            <Button variant='outlined' className='m-2 edit-button-profile-membership' onClick={() => handleFormNavigation("contact")}>Edit  <CreateIcon sx={{ fontSize: '10px' }} /></Button>
                        </div>
                        <Container maxWidth="lg">
                            <div className='row p-3'>
                                <div className='col-lg-5'>
                                    <div className='row'>
                                        <div className='col-4'>
                                            <p className='signika-font fw-semibold'>Address</p>
                                            <p className='signika-font fw-semibold mt-4'>City / Town</p>
                                            <p className='signika-font fw-semibold mt-4'>Pin Code</p>
                                            <p className='signika-font fw-semibold mt-4'>State</p>
                                            <p className='signika-font fw-semibold mt-4'>Country</p>
                                        </div>
                                        <div className='col-8'>
                                            <p className='signika-font'><span className='me-3' style={{ whiteSpace: "nowrap" }}>:</span>{memberDetails?.address}</p>
                                            <p className='signika-font mt-4'><span className='me-3'>:</span>{memberDetails?.city}</p>
                                            <p className='signika-font mt-4'><span className='me-3'>:</span>{memberDetails?.pinCode}</p>
                                            <p className='signika-font mt-4'><span className='me-3'>:</span>{memberDetails?.state}</p>
                                            <p className='signika-font mt-4'><span className='me-3'>:</span>{memberDetails?.country}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-7'>
                                    <div className='row'>
                                        <div className='col-4'>
                                            <p className='signika-font fw-semibold'>   {windowWidth > 513 ? (
                                                <p>Mobile Number</p>
                                            ) : (
                                                <p>Mobile</p>
                                            )}</p>
                                            <p className='signika-font fw-semibold mt-4'>Email Id</p>
                                            <p className='signika-font fw-semibold mt-4 '>
                                                {windowWidth > 513 ? (
                                                    <p>Telephone Number</p>
                                                ) : (
                                                    <p>Telephone</p>
                                                )}
                                            </p>
                                            {/* <p className='signika-font fw-semibold mt-4'>Contact Information Visibility</p> */}
                                        </div>
                                        <div className='col-8'>
                                            <p className='signika-font'><span className='me-3'>:</span>{memberDetails?.mobileNumber}</p>
                                            <p className='signika-font mt-4'><span className='me-3'>:</span>{memberDetails?.email}</p>
                                            <p className='signika-font mt-4'><span className='me-3'>:</span>{memberDetails?.landlineNumber ? memberDetails?.landlineNumber : '-'}</p>
                                            {/* <p className='signika-font mt-4'><span className='me-3'>:</span>
                                                Which contact information woulspand
                                                you like to share with other
                                                members? (Please select all that
                                                apply)
                                            </p> */}
                                            {/* <p className='signika-font  mt-4'>
                                                <div className='row'>
                                                    <div className='col-md-6 col-sm-12'>
                                                        <ulspanspan className='signika-font'>
                                                            <li>WhatsApp</li>
                                                        </ulspanspan>
                                                    </div>
                                                    <div className='col-md-6 col-sm-12'>
                                                        <ulspanspan className='signika-font'>
                                                            <li>Mobile Num</li>
                                                        </ulspanspan>
                                                    </div>
                                                    <div className='col-md-6 col-sm-12'>
                                                        <ulspanspan className='signika-font'>
                                                            <li>Lindline</li>
                                                        </ulspanspan>
                                                    </div>
                                                </div>
                                            </p> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Container>
                    </div>
                </div>
                {/* <p className='signika-font fw-semibold mt-5 mb-0'>YOUR ACADEMIC PROFILE LINKS:</p>
                <div className='card teacher-member-card '>
                    <div className='row'>
                        <div className='d-flex justify-content-end'>
                            <Button variant='outlined' className='m-2'>Edit  <CreateIcon sx={{ fontSize: '10px' }} /></Button>
                        </div>
                        <Container maxWidth="lg">
                            <div className='row p-3'>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <p className='signika-font fw-semibold'>Your Google Scholar Profile</p>
                                    </div>
                                    <div className='col-sm-8'>
                                        <p className='signika-font'><span className='me-3'>:</span>www.benhive.com</p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <p className='signika-font fw-semibold'>Your Scopus Profile link</p>
                                    </div>
                                    <div className='col-sm-8'>
                                        <p className='signika-font'><span className='me-3'>:</span>www.benhive.com</p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <p className='signika-font fw-semibold'>Your LinkedIn Profile</p>
                                    </div>
                                    <div className='col-sm-8'>
                                        <p className='signika-font'><span className='me-3'>:</span>www.benhive.com</p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <p className='signika-font fw-semibold'>Your ORICID id</p>
                                    </div>
                                    <div className='col-sm-8'>
                                        <p className='signika-font'><span className='me-3'>:</span>www.benhive.com</p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <p className='signika-font fw-semibold'>Your blog / website</p>
                                    </div>
                                    <div className='col-sm-8'>
                                        <p className='signika-font'><span className='me-3'>:</span>www.benhive.com</p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <p className='signika-font fw-semibold'>Your Youtube channel</p>
                                    </div>
                                    <div className='col-sm-8'>
                                        <p className='signika-font'><span className='me-3'>:</span>www.benhive.com</p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <p className='signika-font fw-semibold'>Consent to Share Information</p>
                                    </div>
                                    <div className='col-sm-8'>
                                        <p className='signika-font'><span className='me-3'>:</span>
                                            Do you
                                            consent to having your academic profile
                                            shared with other ELTAI members so
                                            they can search, find, and contact you for
                                            delivering talks, writing collaborative
                                            research papers, and other professional
                                            opportunities?
                                        </p>
                                        <ulspan>
                                            <li>yes</li>
                                        </ulspan>
                                    </div>
                                </div>
                            </div>
                        </Container>
                    </div>
                </div> */}
                <p className='signika-font fw-semibold teacher-member-header  mt-5 mb-0'>Membership Details:</p>
                <div className='card teacher-member-card '>
                    <div className='row p-3'>
                        <Container maxWidth="lg">
                            <div className='row p-3'>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <p className='signika-font fw-semibold'>Date of first becoming a member</p>
                                    </div>
                                    <div className='col-sm-8'>
                                        <p className='signika-font'><span className='me-3'>:</span>{formatDate(PriceDetails[0]?.dummyStartDate)}</p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <p className='signika-font fw-semibold'>Type of Current Membership</p>
                                    </div>
                                    <div className='col-sm-8'>
                                        <p className='signika-font'><span className='me-3'>:</span>{pricetype?.planName}</p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <p className='signika-font fw-semibold'>Current Membership Valid from</p>
                                    </div>
                                    <div className='col-sm-4'>
                                        <p className='signika-font'><span className='me-3'>:</span>{formatDate(PriceDetails[0]?.dummyStartDate)}</p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <p className='signika-font fw-semibold'>Current Membership Valid till</p>
                                    </div>
                                    <div className='col-sm-8'>
                                        <p className='signika-font'><span className='me-3'>:</span>{formatDate(PriceDetails[0]?.dummyEndDate)}</p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <p className='signika-font fw-semibold'>Payment Detail</p>
                                    </div>
                                    <div className='col-sm-8 m-0'>
                                        <p className='signika-font mb-0'><span className='me-3'>:</span>Paid
                                            <uispan className='me-3'>
                                                <li>{PriceDetails[0]?.dummyAmount ? "Online Payment" : "Cash"}</li>
                                                <li>{formatDate(PriceDetails[0]?.dummyStartDate)}</li>
                                                <li>{PriceDetails[0]?.dummyCurrency === 1 ? "$" : "₹"}{PriceDetails[0]?.dummyAmount}</li>
                                            </uispan>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Container>
                    </div>
                </div>
                <p className='signika-font fw-semibold teacher-member-header mt-5 mb-0'>Your Network Details:</p>
                <div className='card teacher-member-card '>
                    <div className='row'>
                        {/* <div className='d-flex justify-content-end'>
                            <Button variant='outlined' className='m-2'>Edit  <CreateIcon sx={{ fontSize: '10px' }} /></Button>
                        </div> */}
                        <Container maxWidth="lg">
                            <div className='row p-3'>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <p className='signika-font fw-semibold mb-0'>Which chapter of ELTAI woulspand
                                            you like to be part of?</p>
                                        <a style={{ color: 'darkgreen' }} target="_blank" rel="noopener noreferrer" href="/member/view-Chapter">Click here for Chapter</a>
                                    </div>
                                    <div className='col-sm-8'>
                                        <p className='signika-font d-flex'><span className='me-3'>:</span>   {chapter ? chapter : '-'}   </p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <p className='signika-font fw-semibold mb-0'>Which SIGs of ELTAI woulspand
                                            you like to join?</p>
                                        <a style={{ color: 'darkgreen' }} target="_blank" rel="noopener noreferrer" href="/member/addEditSigGroups">Click here for Chapter</a>
                                    </div>
                                    <div className='col-sm-8'>
                                        <p className='signika-font d-flex'><span className='me-3'>:</span>
                                            {sig && sig.length > 0 ? (sig && sig?.map((data, i) => {
                                                return (<div key={i}>
                                                    <li >{data}</li>
                                                </div>)
                                            })) : '-'}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Container>
                    </div>
                </div>
                {/* <p className='signika-font fw-semibold  teacher-member-header mt-5 mb-0'>Educational Details:</p>
                <div className='card teacher-member-card '>
                    <div className='row'>
                        <div className='d-flex justify-content-end'>
                            <Button variant='outlined' className='m-2'>Edit  <CreateIcon sx={{ fontSize: '10px' }} /></Button>
                        </div>
                        <Container maxWidth="lg">
                            <div className='row p-3'>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <p className='signika-font fw-semibold'>What are you studying at
                                            present?</p>
                                    </div>
                                    <div className='col-sm-8'>
                                        <p className='signika-font mb-1'><span className='me-3'>:</span>(Select all that apply)
                                            <div className='row'>
                                                <div className='col-md-2 col-sm-12'>
                                                    <ulspan className='signika-font'>
                                                        <li>M.A</li>
                                                    </ulspan>
                                                </div>
                                                <div className='col-md-2 col-sm-12'>
                                                    <ulspan className='signika-font'>
                                                        <li>M.Ed</li>
                                                    </ulspan>
                                                </div>
                                                <div className='col-md-2 col-sm-12'>
                                                    <ulspan className='signika-font'>
                                                        <li>B.A</li>
                                                    </ulspan>
                                                </div>
                                            </div>
                                        </p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <p className='signika-font fw-semibold'>Have you got through any
                                            teacher eligibility examinations?</p>
                                    </div>
                                    <div className='col-sm-8'>
                                        <p className='signika-font'><span className='me-3'>:</span>(Select all that apply)
                                            <div className='row'>
                                                <div className='col-md-2 col-sm-12'>
                                                    <ulspan className='signika-font'>
                                                        <li>SET</li>
                                                    </ulspan>
                                                </div>
                                                <div className='col-md-2 col-sm-12'>
                                                    <ulspan className='signika-font'>
                                                        <li>NET</li>
                                                    </ulspan>
                                                </div>
                                                <div className='col-md-2 col-sm-12'>
                                                    <ulspan className='signika-font'>
                                                        <li>JRF</li>
                                                    </ulspan>
                                                </div>
                                            </div>
                                        </p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <p className='signika-font fw-semibold'>Name of your Institution</p>
                                    </div>
                                    <div className='col-sm-8'>
                                        <p className='signika-font'><span className='me-3'>:</span>Hinustan public school</p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <p className='signika-font fw-semibold'>Address of your Institution</p>
                                    </div>
                                    <div className='col-sm-8'>
                                        <p className='signika-font ml-3'><span className='me-3'>:</span>
                                            Hinudstan University,<br />
                                            padur<br />
                                            Chennai<br />
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Container>
                    </div>
                </div> */}
                {/* <p className='signika-font fw-semibold teacher-member-header mt-5 mb-0'>Interest in your Educational Development</p>
                <div className='card teacher-member-card mb-3 '>
                    <div className='row'>
                        <div className='d-flex justify-content-end'>
                            <Button variant='outlined' className='m-2'>Edit  <CreateIcon sx={{ fontSize: '10px' }} /></Button>
                        </div>
                        <Container maxWidth="lg">
                            <div className='row p-3'>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <p className='signika-font fw-semibold'>Are you a member of any other
                                            Teachers’ Association?</p>
                                    </div>
                                    <div className='col-sm-8'>
                                        <p className='signika-font mb-1'><span className='me-3'>:</span>Yes</p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <p className='signika-font fw-semibold'>If yes, mention the name of those
                                            associations</p>
                                    </div>
                                    <div className='col-sm-8'>
                                        <p className='signika-font'><span className='me-3'>:</span>-
                                        </p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <p className='signika-font fw-semibold'>Which of the following woulspand
                                            you like to do for your
                                            continuous professional
                                            development?</p>
                                    </div>
                                    <div className='col-sm-8'>
                                        <p className='signika-font'><span className='me-3'>:</span>(Select all that apply)</p>
                                        <div className='row'>
                                            <div className='col-md-4 col-sm-12'>
                                                <ulspan className='signika-font'>
                                                    <li>Attending webinars</li>
                                                </ulspan>
                                            </div>
                                            <div className='col-md-4 col-sm-12'>
                                                <ulspan className='signika-font'>
                                                    <li>Taking part in online discussions</li>
                                                </ulspan>
                                            </div>
                                            <div className='col-md-4 col-sm-12'>
                                                <ulspan className='signika-font'>
                                                    <li>Enrolling in advanced degree programs</li>
                                                </ulspan>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <p className='signika-font fw-semibold'>Your areas of expertise</p>
                                    </div>
                                    <div className='col-sm-8'>
                                        <p className='signika-font'><span className='me-3'>:</span>
                                            (Select all the areas in which you
                                            teach courses, publish research
                                            papers, and deliver talks)
                                        </p>
                                        <p className='signika-font  fw-bold'>Literary Studies</p>
                                        <div className='row'>
                                            <div className='col-md-4 col-sm-12'>
                                                <ulspan className='signika-font'>
                                                    <li>Attending webinars</li>
                                                </ulspan>
                                            </div>
                                            <div className='col-md-4 col-sm-12'>
                                                <ulspan className='signika-font'>
                                                    <li>Taking part in online discussions</li>
                                                </ulspan>
                                            </div>
                                            <div className='col-md-4 col-sm-12'>
                                                <ulspan className='signika-font'>
                                                    <li>Enrolling in advanced degree programs</li>
                                                </ulspan>
                                            </div>
                                        </div>
                                        <p className='fw-bold signika-font '>Linguistics</p>
                                        <div className='row'>
                                            <div className='col-md-4 col-sm-12'>
                                                <ulspan className='signika-font'>
                                                    <li>Attending webinars</li>
                                                </ulspan>
                                            </div>
                                            <div className='col-md-4 col-sm-12'>
                                                <ulspan className='signika-font'>
                                                    <li>Taking part in online discussions</li>
                                                </ulspan>
                                            </div>
                                            <div className='col-md-4 col-sm-12'>
                                                <ulspan className='signika-font'>
                                                    <li>Enrolling in advanced degree programs</li>
                                                </ulspan>
                                            </div>
                                        </div>
                                        <p className='fw-bold signika-font '>English Language Teaching (ELT)</p>
                                        <div className='row'>
                                            <div className='col-md-4 col-sm-12'>
                                                <ulspan className='signika-font'>
                                                    <li>Attending webinars</li>
                                                </ulspan>
                                            </div>
                                            <div className='col-md-4 col-sm-12'>
                                                <ulspan className='signika-font'>
                                                    <li>Taking part in online discussions</li>
                                                </ulspan>
                                            </div>
                                            <div className='col-md-4 col-sm-12'>
                                                <ulspan className='signika-font'>
                                                    <li>Enrolling in advanced degree programs</li>
                                                </ulspan>
                                            </div>
                                        </div>
                                        <p className='fw-bold signika-font '>Interdisciplinary Areas</p>
                                        <div className='row'>
                                            <div className='col-md-4 col-sm-12'>
                                                <ulspan className='signika-font'>
                                                    <li>Attending webinars</li>
                                                </ulspan>
                                            </div>
                                            <div className='col-md-4 col-sm-12'>
                                                <ulspan className='signika-font'>
                                                    <li>Taking part in online discussions</li>
                                                </ulspan>
                                            </div>
                                            <div className='col-md-4 col-sm-12'>
                                                <ulspan className='signika-font'>
                                                    <li>Enrolling in advanced degree programs</li>
                                                </ulspan>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <p className='signika-font fw-semibold'>Areas of Expertise</p>
                                    </div>
                                    <div className='col-sm-8'>
                                        <p className='signika-font'><span className='me-3'>:</span>
                                            Are you
                                            willing to have your areas of
                                            expertise or interest listed publicly
                                            so that other members can identify
                                            potential collaboration
                                            opportunities?
                                            <ulspan><li>yes</li></ulspan>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Container>
                    </div>
                </div> */}
                <p className='signika-font fw-semibold teacher-member-header mt-5 mb-0'>Institution Details </p>
                <div className='card teacher-member-card mb-3 '>
                    <div className='row'>
                        <div className='d-flex justify-content-end'>
                            <Button variant='outlined' className='m-2 edit-button-profile-membership' onClick={() => handleFormNavigation("institute")}>Edit  <CreateIcon sx={{ fontSize: '10px' }} /></Button>
                        </div>
                        <Container maxWidth="lg">
                            <div className='row p-3'>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <p className='signika-font fw-semibold'>Type of Institution </p>
                                    </div>
                                    <div className='col-sm-8'>
                                        <p className='signika-font mb-1'><span className='me-3'>:</span>{getTypeOfInstitution(memberDetails?.typeOfInstitution)}</p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <p className='signika-font fw-semibold'>Level of Institution </p>
                                    </div>
                                    <div className='col-sm-8'>
                                        <p className='signika-font'><span className='me-3'>:</span>{getLevelOfWork(memberDetails?.levelOfInstitution)}</p>

                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <p className='signika-font fw-semibold'>How many Teachers are there in your institution appoximately </p>
                                    </div>
                                    <div className='col-sm-8'>
                                        <p className='signika-font'><span className='me-3'>:</span>
                                            {memberDetails?.teachersInInstitution?memberDetails?.teachersInInstitution:"-"}
                                        </p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <p className='signika-font fw-semibold'>How Many Students do you have Work for appoximately:</p>
                                    </div>
                                    <div className='col-sm-8'>
                                        <p className='signika-font'><span className='me-3'>:</span>
                                            {memberDetails?.studentsInInstitution ?memberDetails?.studentsInInstitution:'-'}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Container>
                    </div>
                </div>
            </Container>
            <p className='text-center signika-font fs-6 pb-3 mb-0'>
                <span className='fw-bold '>Withdrawal of Consent:</span>  Please note that you can withdraw your consent to share your information at any
                time by updating your privacy settings in your profile.
            </p>
        </div>
    )
}


export default ViewInstitution

