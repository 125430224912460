import React, { useState, useEffect, useCallback } from 'react'
import TopHeader from '../../../Component/Header/TopHeader';
import Box from '@mui/material/Box';
import { useNavigate } from "react-router-dom";
import "../../../style/css/job.css";
import { Button } from '@mui/material';
// import TurnedInNotIcon from '@mui/icons-material/TurnedInNot';
// import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { selectCurrentScope } from '../../../app/auth/authSlice';
import { useSelector } from 'react-redux';
import { useLazyGetAllJobQuery, useLazyGetJobDetailsByIdQuery } from '../../../app/services/jobService';
import { useLazyGetCategoryDetailsByIdQuery } from '../../../app/services/categoryService';


const ViewJob = () => {
  const navigate = useNavigate();//route
  const [getAllJob] = useLazyGetAllJobQuery();
  const [allJobData, setAllJobData] = useState([]);
  const [getJobId, setGetJobID] = useState([])
  const [getJobById] = useLazyGetJobDetailsByIdQuery();
  const [noId, setNoId] = useState(false)
  const [jobZeroValue, setJobZeroValue] = useState();
  const [getCategoryById] = useLazyGetCategoryDetailsByIdQuery();
  const [categoryName, setCategoryName] = useState();
  const [subCategoryName, setSubCategoryName] = useState();
  const memberScope = useSelector(selectCurrentScope)

  const fetchgetCategoryById = useCallback((categoryId, subCategoryId) => {
    getCategoryById(categoryId).then(res => {
      setCategoryName(res?.data?.data?.categoryName);
      res?.data?.data?.subCategory.forEach(value => {
        if (value?._id === subCategoryId) {
          setSubCategoryName(value?.subCategoryName);
        }
      });
    });
  }, [getCategoryById]);

  useEffect(() => {
    getAllJob().then(res => {
      setAllJobData(prevData => {
        if (!prevData.length) {
          // Only set job data if it's not already set to avoid infinite loop
          return res?.data?.data;
        }
        return prevData;
      });
      setJobZeroValue(res?.data?.data[0]);
    });
  }, [getAllJob]);

  useEffect(() => {
    if (jobZeroValue) {
      fetchgetCategoryById(jobZeroValue?.categoryId, jobZeroValue?.subCategoryId);
    }
  }, [jobZeroValue, fetchgetCategoryById]);

  const addJob = () => {
    navigate("/admin/addJob");// Navigate to add Job
  }

  const viewDetails = (id) => {
    getJobById(id).then(res => {
      setNoId(true)
      setGetJobID(res?.data?.data)
      fetchgetCategoryById(res?.data?.data?.categoryId, res?.data?.data?.subCategoryId)
    })
  }

  //Format Date
  const formatDate = (date) => {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    var correctDate = [day, month, year].join('-');
    return correctDate;
  }

  return (
    <div>
      <TopHeader />
      <Box className="banner_text"></Box>
      <div className='container-fluid job-page'>
        <div className='row'>
          <div className='col-lg-4 all-job'>
            {memberScope === 'PORTAL_SUPER_ADMIN' && (
              <Button variant="contained" className="mt-3 menus-color" style={{ display: 'flex', justifyContent: 'center' }}
                onClick={addJob}>Add Job</Button>
            )}
            {allJobData.map((data, index) => (
              <div className='card mt-5' key={index}>
                <div className='d-flex justify-content-center mt-3'>
                  <div>
                    <img src={data?.companyLogo} style={{ marginRight: '35px' }} width="80px" height='80px' alt="company logo"></img>
                  </div>
                  <div>
                    <b className='row d-flex justify-content-center'>{data?.title}</b>
                    <p className='text m-0 row d-flex justify-content-center'>{data?.contactCountry}</p>
                    <p className='text row d-flex justify-content-center'>{data?.contactCity}, {data?.contactCountry}.</p>
                  </div>
                </div>
                <p className='text row job-desc'>{data?.description}</p>
                <p className='text row exp mb-0'>Experiance Level:{data?.experience}</p>
                <p className='text package d-flex justify-content-center'>2LPA</p>
                <div className='d-flex justify-content-evenly mb-3'>
                  <button className='cbtn' onClick={() => viewDetails(data._id)}>Details</button>
                  <button className='cbtn'>Apply</button>
                </div>
              </div>
            ))}
          </div>

          {!noId ? (
            jobZeroValue && (
              <div className='col-lg-8'>
                <div className='card'>
                  <div className='d-flex justify-content-between'>
                    <div className='mt-3 ms-1'>
                      <img src={jobZeroValue?.companyLogo} width="120px" height='120px' alt="company logo" className='jpg-img row'></img><br />
                      <b className='text mb-0'>{jobZeroValue?.contactCompany}</b>
                      <p className='text text-center'>{jobZeroValue?.contactCity},{jobZeroValue?.contactCountry}</p>
                    </div>
                    <div className='time-zone'>
                      <p className='text mb-0'>{formatDate(jobZeroValue?.postDate)}</p>
                      <p className='text-uppercase text'>{jobZeroValue?.status} hiring</p>
                    </div>
                    <div className='btn-content'>
                      <p className='text package text-center'><span style={{ color: '#2D9BF0' }}><b>2LPA </b></span></p>
                      <div className='d-flex justify-content-evenly line'>
                        <button className='apply'>Apply</button>
                        {/* <TurnedInNotIcon className='save mt-2' />
                        <MoreHorizIcon className='dot mt-2' /> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row mt-4'>
                  <div className='col-lg-8'>
                    <div className='card company-card'>
                      <h2 className='ms-2'>Company Detials</h2>
                      <div className='ms-5'>
                        <b>{jobZeroValue.contactCompany}</b>
                        <p className='text mb-0'>{jobZeroValue.contactAddress}</p>
                        <p className='text p-0 mb-0'>Email :<a className="p-0 m-0" href="mailto:{jobZeroValue.contactEmail}"> {jobZeroValue.contactEmail}</a></p>
                        <p className='text mb-0'>Phone : {jobZeroValue.contactPhone}</p>
                        <p className='text mb-0'>Fax : {jobZeroValue.contactFax}</p>
                      </div>
                      <h2 className='ms-2'>Job Description</h2>
                      <div className='ms-5'>
                        <p className='text mb-0'>{jobZeroValue.description}</p>
                        <p className='text p-0 mb-0'>job url :<a className="p-0 m-0" href="{jobZeroValue.jobUrl}"> {jobZeroValue.jobUrl} </a></p>
                        <p className='text mb-0'>Position status on: {formatDate(getJobId.positionStartsOn)}</p>
                        <p className='text mb-0'>period: {jobZeroValue.period}</p>
                        <p className='text mb-0'>Duration: {jobZeroValue.duration}</p>
                        <p className='text mb-0'>Post expiration date :{formatDate(jobZeroValue.expirationDate)}</p>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-4'>
                    <div className='card requirements-card'>
                      <h2 className='ms-2'>Requirements:</h2>
                      <div className='ms-5'>
                        <p className='text mb-0'><b>Skills</b>:{jobZeroValue.skills}</p>
                        <p className='text mb-0'><b>Experience</b>: {jobZeroValue.experience}</p>
                        <p className='text mb-0'><b>Education</b>:{jobZeroValue.education}</p>
                        <p className='text mb-0'><b>Level </b>: {jobZeroValue.level}</p>
                        <p className='text mb-0'><b>Category</b>:{categoryName}</p>
                        <p className='text mb-0'><b>Sub Category</b>: {subCategoryName}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          ) : (
            <div className='col-lg-8'>
              <div className='card'>
                <div className='d-flex justify-content-between'>
                  <div className='mt-3 ms-1'>
                    <img src={getJobId.companyLogo} width="120px" height='120px' className='jpg-img row' alt="company logo"></img><br />
                    <b className='text mb-0'>{getJobId.contactCompany}</b>
                    <p className='text text-center'>{getJobId.contactCity},{getJobId.contactCountry}</p>
                  </div>
                  <div className='time-zone'>
                    <p className='text mb-0'>{formatDate(getJobId.postDate)}</p>
                    <p className='text-uppercase text'>{getJobId.status} hiring</p>
                  </div>
                  <div className='btn-content'>
                    <p className='text package text-center'><span style={{ color: '#2D9BF0' }}><b>2LPA </b></span></p>
                    <div className='d-flex justify-content-evenly line'>
                      <button className='apply'>Apply</button>
                      {/* <TurnedInNotIcon className='save mt-2' />
                      <MoreHorizIcon className='dot mt-2' /> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mt-4'>
                <div className='col-lg-8'>
                  <div className='card company-card'>
                    <h2 className='ms-2'>Company Detials</h2>
                    <div className='ms-5'>
                      <b>{getJobId.contactCompany}</b>
                      <p className='text mb-0'>{getJobId.contactAddress}</p>
                      <p className='text p-0 mb-0'>Email :<a className="p-0 m-0" href="mailto:{getJobId.contactEmail}"> {getJobId.contactEmail}</a></p>
                      <p className='text mb-0'>Phone : {getJobId.contactPhone}</p>
                      <p className='text mb-0'>Fax : {getJobId.contactFax}</p>
                    </div>
                    <h2 className='ms-2'>Job Description</h2>
                    <div className='ms-5'>
                      <p className='text mb-0'>{getJobId.description}</p>
                      <p className='text p-0 mb-0'>job url :<a className="p-0 m-0" href="{getJobId.jobUrl}"> {getJobId.jobUrl} </a></p>
                      <p className='text mb-0'>Position status on: {formatDate(getJobId.positionStartsOn)}</p>
                      <p className='text mb-0'>period: {getJobId.period}</p>
                      <p className='text mb-0'>Duration: {getJobId.duration}</p>
                      <p className='text mb-0'>Post expiration date :{formatDate(getJobId.expirationDate)}</p>
                    </div>
                  </div>
                </div>
                <div className='col-lg-4'>
                  <div className='card requirements-card'>
                    <h2 className='ms-2'>Requirements:</h2>
                    <div className='ms-5'>
                      <p className='text mb-0'><b>Skills</b>:{getJobId?.skills}</p>
                      <p className='text mb-0'><b>Experience</b>: {getJobId?.experience}</p>
                      <p className='text mb-0'><b>Education</b>:{getJobId?.education}</p>
                      <p className='text mb-0'><b>Level </b>: {getJobId?.level}</p>
                      <p className='text mb-0'><b>Category</b>:{categoryName}</p>
                      <p className='text mb-0'><b>Sub Category</b>: {subCategoryName}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="mt-3 mb-3 " >
        <Button variant="contained" className="menus-color" onClick={addJob}>Add Job</Button>
      </div>
    </div>
  )
}

export default ViewJob