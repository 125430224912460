import React from 'react'
import { Outlet } from 'react-router-dom'

const SigAdmin = () => {
    return (
      <>
      <Outlet></Outlet>
      </>
    )
}

export default SigAdmin