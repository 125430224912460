import TopHeader from "../../Component/Header/TopHeader";
import { useFieldArray, useForm } from "react-hook-form";
import React, { useCallback, useEffect, useState, useRef } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { FormHelperText, Grid, TextField } from "@mui/material";
import { Container } from "react-bootstrap";
import Button from "@mui/material/Button";
import { FormControlLabel, Checkbox } from "@mui/material";
import { useSaveChapterMutation } from "../../app/services/chapterServices";
import { useNavigate, useParams } from "react-router-dom";
import { useLazyGetChapterDetailsByIdQuery } from "../../app/services/chapterServices";
import { useUpdateChapterMutation } from "../../app/services/chapterServices";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
import Spinner from "../../Component/spinner/spinner";
import { Typography } from "@mui/material";
import { Controller } from 'react-hook-form';



const ChapterForm = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate(); //route
  const [initialData, setInitalData] = useState([]);
  const stateOption = [
    "Jammu & Kashmir",
    "Himachal Pradesh",
    "Punjab",
    "Uttarakhand",
    "Haryana",
    "Uttar Pradesh",
    "Rajasthan",
    "Bihar",
    "Sikkim",
    "West Bengal",
    "Assam",
    "Meghalaya",
    "Mizoram",
    "Tripura",
    "Manipur",
    "Arunanchal Pradesh",
    "Nagaland",
    "Jharkhand",
    "Madhya Pradesh",
    "Gujarat",
    "Chhattisgarh",
    "Odisha",
    "Maharashtra",
    "Goa",
    "Telangana",
    "Karnataka",
    "Andhra Pradesh",
    "Kerala",
    "Tamil Nadu",
  ]; //option for state
  stateOption.sort(); //sort the option

  //chapterForm Initialization
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {
      title: "",
      region: "",
      state: "",
      country: "India",
      mission: "",
      isPublic: false,
      imageUrl: "",
      description: "",
      roleDetails: [],
      removedAdminEmail: [],
      adminsId: []
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "roleDetails",
  });

  // Adding a new empty object to roleDetails array when "Add Office Bearers" button is clicked
  const addRoleDetails = () => {
    append({
      name: "",
      address: "",
      mobile: "",
      email: "",
      role: "",
      roleImageUrl: "",
      isAdmin: false,
      password: "",
      isNew: true
    });
  };

  // Removing Roledetails for each row except the first one
  const removeRoleDetails = (index) => {
    remove(index);
  };

  const [saveChapter] = useSaveChapterMutation(); //calling save API service
  const { chapterId } = useParams(); //get Id from params
  const [chapterDetailById] = useLazyGetChapterDetailsByIdQuery(); //calling getby chapter id API service
  const [chapterData, setChapterData] = useState(null); //state for get id data
  const [updateChapter] = useUpdateChapterMutation(); //API for Update
  const [selectedImage, setSelectedImage] = useState();
  const fileInputRefImage = useRef(null);
  const [selectedRoleImage, setSelectedRoleImage] = useState([]);

  //get by id
  const fetchChapterData = useCallback(async () => {
    try {
      setLoading(true);
      const response = await chapterDetailById(chapterId);
      if (response.data.status) {
        setLoading(false);
        setChapterData(response.data.data); // Update the state with the fetched data
        setInitalData(response.data?.data);
      } else {
        setLoading(false);
        toast.error(response.data?.err?.message);
      }
    } catch (error) {
      toast.error(error);
    }
  }, [chapterDetailById, chapterId]);
  useEffect(() => {
    if (chapterId) {
      fetchChapterData(); // Call the function to fetch chapter data
    }
  }, [fetchChapterData, chapterId]);

  // Patch form values from chapterData
  useEffect(() => {
    if (chapterData) {
      setValue("title", chapterData.title);
      setValue("region", chapterData.region);
      setValue("mission", chapterData.mission);
      setValue("description", chapterData.description);
      setValue("isPublic", chapterData.isPublic);
      setValue("state", chapterData.state);
      setValue("imageUrl", chapterData.imageUrl)
      if (chapterData.roleDetails.length !== 0) {
        setValue("roleDetails", chapterData.roleDetails);
      }
      const roleImages = chapterData.roleDetails.map(
        (item) => item.roleImageUrl
      );
      setSelectedRoleImage(roleImages);
    }
  }, [chapterData, setValue]);

  //Form submit
  const handleFormSubmit = async (formData) => {
    setLoading(true);
    if (chapterId) {
      const formRoleDetailsIds = formData.roleDetails.map(
        (role) => role._id
      );
      const filteredData = initialData?.roleDetails?.filter((item) => {
        const isIncluded = formRoleDetailsIds.includes(item._id);
        return !isIncluded;
      });
      const isAdminStatus = filteredData.filter(
        (item) => item.isAdmin === true
      );
      if (isAdminStatus) {
        const email = isAdminStatus.map((item) => item.email);
        setValue("removedAdminEmail", email);
        setValue("adminsId", initialData.adminsId);
        formData.removedAdminEmail = watch("removedAdminEmail")
        formData.adminsId = watch("adminsId")
        const base64Regex =
          /^data:image\/(?:png|jpeg|jpg|gif);base64,[\w+/=]+$/;
        formData.imageUrl = base64Regex.test(formData.imageUrl)
          ? formData.imageUrl
          : formData?.imageUrl.split("/").slice(-2).join("/");
        formData.roleDetails = formData.roleDetails.map((item) => {
          return {
            address: item.address,
            email: item.email,
            roleImageUrl: base64Regex.test(item.roleImageUrl)
              ? item.roleImageUrl
              : item.roleImageUrl?.split("/").slice(-3).join("/"),
            isAdmin: item.isAdmin,
            mobile: item.mobile,
            name: item.name,
            password: item.password,
            role: item.role
          };
        });
        const data = { id: chapterId, formData: formData };
        try {
          const res = await updateChapter(data);
          if (res.data.status) {
            setLoading(false);
            toast.success(res.data?.message);
            navigate("/view-Chapter");
          } else {
            setLoading(false);
            toast.error(res.data?.err?.message);
          }
        } catch (error) {
          setLoading(false);
          console.error("API error:", error);
          toast.error("Failed to update chapter");
        }
      }
    }
    //Save Chapter API
    else {
      saveChapter(formData).then((res) => {
        if (res.data.status) {
          setLoading(false);
          toast.success(res.data?.message)
          navigate("/view-Chapter"); // Navigate after the success message disappears
        } else {
          setLoading(false);
          toast.error(res.data?.err?.message)
        }
      });
    }
  };

  //Image handling
  const handleImageFileChange = (event) => {
    const file = event.target.files[0];
    if (!file || !file.type.startsWith("image/")) {
      setSelectedImage(null);
      setValue("imageUrl", "");
      return;
    }
    if (file && file.size > 2 * 1024 * 1024) {
      setSelectedImage(null);
      setValue(`imageUrl`, "")
      return;
    } else {
      const reader = new FileReader();
      reader.onload = (e) => {
        let base64String = e.target.result;
        setSelectedImage(reader.result);
        setValue("imageUrl", base64String);
      };
      reader.readAsDataURL(file);
    }
  };

  //Image handling
  const handleRoleImageFileChange = (event, index) => {
    const file = event.target.files[0];
    if (!file || !file.type.startsWith("image/")) {
      setSelectedRoleImage((prevImages) => {
        const newImages = [...prevImages];
        newImages[index] = null;
        return newImages;
      });
      setValue(`roleDetails.${index}.roleImageUrl`, "")
      return;
    }
    if (file && file.size > 2 * 1024 * 1024) {
      setSelectedRoleImage((prevImages) => {
        const newImages = [...prevImages];
        newImages[index] = null;
        return newImages;
      });
      setValue(`roleDetails.${index}.roleImageUrl`, "")
      return;
    }
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        let base64String = e.target.result;
        console.log('base', base64String)
        setSelectedRoleImage((prevImages) => {
          // const newImages = [...prevImages]; // Create a copy of the array
          // newImages.splice(index, 1, reader.result); // Correctly use splice to replace the item at index
          // return newImages;
          const newImages = [...prevImages];
          newImages[index] = base64String; // Replace image at the specified index
          console.log('newImages', newImages)
          return newImages;
        });
        setValue(`roleDetails.${index}.roleImageUrl`, base64String);
        console.log('ro', `roleDetails.${index}.roleImageUrl`)
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div>
      <TopHeader />
      {chapterId ? (<div className='pt-1 '>
        <div className='p-0'>
          <div className=' Architects-header d-flex align-items-center justify-content-center '>
            <h2 className='signika-fontOEC text-center '>Edit Chapter</h2>
          </div>
        </div>
      </div>) : (
        <div className='pt-1 '>
          <div className='p-0'>
            <div className=' Architects-header d-flex align-items-center justify-content-center '>
              <h2 className='signika-fontOEC text-center '>Add Chapter</h2>
            </div>
          </div>
        </div>
      )}
      <div className="container">
        <div className="row">
          <div className="col-md-7">
            <span style={{ width: "150px" }}></span>
          </div>
          <div className="col-md-4 mt-3">
            <Button
              onClick={() => window.history.back()}
              variant="contained"
              className="menus-color"
              style={{ float: "right" }}
            >
              Back
            </Button>
          </div>
        </div>
      </div>
      {loading ? (
        <Spinner />
      ) : (
        <Container
          maxwidth="md"
          style={{ marginTop: "25px", marginBottom: "25px" }}
        >
          <form onSubmit={handleSubmit(handleFormSubmit)}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "20px",
                marginTop: "20px",
              }}
            >
              <TextField
                InputLabelProps={{ shrink: true }}
                helperText={errors?.title?.message}
                error={!!errors?.title}
                fullWidth
                sx={{ width: '45%' }}
                id="title"
                label={
                  <Typography className="text-dark">
                    Name <span style={{ color: "red" }}>*</span>
                  </Typography>
                }
                variant="outlined"
                {...register("title", { required: "Please Enter Title" })}
              />
              <TextField
                style={{ width: "45%" }}
                InputLabelProps={{ shrink: true }}
                helperText={errors?.region?.message}
                error={!!errors?.region}
                id="region"
                label={
                  <Typography className="text-dark">
                    Region <span style={{ color: "red" }}>*</span>
                  </Typography>
                }
                variant="outlined"
                {...register("region", { required: "Please Enter a Region" })}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "20px",
              }}
            >
              <Controller rules={{ required: "Please Select a State" }} control={control} name="state" render={({ field, fieldState }) => (
                <FormControl style={{ width: "45%" }}>
                  <InputLabel shrink={true} id="state-label">
                    <span className="text-dark">State</span>
                    <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <Select
                    labelId="state-label"
                    id="State"
                    label={
                      <Typography className="text-dark">
                        State <span style={{ color: "red" }}>*</span>
                      </Typography>
                    }
                    value={field?.value || ""}
                    onChange={(e) => field.onChange(e.target.value)}
                    error={!!fieldState.error}
                  >
                    {stateOption.map((states, index) => (
                      <MenuItem key={index} value={states}>
                        {states}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText className="text-danger">
                    {fieldState?.error?.message}
                  </FormHelperText>
                </FormControl>
              )} />
              <TextField
                style={{ width: "45%" }}
                InputLabelProps={{ shrink: true }}
                id="mission"
                label={
                  <Typography className="text-dark">
                    Mission <span style={{ color: "red" }}>*</span>
                  </Typography>
                }
                variant="outlined"
                error={!!errors?.mission}
                helperText={errors?.mission?.message}
                {...register("mission", {
                  required: "Please Enter Mission Satement",
                })}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "20px",
              }}
            >

              <TextField
                style={{ width: "45%" }}
                InputLabelProps={{ shrink: true }}
                id="description"
                label={
                  <Typography className="text-dark">
                    Description <span style={{ color: "red" }}>*</span>
                  </Typography>
                }
                error={!!errors?.description}
                helperText={errors?.description?.message}
                variant="outlined"
                {...register("description", {
                  required: "Please Enter Description",
                })}
              />
              <div
                style={{ width: "45%" }}
              >
                {chapterId ? (
                  <>
                    <img
                      src={selectedImage ? selectedImage : `${watch("imageUrl")}?t=${new Date().getTime()}`} // Fetched image from backend with cache busting
                      height="100"
                      alt="chapterform"
                    />
                    <input
                      ref={fileInputRefImage}
                      accept="image/jpeg, image/png"
                      id="imageUrl"
                      type="file"
                      style={{ display: "none" }}
                      onChange={handleImageFileChange}
                    />
                    <label
                      htmlFor="imageUrl"
                      style={{ marginLeft: "10px" }}
                    >
                      <Button
                        variant="contained"
                        className="menus-color"
                        component="span"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        Choose Image File
                      </Button>
                    </label>
                  </>
                ) : (
                  <>
                    <div style={{ width: "100%", display: "flex" }}>
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        style={{
                          width: "100%",
                        }}
                        variant="outlined"
                        accept="image/jpeg, image/png"
                        label="Logo"
                        ref={fileInputRefImage}
                        id="imageUrl"
                        type="file"
                        onChange={handleImageFileChange}
                      />
                    </div>
                  </>
                )
                }
              </div>

            </div>
            <div style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "20px",
            }}>
              <div style={{ width: "45%" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={watch("isPublic")}
                      {...register("isPublic")}
                    />
                  }
                  label={
                    <Typography className="text-dark">
                      Public <span style={{ color: "red" }}>*</span>
                    </Typography>
                  }
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "20px",
              }}
            >
              <div style={{ width: "45%" }}>
                <Button variant="contained" className="menus-color" onClick={addRoleDetails}>
                  Add Office Bearers
                </Button>
              </div>
            </div>
            {fields.map((details, index) => (
              <div key={details.id}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "20px",
                  }}
                >
                  <TextField
                    style={{ width: "45%" }}
                    InputLabelProps={{ shrink: true }}
                    label={
                      <Typography className="text-dark">
                        Name <span style={{ color: "red" }}>*</span>
                      </Typography>
                    }
                    {...register(`roleDetails.${index}.name`, {
                      required: "Please Enter a Name",
                      minLength: {
                        value: 3,
                        message: "Name must be min length of 3",
                      },
                    })}
                    error={!!errors?.roleDetails?.[index]?.name}
                    helperText={errors?.roleDetails?.[index]?.name?.message}
                  />
                  <TextField
                    style={{ width: "45%" }}
                    InputLabelProps={{ shrink: true }}
                    label={
                      <Typography className="text-dark">
                        Email <span style={{ color: "red" }}>*</span>
                      </Typography>
                    }
                    InputProps={{
                      readOnly: !!chapterData?.roleDetails?.[index]?.email
                    }}
                    {...register(`roleDetails.${index}.email`, {
                      required: "Please Enter a email",
                      pattern: {
                        value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                        message: "Please Enter Valid email",
                      },
                    })}
                    error={!!errors?.roleDetails?.[index]?.email}
                    helperText={errors?.roleDetails?.[index]?.email?.message}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "20px",
                  }}
                >
                  <TextField
                    style={{ width: "45%" }}
                    InputLabelProps={{ shrink: true }}
                    label={
                      <Typography className="text-dark">
                        Mobile <span style={{ color: "red" }}>*</span>
                      </Typography>
                    }
                    {...register(`roleDetails.${index}.mobile`, {
                      required: "Please Enter a Mobile number",
                      validate: (value) => {
                        if (value.length !== 10) {
                          return "Please enter a valid 10-digit phone number";
                        }
                        // Additional validation logic if needed
                        return true; // Validation passed
                      },
                    })}
                    error={!!errors?.roleDetails?.[index]?.mobile}
                    helperText={errors?.roleDetails?.[index]?.mobile?.message}
                  />
                  <TextField
                    style={{ width: "45%" }}
                    InputLabelProps={{ shrink: true }}
                    label={
                      <Typography className="text-dark">
                        Address <span style={{ color: "red" }}>*</span>
                      </Typography>
                    }
                    {...register(`roleDetails.${index}.address`, {
                      required: "Please Enter address",
                    })}
                    error={!!errors?.roleDetails?.[index]?.address}
                    helperText={errors?.roleDetails?.[index]?.address?.message}
                  />
                </div>
                {!chapterId ? (
                  <div >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "20px",
                      }}
                    >
                      <>
                        <TextField
                          style={{ width: "45%" }}
                          InputLabelProps={{ shrink: true }}
                          label={
                            <Typography className="text-dark">
                              Role <span style={{ color: "red" }}>*</span>
                            </Typography>
                          }
                          {...register(`roleDetails.${index}.role`, {
                            required: "Role is required",
                          })}
                          error={!!errors?.roleDetails?.[index]?.role}
                          helperText={errors?.roleDetails?.[index]?.role?.message}
                        />
                      </>
                      <div style={{ width: "45%", display: "flex" }}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          style={{
                            width: "100%",
                          }}
                          variant="outlined"
                          ref={fileInputRefImage}
                          accept="image/jpeg, image/png"
                          id={`roleImageUrl-${index}`}
                          label="Image"
                          placeholder="Please Select a image"
                          type="file"
                          onChange={(e) => handleRoleImageFileChange(e, index)}
                        />
                      </div>
                    </div>
                    <div
                      style={{ width: "45%" }}
                      className={chapterId ? "disable_checkbox" : ""}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            style={{ color: chapterId ? "grey" : "#1976d2" }}
                            checked={watch(`roleDetails.${index}.isAdmin`)}
                            {...register(`roleDetails.${index}.isAdmin`)}
                          />
                        }
                        label="Admin"
                      />
                    </div>
                  </div>
                ) : (<>
                  <>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "20px",
                      }}
                    >
                      <TextField
                        style={{ width: "45%" }}
                        InputLabelProps={{ shrink: true }}
                        label={
                          <Typography className="text-dark">
                            Role <span style={{ color: "red" }}>*</span>
                          </Typography>
                        }
                        {...register(`roleDetails.${index}.role`, {
                          required: "Role is required",
                        })}
                        error={!!errors?.roleDetails?.[index]?.role}
                        helperText={errors?.roleDetails?.[index]?.role?.message}
                      />
                      <>
                        {chapterId ?
                          (
                            <>
                              <div style={{ width: '45%' }}>
                                <img
                                  src={
                                    selectedRoleImage[index] 
                                      ? selectedRoleImage[index].startsWith("data:image/") 
                                        ? selectedRoleImage[index] 
                                        : `${selectedRoleImage[index]}?t=${new Date().getTime()}` 
                                      : watch(`roleDetails.${index}.roleImageUrl`)
                                  }
                                  height="100"
                                  alt={(!watch(`roleDetails.${index}.roleImageUrl`) && !watch(`roleDetails.${index}.isNew`)) ? "roleImage" : ""}
                                  />
                                <input
                                  ref={fileInputRefImage}
                                  accept="image/jpeg, image/png"
                                  id={`roleImageUrl-${index}`}
                                  type="file"
                                  label="Image"
                                  variant="outlined"
                                  placeholder="Please Select a image"
                                  style={{ display: "none" }}
                                  onChange={(e) => handleRoleImageFileChange(e, index)}
                                />
                                <label
                                  htmlFor={`roleImageUrl-${index}`}
                                  style={{ marginLeft: "10px" }}
                                >
                                  <Button
                                    variant="contained"
                                    className="menus-color"
                                    component="span"
                                    style={{ whiteSpace: "nowrap" }}
                                  >
                                    Choose Image File
                                  </Button>
                                </label>
                              </div>
                            </>
                          ) : (
                            <>
                              <div style={{ width: "45%", display: "flex" }}>

                                <TextField
                                  InputLabelProps={{ shrink: true }}
                                  style={{
                                    width: "100%",
                                  }}
                                  variant="outlined"
                                  id={`roleImageUrl-${index}`}
                                  label="Image"
                                  ref={fileInputRefImage}
                                  accept="image/jpeg, image/png"
                                  type="file"
                                  onChange={(e) => handleRoleImageFileChange(e, index)}
                                />
                              </div>
                            </>
                          )
                        }
                      </>
                    </div>
                  </>
                </>)}
                <div
                  style={{
                    marginBottom: "20px",
                  }}
                >
                  {(!chapterId && watch(`roleDetails.${index}.isAdmin`)) ? (
                    <div className="mt-4">
                      <Grid container rowSpacing={3} columnSpacing={5}>
                        <Grid item xs={6}>
                          <TextField
                            style={{ width: "93%" }}
                            InputLabelProps={{ shrink: true }}
                            label={
                              <Typography className="text-dark">
                                Password <span style={{ color: "red" }}>*</span>
                              </Typography>
                            }
                            InputProps={{ readOnly: chapterId ? true : false }}
                            {...register(`roleDetails.${index}.password`, {
                              required: "Please Enter a password",
                            })}
                            error={!!errors?.roleDetails?.[index]?.password}
                            helperText={
                              errors?.roleDetails?.[index]?.password?.message
                            }
                          />
                        </Grid>
                        <Grid item xs={6} >
                          <div className="float-end mt-2">
                            <Button
                              variant="contained"
                              style={{ marginBottom: "90px", background: "red" }}
                              onClick={() => removeRoleDetails(index)}
                            >
                              <DeleteIcon />
                              Remove
                            </Button>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  ) : (
                    <div style={{ marginBottom: '50px' }}>
                      <div className="float-end" >
                        <Button
                          variant="contained"
                          style={{ background: "red" }}
                          onClick={() => removeRoleDetails(index)}
                        >
                          <DeleteIcon />
                          Remove
                        </Button>
                      </div><br />
                    </div>
                  )}
                </div>
              </div>
            ))}
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button variant="contained" className="menus-color" type="submit">
                {chapterId ? "Update" : "Submit"}
              </Button>
            </div>
          </form>
        </Container>
      )
      }
    </div >
  );
};

export default ChapterForm;
