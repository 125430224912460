import React from 'react'
import TopHeader from '../../Component/Header/TopHeader'
import { Box, Container } from '@mui/material'
import '../../style/css/chapter/startingchapter.css'
import chapter from '../../assets/images/chapter/chapter.png'
import chapter1 from '../../assets/images/chapter/chaptergray.png'
import icon from '../../assets/images/chapter/icon.png'

const StartingChapter = () => {
  //card Contained
  const cardArray = [{
    id: 1,
    news: "A free copy of our bi-monthly, Journal of English Language Teaching"
  },
  {
    id: 2,
    news: "Reduced Registration fees for attending all our programmes including our Annual conferences"
  },
  {
    id: 3,
    news: "Preference given to our members in the publication of their articles in our Journals"
  },
  {
    id: 4,
    news: "Eligibility for getting subsidized membership of the International Association of Teachers of English as a Foreign Language. (IATEFL)"
  },
  {
    id: 5,
    news: "Opportunities for interaction with ELT professionals in India and abroad"
  },
  {
    id: 6,
    news: "Access to our E-Journals—The ELT Practitioner, Journal of Technology for ELT and Journal of Teaching and Research in English Literate"
  },



  ]
  return (
    <Box>
      <div className='starting-chapter-container'>
        <div className='contact-us-nav-bar'>
          <TopHeader />
        </div>
      </div>
      <img src={chapter} className="img-fluid" alt="Starting Chapter" />
      <Box sx={{ width: '100%', height: 'auto', backgroundImage:  `url(${chapter1})`, backgroundPosition: 'center center', backgroundSize: "cover", }}>
        <Container maxWidth={"lg"}>
          <h2 className='text-dark  text-start font-family  staring-Chapter-title '>STARING A CHAPTER</h2>
          <p className='text-dark Guidlines-Chapter-heading text-center font-weight-bold mt-5 mb-3'>
            STARTING A CHAPTER — GUIDELINES
          </p>
          <p className='text-dark Guidlines-Chapter-heading text-justify font-weight-bold mt-5'>
            For starting a new chapter one is required to collect subscriptions from at least 25 teachers and
            send the total amount collected by a bank Demand Draft taken in favour of ELTAI to our office at Chennai
            (Address found in our websites www.eltai.in ) only by Speed post or Courier service.
          </p>
          <p className='text-dark Guidlines-Chapter-heading text-justify font-weight-bold mt-2 pb-5'>
            In his/her capacity as the Convenor of the chapter, the one who collects the subscriptions
            may issue a temporary receipt, if required. And the ELTAI office will send individually to
            all the members enrolled, the official receipt
            with the Membership ID after receiving the subscription amount.
          </p>
        </Container>
      </Box>
      <Box>
        <div className='container-fluid '>
          <Container maxWidth={"lg"}>
            <div className='card mb-5 card-container-start-chapter h-auto '>
              <div className='card-body '>
                <div className='text-dark card-Chapter-heading text-center font-weight-bold mt-3'>
                  It is suggested that the following benefits of membership of ELTAI<br /> may first be made known to teachers:
                </div>
                {cardArray.map((card, i) => (
                  <div key={i}>
                    <div className='chapter-important-news mt-1'>
                      <img src={icon} alt='img' />
                      {card.news}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Container>
        </div>
      </Box>
      <Container maxWidth={'lg'}>
        <div className='chapter-bottom  '>
          <p className='text-dark Guidlines-Chapter-heading text-justify font-weight-bold mt-5'>
            It is easy to enroll members at seminars/workshops/conferences organized by any institution.
            Of the 25 members enrolled at least 20 members have to be either short or long term members.
          </p>
          <p className='text-dark Guidlines-Chapter-heading text-justify font-weight-bold mt-2'>
            For any clarification<a className='chapter-span-footer no-decoration  p-1' rel="noreferrer" href='mailto:http://eltai_india@yahoo.co.in' target='_blank'>eltai_india@yahoo.co.in</a>may be contacted.
          </p>
        </div>
      </Container>
    </Box>
  )
}

export default StartingChapter