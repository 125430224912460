import React, { useState, useEffect } from "react";
import TopHeader from "../../../Component/Header/TopHeader";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import TableRow from "@mui/material/TableRow";
import {
  usePaginationCategoryMutation,
  useDeleteCategoryMutation,
  useEnableDisableCategoryMutation,
} from "../../../app/services/categoryService";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import Spinner from "../../../Component/spinner/spinner";
import { Container, Modal, Typography } from "@mui/material";
import Tooltip from "@material-ui/core/Tooltip";

//table head for category
function EnhancedCategoryTableHead() {
  const headCells = [
    {
      id: "categoryname",
      numeric: false,
      disablePadding: true,
      label: "Category Name"
    },
    {
      id: "subCategoryname",
      numeric: true,
      disablePadding: false,
      label: "Sub Category Name"
    },
    { id: "actions", numeric: true, disablePadding: false, label: "Actions" },
  ];

  return (
    <>
      <TableHead>
        <TableRow className="menus-color">
          {headCells.map((headCell) => (
            <TableCell
              sx={{ textAlign: "center", color: "#ffff" }}
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
            >
              {headCell.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    </>
  );
}

const ViewCategory = () => {
  const [selectedOpt, setSelectedOpt] = useState(0);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate(); //route
  const [page, setPage] = React.useState(0); //state for set page
  const [rowsPerPage, setRowsPerPage] = React.useState(5); //state for item per page
  const [getAllPaginationCategoryList] = usePaginationCategoryMutation(); //API call of Get all
  const [deleteCategory] = useDeleteCategoryMutation(); //API call of delete
  const [enableDisableCategory] = useEnableDisableCategoryMutation(); //API call of anableDisable
  const [type, setType] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [allCategory, setAllCategory] = useState([]);
  const handleOpen = (id) => {
    setOpen(true);
    setSelectedId(id);
  };
  const handleClose = () => setOpen(false);
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  //calling event data
  useEffect(() => {
    setRowsPerPage(5);
    const data = { type: 0 };
    fetchData(data);
    // Fetch data on component mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, type]); // Update useEffect dependencies

  //getAllPendingEventList
  const fetchData = (datas) => {
    var data = {
      type: type,
      page: page + 1,
      itemsPerPage: rowsPerPage,
    };
    getAllPaginationCategoryList(data)
      .then((res) => {
        setLoading(false);
        setAllCategory(res?.data?.data?.categoryDetails || []);
        setTotalPage(res?.data?.data.totalCount || 0);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching pending event list:", error);
      });
  };

  //page Click for category
  const handlePageClick = ({ selected }) => {
    setPage(selected);
  };

  //sliced category
  const slicedCategory = allCategory.slice(0, rowsPerPage); // Adjust slicing

  //get change value
  const getChangeValue = (eve) => {
    var data = {
      type: eve.target.value,
    };
    setType(eve.target.value);
    fetchData(data);
  };

  //Edit category&subcategory
  const handleEdit = (id) => {
    navigate("/admin/category/" + id);
  };

  //Delete category & subcategory
  const handleDelete = (id) => {
    const data = { id: selectedId };
    setLoading(true);
    deleteCategory(data).then((res) => {
      if (res.data.status) {
        setLoading(false);
        handleClose();
        if (slicedCategory.length <= 1) {
          setPage(page - 1);
          fetchData();
        } else {
          fetchData();
        }
      } else {
        setLoading(false);
        toast.error(res?.data?.err?.message);
      }
    });
  };

  //Enable and disable category & subcategory
  const handleEnableDisable = (id, num) => {
    const data = { id: id };
    setLoading(true);
    enableDisableCategory(data).then((res) => {
      if (res.data.status) {
        setLoading(false);
        if (num === 0) {
          //set num for diff the enable and disable
          toast.error("Category Disabled successfully"); //set alert message
        } else {
          toast.success("Category  Enabled successfully"); //set alert message
        }
        // Call fetchData again to fetch updated category list after deletion
        fetchData();
      } else {
        setLoading(false);
        if (num === 0) {
          toast.error("Fail to Disable Category "); //set alert message
        } else {
          toast.error("Fail to Enable Category "); //set alert message
        }
      }
    });
  };

  //add Category
  const addCategory = () => {
    navigate("/admin/category");
  };

  //calculate pagecount
  const pageCount = Math.ceil(totalPage / rowsPerPage);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "5px",
    p: 4,
  };

  return (
    <div>
      <TopHeader />
      <Box>
        <div className='pt-1 '>
          <div className='p-0'>
            <div className=' Architects-header d-flex align-items-center justify-content-center '>
              <h2 className='signika-fontOEC text-center '>Category List</h2>
            </div>
          </div>
        </div>
      </Box>
      <div className='container'>
        <span style={{ width: "165px" }}></span>
      </div>
      <Container maxWidth="xl">
        <div className="row">
          <div className="col-md-4 mt-3">
            <div className="container mt-3">
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  marginBottom: "20px",
                }}
              >
                <FormControl style={{ width: "57%" }}>
                  <InputLabel shrink={true} id="type-label">
                    Type
                  </InputLabel>
                  <Select
                    labelId="type-label"
                    id="type"
                    label="type"
                    required
                    value={selectedOpt}
                    onChange={getChangeValue}
                  >
                    <MenuItem onClick={() => setSelectedOpt(0)} value={0}>
                      Job
                    </MenuItem>
                    <MenuItem onClick={() => setSelectedOpt(1)} value={1}>
                      Forums
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
          <div className="col-md-8 mt-3">
            <div className="d-flex justify-content-end mt-3">
              <Button
                variant="contained"
                className="menus-color"
                onClick={addCategory}
              >
                ADD Category{" "}
              </Button>
            </div>
          </div>
        </div>
      </Container>
      {loading ? (
        <Spinner />
      ) : (
        <Container maxWidth="lg">
          <Box className="memberTable ms-2">
            <Paper sx={{ width: "100%", mb: 4 }}>
              <TableContainer>
                <Table aria-labelledby="tableTitle">
                  <EnhancedCategoryTableHead />
                  <TableBody>
                    {slicedCategory.length > 0 ? (
                      slicedCategory.map((category, index) => (
                        <TableRow
                          hover
                          key={category._id}
                          sx={{
                            cursor: "pointer",
                            backgroundColor:
                              index % 2 === 0 ? "#f0f0f0" : "white",
                          }}
                        >
                          <TableCell align="center">
                            {category.categoryName}
                          </TableCell>
                          <TableCell align="center">
                            {category.subCategory &&
                              category.subCategory.length > 0 ? (
                              <>
                                {category.subCategory.map(
                                  (subcategory, index) => (
                                    <span key={subcategory._id}>
                                      {subcategory.subCategoryName}
                                      {index !==
                                        category.subCategory.length - 1 && ", "}
                                    </span>
                                  )
                                )}
                              </>
                            ) : (
                              <>------</>
                            )}
                          </TableCell>
                          <TableCell align="center">
                            <Tooltip title="Edit">
                              <IconButton
                                aria-label="delete"
                                onClick={() => handleEdit(category._id)}
                              >
                                {!(category.isDeleted === 2) && (
                                  <EditIcon style={{ color: "green" }} />
                                )}
                              </IconButton>
                            </Tooltip>
                            {category.isDeleted === 0 && (
                              <Tooltip title="Enable">
                                <IconButton
                                  aria-label="enable"
                                  onClick={() =>
                                    handleEnableDisable(category._id, 0)
                                  }
                                >
                                  <LockOpenIcon style={{ color: "green" }} />
                                </IconButton>
                              </Tooltip>
                            )}
                            {category.isDeleted === 2 && (
                              <Tooltip title="Disable">
                                <IconButton
                                  aria-label="disable"
                                  onClick={() =>
                                    handleEnableDisable(category._id, 1)
                                  }
                                >
                                  <LockIcon style={{ color: "red" }} />
                                </IconButton>
                              </Tooltip>
                            )}
                            <Tooltip title="Delete">
                              <IconButton
                                aria-label="approve"
                                onClick={() => handleOpen(category._id)}
                              >
                                <DeleteIcon style={{ color: "red" }} />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <>
                        <TableRow>
                          <TableCell colSpan={5}>
                            <p className="text-black text-center">
                              No Data Available
                            </p>
                          </TableCell>
                        </TableRow>
                      </>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box
                  sx={style}
                  className="d-flex align-items-center flex-column gap-3"
                >
                  <Typography
                    id="modal-modal-title"
                    className="text-primary"
                    variant="h6"
                    component="h2"
                  >
                    Are You Surely Want to Delete this Category?
                  </Typography>
                  <div>
                    <Button
                      variant="outlined"
                      style={{
                        color: "#2e7d32",
                        border: "1px solid rgba(46, 125, 50, 0.5)",
                        padding: "5px 15px",
                        borderRadius: "4px",
                      }}
                      onClick={handleClose}
                    >
                      No
                    </Button>
                    <Button
                      className="mx-2"
                      variant="contained"
                      color="error"
                      onClick={() => handleDelete()}
                    >
                      Yes
                    </Button>
                  </div>
                </Box>
              </Modal>
            </Paper>
            {slicedCategory.length > 0 ? (
              <ReactPaginate
                pageCount={pageCount} // Calculate total pages
                pageRangeDisplayed={2}
                marginPagesDisplayed={1}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                activeClassName={"active"}
                disabledClassName={"disablebtn"}
                forcePage={page}
                previousLabel={pageCount > 1 && page >= 1 ? "Previous" : null}
                nextLabel={
                  pageCount > 1 && page + 1 !== pageCount ? "Next" : null
                }
              />
            ) : (
              <></>
            )}
            <br/><br/>
          </Box>
        </Container>
      )}
    </div>
  );
};

export default ViewCategory;
