import {
  Autocomplete,
  FormHelperText,
  TextField,
  Typography,
} from "@mui/material";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  InputLabel,
  MenuItem,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm, Controller, useFieldArray, useWatch } from "react-hook-form";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import Select from "@mui/material/Select";
import "../../style/css/eventForm.css";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import TopHeader from "../../Component/Header/TopHeader";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import FormControl from "@mui/material/FormControl";
import { useGetAllEventTypeQuery } from "../../app/services/eventTypeServices";
import { useGetAllSIGGroupQuery } from "../../app/services/SIGGroupServices";
import {
  useCreateEventMutation,
  useGetEventDetailsByIdQuery,
  useUpdateEventMutation,
} from "../../app/services/eventService";
import { useLazyGetAllChaptersQuery } from "../../app/services/chapterServices";
import CancelIcon from "@mui/icons-material/Cancel";
import { useParams } from "react-router-dom";
import {
  selectCurrentScope,
  selectUserId,
  selectCurrentRoleId,
} from "../../app/auth/authSlice";
import { selectCurrentSig } from "../../app/features/sigSlice";
import { selectCurrentChapterAdmin } from "../../app/features/chapterAdminSlice";
import { useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Spinner from "../../Component/spinner/spinner";
import placeholder from '../../../src/assets/images/Event/placeholder-image-icon-0.jpg'

// Dummy steps for the stepper
const steps = [
  "Event Information",
  "Location Information",
  "Organizer and Sponsor Information",
  "Speaker Information",
];

function EventForm() {
  const memberScope = useSelector(selectCurrentScope);
  const roleId = useSelector(selectCurrentRoleId);
  const userId = useSelector(selectUserId);
  const sigDetails = useSelector(selectCurrentSig);
  const sigId = sigDetails._id;
  const chapterDetails = useSelector(selectCurrentChapterAdmin);
  const chapterId = chapterDetails._id;
  const today = new Date();
  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const { eventId } = useParams(); //get Id from params
  const [createEvent] = useCreateEventMutation();
  const [updateEventById] = useUpdateEventMutation();
  const navigate = useNavigate();
  const [allEvent, setAllEvent] = React.useState([]);
  const [allSIG, setAllSIG] = React.useState([]);
  const [selectedChapters, setSelectedChapters] = useState([]);
  const [selectedEvent, setselectedEvent] = useState(false);
  const { refetch: refetchEventType } = useGetAllEventTypeQuery();
  const { refetch: refetchSIG } = useGetAllSIGGroupQuery();
  const [getAllChapters] = useLazyGetAllChaptersQuery();
  const { data: eventById, refetch: refetchEventById } =
    useGetEventDetailsByIdQuery(eventId);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedSpeakerImage, setSelectedSpeakerImage] = useState([]);
  const [isValidImage, setIsValidImage] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);

  // useForm hook for form data handling
  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    reset,
    trigger,
    setError,
    clearErrors,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      title: "",
      description: "",
      eventTypeId: "",
      SIGGroupsId: [],
      isMember: false,
      isPublic: false,
      startDate: "",
      endDate: "",
      startTime: "",
      endTime: "",
      createdBy: {
        id: "",
        role: "",
      },
      imageUrl: "",
      urlLink: "",
      place: "",
      locationName: "",
      locationDescription: "",
      locationAddress: "",
      organizerName: "",
      organizerDescription: "",
      sponsorDescription: "",
      speakers: [{}],
      chaptersId: [],
      isEnableRegistration: false,
      isVirtual: false,
    },
  });

  //Validate SIGGroupsId and chaptersId
  const validateAtLeastOne = () => {
    const { SIGGroupsId, chaptersId } = getValues();
    return (SIGGroupsId.length > 0 || chaptersId.length > 0) || "Please Select Atleast One Of SIG Groups Or Chapters";
  };

  //Watch the fields to revalidate whenever their values change
  const sigGroupsValue = useWatch({ control, name: 'SIGGroupsId' });
  const chaptersValue = useWatch({ control, name: 'chaptersId' });

  //trigger Chappter(or)SIG
  useEffect(() => {
    if (isSubmitted) {
      trigger('SIGGroupsId');
      trigger('chaptersId');
    }
  }, [sigGroupsValue, chaptersValue, trigger, isSubmitted]);

  //On submit for save and update
  const SubmitEvent = (formData) => {
    setIsSubmitted(true);
    if (!isValidImage) {
      return; // Stop the form submission if the image is invalid or greater than 2MB
    }
    if (activeStep === steps.length - 1) {
      setLoading(true);
      var newEventData = {
        ...formData,
        startDate: new Date(formData.startDate)
          .toLocaleDateString("en-CA", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          })
          .replace(/\//g, "-"),
        endDate: new Date(formData.endDate)
          .toLocaleDateString("en-CA", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          })
          .replace(/\//g, "-"),
        startTime: formData.startTime ? formatTime(formData.startTime) : "",
        endTime: formData.endTime ? formatTime(formData.endTime) : "",
        imageUrl: formData.imageUrl, //modified
        id: eventId,
      };
      if (
        (userId && memberScope === "PORTAL_SUPER_ADMIN") ||
        (userId && memberScope === "PORTAL_SIG_ADMIN") ||
        (userId && memberScope === "PORTAL_CHAPTER_ADMIN")
      ) {
        newEventData.createdBy = {
          id: roleId ? roleId : userId,
          role: memberScope,
        };
      }
      if (userId && memberScope === "PORTAL_SIG_ADMIN") {
        newEventData.SIGGroupsId = [sigId];
      } else if (userId && memberScope === "PORTAL_CHAPTER_ADMIN") {
        newEventData.chaptersId = [chapterId];
      }
      if (eventId) {
        const base64Regex =
          /^data:image\/(?:png|jpeg|jpg|gif);base64,[\w+/=]+$/;
        const newData = {
          ...newEventData,
          startTime: formData.startTime ? formatTime(formData.startTime) : "",
          endTime: formData.endTime ? formatTime(formData.endTime) : "",
          imageUrl: base64Regex.test(newEventData.imageUrl)
            ? newEventData.imageUrl
            : newEventData?.imageUrl.split("/").slice(-2).join("/"),
          speakers: newEventData.speakers.map((item) => {
            const dada = {
              file: item.file,
              speakerDescription: item.speakerDescription,
              speakerImageUrl: base64Regex.test(item.speakerImageUrl)
                ? item.speakerImageUrl
                : item.speakerImageUrl?.split("/").slice(-3).join("/"),
              speakerName: item.speakerName,
              speakerPosition: item.speakerPosition,
            };
            return dada;
          }),
        };
        updateEventById(newData).then((res) => {
          if (res.data.status) {
            setLoading(false);
            toast.success(res.data?.message)
            reset();
            if (memberScope === "PORTAL_SUPER_ADMIN") {
              navigate("/admin/eventList");
            }
            if (memberScope === "PORTAL_CHAPTER_ADMIN") {
              navigate("/chapterAdmin/eventList");
            }
            if (memberScope === "PORTAL_SIG_ADMIN") {
              navigate("/sigAdmin/eventList");
            }
          } else {
            setLoading(false);
            toast.error(res.data?.err?.message)
          }
        });
      } else {
        const newData = {
          ...newEventData,
          speakers: newEventData.speakers.map((item) => {
            const dada = {
              file: item.file,
              speakerDescription: item.speakerDescription,
              speakerImageUrl: item.speakerImageUrl,
              speakerName: item.speakerName,
              speakerPosition: item.speakerPosition,
            };
            return dada;
          }),
        };
        createEvent(newData).then((res) => {
          if (res.data.status) {
            setLoading(false);
            toast.success(res.data?.message)
            window.history.back();
          } else {
            setLoading(false);
            toast.error(res.data?.err?.message)
          }
        });
      }
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  //Time format
  const formatTime = (timeString) => {
    const time = dayjs(timeString);
    // Format the time in 12-hour format with AM/PM
    const formattedTime = time.format("hh:mm A");
    return formattedTime;
  };

  //Get all chapter
  React.useEffect(() => {
    setValue("isEnableRegistration", false);
    refetchEventType()
      .then((res) => {
        setAllEvent(res?.data?.data || []);
      })
      .catch((error) => { });
    refetchSIG().then((res) => {
      setAllSIG(res?.data?.data || []);
    });
    getAllChapters().then((res) => {
      setSelectedChapters(res?.data?.data || []);
    });
    if (eventId) {
      if (eventById?.status) {
        setselectedEvent(eventById?.data || []);
        refetchEventById().then((res) => {
          setValue("eventTypeId", res?.data?.data?.eventTypeId);
        });
      }
    }
  }, [
    setValue,
    eventId,
    getAllChapters,
    refetchEventById,
    refetchEventType,
    refetchSIG,
    eventById,
  ]);

  //Patch form values from chapterData
  useEffect(() => {
    if (selectedEvent) {
      setValue("title", selectedEvent.title);
      setValue("SIGGroupsId", selectedEvent.SIGGroupsId);
      setValue("isPublic", selectedEvent.isPublic);
      setValue("isMember", selectedEvent.isMember);
      setValue("startDate", formatDate(selectedEvent.startDate));
      setValue("endDate", formatDate(selectedEvent.endDate));
      setValue("urlLink", selectedEvent.urlLink);
      setValue("place", selectedEvent.place);
      setValue("locationName", selectedEvent.locationName);
      setValue("locationDescription", selectedEvent.locationDescription);
      setValue("locationAddress", selectedEvent.locationAddress);
      setValue("organizerName", selectedEvent.organizerName);
      setValue("organizerDescription", selectedEvent.organizerDescription);
      setValue("sponsorDescription", selectedEvent.sponsorDescription);
      setValue("speakers", selectedEvent.speakers);
      setValue("chaptersId", selectedEvent.chaptersId);
      Object.entries(selectedEvent).forEach(([key, value]) => {
        let startTimeDayjs = dayjs(selectedEvent.startTime, "h:mm A");
        let endTimeDayjs = dayjs(selectedEvent.endTime, "h:mm A");
        if (String(startTimeDayjs.$d) !== "Invalid Date") {
          setValue("startTime", startTimeDayjs);
        } else {
          setValue("startTime", "");
        }
        if (String(endTimeDayjs.$d) !== "Invalid Date") {
          setValue("endTime", endTimeDayjs);
        } else {
          setValue("endTime", "");
        }
        setValue(key, value);
      });
      const speakerImages = selectedEvent.speakers.map(
        (item) => item.speakerImageUrl
      );
      setSelectedSpeakerImage(speakerImages);
    }
  }, [selectedEvent, setValue]);

  const eventTypeId = watch("eventTypeId");

  //Date format
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  //Event image handling
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (!file || !file.type.startsWith("image/")) {
      setSelectedImage(null);
      setValue(`imageUrl`, "")
      return;
    }
    if (file && file.size > 2 * 1024 * 1024) {
      setSelectedImage(null);
      setValue(`imageUrl`, "")
      return;
    }
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.src = e.target.result;
        img.onload = () => {
          if (img.width !== 420 || img.height !== 160) {
            setSelectedImage(null);
            setValue(`imageUrl`, "");
            setError("image", { message: "Image dimensions must be 420x160 pixels" });
            return;
          }
          // Image is valid
          let base64String = e.target.result;
          setSelectedImage(reader.result);
          setValue(`imageUrl`, base64String);
          setIsValidImage(true);
          clearErrors("image");
        };
      };
      reader.readAsDataURL(file);
    }
  };

  //Speaker image handling
  const handleSpeakerImageChange = (event, index) => {
    const file = event.target.files[0];
    if (!file || !file.type.startsWith("image/")) {
      setSelectedSpeakerImage((prevImages) => {
        const newImages = [...prevImages];
        newImages[index] = null;
        return newImages;
      });
      setValue(`speakers.${index}.speakerImageUrl`, "")
      return;
    }
    if (file && file.size > 2 * 1024 * 1024) {
      setSelectedSpeakerImage((prevImages) => {
        const newImages = [...prevImages];
        newImages[index] = null;
        return newImages;
      });
      setValue(`speakers.${index}.speakerImageUrl`, "")
      return;
    }
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        let base64String = e.target.result;
        setSelectedSpeakerImage((prevImages) => {
          const newImages = [...prevImages]; // Create a copy of the array
          newImages.splice(index, 1, reader.result); // Correctly use splice to replace the item at index
          return newImages;
        });
        setValue(`speakers.[${index}].speakerImageUrl`, base64String);
      };
      reader.readAsDataURL(file);
      setIsValidImage(true);
    }
  };

  //handle Back
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const {
    fields: speakerFields,
    append: appendSpeaker,
    remove: removeField,
  } = useFieldArray({
    control,
    name: "speakers",
  });

  const isMember = watch("isMember");
  const isPublic = watch("isPublic");
  const isVirtual = watch("isVirtual");
  const isEnableRegistration = watch("isEnableRegistration");

  useEffect(() => {
    if (memberScope === "PORTAL_SUPER_ADMIN") {
      if (isVirtual) {
        setValue("isVirtual", true);
      }
    }
  }, [isVirtual, setValue, memberScope])

  //patch Value ispublic
  useEffect(() => {
    if (isPublic) {
      setValue("isMember", false);
      setValue("SIGGroupsId", []);
      setValue("chaptersId", []);
    }
  }, [isPublic, setValue]);

  //patch Value member
  useEffect(() => {
    if (isMember) {
      setValue("isPublic", false);
      setValue("SIGGroupsId", []);
      setValue("chaptersId", []);
    }
  }, [isMember, setValue]);

  return (
    <>
      <TopHeader />
      {!eventId ? (
        <>
          <div className='pt-1 '>
            <div className='p-0'>
              <div className=' Architects-header d-flex align-items-center justify-content-center '>
                <h2 className='signika-fontOEC text-center '>Add Event</h2>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className='pt-1 '>
            <div className='p-0'>
              <div className=' Architects-header d-flex align-items-center justify-content-center '>
                <h2 className='signika-fontOEC text-center '>Edit Event</h2>
              </div>
            </div>
          </div>
        </>
      )}
      <div className="container mt-3">
        <div className="row">
          <div className="col-md-8">

          </div>
          <div className="col-md-4 mt-3">
            <Button
              onClick={() => window.history.back()}
              variant="contained"
              className="menus-color"
              style={{ float: "right", marginRight: 58 }}
            >
              Back
            </Button>
          </div>
        </div>
      </div>
      {loading ? (
        <Spinner />
      ) : (
        <Box
          className="container"
          sx={{ width: "100%", marginTop: "50px", marginBottom: "50px" }}
        >
          <Stepper activeStep={activeStep} className="mt-5">
            {steps.map((label, index) => {
              return (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <form className="mt-5" onSubmit={handleSubmit(SubmitEvent)}>
            {activeStep === 0 && (
              <Box>
                <div style={{ paddingLeft: 40, paddingRight: 40 }}>
                  <Grid container rowSpacing={3} columnSpacing={5}>
                    <Grid item xs={12}>
                      {memberScope === "PORTAL_SUPER_ADMIN" && (
                        <>
                          <FormControlLabel
                            control={<Checkbox checked={isPublic} />}
                            {...register("isPublic")}
                            label="Public"
                          />
                          <FormControlLabel
                            control={<Checkbox checked={isMember} />}
                            {...register("isMember")}
                            label="Member"
                          />
                        </>
                      )}
                      <FormControlLabel
                        control={<Checkbox checked={isEnableRegistration} />}
                        {...register("isEnableRegistration")}
                        label="Enable Registration"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        label={
                          <Typography className="text-dark">
                            Event Title <span style={{ color: "red" }}>*</span>
                          </Typography>
                        }
                        {...register("title", {
                          required: "Please Enter a Title",
                        })}
                        error={!!errors?.title}
                        helperText={errors?.title?.message}
                        placeholder="Event Title"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        label={
                          <Typography className="text-dark">
                            Description <span style={{ color: "red" }}>*</span>
                          </Typography>
                        }
                        {...register("description", {
                          required: "Please Enter a Description",
                        })}
                        error={!!errors?.description}
                        helperText={errors?.description?.message}
                        placeholder="Event Description"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Controller control={control} rules={{ required: "Please Select a Event Type", }} name="eventTypeId" render={({ field, fieldState }) => (
                        <FormControl fullWidth>
                          <InputLabel id="event">
                            Event Type <span style={{ color: "red" }}>*</span>
                          </InputLabel>
                          <Select
                            style={{ backgroundColor: "#f0f0f0" }}
                            labelId="event"
                            id="event"
                            label={
                              <Typography className="text-dark">
                                Event Type<span style={{ color: "red" }}>*</span>
                              </Typography>
                            }
                            value={field.value ? field.value : ""}
                            name={field.eventTypeId}
                            variant="outlined"
                            onChange={(e) => field.onChange(e.target.value)}
                            error={!!fieldState.error}
                          >
                            {allEvent.map((event) => (
                              <MenuItem key={event.id} value={event._id}>
                                {event.name}
                              </MenuItem>
                            ))}
                          </Select>
                          {!eventTypeId && (
                            <FormHelperText className="text-danger">
                              {fieldState?.error?.message}
                            </FormHelperText>
                          )}
                        </FormControl>
                      )} />
                    </Grid>
                    {memberScope === "PORTAL_SUPER_ADMIN" &&
                      !(isPublic || isMember) && (
                        <>
                          <Grid item xs={4}>
                            <Controller
                              control={control}
                              name="SIGGroupsId"
                              rules={{
                                validate: validateAtLeastOne,
                              }}
                              render={({ field, fieldState }) => (
                                <Autocomplete
                                  multiple
                                  id="sig"
                                  InputLabelProps={{ shrink: true }}
                                  fullWidth
                                  options={allSIG}
                                  disableCloseOnSelect
                                  getOptionLabel={(sig) => sig?.groupName}
                                  filterSelectedOptions
                                  getOptionSelected={(option, value) =>
                                    option._id === value._id
                                  }
                                  onChange={(event, values) => {
                                    const SIGGroupsId = values.map(
                                      (value) => value?._id
                                    ); // Extract _id from selected options
                                    field.onChange(SIGGroupsId); // Pass array of selected _ids to onChange
                                  }}
                                  value={
                                    field.value
                                      ? field.value.map((id) =>
                                        allSIG.find(
                                          (group) => group._id === id
                                        )
                                      )
                                      : []
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      error={!!fieldState?.error}
                                      helperText={fieldState?.error?.message}
                                      label={
                                        <Typography className="text-dark">
                                          SIG Groups{" "}
                                          <span style={{ color: "red" }}>
                                            *
                                          </span>
                                        </Typography>
                                      }
                                      placeholder="Select SIG"
                                    />
                                  )}
                                />
                              )}
                            />
                          </Grid>

                          <Grid item xs={4}>
                            <Controller
                              control={control}
                              name="chaptersId"
                              rules={{
                                validate: validateAtLeastOne,
                              }}
                              render={({ field, fieldState }) => (
                                <Autocomplete
                                  multiple
                                  id="tags-outlined-chapter"
                                  InputLabelProps={{ shrink: true }}
                                  fullWidth
                                  options={selectedChapters}
                                  getOptionLabel={(option) => option?.title}
                                  filterSelectedOptions
                                  getOptionSelected={(option, value) =>
                                    option._id === value._id
                                  }
                                  onChange={(event, values) => {
                                    const selectedIds = values.map(
                                      (value) => value._id
                                    ); // Extract _id from selected options
                                    field.onChange(selectedIds); // Pass array of selected _ids to onChange
                                  }}
                                  value={
                                    field.value
                                      ? field.value.map((id) =>
                                        selectedChapters.find(
                                          (group) => group._id === id
                                        )
                                      )
                                      : []
                                  } // Use local state as value
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      name="chaptersId"
                                      error={!!fieldState?.error}
                                      helperText={fieldState?.error?.message}
                                      label={
                                        <Typography className="text-dark">
                                          Chapters{" "}
                                          <span style={{ color: "red" }}>
                                            *
                                          </span>
                                        </Typography>
                                      }
                                      placeholder="Select Chapters"
                                    />
                                  )}
                                />
                              )}
                            />
                          </Grid>
                        </>
                      )}
                    <Grid item xs={4}>
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        label={
                          <Typography className="text-dark">
                            Start Date <span style={{ color: "red" }}>*</span>
                          </Typography>
                        }
                        variant="outlined"
                        type="date"
                        value={formatDate(watch("startDate"))}
                        {...register("startDate", {
                          required: "Please Select a Start Date", onChange: () => {
                            setValue('endDate', '')
                          }
                        })}
                        inputProps={{
                          min: eventId ? "" : today.toISOString().split("T")[0],
                        }}
                        error={!!errors?.startDate}
                        helperText={errors?.startDate?.message}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        label={
                          <Typography className="text-dark">
                            End Date <span style={{ color: "red" }}>*</span>
                          </Typography>
                        }
                        variant="outlined"
                        type="date"
                        inputProps={{
                          min: watch("startDate"),
                        }}
                        value={formatDate(watch("endDate"))}
                        {...register("endDate", {
                          required: "Please Select a End Date",
                        })}
                        error={!!errors?.endDate}
                        helperText={errors?.endDate?.message}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <div style={{ width: "60%" }}>
                          <Controller
                            control={control}
                            name="startTime"
                            render={({ field: { onChange, value } }) => (
                              <TimePicker
                                label="Start Time"
                                sx={{ width: "93%" }}
                                InputLabelProps={{ shrink: true }}
                                variant="outlined"
                                value={value || null}
                                onChange={(newTime) => {
                                  onChange(newTime);
                                }}
                                renderInput={(params) => (
                                  <TextField {...params}
                                  />
                                )}
                                viewRenderers={{
                                  hours: renderTimeViewClock,
                                  minutes: renderTimeViewClock,
                                  seconds: renderTimeViewClock,
                                }}
                              />
                            )}
                          />
                        </div>
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={4}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Controller
                          control={control}
                          name="endTime"
                          render={({ field: { onChange, value } }) => (
                            <TimePicker
                              sx={{ width: "56%" }}
                              label="End Time"
                              InputLabelProps={{ shrink: true }}
                              variant="outlined"
                              value={value || null}
                              onChange={(endTime) => {
                                onChange(endTime);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  disabled={!watch("startTime")}
                                />
                              )}
                              viewRenderers={{
                                hours: renderTimeViewClock,
                                minutes: renderTimeViewClock,
                                seconds: renderTimeViewClock,
                              }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={4}>
                      <div>
                        <img
                          src={selectedImage ? selectedImage : `${watch("imageUrl") || placeholder}?t=${new Date().getTime()}`} // Fetched image from backend with cache busting
                          height="100"
                          alt="eventform"
                          style={{
                            objectFit: "scale-down",
                            width: "200px",
                          }}
                        />
                        <TextField
                          sx={{ mt: 2 }}
                          InputLabelProps={{ shrink: true }}
                          inputProps={{ accept: "image/jpeg, image/png" }}
                          id="imageUrl"
                          label={
                            <Typography className="text-dark">
                              Image <span style={{ color: "red" }}>*</span>
                            </Typography>
                          }
                          style={{ display: 'none' }}
                          type="file"
                          {...register(`image`, {
                            required: { value: !watch(`imageUrl`), message: 'Please Select a Image' }, validate: (value) => {
                              const file = value[0];
                              if (!watch(`imageUrl`)) {
                                if (!file || !file.type.startsWith("image/")) {
                                  setValue(`imageUrl`, "")
                                  return "Please Select a Image File"
                                }
                                if (file && file.size > 2 * 1024 * 1024) {
                                  setValue(`imageUrl`, "")
                                  return "File size exceeds 2mb"
                                }
                                return true;
                              }
                            }, onChange: (e) => handleImageChange(e),
                          })}
                        />
                        <label
                          htmlFor="imageUrl"
                          style={{ marginLeft: "10px" }}
                        >
                          <Button
                            variant="contained"
                            className="menus-color"
                            component="span"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Choose File <span style={{ color: "white", paddingLeft: "5px" }}>*</span>
                          </Button>
                        </label>
                        {!!errors?.image && (
                          <div style={{ color: "red", marginTop: "10px" }}>
                            {errors?.image?.message}
                          </div>
                        )}
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </Box>
            )}
            {activeStep === 1 && (
              <Box>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    marginBottom: "20px",
                    gap: "30px",
                    marginLeft: "45px",
                  }}
                >
                  <FormControlLabel
                    control={<Checkbox checked={isVirtual} />}
                    {...register("isVirtual")}
                    label="Virtual Event"
                  />
                </div>
                {!isVirtual && (
                  <Grid container rowSpacing={3} columnSpacing={5}>
                    <Grid item xs={4}>
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        style={{ width: "100%" }}
                        label={
                          <Typography className="text-dark">
                            Place <span style={{ color: "red" }}>*</span>
                          </Typography>
                        }
                        {...register("place", {
                          required: "Please Enter a Place",
                        })}
                        error={!!errors?.place}
                        helperText={errors?.place?.message}
                        placeholder="Event Place"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        style={{ width: "100%" }}
                        label={
                          <Typography className="text-dark">
                            Name <span style={{ color: "red" }}>*</span>
                          </Typography>
                        }
                        {...register("locationName", {
                          required: "Please Enter a Location Name",
                        })}
                        error={!!errors?.locationName}
                        helperText={errors?.locationName?.message}
                        placeholder="Location Name"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        id="address"
                        style={{ backgroundColor: "#f0f0f0", width: "100%" }}
                        label={
                          <Typography className="text-dark">
                            Address <span style={{ color: "red" }}>*</span>
                          </Typography>
                        }
                        {...register("locationAddress", {
                          required: "Please Enter a Location Address",
                        })}
                        error={!!errors?.locationAddress}
                        helperText={errors?.locationAddress?.message}
                        placeholder="Address"
                        multiline
                      />
                    </Grid>
                  </Grid>
                )}
                <Grid
                  container
                  rowSpacing={3}
                  columnSpacing={5}
                  className="mt-3"
                >
                  {isVirtual && (
                    <Grid item xs={4}>
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        style={{ width: "100%" }}
                        disabled={!isVirtual}
                        label={
                          <Typography className="text-dark">
                            External Url <span style={{ color: "red" }}>*</span>
                          </Typography>
                        }
                        {...register("urlLink", {
                          required: "Please Provide a URL",
                        })}
                        placeholder="URL"
                        error={!!errors?.urlLink}
                        helperText={errors?.urlLink?.message}
                      />
                    </Grid>
                  )}
                </Grid>
              </Box>
            )}
            {activeStep === 2 && (
              <Box>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    marginBottom: "20px",
                  }}
                >
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    style={{ width: "30%" }}
                    type="text"
                    label={
                      <Typography className="text-dark">
                        Name <span style={{ color: "red" }}>*</span>
                      </Typography>
                    }
                    {...register("organizerName", {
                      required: "Please Enter Organizer Name",
                    })}
                    placeholder="Organizer Name"
                    error={!!errors?.organizerName}
                    helperText={errors?.organizerName?.message}
                  />
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    style={{ width: "30%" }}
                    label={
                      <Typography className="text-dark">
                        Description <span style={{ color: "red" }}>*</span>
                      </Typography>
                    }
                    {...register("organizerDescription", {
                      required: "Please Enter Description",
                    })}
                    placeholder="Organizer Description"
                    error={!!errors?.organizerDescription}
                    helperText={errors?.organizerDescription?.message}
                  />
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    style={{ width: "30%" }}
                    label={
                      <Typography className="text-dark">
                        Sponsor Description
                      </Typography>
                    }
                    {...register("sponsorDescription")}
                    placeholder="Sponsor Description"
                    error={!!errors?.sponsorDescription}
                    helperText={errors?.sponsorDescription?.message}
                  />
                </div>
              </Box>
            )}
            {activeStep === 3 && (
              <Box>
                {speakerFields.map((speaker, index) => (
                  <div key={speaker.id}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        marginBottom: "20px",
                        marginTop: "20px",
                      }}
                    >
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        style={{ width: "17%" }}
                        type="text"
                        label={
                          <Typography className="text-dark">
                            Name <span style={{ color: "red" }}>*</span>
                          </Typography>
                        }
                        {...register(`speakers.${index}.speakerName`, {
                          required: "Please Enter Speaker Name",
                        })}
                        placeholder="Speaker Name"
                        error={!!errors?.speakers?.[index]?.speakerName}
                        helperText={
                          errors?.speakers?.[index]?.speakerName?.message
                        }
                      />
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        style={{ width: "17%" }}
                        label={
                          <Typography className="text-dark">
                            Role <span style={{ color: "red" }}>*</span>
                          </Typography>
                        }
                        {...register(`speakers.${index}.file`, {
                          required: "Please Enter a Role",
                        })}
                        placeholder="Role"
                        error={!!errors?.speakers?.[index]?.file}
                        helperText={errors?.speakers?.[index]?.file?.message}
                      />
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        style={{ width: "17%" }}
                        label={
                          <Typography className="text-dark">
                            Description <span style={{ color: "red" }}>*</span>
                          </Typography>
                        }
                        {...register(`speakers.${index}.speakerDescription`, {
                          required: "Please Enter Description",
                        })}
                        placeholder="Speaker Description"
                        error={!!errors?.speakers?.[index]?.speakerDescription}
                        helperText={
                          errors?.speakers?.[index]?.speakerDescription?.message
                        }
                      />
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        style={{ width: "17%" }}
                        type="number"
                        label={
                          <Typography className="text-dark">
                            Position <span style={{ color: "red" }}>*</span>
                          </Typography>
                        }
                        {...register(`speakers.${index}.speakerPosition`, {
                          required: "Please Select a Postion",
                          min: {
                            value: 1,
                            message: "Please Enter a Valid Position",
                          },
                        })}
                        placeholder="Speaker Position"
                        error={!!errors?.speakers?.[index]?.speakerPosition}
                        helperText={
                          errors?.speakers?.[index]?.speakerPosition?.message
                        }
                      />
                      <Grid item xs={4}>
                        <div>
                          <img
                            src={selectedSpeakerImage[index] || watch(`speakers.${index}.speakerImageUrl`) || placeholder}
                            height="100"
                            alt="eventform"
                            style={{
                              objectFit: "scale-down",
                              width: "200px",
                            }}
                          />
                          <TextField
                            sx={{ mt: 2 }}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ accept: "image/jpeg, image/png" }}
                            id={`${'speakerImage' + index}`}
                            label={
                              <Typography className="text-dark">
                                Image <span style={{ color: "red" }}>*</span>
                              </Typography>
                            }
                            style={{ display: 'none' }}
                            type="file"
                            {...register(`speakers.${index}.speakerImage`, {
                              required: { value: !watch(`speakers.${index}.speakerImageUrl`), message: 'Please Select a Image' }, validate: (value) => {
                                const file = value[0];
                                if (!watch(`speakers.${index}.speakerImageUrl`)) {
                                  if (!file || !file.type.startsWith("image/")) {
                                    setValue(`speakers.${index}.speakerImageUrl`, "")
                                    return "Please Select a Image File"
                                  }
                                  if (file && file.size > 2 * 1024 * 1024) {
                                    setValue(`speakers.${index}.speakerImageUrl`, "")
                                    return "File Size Exceeds 2mb"
                                  }
                                }
                                return true;
                              }, onChange: (e) => handleSpeakerImageChange(e, index),
                            })}
                          />
                          <label
                            htmlFor={`${'speakerImage' + index}`}
                            style={{ marginLeft: "10px" }}
                          >
                            <Button
                              variant="contained"
                              className="menus-color"
                              component="span"
                              style={{ whiteSpace: "nowrap" }}
                            >
                              Choose File <span style={{ color: "white", paddingLeft: "5px" }}>*</span>
                            </Button>
                          </label>
                          {!!errors?.speakers?.[index]?.speakerImage && (
                            <div style={{ color: "red", marginTop: "10px" }}>
                              {errors?.speakers?.[index]?.speakerImage?.message}
                            </div>
                          )}

                        </div>
                      </Grid>
                      {index !== 0 && (
                        <CancelIcon
                          style={{ cursor: "pointer", color: "red" }}
                          className="mt-3"
                          onClick={() => removeField(index)}
                        />
                      )}
                    </div>
                  </div>
                ))}
                <Button
                  type="button"
                  onClick={() => appendSpeaker({})}
                  variant="contained"
                  className="menus-color"
                  sx={{ ml: 5 }}
                >
                  Add
                </Button>
              </Box>
            )}
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2, mt: 3 }}>
              {activeStep !== 0 && (
                <Button
                  variant="contained"
                  className="menus-color"
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                >
                  Back
                </Button>
              )}
              <Box sx={{ flex: "1 1 auto" }} />
              <Button type="submit" variant="contained" className="menus-color" disabled={!isValid} 
              style={{
                cursor: !isValid ? 'not-allowed' : 'pointer',
                color: "white"
              }} >
                {activeStep === steps.length - 1
                  ? eventId
                    ? "Update"
                    : "Submit"
                  : "Next"}
              </Button>
            </Box>
          </form>
        </Box>
      )}
    </>
  );
}

export default EventForm;
