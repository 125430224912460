import { api } from "./api";
 
const forumPdfServices = api.injectEndpoints({
  tagTypes:['ForumPdfServices'],
  endpoints: (builder) => ({

    //savePDF Foruum
    savePdfForum: builder.mutation({
      query: (newForumPdfData) => ({
        url: "forumPdf/saveForumPdf",
        method: "POST",
        body: newForumPdfData,
      }),
      invalidatesTags: ["ForumPdfServices"],
    }),

    //getAllPDFForum
    getAllPdfForum: builder.query({
      query: () => ({
        url: "forumPdf/getall",
      }),
      invalidatesTags: ["ForumPdfServices"],
    }),
    
    //getPDFForumBYid
    getPdfForumById: builder.query({
      query: (id) => `forumPdf/get/${id}`,
    }),

    //UpdatePDF Forum
    updatePdfForum: builder.mutation({
      query: ({ id, data }) => ({
        url: `forumPdf/update/${id}`,
        method: "PUT",
        headers: { "Content-Type": "multipart/form-data" },
        body: data,
      }),
      invalidatesTags: ["ForumPdfServices"],
    }),

    //deletepdf forum
    deletePdfForum: builder.mutation({
      query: (data) => ({
        url: `forumPdf/deleteUploadPdf`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["ForumPdfServices"],
    }),
  }),
  overrideExisting: false,
});
 
export const {
  useSavePdfForumMutation,
  useGetAllPdfForumQuery,
  useLazyGetPdfForumByIdQuery,
  useUpdatePdfForumMutation,
  useDeletePdfForumMutation,
} = forumPdfServices;