import { Box, Button, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import TopHeader from "../../Component/Header/TopHeader";
import {
  useSaveTestimonialMutation,
  useLazyGetTestimonialByIdQuery,
  useUpdateTestimonialMutation,
} from "../../app/services/testimonialService";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Spinner from "../../Component/spinner/spinner";
import Grid from "@mui/material/Grid";
import userlogo from "../../assets/images/login/userLogo.png";

const AddEditTestimonals = () => {
  const [loading, setLoading] = useState(false);
  const {
    register,
    setValue,
    handleSubmit,
    formState: { isValid, errors },
    reset,
    getValues,
    watch
  } = useForm();
  const [saveTestimonial] = useSaveTestimonialMutation();
  const [updateTestimonial] = useUpdateTestimonialMutation();
  const [getTestimonialById] = useLazyGetTestimonialByIdQuery();
  const { testimonialId } = useParams();
  const navigate = useNavigate();
  const [selectedImage, setSelectedImage] = useState();
  const [imageErrorMessage, setImageErrorMessage] = useState("");
  const [isImageError, setIsImageError] = useState(false);

  //get Testimonial By Id 
  useEffect(() => {
    if (testimonialId) {
      setLoading(true);
      getTestimonialById(testimonialId).then((res) => {
        if (res.data?.status) {
          setLoading(false);
          const data = res.data?.data;
          if (data.imageUrl.length > 0) {
            // Add a timestamp to the URL to force reload (cache-busting)
            const cacheBustedUrl = `${data.imageUrl}?t=${new Date().getTime()}`;
            setValue("imageUrl", cacheBustedUrl);
          } else {
            setValue("imageUrl", "");
          }
          setValue("content", data.content);
          setValue("name", data.name);
        }
      });
    }
  }, [testimonialId, getTestimonialById, setValue]);

  //submit& update
  const onFormSubmit = (formData) => {
    if (isImageError) {
      return;
    }
    if (isValid) {
      setLoading(true);
      if (testimonialId) {
        let updateData;
        const base64Regex = /^data:image\/([a-zA-Z]*);base64,([^"]*)$/;
        if (base64Regex.test(formData.imageUrl)) {
          updateData = { id: testimonialId, formData };
        } else {
          updateData = {
            id: testimonialId,
            formData: { content: formData.content, name: formData.name, imageUrl: formData.imageUrl },
          };
        }
        updateTestimonial(updateData).then((res) => {
          if (res.data?.status) {
            toast.success("Testimonial Updated Successfully!!!");
            setLoading(false);
            navigate("/admin/viewTestimonials");
            reset();
          } else {
            toast.error(res.data?.err?.message);
          }
        });
      } else {
        saveTestimonial(formData).then((res) => {
          if (res.data?.status) {
            toast.success("Testimonial Added Successfully!!!");
            setLoading(false);
            navigate("/admin/viewTestimonials");
            reset();
          } else {
            toast.error(res.data?.err?.message);
          }
        });
      }
    }
  };

  //handleImageChange
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (!file) {
      setValue("imageUrl", "");
      setImageErrorMessage("");
      setSelectedImage(null);
      setIsImageError(false);
      return;
    }
    if (!file.type.startsWith("image/")) {
      setImageErrorMessage("Please select a image file.");
      setSelectedImage(null);
      setIsImageError(true);
      return;
    }
    if (file.size > 2 * 1024 * 1024) {
      setImageErrorMessage("File size exceeds 2 MB.");
      setSelectedImage(null);
      setIsImageError(true);
      return;
    } else {
      setImageErrorMessage("");
      setIsImageError(false);
      const reader = new FileReader();
      reader.onload = (e) => {
        let base64String = e.target.result;
        setSelectedImage(reader.result);
        setValue("imageUrl", base64String);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      <TopHeader />
      <div className='pt-1 '>
        <div className='p-0'>
          <div className=' Architects-header d-flex align-items-center justify-content-center '>
            <h2 className='signika-fontOEC text-center '>{testimonialId ? "Edit Testimonial" : "Add Testimonial"}</h2>
          </div>
        </div>
      </div>
      <div className="container mt-5">
        <div className="row">
          <div className="col-md-8">
            <span className="ms-1" style={{ width: '190px' }}></span>
          </div>
          <div className="col-md-4">
            <Button
              onClick={() => window.history.back()}
              variant="contained"
              className="menus-color"
              style={{ float: "right" }}
            >
              Back
            </Button>
          </div>
        </div>
      </div>
      {loading ? (
        <Spinner />
      ) : (
        <Box
          component={"form"}
          sx={{ padding: "10px", marginTop: 5 }}
          className="d-flex flex-column align-items-center"
          onSubmit={handleSubmit(onFormSubmit)}
        >
          <div className=" mt-4">
            <Grid item xs={4}>
              {testimonialId ? (
                <>
                  <div className="row">
                    <div className="col-md-6">
                      <img
                        src={selectedImage || getValues("imageUrl") || userlogo}
                        height="180"
                        alt="logo"
                      />
                    </div>
                    <div className="col-md-6">
                      <div className="row">
                        <TextField
                          InputLabelProps={{ shrink: "true" }}
                          id="name"
                          label={
                            <Typography className="text-dark">
                              Name <span style={{ color: "red" }}>*</span>
                            </Typography>
                          }
                          placeholder="Please Enter Your Name"
                          style={{ height: "max-content" }}
                          {...register("name", {
                            required: "Please Enter Your Name",
                          })}
                          className="form-control"
                          error={!!errors?.name}
                          helperText={<span className="error-message">{errors?.name?.message}</span>}
                        ></TextField>
                      </div>
                      <div className="row mt-2 position-relative">
                        <span style={{ left: '91%', top: '65%' }} class="position-absolute fs-5 badge translate-middle text-primary">
                          {150 - watch('content')?.length}
                          <span class="visually-hidden">Content length</span>
                        </span>
                        <textarea
                          maxLength={150}
                          style={{
                            border: !!errors?.content
                              ? "2px solid red"
                              : "2px solid lightgrey",
                          }}
                          cols="50"
                          rows="5"
                          id="message"
                          {...register("content", {
                            required: "Please Enter Your Review",
                          })}
                          placeholder="Please Enter Your Review"
                          className="form-control"
                        >
                        </textarea>
                        <p className="error-message">
                          {errors?.content?.message}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-5 d-flex justify-content-center">
                      <input
                        accept="image/jpeg, image/png"
                        id="imageUrl"
                        type="file"
                        style={{ display: "none" }}
                        onChange={handleImageChange}
                      />
                      <label htmlFor="imageUrl" style={{ marginLeft: "10px" }}>
                        <Button
                          variant="contained"
                          className="menus-color"
                          component="span"
                          style={{ whiteSpace: "nowrap" }}
                        >
                          Choose File
                        </Button>
                      </label>
                    </div>
                    {imageErrorMessage && (
                      <div style={{ color: "red", marginTop: "10px" }}>
                        {imageErrorMessage}
                      </div>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="row">
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          style={{
                            width: "100%",
                          }}
                          id="imageUrl"
                          label="User Image"
                          variant="outlined"
                          type="file"
                          placeholder="Please Select a image"
                          onChange={handleImageChange}
                          error={imageErrorMessage}
                          helperText={imageErrorMessage}
                        />
                      </div>
                      <div className="row mt-4">
                        <TextField
                          InputLabelProps={{ shrink: "true" }}
                          id="name"
                          label={
                            <Typography className="text-dark">
                              Name <span style={{ color: "red" }}>*</span>
                            </Typography>
                          }
                          placeholder="Please Enter Your Name"
                          style={{ height: "max-content" }}
                          {...register("name", {
                            required: "Please Enter Your Name",
                          })}
                          className="form-control"
                          error={!!errors?.name}
                          helperText={<span className="error-message">{errors?.name?.message}</span>}
                        ></TextField>
                      </div>
                    </div>
                    <div className="col-md-6 position-relative">
                      <span style={{ left: '91%', top: '68%' }} class="position-absolute fs-5 badge translate-middle text-primary">
                        {150 - (watch('content')?.length != null || 'NAN' || undefined) ? 150 - watch('content')?.length : 150}
                        <span class="visually-hidden">Content length</span>
                      </span>
                      <textarea
                        style={{
                          border: !!errors?.content
                            ? "2px solid red"
                            : "2px solid lightgrey",
                        }}
                        cols="50"
                        rows="5"
                        id="message"
                        {...register("content", {
                          required: "Please Enter Your Review",
                        })}
                        placeholder="Please Enter Your Review"
                        className="form-control"
                        maxLength={150}
                      ></textarea>
                      <p className="error-message">{errors?.content?.message}</p>
                    </div>
                  </div>
                </>
              )}
            </Grid>
          </div>
          <>
            <Button variant="contained" className="menus-color" type="submit">
              {testimonialId ? "Update" : "Submit"}
            </Button>
          </>
        </Box>
      )}
    </>
  );
};

export default AddEditTestimonals;
