import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const ScrolToTop = () => {
    const {pathname}=useLocation()
    
    //scrolling
    useEffect(()=>{
    window.scrollTo(0,0)
    },[pathname])
    
  return (
    null
  )
}

export default ScrolToTop