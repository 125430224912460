import React,{useState,useEffect} from 'react'
import TopHeader from "../../Component/Header/TopHeader";
import { Box } from "@material-ui/core";
import {
  Container, Button, TextField, Typography} from '@mui/material';
import {selectCurrenSigRole,setSigRoleDetails } from '../../app/features/sigSlice';
import { useUpdateSIGGroupProfileMutation} from '../../app/services/SIGGroupServices'
import { useSelector } from 'react-redux';
import { useForm,} from "react-hook-form";
import { toast } from "react-toastify"; 
import placeholder from '../../../src/assets/images/Profile/profile.jpg'

const SigAdminProfile = () => {
  const sigRoleDetails = useSelector(selectCurrenSigRole);
  const imgSig=useSelector(setSigRoleDetails)
  const {  
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch
  } = useForm()
  const [selectedImage, setSelectedImage] = useState(null);
 const [updateSigProfile]=useUpdateSIGGroupProfileMutation()

//patch value  Sig details By id
  useEffect(()=>{ 
    setValue('imageUrl',imgSig?.payload?.auth?.imageUrl)
    Object.entries(sigRoleDetails).forEach(([key,value])=>{
      setValue(key,value)
    })
  },[setValue,imgSig?.payload?.auth?.imageUrl,sigRoleDetails])
const sigAdminUpdateProfile=(data)=>{
   const base64Regex = /^data:image\/(?:png|jpeg|jpg|gif);base64,[\w+/=]+$/;
  const updateData = {
    id: imgSig?.payload?.sig?.sigDetails?._id,
    formData: {
      name:data.name,
      email:data.email,
      address:data.address,
      mobile:data.mobile,
      imageUrl: base64Regex.test(data?.imageUrl)
        ? data?.imageUrl
        : data?.imageUrl.split("/").slice(-2).join("/"),
        role : "PORTAL_SIG_ADMIN",
        roleId: imgSig?.payload?.auth?.roleId,
        adminId: imgSig?.payload?.auth?.memberId,
    },
  };

  //updateSig profile
  updateSigProfile(updateData).then((res)=>{
    if (res?.data?.status) {
      toast.success(res?.data?.message);
      navigator('sigAdmin/sigDetails')
    }

  }).catch((err)=>{
    console.error(err)
  })
}
//image Upload
const handleImageChange = (event) => {
  const file = event.target.files[0];
  if (!file || !file.type.startsWith("image/")) {
    setSelectedImage(null);
    setValue(`imageUrl`, "")
    return;
  }
  if (file && file.size > 2 * 1024 * 1024) {
    setSelectedImage(null);
    setValue(`imageUrl`, "")
    return;
  }
  if (file) {
    const reader = new FileReader();
    reader.onload = (e) => {
      let base64String = e.target.result;
      setSelectedImage(reader.result);
      setValue(`imageUrl`, base64String);
    };
    reader.readAsDataURL(file);

  }
};

  return (
    <div>
        <TopHeader />
      <Box className="banner_text"></Box>
      <Container maxWidth="xl">
        <div className='d-flex justify-content-between'>
          <div>
            <h3 className="heading_h3_bearers mt-4 "> SIG Profile</h3>
            <span
              className="animate-border-bearers border-black mb-5"
              style={{ width: '140px' }}

            ></span>
          </div>
          <div>
            <Button
              onClick={() => window.history.back()}
              variant="contained"
              className="menus-color mt-4"
              style={{ float: "right" }}
            >
              Back
            </Button>
          </div>
        </div>
      </Container>
      <Container maxWidth="xl"className='mb-3'>
    <form onSubmit={handleSubmit(sigAdminUpdateProfile)}>
    <div className='row mb-3'>
              <div class="d-flex justify-content-center">
                <div>
                  <img
                    src={selectedImage || watch("imageUrl") || placeholder}
                    height="100"
                    alt="eventform"
                    style={{
                      objectFit: "scale-down",
                      width: "200px",
                    }}
                  />
                  <TextField
                    sx={{ mt: 2 }}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ accept: "image/jpeg, image/png" }}
                    id="imageUrl"
                    label={
                      <Typography className="text-dark">
                        Image <span style={{ color: "red" }}>*</span>
                      </Typography>
                    }
                    style={{ display: 'none' }}
                    type="file"
                    {...register(`image`, {
                      required: { value: !watch(`imageUrl`), message: 'Please Select a image' }, validate: (value) => {
                        const file = value[0];
                        if (!watch(`imageUrl`)) {
                          if (!file || !file.type.startsWith("image/")) {
                            setValue(`imageUrl`, "")
                            return "Please Select a image file"
                          }
                          if (file && file.size > 2 * 1024 * 1024) {
                            setValue(`imageUrl`, "")
                            return "File size exceeds 2mb"
                          }
                        }
                        return true;
                      }, onChange: (e) => handleImageChange(e),
                    })}
                  />
                  <label
                    htmlFor="imageUrl"
                    style={{ marginLeft: "10px" }}
                  >
                    <Button
                      variant="contained"
                      className="menus-color"
                      component="span"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      Choose File <span style={{ color: "white", paddingLeft: "5px" }}>*</span>
                    </Button>
                  </label>
                  {!!errors?.image && (
                    <div style={{ color: "red", marginTop: "10px" }}>
                      {errors?.image?.message}
                    </div>
                  )}
                </div>
              </div>
            </div>   
      <div className='row'>
        <div className='col-md-4'>
        <TextField
                  InputLabelProps={{ shrink: true }}
                  style={{ width: "100%" }}
                  id="headline"
                  label={
                    <Typography className="text-dark">
                       Name <span style={{ color: "red" }}>*</span>
                    </Typography>
                  }
                  variant="outlined"
                  {...register("name", {
                    required: "Please Enter  Name",
                  })}
                  error={!!errors?.name}
                  helperText={errors?.name?.message}
                />
          <TextField className='mt-3'
                  InputLabelProps={{ shrink: true }}
                  style={{ width: "100%" }}
                  id="headline"
                  label={
                    <Typography className="text-dark">
                      Address <span style={{ color: "red" }}>*</span>
                    </Typography>
                  }
                  variant="outlined"
                  {...register("address", {
                    required: "Please Enter Address",
                  })}
                  error={!!errors?.address}
                  helperText={errors?.address?.message}
                />
        </div>
        <div className='col-md-4'>
        <TextField
                  style={{ width: "100%", }}
                  InputLabelProps={{ shrink: true }}
                  id="title"
                  label={
                    <Typography className="text-dark">
                      Email <span style={{ color: "red" }}>*</span>
                    </Typography>
                  }
                  variant="outlined"
                  disabled
                  {...register("email", {
                    required: "Please Enter a email",
                    pattern: {
                      value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                      message: "Please Enter a valid Email",
                    },
                  })}
                  error={!!errors?.email}
                  helperText={errors?.email?.message}
                />
        </div>
        <div className='col-md-4'>
        <TextField
                  InputLabelProps={{ shrink: true }}
                  style={{ width: "100%" }}
                  id="headline"
                  label={
                    <Typography className="text-dark">
                      Mobile <span style={{ color: "red" }}>*</span>
                    </Typography>
                  }
                  variant="outlined"
                  {...register("mobile", {
                    required: "Please Enter Mobile",
                  })}
                  error={!!errors?.mobile}
                  helperText={errors?.mobile?.message}
                />
        </div>
      </div>
      <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 5,
              }}
            >
              <Button
                variant="contained"
                className="menus-color"
                type="submit"
                sx={{ mr: 3 }}
              >
                Update
              </Button>
            </div>
      </form>
    </Container>
    </div>
  )
}

export default SigAdminProfile