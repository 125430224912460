import {
  Autocomplete,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import {
  Box,
  Button,
  FormControlLabel,
  InputLabel,
  MenuItem,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm, Controller, } from "react-hook-form";
import Select from "@mui/material/Select";
import "../../../style/css/eventForm.css";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import FormControl from "@mui/material/FormControl";
import { useGetAllSIGGroupQuery } from "../../../app/services/SIGGroupServices";
import { useNavigate } from "react-router-dom";
import { useLazyGetAllChaptersQuery } from "../../../app/services/chapterServices";
import Grid from "@mui/material/Grid";
import { toast } from "react-toastify";
import Spinner from "../../../Component/spinner/spinner";
import { Qualifications, Salutation, } from '../../../constant/constant'
import { Country, State } from 'country-state-city';
import { useLazyGetAllMemberShipDetailsQuery, } from '../../../app/services/memberShipServices'
import { useCreateMemberMutation, useLazyGetMemberDetailsByIdQuery } from '../../../app/services/memberServices'

const steps = [
  "Personal Information",
  "Payment Information",
];

function AddEditStudentMember() {
  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [allSIG, setAllSIG] = React.useState([]);
  const [selectedChapters, setSelectedChapters] = useState([]);
  const { refetch: refetchSIG } = useGetAllSIGGroupQuery();
  const [getAllChapters] = useLazyGetAllChaptersQuery();
  const navigator = useNavigate()
  const [getAllplans] = useLazyGetAllMemberShipDetailsQuery()
  const [planName, setPlanName] = useState([])
  const [gettMemberDetailsByIdQuery] = useLazyGetMemberDetailsByIdQuery();
  const [showStudyingOtherInput, setShowStudyingOtherInput] = useState(false);
  const [otherStudying, setOtherStudying] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [costType, setCostType] = useState([]);
  const [amount, setAmount] = useState()
  const [currency, setCurrency] = useState()
  const [createMember] = useCreateMemberMutation()
  const countries = Country.getAllCountries();
  const states = selectedCountry ? State.getStatesOfCountry(selectedCountry) : [];

  // useForm hook for form data handling
  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      type: '1',
      salutation: Salutation[0].id,
      fullName: "",
      dateOfBirth: "",
      gender: '',
      city: "",
      pinCode: "",
      email: "",
      state: "",
      country: "",
      uploadStudentPDF: '',
      mobileNumber: '',
      landlineNumber: '',
      whatsAppNumber: '',
      course: '',
      institution: '',
      studying: '',
      otherStudying: "",
      institutionCity: '',
      role: "PORTAL_MEMBER",
    },
  });

  const selectedPlanName = watch("dummyPlanId");
  const selectedplantypeId = watch("dummyPlanTypeId")

  useEffect(() => {
    if (selectedPlanName) {
      const foundPlan = planName.find((plan) => plan._id === selectedPlanName);
      setCostType(foundPlan?.planType || []);
    }
  }, [selectedPlanName, planName]);

  useEffect(() => {
    costType.forEach((data) => {
      if (data._id === selectedplantypeId) {
        setAmount(data.amount)
        setCurrency(data.currencyType)
      }
    })
  }, [costType, selectedplantypeId])

  useEffect(() => {
    if (amount && currency) {
      setValue("dummyAmount", amount)
      setValue("dummyCurrency", currency)
    }
  }, [setValue, amount, currency])

  useEffect(() => {
    getAllplans().then((res) => {
      if (res?.data?.status) {
        const filteredPlan = res?.data?.data?.filter(item => item.isDeleted === 0)
        setPlanName(filteredPlan)
      }
    })
  }, [getAllplans])

  useEffect(() => {
  }, [planName])

  //On submit for save
  const AddEditStudentMember = (data) => {
    if (activeStep === steps.length - 1) {
      setLoading(false)
      const formData = new FormData();
      Object.entries(data).forEach(([key, value]) => {
        if (value instanceof FileList) {
          formData.append(key, value[0]);
        } else if (key !== 'sigGroups' && key !== "dummyAmount" && key !== "dummyCurrency" && key !== "studying" && key !== "otherStudying") {
          formData.append(key, value);
        }
      });
      if (data.sigGroups && data.sigGroups.length > 0) {
        data.sigGroups.forEach(item => {
          formData.append("sigGroups", item)
        })
      }
      formData.append("dummyAmount", amount)
      formData.append("dummyCurrency", currency)

      // Handle studying and otherStudying
      const { studying } = data;
      formData.append("studying", studying);

      if (showStudyingOtherInput && otherStudying) {
        formData.append("otherStudying", otherStudying);
      } else {
        formData.append("otherStudying", "");
      }

      createMember(formData).then((res) => {
        if (res?.data?.data) {
          var options = {
            key: process.env.REACT_APP_RAZOR_PAY_KEY_ID, // Enter the Key ID generated from the Dashboard
            amount: amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
            currency: (amount === 0 ? "INR" : "USD"),
            name: res?.data?.data?.fullName,
            description: "Membership payment",
            order_id: res?.data?.data?.purchaseData?.orderId, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
            handler: function (response) {
              gettMemberDetailsByIdQuery(res?.data?.data?._id).then((res) => {
                if (res.data?.status) {
                  const isPaid = res.data?.data?.plans[0]?.isPaid === 1;
                  setValue("paymentStatus", isPaid);
                  toast.success("Member Saved Successfully");
                  navigator('/login')
                }
              });
            },
          };
          var rzp1 = new window.Razorpay(options);
          rzp1.open();
          rzp1.on("payment.failed", function (response) {
            alert("Error in Payment", response.error);
            toast.error("Member Created Failed");
          });
        }
        else {
          toast.error(res.error.data.error);
        }
      })
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  //Get all chapter
  React.useEffect(() => {
    refetchSIG().then((res) => {
      setAllSIG(res?.data?.data || []);
    });
    getAllChapters().then((res) => {
      setSelectedChapters(res?.data?.data || []);
    });

  }, [
    setValue,
    getAllChapters,
    refetchSIG,
  ]);

  //handle Back
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const validateFile = (file) => {
    const allowedTypes = ['application/pdf'];
    const maxSize = 5 * 1024 * 1024; // 5 MB size limit
    if (file.length === 0) {
      return "File is required!";
    }
    if (!allowedTypes.includes(file[0].type)) {
      return "Only PDFs are allowed!";
    }
    if (file[0].size > maxSize) {
      return "File size should not exceed 5MB!";
    }
    return true;
  };

  return (
    <>
      <Box>
        <div className='pt-1  pb-3'>
          <div className='p-0'>
            <div className=' Architects-header d-flex align-items-center justify-content-center '>
              <h2 className='signika-fontOEC text-center '>Individual - Student</h2>
            </div>
          </div>
        </div>
      </Box>
      <div className="container mt-3">
        <div className="row">
          <div className="col-md-8">
          </div>
          <div className="col-md-4 mt-3">
            <Button
              onClick={() => window.history.back()}
              variant="contained"
              className="menus-color"
              style={{ float: "right", marginRight: 58 }}
            >
              Back
            </Button>
          </div>
        </div>
      </div>
      {loading ? (
        <Spinner />
      ) : (
        <Box
          className="container"
          sx={{ width: "100%", marginTop: "50px", marginBottom: "50px" }}
        >
          <Stepper activeStep={activeStep} className="mt-5">
            {steps.map((label, index) => {
              return (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <form className="mt-5" onSubmit={handleSubmit(AddEditStudentMember)}>
            {activeStep === 0 && (
              <Box>
                <div style={{ paddingLeft: 40, paddingRight: 40 }}>
                  <Grid container rowSpacing={3} columnSpacing={5}>
                    <Grid item xs={12}>
                    </Grid>
                    <Grid item xs={4}>
                      <Controller control={control} rules={{ required: "Please Select an Option", }} name="salutation" render={({ field, fieldState }) => (
                        <FormControl fullWidth>
                          <InputLabel id="Salutation">
                            Salutation
                            <span style={{ color: "red" }}>*</span>
                          </InputLabel>
                          <Select
                            style={{ backgroundColor: "#f0f0f0" }}
                            labelId="Salutation"
                            id="Salutation"
                            label={
                              <Typography className="text-dark">
                                Salutation <span style={{ color: "red" }}>*</span>
                              </Typography>
                            }
                            value={field.value ? field.value : ""}
                            variant="outlined"
                            onChange={(e) => field.onChange(e.target.value)}
                            error={!!fieldState.error}
                          >
                            {Salutation.map((data, index) => (
                              index < 3 ? <MenuItem key={data.Name} value={data.id}>
                                {data.Name}
                              </MenuItem> : <></>
                            ))}
                          </Select>
                        </FormControl>
                      )} />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        label={
                          <Typography className="text-dark">
                            Your Full Name <span style={{ color: "red" }}>*</span>
                          </Typography>
                        }
                        {...register("fullName", {
                          required: "Please Enter a Full Name", minLength: {
                            value: 3,
                            message: 'Please Enter a Valid Name'
                          }
                        })}
                        error={!!errors?.fullName}
                        helperText={errors?.fullName?.message}
                        placeholder="Your Full Name"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        label={
                          <Typography className="text-dark">
                            Your Date of Birth <span style={{ color: "red" }}>*</span>
                          </Typography>
                        }
                        variant="outlined"
                        type="date"
                        value={watch("dateOfBirth") ? watch("dateOfBirth").split("T")[0] : ""}
                        {...register("dateOfBirth", {
                          required: "Please Select a Date Of Birth", max: {
                            value: new Date().toISOString().split('T')[0],
                            message: 'Future date is not allowed'
                          }
                        })}
                        error={!!errors?.dateOfBirth}
                        helperText={errors?.dateOfBirth?.message}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl component="fieldset">
                        <FormLabel component="legend">Your Gender <span style={{ color: "red" }}>*</span></FormLabel>
                        <Controller
                          name="gender"
                          control={control}
                          defaultValue=""
                          rules={{ required: "Please Select a Gender" }}
                          render={({ field }) => (
                            <RadioGroup row {...field}>
                              <FormControlLabel
                                value="0"
                                control={<Radio />}
                                label="Male"
                              />
                              <FormControlLabel
                                value="1"
                                control={<Radio />}
                                label="Female"
                              />
                              <FormControlLabel
                                value="3"
                                control={<Radio />}
                                label="Transgender"
                              />
                            </RadioGroup>
                          )}
                        />
                        {errors.gender && (
                          <FormHelperText className="text-danger">{errors.gender.message}</FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        label={
                          <Typography className="text-dark">
                            Your Address (for Communication) <span style={{ color: "red" }}>*</span>
                          </Typography>
                        }
                        {...register("address", {
                          required: "Please Enter a Address",
                        })}
                        error={!!errors?.address}
                        helperText={errors?.address?.message}
                        placeholder="Address"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        label={
                          <Typography className="text-dark">
                            Your City/Town <span style={{ color: "red" }}>*</span>
                          </Typography>
                        }
                        {...register("city", {
                          required: "Please Enter a City/Town",
                        })}
                        error={!!errors?.city}
                        helperText={errors?.city?.message}
                        placeholder="Your City/Town"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        label={
                          <Typography className="text-dark">
                            Pin Code <span style={{ color: "red" }}>*</span>
                          </Typography>
                        }
                        {...register("pinCode", {
                          required: "Please Enter a Pin Code", minLength: {
                            value: 6,
                            message: "Pin Code must be 6 digits",
                          }, maxLength: {
                            value: 6,
                            message: "Pin Code must be 6 digits"
                          }
                        })}
                        error={!!errors?.pinCode}
                        helperText={errors?.pinCode?.message}
                        placeholder="Pin Code"
                      />
                    </Grid>
                    <Grid item xs={4} >
                      <FormControl fullWidth className="mt-0" error={!!errors.country}>
                        <InputLabel >Country <span style={{ color: "red" }}>*</span></InputLabel>
                        <Controller
                          name="country"
                          control={control}
                          defaultValue=""
                          rules={{
                            required: "Country is Required", // Validation rule
                          }}
                          render={({ field }) => (
                            <Select
                              {...field}
                              value={selectedCountry}
                              onChange={(e) => {
                                setSelectedCountry(e.target.value);
                                field.onChange(e);
                              }}
                              label={
                                <Typography className="text-dark">
                                  Country <span style={{ color: "red" }}>*</span>
                                </Typography>
                              }
                            >
                              <MenuItem value="">
                                <em>Select Country</em>
                              </MenuItem>
                              {countries.map((country) => (
                                <MenuItem key={country.isoCode} value={country.isoCode}>
                                  {country.name}
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                        />
                        {errors.country && (
                          <FormHelperText className="text-danger">{errors.country.message}</FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl fullWidth className="mt-0" disabled={!selectedCountry} error={!!errors?.state}>
                        <InputLabel>State <span style={{ color: "red" }}>*</span></InputLabel>
                        <Controller
                          name="state"
                          control={control}
                          defaultValue=""
                          rules={{
                            required: selectedCountry ? { value: true, message: "State is Required" } : "Please Select Country", // Only required if a country is selected
                          }}
                          render={({ field }) => (
                            <Select
                              {...field}
                              value={selectedState}
                              onChange={(e) => {
                                setSelectedState(e.target.value);
                                field.onChange(e);
                              }}
                              label={
                                <Typography className="text-dark">
                                  State <span style={{ color: "red" }}>*</span>
                                </Typography>
                              }
                              error={!!errors?.state}
                            >
                              <MenuItem value="">
                                <em>Select State</em>
                              </MenuItem>
                              {states.map((state) => (
                                <MenuItem key={state.isoCode} value={state.name}>
                                  {state.name}
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                        />
                        {errors.state && (
                          <FormHelperText>{errors.state.message}</FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ type: 'number' }}
                        fullWidth
                        label={
                          <Typography className="text-dark">
                            Mobile Number <span style={{ color: "red" }}>*</span>
                          </Typography>
                        }
                        {...register("mobileNumber", {
                          required: "Please Enter a Mobile Number ",
                          pattern: {
                            value: /^\d{10}$/,
                            message: 'Mobile Number must be 10 digits'
                          }
                        })}
                        error={!!errors?.mobileNumber}
                        helperText={errors?.mobileNumber?.message}
                        placeholder=" Mobile Number"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        inputProps={{ type: 'number' }}
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        label={
                          <Typography className="text-dark">
                            WhatsApp Number <span style={{ color: "red" }}>*</span>
                          </Typography>
                        }
                        {...register("whatsAppNumber", {
                          required: "Please Enter a WhatsApp Number",
                          pattern: {
                            value: /^\d{10}$/,
                            message: 'Mobile Number must be 10 digits'
                          }
                        })}
                        error={!!errors?.whatsAppNumber}
                        helperText={errors?.whatsAppNumber?.message}
                        placeholder="whatsApp Number"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        label={
                          <Typography className="text-dark">
                            Email Address <span style={{ color: "red" }}>*</span>
                          </Typography>
                        }
                        {...register("email", {
                          required: "Please Enter a Email Address",
                          pattern: {
                            value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                            message: 'Enter a Valid Email ID'
                          }
                        })}
                        error={!!errors?.email}
                        helperText={errors?.email?.message}
                        placeholder="Email Address"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        label={
                          <Typography className="text-dark">
                            Landline Number
                          </Typography>
                        }
                        {...register("landlineNumber", {
                          pattern: {
                            value: /^(?:\+91[-\s]?)?(?:\(\d{3}\)|\d{3})[-\s]?\d{7}$/,
                            message: 'Please Enter a Valid Landline Number'
                          }
                        })}
                        error={!!errors?.landlineNumber}
                        helperText={errors?.landlineNumber?.message}
                        placeholder="Landline Number"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Controller control={control} rules={{ required: "Please Select a Currently Studying", }} name="studying" render={({ field, fieldState }) => (
                        <FormControl fullWidth>
                          <InputLabel>
                            What are you currently studying?<span style={{ color: "red" }}>*</span>
                          </InputLabel>
                          <Select
                            style={{ backgroundColor: "#f0f0f0" }}
                            label={
                              <Typography className="text-dark">
                                What are you currently studying?<span style={{ color: "red" }}>*</span>
                              </Typography>
                            }
                            value={field.value}
                            name="studying"
                            variant="outlined"
                            onChange={(event, values) => {
                              const selectedValue = event.target.value;
                              field.onChange(selectedValue);
                              const isOther = selectedValue === Qualifications.find(option => option.label === 'Other').id;
                              setShowStudyingOtherInput(isOther);
                               // If 'Other' is selected, clear the 'otherPrimarily' value
                               if (isOther) {
                                setOtherStudying('');
                              }
                            }}
                            renderValue={(selected) => {
                              const selectedOption = Qualifications.find((option) => option.id === selected);
                              return selectedOption ? selectedOption.label : 'Select Primarily Teaching/Taught';
                            }}
                            error={!!fieldState.error}
                          >
                            {Qualifications.map((option) => (
                              <MenuItem key={option.id} value={option.id}>
                                <FormControlLabel
                                  value={option.id}
                                  control={<Radio checked={field.value === option.id} />}
                                  label={option.label}
                                />
                              </MenuItem>
                            ))}
                          </Select>

                          {/* Conditionally render input for "Other" option */}
                          {showStudyingOtherInput && (
                            <Controller
                            name="otherStudying"
                            control={control}
                            rules={{
                              required: showStudyingOtherInput ? 'Please Specify The Other Studying' : false,
                            }}
                            render={({ field: otherField, fieldState: otherFieldState }) => (
                              <>
                                <TextField
                                  fullWidth
                                  label={<Typography className="text-dark">Please specify</Typography>}
                                  placeholder="Specify other studying"
                                  variant="outlined"
                                  margin="normal"
                                  value={otherStudying}
                                  onChange={(e) => {
                                    otherField.onChange(e.target.value);
                                    setOtherStudying(e.target.value);
                                  }}
                                  error={!!otherFieldState.error}
                                  helperText={otherFieldState.error?.message}
                                />
                              </>
                            )}
                          />
                          )}
                        </FormControl>
                      )} />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        label={
                          <Typography className="text-dark">
                            Your Class/Course <span style={{ color: "red" }}>*</span>
                          </Typography>
                        }
                        {...register("course", {
                          required: "Please Enter a Your Class/Course",
                          minLength: { value: 3, message: 'Please Enter a Valid Class/Course' }
                        })}
                        error={!!errors?.course}
                        helperText={errors?.course?.message}
                        placeholder="Your Class/Course"
                      />
                    </Grid>
                    <Grid item xs={4} className="mt-5">
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        label={
                          <Typography className="text-dark">
                            Name of your Institution <span style={{ color: "red" }}>*</span>
                          </Typography>
                        }
                        {...register("institution", {
                          required: "Please Enter a Your Name Of the Institution",
                          minLength: { value: 3, message: 'Please Enter a Valid Institution' }
                        })}
                        error={!!errors?.institution}
                        helperText={errors?.institution?.message}
                        placeholder="Name of your Institution"
                      />
                    </Grid>
                    <Grid item xs={4} className="mt-5">
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        label={
                          <Typography className="text-dark">
                            Place of your Institution (Town/city)  <span style={{ color: "red" }}>*</span>
                          </Typography>
                        }
                        {...register("institutionCity", {
                          required: "Please Enter a Your Places Of the Institution Town",
                          minLength: { value: 3, message: 'Please Enter a valid Institution Town' }
                        })}
                        error={!!errors?.institutionCity}
                        helperText={errors?.institutionCity?.message}
                        placeholder="Place of your Institution"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <div className="mb-3">
                        <label htmlFor="file-upload" className="form-label">Upload a copy of your identity card / bona fide certificate
                          provided by your institution(PDF only)<span style={{ color: "red" }}>*</span> </label>
                        <input
                          className={`form-control ${errors.uploadStudentPDF ? 'is-invalid' : ''}`} // Apply Bootstrap styles
                          accept=".pdf"
                          id="file-upload"
                          type="file"
                          {...register("uploadStudentPDF", {
                            validate: validateFile, // Custom validation function
                          })} />
                        {errors.uploadStudentPDF && <div className="invalid-feedback">{errors.uploadStudentPDF.message}</div>} {/* Error message display */}
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </Box>
            )}
            {activeStep === 1 && (
              <div className="row p-4">
                <div className="col-md-4">
                  <FormControl fullWidth error={!!errors.dummyPlanId}>
                    <InputLabel>Membership Plan <span style={{ color: "red" }}>*</span></InputLabel>
                    <Controller
                      name="dummyPlanId"
                      control={control}
                      rules={{ required: "Please Select a Membership Plan" }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          label="Membership Type"
                          onChange={(e) => {
                            field.onChange(e);
                            setValue("dummyPlanTypeId", ""); // Reset the plan type if a new plan is selected
                          }}
                        >
                          <MenuItem >
                            <em>Select a membership plan</em>
                          </MenuItem>
                          {planName.map((data) => (
                            <MenuItem key={data?._id} value={data?._id}>
                              {data?.planName}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                    {errors.dummyPlanId && (
                      <FormHelperText>{errors.dummyPlanId.message}</FormHelperText>
                    )}
                  </FormControl>
                </div>
                <div className="col-md-4">
                  <FormControl className="mt-0"
                    fullWidth
                    error={!!errors.dummyPlanTypeId}
                    disabled={!selectedPlanName}
                    style={{ marginTop: 16 }}
                  >
                    <InputLabel>Membership Plan Type <span style={{ color: "red" }}>*</span></InputLabel>
                    <Controller
                      name="dummyPlanTypeId"
                      control={control}
                      rules={{
                        required: selectedPlanName ? "Please Select a Membership Plan Type" : "Please Select a Membership Plan",
                      }}
                      render={({ field }) => (
                        <Select {...field} label="Membership Plan">
                          <MenuItem >
                            <em>Select a membership plan type</em>
                          </MenuItem>
                          {selectedPlanName && costType.map((plan) => (
                            <MenuItem key={plan._id} value={plan._id}>
                              {plan.planTypeName} - {plan.amount}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                    {errors.dummyPlanTypeId && (
                      <FormHelperText>{errors.dummyPlanTypeId.message}</FormHelperText>
                    )}
                  </FormControl>
                </div>
                <div className="col-md-4 ">
                  <Controller
                    control={control}
                    name="chapter"
                    render={({ field, fieldState }) => (
                      <FormControl fullWidth>
                        <InputLabel id="event">
                          Which chapter of ELTAI would
                          you like to be part of?
                        </InputLabel>
                        <Select
                          style={{ backgroundColor: "#f0f0f0" }}
                          labelId="event"
                          id="event"
                          label={
                            <Typography className="text-dark">
                              Which chapter of ELTAI would
                              you like to be part of?
                            </Typography>
                          }
                          value={field.value || ""}
                          variant="outlined"
                          onChange={(e) => field.onChange(e.target.value)}
                          error={!!fieldState.error}
                        >
                          {selectedChapters.map((data) => (
                            <MenuItem key={data._id} value={data._id}>
                              {data.title}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  />
                </div>
                <div className="col-md-4 mt-2">
                  <Grid item xs={4}>
                    <Controller
                      control={control}
                      name="sigGroups"
                      render={({ field, fieldState }) => (
                        <Autocomplete
                          multiple
                          id="sig"
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          options={allSIG}
                          disableCloseOnSelect
                          getOptionLabel={(sig) => sig?.groupName}
                          filterSelectedOptions
                          getOptionSelected={(option, value) =>
                            option._id === value._id
                          }
                          onChange={(event, values) => {
                            const sigGroups = values.map(
                              (value) => value?._id
                            ); // Extract _id from selected options
                            field.onChange(sigGroups); // Pass array of selected _ids to onChange
                          }}
                          value={
                            field.value
                              ? field.value.map((id) =>
                                allSIG.find(
                                  (group) => group._id === id
                                )
                              )
                              : []
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              error={!!fieldState?.error}
                              helperText={fieldState?.error?.message}
                              label={
                                <Typography className="text-dark">
                                  Which SIG Groups of ELTAI would
                                  you like to be part of?
                                </Typography>
                              }
                              placeholder="Select SIG Groups"
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>
                </div>
                <div className="col-md-4 mt-2">
                  <FormControl component="fieldset" error={!!errors?.emailMessage}>
                    <FormLabel component="legend">Would you like to receive updates
                      from ELTAI by email?<span style={{ color: "red" }}>*</span></FormLabel>
                    <Controller
                      name="emailMessage"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Please Select an Option" }}
                      render={({ field }) => (
                        <RadioGroup row {...field}>
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="0"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      )}
                    />
                    {errors?.emailMessage && (
                      <FormHelperText>{errors?.emailMessage?.message}</FormHelperText>
                    )}
                  </FormControl>
                </div>
                <div className="col-md-4 mt-2">
                  <FormControl component="fieldset" error={!!errors.whatsAppMessage}>
                    <FormLabel component="legend">Would you like to receive updates
                      from ELTAI by WhatsApp?</FormLabel>
                    <Controller
                      name="whatsAppMessage"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Please Select an Option" }}
                      render={({ field }) => (
                        <RadioGroup row {...field}>
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="0"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      )}
                    />
                    {errors?.whatsAppMessage && (
                      <FormHelperText>{errors?.whatsAppMessage?.message}</FormHelperText>
                    )}
                  </FormControl>
                </div>
                <div className="col-md-4">
                  <div className="card ">
                    <p className="fw-bold text-center p-3" {...register("dummyAmount", amount)} {...register("dummyCurrency", currency)}> You have to Pay {(currency === 1) ? "$" : "₹"}{amount}</p>
                  </div>
                </div>
              </div>
            )}
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2, mt: 3 }}>
              {activeStep !== 0 && (
                <Button
                  variant="contained"
                  className="menus-color"
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                >
                  Back
                </Button>
              )}
              <Box sx={{ flex: "1 1 auto" }} />
              <Button type="submit" variant="contained" className="menus-color">
                {activeStep === steps.length - 1
                  ? "Submit"
                  : "Next"}
              </Button>
            </Box>
          </form>
        </Box>
      )}
    </>
  );
}

export default AddEditStudentMember;
