import {
    Autocomplete,
    FormLabel,
    FormHelperText,
    TextField,
    Typography,
    FormControlLabel,
    Box,
    Button,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Checkbox
} from "@mui/material";
import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useForm, Controller, useWatch } from "react-hook-form";
import Select from "@mui/material/Select";
import "../../style/css/eventForm.css";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import { toast } from "react-toastify";
import Spinner from "../../Component/spinner/spinner";
import { ContactInformationVisibility, EligibilityExaminations, industries, ProfessionalEngagement, NatureOfWork, PrimarilyTeaching, CurrentlyTeaching, TeachersExperience, TeachersQualification, ProfessionalDevelopment, InterdisciplinaryAreas, EnglishLanguageTeaching, Linguistics, LiteraryStudies } from '../../constant/constant'
import { Country, State } from 'country-state-city';
import { useLazyGetMemberDetailsByIdQuery, useUpdateMemberMutation } from '../../app/services/memberServices'
import { selectUserId } from "../../app/auth/authSlice";
import { useSelector, useDispatch } from "react-redux";
import { setMemberDetails } from '../../app/features/memberSlice'
import { useParams, useNavigate } from "react-router-dom";

function CorporateProfile() {
    const [showEligibilityOtherInput, setShowEligibilityOtherInput] = useState(false);
    const [showEducationalOtherInput, setShowEducationalOtherInput] = useState(false);
    const [showProfessionOtherInput, setShowProfessionOtherInput] = useState(false);
    const [showLiteraryOtherInput, setShowLiteraryOtherInput] = useState(false);
    const [showLinguisticsOtherInput, setShowLinguisticsOtherInput] = useState(false);
    const [showELTOtherInput, setShowELTOtherInput] = useState(false);
    const [showInterOtherInput, setShowInterOtherInput] = useState(false);
    const [showPrimarilyOtherInput, setShowPrimarilyOtherInput] = useState(false);
    const navigate = useNavigate();

    //Corporate Contact Details
    /* ========================= */
    const [loading, setLoading] = useState(false);
    const userId = useSelector(selectUserId);
    const [gettMemberDetailsByIdQuery] = useLazyGetMemberDetailsByIdQuery();
    const [updateMember] = useUpdateMemberMutation();
    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedState, setSelectedState] = useState('');
    const [getStudentDetails, setGetStudentDetails] = useState()
    const dispatch = useDispatch()
    const { key } = useParams();

    // useForm hook for form data handling
    const {
        control: controlContact,
        register: registerContact,
        handleSubmit: handleSubmitContact,
        setValue: setValueContact,
        formState: { errors: errorsContact },
    } = useForm({
        mode: 'onChange',
        defaultValues: {
            type: '3',
            address: "",
            city: "",
            pinCode: "",
            state: "",
            country: "",
            mobileNumber: '',
            whatsAppNumber: '',
            email: "",
            landlineNumber: '',
            contactInformation: "",
            role: "PORTAL_MEMBER",
        },
    });

    const countries = Country.getAllCountries();
    const states = selectedCountry ? State.getStatesOfCountry(selectedCountry) : [];

    // Update states whenever selectedCountry changes
    useEffect(() => {
        if (selectedCountry) {
            const fetchedStates = State.getStatesOfCountry(selectedCountry);
            setSelectedState(fetchedStates);
            // If we have getStudentDetails, set the state based on it
            if (getStudentDetails?.state) {
                setValueContact("state", getStudentDetails.state);
                setSelectedState(getStudentDetails.state); // Set the selected state
            } else {
                setValueContact("state", ""); // Reset state if no state exists
            }
        }
    }, [selectedCountry, setValueContact, getStudentDetails]);

    useEffect(() => {
        gettMemberDetailsByIdQuery(userId).then((res) => {
            if (res?.data?.status) {
                setGetStudentDetails(res?.data?.data)
            }
        })
    }, [gettMemberDetailsByIdQuery, userId])

    useEffect(() => {
    }, [getStudentDetails])

    useEffect(() => {
        if (getStudentDetails) {
            setValueContact("address", getStudentDetails.address)
            setValueContact("city", getStudentDetails.city)
            setValueContact("pinCode", getStudentDetails.pinCode)
            setValueContact("country", getStudentDetails.country)
            setSelectedCountry(getStudentDetails.country);
            setValueContact("mobileNumber", getStudentDetails.mobileNumber)
            setValueContact("whatsAppNumber", getStudentDetails.whatsAppNumber)
            setValueContact("email", getStudentDetails.email)
            setValueContact("landlineNumber", getStudentDetails.landlineNumber)
            setValueContact("contactInformation", getStudentDetails.contactInformation)
        }
    }, [getStudentDetails, setValueContact])

    //On submit for update
    const UpdateTeachersMemberContact = (data, userId) => {
        setLoading(false)
        const formData = new FormData();
        // Iterate over the keys and values of the data object
        Object.entries(data).forEach(([key, value]) => {
            if (key !== 'contactInformation' && value !== undefined && value !== null) {
                formData.append(key, value);
            }
        });
        if (data.contactInformation && data.contactInformation.length > 0) {
            data.contactInformation.forEach(item => {
                formData.append("contactInformation", item)
            })
        }
        const updateData = { id: userId, formData }
        updateMember(updateData).then((res) => {
            if (res?.data?.data) {
                toast.success(res?.data?.message);
                navigate(`/member/view-corporate/${userId}`)
                gettMemberDetailsByIdQuery(userId).then((res) => {
                    if (res?.data?.status) {
                        dispatch(setMemberDetails(res?.data?.data))
                    }
                })
            }
            else {
                toast.error(res?.data?.err?.message);
            }
        }).catch((err) => {
            console.error(err)
        })
    }

    //Corporate Academic Profile Links
    /* ========================= */

    // useForm hook for form data handling
    const {
        control: controlAcademic,
        register: registerAcademic,
        handleSubmit: handleSubmitAcademic,
        setValue: setValueAcademic,
        formState: { errors: errorsAcademic },
    } = useForm({
        mode: 'onChange',
        defaultValues: {
            type: '3',
            institutionWebsite: "",
            scholarProfile: "",
            vidwanID: "",
            scopusProfileLink: "",
            ORICID: "",
            linkedInProfile: "",
            blog: "",
            youtubeChannel: '',
            shareInformation: '',
            role: "PORTAL_MEMBER",
        },
    });

    useEffect(() => {
        gettMemberDetailsByIdQuery(userId).then((res) => {
            if (res?.data?.status) {
                setGetStudentDetails(res?.data?.data)
            }
        })
    }, [gettMemberDetailsByIdQuery, userId])

    useEffect(() => {
    }, [getStudentDetails])

    useEffect(() => {
        if (getStudentDetails) {
            setValueAcademic("institutionWebsite", getStudentDetails.institutionWebsite)
            setValueAcademic("scholarProfile", getStudentDetails.scholarProfile)
            setValueAcademic("vidwanID", getStudentDetails.vidwanID)
            setValueAcademic("scopusProfileLink", getStudentDetails.scopusProfileLink)
            setValueAcademic("ORICID", getStudentDetails.ORICID)
            setValueAcademic("linkedInProfile", getStudentDetails.linkedInProfile)
            setValueAcademic("blog", getStudentDetails.blog)
            setValueAcademic("youtubeChannel", getStudentDetails.youtubeChannel)
            setValueAcademic("shareInformation", getStudentDetails.shareInformation)
        }
    }, [getStudentDetails, setValueAcademic])

    //On submit for update
    const UpdateTeachersMemberAcademic = (data, userId) => {
        setLoading(false)
        const formData = new FormData();
        // Iterate over the keys and values of the data object
        Object.entries(data).forEach(([key, value]) => {
            if (value !== undefined && value !== null) {
                formData.append(key, value);
            }
        });
        const updateData = { id: userId, formData }
        updateMember(updateData).then((res) => {
            if (res?.data?.data) {
                toast.success(res?.data?.message);
                navigate(`/member/view-corporate/${userId}`)
                if (res.data.status) {
                    gettMemberDetailsByIdQuery(userId).then((res) => {
                        if (res?.data?.status) {
                            dispatch(setMemberDetails(res?.data?.data))
                        }
                    })
                }
            }
            else {
                toast.error(res?.data?.err?.message);
            }
        }).catch((err) => {
            console.error(err)
        })
    }

    //Corporate Professional Details
    /* ========================= */

    const [otherEligibility, setOtherEligibility] = useState('');
    const [otherEducational, setOtherEducational] = useState('');
    const [otherPrimarily, setOtherPrimarily] = useState('');

    // useForm hook for form data handling
    const {
        control: controlEducation,
        register: registerEducation,
        handleSubmit: handleSubmitEducation,
        setValue: setValueEducation,
    } = useForm({
        mode: 'onChange',
        defaultValues: {
            type: '3',
            educationalQualification: "",
            eligibilityExamination: "",
            teacherExperience: "",
            teaching: "",
            currentDesignation: "",
            institution: "",
            lastDesignation: "",
            institutionWorkedLast: "",
            primarilyTeachingLevel: "",
            otherprimarilyTeachingLevel: "",
            natureWork: "",
            phdResearchSupervisor: "",
            nameResearchSupervisor: "",
            role: "PORTAL_MEMBER",
        },
    });

    const eligibilityOptions = useMemo(() => EligibilityExaminations.map(exam => exam.name), []);
    // Memoizing the options arrays
    const memoizedEligibilityOptions = useMemo(() => eligibilityOptions, [eligibilityOptions]);

    const educationalOptions = useMemo(() => TeachersQualification.map(exam => exam.name), []);
    // Memoizing the options arrays
    const memoizedEducationalOptions = useMemo(() => educationalOptions, [educationalOptions]);

    const primarilyOptions = useMemo(() => PrimarilyTeaching.map(exam => exam.name), []);
    // Memoizing the options arrays
    const memoizedPrimarilyOptions = useMemo(() => primarilyOptions, [primarilyOptions]);

    //Get by id member details
    const getMemberDetails = useCallback(
        () => {
            gettMemberDetailsByIdQuery(userId).then((res) => {
                if (res?.data?.status) {
                    const studentDetails = res?.data?.data;
                    setGetStudentDetails(studentDetails);

                    const educationalExams = studentDetails?.educationalQualification || [];
                    const othereducationalText = educationalExams.find(item => !memoizedEducationalOptions.includes(item));
                    setOtherEducational(othereducationalText || '');
                    setShowEducationalOtherInput(!!othereducationalText);

                    const eligibilityExams = studentDetails?.eligibilityExamination || [];
                    const otherText = eligibilityExams.find(item => !memoizedEligibilityOptions.includes(item));
                    setOtherEligibility(otherText || '');
                    setShowEligibilityOtherInput(!!otherText);

                    const primarilyTeachingLevel = studentDetails?.primarilyTeachingLevel;
                    const isOtherPrimarily = !memoizedPrimarilyOptions.includes(primarilyTeachingLevel);
                    setOtherPrimarily(isOtherPrimarily ? studentDetails?.otherprimarilyTeachingLevel : '');
                    setShowPrimarilyOtherInput(isOtherPrimarily);
                }
            });
        }, [gettMemberDetailsByIdQuery, userId, memoizedEducationalOptions, memoizedEligibilityOptions, memoizedPrimarilyOptions]
    )

    //getAllNews
    useEffect(() => {
        getMemberDetails()
    }, [getMemberDetails])

    const handleOtherEligibilityChange = (e) => {
        const inputValue = e.target.value;
        setOtherEligibility(inputValue);
        if (inputValue && !eligibilityOptions.includes(inputValue)) {
            setShowEligibilityOtherInput(true);
        } else {
            setShowEligibilityOtherInput(false);
        }
    };

    const handleOtherEducationalChange = (e) => {
        const inputValue = e.target.value;
        setOtherEducational(inputValue);
        if (inputValue && !educationalOptions.includes(inputValue)) {
            setShowEducationalOtherInput(true);
        } else {
            setShowEducationalOtherInput(false);
        }
    };

    useEffect(() => {
    }, [getStudentDetails])

    useEffect(() => {
        if (getStudentDetails) {
            setValueEducation("educationalQualification", getStudentDetails.educationalQualification)
            setValueEducation("eligibilityExamination", getStudentDetails.eligibilityExamination)
            setValueEducation("teacherExperience", getStudentDetails.teacherExperience)
            setValueEducation("teaching", getStudentDetails.teaching)
            setValueEducation("currentDesignation", getStudentDetails.currentDesignation)
            setValueEducation("institution", getStudentDetails.institution)
            setValueEducation("lastDesignation", getStudentDetails.lastDesignation)
            setValueEducation("institutionWorkedLast", getStudentDetails.institutionWorkedLast)
            setValueEducation("primarilyTeachingLevel", getStudentDetails.primarilyTeachingLevel)
            setValueEducation("otherprimarilyTeachingLevel", getStudentDetails.otherprimarilyTeachingLevel)
            setValueEducation("natureWork", getStudentDetails.natureWork)
            setValueEducation("phdResearchSupervisor", getStudentDetails.phdResearchSupervisor)
            setValueEducation("nameResearchSupervisor", getStudentDetails.nameResearchSupervisor)
        }
    }, [getStudentDetails, setValueEducation])

    //On submit for update
    const UpdateTeachersMemberEducation = (data, userId) => {
        setLoading(false)
        const formData = new FormData();
        // Iterate over the keys and values of the data object
        Object.entries(data).forEach(([key, value]) => {
            if (key !== 'educationalQualification' && key !== 'eligibilityExamination' && key !== 'otherprimarilyTeachingLevel' && key !== 'primarilyTeachingLevel' && key !== 'otherEligibility' && key !== 'otherEducational' && key !== 'otherPrimarily' && value !== undefined && value !== null) {
                formData.append(key, value);
            }
        });

        // Handle educational qualification updates
        let updatedEducationalExams = [...data.educationalQualification];
        const otherEducationalInputIndex = updatedEducationalExams.findIndex(value => value === 'Other')
        if (otherEducationalInputIndex) {
            updatedEducationalExams[otherEducationalInputIndex + 1] = otherEducational
        }
        updatedEducationalExams.forEach(item => {
            formData.append("educationalQualification", item);
        });

        // Handle eligibility examination updates
        let updatedEligibilityExams = [...data.eligibilityExamination];
        const otherInputIndex = updatedEligibilityExams.findIndex(value => value === 'Other')
        if (otherInputIndex) {
            updatedEligibilityExams[otherInputIndex + 1] = otherEligibility
        }
        updatedEligibilityExams.forEach(item => {
            formData.append("eligibilityExamination", item);
        });

        // Handle primarilyTeachingLevel and otherPrimarilyTeachingLevel
        const { primarilyTeachingLevel } = data;
        formData.append("primarilyTeachingLevel", primarilyTeachingLevel);

        if (showPrimarilyOtherInput && otherPrimarily) {
            formData.append("otherprimarilyTeachingLevel", otherPrimarily);
        } else {
            formData.append("otherprimarilyTeachingLevel", "");
        }

        const updateData = { id: userId, formData }
        updateMember(updateData).then((res) => {
            if (res?.data?.data) {
                toast.success(res?.data?.message);
                navigate(`/member/view-corporate/${userId}`)
                gettMemberDetailsByIdQuery(userId).then((res) => {
                    if (res?.data?.status) {
                        dispatch(setMemberDetails(res?.data?.data))
                    }
                })
            }
            else {
                toast.error(res?.data?.err?.message);
            }
        }).catch((err) => {
            console.error(err)
        })
    }

    //Corporate Professional Development
    /* ========================= */

    const [otherProfession, setOtherProfession] = useState('');
    const [otherLit, setOtherLit] = useState('');
    const [otherLing, setOtherLing] = useState('');
    const [otherELT, setOtherELT] = useState('');
    const [otherInter, setOtherInter] = useState('');

    // useForm hook for form data handling
    const {
        control: controlProfession,
        register: registerProfession,
        handleSubmit: handleSubmitProfession,
        setValue: setValueProfession,
    } = useForm({
        mode: 'onChange',
        defaultValues: {
            type: '3',
            teacherAssociation: "",
            associationName: "",
            professionalDevelopment: "",
            expertiseLiteraryStudies: "",
            expertiseLinguistics: "",
            expertiseELT: "",
            expertiseInterdisciplinaryAreas: "",
            areaOfExpertise: "",
            professionalEngagement: "",
            role: "PORTAL_MEMBER",
        },
    });

    const professionOptions = useMemo(() => ProfessionalDevelopment.map(exam => exam.name), []);
    // Memoizing the options arrays
    const memoizedProfessionOptions = useMemo(() => professionOptions, [professionOptions]);
    const litOptions = useMemo(() => LiteraryStudies.map(exam => exam.name), []);
    // Memoizing the options arrays
    const memoizedLitOptions = useMemo(() => litOptions, [litOptions]);
    const lingOptions = useMemo(() => Linguistics.map(exam => exam.name), []);
    // Memoizing the options arrays
    const memoizedLingOptions = useMemo(() => lingOptions, [lingOptions]);
    const ELTOptions = useMemo(() => EnglishLanguageTeaching.map(exam => exam.name), []);
    // Memoizing the options arrays
    const memoizedELTOptions = useMemo(() => ELTOptions, [ELTOptions]);
    const interOptions = useMemo(() => InterdisciplinaryAreas.map(exam => exam.name), []);
    // Memoizing the options arrays
    const memoizedInterOptions = useMemo(() => interOptions, [interOptions]);
    
    useEffect(() => {
        gettMemberDetailsByIdQuery(userId).then((res) => {
            if (res?.data?.status) {
                const studentDetails = res?.data?.data;
                setGetStudentDetails(studentDetails);

                const professionExams = studentDetails?.professionalDevelopment || [];
                const otherText = professionExams.find(item => !memoizedProfessionOptions.includes(item));
                setOtherProfession(otherText || '');
                setShowProfessionOtherInput(!!otherText);

                const LitExams = studentDetails?.expertiseLiteraryStudies || [];
                const otherLitText = LitExams.find(item => !memoizedLitOptions.includes(item));
                setOtherLit(otherLitText || '');
                setShowLiteraryOtherInput(!!otherLitText);

                const LingExams = studentDetails?.expertiseLinguistics || [];
                const otherLingText = LingExams.find(item => !memoizedLingOptions.includes(item));
                setOtherLing(otherLingText || '');
                setShowLinguisticsOtherInput(!!otherLingText);

                const ELTExams = studentDetails?.expertiseELT || [];
                const otherELTText = ELTExams.find(item => !memoizedELTOptions.includes(item));
                setOtherELT(otherELTText || '');
                setShowELTOtherInput(!!otherELTText);

                const interExams = studentDetails?.expertiseInterdisciplinaryAreas || [];
                const otherInterText = interExams.find(item => !memoizedInterOptions.includes(item));
                setOtherInter(otherInterText || '');
                setShowInterOtherInput(!!otherInterText);
            }
        });
    }, [gettMemberDetailsByIdQuery, userId, memoizedProfessionOptions, memoizedLitOptions, memoizedLingOptions, memoizedELTOptions, memoizedInterOptions])

    const handleOtherProfessionChange = (e) => {
        const inputValue = e.target.value;
        setOtherProfession(inputValue);
        if (inputValue && !professionOptions.includes(inputValue)) {
            setShowProfessionOtherInput(true);
        } else {
            setShowProfessionOtherInput(false);
        }
    };

    const handleOtherLitChange = (e) => {
        const inputValue = e.target.value;
        setOtherLit(inputValue);
        if (inputValue && !litOptions.includes(inputValue)) {
            setShowLiteraryOtherInput(true);
        } else {
            setShowLiteraryOtherInput(false);
        }
    };

    const handleOtherLingChange = (e) => {
        const inputValue = e.target.value;
        setOtherLing(inputValue);
        if (inputValue && !lingOptions.includes(inputValue)) {
            setShowLinguisticsOtherInput(true);
        } else {
            setShowLinguisticsOtherInput(false);
        }
    };

    const handleOtherELTChange = (e) => {
        const inputValue = e.target.value;
        setOtherELT(inputValue);
        if (inputValue && !ELTOptions.includes(inputValue)) {
            setShowELTOtherInput(true);
        } else {
            setShowELTOtherInput(false);
        }
    };

    const handleOtherInterChange = (e) => {
        const inputValue = e.target.value;
        setOtherInter(inputValue);
        if (inputValue && !interOptions.includes(inputValue)) {
            setShowInterOtherInput(true);
        } else {
            setShowInterOtherInput(false);
        }
    };

    useEffect(() => {
    }, [getStudentDetails])

    useEffect(() => {
        if (getStudentDetails) {
            setValueProfession("teacherAssociation", getStudentDetails.teacherAssociation)
            setValueProfession("associationName", getStudentDetails.associationName)
            setValueProfession("professionalDevelopment", getStudentDetails.professionalDevelopment)
            setValueProfession("expertiseLiteraryStudies", getStudentDetails.expertiseLiteraryStudies)
            setValueProfession("expertiseLinguistics", getStudentDetails.expertiseLinguistics)
            setValueProfession("expertiseELT", getStudentDetails.expertiseELT)
            setValueProfession("expertiseInterdisciplinaryAreas", getStudentDetails.expertiseInterdisciplinaryAreas)
            setValueProfession("areaOfExpertise", getStudentDetails.areaOfExpertise)
            setValueProfession("professionalEngagement", getStudentDetails.professionalEngagement)
        }
    }, [getStudentDetails, setValueProfession])

    //On submit for update
    const UpdateTeachersMemberProfession = (data, userId) => {
        setLoading(false)
        const formData = new FormData();
        // Iterate over the keys and values of the data object
        Object.entries(data).forEach(([key, value]) => {
            if (key !== 'professionalDevelopment' && key !== 'expertiseLiteraryStudies' && key !== 'expertiseLinguistics' && key !== 'expertiseELT' && key !== 'expertiseInterdisciplinaryAreas' && key !== 'otherProfession' && key !== 'otherLit' && key !== 'otherLing' && key !== 'otherELT' && key !== 'otherInter' && key !== 'professionalEngagement' && value !== undefined && value !== null) {
                formData.append(key, value);
            }
        });
        let updatedProfessionExams = [...data.professionalDevelopment];
        const otherInputIndex = updatedProfessionExams.findIndex(value => value === 'Other')
        if (otherInputIndex) {
            updatedProfessionExams[otherInputIndex + 1] = otherProfession
        }
        updatedProfessionExams.forEach(item => {
            formData.append("professionalDevelopment", item);
        });

        let updatedLitExams = [...data.expertiseLiteraryStudies];
        const otherInputLitIndex = updatedLitExams.findIndex(value => value === 'Other')
        if (otherInputLitIndex) {
            updatedLitExams[otherInputLitIndex + 1] = otherLit
        }
        updatedLitExams.forEach(item => {
            formData.append("expertiseLiteraryStudies", item);
        });

        let updatedLingExams = [...data.expertiseLinguistics];
        const otherInputLingIndex = updatedLingExams.findIndex(value => value === 'Other')
        if (otherInputLingIndex) {
            updatedLingExams[otherInputLingIndex + 1] = otherLing
        }
        updatedLingExams.forEach(item => {
            formData.append("expertiseLinguistics", item);
        });

        let updatedELTExams = [...data.expertiseELT];
        const otherInputELTIndex = updatedELTExams.findIndex(value => value === 'Other')
        if (otherInputELTIndex) {
            updatedELTExams[otherInputELTIndex + 1] = otherELT
        }
        updatedELTExams.forEach(item => {
            formData.append("expertiseELT", item);
        });

        let updatedInterExams = [...data.expertiseInterdisciplinaryAreas];
        const otherInputInterIndex = updatedInterExams.findIndex(value => value === 'Other')
        if (otherInputInterIndex) {
            updatedInterExams[otherInputInterIndex + 1] = otherInter
        }
        updatedInterExams.forEach(item => {
            formData.append("expertiseInterdisciplinaryAreas", item);
        });

        if (data.professionalEngagement && data.professionalEngagement.length > 0) {
            data.professionalEngagement.forEach(item => {
                formData.append("professionalEngagement", item);
            });
        }

        const updateData = { id: userId, formData }
        updateMember(updateData).then((res) => {
            if (res?.data?.data) {
                toast.success(res?.data?.message);
                navigate(`/member/view-corporate/${userId}`)
                gettMemberDetailsByIdQuery(userId).then((res) => {
                    if (res?.data?.status) {
                        dispatch(setMemberDetails(res?.data?.data))
                    }
                })
            }
            else {
                toast.error(res?.data?.err?.message);
            }
        }).catch((err) => {
            console.error(err)
        })
    }

    // Watch the teaching field value
    const isTeaching = useWatch({
        control: controlEducation,
        name: 'teaching',
        defaultValue: '',
    });

    // Watch the phdResearchSupervisor field value
    const isPhd = useWatch({
        control: controlEducation,
        name: 'phdResearchSupervisor',
        defaultValue: '',
    });

    // Watch the teacherAssociation field value
    const isTeachersAsso = useWatch({
        control: controlProfession,
        name: 'teacherAssociation',
        defaultValue: '',
    });

    //Corporate Professional Details
    /* ========================= */

    // useForm hook for form data handling
    const {
        control: controlOrganisation,
        register: registerOrganisation,
        handleSubmit: handleSubmitOrganisation,
        setValue: setValueOrganisation,
    } = useForm({
        mode: 'onChange',
        defaultValues: {
            type: '3',
            typeOfOrganisation: "",
            industry: "",
            headOfficeLocated: "",
            employees: "",
            role: "PORTAL_MEMBER",
        },
    });

    //Get by id member details
    const getMemberDetailsOrg = useCallback(
        () => {
            gettMemberDetailsByIdQuery(userId).then((res) => {
                if (res?.data?.status) {
                    const studentDetails = res?.data?.data;
                    setGetStudentDetails(studentDetails);
                }
            });
        }, [gettMemberDetailsByIdQuery, userId]
    )

    //getAllNews
    useEffect(() => {
        getMemberDetailsOrg()
    }, [getMemberDetailsOrg])

    useEffect(() => {
    }, [getStudentDetails])

    useEffect(() => {
        if (getStudentDetails) {
            setValueOrganisation("typeOfOrganisation", getStudentDetails.typeOfOrganisation)
            setValueOrganisation("industry", getStudentDetails.industry)
            setValueOrganisation("headOfficeLocated", getStudentDetails.headOfficeLocated)
            setValueOrganisation("employees", getStudentDetails.employees)
        }
    }, [getStudentDetails, setValueOrganisation])

    //On submit for update
    const UpdateOrganisationMember = (data, userId) => {
        setLoading(false)
        const formData = new FormData();
        // Iterate over the keys and values of the data object
        Object.entries(data).forEach(([key, value]) => {
            if (value !== undefined && value !== null) {
                formData.append(key, value);
            }
        });

        const updateData = { id: userId, formData }
        updateMember(updateData).then((res) => {
            if (res?.data?.data) {
                toast.success(res?.data?.message);
                navigate(`/member/view-corporate/${userId}`)
                gettMemberDetailsByIdQuery(userId).then((res) => {
                    if (res?.data?.status) {
                        dispatch(setMemberDetails(res?.data?.data))
                    }
                })
            }
            else {
                toast.error(res?.data?.err?.message);
            }
        }).catch((err) => {
            console.error(err)
        })
    }

    return (
        <>
            {/* Corporate Contact Details */}
            <>
                {key === "contact" && (
                    <Box>
                        <div className="pt-1 pb-3">
                            <div className="p-0">
                                <div className="Architects-header d-flex align-items-center justify-content-center">
                                    <h2 className="signika-fontOEC text-center">Contact Details</h2>
                                </div>
                            </div>
                        </div>
                        <div className="container mt-3">
                            <div className="row">
                                <div className="col-md-8"></div>
                                <div className="col-md-4 mt-3">
                                    <Button
                                        onClick={() => window.history.back()}
                                        variant="contained"
                                        className="menus-color"
                                        style={{ float: "right", marginRight: 58 }}
                                    >
                                        Back
                                    </Button>
                                </div>
                            </div>
                        </div>

                        {loading ? (
                            <Spinner />
                        ) : (
                            <Box className="container" sx={{ width: "100%", marginTop: "0px", marginBottom: "50px" }}>
                                <form
                                    className="mt-2"
                                    onSubmit={handleSubmitContact((data) => UpdateTeachersMemberContact(data, userId))}
                                >
                                    <Box>
                                        <div style={{ paddingLeft: 40, paddingRight: 40 }}>
                                            <Grid container rowSpacing={3} columnSpacing={5}>
                                                <Grid item xs={12}></Grid>

                                                <Grid item xs={4}>
                                                    <TextField
                                                        InputLabelProps={{ shrink: true }}
                                                        fullWidth
                                                        label={<Typography className="text-dark">Address <span style={{ color: "red" }}>*</span></Typography>}
                                                        {...registerContact("address", { required: "Please Enter an Address" })}
                                                        error={!!errorsContact?.address}
                                                        helperText={errorsContact?.address?.message}
                                                        placeholder="Address"
                                                    />
                                                </Grid>

                                                <Grid item xs={4}>
                                                    <TextField
                                                        InputLabelProps={{ shrink: true }}
                                                        fullWidth
                                                        label={<Typography className="text-dark">Mobile Number <span style={{ color: "red" }}>*</span></Typography>}
                                                        {...registerContact("mobileNumber", { required: "Please Enter a Mobile Number" })}
                                                        error={!!errorsContact?.mobileNumber}
                                                        helperText={errorsContact?.mobileNumber?.message}
                                                        placeholder="Mobile Number"
                                                    />
                                                </Grid>

                                                <Grid item xs={4}>
                                                    <TextField
                                                        InputLabelProps={{ shrink: true }}
                                                        fullWidth
                                                        label={<Typography className="text-dark">City/Town <span style={{ color: "red" }}>*</span></Typography>}
                                                        {...registerContact("city", { required: "Please Enter a City/Town" })}
                                                        error={!!errorsContact?.city}
                                                        helperText={errorsContact?.city?.message}
                                                        placeholder="City/Town"
                                                    />
                                                </Grid>

                                                <Grid item xs={4}>
                                                    <TextField
                                                        InputLabelProps={{ shrink: true }}
                                                        fullWidth
                                                        label={<Typography className="text-dark">WhatsApp Number <span style={{ color: "red" }}>*</span></Typography>}
                                                        {...registerContact("whatsAppNumber", { required: "Please Enter a WhatsApp Number" })}
                                                        error={!!errorsContact?.whatsAppNumber}
                                                        helperText={errorsContact?.whatsAppNumber?.message}
                                                        placeholder="WhatsApp Number"
                                                    />
                                                </Grid>

                                                <Grid item xs={4}>
                                                    <TextField
                                                        InputLabelProps={{ shrink: true }}
                                                        fullWidth
                                                        label={<Typography className="text-dark">PIN Code <span style={{ color: "red" }}>*</span></Typography>}
                                                        {...registerContact("pinCode", { required: "Please Enter a PIN Code" })}
                                                        error={!!errorsContact?.pinCode}
                                                        helperText={errorsContact?.pinCode?.message}
                                                        placeholder="PIN Code"
                                                    />
                                                </Grid>

                                                <Grid item xs={4}>
                                                    <TextField
                                                        InputLabelProps={{ shrink: true }}
                                                        fullWidth
                                                        label={<Typography className="text-dark">Email Address <span style={{ color: "red" }}>*</span></Typography>}
                                                        {...registerContact("email", { required: "Please Enter an Email" })}
                                                        error={!!errorsContact?.email}
                                                        helperText={errorsContact?.email?.message}
                                                        placeholder="Email Address"
                                                        disabled
                                                    />
                                                </Grid>

                                                <Grid item xs={4}>
                                                    <FormControl fullWidth margin="normal" className="mt-0" disabled={!selectedCountry} error={!!errorsContact.state}>
                                                        <InputLabel>State <span style={{ color: "red" }}>*</span></InputLabel>
                                                        <Controller
                                                            name="state"
                                                            control={controlContact}
                                                            defaultValue=""
                                                            rules={{ required: selectedCountry ? "State is required" : false }}
                                                            render={({ field }) => (
                                                                <Select
                                                                    {...field}
                                                                    value={selectedState}
                                                                    onChange={(e) => {
                                                                        setSelectedState(e.target.value);
                                                                        field.onChange(e);
                                                                    }}
                                                                >
                                                                    {states.map((state) => (
                                                                        <MenuItem key={state.isoCode} value={state.name}>
                                                                            {state.name}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                            )}
                                                            disabled
                                                        />
                                                        {errorsContact.state && <FormHelperText>{errorsContact.state.message}</FormHelperText>}
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={4}>
                                                    <FormControl fullWidth margin="normal" className="mt-0" error={!!errorsContact.country}>
                                                        <InputLabel>Country <span style={{ color: "red" }}>*</span></InputLabel>
                                                        <Controller
                                                            name="country"
                                                            control={controlContact}
                                                            defaultValue=""
                                                            rules={{ required: "Country is required" }}
                                                            render={({ field }) => (
                                                                <Select
                                                                    {...field}
                                                                    value={selectedCountry}
                                                                    onChange={(e) => {
                                                                        setSelectedCountry(e.target.value);
                                                                        field.onChange(e);
                                                                    }}
                                                                >
                                                                    {countries.map((country) => (
                                                                        <MenuItem key={country.isoCode} value={country.isoCode}>
                                                                            {country.name}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                            )}
                                                            disabled
                                                        />
                                                        {errorsContact.country && <FormHelperText>{errorsContact.country.message}</FormHelperText>}
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={4}>
                                                    <TextField
                                                        InputLabelProps={{ shrink: true }}
                                                        inputProps={{ type: "number" }}
                                                        fullWidth
                                                        label={<Typography className="text-dark">Telephone Number <span style={{ color: "red" }}>*</span></Typography>}
                                                        {...registerContact("landlineNumber", { required: "Please Enter a Telephone Number" })}
                                                        error={!!errorsContact?.landlineNumber}
                                                        helperText={errorsContact?.landlineNumber?.message}
                                                        placeholder="Telephone Number"
                                                    />
                                                </Grid>

                                                <Grid item xs={4}>
                                                    <Controller
                                                        control={controlContact}
                                                        name="contactInformation"
                                                        render={({ field, fieldState }) => (
                                                            <Autocomplete
                                                                multiple
                                                                id="contactInformation"
                                                                fullWidth
                                                                options={ContactInformationVisibility}
                                                                disableCloseOnSelect
                                                                getOptionLabel={(option) => option?.name}
                                                                filterSelectedOptions
                                                                onChange={(event, values) => {
                                                                    const selectedValues = Array.isArray(values) ? values : [];
                                                                    const contactInfoNames = selectedValues.map((value) => value?.name);
                                                                    field.onChange(contactInfoNames);
                                                                }}
                                                                value={
                                                                    field.value ?
                                                                        ContactInformationVisibility.filter((info) => field.value.includes(info.name)) : []
                                                                }
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        error={!!fieldState?.error}
                                                                        helperText={fieldState?.error?.message}
                                                                        label={<Typography className="text-dark">Contact Information Visibility</Typography>}
                                                                        placeholder="Select Contact Information Visibility"
                                                                    />
                                                                )}
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </Box>

                                    <Box sx={{ display: "flex", flexDirection: "row", pt: 2, mt: 3 }}>
                                        <Box sx={{ flex: "1 1 auto" }} />
                                        <Button type="submit" variant="contained" className="menus-color">
                                            Update
                                        </Button>
                                    </Box>
                                </form>
                            </Box>
                        )}
                    </Box>
                )}
            </>

            {/* Corporate Academic Profile Links */}
            <>
                {key === "academic" && (
                    <>
                        <Box>
                            <div className='pt-1 pb-3'>
                                <div className='p-0'>
                                    <div className='Architects-header d-flex align-items-center justify-content-center'>
                                        <h2 className='signika-fontOEC text-center'>Academic Profile Links</h2>
                                    </div>
                                </div>
                            </div>
                        </Box>
                        <div className="container mt-3">
                            <div className="row">
                                <div className="col-md-8"></div>
                                <div className="col-md-4 mt-3">
                                    <Button
                                        onClick={() => window.history.back()}
                                        variant="contained"
                                        className="menus-color"
                                        style={{ float: "right", marginRight: 58 }}
                                    >
                                        Back
                                    </Button>
                                </div>
                            </div>
                        </div>
                        {loading ? (
                            <Spinner />
                        ) : (
                            <Box
                                className="container"
                                sx={{ width: "100%", marginTop: "0px", marginBottom: "50px" }}
                            >
                                <form className="mt-2" onSubmit={handleSubmitAcademic((data) => UpdateTeachersMemberAcademic(data, userId))}>
                                    <Box>
                                        <div style={{ paddingLeft: 40, paddingRight: 40 }}>
                                            <Grid container rowSpacing={3} columnSpacing={5}>
                                                <Grid item xs={12}></Grid>

                                                <Grid item xs={4}>
                                                    <TextField
                                                        InputLabelProps={{ shrink: true }}
                                                        fullWidth
                                                        label={<Typography className="text-dark">Your Google Scholar Profile</Typography>}
                                                        {...registerAcademic("scholarProfile")}
                                                        error={!!errorsAcademic?.scholarProfile}
                                                        helperText={errorsAcademic?.scholarProfile?.message}
                                                        placeholder="Google Scholar Profile"
                                                    />
                                                </Grid>

                                                <Grid item xs={4}>
                                                    <TextField
                                                        InputLabelProps={{ shrink: true }}
                                                        fullWidth
                                                        label={<Typography className="text-dark">Your Scopus Profile Link</Typography>}
                                                        {...registerAcademic("scopusProfileLink")}
                                                        error={!!errorsAcademic?.scopusProfileLink}
                                                        helperText={errorsAcademic?.scopusProfileLink?.message}
                                                        placeholder="Scopus Profile Link"
                                                    />
                                                </Grid>

                                                <Grid item xs={4}>
                                                    <TextField
                                                        InputLabelProps={{ shrink: true }}
                                                        fullWidth
                                                        label={<Typography className="text-dark">Your ORICID Id</Typography>}
                                                        {...registerAcademic("ORICID")}
                                                        error={!!errorsAcademic?.ORICID}
                                                        helperText={errorsAcademic?.ORICID?.message}
                                                        placeholder="ORICID Id"
                                                    />
                                                </Grid>

                                                <Grid item xs={4}>
                                                    <TextField
                                                        InputLabelProps={{ shrink: true }}
                                                        fullWidth
                                                        label={<Typography className="text-dark">Your LinkedIn Profile</Typography>}
                                                        {...registerAcademic("linkedInProfile")}
                                                        error={!!errorsAcademic?.linkedInProfile}
                                                        helperText={errorsAcademic?.linkedInProfile?.message}
                                                        placeholder="LinkedIn Profile"
                                                    />
                                                </Grid>

                                                <Grid item xs={4}>
                                                    <TextField
                                                        InputLabelProps={{ shrink: true }}
                                                        fullWidth
                                                        label={<Typography className="text-dark">Your Blog/Website</Typography>}
                                                        {...registerAcademic("blog")}
                                                        error={!!errorsAcademic?.blog}
                                                        helperText={errorsAcademic?.blog?.message}
                                                        placeholder="Blog/Website"
                                                    />
                                                </Grid>

                                                <Grid item xs={4}>
                                                    <TextField
                                                        InputLabelProps={{ shrink: true }}
                                                        fullWidth
                                                        label={<Typography className="text-dark">Your Youtube Channel</Typography>}
                                                        {...registerAcademic("youtubeChannel")}
                                                        error={!!errorsAcademic?.youtubeChannel}
                                                        helperText={errorsAcademic?.youtubeChannel?.message}
                                                        placeholder="Youtube Channel"
                                                    />
                                                </Grid>

                                                <Grid item xs={4}>
                                                    <FormControl fullWidth margin="normal" className="mt-0" error={!!errorsAcademic.shareInformation}>
                                                        <FormLabel component="legend">Consent To Share Information</FormLabel>
                                                        <Controller
                                                            name="shareInformation"
                                                            control={controlAcademic}
                                                            defaultValue=""
                                                            render={({ field }) => (
                                                                <RadioGroup
                                                                    {...field}
                                                                    row
                                                                    onChange={(e) => field.onChange(e.target.value)}
                                                                >
                                                                    <FormControlLabel value="1" control={<Radio />} label="Yes" />
                                                                    <FormControlLabel value="0" control={<Radio />} label="No" />
                                                                </RadioGroup>
                                                            )}
                                                        />
                                                        {errorsAcademic.shareInformation && (
                                                            <FormHelperText>{errorsAcademic.shareInformation.message}</FormHelperText>
                                                        )}
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </Box>

                                    <Box sx={{ display: "flex", flexDirection: "row", pt: 2, mt: 3 }}>
                                        <Box sx={{ flex: "1 1 auto" }} />
                                        <Button type="submit" variant="contained" className="menus-color">
                                            Update
                                        </Button>
                                    </Box>
                                </form>
                            </Box>
                        )}
                    </>
                )}
            </>

            {/* Corporate Professional Details */}
            <>
                {key === "profession" && (
                    <>
                        <Box>
                            <div className="pt-1 pb-3">
                                <div className="p-0">
                                    <div className="Architects-header d-flex align-items-center justify-content-center">
                                        <h2 className="signika-fontOEC text-center">Professional Details</h2>
                                    </div>
                                </div>
                            </div>
                        </Box>
                        <div className="container mt-3">
                            <div className="row">
                                <div className="col-md-8"></div>
                                <div className="col-md-4 mt-3">
                                    <Button
                                        onClick={() => window.history.back()}
                                        variant="contained"
                                        className="menus-color"
                                        style={{ float: "right", marginRight: 58 }}
                                    >
                                        Back
                                    </Button>
                                </div>
                            </div>
                        </div>
                        {loading ? (
                            <Spinner />
                        ) : (
                            <Box className="container" sx={{ width: "100%", marginTop: "0px", marginBottom: "50px" }}>
                                <form className="mt-2" onSubmit={handleSubmitEducation((data) => UpdateTeachersMemberEducation(data, userId))}>
                                    <Box>
                                        <div style={{ paddingLeft: 40, paddingRight: 40 }}>
                                            <Grid container rowSpacing={3} columnSpacing={5}>
                                                <Grid item xs={12}></Grid>

                                                <Grid item xs={4}>
                                                    <Controller
                                                        control={controlEducation}
                                                        name="educationalQualification"
                                                        render={({ field, fieldState }) => (
                                                            <>
                                                                <Autocomplete
                                                                    multiple
                                                                    id="educationalQualification"
                                                                    fullWidth
                                                                    options={TeachersQualification}
                                                                    disableCloseOnSelect
                                                                    getOptionLabel={(option) => option?.name}
                                                                    filterSelectedOptions
                                                                    onChange={(event, values) => {
                                                                        const selectedValues = Array.isArray(values) ? values : [];
                                                                        const contactInfoIds = selectedValues.map((value) => value?.name);
                                                                        setShowEducationalOtherInput(selectedValues.some((value) => value?.name === 'Other'));
                                                                        field.onChange(contactInfoIds);
                                                                    }}
                                                                    value={
                                                                        Array.isArray(field.value) ?
                                                                            TeachersQualification.filter((info) => field.value.includes(info.name)) : []
                                                                    }
                                                                    renderInput={(params) => (
                                                                        <TextField
                                                                            {...params}
                                                                            error={!!fieldState?.error}
                                                                            helperText={fieldState?.error?.message}
                                                                            label={
                                                                                <Typography className="text-dark">
                                                                                    Your Educational Qualification
                                                                                </Typography>
                                                                            }
                                                                            placeholder="Select Any Educational Qualification"
                                                                        />
                                                                    )}
                                                                />

                                                                {/* Conditionally render input for "Other" option */}
                                                                {showEducationalOtherInput && (
                                                                    <TextField
                                                                        fullWidth
                                                                        label={<Typography className="text-dark">Please specify</Typography>}
                                                                        placeholder="Specify other educational qualification"
                                                                        variant="outlined"
                                                                        margin="normal"
                                                                        {...registerEducation('otherEducational')}
                                                                        value={otherEducational} // Use state variable here
                                                                        onChange={handleOtherEducationalChange}
                                                                    />
                                                                )}
                                                            </>
                                                        )}
                                                    />
                                                </Grid>

                                                <Grid item xs={4}>
                                                    <Controller
                                                        control={controlEducation}
                                                        name="eligibilityExamination"
                                                        render={({ field, fieldState }) => (
                                                            <>
                                                                <Autocomplete
                                                                    multiple
                                                                    id="eligibilityExamination"
                                                                    fullWidth
                                                                    options={EligibilityExaminations}
                                                                    disableCloseOnSelect
                                                                    getOptionLabel={(option) => option?.name}
                                                                    filterSelectedOptions
                                                                    onChange={(event, values) => {
                                                                        const selectedValues = Array.isArray(values) ? values : [];
                                                                        const contactInfoIds = selectedValues.map((value) => value?.name);
                                                                        setShowEligibilityOtherInput(selectedValues.some((value) => value?.name === 'Other'));
                                                                        field.onChange(contactInfoIds);
                                                                    }}
                                                                    value={
                                                                        Array.isArray(field.value) ?
                                                                            EligibilityExaminations.filter((info) => field.value.includes(info.name)) : []
                                                                    }
                                                                    renderInput={(params) => (
                                                                        <TextField
                                                                            {...params}
                                                                            error={!!fieldState?.error}
                                                                            helperText={fieldState?.error?.message}
                                                                            label={
                                                                                <Typography className="text-dark">
                                                                                    Have You Got Through Any Eligibility Examinations?
                                                                                </Typography>
                                                                            }
                                                                            placeholder="Select Any Eligibility Examinations"
                                                                        />
                                                                    )}
                                                                />

                                                                {/* Conditionally render input for "Other" option */}
                                                                {showEligibilityOtherInput && (
                                                                    <TextField
                                                                        fullWidth
                                                                        label={<Typography className="text-dark">Please specify</Typography>}
                                                                        placeholder="Specify other eligibility studies"
                                                                        variant="outlined"
                                                                        margin="normal"
                                                                        {...registerEducation('otherEligibility')}
                                                                        value={otherEligibility} // Use state variable here
                                                                        onChange={handleOtherEligibilityChange}
                                                                    />
                                                                )}
                                                            </>
                                                        )}
                                                    />
                                                </Grid>

                                                <Grid item xs={4}>
                                                    <Controller
                                                        control={controlEducation}
                                                        name="teacherExperience"
                                                        render={({ field, fieldState }) => (
                                                            <>
                                                                <FormControl fullWidth>
                                                                    <InputLabel>
                                                                        <Typography className="text-dark">Teaching Experience (in years)</Typography>
                                                                    </InputLabel>
                                                                    <Select
                                                                        label="Teaching Experience (in years)"
                                                                        value={field.value}
                                                                        onChange={(event) => {
                                                                            const selectedValue = event.target.value;
                                                                            field.onChange(selectedValue);
                                                                        }}
                                                                        renderValue={(selected) => (selected ? selected : 'Select Teaching Experience')}
                                                                    >
                                                                        <RadioGroup
                                                                            value={field.value}
                                                                            onChange={(event) => {
                                                                                const selectedValue = event.target.value;
                                                                                field.onChange(selectedValue);
                                                                            }}
                                                                        >
                                                                            {TeachersExperience.map((option) => (
                                                                                <MenuItem key={option.name} value={option.name}>
                                                                                    <FormControlLabel
                                                                                        value={option.name}
                                                                                        control={<Radio />}
                                                                                        label={option.name}
                                                                                    />
                                                                                </MenuItem>
                                                                            ))}
                                                                        </RadioGroup>
                                                                    </Select>
                                                                    {fieldState?.error && (
                                                                        <FormHelperText error>
                                                                            {fieldState.error.message}
                                                                        </FormHelperText>
                                                                    )}
                                                                </FormControl>
                                                            </>
                                                        )}
                                                    />
                                                </Grid>

                                                <Grid item xs={4}>
                                                    <Controller
                                                        control={controlEducation}
                                                        name="teaching"
                                                        render={({ field, fieldState }) => (
                                                            <>
                                                                <FormControl fullWidth>
                                                                    <InputLabel>
                                                                        <Typography className="text-dark">Are you currently teaching?{" "}<span style={{ color: "red" }}>*</span></Typography>
                                                                    </InputLabel>
                                                                    <Select
                                                                        label="Teaching Experience (in years)"
                                                                        value={field.value}
                                                                        onChange={(event) => {
                                                                            const selectedValue = event.target.value;
                                                                            field.onChange(selectedValue);
                                                                        }}
                                                                        renderValue={(selected) => {
                                                                            const selectedOption = CurrentlyTeaching.find((option) => option.id === selected);
                                                                            return selectedOption ? selectedOption.name : 'Select Currently Teaching';
                                                                        }}
                                                                    >
                                                                        {CurrentlyTeaching.map((option) => (
                                                                            <MenuItem key={option.id} value={option.id}>
                                                                                <FormControlLabel
                                                                                    value={option.id}
                                                                                    control={<Radio checked={field.value === option.id} />}
                                                                                    label={option.name}
                                                                                />
                                                                            </MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                    {fieldState?.error && (
                                                                        <FormHelperText error>
                                                                            {fieldState.error.message}
                                                                        </FormHelperText>
                                                                    )}
                                                                </FormControl>
                                                            </>
                                                        )}
                                                    />
                                                </Grid>

                                                <Grid item xs={4}>
                                                    <TextField
                                                        InputLabelProps={{ shrink: true }}
                                                        fullWidth
                                                        label={<Typography className="text-dark">Your Current Designation</Typography>}
                                                        {...registerEducation("currentDesignation")}
                                                        placeholder="Your Current Designation"
                                                        disabled={isTeaching !== 0}
                                                    />
                                                </Grid>

                                                <Grid item xs={4}>
                                                    <TextField
                                                        InputLabelProps={{ shrink: true }}
                                                        fullWidth
                                                        label={<Typography className="text-dark">Name Of Your Institution</Typography>}
                                                        {...registerEducation("institution")}
                                                        placeholder="Name Of Your Institution"
                                                        disabled={isTeaching !== 0}
                                                    />
                                                </Grid>

                                                <Grid item xs={4}>
                                                    <TextField
                                                        InputLabelProps={{ shrink: true }}
                                                        fullWidth
                                                        label={<Typography className="text-dark">Your Last Designation</Typography>}
                                                        {...registerEducation("lastDesignation")}
                                                        placeholder="Your Last Designation"
                                                        disabled={isTeaching !== 0}
                                                    />
                                                </Grid>

                                                <Grid item xs={4}>
                                                    <TextField
                                                        InputLabelProps={{ shrink: true }}
                                                        fullWidth
                                                        label={<Typography className="text-dark">Name of the Institution where you worked last</Typography>}
                                                        {...registerEducation("institutionWorkedLast")}
                                                        placeholder="Institution Where You Worked Last"
                                                        disabled={isTeaching !== 0}
                                                    />
                                                </Grid>

                                                <Grid item xs={4}>
                                                    <Controller
                                                        control={controlEducation}
                                                        name="primarilyTeachingLevel"
                                                        render={({ field, fieldState }) => (
                                                            <>
                                                                <FormControl fullWidth>
                                                                    <InputLabel>
                                                                        <Typography className="text-dark">Level at which you are primarily teaching/taught:</Typography>
                                                                    </InputLabel>
                                                                    <Select
                                                                        label="Teaching Experience (in years)"
                                                                        value={field.value}
                                                                        onChange={(event, values) => {
                                                                            const selectedValue = event.target.value;
                                                                            field.onChange(selectedValue);
                                                                            const isOther = selectedValue === PrimarilyTeaching.find(option => option.label === 'Other').id;
                                                                            setShowPrimarilyOtherInput(isOther);
                                                                        }}
                                                                        renderValue={(selected) => {
                                                                            const selectedOption = PrimarilyTeaching.find((option) => option.id === selected);
                                                                            return selectedOption ? selectedOption.label : 'Select Primarily Teaching/Taught';
                                                                        }}
                                                                    >
                                                                        {PrimarilyTeaching.map((option) => (
                                                                            <MenuItem key={option.id} value={option.id}>
                                                                                <FormControlLabel
                                                                                    value={option.id}
                                                                                    control={<Radio checked={field.value === option.id} />}
                                                                                    label={option.label}
                                                                                />
                                                                            </MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                    {fieldState?.error && (
                                                                        <FormHelperText error>
                                                                            {fieldState.error.message}
                                                                        </FormHelperText>
                                                                    )}
                                                                </FormControl>

                                                                {/* Conditionally render input for "Other" option */}
                                                                {showPrimarilyOtherInput && (
                                                                    <TextField
                                                                        fullWidth
                                                                        label={<Typography className="text-dark">Please specify</Typography>}
                                                                        placeholder="Specify other primarily teaching"
                                                                        variant="outlined"
                                                                        margin="normal"
                                                                        value={otherPrimarily}
                                                                        onChange={(e) => setOtherPrimarily(e.target.value)}
                                                                    />
                                                                )}
                                                            </>
                                                        )}
                                                    />
                                                </Grid>

                                                <Grid item xs={4}>
                                                    <Controller
                                                        control={controlEducation}
                                                        name="natureWork"
                                                        render={({ field, fieldState }) => (
                                                            <>
                                                                <FormControl fullWidth>
                                                                    <InputLabel>
                                                                        <Typography className="text-dark">Nature of your work:{" "}<span style={{ color: "red" }}>*</span></Typography>
                                                                    </InputLabel>
                                                                    <Select
                                                                        label="Teaching Experience (in years)"
                                                                        value={field.value}
                                                                        onChange={(event) => {
                                                                            const selectedValue = event.target.value;
                                                                            field.onChange(selectedValue);
                                                                        }}
                                                                        renderValue={(selected) => {
                                                                            const selectedOption = NatureOfWork.find((option) => option.id === selected);
                                                                            return selectedOption ? selectedOption.name : 'Select Nature Of Work';
                                                                        }}
                                                                    >
                                                                        {NatureOfWork.map((option) => (
                                                                            <MenuItem key={option.id} value={option.id}>
                                                                                <FormControlLabel
                                                                                    value={option.id}
                                                                                    control={<Radio />}
                                                                                    label={option.name}
                                                                                />
                                                                            </MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                    {fieldState?.error && (
                                                                        <FormHelperText error>
                                                                            {fieldState.error.message}
                                                                        </FormHelperText>
                                                                    )}
                                                                </FormControl>
                                                            </>
                                                        )}
                                                    />
                                                </Grid>

                                                <Grid item xs={4}>
                                                    <FormControl fullWidth margin="normal" className="mt-0">
                                                        <FormLabel component="legend">Are you a Ph.D. Research Supervisor?</FormLabel>
                                                        <Controller
                                                            name="phdResearchSupervisor"
                                                            control={controlEducation}
                                                            defaultValue=""
                                                            render={({ field }) => (
                                                                <RadioGroup
                                                                    {...field}
                                                                    row
                                                                    onChange={(e) => field.onChange(e.target.value)}
                                                                >
                                                                    <FormControlLabel value="1" control={<Radio />} label="Yes" />
                                                                    <FormControlLabel value="0" control={<Radio />} label="No" />
                                                                </RadioGroup>
                                                            )}
                                                        />
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={4}>
                                                    <TextField
                                                        InputLabelProps={{ shrink: true }}
                                                        fullWidth
                                                        label={
                                                            <Typography className="text-dark">
                                                                Name of the university where you are a research supervisor:
                                                            </Typography>
                                                        }
                                                        {...registerEducation("nameResearchSupervisor")}
                                                        placeholder="University Name Where You Are A Research Supervisor"
                                                        disabled={isPhd !== "1"}
                                                    />
                                                </Grid>

                                            </Grid>
                                        </div>
                                    </Box>
                                    <Box sx={{ display: "flex", flexDirection: "row", pt: 2, mt: 3 }}>
                                        <Box sx={{ flex: "1 1 auto" }} />
                                        <Button type="submit" variant="contained" className="menus-color">
                                            Update
                                        </Button>
                                    </Box>
                                </form>
                            </Box>
                        )}
                    </>
                )}
            </>

            {/* Corporate Professional Development */}
            <>
                {key === "professionalDev" && (
                    <>
                        <Box>
                            <div className="pt-1 pb-3">
                                <div className="p-0">
                                    <div className="Architects-header d-flex align-items-center justify-content-center">
                                        <h2 className="signika-fontOEC text-center">Professional Development</h2>
                                    </div>
                                </div>
                            </div>
                        </Box>

                        <div className="container mt-3">
                            <div className="row">
                                <div className="col-md-8"></div>
                                <div className="col-md-4 mt-3">
                                    <Button
                                        onClick={() => window.history.back()}
                                        variant="contained"
                                        className="menus-color"
                                        style={{ float: "right", marginRight: 58, marginBottom: "15px" }}
                                    >
                                        Back
                                    </Button>
                                </div>
                            </div>
                        </div>

                        {loading ? (
                            <Spinner />
                        ) : (
                            <Box className="container" sx={{ width: "100%", marginTop: "0px", marginBottom: "50px" }}>
                                <form className="mt-2" onSubmit={handleSubmitProfession((data) => UpdateTeachersMemberProfession(data, userId))}>
                                    <Box>
                                        <div style={{ paddingLeft: 40, paddingRight: 40 }}>
                                            <Grid container rowSpacing={3} columnSpacing={5}>
                                                <Grid item xs={4}>
                                                    <FormControl component="fieldset">
                                                        <FormLabel component="legend">Are you a member of any other Teachers’ Association?</FormLabel>
                                                        <Controller
                                                            name="teacherAssociation"
                                                            control={controlProfession}
                                                            defaultValue=""
                                                            render={({ field }) => (
                                                                <RadioGroup row {...field}>
                                                                    <FormControlLabel value="1" control={<Radio />} label="Yes" />
                                                                    <FormControlLabel value="0" control={<Radio />} label="No" />
                                                                </RadioGroup>
                                                            )}
                                                        />
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={4}>
                                                    <TextField
                                                        InputLabelProps={{ shrink: true }}
                                                        fullWidth
                                                        label={
                                                            <Typography className="text-dark">
                                                                If yes, mention the name of those associations
                                                            </Typography>
                                                        }
                                                        {...registerProfession("associationName")}
                                                        placeholder="Mention The Name Of Those Associations"
                                                        disabled={isTeachersAsso !== "1"}
                                                    />
                                                </Grid>

                                                <Grid item xs={4}>
                                                    <Controller
                                                        control={controlProfession}
                                                        name="professionalDevelopment"
                                                        render={({ field, fieldState }) => (
                                                            <>
                                                                <Autocomplete
                                                                    multiple
                                                                    id="professionalDevelopment"
                                                                    fullWidth
                                                                    options={ProfessionalDevelopment}
                                                                    disableCloseOnSelect
                                                                    getOptionLabel={(option) => option?.name}
                                                                    filterSelectedOptions
                                                                    onChange={(event, values) => {
                                                                        const selectedValues = Array.isArray(values) ? values : [];
                                                                        const contactInfoIds = selectedValues.map((value) => value?.name);
                                                                        setShowProfessionOtherInput(selectedValues.some((value) => value?.name === 'Other'));
                                                                        field.onChange(contactInfoIds);
                                                                    }}
                                                                    value={
                                                                        field.value ?
                                                                            ProfessionalDevelopment.filter((info) => field.value.includes(info.name)) : []
                                                                    }
                                                                    renderOption={(props, option, { selected }) => (
                                                                        <li {...props}>
                                                                            <Checkbox style={{ marginRight: 8 }} checked={selected} />
                                                                            {option.name}
                                                                        </li>
                                                                    )}
                                                                    renderInput={(params) => (
                                                                        <TextField
                                                                            {...params}
                                                                            error={!!fieldState?.error}
                                                                            helperText={fieldState?.error?.message}
                                                                            label={
                                                                                <Typography className="text-dark">
                                                                                    Which of the following would you like to do for your continuous professional development?
                                                                                </Typography>
                                                                            }
                                                                            placeholder="Select Any Professional Development"
                                                                        />
                                                                    )}
                                                                />

                                                                {/* Conditionally render input for "Other" option */}
                                                                {showProfessionOtherInput && (
                                                                    <TextField
                                                                        fullWidth
                                                                        label={<Typography className="text-dark">Please specify</Typography>}
                                                                        placeholder="Specify other professional studies"
                                                                        variant="outlined"
                                                                        margin="normal"
                                                                        {...registerProfession('otherProfession')}
                                                                        value={otherProfession} // Use state variable here
                                                                        onChange={handleOtherProfessionChange}
                                                                    />
                                                                )}
                                                            </>
                                                        )}
                                                    />
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <h2 className="menus-green-color">Your areas of Interest:</h2>
                                                </Grid>

                                                <Grid item xs={4}>
                                                    <Controller
                                                        control={controlProfession}
                                                        name="expertiseLiteraryStudies"
                                                        render={({ field, fieldState }) => (
                                                            <>
                                                                <Autocomplete
                                                                    multiple
                                                                    id="expertiseLiteraryStudies"
                                                                    fullWidth
                                                                    options={LiteraryStudies}
                                                                    disableCloseOnSelect
                                                                    getOptionLabel={(option) => option?.name}
                                                                    filterSelectedOptions
                                                                    onChange={(event, values) => {
                                                                        const selectedValues = Array.isArray(values) ? values : [];
                                                                        const contactInfoIds = selectedValues.map((value) => value?.name);
                                                                        setShowLiteraryOtherInput(selectedValues.some((value) => value?.name === 'Other'));
                                                                        field.onChange(contactInfoIds);
                                                                    }}
                                                                    value={
                                                                        field.value ?
                                                                            LiteraryStudies.filter((info) => field.value.includes(info.name)) : []
                                                                    }
                                                                    renderOption={(props, option, { selected }) => (
                                                                        <li {...props}>
                                                                            <Checkbox style={{ marginRight: 8 }} checked={selected} />
                                                                            {option.name}
                                                                        </li>
                                                                    )}
                                                                    renderInput={(params) => (
                                                                        <TextField
                                                                            {...params}
                                                                            error={!!fieldState?.error}
                                                                            helperText={fieldState?.error?.message}
                                                                            label={<Typography className="text-dark">Literary Studies</Typography>}
                                                                            placeholder="Select Any Literary Studies"
                                                                        />
                                                                    )}
                                                                />

                                                                {/* Conditionally render input for "Other" option */}
                                                                {showLiteraryOtherInput && (
                                                                    <TextField
                                                                        fullWidth
                                                                        label={<Typography className="text-dark">Please specify</Typography>}
                                                                        placeholder="Specify other literary studies"
                                                                        variant="outlined"
                                                                        margin="normal"
                                                                        {...registerProfession('otherLit')}
                                                                        value={otherLit} // Use state variable here
                                                                        onChange={handleOtherLitChange}
                                                                    />
                                                                )}
                                                            </>
                                                        )}
                                                    />
                                                </Grid>

                                                <Grid item xs={4}>
                                                    <Controller
                                                        control={controlProfession}
                                                        name="expertiseLinguistics"
                                                        render={({ field, fieldState }) => (
                                                            <>
                                                                <Autocomplete
                                                                    multiple
                                                                    id="expertiseLinguistics"
                                                                    fullWidth
                                                                    options={Linguistics}
                                                                    disableCloseOnSelect
                                                                    getOptionLabel={(option) => option?.name}
                                                                    filterSelectedOptions
                                                                    onChange={(event, values) => {
                                                                        const selectedValues = Array.isArray(values) ? values : [];
                                                                        const contactInfoIds = selectedValues.map((value) => value?.name);
                                                                        const otherSelected = selectedValues.some((value) => value?.name === 'Other');
                                                                        setShowLinguisticsOtherInput(otherSelected);
                                                                        field.onChange(contactInfoIds);
                                                                    }}
                                                                    value={
                                                                        field.value ?
                                                                            Linguistics.filter((info) => field.value.includes(info.name)) : []
                                                                    }
                                                                    renderOption={(props, option, { selected }) => (
                                                                        <li {...props}>
                                                                            <Checkbox style={{ marginRight: 8 }} checked={selected} />
                                                                            {option.name}
                                                                        </li>
                                                                    )}
                                                                    renderInput={(params) => (
                                                                        <TextField
                                                                            {...params}
                                                                            error={!!fieldState?.error}
                                                                            helperText={fieldState?.error?.message}
                                                                            label={<Typography className="text-dark">Linguistics</Typography>}
                                                                            placeholder="Select Any Linguistics"
                                                                        />
                                                                    )}
                                                                />

                                                                {/* Conditionally render input for "Other" option */}
                                                                {showLinguisticsOtherInput && (
                                                                    <TextField
                                                                        fullWidth
                                                                        label={<Typography className="text-dark">Please specify</Typography>}
                                                                        placeholder="Specify other linguistics studies"
                                                                        variant="outlined"
                                                                        margin="normal"
                                                                        {...registerProfession('otherLing')}
                                                                        value={otherLing} // Use state variable here
                                                                        onChange={handleOtherLingChange}
                                                                    />
                                                                )}
                                                            </>
                                                        )}
                                                    />
                                                </Grid>

                                                <Grid item xs={4}>
                                                    <Controller
                                                        control={controlProfession}
                                                        name="expertiseELT"
                                                        render={({ field, fieldState }) => (
                                                            <>
                                                                <Autocomplete
                                                                    multiple
                                                                    id="expertiseELT"
                                                                    fullWidth
                                                                    options={EnglishLanguageTeaching}
                                                                    disableCloseOnSelect
                                                                    getOptionLabel={(option) => option?.name}
                                                                    filterSelectedOptions
                                                                    onChange={(event, values) => {
                                                                        const selectedValues = Array.isArray(values) ? values : [];
                                                                        const contactInfoIds = selectedValues.map((value) => value?.name);
                                                                        const otherSelected = selectedValues.some((value) => value?.name === 'Other');
                                                                        setShowELTOtherInput(otherSelected);
                                                                        field.onChange(contactInfoIds);
                                                                    }}
                                                                    value={
                                                                        field.value ?
                                                                            EnglishLanguageTeaching.filter((info) => field.value.includes(info.name)) : []
                                                                    }
                                                                    renderOption={(props, option, { selected }) => (
                                                                        <li {...props}>
                                                                            <Checkbox
                                                                                style={{ marginRight: 8 }}
                                                                                checked={selected}
                                                                            />
                                                                            {option.name}
                                                                        </li>
                                                                    )}
                                                                    renderInput={(params) => (
                                                                        <TextField
                                                                            {...params}
                                                                            error={!!fieldState?.error}
                                                                            helperText={fieldState?.error?.message}
                                                                            label={
                                                                                <Typography className="text-dark">
                                                                                    English Language Teaching (ELT)
                                                                                </Typography>
                                                                            }
                                                                            placeholder="Select Any English Language Teaching (ELT)"
                                                                        />
                                                                    )}
                                                                />

                                                                {/* Conditionally render input for "Other" option */}
                                                                {showELTOtherInput && (
                                                                    <TextField
                                                                        fullWidth
                                                                        label={<Typography className="text-dark">Please specify</Typography>}
                                                                        placeholder="Specify other ELT studies"
                                                                        variant="outlined"
                                                                        margin="normal"
                                                                        {...registerProfession('otherELT')}
                                                                        value={otherELT} // Use state variable here
                                                                        onChange={handleOtherELTChange}
                                                                    />
                                                                )}
                                                            </>
                                                        )}
                                                    />
                                                </Grid>

                                                <Grid item xs={4}>
                                                    <Controller
                                                        control={controlProfession}
                                                        name="expertiseInterdisciplinaryAreas"
                                                        render={({ field, fieldState }) => (
                                                            <>
                                                                <Autocomplete
                                                                    multiple
                                                                    id="expertiseInterdisciplinaryAreas"
                                                                    fullWidth
                                                                    options={InterdisciplinaryAreas}
                                                                    disableCloseOnSelect
                                                                    getOptionLabel={(option) => option?.name}
                                                                    filterSelectedOptions
                                                                    onChange={(event, values) => {
                                                                        const selectedValues = Array.isArray(values) ? values : [];
                                                                        const contactInfoIds = selectedValues.map((value) => value?.name);
                                                                        const otherSelected = selectedValues.some((value) => value?.name === 'Other');
                                                                        setShowInterOtherInput(otherSelected);
                                                                        field.onChange(contactInfoIds);
                                                                    }}
                                                                    value={
                                                                        field.value ?
                                                                            InterdisciplinaryAreas.filter((info) => field.value.includes(info.name)) : []
                                                                    }
                                                                    renderOption={(props, option, { selected }) => (
                                                                        <li {...props}>
                                                                            <Checkbox
                                                                                style={{ marginRight: 8 }}
                                                                                checked={selected}
                                                                            />
                                                                            {option.name}
                                                                        </li>
                                                                    )}
                                                                    renderInput={(params) => (
                                                                        <TextField
                                                                            {...params}
                                                                            error={!!fieldState?.error}
                                                                            helperText={fieldState?.error?.message}
                                                                            label={
                                                                                <Typography className="text-dark">
                                                                                    Interdisciplinary Areas
                                                                                </Typography>
                                                                            }
                                                                            placeholder="Select Any Interdisciplinary Areas"
                                                                        />
                                                                    )}
                                                                />

                                                                {/* Conditionally render input for "Other" option */}
                                                                {showInterOtherInput && (
                                                                    <TextField
                                                                        fullWidth
                                                                        label={<Typography className="text-dark">Please specify</Typography>}
                                                                        placeholder="Specify other interdisciplinary studies"
                                                                        variant="outlined"
                                                                        margin="normal"
                                                                        {...registerProfession('otherInter')}
                                                                        value={otherInter} // Use state variable here
                                                                        onChange={handleOtherInterChange}
                                                                    />
                                                                )}
                                                            </>
                                                        )}
                                                    />
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <h2 className="menus-green-color">Areas of Expertise:</h2>
                                                </Grid>

                                                <Grid item xs={4}>
                                                    <FormControl component="fieldset">
                                                        <FormLabel component="legend">
                                                            Are you
                                                            willing to have your areas of
                                                            expertise or interest listed publicly
                                                            so that other members can identify
                                                            potential collaboration
                                                            opportunities?
                                                        </FormLabel>
                                                        <Controller
                                                            name="areaOfExpertise"
                                                            control={controlProfession}
                                                            defaultValue=""
                                                            render={({ field }) => (
                                                                <RadioGroup row {...field}>
                                                                    <FormControlLabel value="1" control={<Radio />} label="Yes" />
                                                                    <FormControlLabel value="0" control={<Radio />} label="No" />
                                                                </RadioGroup>
                                                            )}
                                                        />
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={4}>
                                                    <FormControl component="fieldset">
                                                        <FormLabel component="legend">
                                                            Are you interested in being contacted
                                                            for the following activities?
                                                        </FormLabel>
                                                        <Controller
                                                            control={controlProfession}
                                                            name="professionalEngagement"
                                                            render={({ field, fieldState }) => (
                                                                <>
                                                                    <Autocomplete
                                                                        multiple
                                                                        id="professionalEngagement"
                                                                        fullWidth
                                                                        options={ProfessionalEngagement}
                                                                        disableCloseOnSelect
                                                                        getOptionLabel={(option) => option?.label}
                                                                        filterSelectedOptions
                                                                        onChange={(event, values) => {
                                                                            const selectedValues = Array.isArray(values) ? values : [];
                                                                            const contactInfoIds = selectedValues.map((value) => value?.label);
                                                                            field.onChange(contactInfoIds);
                                                                        }}
                                                                        value={
                                                                            field.value ?
                                                                                ProfessionalEngagement.filter((info) => field.value.includes(info.label)) : []
                                                                        }
                                                                        renderOption={(props, option, { selected }) => (
                                                                            <li {...props}>
                                                                                <Checkbox
                                                                                    style={{ marginRight: 8 }}
                                                                                    checked={selected}
                                                                                />
                                                                                {option.label}
                                                                            </li>
                                                                        )}
                                                                        renderInput={(params) => (
                                                                            <TextField
                                                                                {...params}
                                                                                error={!!fieldState?.error}
                                                                                helperText={fieldState?.error?.message}
                                                                                label={
                                                                                    <Typography className="text-dark">
                                                                                        Professional Engagement
                                                                                    </Typography>
                                                                                }
                                                                                placeholder="Select Any Professional Engagement"
                                                                            />
                                                                        )}
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                    </FormControl>
                                                </Grid>

                                            </Grid>
                                        </div>
                                    </Box>

                                    <Box sx={{ display: "flex", flexDirection: "row", pt: 2, mt: 3 }}>
                                        <Box sx={{ flex: "1 1 auto" }} />
                                        <Button type="submit" variant="contained" className="menus-color">
                                            Update
                                        </Button>
                                    </Box>
                                </form>
                            </Box>
                        )}
                    </>
                )}
            </>

            {/* Corporate Organization Details */}
            <>
                {key === "organization" && (
                    <>
                        <Box>
                            <div className="pt-1 pb-3">
                                <div className="p-0">
                                    <div className="Architects-header d-flex align-items-center justify-content-center">
                                        <h2 className="signika-fontOEC text-center">Organization Details</h2>
                                    </div>
                                </div>
                            </div>
                        </Box>

                        <div className="container mt-3">
                            <div className="row">
                                <div className="col-md-8"></div>
                                <div className="col-md-4 mt-3">
                                    <Button
                                        onClick={() => window.history.back()}
                                        variant="contained"
                                        className="menus-color"
                                        style={{ float: "right", marginRight: 58, marginBottom: "15px" }}
                                    >
                                        Back
                                    </Button>
                                </div>
                            </div>
                        </div>

                        {loading ? (
                            <Spinner />
                        ) : (
                            <Box className="container" sx={{ width: "100%", marginTop: "0px", marginBottom: "50px" }}>
                                <form className="mt-2" onSubmit={handleSubmitOrganisation((data) => UpdateOrganisationMember(data, userId))}>
                                    <Box>
                                        <div style={{ paddingLeft: 40, paddingRight: 40 }}>
                                            <Grid container rowSpacing={3} columnSpacing={5}>

                                                <Grid item xs={4}>
                                                    <Controller control={controlOrganisation} rules={{ required: "Please Select a type of your Organisation", }} name="typeOfOrganisation" render={({ field, fieldState }) => (
                                                        <FormControl fullWidth>
                                                            <InputLabel id="typeOfOrganisation">
                                                                Type of your organisation<span style={{ color: "red" }}>*</span>
                                                            </InputLabel>
                                                            <Select
                                                                style={{ backgroundColor: "#f0f0f0" }}
                                                                labelId="typeOfOrganisation"
                                                                id="typeOfOrganisation"
                                                                label={
                                                                    <Typography className="text-dark">
                                                                        Type of your organisation<span style={{ color: "red" }}>*</span>
                                                                    </Typography>
                                                                }
                                                                value={field.value ? field.value : ""}
                                                                name={field.eventTypeId}
                                                                variant="outlined"
                                                                onChange={(e) => field.onChange(e.target.value)}
                                                                error={!!fieldState.error}
                                                            >
                                                                <MenuItem value="1">
                                                                    Government
                                                                </MenuItem>
                                                                <MenuItem value="2">
                                                                    Private
                                                                </MenuItem>
                                                                <MenuItem value="3">
                                                                    Non-Profit
                                                                </MenuItem>

                                                            </Select>
                                                        </FormControl>
                                                    )} />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Controller control={controlOrganisation} rules={{ required: "Please Select a sector of your Organisation", }} name="industry" render={({ field, fieldState }) => (
                                                        <FormControl fullWidth>
                                                            <InputLabel id="industry">
                                                                What industry sector does your organisation belong to?<span style={{ color: "red" }}>*</span>
                                                            </InputLabel>
                                                            <Select
                                                                style={{ backgroundColor: "#f0f0f0" }}
                                                                labelId="industry"
                                                                id="industry"
                                                                label={
                                                                    <Typography className="text-dark">
                                                                        What industry sector does your organisation belong to?<span style={{ color: "red" }}>*</span>
                                                                    </Typography>
                                                                }
                                                                value={field.value ? field.value : ""}
                                                                name={field.eventTypeId}
                                                                variant="outlined"
                                                                onChange={(e) => field.onChange(e.target.value)}
                                                                error={!!fieldState.error}
                                                            >
                                                                {industries.map((data) => (
                                                                    <MenuItem key={data.id} value={data.id}>
                                                                        {data.name}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    )} />
                                                </Grid>

                                                <Grid item xs={4}>
                                                    <TextField
                                                        InputLabelProps={{ shrink: true }}
                                                        fullWidth
                                                        label={<Typography className="text-dark">Where is your head office located in?</Typography>}
                                                        {...registerOrganisation("headOfficeLocated")}
                                                        placeholder="Institution Where You Worked Last"
                                                    />
                                                </Grid>

                                                <Grid item xs={4}>
                                                    <TextField
                                                        InputLabelProps={{ shrink: true }}
                                                        fullWidth
                                                        label={<Typography className="text-dark">How many employees do you have approximately?</Typography>}
                                                        {...registerOrganisation("employees")}
                                                        placeholder="Institution Where You Worked Last"
                                                    />
                                                </Grid>

                                            </Grid>
                                        </div>
                                    </Box>

                                    <Box sx={{ display: "flex", flexDirection: "row", pt: 2, mt: 3 }}>
                                        <Box sx={{ flex: "1 1 auto" }} />
                                        <Button type="submit" variant="contained" className="menus-color">
                                            Update
                                        </Button>
                                    </Box>
                                </form>
                            </Box>
                        )}
                    </>
                )}
            </>
        </>
    );
}

export default CorporateProfile;