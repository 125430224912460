import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TopHeader from "../Component/Header/TopHeader";
import { Box } from "@mui/material";
import ReactPaginate from "react-paginate";
import "../style/css/pagination.css"; // Import CSS file for pagination styles
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import Spinner from "../Component/spinner/spinner";
import { useGetJoinMemberListMutation } from "../app/services/chapterServices";
import { useParams } from "react-router-dom";
import { toast } from 'react-toastify'

//Table header
function EnhancedTableHead() {
  const headCells = [
    { id: "title", numeric: false, disablePadding: true, label: "Name" },
    {
      id: "Email",
      numeric: true,
      disablePadding: false,
      label: "Phone",
    },
    {
      id: "eventLocation",
      numeric: true,
      disablePadding: false,
      label: "Email",
    },
  ];

  return (
    <>
      <TableHead>
        <TableRow className="menus-color">
          {headCells.map((headCell) => (
            <TableCell
              sx={{ textAlign: "center", color: "#ffff" }}
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
            >
              {headCell.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    </>
  );
}

export default function ChapterJoinMember() {
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState();
  const [totalPage, setTotalPage] = useState(0);
  const { id } = useParams()
  const [getJoinMemberList] = useGetJoinMemberListMutation()
  const [joinMember, setjoinMembers] = useState([]);

  //Get all events list
  useEffect(() => {
    getJoinMemberList({ "chapterId": id, page: page + 1, itemsPerPage: rowsPerPage })
      .unwrap()
      .then((res) => {
        setLoading(false);
        setjoinMembers(res?.data?.joiningMembers);
        setTotalPage(res?.data?.totalCount || 0);
      })
      .catch((error) => { toast.error(error) });
  }, [getJoinMemberList, page, rowsPerPage, id])
  useEffect(() => {
  }, [joinMember])

  useEffect(() => {
    setRowsPerPage(5);

  }, [page, rowsPerPage]); // Update useEffect dependencies

  //handlepageClick
  const handlePageClick = ({ selected }) => {
    setPage(selected);
  };

  //calculate pagecount
  const pageCount = Math.ceil(totalPage / rowsPerPage);

  return (
    <>
      <TopHeader />
      <div className='pt-1 pb-2 '>
        <div className='p-0'>
          <div className=' Architects-header d-flex align-items-center justify-content-center '>
            <h2 className='signika-fontOEC text-center '> Joined Member List</h2>
          </div>
        </div>
      </div>
      <Link
        style={{ float: "right", marginRight: "6rem" }}
      >
        <Button
          onClick={() => window.history.back()}
          variant="contained"
          className="menus-color"
          style={{ float: "right", color: "white" }}
        >
          Back
        </Button>
      </Link>
      {loading ? (
        <Spinner />
      ) : (
        <div>
          <Box
            sx={{ width: "80%", marginBottom: "110px", marginTop: "20px" }}
            className="memberTable"
          >
            <Paper sx={{ width: "100%", marginTop: '50px', mb: 2 }}>
              <TableContainer>
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                  <EnhancedTableHead />
                  <TableBody>
                    {joinMember?.length > 0 ? (
                      joinMember.map((join, index) => (
                        <TableRow
                          hover
                          key={index}
                          sx={{
                            cursor: "pointer",
                            backgroundColor:
                              index % 2 === 0 ? "#f0f0f0" : "white",
                          }}
                        >
                          <TableCell align="center">{join.memberName}</TableCell>
                          <TableCell align="center">
                            {join.memberPhone}
                          </TableCell>
                          <TableCell align="center">
                            {join.memberEmail}
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={6} align="center">
                          No Data Available
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Box>
          {joinMember?.length > 0 && (
            <>
              <ReactPaginate
                pageCount={pageCount} // Calculate total pages
                pageRangeDisplayed={2}
                marginPagesDisplayed={1}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                activeClassName={"active"}
                previousLabel={pageCount > 1 && page >= 1 ? "Previous" : null}
                nextLabel={pageCount > 1 && page + 1 !== pageCount ? "Next" : null}
                disabledClassName={"disablebtn"}
                forcePage={page}
              />
            </>
          )}
        </div>
      )}
    </>
  );
}
