import { api } from "./api";
 
const announcementServices = api.injectEndpoints({
  tagTypes: [
    'announcement'
    ],
  endpoints: (builder) => ({
     //save Announcement
     saveAnnouncement: builder.mutation({
      query: (formData) => ({
        url: "announcements/saveAnnouncements",
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: formData,
      }),
    }),
 
    //get All Announcement
    getAllAnnouncement: builder.query({
      query: () => ({
        url: "announcements/getall",
      }),
      invalidatesTags: ['announcement'],
    }),
 
    //get Announcement Details by Id
    getAnnouncementDetailsById: builder.query({
      query: (id) => "announcements/get/" + id,
    }),
 
    //Update Announcement
    updateAnnouncement: builder.mutation({
      query: (data) => ({
        url: "announcements/update/"+ data.id,
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: data.formData,
      }),
    }),
 
     //delete Announcement
     deleteAnnouncement: builder.mutation({
      query: (data) => ({
        url: "announcements/delete/"+ data.id,
        method: "PUT",
        headers: { "Content-Type": "application/json" },
      }),
    }),
 
     //enableDisable Announcement
     enableDisableAnnouncement: builder.mutation({
      query: (data) => ({
        url: "announcements/enableDisable/"+ data.id,
        method: "PUT",
        headers: { "Content-Type": "application/json" },
      }),
    }),
  }),
  overrideExisting: false,
});
 
export const {
  useSaveAnnouncementMutation,
  useUpdateAnnouncementMutation,
  useGetAllAnnouncementQuery,
  useLazyGetAnnouncementDetailsByIdQuery,
  useDeleteAnnouncementMutation,
  useEnableDisableAnnouncementMutation
} = announcementServices;