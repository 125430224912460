import { Modal } from "@mui/material";
import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { TextField } from "@mui/material";
import { Container } from "react-bootstrap";
import Button from "@mui/material/Button";
import TopHeader from "../../Component/Header/TopHeader";
import "../../style/css/journalForm.css";
import { useForm } from "react-hook-form";
import {
  useSaveEventTypeMutation,
  usePaginationEventTypeMutation,
  useUpdateEventTypeMutation,
  useDeleteEventTypeMutation,
} from "../../app/services/eventTypeServices";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@mui/icons-material/Edit";
import "../../style/css/SIGGroupTable.css";
import Typography from "@mui/material/Typography";
import { toast } from "react-toastify";
import Spinner from "../../Component/spinner/spinner"
import ReactPaginate from "react-paginate";
import Tooltip from "@material-ui/core/Tooltip";

function EventType() {
  const [data, setData] = useState([]);
  const [updatedData, setUpdatedData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [saveEventType] = useSaveEventTypeMutation();
  const [updateEventType] = useUpdateEventTypeMutation();
  const [deleteEventType] = useDeleteEventTypeMutation();
  const [selectedEventType, setSelectedEventType] = useState(null);
  const [getPagitionEventType] = usePaginationEventTypeMutation();
  const [page, setPage] = React.useState(0);//state for set page
  const [rowsPerPage, setRowsPerPage] = React.useState(5);//state for item per page
  const [totalPage, setTotalPage] = useState(0);
  const [allEventType, setAllEventType] = useState([])
  //handle model open for delete
  const handleOpen = (id) => {
    setOpen(true);
    setSelectedEventType(id);
  };
  // modal close
  const handleClose = () => setOpen(false);
  const { register, handleSubmit, trigger, setValue, reset, formState: { errors } } = useForm({
    defaultValues: {
      name: "",
      color: "#ffffff",
    },
  });

  //calling event data
  useEffect(() => {
    // setLoading(true);
    setRowsPerPage(5);
    fetchData(); // Fetch data on component mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]); // Update useEffect dependencies

  //getAllPendingEventList
  const fetchData = () => {
    var data = {
      type: 0,
      page: page + 1,
      itemsPerPage: rowsPerPage
    }
    getPagitionEventType(data).then((res) => {
      setLoading(false);
      setAllEventType(res?.data?.data?.eventTypeDetails || []);
      setTotalPage(res?.data?.data.totalCount || 0);
    })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching pending event list:", error);
      });
  };

  //page Click for event type
  const handlePageClick = ({ selected }) => {
    setPage(selected);
  };

  //sliced Event type
  const slicedEventType = allEventType.slice(0, rowsPerPage); // Adjust slicing

  //calculate pagecount
  const pageCount = Math.ceil(totalPage / rowsPerPage);

  //save and update event type
  const onSaveEventType = (formData) => {
    setLoading(true);
    // Trigger validation before submitting
    trigger().then((isValid) => {
      if (isValid) {
        setLoading(true)
        setData([...data, formData]);
        if (updatedData) {
          let requestIdAndData = { id: updatedData._id, data: formData };
          updateEventType(requestIdAndData)
            .then((res) => {
              if (res.data.status) {
                setLoading(false)
                fetchData();
                toast.success(res.data?.message)
                setUpdatedData(null)
                reset();
              } else {
                setLoading(false)
                toast.error(res.data?.err?.message)
              }
            })
            .catch((error) => {
              toast.error(error)
            });
        } else {
          saveEventType(formData)
            .then((res) => {
              setLoading(true)
              if (res.data?.status) {
                setLoading(false)
                fetchData();
                toast.success(res.data?.message)
                reset();
              }
              else {
                setLoading(false)
                toast.error(res.data?.err?.message)
              }
            })
            .catch((error) => {
              setLoading(false)
              toast.error(error)
            });
        }
      } else {
        toast.warning('Form Not Valid')
      }
    });
  };

  //patching event type form values
  const handleEdit = (updatedData) => {
    setUpdatedData(updatedData);
    setValue("name", updatedData.name);
    setValue("color", updatedData.color);
  };

  //cancel
  const handleCancel = () => {
    reset();
    setUpdatedData({});
  };

  //delete Event Type
  const handleDelete = (
    requestIdAndIsDeleteFlag = { id: selectedEventType, isDeleted: 1 }
  ) => {
    setLoading(true);
    deleteEventType(
      selectedEventType,
      requestIdAndIsDeleteFlag.isDeleted
    ).then((res) => {
      setLoading(true)
      if (res.data?.status) {
        setLoading(false)
        toast.success(res.data?.message)
        handleClose();
        if (allEventType.length <= 1) {
          setPage(page - 1);
          fetchData();
        } else {
          fetchData();
        }
      }
      else {
        setLoading(false)
        toast.error(res.data?.err.message)
      }
    });
  };

  //Table head for event type
  function EnhancedTableHead() {
    const headCells = [
      {
        id: "name",
        numeric: false,
        disablePadding: true,
        label: "Event Type Name",
      },
      { id: "calories", numeric: true, disablePadding: false, label: "Color" },
      { id: "actions", numeric: true, disablePadding: false, label: "Actions" },
    ];

    return (
      <>
        <TableHead>
          <TableRow className="menus-color">
            {headCells.map((headCell) => (
              <TableCell
                sx={{ textAlign: "center", color: "#ffff" }}
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "normal"}
              >
                {headCell.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
      </>
    );
  }
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "5px",
    p: 4,
  };
  return (
    <>
      <TopHeader />
      <Box>
        <div className='pt-1 '>
          <div className='p-0'>
            <div className=' Architects-header d-flex align-items-center justify-content-center '>
              <h2 className='signika-fontOEC text-center '>Event Type</h2>
            </div>
          </div>
        </div>
      </Box>
      <span className="ms-4 mb-5" style={{ width: '140px' }}></span>
      <Container
        maxWidth="md"
        style={{ marginTop: "10px", marginBottom: "30px" }}
      >
        <form onSubmit={handleSubmit(onSaveEventType)}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: 'center',
              gap: '20px',
              marginTop: '5px'
            }}
          >
            <TextField
              InputLabelProps={{ shrink: true }}
              style={{ width: "30%" }}
              id="name"
              label={(
                <Typography className='text-dark'>
                  Name <span style={{ color: 'red' }}>*</span>
                </Typography>
              )}
              variant="outlined"
              error={!!errors?.name}
              helperText={errors?.name?.message}
              placeholder="Please Enter a Event Type"
              {...register("name", { required: "Please Enter a Event Type" })}
            />
            <TextField
              label={(
                <Typography className='text-dark'>
                  Choose Color: <span style={{ color: 'red' }}>*</span>
                </Typography>
              )}
              InputLabelProps={{ shrink: true }}
              style={{ width: "30%", height: "60px", marginRight: "20px" }}
              id="Color"
              name="color"
              type="color"
              error={!!errors?.color}
              helperText={errors?.color?.message}
              {...register("color", { required: "Please Select a Color" })}
            />
            {updatedData && updatedData._id ? (
              <>
                <Button
                  variant="contained"
                  className="menus-color"
                  type="submit"
                  style={{
                    backgroundColor: "#1769aa",
                    color: "#fff",
                    padding: "6px 16px",
                    borderRadius: "4px",
                  }}
                >
                  Update
                </Button>
                <Button
                  variant="contained"
                  className="menus-color"
                  type="submit"
                  style={{
                    backgroundColor: "#1769aa",
                    color: "#fff",
                    padding: "6px 16px",
                    borderRadius: "4px",
                  }}
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </>
            ) : (
              <Button
                variant="contained"
                className="menus-color"
                type="submit"
                style={{
                  backgroundColor: "#1769aa",
                  color: "#fff",
                  padding: "6px 16px",
                  borderRadius: "4px",
                }}
              >
                Submit
              </Button>
            )}
          </div>
        </form>
      </Container>
      <Box sx={{ width: "80%" }} className="memberTable mb-5">
        {loading ? <Spinner></Spinner> :
          <Paper sx={{ width: "100%", marginBottom: 5 }}>
            <TableContainer>
              <Table sx={{ minWidth: 750, marginBottom: '30px' }} aria-labelledby="tableTitle">
                <EnhancedTableHead />
                <TableBody>
                  {
                    slicedEventType.length > 0 ? (slicedEventType.map((row, index) => (
                      <TableRow
                        hover
                        key={index}
                        sx={{
                          cursor: "pointer",
                          backgroundColor: index % 2 === 0 ? "#f0f0f0" : "white",
                        }}
                      >
                        <TableCell align="center">{row.name}</TableCell>
                        <TableCell
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              backgroundColor: row.color,
                              width: "100px",
                              height: "20px",
                            }}
                          ></div>
                        </TableCell>
                        <TableCell align="center">
                          <Tooltip title="Edit">
                            <IconButton
                              aria-label="edit"
                              onClick={() => handleEdit(row)}
                            >
                              <EditIcon style={{ color: "green" }} />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Delete">
                            <IconButton
                              aria-label="delete"
                              onClick={() => {
                                handleOpen(row._id);
                              }}
                            >
                              <DeleteIcon style={{ color: "red" }} />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={6} align="center">
                          No Data Available
                        </TableCell>
                      </TableRow>
                    )}
                </TableBody>
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box
                    sx={style}
                    className="d-flex align-items-center flex-column gap-3"
                  >
                    <Typography
                      id="modal-modal-title"
                      style={{ color: "#611f1e" }}
                      variant="h6"
                      component="h2"
                    >
                      Are You Surely Want to Delete this Event Type?
                    </Typography>
                    <div>
                      <Button
                        variant="outlined"
                        style={{ color: "#2e7d32", border: "1px solid rgba(46, 125, 50, 0.5)", padding: "5px 15px", borderRadius: "4px" }}
                        onClick={handleClose}
                      >
                        No
                      </Button>
                      <Button
                        className="mx-2"
                        variant="contained"
                        style={{ backgroundColor: "#dc3545", color: "white", padding: "5px 15px", borderRadius: "4px" }}
                        onClick={() => handleDelete()}
                      >
                        Yes
                      </Button>
                    </div>
                  </Box>
                </Modal>
              </Table>
            </TableContainer>
          </Paper>
        }
        {slicedEventType.length > 0 ? (
          <ReactPaginate
            pageCount={pageCount} // Calculate total pages
            pageRangeDisplayed={2}
            marginPagesDisplayed={1}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            activeClassName={"active"}
            previousLabel={pageCount > 1 && page >= 1 ? "Previous" : null}
            nextLabel={pageCount > 1 && page + 1 !== pageCount ? "Next" : null}
            disabledClassName={"disablebtn"}
            forcePage={page}
          />
        ) : (<></>)}
      </Box>
    </>
  );
}

export default EventType;