import { api } from './api'
// const boundary = `----${new Date().getTime()}`
const ResumeService = api.injectEndpoints({
    endpoints: (builders) => ({
        getAllResume: builders.query({
            query: () => ({
                url: "resume/getall",
            }),
            invalidatesTags: ["Resume"],
        }),
        deleteResume: builders.mutation({
            query: (id) => ({
                url: `resume/delete/${id}`,
                method: "PUT",
                // body: {data},
            }),
            invalidatesTags: ['Resume']
        }),
        enableDisableResume: builders.mutation({
            query: (id) => ({
                url: `resume/enableDisable/${id}`,
                method: "PUT",
                // body: {data},
            }),
            invalidatesTags: ['Resume']
        }),
        getByResumeId:builders.query({
            query:(id)=>({
                url: `resume/get/${id}`,
                method: "GET", 
            })
        }),
        saveResume:builders.mutation({
         query:(data)=>({
            url :'resume/saveResume',
            method:'POST',
            body:data,
            // ContentType: 'application/x-www-form-urlencoded'
            // headers: { "Content-Type": "application/x-www-form-urlencoded" },
            //  headers:{'Content-Type': "application/x-www-form-urlencoded"},
             
            //  formData:true   
         })
        }),
        paginationResume: builders.mutation({
            query: (data) => ({
              url: "resume/getAllResumePagination",
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: data,
            }),
            invalidatesTags:['Resume']
          }),
          updateResume:builders.mutation({
            query: (data) => ({
                url: `resume/update/${data.id}`,
                method: "PUT",
                body: data.formData,
              }),
              invalidatesTags:['Resume']
          }),
    })
})
export const { useLazyGetAllResumeQuery, useDeleteResumeMutation,useEnableDisableResumeMutation,useLazyGetByResumeIdQuery,useSaveResumeMutation,usePaginationResumeMutation,useUpdateResumeMutation } = ResumeService