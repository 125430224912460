import {api} from './api'
const loginApi=api.injectEndpoints({
    endpoints: (build) => ({

      //login
        login: build.mutation({
          query: (userdata) =>({
            url:'auth/login',
            method:'POST',
            headers:{'Content-Type': 'application/x-WWW-form-urlencoded',},
            body:userdata,
          }),
        }),

        //forgot password
        forgotPassword: build.mutation({
          query: (formData) =>({
            url:'auth/forgotPassword',
            method:'POST',
            headers:{'Content-Type': 'application/json',},
            body:formData,
          }),
        }),

        //reset password
        resetPassword: build.mutation({
          query: (formData) =>({
            url:'auth/resetPassword',
            method:'POST',
            headers:{'Content-Type': 'application/json',},
            body:formData,
          }),
        }),
        
      }),
      overrideExisting: false,
})
export const {
  useLoginMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
}=loginApi