import { useLocation, Navigate, Outlet } from "react-router-dom"
import { useSelector } from "react-redux"
import { selectCurrentAccessToken } from "./authSlice"

const RequireAuth = () => {
    //accessToken
    const accessToken = useSelector(selectCurrentAccessToken)
    const location = useLocation()
    sessionStorage.setItem('lastActivePage', location.pathname);

    return (
        accessToken
            ? <Outlet />
            : <Navigate to="/login" state={{ from: location }} replace />
    )
}

export default RequireAuth