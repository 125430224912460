import React, { useEffect, useState } from "react";
import "../../style/css/about.css";
import { useGetAllChaptersPaginationMutation } from "../../app/services/chapterServices";
import { useGetAllMemberListMutation } from "../../app/services/memberListServices";
import { useGetAllSIGPaginationMutation } from "../../app/services/SIGServices";
import { Container } from "@mui/material";

function AboutSection() {
  const [totalChapterPage, setTotalChapterPage] = useState([]);
  const [totalMemberPage, setTotalMemberPage] = useState([]);
  const [totalSIGPage, setTotalSIGPage] = useState([]);
  const [runningNumber, setRunningNumber] = useState(0); // Start with 0
  const [runningSigNumber, setRunningSigNumber] = useState(0); // Start with 0
  const [runningChapterNumber, setRunningChapterNumber] = useState(0); // Start with 0
  const [getAllChaptercount] = useGetAllChaptersPaginationMutation()
  const [getAllMembercount] = useGetAllMemberListMutation();
  const [getAllSIGcount] = useGetAllSIGPaginationMutation();

  useEffect(() => {
    //Chapter Count
    getAllChaptercount({ page: 1, itemsPerPage: 5 }).then((res) => {
      if (res?.data?.status) {
        const data = res?.data?.data?.totalCount;
        setTotalChapterPage(data || 0);
      }
    });

    //Member Count
    getAllMembercount({ page: 1, itemsPerPage: 5 }).then((res) => {
      if (res?.data?.status) {
        const data = res?.data?.data?.totalCount;
        setTotalMemberPage(data || 0);
      }
    });

    //Get All Sig Count
    getAllSIGcount({ page: 1, itemsPerPage: 5 }).then((res) => {
      if (res?.data?.status) {
        const data = res?.data?.data?.totalCount;
        setTotalSIGPage(data || 0);
      }
    });
  }, [getAllChaptercount, getAllMembercount, getAllSIGcount]);

  // Decrease the interval for smoother transition
  useEffect(() => {
    const interval = setInterval(() => {
      if (runningNumber < totalMemberPage) {
        setRunningNumber((prevNumber) => prevNumber + 1);
      }
    }, 100);

    return () => clearInterval(interval);
  }, [runningNumber, totalMemberPage]);

  // Decrease the interval for smoother transition
  useEffect(() => {
    const interval = setInterval(() => {
      if (runningSigNumber < totalSIGPage) {
        setRunningSigNumber((prevNumber) => prevNumber + 1);
      }
    }, 100);
    return () => clearInterval(interval);
  }, [runningSigNumber, totalSIGPage]);

  // Decrease the interval for smoother transition
  useEffect(() => {
    const interval = setInterval(() => {
      if (runningChapterNumber < totalChapterPage) {
        setRunningChapterNumber((prevNumber) => prevNumber + 1);
      }
    }, 100);
    return () => clearInterval(interval);
  }, [runningChapterNumber, totalChapterPage]);

  return (
    <div style={{ position: "relative", top: "53px" }} className="abt-bg">
      <div className="row aboutsection-container">
        <div className="d-flex justify-content-center p-0">
          <div>
            <div className="d-flex ms-5">
              <h2 className="custom-header ms-0">About ELTAI</h2>
              <hr className="about-hr ms-4 me-4 d-none d-md-block" />
            </div>
            <div className="ms-auto align-items-center mt-2 mt-md-0">
              <p className="w-60 text-justify signika fw-bold abt-text">
                Founded on August 7, 1969, by the visionary Padmashri S. Natarajan and five pioneering educators, the English Language Teachers' Association of India (ELTAI) has flourished into the nation's largest network of English teachers. From an initial 250 members, it has grown to over 5,500 strong, spanning 60 chapters across India. ELTAI stands as a global force, collaborating with esteemed bodies like IATEFL (UK) and TESOL (USA), and offering journals, e-publications, and transformative events. Through our conferences, workshops, and consultancy services, we empower educators with cutting-edge insights, shaping the future of English teaching in India.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="row mb-5">
          <div className="event-counter-section section-padding">
            <>
              <Container maxWidth="xl" className="ms-md-3 ms-0">
                <div className="row align-items-center ">
                  <div className="col-xl-3 col-md-12 col-sm-12 text-md-center">
                    <div className="section-title">
                      <h5 className="sub-title signika">Become a member</h5>
                      <h2 className="title signika">Grow with us.</h2>
                      <h2 className="title signika">
                      Lead the future.
                      </h2>
                    </div>
                  </div>
                  <div className="col-xl-9 col-md-12 col-sm-12">
                    <div className="row d-flex justify-content-center">
                      <div className="counter-item">
                        <div className="counter-item-inner">
                          <h1 className="counter-numb counter color1">5000+</h1>
                          <span className="signika member-config">
                            Individual Members
                          </span>
                        </div>
                      </div>
                      <div className="counter-item">
                        <div className="counter-item-inner">
                          <h1 className="counter-numb counter color2">200+</h1>
                          <span className="signika chapter-config">
                            Institutional Members
                          </span>
                        </div>
                      </div>
                      <div className="counter-item">
                        <div className="counter-item-inner">
                          <h1 className="counter-numb counter color3">70+</h1>
                          <span className="signika sig-config">
                          Chapters Across India
                          </span>
                        </div>
                      </div>
                      <div className="counter-item">
                        <div className="counter-item-inner">
                          <h1 className="counter-numb counter color2">100+</h1>
                          <span className="signika sig-config">
                          Partners Across the Globe
                          </span>
                        </div>
                      </div>
                      <div className="counter-item">
                        <div className="counter-item-inner">
                          <h1 className="counter-numb counter color1">900+</h1>
                          <span className="signika sig-config">Events </span>
                          <br />
                          <span className="signika sig-config">So Far</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Container>
            </>
          </div>
        </div>
      </div>
    </div>
    //    <div>
    //   <Box
    //     m={1} //margin
    //     display="flex"
    //     justifyContent="flex-end"
    //     alignItems="flex-end"
    //     paddingTop={5}
    //   >
    //     {currentScope === 'PORTAL_SUPER_ADMIN' && (
    //       <Button variant="contained" className='' color="primary" sx={{ height: 40, backgroundColor: '#0071b5', fontWeight: 600 }}>
    //         <Link className='text-decoration-none text-white' to='/admin/aboutUs'>Learn More</Link>
    //       </Button>
    //     )}
    //     {currentScope === 'PORTAL_SIG_ADMIN' && (
    //       <Button variant="contained" className='' color="primary" sx={{ height: 40, backgroundColor: '#0071b5', fontWeight: 600 }}>
    //         <Link className='text-decoration-none text-white' to='/sigAdmin/aboutUs'>Learn More</Link>
    //       </Button>
    //     )}
    //     {currentScope === 'PORTAL_CHAPTER_ADMIN' && (
    //       <Button variant="contained" className='' color="primary" sx={{ height: 40, backgroundColor: '#0071b5', fontWeight: 600 }}>
    //         <Link className='text-decoration-none text-white' to='/chapterAdmin/aboutUs'>Learn More</Link>
    //       </Button>
    //     )}
    //     {currentScope === 'PORTAL_MEMBER' && (
    //       <Button variant="contained" className='' color="primary" sx={{ height: 40, backgroundColor: '#0071b5', fontWeight: 600 }}>
    //         <Link className='text-decoration-none text-white' to='/member/aboutUs'>Learn More</Link>
    //       </Button>
    //     )}
    //     {(currentScope === '' || currentScope === undefined) && (
    //       <Button variant="contained" className='' color="primary" sx={{ height: 40, backgroundColor: '#0071b5', fontWeight: 600 }}>
    //         <Link className='text-decoration-none text-white' to='/aboutUs'>Learn More</Link>
    //       </Button>
    //     )}
    //   </Box>
    // </div>
  );
}

export default AboutSection;
