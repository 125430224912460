import React from 'react'
import { Outlet } from 'react-router-dom'

const ChapterAdmin = () => {
  return (
    <>
      <Outlet></Outlet>
      </>
  )
}

export default ChapterAdmin
