import React, { useState, useEffect } from 'react'
import { Box, Container, Button, Paper, Typography, Alert, IconButton } from '@mui/material'
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import TopHeader from '../../../Component/Header/TopHeader'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import '../../../style/css/pagination.css'
import ReactPaginate from "react-paginate";
import {
  useDeleteResumeMutation,
  useEnableDisableResumeMutation, usePaginationResumeMutation
} from '../../../app/services/resumeService';
import { Lock, LockOpen } from '@material-ui/icons';

import VisibilityIcon from '@mui/icons-material/Visibility';
import Spinner from "../../../Component/spinner/spinner"


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "100%",
  height: "100%",
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  overflowY: "scroll",
  scrollbarWidth: "none",

};
const backdropStyle = {
  position: 'fixed',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: 'rgba(0,0,0,0.3)',
  padding: 150,
  scrollbarWidth: "none",
};

// The modal "window"
const modalStyle = {
  backgroundColor: '#fff',
  borderRadius: 5,
  maxWidth: 900,
  minHeight: 1300,
  margin: '0 auto',
  padding: 30,
  scrollbarWidth: "none",
};
function EnhancedTableHead() {

  const headCells = [
    { id: 'name', numeric: false, disablePadding: true, label: 'Name' },
    { id: 'jobs', numeric: true, disablePadding: false, label: 'Job Description' },
    { id: 'phone', numeric: false, disablePadding: true, label: 'Phone' },
    { id: 'email', numeric: true, disablePadding: false, label: 'Email' },
    { id: 'skills', numeric: false, disablePadding: true, label: 'Skills' },
    // { id: 'resume', numeric: true, disablePadding: false, label: 'Resume' },
    { id: 'actions', numeric: true, disablePadding: false, label: 'Actions' },
  ];

  return (
    <>
      <TableHead>
        <TableRow className="menus-color">
          {headCells.map((headCell) => (
            <TableCell
              sx={{ textAlign: "center", color: "#ffff" }}
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
            >
              {headCell.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    </>
  );
}
function EnhancedTableToolbar({ numSelected }) {
  return (
    <Toolbar
      sx={{
        backgroundColor: '#0072bb',
        textAlign: 'center',
        color: 'white',
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >
      <Typography
        sx={{ flex: '1 1 100%' }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        {numSelected > 0 && numSelected ? `${numSelected} selected` : 'Resume Table'}
      </Typography>
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};
function ResumeTable() {
  const [loading, setLoading] = useState(true);
  const [successMessage, setSuccessMessage] = useState("");
  const [currentPage, setCurrentPage] = useState(1); const [DeleteMessage, setDeleteMessage] = useState()
  const [enableMessage, setEnableMessage] = useState()
  const [deleteResume] = useDeleteResumeMutation();
  const [enableDisableResume] = useEnableDisableResumeMutation()
  const [paginationResume] = usePaginationResumeMutation()
  let [totalCount, setTotalCount] = useState(0);
  const [getAll, setGetAll] = useState([])
  const [selectedResume, setSelectedResume] = useState(null)
  const itemsPerPage = 5;
  const navigate = useNavigate()
  const addResume = () => {
    navigate('/admin/addResume')
  }

  useEffect(() => {
    setLoading(true);
    paginationResume({ page: currentPage, itemsPerPage }).unwrap().then((res) => {
      if (res?.status) {
        setLoading(false);
        setGetAll(res?.data?.memberDetails)
        setTotalCount(res.data.totalCount);
      }
    })
  }, [currentPage, itemsPerPage, paginationResume])
  const totalPages = Math.ceil(totalCount / itemsPerPage);
  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected + 1);
  };
  useEffect(() => { }, [])


  const handleDelete = (id) => {
    setLoading(true);
    deleteResume(id).then((res) => {
      if (res.data.status) {
        setLoading(false);
        setDeleteMessage("Resume Table Deleted SucuessFully")
        setTimeout(() => setSuccessMessage(false), 4000)
        paginationResume()
      }
    })
  }
  const handleEnable = (id) => {
    setLoading(true);
    enableDisableResume(id).then((res) => {
      if (res.data.status) {
        setLoading(false);
        if (res?.data?.data?.isDeleted === 0) {
          setEnableMessage('Resume  Enable SucuessFully')
          setTimeout(() => setSuccessMessage(false), 4000)
          paginationResume()
        } else {
          setEnableMessage('Resume  Disable SucuessFully')
          setTimeout(() => setSuccessMessage(false), 4000)
          paginationResume()
        }
      }
    }).catch(err => console.error(err))
  }
  const handleEdit = (id) => {
    navigate(`/admin/addResume/${id}`)
  }
  const setOpenSelect = (resume) => {
    setSelectedResume(resume)
  }

  return (
    <Box>
      <TopHeader />
      <Box className="banner_text">
      </Box>
      {loading ? (
        <Spinner />
      ) : (
        <div className='ResumeTable-container'>
          <Container maxWidth={'lg'}>
            <div className="row justify-content-end mt-5 ">
              <div className="col-auto">
                <Button variant="contained"  style={{ marginRight: "35px" }} className='justify-content-end menus-color' onClick={() => { addResume() }}>Add</Button>
              </div>
            </div>
          </Container>
          <Container maxWidth={'xl'}>


            {
              DeleteMessage && (
                <Alert severity="error" variant='filled'>{DeleteMessage}</Alert>
              )
            }
            {
              enableMessage && (
                <Alert severity="sucessfully" variant='filled'>{enableMessage}</Alert>
              )
            }

            <Container maxWidth={"xl"}>
              <Box sx={{ width: '80%' }} className="memberTable ">
                <Paper sx={{ width: '100%', mb: 2 }}>
                  <div className='float-right'>
                    {successMessage && (
                      <Alert variant="filled" severity="success">
                        {successMessage}
                      </Alert>
                    )}
                    {enableMessage && (
                      <Alert variant="filled" severity="success">
                        {enableMessage}
                      </Alert>
                    )}
                  </div>
                  <EnhancedTableToolbar />
                  <TableContainer>
                    <Table
                      sx={{ minWidth: 750 }}
                      aria-labelledby="tableTitle"
                    // size={dense ? 'small' : 'medium'}
                    >
                      <EnhancedTableHead />
                      <TableBody>


                        {
                          // (getResumeData && getResumeData >0)?
                          getAll?.map((resume, index) => (
                            <TableRow
                              hover
                              key={resume._id}
                              sx={{ cursor: 'pointer', backgroundColor: index % 2 === 0 ? '#f0f0f0' : 'white' }}
                            >
                              <TableCell align="center">{resume.contactFirstName}</TableCell>
                              <TableCell align="center">{resume.description}</TableCell>
                              <TableCell align="center">{resume.contactPhone}</TableCell>
                              <TableCell align="center">{resume.contactEmail}</TableCell>
                              <TableCell align="center">{resume.skills}</TableCell>
                              {/* <TableCell align="center">{resume.uploadResume}</TableCell> */}
                              <TableCell align="center">
                                <IconButton aria-label="delete" onClick={() => { handleEdit(resume._id) }}  >
                                  <EditIcon style={{ color: 'blue' }} />
                                </IconButton>
                                <IconButton aria-label="approve" onClick={() => { handleDelete(resume._id) }} >
                                  <DeleteIcon style={{ color: 'red' }} />
                                </IconButton>

                                {((resume.isDeleted === 2) ? <>
                                  <IconButton aria-label="approve" onClick={() => { handleEnable(resume._id) }} >
                                    <Lock style={{ color: 'blue' }} />
                                  </IconButton>
                                </> : <>   <IconButton aria-label="approve" onClick={() => { handleEnable(resume._id) }} >
                                  <LockOpen style={{ color: 'green' }} />


                                </IconButton></>)}
                                <IconButton aria-label="approve" data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={() => setOpenSelect(resume)}  >
                                  <VisibilityIcon className='icon-vieweye-color' />
                                  <div>
                                    <div className="modal fade" style={backdropStyle} id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                      <div className="modal-dialog modal-dialog-scrollable" style={modalStyle} >
                                        <div className="modal-content" >
                                          <div className="modal-header">
                                            <h1 className="modal-title fs-5 text-center" id="exampleModalLabel">Resume</h1>
                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                          </div>
                                          <div className="modal-body" style={{ height: '800px' }}>
                                            <Box sx={style}>
                                              <div className='container'>
                                                <div className='row' style={{ display: 'grid', justifyContent: 'center', overflowY: "scroll", scrollbarWidth: "none" }}>'
                                                  <div className='col-12 center'>

                                                    <h2 className='text-center starting-chapter-container'>Resume</h2>
                                                    <Container maxWidth='md'>
                                                      <div className='row'>
                                                        <div className='col-md-6'>
                                                          <h4 className='mt-3 starting-chapter-container'>Title:</h4>
                                                          <h4 className='mt-3 starting-chapter-container'>Description:</h4>
                                                          <h4 className='mt-3 starting-chapter-container'>Industry</h4>
                                                          <h4 className='mt-3 starting-chapter-container'>Location:</h4>
                                                          <h4 className='mt-3 starting-chapter-container'>Skills:</h4>
                                                          <h4 className='mt-4 starting-chapter-container'>Experience:</h4>
                                                          <h4 className='mt-3 starting-chapter-container'>Awards Certification:</h4>
                                                          <h4 className='mt-3 starting-chapter-container'>Education:</h4>
                                                          <h4 className='mt-3 starting-chapter-container'>Contact:</h4>

                                                        </div>
                                                        <div className='col-md-6'>
                                                          <p className='text-dark mt-3  starting-chapter-container' style={{ fontSize: '20px' }}>{selectedResume?.title}</p>
                                                          <p className='text-dark mt-3  starting-chapter-container' style={{ fontSize: '20px' }}>{selectedResume?.description}</p>
                                                          <p className='text-dark mt-2  starting-chapter-container' style={{ fontSize: '20px' }}>{selectedResume?.industry}</p>
                                                          <p className='text-dark mt-3  starting-chapter-container' style={{ fontSize: '20px' }}>{selectedResume?.location}</p>
                                                          <p className='text-dark mt-3  starting-chapter-container' style={{ fontSize: '20px' }}>{selectedResume?.skills}</p>
                                                          <p className='text-dark mt-4  starting-chapter-container' style={{ fontSize: '20px' }}>{selectedResume?.experience}</p>
                                                          <p className='text-dark mt-4  starting-chapter-container' style={{ fontSize: '20px' }}>{selectedResume?.awardsCertification}</p>
                                                          <p className='text-dark mt-4  starting-chapter-container' style={{ fontSize: '20px' }}>{selectedResume?.education}</p>
                                                          <p className='text-dark mt-2  starting-chapter-container' style={{ fontSize: '20px' }}>Name:{selectedResume?.contactFirstName}</p>
                                                          <p className='text-dark mt-2  starting-chapter-container' style={{ fontSize: '20px' }}>Phone:{selectedResume?.contactPhone}</p>
                                                          <p className='text-dark mt-2  starting-chapter-container' style={{ fontSize: '20px' }}>Email:{selectedResume?.contactEmail}</p>
                                                          <p className='text-dark mt-2  starting-chapter-container' style={{ fontSize: '20px' }}>Website:{selectedResume?.contactWebsite}</p>
                                                          <p className='text-dark mt-2  starting-chapter-container' style={{ fontSize: '20px' }}>Fax:{selectedResume?.contactFax
                                                          }</p>
                                                          <Link to={selectedResume?.uploadResume} target='_blank' className=' starting-chapter-container' style={{ textDecoration: "none" }}>Resume Preview</Link>
                                                        </div>
                                                      </div>
                                                    </Container>
                                                    <div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </Box>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                </IconButton>

                              </TableCell>
                            </TableRow>
                          ))
                          //   :
                          //   (<> 
                          //       <TableRow>
                          //         <TableCell colSpan={6} align="center">No Data Available</TableCell>
                          //       </TableRow></>)
                        }
                      </TableBody>

                    </Table>
                  </TableContainer>
                  {/* <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={getAllGallery.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            /> */}
                </Paper>
                <ReactPaginate
                  pageCount={totalPages} // Replace with the total number of pages
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={2}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination"}
                  activeClassName={"active"}
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                />
              </Box>
            </Container>
          </Container>

        </div>
      )}
    </Box>
  )
}

export default ResumeTable
