import React, { useState, useEffect, useCallback } from 'react'
import TopHeader from '../../Component/Header/TopHeader'
import Box from '@mui/material/Box';
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Grid from "@mui/material/Grid";
import { AppBar, Toolbar, Typography, IconButton, Container, } from '@mui/material';
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import PropTypes from "prop-types";
import ReactPaginate from "react-paginate";
import { useGetPendingEventListMutation, } from '../../app/services/eventService';
import { useGetPendingForumListMutation, } from '../../app/services/forumServices';
import { useGetPendingNewsListMutation, } from '../../app/services/journalServices';
import { useGetPendingGalleryListMutation } from '../../app/services/galleryService';
import Tooltip from '@mui/material/Tooltip';
import { useNavigate } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useLazyGetCategoryDetailsByIdQuery } from "../../app/services/categoryService";
import Spinner from "../../Component/spinner/spinner"
import { toast } from 'react-toastify';
//event Table 

//table head for event
function EnhancedEventTableHead() {
    const headCells = [
        { id: "title", numeric: false, disablePadding: true, label: "Event Title" },
        {
            id: "eventDate",
            numeric: true,
            disablePadding: false,
            label: "Event Date",
        },
        {
            id: "organizer",
            numeric: true,
            disablePadding: false,
            label: "Organizer",
        },
        {
            id: "eventLocation",
            numeric: true,
            disablePadding: false,
            label: "Event Location",
        },
        { id: "actions", numeric: true, disablePadding: false, label: "Actions" },
    ];

    return (
        <>
            <TableHead>
                <TableRow className="menus-color">
                    {headCells.map((headCell) => (
                        <TableCell
                            sx={{ textAlign: "center", color: "#ffff" }}
                            key={headCell.id}
                            align={headCell.numeric ? "right" : "left"}
                            padding={headCell.disablePadding ? "none" : "normal"}
                        >
                            {headCell.label}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        </>
    );
}

//News table

//table head for News
function EnhancedNewsTableHead() {
    const headCells = [
        {
            id: "Headline",
            numeric: false,
            disablePadding: true,
            label: "Headline",
        },
        {
            id: "Summary",
            numeric: false,
            disablePadding: true,
            label: "Summary",
        },
        {
            id: "Author Name",
            numeric: false,
            disablePadding: true,
            label: "Author Name",
        },
        { id: "actions", numeric: true, disablePadding: false, label: "Actions" },
    ];

    return (
        <>
            <TableHead>
                <TableRow className="menus-color">
                    {headCells.map((headCell) => (
                        <TableCell
                            sx={{ textAlign: "center", color: "#ffff" }}
                            key={headCell.id}
                            align={headCell.numeric ? "right" : "left"}
                            padding={headCell.disablePadding ? "none" : "normal"}
                        >
                            {headCell.label}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        </>
    );
}

//forum table

//table toolbar for forum

//table head for forum
function EnhancedForumTableHead() {
    const headCells = [
        {
            id: "categoryName",
            numeric: false,
            disablePadding: true,
            label: "Category Name",
        },
        {
            id: "name",
            numeric: false,
            disablePadding: true,
            label: "Name",
        },
        {
            id: "updatedDate",
            numeric: false,
            disablePadding: true,
            label: "Date",
        },
        { id: "actions", numeric: true, disablePadding: false, label: "Actions" },
    ];

    return (
        <>
            <TableHead>
                <TableRow className="menus-color">
                    {headCells.map((headCell) => (
                        <TableCell
                            sx={{ textAlign: "center", color: "#ffff" }}
                            key={headCell.id}
                            align={headCell.numeric ? "right" : "left"}
                            padding={headCell.disablePadding ? "none" : "normal"}
                        >
                            {headCell.label}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        </>
    );
}

//Gallery table

//table toolbar for Gallery
function EnhancedGalleryTableToolbar({ numSelected = 0 }) {
    return (
        <Toolbar
            sx={{
                backgroundColor: "#611f1e",
                textAlign: "center",
                color: "white",
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
            }}
        >
            <Typography
                sx={{ flex: "1 1 100%" }}
                variant="h6"
                id="tableTitle"
                component="div"
            >
                {numSelected > 0 ? `${numSelected} selected` : "Gallery Table"}
            </Typography>
        </Toolbar>
    );
}

EnhancedGalleryTableToolbar.propTypes = {
    numSelected: PropTypes.number,
};

//table head for gallery
function EnhancedGalleryTableHead() {
    const headCells = [
        { id: 'name', numeric: false, disablePadding: true, label: 'Gallery Image' },
        { id: 'calories', numeric: true, disablePadding: false, label: 'Description' },
        { id: "actions", numeric: true, disablePadding: false, label: "Actions" },
    ];

    return (
        <>
            <TableHead>
                <TableRow className="menus-color">
                    {headCells.map((headCell) => (
                        <TableCell
                            sx={{ textAlign: "center", color: "#ffff" }}
                            key={headCell.id}
                            align={headCell.numeric ? "right" : "left"}
                            padding={headCell.disablePadding ? "none" : "normal"}
                        >
                            {headCell.label}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        </>
    );
}

const CategoryName = ({ id }) => {
    const [categoryDetails, setCategoryDetails] = useState(null);
    const [getCategoryDetailsById] = useLazyGetCategoryDetailsByIdQuery();

    //getCategoryDetailsById
    useEffect(() => {
        getCategoryDetailsById(id)
            .unwrap()
            .then((res) => {
                setCategoryDetails(res?.data?.categoryName);
            })
            .catch((error) => {
                setCategoryDetails("Error fetching data");
            });
    }, [id, getCategoryDetailsById]);
    return categoryDetails;
};

const AdminApproval = () => {
    const [currentTabIndex, setCurrentTabIndex] = useState(0);

    //tab Change
    const handleTabChange = (e, tabIndex) => {
        setCurrentTabIndex(tabIndex);
    };
    const [page, setPage] = useState(0);
    const rowsPerPage = 5;
    const rowsForumPerPage = 5;
    const rowsNewsPerPage = 5;
    const rowsGalleryPerPage = 2;
    const [allEvent, setAllEvents] = useState([])
    const [totalPage, setTotalPage] = useState(0);
    const [forumPage, setForumPage] = useState(0);
    const [totalForumPage, setTotalForumPage] = useState(0);
    const [allForum, setAllForum] = useState([]);
    const [newsPage, setNewsPage] = useState(0);
    const [totalNewsPage, setTotalNewsPage] = useState(0);
    const [allNews, setAllNews] = useState([]);
    const [galleryPage, setGalleryPage] = useState(0);
    const [totalGalleryPage, setTotalGalleryPage] = useState(0);
    const [allGallery, setAllGallery] = useState([]);
    const [getAllPendingEventList] = useGetPendingEventListMutation();
    const [getAllPendingForumList] = useGetPendingForumListMutation();
    const [getAllPendingNewsList] = useGetPendingNewsListMutation();
    const [getAllPendingGalleryList] = useGetPendingGalleryListMutation();
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true);


    //Event Function -----------------------started------------------------------//

    //calling event data
    useEffect(() => {
        fetchData(); // Fetch data on component mount
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, rowsPerPage, rowsForumPerPage]); // Update useEffect dependencies


    //getAllPendingEventList
    const fetchData = () => {
        var data = {
            page: page + 1,
            itemsPerPage: rowsPerPage
        }
        getAllPendingEventList(data).then((res) => {
            setLoading(false);
            if (res.data.status) {
                if (res.data.data.eventDetails.length === 0 && page >= 1) {
                    setPage(page - 1);
                }
                setAllEvents(res?.data?.data?.eventDetails || []);
                setTotalPage(res?.data?.data.totalCount || 0);
            }
            else {
                setAllEvents([])
                setTotalPage(0)
            }
        })
            .catch((error) => {
                setLoading(false);
                toast.error(error)
            });
    };

    //page Click for Event
    const handleEventPageClick = ({ selected }) => {
        setPage(selected);
    };

    //sliced Event
    const slicedEvents = allEvent.slice(0, rowsPerPage); // Adjust slicing

    //calculate pagecount
    const pageCount = Math.ceil(totalPage / rowsPerPage);

    //Event function -------------------------------------- Ended-------------------------------------------//

    //News Function -----------------------------------started---------------------------------------------//

    //get all News
    const getAllNews = () => {
        setNewsPage(0)
        fetchNewsData();
    }

    //getAllPendingForumList
    const fetchNewsData = useCallback(() => {
        var data = {
            page: newsPage + 1,
            itemsPerPage: rowsNewsPerPage
        };
        getAllPendingNewsList(data)
            .then((res) => {
                setLoading(false);
                if (res.data.status) {
                    if (res.data.data.newsDetails.length === 0 && newsPage >= 1) {
                        setNewsPage(newsPage - 1);
                    }
                    setAllNews(res?.data?.data.newsDetails || []);
                    setTotalNewsPage(res?.data.data.totalCount);
                }
                else{
                    setAllNews([]);
                    setTotalNewsPage(0);
                }
            })
            .catch((error) => {
                setLoading(false);
                toast.error(error)
            });
    }, [newsPage, getAllPendingNewsList, rowsNewsPerPage]);

    //effect to set page
    useEffect(() => {
        fetchNewsData();
    }, [fetchNewsData]);

    //page Click for Forum
    const handleNewsPageClick = ({ selected }) => {
        setNewsPage(selected);
    };

    //slicedForum
    const slicedNews = allNews.slice(0, rowsNewsPerPage); // Adjust slicing

    //calculate pagecount
    const pageCount1 = Math.ceil(totalNewsPage / rowsPerPage);


    //News function -------------------------------------- Ended-------------------------------------------//

    //Forum Function -----------------------------------started---------------------------------------------//

    //get all forum
    const getAllForum = () => {
        setForumPage(0)
        fetchForumData();
    }

    const fetchForumData = useCallback(() => {
        var data = {
            page: forumPage + 1,
            itemsPerPage: rowsForumPerPage
        };
        getAllPendingForumList(data)
            .then((res) => {
                setLoading(false);
                if(res.data.status){
                    if (res.data.data.forumDetails.length === 0 && forumPage >= 1) {
                        setForumPage(forumPage - 1);
                      }
                setAllForum(res?.data?.data.forumDetails || []);
                setTotalForumPage(res?.data.data.totalCount);
                }
                else{
                    setAllForum([]);
                    setTotalForumPage(0);                }
            })
            .catch((error) => {
                setLoading(false);
                toast.error(error)
            });
    }, [forumPage, rowsForumPerPage, getAllPendingForumList]); // Add dependencies

    useEffect(() => {
        fetchForumData(); // Fetch forum data whenever forumPage changes
    }, [fetchForumData]); // Add fetchForumData to the dependency array


    //page Click for Forum
    const handleForumPageClick = ({ selected }) => {
        setForumPage(selected);
    };

    //slicedForum
    const slicedForum = allForum.slice(0, rowsForumPerPage); // Adjust slicing

    //calculate pagecount
    const pageCount2 = Math.ceil(totalForumPage / rowsPerPage);

    //Forum function -------------------------------------- Ended-------------------------------------------//

    //Gallery Function -----------------------------------started---------------------------------------------//

    //get all Gallery
    const getAllGallery = () => {
        fetchGalleryData();
    }

    //getAllPendingGalleryList
    const fetchGalleryData = useCallback(() => {
        var data = {
            page: galleryPage + 1,
            itemsPerPage: rowsGalleryPerPage,
            role:"PORTAL_SUPER_ADMIN"
        };
        getAllPendingGalleryList(data)
            .then((res) => {
                setLoading(false);
                if(res.data.status){
                    if (res.data.data.pendingDetails.length === 0 && galleryPage >= 1) {
                        setGalleryPage(galleryPage - 1);
                      }
                setAllGallery(res?.data?.data?.pendingDetails || []);
                setTotalGalleryPage(res?.data.data.totalCount);
                }
                else{
                    setAllGallery([]);
                    setTotalGalleryPage(0);
                }
            })
            .catch((error) => {
                setLoading(false);
                toast.error(error)
            });
    }, [galleryPage, rowsGalleryPerPage, getAllPendingGalleryList]);

    //effect to set page
    useEffect(() => {
        fetchGalleryData();
    }, [fetchGalleryData]);



    //page Click for gallery
    const handleGalleryPageClick = ({ selected }) => {
        setGalleryPage(selected);
    };

    //slicedGallery
    const [slicedGallery, setSlicedGallery] = useState([])
    useEffect(() => {
        setSlicedGallery(allGallery); // Adjust slicing
    }, [allGallery])

    //calculate pagecount
    const pageCount3 = Math.ceil(totalGalleryPage / rowsGalleryPerPage);

    //Gallery function -------------------------------------- Ended-------------------------------------------//

    return (
        <div>
            <TopHeader />
            <Box>
                <div className='pt-1 '>
                    <div className='p-0'>
                        <div className=' Architects-header d-flex align-items-center justify-content-center '>
                            <h2 className='signika-fontOEC text-center '>Admin Approval</h2>
                        </div>
                    </div>
                </div>
            </Box>
            {loading ? (
                <Spinner />
            ) : (
                <div className='row' >
                    <Container maxWidth="xl">
                        <div className='mb-5 d-flex justify-content-start flex-column mt-2'>
                            <span style={{width:'200px'}}></span>
                        </div>
                    </Container>
                    <div className='col-md-2'>
                    </div>
                    <div className='col-md-8'>

                        <Grid
                            container
                            spacing={2}
                            style={{
                                margin: "0 auto",
                                position: "sticky",
                                top: "0",
                                zIndex: "999",
                                marginBlock: "10px",
                            }}
                        >
                            <AppBar>
                            </AppBar>
                            <Tabs
                                position="static" sx={{ borderRadius: "8px", backgroundColor: '#611f1e', mx: 'auto', width: '100%' }}
                                value={currentTabIndex}
                                onChange={handleTabChange}
                                variant='fullWidth'
                                textColor='white'
                            >
                                <Tab label="Events" sx={{ color: `${currentTabIndex === 0 ? '#611f1e' : 'white'}`, background: `${currentTabIndex === 0 ? 'white' : '#611f1e'}` }} />
                                <Tab label="News" onClick={() => getAllNews()} sx={{ color: `${currentTabIndex === 1 ? '#611f1e' : 'white'}`, background: `${currentTabIndex === 1 ? 'white' : '#611f1e'}` }} />
                                <Tab label="Forums" onClick={() => getAllForum()} sx={{ color: `${currentTabIndex === 2 ? '#611f1e' : 'white'}`, background: `${currentTabIndex === 2 ? 'white' : '#611f1e'}` }} />
                                <Tab label="Gallery" onClick={() => getAllGallery()} sx={{ color: `${currentTabIndex === 3 ? '#611f1e' : 'white'}`, background: `${currentTabIndex === 3 ? 'white' : '#611f1e'}` }} />
                            </Tabs>
                        </Grid>
                        <div>
                            {currentTabIndex === 0 && (
                                <>
                                    <Box
                                        className="memberTable ms-2"
                                    >
                                        <Paper sx={{ width: "100%" }}>
                                            {/* <EnhancedEventTableToolbar /> */}
                                            <TableContainer >
                                                <Table aria-labelledby="tableTitle">
                                                    <EnhancedEventTableHead />
                                                    <TableBody>
                                                        {slicedEvents.length > 0 ? (
                                                            slicedEvents.map((event, index) => (
                                                                <TableRow
                                                                    hover
                                                                    key={event._id}
                                                                    sx={{
                                                                        cursor: "pointer",
                                                                        backgroundColor: index % 2 === 0 ? "#f0f0f0" : "white",
                                                                    }}
                                                                >
                                                                    <TableCell className='text-capitalize' align="center">{event.title}</TableCell>
                                                                    <TableCell className='text-capitalize' align="center">
                                                                        {new Date(event.startDate).toLocaleDateString(
                                                                            "en-In",
                                                                            {
                                                                                year: "numeric",
                                                                                month: "2-digit",
                                                                                day: "2-digit",
                                                                            }
                                                                        )}<br></br>to <br />{new Date(event.endDate).toLocaleDateString(
                                                                            "en-In",
                                                                            {
                                                                                year: "numeric",
                                                                                month: "2-digit",
                                                                                day: "2-digit",
                                                                            }
                                                                        )}
                                                                    </TableCell>
                                                                    <TableCell className='text-capitalize' align="center">
                                                                        {event?.organizerName}
                                                                    </TableCell>
                                                                    <TableCell className='text-capitalize' align="center">
                                                                        {event.locationAddress}
                                                                    </TableCell>
                                                                    <TableCell className='text-capitalize' align="center">
                                                                        <Tooltip title="View">
                                                                            <IconButton
                                                                                aria-label="ViewIcon"

                                                                                onClick={() => navigate("/VieweventFeed/" + event._id)}
                                                                            >
                                                                                <VisibilityIcon className='icon-vieweye-color' />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))
                                                        ) : <><TableRow><TableCell className='text-capitalize' colSpan={5}><p className='text-black text-center'>No Data Available</p></TableCell></TableRow></>}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Paper>
                                            <br/>
                                        {slicedEvents.length > 0 ? (
                                            <ReactPaginate
                                                pageCount={pageCount} // Calculate total pages
                                                pageRangeDisplayed={2}
                                                marginPagesDisplayed={1}
                                                onPageChange={handleEventPageClick}
                                                containerClassName={"pagination"}
                                                activeClassName={"active"}
                                                previousLabel={pageCount > 1 && page >= 1 ? "Previous" : null}
                                                nextLabel={pageCount > 1 && page + 1 !== pageCount ? "Next" : null}
                                                disabledClassName={"disablebtn"}
                                                forcePage={page}
                                            />
                                        ) : (<></>)}
                                        <br/>
                                    </Box>
                                </>
                            )}

                            {currentTabIndex === 1 && (
                                <>
                                    {loading ? (
                                        <Spinner />
                                    ) : (
                                        <Box
                                            className="memberTable justify-content-center d-flex flex-column align-items-center"
                                        >
                                            <Paper sx={{ width: "100%" }}>
                                                <TableContainer>
                                                    <Table className='' aria-labelledby="tableTitle">
                                                        <EnhancedNewsTableHead />
                                                        <TableBody>
                                                            {slicedNews.length > 0 ? slicedNews.map((news, index) => (
                                                                <TableRow
                                                                    hover
                                                                    key={news._id}
                                                                    sx={{
                                                                        cursor: "pointer",
                                                                        backgroundColor: index % 2 === 0 ? "#f0f0f0" : "white",
                                                                    }}
                                                                >
                                                                    <TableCell className='text-capitalize' align="center">
                                                                        {news.headline}
                                                                    </TableCell>
                                                                    {/* <TableCell align="center">{news.summary}</TableCell> */}
                                                                    <TableCell width={350} align="center">{news?.summary.length>50?news?.summary.slice(0,50)+"...":news?.summary}</TableCell>
                                                                    <TableCell align="center">
                                                                        {news.authorName}
                                                                    </TableCell>
                                                                    <TableCell align="center">
                                                                        <Tooltip title="view News">
                                                                            <IconButton
                                                                                aria-label="EditIcon"
                                                                                onClick={() => navigate("/viewJournal/" + news._id)}
                                                                            >
                                                                                <VisibilityIcon className='icon-vieweye-color' />
                                                                            </IconButton>
                                                                        </Tooltip>

                                                                    </TableCell>
                                                                </TableRow>
                                                            )) : <><TableRow><TableCell colSpan={6}><p className='text-black text-center text-capitalize'>No Data Available</p></TableCell></TableRow></>}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </Paper>
                                            <br/>
                                            {slicedNews.length > 0 ? (
                                                <ReactPaginate
                                                    pageCount={pageCount1} // Calculate total pages
                                                    pageRangeDisplayed={2}
                                                    marginPagesDisplayed={1}
                                                    onPageChange={handleNewsPageClick}
                                                    containerClassName={"pagination"}
                                                    activeClassName={"active"}
                                                    previousLabel={pageCount1 > 1 && newsPage >= 1 ? "Previous" : null}
                                                    nextLabel={pageCount1 > 1 && newsPage + 1 !== pageCount1 ? "Next" : null}
                                                    disabledClassName={"disablebtn"}
                                                    forcePage={newsPage}
                                                />
                                            ) : (<></>)}
                                            <br/>
                                        </Box>
                                    )}
                                </>
                            )}
                            {currentTabIndex === 2 && (
                                <>
                                    {loading ? (
                                        <Spinner />
                                    ) : (
                                        <Box
                                            className="memberTable justify-content-center d-flex flex-column align-items-center"
                                        >
                                            <Paper sx={{ width: "100%" }}>
                                                {/* <EnhancedForumTableToolbar /> */}
                                                <TableContainer>
                                                    <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                                                        <EnhancedForumTableHead />
                                                        <TableBody>
                                                            {slicedForum.length > 0 ? slicedForum.map((Forum, index) => (
                                                                <TableRow
                                                                    hover
                                                                    key={Forum._id}
                                                                    sx={{
                                                                        cursor: "pointer",
                                                                        backgroundColor: index % 2 === 0 ? "#f0f0f0" : "white",
                                                                    }}
                                                                >
                                                                    <TableCell align="center">
                                                                        <CategoryName id={Forum.category} />
                                                                    </TableCell>
                                                                    <TableCell align="center">
                                                                        {Forum.name}
                                                                    </TableCell>
                                                                    <TableCell align="center">
                                                                        {new Date(Forum.updatedDate).toLocaleDateString()}
                                                                    </TableCell>
                                                                    <TableCell align="center">
                                                                        <Tooltip title="view">
                                                                            <IconButton
                                                                                aria-label="EditIcon"

                                                                                onClick={() => navigate("/readMoreForum/" + Forum._id)}
                                                                            >
                                                                                <VisibilityIcon className='icon-vieweye-color' />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    </TableCell>
                                                                </TableRow>
                                                            )) : (<><TableRow><TableCell colSpan={6}><p className='text-black text-center'>No Data Available</p></TableCell></TableRow></>)}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </Paper>
                                            <br/>
                                            {slicedForum.length > 0 ? (
                                                <ReactPaginate
                                                    pageCount={pageCount2} // Calculate total pages
                                                    pageRangeDisplayed={2}
                                                    marginPagesDisplayed={1}
                                                    onPageChange={handleForumPageClick}
                                                    containerClassName={"pagination"}
                                                    activeClassName={"active"}
                                                    previousLabel={pageCount2 > 1 && forumPage >= 1 ? "Previous" : null}
                                                    nextLabel={pageCount2 > 1 && forumPage + 1 !== pageCount2 ? "Next" : null}
                                                    disabledClassName={"disablebtn"}
                                                    forcePage={forumPage}
                                                />
                                            ) : (<></>)}
                                            <br/>
                                        </Box>
                                    )}
                                </>
                            )}
                            {currentTabIndex === 3 && (
                                <>
                                    {loading ? (
                                        <Spinner />
                                    ) : (
                                        <Box
                                            className="memberTable justify-content-center d-flex flex-column align-items-center"
                                        >
                                            <Paper sx={{ width: "100%" }}>
                                                {/* <EnhancedGalleryTableToolbar /> */}
                                                <TableContainer>
                                                    <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                                                        <EnhancedGalleryTableHead />
                                                        <TableBody>
                                                            {slicedGallery.length > 0 ? (
                                                                slicedGallery.map((gallery, index) => (
                                                                    <TableRow
                                                                        hover
                                                                        key={gallery._id}
                                                                        sx={{
                                                                            cursor: "pointer",
                                                                            backgroundColor: index % 2 === 0 ? "#f0f0f0" : "white",
                                                                        }}
                                                                    >
                                                                        <TableCell align="center">
                                                                            <img src={gallery?.imageUrl} width={"150px"} height={"150px"} alt='gallery' />
                                                                        </TableCell>
                                                                        <TableCell width={200} align="center">
                                                                            {gallery.description}
                                                                        </TableCell>
                                                                        <TableCell align="center">
                                                                            <Tooltip title="View">
                                                                                <IconButton
                                                                                    aria-label="EditIcon"

                                                                                    onClick={() => navigate("/admin/approveRejectGallery/" + gallery.mainGalleryId)}
                                                                                >
                                                                                    <VisibilityIcon className='icon-vieweye-color' />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ))
                                                            ) : <><TableRow><TableCell colSpan={3}><p className='text-black text-center'>No Data Available</p></TableCell></TableRow></>}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </Paper>
                                            <br/>
                                            {slicedGallery.length > 0 ? (
                                                <ReactPaginate
                                                    pageCount={pageCount3} // Calculate total pages
                                                    onPageChange={handleGalleryPageClick}
                                                    containerClassName={"pagination"}
                                                    activeClassName={"active"}
                                                    previousLabel={pageCount3 > 1 && galleryPage >= 1 ? "Previous" : null}
                                                    nextLabel={pageCount3 > 1 && galleryPage + 1 !== pageCount3 ? "Next" : null}
                                                    disabledClassName={"disablebtn"}
                                                    forcePage={galleryPage}
                                                />
                                            ) : (<></>)}
                                            <br/>
                                        </Box>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </div>)}

        </div >
    )
}

export default AdminApproval