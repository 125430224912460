import { api } from "./api";

const forumServices = api.injectEndpoints({
  tagTypes: ['ForumServices'],
  endpoints: (builder) => ({
    //saveForum
    saveForum: builder.mutation({
      query: (newForumData) => ({
        url: "forums/saveForums",
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: newForumData,
      }),
      invalidatesTags: ["ForumServices"],
    }),

    //getAllForum
    getAllForum: builder.query({
      query: () => ({
        url: "forums/getAll",
      }),
      invalidatesTags: ["ForumServices"],
    }),

    //GetForumById
    getForumById: builder.query({
      query: (id) => `forums/get/${id}`,
    }),

    //updateForum
    updateForum: builder.mutation({
      query: ({ id, data }) => ({
        url: `forums/update/${id}`,
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: data,
      }),
      invalidatesTags: ["ForumServices"],
    }),

    //delete Forum
    deleteForum: builder.mutation({
      query: (id, isDeleted) => ({
        url: `forums/delete/${id}`,
        method: "PUT",
        body: { isDeleted },
      }),
      invalidatesTags: ["ForumServices"],
    }),

    //getApprove (or)reject Forum
    getApproveRejectForum: builder.mutation({
      query: (data) => ({
        url: "forums/approveRejectForum",
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: data,
      }),
      invalidatesTags: ["ForumServices"],
    }),

    //pending forum
    getPendingForumList: builder.mutation({
      query: (data) => ({
        url: "forums/getForumPendingList",
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: data,
      }),
      invalidatesTags: ["ForumServices"],
    }),
    
    //pagination Forum
    paginationForum: builder.mutation({
      query: (newMemberListData) => ({
        url: "forums/getAllForumsPagination",
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: newMemberListData,
      }),
      invalidatesTags:['ForumServices']
    }),
  }),
  overrideExisting: false,
});

export const {
  useSaveForumMutation,
  useLazyGetAllForumQuery,
  useLazyGetForumByIdQuery,
  useUpdateForumMutation,
  useDeleteForumMutation,
  useGetPendingForumListMutation,
  useGetApproveRejectForumMutation,
  usePaginationForumMutation
} = forumServices;