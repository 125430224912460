import { api } from "./api";

const jobServices = api.injectEndpoints({
  endpoints: (builder) => ({
     //save Job
     saveJob: builder.mutation({
      query: (formData) => ({
        url: "job/saveJob",
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: formData,
      }),
    }),

    //get All Job
    getAllJob:builder.query({
      query: () => ({
        url: "job/getall",
      }),
    }),

    //get Job Details by Id
    getJobDetailsById: builder.query({
      query: (id) => "job/get/" + id,
    }),

    //Update Job
    updateJob: builder.mutation({
      query: (data) => ({
        url: "job/update/"+ data.id,
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: data.formData,
      }),
    }),

     //delete Job
     deleteJob: builder.mutation({
      query: (data) => ({
        url: "job/delete/"+ data.id,
        method: "PUT",
        headers: { "Content-Type": "application/json" },
      }),
    }),

     //enableDisable Job
     enableDisableJob: builder.mutation({
      query: (data) => ({
        url: "job/enableDisable/"+ data.id,
        method: "PUT",
        headers: { "Content-Type": "application/json" },
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useLazyGetAllJobQuery,
  useSaveJobMutation,
  useLazyGetJobDetailsByIdQuery,
  useDeleteJobMutation,
  useEnableDisableJobMutation,
  useUpdateJobMutation,
} = jobServices;
