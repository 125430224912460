import { api } from "./api";

const categoryServices = api.injectEndpoints({
  endpoints: (builder) => ({
    //save category
    saveCategory: builder.mutation({
      query: (formData) => ({
        url: "category/saveCategory",
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: formData,
      }),
    }),

    //get All category
    getAllCategory: builder.mutation({
      query: (data) => ({
        url: "category/getAll",
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: data,
      }),
    }),

    //get category Details by Id
    getCategoryDetailsById: builder.query({
      query: (id) => "category/get/" + id,
    }),

    //Update category
    updateCategory: builder.mutation({
      query: (data) => ({
        url: "category/update/" + data.id,
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: data.formData,
      }),
    }),

    //delete category
    deleteCategory: builder.mutation({
      query: (data) => ({
        url: "category/delete/" + data.id,
        method: "PUT",
        headers: { "Content-Type": "application/json" },
      }),
    }),

    //enableDisable category
    enableDisableCategory: builder.mutation({
      query: (data) => ({
        url: "category/enableDisable/" + data.id,
        method: "PUT",
        headers: { "Content-Type": "application/json" },
      }),
    }),

    //pagination category
    paginationCategory: builder.mutation({
      query: (data) => ({
        url: "category/getAllCategoryPagination",
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: data,
      }),
    }),

  }),
  overrideExisting: false,
});

export const {
  useGetAllCategoryMutation,
  useSaveCategoryMutation,
  useLazyGetCategoryDetailsByIdQuery,
  useUpdateCategoryMutation,
  useDeleteCategoryMutation,
  useEnableDisableCategoryMutation,
  usePaginationCategoryMutation
} = categoryServices;
