import { createSlice } from "@reduxjs/toolkit";
const sigSlice=createSlice({
    name:'sigGroup',

    //intial state
    initialState:{
        sigDetails:{},
        sigRoleDetails: {},
    },

    //reducer
    reducers:{
        setSigDetails:(state,action)=>{
            state.sigDetails=action.payload;
        },
        setSigRoleDetails: (state, action) => {
            state.sigRoleDetails = action.payload;
        } 
    }
})

export default sigSlice.reducer
export const {setSigDetails}=sigSlice.actions
export const {setSigRoleDetails}=sigSlice.actions
export const selectCurrentSig=(state)=>state?.sig?.sigDetails
export const selectCurrenSigRole = (state) => state.sig?.sigRoleDetails