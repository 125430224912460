import React from 'react';
import "../../style/css/spinner.css"

const spinner = () => {
    return (
        <>
        <div className='spinner'>
        <div className="loader"></div>
        </div>
            

        </>

    )
}

export default spinner

