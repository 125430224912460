import React from 'react'
import './ourHistory.css'
import Box from '@mui/material/Box';
import { Container } from '@mui/material';
import { history } from '../../../constant/constant'

const OurHistory = () => {
    return (
        <div className='ourExecutiveCommittee-containter'>
            <Box className="pt-lg-5 pt-3">
                <header className='Architects-header d-flex align-items-center justify-content-center'>
                    <h2 className='signika-fontOEC text-center'>Our History</h2>
                </header>
                <Container maxWidth="xl" className='p-lg-5 p-3'>
                    <div>
                        <h4 className='signika-fontOEC text-center mb-2 mt-4' style={{ color: '#134b2b' }}>Milestones in Our History</h4>
                    </div>
                    <p className='signika-font our-history-intro mb-5 mt-3'>
                        From its humble beginnings to becoming the largest professional association for teachers in India, ELTAI&#39;s
                        story is one of dedication, innovation, and collaboration. Join us as we delve into the significant milestones
                        that have shaped ELTAI over the years.
                    </p>
                    <Container maxWidth="xl">
                        {history.map((data, i) => {
                            return (<div key={i}>
                                <h5 className={`text-center fw-bold history-title${data.id} Futura-Bold-fontfamily mb-4`}>{data.title}</h5>
                                <div className='row'>
                                    <div className='row mb-3'>
                                        <div className='col-md-2 d-flex align-items-center justify-content-center'>
                                            <img src={data.img} alt={data.title} className='history-badge' />
                                        </div>
                                        <div className='col-md-10 d-flex align-items-center justify-content-center'>
                                            <p className='history-content signika-font'>
                                                {data.content}</p></div>
                                    </div>

                                </div>

                            </div>)
                        })

                        }
                    </Container>
                    <Container className="p-5">
                            <div className="ourhistory_card">
                                < p className='signika-font ourhistory_content mb-0 ' > ELTAI’s journey over the past decades reflects its unwavering commitment to the advancement of Indian
                                    English language teaching. As it strides confidently into the future, ELTAI remains a beacon of inspiration
                                    for educators across India, fostering excellence and collaboration among English language teaching
                                    fraternity.</p>
                            </div>
                    </Container>
                </Container>
            </Box>
        </div >
    )
}

export default OurHistory