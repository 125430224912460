import React, { useState, useEffect } from "react";
import "../../../src/style/css/chapter.css";
import TopHeader from "../../Component/Header/TopHeader";
import { useGetAllSIGGroupQuery } from "../../app/services/SIGGroupServices";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import ReportIcon from "@mui/icons-material/Report";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { selectCurrentScope } from "../../../src/app/auth/authSlice";
import Alert from "@mui/material/Alert";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useNavigate } from "react-router-dom";

//dialog content
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ViewSIG = () => {
  const { data: getAllSIGGroup, refetch } = useGetAllSIGGroupQuery();
  const memberScope = useSelector(selectCurrentScope); //get scope
  const [detailData] = useState([]); //state for compare login and SIG member
  const [currentSIGId, setCurrentSIGId] = useState(""); //state for SIG Id
  const [currentSIGIndex, setCurrentSIGIndex] = useState("");
  const navigate = useNavigate(); //route
  const [alertMessage] = useState(""); //state to show Alert message
  const [showSuccessAlert] = useState(false); //state for success Alert
  const [showErrorAlert] = useState(false); //state for Error Alert
  const [open, setOpen] = useState(false); //open and close state for dialog

  //on click the join
  const handleClickOpen = (id, index) => {
    setCurrentSIGId(id);
    setCurrentSIGIndex(index);
  };

  //on click Agree
  const handleAgree = () => {
    if (!currentSIGId) {
      console.error("SIG ID is empty!");
      return;
    }
    detailData.forEach((value, index) => {
      if (currentSIGIndex === index) {   
      }
    });
  };

  //close of Dialog
  const handleClose = () => {
    setOpen(false);
  };
 
  //addSig
  const addSIG = () => {
    navigate("/admin/sigGroupForm");
  };

  useEffect(() => {
    refetch();
  }, [refetch]);

  return (
    <div>
      <TopHeader />
      <div className='pt-1 mb-5'>
                <div className='p-0'>
                    <div className=' Architects-header d-flex align-items-center justify-content-center '>
                        <h2 className='signika-fontOEC text-center '>Sig </h2>
                    </div>
                </div>
            </div>
     
      <div className="container mb-5">
        <div className="row">
          <div className="col-3"></div>
          <div className="col-3">
            {memberScope === "PORTAL_SUPER_ADMIN" && (
              <button
                className="btn btn-primary btn-lg btn-block"
                onClick={addSIG}
              >
                Add SIG
              </button>
            )}
          </div>
        </div>
        <div className="row mt-4">
          {getAllSIGGroup?.data.length > 0 ? (
            getAllSIGGroup?.data
              .filter((item) => item.isDeleted === 0)
              .map((SIG, index) => (
                <div key={index} className="col-lg-4 col-md-6 col-sm-12">
                  <div className="chapter_card mt-5">
                    {memberScope === "PORTAL_SUPER_ADMIN" && (
                      <Link
                        style={{
                          float: "right",
                          color: "#030303",
                          background: "#1fe2fe",
                        }}
                        to={`/admin/sigGroupForm/${SIG._id}`}
                        role="button"
                      >
                        <Link
                          style={{
                            float: "left",
                            color: "#030303",
                            background: "#1fe2fe",
                          }}
                          to={`/sig-member-table/${SIG._id}`}
                          role="button"
                        >
                          <CheckBoxIcon />
                        </Link>
                        <EditNoteIcon />
                      </Link>
                    )}
                    <div className="row">
                      <h3 className="mt-4">{SIG.groupName}</h3>
                    </div>
                    {SIG.roleDetails.map((role, index) => (
                      <div className="row " key={index}>
                        <div className="col-12 SIG_card_text">
                          {role.role}: {role.name}
                        </div>
                      </div>
                    ))}
                    {detailData.map((data, index) => {
                      if (
                        memberScope === "PORTAL_MEMBER" &&
                        data.SIGId !== SIG._id
                      ) {
                        return (
                          <Button
                          variant="contained"
                          style={{
                            color: "#fff",
                            bottom: "10%",
                            fontWeight: "bold",
                            marginTop: "50px",
                          }}
                          key={index}
                          className="menus-color"
                          onClick={() => handleClickOpen(SIG._id, index)}
                        >
                          Join
                        </Button>
                        );
                      }
                      return null;
                    })}
                  </div>
                </div>
              ))
          ) : (
            <>
              <ReportIcon className="report_icon p-0 h-50" />
              <p className="no_Chapter">No SIG Available</p>
            </>
          )}
        </div>
      </div>
      <div className="mt-3">
        {showSuccessAlert && (
          <Alert variant="filled" severity="success">
            {alertMessage}
          </Alert>
        )}
        {showErrorAlert && (
          <Alert variant="filled" severity="error">
            {alertMessage}
          </Alert>
        )}
      </div>
      {/* model for Join */}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Join SIG"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            As you are currently in another SIG, would you like to join here?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button onClick={handleAgree}>Agree</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ViewSIG;
