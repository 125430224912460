import React, { useEffect, useState } from 'react'
import { useLazyGetJournalByIdQuery } from '../../app/services/journalServices'
import { useParams, useNavigate } from "react-router-dom";
import TopHeader from '../../Component/Header/TopHeader';
import Box from '@mui/material/Box';
import "../../style/css/viewJournal.css";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { useSelector } from "react-redux";
import { selectCurrentScope } from "../../app/auth/authSlice";
import { toast } from 'react-toastify';
import { useGetApproveRejectNewsMutation } from '../../app/services/journalServices'
import { Button, InputAdornment, Modal, TextField, Typography } from "@mui/material";
import Spinner from "../../Component/spinner/spinner"
import { useForm } from 'react-hook-form';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '5px',
  p: 5,
  backgroundColor:'#ffff',
  padding: '20px',
};

const ViewJournal = (props) => {

  const { journal } = useParams();//get Id from params
  const journalId = props.journalId || journal
  const [getJournalById] = useLazyGetJournalByIdQuery();
  const [news, setNews] = useState(null)
  const memberScope = useSelector(selectCurrentScope)
  const [approveRejectNews] = useGetApproveRejectNewsMutation(0)
  const [loading, setLoading] = useState(false)
  const navigator = useNavigate()
  const [open, setOpen] = useState(false)
  const handleOpen = () => { setOpen(true) };
  const handleClose = () => { setOpen(false); clearErrors() }
  const { register, formState: { errors }, getValues, setError, clearErrors, watch } = useForm({
    defaultValues: {
      rejectionReason: ""
    }
  })
  const [ isApprovedData,setIsApprovedData]=useState()

  //get by id
  useEffect(() => {
    if (journalId) {
      getJournalById(journalId).then((res) => {
        if (res?.data?.status) {
          const data = res.data?.data;
          setIsApprovedData(data?.isApproved);
          setNews(data)
        }
      });
    }
  }, [journalId, getJournalById]);
  useEffect(() => { }, [news])

  //handleNewsApproveReject
  const handleNewsApproveReject = (id, type) => {
    const rejectionReason = getValues('rejectionReason')
    let data;
    if (type === 0) {
      data = {
        newsId: id,
        type: type
      };
    }
    if (type === 1) {
      if (rejectionReason === null || rejectionReason === undefined || rejectionReason === "") {
        setError('rejectionReason', { type: 'required', message: 'Please Enter a Reason For Rejection' }, { shouldFocus: true })
        return
      }
      else {
        clearErrors()
        data = {
          newsId: id,
          type: type,
          rejectionReason
        };

      }
    }
    approveRejectNews(data).then(res => {
      if (res?.data?.status) {
        getJournalById()
        if (res?.data?.data?.isApproved === 1) {
          setLoading(true)
          toast.success("News Approved Successfully!!!");
          navigator('/admin/approval')
        }
        else {
          toast.error("News Rejected Successfully!!!",)

        }
        navigator('/admin/approval')

      }
      else {
        toast.error(data.data?.message);
        setLoading(false)

      }
    })

  };
  return (
    <>
      <div className='ourExecutiveCommittee-containter '>
        {journal && <>
          <TopHeader />
          <div className='pt-1 '>
            <div className='p-0'>
              <div className=' Architects-header d-flex align-items-center justify-content-center '>
                <h2 className='signika-fontOEC text-center '>View News</h2>
              </div>
            </div>
          </div>
          <div className='container px-5 d-flex justify-content-end'>

            <Button onClick={() => window.history.back()} variant="contained" className='menus-color mt-3 mb-3'
            >Back</Button>
          </div>
        </>}
        {loading ?
          <><Spinner /></>
          :
          <div className='container px-5'>
              <div>
                <h2 style={{ color: '#601e1d', fontWeight: '600' }}>{news?.headline}</h2>
                </div>
                <div className='d-flex justify-content-between align-items-center'>
                <h2>By {news?.authorName}</h2>
                <h4><CalendarMonthIcon /> {new Date(news?.date).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}</h4>
              </div>
            <br />
            <div className='newsContent clearfix pb-5'>
              <img src={news?.thumbnailImage} alt='news' style={{float:'left',margin: '0px 15px 0px 0px', maxWidth:'500px'}}/>
                <Typography className=' styled-news-body' style={{ textAlign: 'justify', fontSize: '22px', paddingBottom: '40px' }}>
                  {news?.body}
                </Typography>
            </div>
           
          </div>
        }
        <div className='d-flex justify-content-end'>
        {memberScope === "PORTAL_SUPER_ADMIN" ?
        <>
        {isApprovedData === 0 && (
                  <div className="text-end">
                    {memberScope === "PORTAL_SUPER_ADMIN" ? (
                      <>
                        <Button
                          variant="contained"
                          style={{
                            backgroundColor: "red",
                          }}
                          onClick={() => {
                            handleOpen();
                          }}
                        >
                          Reject
                        </Button>
                        <Button
                          variant="contained"
                          style={{ backgroundColor: "green", marginLeft: "10px" }}
                          onClick={() => handleNewsApproveReject( journalId,0)}
                        >
                          Approve
                        </Button>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                )}
        </>
        :""}
        </div>
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <div
              style={style}
              className="d-flex align-items-center flex-column gap-3"
            >
              <form>
                <Typography
                  component={"h2"}
                  className="text-center fs-4 fw-bold text-danger mb-2"
                >
                  Reject Event
                </Typography>
                <TextField
                  inputProps={{ maxLength: "300" }}
                  fullWidth
                  label={
                    <Typography className="text-dark">
                      Reason For Rejection{" "}
                      <span style={{ color: "red" }}>*</span>
                    </Typography>
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {300 - watch("rejectionReason").length}
                      </InputAdornment>
                    ),
                  }}
                  error={!!errors?.rejectionReason}
                  helperText={errors?.rejectionReason?.message}
                  {...register("rejectionReason")}
                />
                <div
                  className="mt-3 mx-auto"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={handleClose}
                    style={{ marginRight: "10px" }}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="menus-color"
                    variant="contained"
                    color="success"
                    onClick={() => handleNewsApproveReject(journalId, 1)}
                  >
                    Submit
                  </Button>
                </div>
              </form>
            </div>
          </Modal>
      </div>
    </>
  )
}

export default ViewJournal