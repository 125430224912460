import React, { useEffect, useState } from "react";
import {
  useLazyGetAllMemberShipDetailsQuery,
  useDisableMemberShipMutation,
} from "../../app/services/memberShipServices";
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import TopHeader from "../../Component/Header/TopHeader";
import { toast } from "react-toastify";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import Spinner from "../../Component/spinner/spinner";
import Tooltip from "@mui/material/Tooltip";
import Grid from "@mui/material/Grid";
import joineltaibackground from "../../assets/images/joinetai/bgimage.png";



const ViewMembershipPlan = () => {
  const [memberShipPlans, setMemberShipPlans] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [getAllMershipPlans] = useLazyGetAllMemberShipDetailsQuery();
  const [disableMembershipPlanApi] = useDisableMemberShipMutation();
  const [loadingPlanId, setLoadingPlanId] = useState(null);
  const [hoveredPrice, setHoveredPrice] = useState(null);


  // getAllMershipPlans
  useEffect(() => {
    setLoading(true);
    getAllMershipPlans().then((res) => {
      if (res.data?.status) {
        setLoading(false);
        setMemberShipPlans(res.data?.data);
      }
    });
  }, [getAllMershipPlans, memberShipPlans]);

  //disableMemberShipPlan
  const disableMemberShipPlan = (id) => {
    if (id) {
      disableMembershipPlanApi(id)
        .then((res) => {
          setLoading(true);
          setLoadingPlanId(id);
          if (res?.data?.status) {
            setLoading(false);
            setLoadingPlanId(null);
            if (res?.data?.data.isDeleted === 2) {
              toast.error("Plan Disabled successfully!!!");
            } else {
              setLoading(false);
              setLoadingPlanId(null);
              toast.success("Plan Enabled Successfully!!!");
            }
            getAllMershipPlans().then((res) => {
              if (res.data?.status) {
                setMemberShipPlans(res.data?.data);
              }
            });
          } else {
            setLoading(false);
            setLoadingPlanId(null);
            toast.error(res?.data?.err?.message);
          }
        })
        .catch((err) => toast.error(err));
    }
  };

  //handle mouse enter(hover)
  const handleMouseEnter = (index) => {
    console.log(index)
    setHoveredPrice(index);
  };

  //news Mouse Leave
  const handleMouseLeave = (index) => {
    setHoveredPrice(null);
  };

  return (
    <div>
      <TopHeader />
      <Box
        sx={{
          width: "100%",
          height: "max-height",
          backgroundImage: `url(${joineltaibackground})`,
          backgroundPosition: "center ",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className="page_container">
          <div className='pt-1 '>
            <div className='p-0'>
              <div className=' Architects-header d-flex align-items-center justify-content-center '>
                <h2 className='signika-fontOEC text-center '>All Membership Plans</h2>
              </div>
            </div>
          </div>
          <Container maxWidth="xl">
            <div className="d-flex justify-content-between align-items-center mt-5">
              <div >
                <br />
              </div>
              <Button
                onClick={() => navigate("/admin/addEditMembershipPlan")}
                variant="contained"
                className="menus-color"
                type="submit"
              >
                Add New Plan
              </Button>
            </div>
          </Container>
          {loading && !loadingPlanId ? (
            <Spinner />
          ) : (

            <div className=" subscribtion-card mt-5 pb-5">
              <Container maxWidth={"xl"}>
                <Grid>
                  <Grid
                    container
                    spacing={{ xs: 4, md: 3.5 }}
                    columns={{ xs: 4, sm: 4, md: 12 }}
                  >
                    {memberShipPlans.length > 0 ? (
                      memberShipPlans.map((plan, index) => {
                        return (
                          <Grid item xs={12} sm={4} md={4} >
                            <div
                              className={
                                hoveredPrice === index
                                  ? "membercard-container1"
                                  : "membercard-container1"
                              }
                              onMouseEnter={() => handleMouseEnter(index)}
                              onMouseLeave={() => handleMouseLeave(index)}
                            >
                              <Card key={plan._id} sx={{ backgroundColor: "transparent", boxShadow: "none" }}>
                                <CardContent className="getall_customScroll">
                                  <div className="text-center d-flex">
                                    <p className="Subscribtion-card-heading mb-2">
                                      <b>{plan.planName}</b>
                                    </p>
                                    <Tooltip title="Edit">
                                      <IconButton
                                        aria-label="EditIcon"
                                        onClick={() =>
                                          navigate("/admin/addEditMembershipPlan/" + plan._id)
                                        }
                                      >
                                        {!(plan.isDeleted === 2) && (
                                          <EditIcon style={{ color: "blue" }} />
                                        )}
                                      </IconButton>
                                    </Tooltip>
                                    <Tooltip title={plan.isDeleted === 2 ? "Disable" : "Enable"}>
                                      {loading && loadingPlanId === plan._id ? (
                                        <Spinner />
                                      ) : plan.isDeleted === 2 ? (
                                        <IconButton aria-label="LockIcon" onClick={() => disableMemberShipPlan(plan._id)}>
                                          <LockIcon style={{ color: "red" }} />
                                        </IconButton>
                                      ) : (
                                        <IconButton aria-label="LockOpenIcon" onClick={() => disableMemberShipPlan(plan._id)}>
                                          <LockOpenIcon style={{ color: "green" }} />
                                        </IconButton>
                                      )}
                                    </Tooltip>

                                  </div>

                                  <div className="subscribtion-prize">
                                    {plan.planType.map((item) => (
                                      <Container
                                        sx={{ mt: 1 }}
                                        maxWidth="lg"
                                      // key={index}
                                      >
                                        <div className="row justify-content-center align-items-center">
                                          {/* <div className="col-8"> */}
                                          <p 
                                            className="text-start"
                                            style={{
                                              fontSize: "22px",
                                              color: "rgb(95,99,104)",
                                            }}
                                          >
                                            {item.planTypeName}({item.numberOfYears}Year):  {(item.currencyType === 1) ? "$" : "₹"}{item.amount}
                                          </p>
                                          {/* </div> */}

                                        </div>
                                      </Container>
                                    ))}

                                  </div>
                                </CardContent>
                              </Card>
                            </div>
                          </Grid>
                        );
                      })
                    ) : (
                      <>
                        <Grid item xs={12} sm={12} md={12}>
                          <div className={"card_join card-container"}>
                            <CardContent>
                              <div className="text-center">
                                <p className={"Subscribtion-card-heading"}>
                                  No Membership Plans Are Added
                                </p>
                              </div>
                            </CardContent>
                          </div>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Grid>
              </Container>
            </div>


          )}
        </div>
      </Box>
    </div>
  );

};

export default ViewMembershipPlan;
