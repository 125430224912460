import React, { useCallback, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  useLazyGetEventByIdQuery,
  useJoinEventMutation,
} from "../app/services/eventService";
import "../../src/style/css/individualEvent.css";
import { useLazyGetEventTypeByIdQuery } from "../app/services/eventTypeServices";
import Button from "@mui/material/Button";
import { selectCurrentScope } from "../app/auth/authSlice";
import { useSelector } from "react-redux";
import { useGetApproveRejectEventMutation } from "../app/services/eventService";
import { toast } from "react-toastify";
import Spinner from "../Component/spinner/spinner";
import { InputAdornment, Modal, TextField, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { selectCurrentMember } from "../app/features/memberSlice";

//Modal usage
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  backgroundColor: "white",
  boxShadow: 24,
  borderRadius: "5px",
  padding: "20px",
};

function IndividualEvent() {
  const { eventId } = useParams();
  const [loading, setLoading] = useState(false);
  const [getEventById] = useLazyGetEventByIdQuery();
  const [getEventData, setGetEventData] = useState([]);
  const [getEventTypeById] = useLazyGetEventTypeByIdQuery();
  const [getEventType, setGetEventType] = useState([]);
  const [getEventDate, setGetEventDate] = useState([]);
  const [joinEventService] = useJoinEventMutation();
  const memberScope = useSelector(selectCurrentScope);
  const [approveRejectEvent] = useGetApproveRejectEventMutation();
  const navigate = useNavigate();
  const memberDetail = useSelector(selectCurrentMember);
  const [open, setOpen] = useState(false);
  const [nonMemberRegisterOpen, setNonMemberRegisterOpen] = useState(false);
  const [joinButton, setJoinButton] = useState(-105); // Default top position

  const {
    register,
    formState: { errors },
    getValues,
    setError,
    clearErrors,
    watch,
    reset,
  } = useForm({
    defaultValues: {
      rejectionReason: "",
    },
  });

  const {
    reset: resetNonMemberForm,
    register: nonMemberRegisterForm,
    formState: { errors: nonMemberFormError },
    handleSubmit: handleSubmitEventNonMember,
  } = useForm({
    defaultValues: {
      name: "",
      email: "",
      phone: "",
    },
  });

  //Modal usage
  const handleOpen = (id) => {
    setOpen(true);
  };

  //close handle
  const handleClose = () => {
    setOpen(false);
    setNonMemberRegisterOpen(false);
    reset();
    resetNonMemberForm();
  };

  //Non member registration
  const handleNonMemberRegistation = () => {
    setNonMemberRegisterOpen(true);
  };

  //Event by id
  useEffect(() => {
    if (eventId) {
      getEventById(eventId);
    }
  }, [getEventById, eventId]);

  //Handle approve or reject events with reason
  const handleApproveReject = (id, type) => {
    const rejectionReason = getValues("rejectionReason");
    let data;
    if (type === 0) {
      data = {
        eventId,
        type: type,
      };
    }
    if (type === 1) {
      if (
        rejectionReason === null ||
        rejectionReason === undefined ||
        rejectionReason === ""
      ) {
        setError(
          "rejectionReason",
          { type: "required", message: "Please Enter a Reason For Rejection" },
          { shouldFocus: true }
        );
        return;
      } else {
        clearErrors();
        data = {
          eventId,
          type: type,
          rejectionReason,
        };
      }
    }
    setLoading(true);
    handleClose();
    approveRejectEvent(data).then((res) => {
      if (res.data?.status) {
        setLoading(false);
        getEventDetailsById();
        if (res?.data?.data?.isApproved === 1) {
          toast.success("Event Approved Successfully!!!");
          navigate("/admin/approval");
        } else {
          toast.error("Event Rejected Successfully!!!");
          navigate("/admin/approval");
        }
      } else {
        setLoading(false);
        toast.error(data.data?.message);
      }
    });
  };

  //Format date
  const formatDate = (dateStr) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric' }; // Options for formatting
    return new Date(dateStr).toLocaleDateString('en-US', options); // 'en-US' specifies the format
  };

  //Event details by id
  const getEventDetailsById = useCallback(() => {
    setLoading(true);
    getEventById(eventId)
      .then((res) => {
        if (res.data?.status) {
          setLoading(false);
          var startDate = res.data.data.startDate;
          var endDate = res.data.data.endDate;
          var date = {
            startDate: formatDate(startDate),
            endDate: formatDate(endDate),
          };
          setGetEventDate(date);
          setGetEventData(res.data.data);
          getEventTypeById(res.data.data.eventTypeId).then((res) => {
            if (res.data?.status) {
              setGetEventType(res.data.data);
            }
          });
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [getEventById, getEventTypeById, eventId]);

  useEffect(() => {
    if (eventId) {
      getEventDetailsById();
    }
  }, [getEventDetailsById, eventId]);

  //Join non member event
  const joinEvent = (event) => {
    if (memberScope === null || memberScope === undefined) {
      handleNonMemberRegistation();
    } else {
      setLoading(true);
      const data = { eventId: event._id, memberId: memberDetail._id };
      joinEventService(data).then((res) => {
        if (res.data?.status) {
          setLoading(false);
          toast.success(res.data.message);
          handleClose();
          reset();
          getEventDetailsById();
        } else {
          setLoading(false);
          toast.error(res.data.err.message);
        }
      });
    }
  };

  //Join non member register
  const nonMemberRegister = (registerData) => {
    const data = {
      eventId: eventId,
      nonMemberDetails: registerData,
    };
    setLoading(true);
    joinEventService(data).then((res) => {
      setLoading(false);
      if (res?.data?.status) {
        toast.success(res?.data?.message);
        setNonMemberRegisterOpen(false);
        resetNonMemberForm();
      } else {
        setLoading(false);
        toast.error(res?.data?.err?.message);
      }
    });
  };

  function haveCommonElements(arr1, arr2) {
    return arr1.some((item) => arr2?.includes(item));
  }

  //handleResize for join button
  const handleResize = useCallback(() => {
    const width = window.innerWidth;
    if (width >= 320 && width <= 991) {
      setJoinButton(-100)
    } else {
      setJoinButton(-105)
    }
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize(); // Call it initially to set state based on the initial window size
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize])

  return (
    <div className='page-containter'>
      <div className='pt-5 mb-3'></div>
      <div className="upcoming-class">Event</div>
      {loading ? (
        <Spinner />
      ) : (
        <div className="container-fluid   mt-2">
          <div className="row me-2">
            <div className="container mt-2 mb-2 me-2 ">
              <Button
                onClick={() => window.history.back()}
                variant="contained"
                className="menus-color"
                style={{ float: "right" }}
              >
                Back
              </Button>
            </div>
          </div>
          <div className="row ms-2">
            <div className="col-lg-4 speakers col-md-12 order-md-last order-lg-first col-sm-12 order-sm-last col-12 order-last md-responsive-class-3">
              {getEventData?.speakers?.map((data, index) => (
                <div className="card spacing" key={index}>
                  <h4 style={{ textAlignLast: "end" }} className="ml-3 sponser">
                    Speaker: {data.speakerPosition}{" "}
                  </h4>
                  <div className="d-flex justify-content-center mt-3">
                    <div style={{ width: 425 }}>
                      <img
                        src={data.speakerImageUrl}
                        width="100%"
                        height="150px"
                        alt="company logo"
                      ></img>
                    </div>
                  </div>
                  <p className="text row exp mb-0 sponser ">
                    {data.speakerName}
                  </p>
                  <p className="text package d-flex justify-content-center">
                    {data.file}
                  </p>
                  <p className="ms-3 me-3 package" style={{ textAlign: "justify" }}>
                    {data.speakerDescription}
                  </p>
                </div>
              ))}
            </div>
            <div className="col-lg-8 col-md-12 order-md-first order-lg-last col-sm-12 order-sm-first col-12 order-first">
              <div className="row">
                <div className="col-lg-12 md-responsive-class d-flex d-lg-block d-md-block d-sm-block">
                  <img
                    src={getEventData.imageUrl ? `${getEventData.imageUrl}?t=${new Date().getTime()}` : `${watch("imageUrl")}?t=${new Date().getTime()}`}
                    className="individualEvent md-responsive-class-4"
                    alt="eventfeed"
                  />
                  <p className="separate_desc me-3">
                    <span className="separateevent_title">{getEventData.title}</span>
                    <br />
                    {getEventData.description}
                  </p>
                  <div className="row">
                    <p className="sponser">
                      Start Date: <span style={{ color: "black" }}>{getEventDate.startDate}</span>
                      &nbsp;|&nbsp;
                      End Date: <span style={{ color: "black" }}>{getEventDate.endDate}</span>
                    </p>
                  </div>
                  {(getEventData.startTime && getEventData.endTime) &&
                    <div className="row">
                      <p className="sponser">
                        Start Time: <span style={{ color: "black" }}>{getEventData.startTime}</span>
                        &nbsp;|&nbsp;
                        End Time: <span style={{ color: "black" }}>{getEventData.endTime}</span>
                      </p>
                    </div>
                  }
                </div>
              </div>
              <div className="row mt-4 mb-5">
                <div className="col-12 md-responsive-class-2">
                  <div className="card p-3 ms-3 me-3">
                    <div className="row d-flex">
                      <div className="col-lg-6 d-flex flex-column md-responsive-class-1">
                        <div className="card flex-fill h-100" style={{ backgroundColor: "#fbdede" }}>
                          <h2
                            className="text-center mt-2 sponser"
                            style={{ fontSize: 24 }}
                          >
                            Organizer
                          </h2>
                          <h2
                            className="ms-2 mt-2 sponser"
                            style={{ fontSize: 24, textAlign: "center" }}
                          >
                            {getEventData.organizerName}
                          </h2>
                          <div className="ms-3 me-3" style={{ textAlign: "justify" }}>
                            <p>
                              {getEventData.organizerDescription}
                            </p>
                            <p className="separateEvent_content">
                              <span className="sponser">Event Type</span>:{" "}
                              {getEventType.name}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 d-flex flex-column">
                        {getEventData.isVirtual ? (
                          <div className="card flex-fill h-100" style={{ backgroundColor: "#fbdede" }}>
                            <h2
                              className="text-center mt-2 sponser"
                              style={{ fontSize: 24 }}
                            >
                              Virtual Link
                            </h2>
                            <div className="ms-3 me-3" style={{ textAlign: "justify" }}>
                              <a href={getEventData.urlLink.startsWith('http') ? getEventData.urlLink : `https://${getEventData.urlLink}`}
                                target="_blank" rel="noopener noreferrer" style={{ color: "black" }}>
                                {getEventData.urlLink}
                              </a>
                            </div>
                          </div>
                        ) : (
                          <div className="card flex-fill h-100" style={{ backgroundColor: "#fbdede" }}>
                            <h2
                              className="text-center mt-2 sponser"
                              style={{ fontSize: 24 }}
                            >
                              Address
                            </h2>
                            <div className="ms-3 me-3" style={{ textAlign: "justify" }}>
                              <p style={{ color: "black" }}>
                                {getEventData.locationAddress}
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {getEventData.sponsorDescription &&
            <div className="row pb-5 col-lg-12">
              <div className="col-lg-12">
                <h2 className="ms-4 sponser">Sponsors</h2>
                <div className="ms-5 mt-4 ">
                  <h6 className="sponser-h6 me-4">{getEventData.sponsorDescription}</h6>
                </div>
              </div>
            </div>
          }
          {getEventData?.membersId?.includes(memberDetail?._id) ? (
            <Button
              className="menus-color"
              variant="contained"
              style={{
                color: "#ffff",
                bottom: "10%",
                fontWeight: "bold",
                marginTop: "5%",
              }}
            >
              Joined
            </Button>
          ) : (
            <>
              {!(
                memberScope === "PORTAL_SIG_ADMIN" ||
                memberScope === "PORTAL_CHAPTER_ADMIN" ||
                memberScope === "PORTAL_SUPER_ADMIN"
              ) &&
                (((memberScope === undefined || memberScope === null) &&
                  getEventData.isEnableRegistration &&
                  getEventData.isPublic) ||
                  (memberScope === "PORTAL_MEMBER" &&
                    getEventData.isEnableRegistration &&
                    (haveCommonElements(
                      getEventData?.SIGGroupsId,
                      memberDetail?.sigGroups
                    ) ||
                      getEventData?.chaptersId?.includes(
                        memberDetail?.chapter
                      ) ||
                      getEventData.isMember))) && (
                  <Button
                    variant="contained"
                    style={{
                      color: "#fff",
                      bottom: "10%",
                      fontWeight: "bold",
                      marginTop: "50px",
                      backgroundColor: "brown",
                      float: "right",
                      top: joinButton,
                      position: "relative"
                    }}
                    className="menus-color me-3"
                    onClick={() => joinEvent(getEventData)}
                  >
                    Join
                  </Button>
                )}
            </>
          )}
          {memberScope === "PORTAL_SUPER_ADMIN" ? (
            <>
              {getEventData.isApproved === 0 ? (
                <div className="text-end">
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => handleOpen()}
                  >
                    Reject
                  </Button>
                  <Button
                    variant="contained"
                    style={{ margin: "20px" }}
                    color="success"
                    onClick={() => handleApproveReject(eventId, 0)}
                  >
                    Approve
                  </Button>
                </div>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}
          <Modal
            open={nonMemberRegisterOpen}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <div
              style={style}
              className="d-flex align-items-center flex-column gap-3"
            >
              <Typography
                component={"h2"}
                className="text-center fs-4 fw-bold mb-2 code "
                style={{ color: "brown" }}
              >
                Non Member Register Event
              </Typography>
              <form onSubmit={handleSubmitEventNonMember(nonMemberRegister)}
              >
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  label={
                    <Typography
                      className="text-dark"
                      InputLabelProps={{ shrink: true }}
                    >
                      Name{" "}
                      <span style={{ color: "red", fontFamily: "code" }}>
                        *
                      </span>
                    </Typography>
                  }
                  variant="outlined"
                  {...nonMemberRegisterForm("name", {
                    required: "Please Enter Name",
                  })}
                  error={!!nonMemberFormError?.name}
                  helperText={nonMemberFormError?.name?.message}
                />
                <TextField
                  style={{ marginTop: "20px" }}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  label={
                    <Typography
                      className="text-dark"
                      InputLabelProps={{ shrink: true }}
                    >
                      Phone Number<span style={{ color: "red" }}>*</span>
                    </Typography>
                  }
                  variant="outlined"
                  {...nonMemberRegisterForm("phone", {
                    required: "Please Enter Phone Number",
                    validate: (value) => {
                      if (String(value).length !== 10) {
                        return "Please enter a valid 10-digit phone number";
                      }
                    }
                  })}
                  error={!!nonMemberFormError?.phone}
                  helperText={nonMemberFormError?.phone?.message}
                />
                <TextField
                  style={{ width: "100%", marginTop: "20px" }}
                  InputLabelProps={{ shrink: true }}
                  id="title"
                  label={
                    <Typography className="text-dark">
                      Email <span style={{ color: "red" }}>*</span>
                    </Typography>
                  }
                  variant="outlined"
                  {...nonMemberRegisterForm("email", {
                    required: "Please Enter a email",
                    pattern: {
                      value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                      message: "Please Enter a valid Email",
                    },
                  })}
                  error={!!nonMemberFormError?.email}
                  helperText={nonMemberFormError?.email?.message}
                />
                <div className="row">
                  <div className="d-flex justify-content-center">
                    <div
                      className="mt-3 mx-auto"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={handleClose}
                        style={{ marginRight: "10px" }}
                      >
                        Cancel
                      </Button>
                      <Button
                        className="menus-color"
                        variant="contained"
                        type="submit"
                        style={{ backgroundColor: "brown" }}
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </Modal>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <div
              style={style}
              className="d-flex align-items-center flex-column gap-3"
            >
              <form>
                <Typography
                  component={"h2"}
                  className="text-center fs-4 fw-bold text-danger mb-2"
                >
                  Reject Event
                </Typography>
                <TextField
                  inputProps={{ maxLength: "300" }}
                  fullWidth
                  label={
                    <Typography className="text-dark">
                      Reason For Rejection{" "}
                      <span style={{ color: "red" }}>*</span>
                    </Typography>
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {300 - watch("rejectionReason").length}
                      </InputAdornment>
                    ),
                  }}
                  error={!!errors?.rejectionReason}
                  helperText={errors?.rejectionReason?.message}
                  {...register("rejectionReason")}
                />
                <div
                  className="mt-3 mx-auto"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={handleClose}
                    style={{ marginRight: "10px" }}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="menus-color"
                    variant="contained"
                    color="success"
                    onClick={() => handleApproveReject(eventId, 1)}
                  >
                    Submit
                  </Button>
                </div>
              </form>
            </div>
          </Modal>
        </div>
      )}
    </div>
  );
}

export default IndividualEvent;
