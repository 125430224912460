import { api } from "./api";

const testimonialServices = api.injectEndpoints({
  endpoints: (builder) => ({
     //save testimonial
     saveTestimonial: builder.mutation({
      query: (formData) => ({
        url: "testimonial/saveTestimonial",
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: formData,
      }),
      invalidatesTags:["testimonial"]
    }),

    //get All testimonial
    getAllTestimonial: builder.query({
      query: () => ({
        url: "testimonial/getAll",
      }),
      providesTags:['testimonial']
    }),

    //get Testimonial pagination
    getAllTestimonialPagination:builder.mutation({
      query: (data) => ({
        url: "testimonial/getAllTestimonialPagination",
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: data,
      }),
    }),
    
    //get testimonial Details by Id
    getTestimonialById: builder.query({
      query: (id) => "testimonial/get/" + id,
      invalidatesTags: ["testimonial"],
    }),

    //Update testimonial
    updateTestimonial: builder.mutation({
      query: (data) => ({
        url: "testimonial/update/"+ data.id,
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: data.formData,
      }),
      invalidatesTags:['testimonial']
    }),

    //deleteTestmonial
    deleteTestimonial: builder.mutation({
        query: (id) => ({
          url: "testimonial/delete/"+ id,
          method: "PUT",
          headers: { "Content-Type": "application/json" },
        }),
        invalidatesTags:['testimonial']
      }),
  }),
  overrideExisting: false,
});

export const {
  useGetAllTestimonialQuery,
  useSaveTestimonialMutation,
  useDeleteTestimonialMutation,
  useLazyGetTestimonialByIdQuery,
  useUpdateTestimonialMutation,
  useLazyGetAllTestimonialQuery,
  useGetAllTestimonialPaginationMutation
} = testimonialServices;
