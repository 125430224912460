import { Box, Grid, TextField, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { Button, Container } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { useNavigate,useParams } from 'react-router-dom';
import { useCreateAnnualConferenceMutation,useLazyGetAnnualConferenceByIdQuery,
         useUpdateAnnualConferenceMutation
 } from '../../../app/services/annualConferenceService';
import { toast } from 'react-toastify';

const AddEditImportAnnualConference = () => {      
  const [createAnnualConference] = useCreateAnnualConferenceMutation();
  const navigate = useNavigate();
  const {id}=useParams()
  const [getById]=useLazyGetAnnualConferenceByIdQuery();
  const [updateAnnualConference]=useUpdateAnnualConferenceMutation();
  
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: { conferenceName: '', venue: '', date: '' }
  });
  
  useEffect(()=>{
   getById(id).then((res)=>{
    if(res?.data?.status){
      console.log(res?.data?.data)
          {
        Object.entries(res?.data?.data).forEach(([key, value]) => {
          setValue(key, value)
        })
      }
    }
   })
   .catch((err)=>console.log(err))
  },[id,setValue])

  const onSaveConference = (data) => {
    if(id){
       const updateData={
      id,
      data
      }
       
        updateAnnualConference(updateData).then((res)=>{
        if(res?.data?.status){
           toast.success(res?.data?.message)
            navigate('/admin/annualConferenceList')
        }
        else{
          toast.error(res?.data?.message)
        }
        }).catch(err=>console.log(err))
    }
    else{
    // Call the mutation to create the conference
     createAnnualConference(data).then((res)=>{
      if(res?.data?.status){
      toast.success(res?.data?.message)
      navigate('/admin/annualConferenceList')
      reset()
      }else{
        toast.error(res?.data?.message)
      }
     })
     .catch(err=>console.error(err))
    }
  
  };

  return (
    <div>
      <Box>
        <div className='pt-1'>
          <div className='p-0'>
            <div className='Architects-header d-flex align-items-center justify-content-center'>
              <h2 className='signika-font-aboutUs text-center'>{id ?"Edit Conference List":"Add Conference List"}</h2>
            </div>
          </div>
        </div>
        <form onSubmit={handleSubmit(onSaveConference)}>
          <Container maxWidth='lg'>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', padding: 2 }}>
              <Button onClick={() => navigate(-1)} variant='contained' style={{ color: 'white' }} className="menus-color">Back</Button>
            </Box>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  id="conferenceName"
                  label={
                    <Typography className="text-dark">
                      Conference Name <span style={{ color: "red" }}>*</span>
                    </Typography>
                  }
                  variant="outlined"
                  {...register("conferenceName", {
                    required: "Please enter a conference name",
                    minLength: { value: 3, message: "Conference name must be at least 3 characters long" }
                  })}
                  error={!!errors?.conferenceName}
                  helperText={errors?.conferenceName?.message}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  id="venue"
                  label={
                    <Typography className="text-dark">
                      Venue <span style={{ color: "red" }}>*</span>
                    </Typography>
                  }
                  variant="outlined"
                  {...register("venue", {
                    required: "Please enter a venue",
                  })}
                  error={!!errors?.venue}
                  helperText={errors?.venue?.message}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  id="date"
                  label={
                    <Typography className="text-dark">
                      Date <span style={{ color: "red" }}>*</span>
                    </Typography>
                  }
                  variant="outlined"
                  {...register("date", {
                    required: "Please enter a date",
                  })}
                  error={!!errors?.date}
                  helperText={errors?.date?.message}
                />
              </Grid>
            </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'center', padding: 3 }}>
              <Button variant='contained' type="submit" style={{ color: 'white' }} className="menus-color">{id?"Update":"Submit"}</Button>
            </Box>
          </Container>
        </form>
      </Box>
    </div>
  );
};

export default AddEditImportAnnualConference;
