import {
  Box,
  Button,
  Container,
  IconButton,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import TopHeader from "../../Component/Header/TopHeader";
import {
  useGetAllTestimonialPaginationMutation,
  useDeleteTestimonialMutation,
} from "../../app/services/testimonialService";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ReactPaginate from "react-paginate";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Spinner from "../../Component/spinner/spinner";
import Tooltip from "@mui/material/Tooltip";

//table Features
function EnhancedTableHead() {
  const headCells = [
    {
      id: "userImage",
      numeric: false,
      disablePadding: true,
      label: "User Image",
      class: "menus-color"
    },
    { id: "name", numeric: true, disablePadding: false, label: "Name", class: "menus-color" },
    { id: "content", numeric: true, disablePadding: false, label: "Content", class: "menus-color" },
    { id: "actions", numeric: true, disablePadding: false, label: "Actions", class: "menus-color" },
  ];

  return (
    <>
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              sx={{ textAlign: "center", color: "#ffff" }}
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              className={headCell.class ? headCell.class : ""}
            >
              {headCell.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    </>
  );
}

function EnhancedTableToolbar({ numSelected }) {
  return (
    <Toolbar
      sx={{
        backgroundColor: "#0072bb",
        textAlign: "center",
        color: "white",
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >
      <Typography
        sx={{ flex: "1 1 100%" }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        {numSelected > 0 && numSelected
          ? `${numSelected} selected`
          : "Member List"}
      </Typography>
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "5px",
  p: 4,
};

const ViewTestimonial = () => {
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [allTestimonialPagination] = useGetAllTestimonialPaginationMutation();
  const [deleteTestimonial] = useDeleteTestimonialMutation();
  const [page, setPage] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [allTestimonialData, setAllTestimonialData] = useState([]);
  const [selectedMember, setSelectedMember] = useState(null);
  const rowsPerPage = 5; //state for item per page
  const handleOpen = (id) => {
    setOpen(true);
    setSelectedMember(id);
  };
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();

  //get all pagination for testimonial
  const getAllPagination = useCallback(() => {
    var data = {
      page: page + 1,
      itemsPerPage: rowsPerPage,
    };
    allTestimonialPagination(data).then((res) => {
      if (res.data?.status) {
        setLoading(false);
        setTotalPage(res?.data?.data?.totalCount || 0);
        setAllTestimonialData(res.data?.data?.testimonialDetails || []);
      } else {
        setLoading(false);
      }
    });
  }, [allTestimonialPagination, page, rowsPerPage]);

  //useEffect
  useEffect(() => {
    getAllPagination();
  }, [getAllPagination]);

  //sliced newsLetter
  //  const slicedTestimonial = allTestimonialData.slice(0, rowsPerPage); // Adjust slicing

  //page click
  const handlePageClick = ({ selected }) => {
    setPage(selected);
  };

  //calculate pagecount
  const pageCount = Math.ceil(totalPage / rowsPerPage);

  //edit route
  const handleEdit = (id) => {
    navigate("/admin/addEditTestimonials/" + id);
  };

  //delete function for testimonial
  const handleDelete = () => {
    setLoading(true);
    deleteTestimonial(selectedMember).then((res) => {
      if (res.data?.status) {
        setLoading(false);
        toast.error("Testimonial Deleted Successfully!!!");
        handleClose();
        if (allTestimonialData.length <= 1) {
          setPage(page - 1);
          getAllPagination();
        } else {
          getAllPagination();
        }
      } else {
        setLoading(false);
        toast.error(res.data?.err?.message);
      }
    });
  };

  return (
    <>
      <TopHeader />
      <Box>
        <div className='pt-1 '>
                    <div className='p-0'>
                        <div className=' Architects-header d-flex align-items-center justify-content-center '>
                            <h2 className='signika-fontOEC text-center '>Testimonials List</h2>
                        </div>
                    </div>
                </div>
      </Box>
      {loading ? (
        <Spinner />
      ) : (
        <Container maxWidth={"xl"}>
          <div className="d-flex justify-content-between align-items-center mt-5">
            <div>
              <span
               style={{width:'200px'}}
                className="ms-1"
              ></span>
            </div>
            <Link to="/admin/addEditTestimonials">
              <Button
                variant="contained"
                sx={{ width: "max-content", float: "right" }}
                className="menus-color"
              >
                Add Testimonial
              </Button>
            </Link>
          </div>
          <Box sx={{ width: "80%" }} className="memberTable mb-2">
            <Paper sx={{ width: "100%", mb: 2 }}>
              <TableContainer>
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                  <EnhancedTableHead />
                  <TableBody>
                    {allTestimonialData.length > 0 ? (
                      allTestimonialData.map((testimonial, index) => (
                        <TableRow
                          hover
                          key={testimonial._id}
                          sx={{
                            cursor: "pointer",
                            backgroundColor:
                              index % 2 === 0 ? "#f0f0f0" : "white",
                          }}
                        >
                          <TableCell align="center">
                            <img
                              src={
                                testimonial.imageUrl
                                  ? testimonial.imageUrl
                                  : "/assets/images/login/userLogo.png"
                              }
                              width={"40px"}
                              height={"40px"}
                              alt="View Testimonial"
                            />
                          </TableCell>
                          <TableCell align="center">
                            {testimonial.name}
                          </TableCell>
                          <TableCell align="center">
                            {testimonial.content.slice(0, 50)}...
                          </TableCell>
                          <TableCell align="center">
                            <Tooltip title="Edit">
                              <IconButton
                                aria-label="edit"
                                onClick={() => {
                                  handleEdit(testimonial._id);
                                }}
                              >
                                <EditIcon style={{ color: "green" }} />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete">
                              <IconButton
                                aria-label="delete"
                                onClick={() => {
                                  handleOpen(testimonial._id);
                                }}
                              >
                                <DeleteIcon style={{ color: "red" }} />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <>
                        <TableRow hover>
                          <TableCell colSpan={4} align="center">
                            No Data Available
                          </TableCell>
                        </TableRow>
                      </>
                    )}
                  </TableBody>
                  <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box
                      sx={style}
                      className="d-flex align-items-center flex-column gap-3"
                    >
                      <Typography
                        id="modal-modal-title"
                        style={{color:"#611f1e"}} 
                        variant="h6"
                        component="h2"
                      >
                        Are You Surely Want to Delete this Testimonial?
                      </Typography>
                      <div>
                        <Button
                          variant="outlined"
                          color="success"
                          onClick={handleClose}
                        >
                          No
                        </Button>
                        <Button
                          className="mx-2"
                          variant="contained"
                          color="error"
                          onClick={() => handleDelete()}
                        >
                          Yes
                        </Button>
                      </div>
                    </Box>
                  </Modal>
                </Table>
              </TableContainer>
            </Paper>
            <div className="w-100 d-flex justify-content-center pb-4">
              {allTestimonialData.length > 0 ? (
                <ReactPaginate
                  pageCount={pageCount} // Calculate total pages
                  pageRangeDisplayed={2}
                  marginPagesDisplayed={1}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination"}
                  activeClassName={"active"}
                  previousLabel={pageCount > 1 && page >= 1 ? "Previous" : null}
                  nextLabel={
                    pageCount > 1 && page + 1 !== pageCount ? "Next" : null
                  }
                  disabledClassName={"disablebtn"}
                  forcePage={page}
                />
              ) : (
                <></>
              )}
            </div>
          </Box>
        </Container>
      )}
    </>
  );
};

export default ViewTestimonial;
