import React, { useState, useEffect } from "react";
import { Card, CardContent, CardMedia, Grid, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { useLazyGetAllEventsQuery } from "../../app/services/eventService";
import { selectCurrentMember } from "../../app/features/memberSlice";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { selectCurrentScope } from "../../app/auth/authSlice";
import { selectCurrentSig } from "../../app/features/sigSlice";
import { selectCurrentChapterAdmin } from "../../app/features/chapterAdminSlice";
import Spinner from "../../Component/spinner/spinner";

const Event = () => {
  const [eventDetails, setEventDetails] = useState([]);
  const [getAllEvents] = useLazyGetAllEventsQuery();
  const { sigGroupId } = useParams();
  const { chapterId } = useParams();
  const location = useLocation();
  const url = location.pathname;
  const memberDetails = useSelector(selectCurrentMember);
  const userSIG = memberDetails?.sigGroups;
  const userChapter = memberDetails?.chapter;
  const currentScope = useSelector(selectCurrentScope);
  const navigate = useNavigate();
  const sigDetails = useSelector(selectCurrentSig);
  const chapterDetails = useSelector(selectCurrentChapterAdmin);
  const [loading, setLoading] = useState(true);
  const [chapterIdLocal, setChapterIdLocal] = useState();

  useEffect(() => {
    const storedChapterId = localStorage.getItem('chapterId');
    if (storedChapterId) {
      setChapterIdLocal(storedChapterId);
    }
  }, []);

  useEffect(() => {
    //fetch event Details
    const fetchEvents = async () => {
      try {
        const res = await getAllEvents();
        const data = res?.data?.data;
        let filteredEvents = [];
        if (data && data.length > 0) {
          if (currentScope === "PORTAL_MEMBER") {
            filteredEvents = data.filter((event) => {
              if (chapterIdLocal) {
                return (
                  url.includes("/view-Chapter") &&
                  event.chaptersId.includes(chapterId) &&
                  event.isApproved === 1
                );
              }
              if (sigGroupId) {
                return (
                  url.includes("/viewSigDetails") &&
                  event.SIGGroupsId.includes(sigGroupId) &&
                  event.isApproved === 1
                );
              }
              return (
                (event.isPublic ||
                  event.isMember ||
                  haveCommonElements(event.SIGGroupsId, userSIG) ||
                  event.chaptersId.includes(userChapter)) &&
                event.isApproved === 1
              );
            });
          } else if (currentScope === "PORTAL_SIG_ADMIN") {
            filteredEvents = data.filter((event) => {
              if (chapterIdLocal) {
                return (
                  url.includes("/view-Chapter") &&
                  event.chaptersId.includes(chapterId) &&
                  event.isApproved === 1
                );
              }
              if (sigGroupId) {
                return (
                  url.includes("/viewSigDetails") &&
                  event.SIGGroupsId.includes(sigGroupId) &&
                  event.isApproved === 1
                );
              }
              return (
                (event.SIGGroupsId.includes(sigDetails?._id) &&
                  event.isApproved === 1) ||
                (event.isPublic && event.isApproved === 1) ||
                (event.isMember && event.isApproved === 1)
              );
            });
          } else if (currentScope === "PORTAL_CHAPTER_ADMIN") {
            filteredEvents = data.filter((event) => {
              if (chapterIdLocal) {
                return (
                  url.includes("/view-Chapter") &&
                  event.chaptersId.includes(chapterId) &&
                  event.isApproved === 1
                );
              }
              if (sigGroupId) {
                return (
                  url.includes("/viewSigDetails") &&
                  event.SIGGroupsId.includes(sigGroupId) &&
                  event.isApproved === 1
                );
              }
              return (
                (event.chaptersId.includes(chapterDetails?._id) &&
                  event.isApproved === 1) ||
                (event.isPublic && event.isApproved === 1) ||
                (event.isMember && event.isApproved === 1)
              );
            });
          } else {
            filteredEvents = data.filter((event) => {
              if (chapterId) {
                return url.includes("/view-Chapter") && (event.chaptersId.includes(chapterId) && event.isApproved === 1)
              }
              if (sigGroupId) {
                return url.includes("/viewSigDetails") && (event.SIGGroupsId.includes(sigGroupId) && event.isApproved === 1)
              }
              return event.isApproved === 1;
            });
          }
        }
        setEventDetails(filteredEvents);
      } catch (error) {
        console.error("Error fetching events:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchEvents();
  }, [
    getAllEvents,
    chapterId,
    sigGroupId,
    url,
    currentScope,
    sigDetails,
    userSIG,
    userChapter,
    chapterDetails,
    chapterIdLocal
  ]);

  // COMMON element
  function haveCommonElements(arr1, arr2) {
    return arr1.some((item) => arr2?.includes(item));
  }

  //REGISTER BUTTON
  const registeredBtn = (event) => {
    const membersId = event?.membersId;
    const memberRegister = membersId.filter((id) => id === memberDetails?._id);
    if (memberRegister.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  // on click the event have to route to the view event page
  const viewevent = (eventId) => {
    if (currentScope === "PORTAL_SIG_ADMIN") {
      navigate("/sigAdmin/VieweventFeed/" + eventId);
    } else if (currentScope === "PORTAL_SUPER_ADMIN") {
      navigate("/admin/VieweventFeed/" + eventId);
    } else if (currentScope === "PORTAL_MEMBER") {
      navigate("/member/VieweventFeed/" + eventId);
    } else if (currentScope === "PORTAL_CHAPTER_ADMIN") {
      navigate("/chapterAdmin/VieweventFeed/" + eventId);
    }
  };

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <>
          {eventDetails?.length > 0 ? (
            eventDetails.map((event) => (
              <Card
                key={event?._id}
                className="onclick"
                sx={{
                  position: "relative",
                  maxWidth: "90%",
                  mx: "auto",
                  boxShadow: "none",
                  background: "transparent",
                }}
                onClick={() => viewevent(event?._id)}
              >
                {registeredBtn(event) && (
                  <span
                    style={{
                      right: "0%",
                      top: "40%",
                      marginTop: "17px",
                      padding: "8px",
                      backgroundColor: "#333",
                    }}
                    className="position-absolute top-0 translate-middle badge"
                  >
                    Registered
                    <span className="visually-hidden">Registered</span>
                  </span>
                )}
                <CardContent>
                  <div
                    style={{
                      border: "1px solid #a52a2a",
                      borderRadius: "15px",
                      padding: "10px",
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={4} style={{ position: "relative" }}>
                        <div style={{ position: "relative" }}>
                          <CardMedia
                            component="img"
                            height="160"
                            image={event?.imageUrl}
                            alt="Tab 1 Image"
                            style={{
                              objectFit: "cover",
                              borderRadius: "15px",
                            }}
                          />
                          <Typography
                            variant="h6"
                            style={{
                              position: "absolute",
                              left: "18%",
                              bottom: "7%",
                              color: "#fff",
                              background: "#611f1e",
                              padding: "5px 10px",
                              borderRadius: "8px",
                            }}
                          >
                            Speaker:{" "}
                            {event?.speakers && event?.speakers[0]?.speakerName}
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography variant="h5" color="#a52a2a">
                          {event?.title}
                        </Typography>
                        <Typography variant="body2">
                          {event?.description && event?.description.slice(0, 50)}
                          ...
                        </Typography>
                        <div
                          className="d-flex justify-content-end"
                          style={{
                            color: "#a52a2a",
                            position: "absolute",
                            bottom: "10%",
                            left: "50%",
                            fontWeight: "bold",
                          }}
                        >
                          Start Date:{" "}
                          {event?.startDate
                            .split("T")[0]
                            .split("-")
                            .reverse()
                            .join("-")}{" "}
                          End Date:{" "}
                          {event?.endDate
                            .split("T")[0]
                            .split("-")
                            .reverse()
                            .join("-")}
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </CardContent>
              </Card>
            ))
          ) : (
            <p className="text-black fs-2 text-capitalize lead text-center">
              No Data Available
            </p>
          )}
        </>
      )}
    </>
  );
};

export default Event;
