import { createSlice } from "@reduxjs/toolkit";
const memberSlice=createSlice({
    name:'member',

    //initial state
    initialState:{
        memberDetails:{}
    },
    
    //reducer
    reducers:{
        setMemberDetails:(state,action)=>{
            state.user=action.payload;
        },
    }
})

export default memberSlice.reducer
export const {setMemberDetails}=memberSlice.actions
export const selectCurrentMember=(state)=>state.member.user