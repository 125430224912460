import { api } from "./api";

const newsLetterServices = api.injectEndpoints({
  endpoints: (builder) => ({
    //save NewsLetter
    saveNewsLetter: builder.mutation({
      query: (formData) => ({
        url: "newsletter/saveNewsletter",
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: formData,
      }),
    }),

    //get All NewsLetter
    getAllNewsLetter: builder.query({
      query: () => ({
        url: "newsletter/getall",
      }),
    }),

    //get NewsLetter Details by Id
    getNewsLetterDetailsById: builder.query({
      query: (id) => "newsletter/get/" + id,
    }),

    //Update NewsLetter
    updateNewsLetter: builder.mutation({
      query: (data) => ({
        url: "newsletter/update/" + data.id,
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: data.formData,
      }),
    }),

    //delete NewsLetter
    deleteNewsLetter: builder.mutation({
      query: (data) => ({
        url: "newsletter/delete/" + data.id,
        method: "PUT",
        headers: { "Content-Type": "application/json" },
      }),
    }),

    //publish NewsLetter
    publishNewsLetter: builder.mutation({
      query: (data) => ({
        url: "newsletter/publish/" + data.id,
        method: "PUT",
        headers: { "Content-Type": "application/json" },
      }),
    }),

    //enableDisable NewsLetter
    enableDisableNewsLetter: builder.mutation({
      query: (data) => ({
        url: "newsletter/enableDisable/" + data.id,
        method: "PUT",
        headers: { "Content-Type": "application/json" },
      }),
    }),

     //pagination NewsLetter
     paginationNewsLetter: builder.mutation({
      query: (data) => ({
        url: "newsletter/getAllNewsletterPagination",
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: data,
      }),
    }),

  }),
  overrideExisting: false,
});

export const {
  useSaveNewsLetterMutation,
  useGetAllNewsLetterQuery,
  useLazyGetAllNewsLetterQuery,
  useLazyGetNewsLetterDetailsByIdQuery,
  useGetNewsLetterDetailsByIdQuery,
  usePaginationNewsLetterMutation,
  useDeleteNewsLetterMutation,
  usePublishNewsLetterMutation,
  useEnableDisableNewsLetterMutation,
  useUpdateNewsLetterMutation,
} = newsLetterServices;
