function TopHeader() {
    return (
        // <Box>
        //     <Grid container spacing={2}>
        //         <Grid className='logo_psn' item xs={12} sm={3}>
        //             <Box>
        //                 <img src={logo} alt="Logo" className='logo' />
        //             </Box>
        //         </Grid>
        //         <Grid item xs={12} sm={9} className='social_icons'>
        //             <Box className="d-flex justify">
        //                 <Box className="d-flex justify without_btn">
        //                     <Box className="d-flex justify icon_container">
        //                         <a target='blank' href="https://www.facebook.com/ELTAI.India" className='p-0'>
        //                             <FacebookRoundedIcon className='rnd_fb cmn_icn me-2' />
        //                         </a>
        //                         <a target='blank' href="https://www.twitter.com/EltaiIndia" className='p-0'>
        //                             <TwitterIcon className='rnd_twitter cmn_icn me-2' />
        //                         </a>
        //                         <a target='blank' href="https://www.instagram.com/eltai.india" className='p-0'>
        //                             <InstagramIcon className='out_insta cmn_icn me-2' />
        //                         </a>
        //                         <a target='blank' href="https://www.youtube.com/@ELTAIIndia" className='p-0'>
        //                             <YouTubeIcon className='out_utube cmn_icn me-2' />
        //                         </a>
        //                     </Box>
        //                 </Box>
        //                 {memberScope === 'PORTAL_SUPER_ADMIN' || memberScope === 'PORTAL_CHAPTER_ADMIN' || memberScope === 'PORTAL_SIG_ADMIN' || memberScope === 'PORTAL_MEMBER' ? (
        //                     <React.Fragment>
        //                         <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        //                             <Tooltip title="Profile">
        //                                 <IconButton
        //                                     onClick={handleClick}
        //                                     size="small"
        //                                     sx={{ ml: 2 }}
        //                                     aria-controls={open ? 'account-menu' : undefined}
        //                                     aria-haspopup="true"
        //                                     aria-expanded={open ? 'true' : undefined}
        //                                 >
        //                                     <Avatar sx={{ width: 32, height: 32, bgcolor: '#0072bb' }}><ManageAccountsIcon className='accountIcon' /></Avatar>
        //                                 </IconButton>
        //                             </Tooltip>
        //                         </Box>
        //                         <Menu
        //                             anchorEl={anchorEl}
        //                             id="account-menu"
        //                             open={open}
        //                             onClose={handleClose}
        //                             onClick={handleClose}
        //                             PaperProps={{
        //                                 elevation: 0,
        //                                 sx: {
        //                                     overflow: 'visible',
        //                                     filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
        //                                     mt: 1.5,
        //                                     '& .MuiAvatar-root': {
        //                                         width: 32,
        //                                         height: 32,
        //                                         ml: -0.5,
        //                                         mr: 1,
        //                                     },
        //                                     '&::before': {
        //                                         content: '""',
        //                                         display: 'block',
        //                                         position: 'absolute',
        //                                         top: 0,
        //                                         right: 14,
        //                                         width: 10,
        //                                         height: 10,
        //                                         bgcolor: 'primary.main',
        //                                         transform: 'translateY(-50%) rotate(45deg)',
        //                                         zIndex: 0,
        //                                     },
        //                                 },
        //                             }}
        //                             transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        //                             anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        //                         >
        //                             {memberScope === 'PORTAL_MEMBER' ? (
        //                                 <MenuItem onClick={routeToPortal}>
        //                                     <Avatar />
        //                                     {memberDetails && memberDetails.firstName} ({role})
        //                                 </MenuItem>
        //                             ) : memberScope === 'PORTAL_CHAPTER_ADMIN' ? (<MenuItem onClick={routeToPortal}>
        //                                 <Avatar />
        //                                 {roleDetails && roleDetails.name}  ({role})
        //                             </MenuItem>) : memberScope === 'PORTAL_SIG_ADMIN' ? (
        //                                 <MenuItem onClick={routeToPortal}>
        //                                     <Avatar />
        //                                     {sigRoleDetails && sigRoleDetails.name} ({role})
        //                                 </MenuItem>) : (<MenuItem onClick={routeToPortal}>
        //                                     <Avatar />
        //                                     ({role})
        //                                 </MenuItem>)}
        //                             <Divider />
        //                             <MenuItem onClick={handleLogout}>
        //                                 <ListItemIcon>
        //                                     <Logout fontSize="small" />
        //                                 </ListItemIcon>
        //                                 Logout
        //                             </MenuItem>
        //                         </Menu>
        //                     </React.Fragment>

        //                 ) : (
        //                     <Box className="btn_container">
        //                         <Link to="/register" ><Button className='top_nav_btn_join'>Join</Button></Link>
        //                         <Link to='/login' ><Button className='top_nav_btn'>Login</Button></Link>
        //                     </Box>
        //                 )}
        //             </Box>
        //         </Grid>
        //     </Grid>
        // </Box>
        <></>
    );
}

export default TopHeader;
