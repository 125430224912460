import React from 'react'
import { Box, Container } from '@mui/material'
import './membershipPage.css'
import {membershipImage} from '../../constant/constant'
import { Link } from 'react-router-dom'
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
const MembershipPage = () => {

  return (
    <div className='ourExecutiveCommittee-containter'>
      <Box>
        <div className='pt-1 pb-5'>
          <div className='p-0'>
            <div className=' Architects-header d-flex align-items-center justify-content-center '>
              <h2 className='signika-fontOEC text-center '>Membership &nbsp; Form</h2>
            </div>
          </div>
        </div>
      </Box>
      <Container maxWidth="xl" className='pt-3'>
        <div className='row px-5 pb-5'>
          {
            membershipImage.map((data, i) => (
              <div className='col-lg-3 col-md-4' key={i}>
                <div className='card membershippage-image-card-bg my-3'>
                  <h3 className='signika-font text-center mb-3'>{data.Title}</h3>
                  <img src={data?.img} alt={data.role} className='membershippage-image' />
                  <Link to={'https://forms.gle/kthcZi1BQXjWtE5DA'} target='_blank' className='px-0'>
                    <button className='membershippage-role-card-bg p-3 d-flex justify-content-between'>
                      <h5 className='signika-font'>{data.role}<span className='ml-3'></span></h5>
                      <div className=''><ArrowCircleRightIcon/></div>
                    </button>
                  </Link>
                </div>
                <div className='d-flex justify-content-between'></div>
                <div className='signika-font text-justify  px-4 mt-1 membership-caption '>
                  {data.caption}
                </div>
              </div>
            ))
          }
        </div>
      </Container>
    </div>
  )
}

export default MembershipPage
