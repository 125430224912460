import React, { useState, useEffect, useCallback } from 'react';
import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap';
import '../../style/css/header.css';
import '../../style/css/banner.css';
import { selectCurrentScope } from '../../app/auth/authSlice';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom'
import banner1 from '../../assets/images/banner/banner2.png'
import banner2 from '../../assets/images/AboutPage/abt_bnr1.png'
import banner3 from '../../assets/images/banner/form_banner.png'
import banner5 from '../../assets/images/banner/viewwebinar.png'
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { logOut } from '../../app/auth/authSlice';
import { useDispatch } from 'react-redux';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Logout from '@mui/icons-material/Logout';
import { selectCurrentMember, setMemberDetails } from '../../app/features/memberSlice';
import { setRoleDetails, selectCurrentChapterAdmin, selectCurrenChapterRole, setChapterDetails } from "../../app/features/chapterAdminSlice";
import { setSigRoleDetails, selectCurrenSigRole, setSigDetails } from '../../app/features/sigSlice';
import { api } from '../../app/services/api';
import { useGetAllAnnouncementQuery } from '../../app/services/announcementService';
import Box from '@mui/material/Box';
import logo from '../../assets/images/logos/ELTA@I.svg'
import XIcon from '@mui/icons-material/X';
import { useLazyGetMemberDetailsByIdQuery } from '../../app/services/memberServices'
import constitutionpdf from '../../assets/pdf/Constitution-ELTAI.pdf'

function Header() {
    const [backgroundImage, setBackgroundImage] = useState('');
    const memberScope = useSelector(selectCurrentScope)
    const [dropdownHover, setDropdownHover] = useState(null);
    const navigate = useNavigate()
    const currentLocation = useLocation();
    const [subDropdownHover, setSubDropdownHover] = useState(null);
    const [topPosition, setTopPosition] = useState(0); // Default top position
    const [topBarButtons, setTopBarButtons] = useState(0); // Default top position
    const { data: getAllAnnouncement } = useGetAllAnnouncementQuery(0); //API call of get All announcement
    const [navMoreShow, setNavMoreShow] = useState(false)
    const [getMemberById] = useLazyGetMemberDetailsByIdQuery()
    const [expanded, setExpanded] = useState(false); // Manage expanded state
    const [isMobile, setIsMobile] = useState(false);
    const [cursorStyle, setCursorStyle] = useState("pointer");

    //Scrolling 
    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.pageYOffset;

            if (scrollTop > 50) {
                setTopPosition(0); // Change top position while scrolling

            } else {
                setTopPosition(0); // Default top position
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const location = useLocation()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [role, setRole] = useState('')
    const open = Boolean(anchorEl);
    const disPatch = useDispatch();
    const chapterDetail = useSelector(selectCurrentChapterAdmin);
    const memberDetails = useSelector(selectCurrentMember);
    const roleDetails = useSelector(selectCurrenChapterRole);
    const sigRoleDetails = useSelector(selectCurrenSigRole);
    const sigdetail = useSelector(selectCurrenSigRole)
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    //Close
    const handleClose = () => {
        setAnchorEl(null);
    };

    const membershipRouteUrl = (id) => {
        getMemberById(memberDetails?._id).then((res) => {
            if (res?.data?.data) {
                const memberType = res?.data?.data?.type;
                if (memberType === "0") {
                    navigate(`/member/teacher-member/${id}`);
                } else if (memberType === "1") {
                    navigate(`/member/student-member/${id}`);
                } else if (memberType === "2") {
                    navigate(`/member/View-institution/${id}`);
                } else {
                    navigate(`/member/view-corporate/${id}`);
                }
            }
        });
    }


    //handle Log Out
    const handleLogout = () => {
        disPatch(logOut())
        navigate('/login', { replace: true, state: { from: location } })
        disPatch(setMemberDetails({}))
        disPatch(setRoleDetails({}))
        disPatch(setSigRoleDetails({}))
        disPatch(setChapterDetails({}))
        disPatch(setSigDetails({}))
        disPatch(api.util.resetApiState())
    }

    //handleREsize
    const handleResize = useCallback(() => {
        const width = window.innerWidth;
        if (width >= 769 && width <= 1430) {
            setTopBarButtons(7)
            if (width < 992) {
                setTopBarButtons(56)
                setIsMobile(true);
            } else {
                setTopBarButtons(7)
                setIsMobile(false);
            }
            setNavMoreShow(true);
        } else if (width < 992) {
            setIsMobile(true);
            setTopBarButtons(56)
            setNavMoreShow(false);
        } else if (width >= 992) {
            setTopBarButtons(7)
            setNavMoreShow(false);
            setIsMobile(false);
        } else {
            // setTopBarButtons(7)
            setNavMoreShow(false);
        }
    }, []);



    useEffect(() => {
        window.addEventListener('resize', handleResize);
        handleResize(); // Call it initially to set state based on the initial window size
        return () => window.removeEventListener('resize', handleResize);
    }, [handleResize]);

    //role member Scope
    useEffect(() => {
        if (memberScope === 'PORTAL_SUPER_ADMIN') {
            setRole('Admin');
        } else if (memberScope === 'PORTAL_MEMBER') {
            setRole('Member');
        }
        else if (memberScope === 'PORTAL_SIG_ADMIN') {
            setRole('SIG Admin');
        }
        else if (memberScope === 'PORTAL_CHAPTER_ADMIN') {
            setRole('Chapter Admin');
        }
    }, [memberScope]);

    //route of Potral 
    const routeToPortal = () => {
        if (role === 'Member') {
            navigate('/member/viewMember')
        }
        else if (role === 'SIG Admin') {
            navigate('/sigAdmin/sigDetails')
        }
        else if (role === 'Chapter Admin') {
            navigate('/chapterAdmin/chapterDetail')
        }
        else if (role === "Admin") {
            setCursorStyle("default");
            return;
        }
    }

    //handlemouseenter
    const handleMouseEnter = (index) => {
        setDropdownHover(index);
        setSubDropdownHover(null); // Reset sub-dropdown hover
    };

    //handlemouseleave
    const handleMouseLeave = () => {
        setDropdownHover(null);
        setSubDropdownHover(null);
    };

    const handleMobileClick = (index) => {
        if (isMobile) {
            // Toggle dropdown visibility on mobile
            setDropdownHover(dropdownHover === index ? null : index);
        }
    };


    const handleSubMobileClick = (index) => {
        if (isMobile) {
            // Toggle nested submenu on mobile
            setSubDropdownHover(subDropdownHover === index ? null : index);
        }
    };

    //handle Sub Mouse Enter
    const handleSubMouseEnter = (index) => {
        setSubDropdownHover(index);
        // if(isMobile){
        //     setSubDropdownHover(subDropdownHover === index ? null : index);
        // }
    };

    //handle Sub Mouse Leave
    const handleSubMouseLeave = () => {
        setSubDropdownHover(null);
    };

    //menu Items
    const menuItems = memberScope === 'PORTAL_SUPER_ADMIN' ? [
        {
            label: 'Home',
            url: '/admin/'
        },
        {
            label: 'About',
            // url:'/aboutUs',
            subMenu: [
                { label: 'About ELTAI', url: '/admin/aboutUs' },
                { label: 'Vision', url: '/admin/vision' },
                { label: 'Mission', url: '/admin/mission' },
                { label: "Present Office Bearers", url: '/admin/officeBearers' }
            ]
        },
        {
            label: 'Membership',
            url: '/membership',
            subMenu: [
                { label: 'Join', url: '/admin/join-eltai' },
                { label: 'Membership Plans', url: '/admin/viewMembershipPlans' },
                { label: 'Member List', url: '/admin/allMemberList' },
            ]
        },
        {
            label: 'Chapters',
            url: '/chapters',
            subMenu: [
                { label: 'Chapters List', url: '/admin/view-Chapter' },
                { label: "Start a Chapter", url: '/admin/chapter-starting' }
            ]
        },
        {
            label: 'SIGs',
            url: '/sig',
            subMenu: [
                { label: 'SIG List', url: '/admin/addEditSigGroups' },
            ]
        },
        {
            label: 'Events',
            url: '/events',
            subMenu: [
                { label: 'Event Type', url: '/admin/eventType' },
                { label: 'Event List', url: '/admin/eventList' },
            ]
        },
        {
            label: 'Gallery',
            url: '/admin/gallery-table',
        },
        {
            label: 'Contact Us',
            url: '/admin/contact-us'
        },
        {
            label: 'More',
            subMenu: [
                { label: 'Category ', url: '/admin/viewCategory' },
                { label: 'Flash News', url: '/admin/announcement' },
                { label: 'Announcement', url: '/admin/importannoucenment' },
                { label: 'Testimonials', url: '/admin/viewTestimonials' },
                { label: 'Forum', url: '/admin/forumTable' },
                { label: 'News', url: '/admin/journalTable' },
                { label: 'Newsletter', url: '/admin/viewNewsLetter' },
                { label: 'Annual Conference', url: '/admin/annualConferenceList' },
                { label: 'Admin Approval', url: '/admin/approval' },
            ]
        },
    ] :
        memberScope === 'PORTAL_SIG_ADMIN' ? [
            {
                label: 'Home',
                url: '/sigAdmin/'
            },
            {
                label: 'About Us',
                subMenu: [
                    { label: 'About ELTAI', url: '/sigAdmin/aboutUs' },
                    { label: 'Vision', url: '/sigAdmin/vision' },
                    { label: 'Mission', url: '/sigAdmin/mission' },
                    { label: "Present Office Bearers", url: '/sigAdmin/officeBearers' }
                ]
            },
            {
                label: 'Membership',
            },
            {
                label: 'Chapters',
                url: '/chapters',
                subMenu: [
                    { label: 'Chapters List', url: '/sigAdmin/view-Chapter' },
                    { label: "Start a Chapter", url: '/sigAdmin/chapter-starting' }
                ]
            },
            {
                label: 'SIGs',
                subMenu: [
                    { label: 'Sig List', url: '/sigAdmin/addEditSigGroups' },
                ]
            },
            {
                label: 'Events',
                url: '/events',
                subMenu: [
                    { label: 'Event List', url: '/sigAdmin/eventList' },
                ]
            },
            {
                label: 'Gallery',
                url: '/sigAdmin/gallery-table',

            },
            {
                label: 'Contact Us',
                url: 'sigAdmin/contact-us'
            },
            {
                label: 'More',
                subMenu: [
                    { label: 'Forum', url: '/sigAdmin/forumTable' },
                    // { label: 'View Forum', url: '/viewForum' },
                    { label: 'News', url: '/sigAdmin/journalTable' },
                    { label: 'Announcement', url: '/sigadmin/importannoucenment' },
                ]
            },

        ] : memberScope === 'PORTAL_CHAPTER_ADMIN' ? [
            {
                label: 'Home',
                url: '/chapterAdmin/'
            },
            {
                label: 'About Us',
                subMenu: [
                    { label: 'About ELTAI', url: '/chapterAdmin/aboutUs' },
                    { label: 'Vision', url: '/chapterAdmin/vision' },
                    { label: 'Mission', url: '/chapterAdmin/mission' },
                    { label: "Present Office Bearers", url: '/chapterAdmin/officeBearers' }
                ]
            },
            {
                label: 'Membership',
                url: '/chapterAdmin/join-eltai',
            },
            {
                label: 'Chapters',
                url: '/chapters',
                subMenu: [
                    { label: 'Chapters List', url: '/chapterAdmin/view-Chapter' },
                    { label: "Start a Chapter", url: '/chapterAdmin/chapter-starting' }
                ]
            },
            {
                label: 'SIGs',
                subMenu: [
                    { label: 'Sig List', url: '/chapterAdmin/addEditSigGroups' }
                ]
            },
            {
                label: 'Events',
                url: '/events',
                subMenu: [
                    { label: ' Event List', url: '/chapterAdmin/eventList' }
                ]
            },
            {
                label: 'Gallery',
                url: '/chapterAdmin/gallery-table',

            },
            {
                label: 'Contact us',
                url: '/chapterAdmin/contact-us'
            },
            {
                label: 'More',
                subMenu: [
                    { label: 'Forum', url: '/chapterAdmin/forumTable' },
                    // { label: 'View Forum', url: '/viewForum' },
                    { label: 'News', url: '/chapterAdmin/journalTable' },
                    { label: 'Announcement', url: '/chapterAdmin/importannoucenment' },
                ]
            },
        ] :
            memberScope === 'PORTAL_MEMBER' ?
                (!navMoreShow) ?
                    (
                        [
                            {
                                label: 'Home',
                                url: '/'
                            },
                            {
                                label: 'About Us',
                                url: '/aboutUs',
                                subMenu: [
                                    { label: 'Who We Are ', url: '/aboutUs' },
                                    { label: ' Our Vision', url: '/vision' },
                                    { label: 'Our Mission', url: '/mission' },
                                    { label: 'Our History', url: '/ourHistory ' },
                                    { label: 'Our Architects', url: '/ourArchitects' },
                                    // { label: 'Founder' },
                                    { label: 'Our Constitution', url: constitutionpdf },
                                    { label: 'Our Executive Committee', url: '/ourExecutiveCommittee' },
                                    // { label: 'Endowments' },
                                    // { label: "Present Office Bearers", url: '/officeBearers' },
                                    // { label: "Committees" },
                                ]
                            },
                            {
                                label: 'Join',
                                url: '/join-eltai',
                                subMenu: [
                                    { label: 'Membership Benefits', },
                                    { label: 'Become a member ', url: '/member/membershipPage' },
                                    { label: 'Our Institutional Members', url: '/member/membership-institution' },
                                    { label: 'Membership', url: '/member/join-eltai' },
                                ]
                            },
                            {
                                label: 'Network',
                                subMenu: [
                                    {
                                        label: 'Chapters',
                                        subMenu: [
                                            { label: 'Find a Chapter ', url: "/member/view-Chapter" },
                                            { label: "Start a Chapter", url: '/member/chapter-starting' },
                                            { label: 'Chapters List', url: '/member/view-Chapter' },
                                        ]
                                    },
                                    {
                                        label: 'SIGs',
                                        subMenu: [
                                            // { label: 'Sig List', url: '/addEditSigGroups' },
                                            { label: 'Literature SIG' },
                                            { label: 'Computer Technology SIG' },
                                            { label: 'Business English SIG' },
                                            // { label: 'SIGs with ELTAI ', url: '/eltai-sig' },
                                            { label: 'Starting a SIG with ELTAI ', url: 'starting-sig' },
                                            { label: ' Sig List', url: '/member/addEditSigGroups' },
                                        ]
                                    },
                                ]
                            },
                            // {
                            //     label: 'Chapters',
                            //     url: '/chapters',
                            //     subMenu: [
                            //         { label: 'Chapters List', url: '/view-Chapter' },
                            //         { label: "Starting a Chapter", url: '/starting-chapter' },

                            //     ]
                            // },
                            {
                                label: 'Publish',
                                url: '/chapters',
                                subMenu: [
                                    { label: "Journal of English Language Teaching (JELT)", url: 'https://journals.eltai.in/index.php/jelt/index' },
                                    { label: "Journal of Teaching & Research in English Literature (JTREL)", url: 'https://journals.eltai.in/index.php/jtrel' },
                                    { label: "Journal of Technology for ELT (JTELT)", url: 'https://journals.eltai.in/index.php/jtelt' },
                                    { label: "ELT Practitioner" },
                                    { label: "Our Newsletter" },

                                ]
                            },
                            // {
                            //     label: 'SIGs',
                            //     subMenu: [
                            //         { label: 'Sig List', url: '/addEditSigGroups' },
                            //         { label: 'Literature SIG' },
                            //         { label: 'Computer Technology SIG' },
                            //         { label: 'Business English SIG' },
                            //         { label: 'Starting a Chapter with ELTAI ',url:'/eltai-sig' },
                            //     ]
                            // },
                            {
                                label: 'India Reads',
                                subMenu: [
                                    { label: 'Why India Reads ', url: '/India-Reads' },
                                    { label: 'News', url: '' },
                                    // { label: 'News', url: '/news' },
                                    { label: 'LMS', url: 'https://indiareads.eltai.in/' },
                                ]
                            },
                            {
                                label: 'Partner',
                                subMenu: [
                                    { label: 'Partner with Us  ' },
                                    { label: 'Our Partners', url: '/partner' },
                                ]
                            },
                            {
                                label: 'Consult',
                                url: ''
                            },
                            {
                                label: 'Awards',
                                url: ''
                            },
                            {
                                label: 'Participate ',
                                subMenu: [
                                    { label: 'Our Annual Conference', url: 'annual-conference' },
                                    { label: 'Our Webinars ' },
                                    {
                                        label: 'Our Upcoming Events',
                                        url: '/member/eventFeed'
                                        // subMenu: [
                                        //     { label: 'Event List', url: '/member/eventFeed' },
                                        // ]
                                    },
                                    { label: 'Calendar of Events' },
                                ]
                            },
                            {
                                label: 'Archives ',
                                subMenu: [
                                    { label: 'Reports   ' },
                                    { label: 'Galleries ', url: '/view-Gallery', },
                                ]
                            },
                            {
                                label: 'Contact Us', url: '/contact-us'
                            }
                        ]
                    ) : (
                        [
                            {
                                label: 'Home',
                                url: '/'
                            },
                            {
                                label: 'About Us',
                                url: '/aboutUs',
                                subMenu: [
                                    { label: 'Who We Are ', url: '/aboutUs' },
                                    { label: ' Our Vision', url: '/vision' },
                                    { label: 'Our Mission', url: '/mission' },
                                    { label: 'Our History', url: '/ourHistory ' },
                                    { label: 'Our Architects', url: '/ourArchitects' },
                                    // { label: 'Founder' },
                                    { label: 'Our Constitution', url: constitutionpdf },
                                    { label: 'Our Executive Committee', url: '/ourExecutiveCommittee' },
                                    // { label: 'Endowments' },
                                    // { label: "Present Office Bearers", url: '/officeBearers' },
                                    // { label: "Committees" },
                                ]
                            },
                            {
                                label: 'Join',
                                url: '/join-eltai',
                                subMenu: [
                                    { label: 'Membership Benefits', },
                                    { label: 'Become a member ', url: '/membershipPage' },
                                    { label: 'Our Institutional Members', url: '/member/membership-institution' },
                                    { label: 'Membership', url: '/member/join-eltai' },
                                ]
                            },
                            {
                                label: 'Network',
                                subMenu: [
                                    {
                                        label: 'Chapters',
                                        subMenu: [
                                            { label: 'Find a Chapter ', url: "/member/view-Chapter" },
                                            { label: "Start a Chapter", url: '/member/chapter-starting' },
                                            { label: 'Chapters List', url: '/member/view-Chapter' },
                                        ]
                                    },
                                    {
                                        label: 'SIGs',
                                        subMenu: [
                                            // { label: 'Sig List', url: '/addEditSigGroups' },
                                            { label: 'Literature SIG' },
                                            { label: 'Computer Technology SIG' },
                                            { label: 'Business English SIG' },
                                            // { label: 'SIGs with ELTAI ', url: '/eltai-sig' },
                                            { label: 'Starting a SIG with ELTAI ', url: 'starting-sig' },
                                            { label: ' Sig List', url: '/member/addEditSigGroups' },
                                        ]
                                    },
                                ]
                            },
                            // {
                            //     label: 'Chapters',
                            //     url: '/chapters',
                            //     subMenu: [
                            //         { label: 'Chapters List', url: '/view-Chapter' },
                            //         { label: "Starting a Chapter", url: '/starting-chapter' },

                            //     ]
                            // },
                            {
                                label: 'Publish',
                                url: '/chapters',
                                subMenu: [
                                    { label: "Journal of English Language Teaching (JELT)", url: 'https://journals.eltai.in/index.php/jelt/index' },
                                    { label: "Journal of Teaching & Research in English Literature (JTREL)", url: 'https://journals.eltai.in/index.php/jtrel' },
                                    { label: "Journal of Technology for ELT (JTELT)", url: 'https://journals.eltai.in/index.php/jtelt' },
                                    { label: "ELT Practitioner" },
                                    { label: "Our Newsletter" },

                                ]
                            },
                            // {
                            //     label: 'SIGs',
                            //     subMenu: [
                            //         { label: 'Sig List', url: '/addEditSigGroups' },
                            //         { label: 'Literature SIG' },
                            //         { label: 'Computer Technology SIG' },
                            //         { label: 'Business English SIG' },
                            //         { label: 'Starting a Chapter with ELTAI ',url:'/eltai-sig' },
                            //     ]
                            // },
                            {
                                label: 'India Reads',
                                subMenu: [
                                    { label: 'Why India Reads ', url: '/India-Reads' },
                                    { label: 'News', url: '' },
                                    // { label: 'News', url: '/news' },
                                    { label: 'LMS', url: 'https://indiareads.eltai.in/' },
                                ]
                            },
                            {
                                label: 'Partner',
                                subMenu: [
                                    {
                                        label: 'Partner with Us  ',
                                        subMenu: [
                                            { label: 'Our Annual Conference', url: 'annual-conference' },
                                            { label: 'Our Webinars ' },
                                            { label: 'Calender' },
                                        ]
                                    },
                                    { label: 'Our Partners', url: '/partner' },
                                ]
                            },
                            {
                                label: 'Contact Us', url: '/contact-us'
                            },
                            {
                                label: 'More',
                                subMenu: [
                                    {
                                        label: 'Consult',
                                        url: ''
                                    },
                                    {
                                        label: 'Awards',
                                        url: ''
                                    },
                                    {
                                        label: 'Participate ',
                                        subMenu: [
                                            { label: 'Our Annual Conference', url: 'annual-conference' },
                                            { label: 'Our Webinars ' },
                                            {
                                                label: 'Our Upcoming Events',
                                                url: '/eventFeed'
                                                // subMenu: [
                                                //     { label: 'Event List', url: '/member/eventFeed' },
                                                // ]
                                            },
                                            { label: 'Calendar of Events' },
                                        ]
                                    },
                                    {
                                        label: 'Archives ',
                                        subMenu: [
                                            { label: 'Reports   ' },
                                            { label: 'Galleries ', url: '/view-Gallery', },
                                        ]
                                    }
                                ]
                            },
                            // {
                            //     label: 'Consult',
                            //     url: ''
                            // },
                            // {
                            //     label: 'Awards',
                            //     url: ''
                            // },
                            // {
                            //     label: 'Participate ',
                            //     subMenu: [
                            //         { label: 'Our Annual Conference',url:'annual-conference' },
                            //         { label: 'Our Webinars ' },
                            //         {label:'Our Upcoming Events'},
                            //         {label:'Calender'},
                            //     ]
                            // },
                            // {
                            //     label: 'Archives ',
                            //     subMenu: [
                            //         { label: 'Reports   ' },
                            //         { label: 'Galleries ' },
                            //     ]
                            // },
                            // {
                            //     label: 'Contact Us',url:'/contact-us' 
                            // }
                        ]

                    )
                :

                (!navMoreShow) ?
                    (
                        [
                            {
                                label: 'Home',
                                url: '/'
                            },
                            {
                                label: 'About Us',
                                url: '/aboutUs',
                                subMenu: [
                                    { label: 'Who We Are ', url: '/aboutUs' },
                                    { label: ' Our Vision', url: '/vision' },
                                    { label: 'Our Mission', url: '/mission' },
                                    { label: 'Our History', url: '/ourHistory ' },
                                    { label: 'Our Architects', url: '/ourArchitects' },
                                    // { label: 'Founder' },
                                    { label: 'Our Constitution', url: constitutionpdf },
                                    { label: 'Our Executive Committee', url: '/ourExecutiveCommittee' },
                                    // { label: 'Endowments' },
                                    // { label: "Present Office Bearers", url: '/officeBearers' },
                                    // { label: "Committees" },
                                ]
                            },
                            {
                                label: 'Join',
                                url: '/join-eltai',
                                subMenu: [
                                    { label: 'Membership Benefits', },
                                    { label: 'Become a member ', url: '/membershipPage' },
                                    { label: 'Our Institutional Members', url: '/membership-institution' }
                                ]
                            },
                            {
                                label: 'Network',
                                subMenu: [
                                    {
                                        label: 'Chapters',
                                        subMenu: [
                                            { label: 'Find a Chapter ', url: "/view-Chapter" },
                                            { label: "Starting Chapter", url: "/chapter-starting" }
                                        ]
                                    },
                                    {
                                        label: 'SIGs',
                                        subMenu: [
                                            // { label: 'Sig List', url: '/addEditSigGroups' },
                                            { label: 'Literature SIG' },
                                            { label: 'Computer Technology SIG' },
                                            { label: 'Business English SIG' },
                                            // { label: 'SIGs with ELTAI ', url: '/eltai-sig' },
                                            { label: 'Starting a SIG with ELTAI ', url: '/starting-sig' },
                                        ]
                                    },
                                ]
                            },
                            // {
                            //     label: 'Chapters',
                            //     url: '/chapters',
                            //     subMenu: [
                            //         { label: 'Chapters List', url: '/view-Chapter' },
                            //         { label: "Starting a Chapter", url: '/starting-chapter' },

                            //     ]
                            // },
                            {
                                label: 'Publish',
                                url: '/chapters',
                                subMenu: [
                                    { label: "Journal of English Language Teaching (JELT)", url: 'https://journals.eltai.in/index.php/jelt/index' },
                                    { label: "Journal of Teaching & Research in English Literature (JTREL)", url: 'https://journals.eltai.in/index.php/jtrel' },
                                    { label: "Journal of Technology for ELT (JTELT)", url: 'https://journals.eltai.in/index.php/jtelt' },
                                    { label: "ELT Practitioner" },
                                    { label: "Our Newsletter" },

                                ]
                            },
                            // {
                            //     label: 'SIGs',
                            //     subMenu: [
                            //         { label: 'Sig List', url: '/addEditSigGroups' },
                            //         { label: 'Literature SIG' },
                            //         { label: 'Computer Technology SIG' },
                            //         { label: 'Business English SIG' },
                            //         { label: 'Starting a Chapter with ELTAI ',url:'/eltai-sig' },
                            //     ]
                            // },
                            {
                                label: 'India Reads',
                                subMenu: [
                                    { label: 'Why India Reads ', url: '/India-Reads' },
                                    { label: 'News', url: '' },
                                    // { label: 'News', url: '/news' },
                                    { label: 'LMS', url: 'https://indiareads.eltai.in/' },
                                ]
                            },
                            {
                                label: 'Partner',
                                subMenu: [
                                    { label: 'Partner with Us  ' },
                                    { label: 'Our Partners', url: '/partner' },
                                ]
                            },
                            {
                                label: 'Consult',
                                url: ''
                            },
                            {
                                label: 'Awards ',
                                url: ''
                            },
                            {
                                label: 'Participate ',
                                subMenu: [
                                    { label: 'Our Annual Conference', url: 'annual-conference' },
                                    { label: 'Our Webinars ' },
                                    { label: 'Our Upcoming Events', url: '/eventFeed' },
                                    { label: 'Calendar of Events' },
                                ]
                            },
                            {
                                label: 'Archives ',
                                subMenu: [
                                    { label: 'Reports   ' },
                                    { label: 'Galleries ', url: '/view-Gallery', },
                                ]
                            },
                            {
                                label: 'Contact Us', url: '/contact-us'
                            }
                        ]
                    ) : (
                        [
                            {
                                label: 'Home',
                                url: '/'
                            },
                            {
                                label: 'About Us',
                                url: '/aboutUs',
                                subMenu: [
                                    { label: 'Who We Are ', url: '/aboutUs' },
                                    { label: ' Our Vision', url: '/vision' },
                                    { label: 'Our Mission', url: '/mission' },
                                    { label: 'Our History', url: '/ourHistory ' },
                                    { label: 'Our Architects', url: '/ourArchitects' },
                                    // { label: 'Founder' },
                                    { label: 'Our Constitution', url: constitutionpdf },
                                    { label: 'Our Executive Committee', url: '/ourExecutiveCommittee' },
                                    // { label: 'Endowments' },
                                    // { label: "Present Office Bearers", url: '/officeBearers' },
                                    // { label: "Committees" },
                                ]
                            },
                            {
                                label: 'Join',
                                url: '/join-eltai',
                                subMenu: [
                                    { label: 'Membership Benefits', },
                                    { label: 'Become a member ', url: '/membershipPage' },
                                    { label: 'Our Institutional Members', url: '/membership-institution' }
                                ]
                            },
                            {
                                label: 'Network',
                                subMenu: [
                                    {
                                        label: 'Chapters',
                                        subMenu: [
                                            { label: 'Find a Chapter ', url: "/view-Chapter" },
                                            { label: "Starting Chapter", url: "/chapter-starting" }
                                        ]
                                    },
                                    {
                                        label: 'SIGs',
                                        subMenu: [
                                            // { label: 'Sig List', url: '/addEditSigGroups' },
                                            { label: 'Literature SIG' },
                                            { label: 'Computer Technology SIG' },
                                            { label: 'Business English SIG' },
                                            // { label: 'SIGs with ELTAI ', url: '/eltai-sig' },
                                            { label: 'Starting a SIG with ELTAI ', url: 'starting-sig' },
                                        ]
                                    },
                                ]
                            },
                            {
                                label: 'Publish',
                                url: '/chapters',
                                subMenu: [
                                    { label: "Journal of English Language Teaching (JELT)", url: 'https://journals.eltai.in/index.php/jelt/index' },
                                    { label: "Journal of Teaching & Research in English Literature (JTREL)", url: 'https://journals.eltai.in/index.php/jtrel' },
                                    { label: "Journal of Technology for ELT (JTELT)", url: 'https://journals.eltai.in/index.php/jtelt' },
                                    { label: "ELT Practitioner" },
                                    { label: "Our Newsletter" },

                                ]
                            },
                            // {
                            //     label: 'SIGs',
                            //     subMenu: [
                            //         { label: 'Sig List', url: '/addEditSigGroups' },
                            //         { label: 'Literature SIG' },
                            //         { label: 'Computer Technology SIG' },
                            //         { label: 'Business English SIG' },
                            //         { label: 'Starting a Chapter with ELTAI ',url:'/eltai-sig' },
                            //     ]
                            // },
                            {
                                label: 'India Reads',
                                subMenu: [
                                    { label: 'Why India Reads ', url: '/India-Reads' },
                                    { label: 'News', url: '' },
                                    // { label: 'News', url: '/news' },
                                    { label: 'LMS', url: 'https://indiareads.eltai.in/' },
                                ]
                            },
                            {
                                label: 'Partner',
                                subMenu: [
                                    { label: 'Partner with Us  ' },
                                    { label: 'Our Partners', url: '/partner' },
                                ]
                            },
                            {
                                label: 'Contact Us', url: '/contact-us'
                            },
                            {
                                label: 'More',
                                subMenu: [
                                    {
                                        label: 'Consult',
                                        url: ''
                                    },
                                    {
                                        label: 'Awards',
                                        url: ''
                                    },
                                    {
                                        label: 'Participate ',
                                        subMenu: [
                                            { label: 'Our Annual Conference', url: 'annual-conference' },
                                            { label: 'Our Webinars ' },
                                            { label: 'Our Upcoming Events', url: '/eventFeed' },
                                            { label: 'Calendar of Events' },
                                        ]
                                    },
                                    {
                                        label: 'Archives ',
                                        subMenu: [
                                            { label: 'Reports   ' },
                                            { label: 'Galleries ', url: '/view-Gallery', },
                                        ]
                                    }
                                ]
                            },
                            // {
                            //     label: 'Consult',
                            //     url: ''
                            // },
                            // {
                            //     label: 'Awards',
                            //     url: ''
                            // },
                            // {
                            //     label: 'Participate ',
                            //     subMenu: [
                            //         { label: 'Our Annual Conference',url:'annual-conference' },
                            //         { label: 'Our Webinars ' },
                            //         {label:'Our Upcoming Events'},
                            //         {label:'Calender'},
                            //     ]
                            // },
                            // {
                            //     label: 'Archives ',
                            //     subMenu: [
                            //         { label: 'Reports   ' },
                            //         { label: 'Galleries ' },
                            //     ]
                            // },
                            // {
                            //     label: 'Contact Us',url:'/contact-us' 
                            // }
                        ]

                    )

    //Update BackGrond Image
    useEffect(() => {
        const updateBackgroundImage = () => {
            const currentUrl = window.location.pathname;
            // Logic to set background image based on current URL
            let newBackgroundImage = '';
            let url;
            if (process.env.REACT_APP_PUBLIC_URL) {
                if (process.env.REACT_APP_PUBLIC_URL !== '' || process.env.REACT_APP_PUBLIC_URL !== undefined) {
                    if (memberScope === 'PORTAL_SUPER_ADMIN') {
                        url = currentUrl.split(process.env.REACT_APP_PUBLIC_URL + 'admin')[1];
                    }
                    else if (memberScope === 'PORTAL_CHAPTER_ADMIN') {
                        url = currentUrl.split(process.env.REACT_APP_PUBLIC_URL + 'chapterAdmin')[1];
                    }
                    else if (memberScope === 'PORTAL_SIG_ADMIN') {
                        url = currentUrl.split(process.env.REACT_APP_PUBLIC_URL + 'sigAdmin')[1];
                    }
                    else if (memberScope === 'PORTAL_MEMBER') {
                        url = currentUrl.split(process.env.REACT_APP_PUBLIC_URL + 'member')[1];
                    }
                    else {
                        url = '/';
                    }
                }
                else {
                    url = currentUrl
                }
            }
            else {
                if (memberScope === 'PORTAL_CHAPTER_ADMIN') {
                    url = currentUrl.split('chapterAdmin')[1];
                }
                else if (memberScope === 'PORTAL_SIG_ADMIN') {
                    url = currentUrl.split('sigAdmin')[1];
                }
                else if (memberScope === 'PORTAL_MEMBER') {
                    url = currentUrl.split('member')[1];
                }
                else {
                    url = currentUrl
                }
            }

            // Example: Set different background images for different URLs
            switch (url) {
                case '/':
                    newBackgroundImage = `url(${banner1})`;
                    break;
                case '/aboutUs':
                    newBackgroundImage = `url(${banner2})`;
                    break;
                case '/officeBearers':
                    newBackgroundImage = `url(${banner2})`;
                    break;
                case '/chapterForm':
                    newBackgroundImage = `url(${banner3})`;
                    break;
                case '/view-Event':
                    newBackgroundImage = `url(${banner5})`;
                    break;
                case '/chapterJoinMember':
                    newBackgroundImage = `url(${banner5})`;
                    break;
                case '/eventForm':
                    newBackgroundImage = `url(${banner3})`;
                    break;
                case '/memberList':
                    newBackgroundImage = `url(${banner3})`;
                    break;
                case '/eventType':
                    newBackgroundImage = `url(${banner3})`;
                    break;
                case '/sigGroup':
                    newBackgroundImage = `url(${banner3})`;
                    break;
                case '/category':
                    newBackgroundImage = `url(${banner3})`;
                    break;
                case '/viewCategory':
                    newBackgroundImage = `url(${banner3})`;
                    break;
                case '/gallery-table':
                    newBackgroundImage = `url(${banner3})`;
                    break;
                case '/add-edit-Gallery':
                    newBackgroundImage = `url(${banner3})`;
                    break;
                case '/announcement':
                    newBackgroundImage = `url(${banner3})`;
                    break;
                case '/allMemberList':
                    newBackgroundImage = `url(${banner3})`;
                    break;
                case '/eventList':
                    newBackgroundImage = `url(${banner1})`;
                    break;
                case '/eventFeed':
                    newBackgroundImage = `url(${banner3})`;
                    break;
                case '/memberForm':
                    newBackgroundImage = `url(${banner2})`;
                    break;
                case '/forumTable':
                    newBackgroundImage = `url(${banner3})`;
                    break;
                case '/forumForm':
                    newBackgroundImage = `url(${banner3})`;
                    break;
                case '/viewForum':
                    newBackgroundImage = `url(${banner3})`;
                    break;
                case '/sigGroupForm':
                    newBackgroundImage = `url(${banner3})`;
                    break;
                case '/viewSig':
                    newBackgroundImage = `url(${banner3})`;
                    break;
                case '/addEditSigGroups':
                    newBackgroundImage = `url(${banner3})`;
                    break;
                case '/viewJob':
                    newBackgroundImage = `url(${banner3})`;
                    break;
                case '/addJob':
                    newBackgroundImage = `url(${banner3})`;
                    break;
                case '/viewMember':
                    newBackgroundImage = `url(${banner2})`;
                    break;
                case '/viewNewsLetter':
                    newBackgroundImage = `url(${banner3})`;
                    break;
                case '/addNewsLetter':
                    newBackgroundImage = `url(${banner3})`;
                    break;
                case '/resume-table':
                    newBackgroundImage = `url(${banner2})`;
                    break;
                case '/addResume':
                    newBackgroundImage = `url(${banner2})`;
                    break;
                case '/chapterDetail':
                    newBackgroundImage = `url(${banner2})`;
                    break;
                case '/sigDetail':
                    newBackgroundImage = `url(${banner2})`;
                    break;
                case '/approval':
                    newBackgroundImage = `url(${banner3})`;
                    break;
                case '/viewGallery':
                    newBackgroundImage = `url(${banner3})`;
                    break;
                case '/sig-joined-Member':
                    newBackgroundImage = `url(${banner3})`;
                    break;
                case '/profile':
                    newBackgroundImage = `url(${banner3})`;
                    break;

                // Add more cases as needed
                default:
                    // Add a case for dynamic chapterForm/:chapterId route
                    if (currentUrl.includes('/chapterForm/')) {
                        newBackgroundImage = `url(${banner3})`;
                    }
                    if (currentUrl.includes('/viewJournal/')) {
                        newBackgroundImage = `url(${banner3})`;
                    }
                    if (currentUrl.includes('/memberList/')) {
                        newBackgroundImage = `url(${banner3})`;
                    }
                    if (currentUrl.includes('/category/')) {
                        newBackgroundImage = `url(${banner3})`;
                    }
                    if (currentUrl.includes('/evenTable/') || currentUrl.includes('/admin/') || currentUrl.includes('/eventForm/')) {
                        newBackgroundImage = `url(${banner3})`;
                    }
                    if (currentUrl.includes('/sig-member-table/')) {
                        newBackgroundImage = `url(${banner3})`;
                    }
                    if (currentUrl.includes('/gallery-table/')) {
                        newBackgroundImage = `url(${banner3})`;
                    }
                    if (currentUrl.includes('/addEditTestimonials/')) {
                        newBackgroundImage = `url(${banner3})`;
                    }
                    if (currentUrl.includes('/viewRegistered/')) {
                        newBackgroundImage = `url(${banner3})`;
                    }
                    if (currentUrl.includes('/journalTable')) {
                        newBackgroundImage = `url(${banner3})`;
                    }
                    if (currentUrl.includes('/addEditJournal')) {
                        newBackgroundImage = `url(${banner3})`;
                    }
                    if (currentUrl.includes('/addResume/')) {
                        newBackgroundImage = `url(${banner3})`;
                    }
                    if (currentUrl.includes('/addNewsLetter/')) {
                        newBackgroundImage = `url(${banner3})`;
                    }
                    if (currentUrl.includes('/readMoreForum/')) {
                        newBackgroundImage = `url(${banner3})`;
                    }
                    if (currentUrl.includes('/chapterAdmin/')) {
                        newBackgroundImage = `url(${banner3})`;
                    }
                    if (currentUrl.includes('/sigAdmin/')) {
                        newBackgroundImage = `url(${banner3})`;
                    }
                    if (currentUrl.includes('/admin/')) {
                        newBackgroundImage = `url(${banner3})`;
                    }
                    if (currentUrl.includes('/chapterAdmin/')) {
                        newBackgroundImage = `url(${banner3})`;
                    }
                    if (currentUrl.includes('/sigAdmin/')) {
                        newBackgroundImage = `url(${banner3})`;
                    }
                    if (currentUrl.includes('/VieweventFeed/')) {
                        newBackgroundImage = `url(${banner3})`;
                    }
                    if (currentUrl.includes('/add-edit-Gallery/')) {
                        newBackgroundImage = `url(${banner3})`;
                    }
                    if (currentUrl.includes('/view-Chapter/')) {
                        newBackgroundImage = `url(${banner3})`;
                    }
                    if (currentUrl.includes('/addEditMembershipPlan/')) {
                        newBackgroundImage = `url(${banner3})`;
                    }
                    if (currentUrl.includes('/viewGallery/')) {
                        newBackgroundImage = `url(${banner3})`;
                    }
                    if (currentUrl.includes('/sig-joined-Member/')) {
                        newBackgroundImage = `url(${banner3})`;
                    }
                    if (currentUrl.includes('/profile/')) {
                        newBackgroundImage = `url(${banner3})`;
                    }
                    if (currentUrl.includes('/chapterJoinMember/')) {
                        newBackgroundImage = `url(${banner5})`;
                    }
                    break;
            }

            setBackgroundImage(newBackgroundImage);
        };
        updateBackgroundImage();
    }, [memberScope]);

    // Render SubMenu Items
    const renderSubMenuItems = (subMenuItems, level = 1) => {
        return subMenuItems?.map((subMenuItem, index) => {
            if (subMenuItem.subMenu && subMenuItem.subMenu.length > 0) {
                return (
                    <NavDropdown
                        title={subMenuItem.label}
                        id={`submenu-${index}`}
                        key={subMenuItem.label || index}
                        style={{ textTransform: 'none !important', fontWeight: '600', fontFamily: 'poppins' }}
                        onMouseEnter={() => handleSubMouseEnter(index)}
                        onMouseLeave={handleSubMouseLeave}
                        show={subDropdownHover === index} // Show nested submenu when hovered
                        onClick={() => handleSubMobileClick(index)}

                        className={`custom-nav-dropdown-item text-capitalize dropdown-level-${level}`}
                    >
                        {renderSubMenuItems(subMenuItem.subMenu, level + 1)} {/* Recursive call for nested submenus */}
                    </NavDropdown>
                );
            } else {
                return (
                    <NavDropdown.Item
                        onClick={() => {
                            if (subMenuItem.url) {
                                if (subMenuItem.url.startsWith('http')) {
                                    // Open external links in a new tab
                                    window.open(subMenuItem.url, '_blank');
                                } else if (subMenuItem.url.endsWith('.pdf')) {
                                    // Open PDF in a new tab
                                    window.open(subMenuItem.url, '_blank');
                                } else {
                                    // Navigate within the app for internal URLs
                                    navigate(subMenuItem.url);
                                }
                            } else {
                                console.warn(`No URL defined for submenu item: ${subMenuItem.label}`);
                            }

                            // Close all dropdowns
                            setDropdownHover(null);
                            setSubDropdownHover(null);
                            setExpanded(false);
                        }}
                        key={subMenuItem.label || index}
                        style={{ textTransform: 'none', fontFamily: 'Poppins' }}
                        className={currentLocation.pathname === subMenuItem.url ? 'active' : ''}
                    >
                        {subMenuItem.label}
                    </NavDropdown.Item>
                );
            }
        });
    };

    return (
        <>
            <style>{`
            .banner_text {
                background-image: ${backgroundImage};
                background-size: cover;
                background-position: center;
                padding: 0px;
            }
            .navbar-dark .navbar-nav .nav-item .dropdown-menu .nav-link {
                color: black ;
            }
            .active-menu {
                color: #ff0400; 
            }
        `}</style>

            <div className='row top-nav pt-2   header-containter d-flex align-items-center justify-content-center' style={{ top: topPosition, position: 'sticky', zIndex: '12999999999999', backgroundColor: '#104E29', height: '50px' }}>
                <div className='col-sm-3 col-lg-2' >
                    <div className="social-menu">
                        <ul>
                            <li><a className='nav ps-0' href="https://www.facebook.com/ELTAI.India" target="blank"><i className="fa fa-facebook icon-config"></i></a></li>
                            <li><a className='nav ps-0' href="https://www.instagram.com/eltai.india" target="blank"><i className="fa fa-instagram icon-config-insta"></i></a></li>
                            <li><a className='nav ps-0' href="https://www.youtube.com/@ELTAIIndia" target="blank"><i className="fa fa-youtube icon-config-utube"></i></a></li>
                            <li className="twitter-list"><a className='nav ps-0' href="https://x.com/EltaiIndia" target="blank"><XIcon className="social-media-footer-icon-header" /></a></li>
                        </ul>
                    </div>
                </div>
                <div className="col-sm-8 col-lg-8 ps-0 ps-sm-5 ps-md-0">
                    <marquee className="text-light">{getAllAnnouncement?.data[0].contents}</marquee>
                </div>
                <div style={{ zIndex: '1300', display: 'flex', justifyContent: 'end' }} className='col-sm-1 col-lg-2 pe-4' >
                    {memberScope === 'PORTAL_SUPER_ADMIN' || memberScope === 'PORTAL_CHAPTER_ADMIN' || memberScope === 'PORTAL_SIG_ADMIN' || memberScope === 'PORTAL_MEMBER' ? (
                        <React.Fragment>
                            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center', position: 'absolute', top: topBarButtons }}>
                                <Tooltip title="Profile">
                                    <IconButton
                                        onClick={handleClick}
                                        size="small"
                                        sx={{ ml: 2 }}
                                        aria-controls={open ? 'account-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={open ? 'true' : undefined}
                                    >
                                        <Avatar sx={{ width: 32, height: 32, bgcolor: '#0072bb' }}><ManageAccountsIcon className='accountIcon' /></Avatar>
                                    </IconButton>
                                </Tooltip>
                            </Box>
                            <Menu
                                anchorEl={anchorEl}
                                id="account-menu"
                                open={open}
                                onClose={handleClose}
                                onClick={handleClose}
                                PaperProps={{
                                    elevation: 0,
                                    sx: {
                                        overflow: 'visible',
                                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                        mt: 1.5,
                                        '& .MuiAvatar-root': {
                                            width: 32,
                                            height: 32,
                                            ml: -0.5,
                                            mr: 1,
                                        },
                                        '&::before': {
                                            content: '""',
                                            display: 'block',
                                            position: 'absolute',
                                            top: 0,
                                            right: 14,
                                            width: 10,
                                            height: 10,
                                            bgcolor: 'primary.main',
                                            transform: 'translateY(-50%) rotate(45deg)',
                                            zIndex: 0,
                                        },
                                    },
                                }}
                                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                            >
                                {memberScope === 'PORTAL_MEMBER' ? (
                                    <MenuItem onClick={() => membershipRouteUrl(memberDetails?._id)}>
                                        {/* <ListItemIcon>
                                            <Avatar />
                                        </ListItemIcon>
                                        Profile */}
                                        {memberDetails?.type === "0"
                                            ? <div className='text-center'>
                                                <ListItemIcon>
                                                    <Avatar src={memberDetails?.imageUrl} />
                                                </ListItemIcon>
                                                Profile
                                            </div>
                                            : memberDetails?.type === "1"
                                                ? <div className='text-center'>
                                                    <ListItemIcon>
                                                        <Avatar src={memberDetails?.imageUrl} />
                                                    </ListItemIcon>
                                                    Profile
                                                </div>
                                                : memberDetails?.type === "2"
                                                    ? <>
                                                        <ListItemIcon>
                                                            <Avatar src={memberDetails?.uploadInstitutionLogo} />
                                                        </ListItemIcon>
                                                        Profile
                                                    </>
                                                    : <div>
                                                        <ListItemIcon>
                                                            <Avatar src={memberDetails?.uploadOrganisationLogo} />
                                                        </ListItemIcon>
                                                        Profile
                                                    </div>
                                        }
                                    </MenuItem>
                                ) : null}
                                {memberScope === 'PORTAL_CHAPTER_ADMIN' ?
                                    <MenuItem onClick={() => navigate(`chapterAdmin/chapterProfile/${chapterDetail?._id}`)}>
                                        <ListItemIcon>
                                            <Avatar />
                                        </ListItemIcon>
                                        Profile
                                    </MenuItem>
                                    : <></>}
                                {memberScope === 'PORTAL_SIG_ADMIN' ?
                                    <MenuItem onClick={() => navigate(`sigAdmin/sigadminProfile/${sigdetail?._id}`)}>
                                        <ListItemIcon>
                                            <Avatar />
                                        </ListItemIcon>
                                        Profile
                                    </MenuItem>
                                    : <></>}
                                <Divider />

                                {memberScope === 'PORTAL_MEMBER' ? (
                                    <MenuItem onClick={routeToPortal}>
                                        {memberDetails?.type === "0"
                                            ? <div className='text-center'>
                                                {memberDetails?.fullName}
                                            </div>
                                            : memberDetails?.type === "1"
                                                ? <div className='text-center'>
                                                    {memberDetails?.fullName}
                                                </div>
                                                : memberDetails?.type === "2"
                                                    ? <>
                                                        {memberDetails?.institution}
                                                    </>
                                                    : <div>
                                                        {memberDetails?.organisationName}
                                                    </div>
                                        }
                                    </MenuItem>

                                ) : memberScope === 'PORTAL_CHAPTER_ADMIN' ? (<MenuItem onClick={routeToPortal}>
                                    <Avatar />
                                    {roleDetails && roleDetails.name}  ({role})
                                </MenuItem>) : memberScope === 'PORTAL_SIG_ADMIN' ? (
                                    <MenuItem onClick={routeToPortal}>
                                        <Avatar />
                                        {sigRoleDetails && sigRoleDetails.name} ({role})
                                    </MenuItem>) : (<MenuItem onClick={routeToPortal} style={{ cursor: cursorStyle }}>
                                        <Avatar />
                                        <div style={{ cursor: cursorStyle, pointerEvents: role === "Admin" ? "none" : "auto" }}
                                            disabled={role === "Admin"}> ({role})</div>
                                    </MenuItem>)}
                                {memberScope === 'PORTAL_MEMBER' ?
                                    <>
                                        <MenuItem onClick={routeToPortal}>
                                            {memberDetails?.type === "0"
                                                ? <>
                                                    <p className='m-0'>(INDIVIDUAL)</p>
                                                </>
                                                : memberDetails?.type === "1"
                                                    ? <>
                                                        <p className='m-0'>(INDIVIDUAL)</p>
                                                    </>
                                                    : memberDetails?.type === "2"
                                                        ? <>
                                                            <br />
                                                            <p className='m-0'>(INSTITUTION)</p>
                                                        </>
                                                        : <>
                                                            <br />
                                                            <p className='m-0'>(INSTITUTION)</p>
                                                        </>
                                            }
                                        </MenuItem>
                                    </> : <></>
                                }
                                <Divider />

                                <MenuItem onClick={handleLogout}>
                                    <ListItemIcon>
                                        <Logout fontSize="small" />
                                    </ListItemIcon>
                                    Logout
                                </MenuItem>
                            </Menu>
                        </React.Fragment>
                    ) : (
                        <Box className="btn_container d-flex me-5 pb-1" sx={{ position: 'absolute', top: topBarButtons }} >
                            <Link to='/login' className='text-nowrap ps-0 me-2' ><i className="fa fa-sign-in text-light login-icon" aria-hidden="true"></i><Button className='top_nav_btn oswald text-capitalize'>Login</Button></Link>
                            <a href="https://forms.gle/kthcZi1BQXjWtE5DA" target='blank' ><Button className='top_nav_btn_join oswald text-capitalize'><i className="fa fa-angle-double-right text-light pe-1" aria-hidden="true"></i>Join</Button></a>
                        </Box>
                    )}
                </div>
            </div>

            <Navbar expand="lg" className={`bg_clr sticky`} variant="dark" expanded={expanded}>
                <Container fluid className="header-container mt-xs-4">
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={() => setExpanded(expanded ? false : true)} />
                    <Navbar.Collapse id="responsive-navbar-nav" className={isMobile ? 'mobile-style' : 'desktop-style'}>
                        <Nav className="d-flex justify-content-between mt-xs-4  mx-4 align-items-center">
                            <Nav.Link className='p-0 me-4'>
                                <img src={logo} style={{ height: '40px' }} alt='' />
                            </Nav.Link>

                            {menuItems.map((menuItem, index) => (
                                menuItem.subMenu ? (
                                    <NavDropdown
                                        title={menuItem.label}
                                        id={`menu-${index}`}
                                        key={index}
                                        onMouseEnter={() => handleMouseEnter(index)}
                                        onMouseLeave={handleMouseLeave}
                                        show={dropdownHover === index}
                                        // active={currentLocation.pathname === menuItem?.url || menuItem?.subMenu.some(item => item.url === currentLocation.pathname)}
                                        active={
                                            // currentLocation.pathname === menuItem?.url ||
                                            menuItem?.subMenu?.some(item => item.url === currentLocation.pathname ||
                                                item.subMenu?.some(subItem => subItem.url === (currentLocation.pathname))
                                                // item.subMenu?.some(subItem => new RegExp(subItem.url).test(currentLocation.pathname))
                                                // item.subMenu?.some(subItem => {
                                                //     // Create a regex that matches the exact URL
                                                //     const regexPattern = `^${subItem.url}$`; // Anchors for exact match
                                                //     return new RegExp(regexPattern).test(currentLocation.pathname);
                                                // })
                                            )
                                        }
                                        onClick={() => handleMobileClick(index)} // Handle click for mobile
                                        className={`custom-nav-dropdown eltai-home-nav-bar nav-item ${menuItem.label === "More" ? "dropdown-right" : ""}`}
                                    >
                                        {renderSubMenuItems(menuItem.subMenu)}
                                    </NavDropdown>
                                ) : (
                                    <Nav.Link
                                        onClick={() => {
                                            navigate(menuItem.url);
                                            setExpanded(false); // Collapse navbar after selecting
                                        }}
                                        key={index}
                                        active={currentLocation.pathname === menuItem?.url}
                                        style={{ fontWeight: '600', textTransform: 'none !important', fontFamily: 'poppins' }}
                                        className={`custom-nav-link nav-item ${menuItem.label === "More" ? "dropdown-right" : ""}`}
                                    >
                                        {menuItem.label}
                                    </Nav.Link>
                                )
                            ))}
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    );
}

export default Header;
