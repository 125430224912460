import React from 'react'
import { useLocation, Navigate, Outlet } from "react-router-dom"
import { useSelector } from "react-redux"
import { selectCurrentAccessToken, selectCurrentScope } from "./authSlice"

const ChapterAdminRequired = () => {
    const accessToken = useSelector(selectCurrentAccessToken)
    const scope = useSelector(selectCurrentScope)
    const location = useLocation()

    return (
        accessToken && scope === 'PORTAL_CHAPTER_ADMIN'
            ? <Outlet />
            : <Navigate to="/login" state={{ from: location }} replace />
    )
}

export default ChapterAdminRequired