import React from 'react';
import { Button, Container } from '@mui/material';
import { Box } from '@mui/material';
import '../../../src/style/css/membership.css';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectCurrentScope } from '../../app/auth/authSlice';

function MembershipSection() {
  const currentScope = useSelector(selectCurrentScope);

  // MemberTextContainted
  const membershipText = [
    {
      id: 1,
      news: 'A free copy of our bimonthly, Journal of English Language Teaching',
    },
    {
      id: 2,
      news: 'Reduced registration fees for attending all our programmes',
    },
    {
      id: 3,
      news: 'Access to exclusive members-only events and resources',
    },
  ];

  return (
    <div className='container-fulid membership'>
      <div className='member'>
        <div className='mt-5'>
          <h3 className='carosHeading mb-5 signika'> MEMBERSHIP</h3>
          <Container>
            <div
              id='carouselExampleControls'
              className='carousel slide'
              data-bs-ride='carousel'
            >
              <div className='carousel-inner caroHeight'>
                {membershipText.map((mem, i) => (
                  <div
                    key={mem.id} // Correct placement of the key prop
                    className={`carousel-item ${i === 0 ? 'active' : ''}`}
                  >
                    <div className='card membercard'>
                      <h5 className='caroseText mt-4 signika'>{mem.news}</h5>
                    </div>
                  </div>
                ))}
              </div>
              <button
                className='carousel-control-prev'
                type='button'
                data-bs-target='#carouselExampleControls'
                data-bs-slide='prev'
              >
                <span
                  className='carousel-control-prev-icon'
                  aria-hidden='true'
                ></span>
                <span className='visually-hidden'>
                  <p>Previous</p>
                </span>
              </button>
              <button
                className='carousel-control-next'
                type='button'
                data-bs-target='#carouselExampleControls'
                data-bs-slide='next'
              >
                <span
                  className='carousel-control-next-icon'
                  aria-hidden='true'
                ></span>
                <span className='visually-hidden'>Next</span>
              </button>
            </div>
          </Container>
          <Box
            m={1}
            display='flex'
            justifyContent='center'
            alignItems='center'
            paddingTop={5}
            paddingBottom={5}
          >
            {(currentScope === 'PORTAL_SIG_ADMIN' ||
              currentScope === 'PORTAL_CHAPTER_ADMIN' ||
              currentScope === undefined ||
              currentScope === null) && (
                <Button
                  variant='contained'
                  className='reg-btn'
                  sx={{
                    height: 50,
                    backgroundColor: '#a52a2a',
                    fontWeight: 600,
                    justifyContent: 'center',
                    display: 'flex',
                    '&:hover': {
                      backgroundColor: ' #b5bcb8', // Hover background color
                    },
                  }}
                >
                  <Link
                    className='text-decoration-none text-white signika text-capitalize' target='_blank'
                    to='https://docs.google.com/forms/d/e/1FAIpQLScHn0WyfBsNq-HG_FaHNqSJmfAh455l79A-3TwNYHCR6D5AGA/viewform'
                  >
                    Join
                  </Link>
                </Button>
              )}
          </Box>
        </div>
      </div>
    </div>
  );
}

export default MembershipSection;
