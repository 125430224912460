import React, { useState, useEffect, useCallback } from "react";
import Box from "@mui/material/Box";
import TopHeader from "../../Component/Header/TopHeader";
import "../../style/css/journalForm.css";
import {
  useGetAllMemberListMutation,
  useEnableDisableMemberListMutation,
} from "../../app/services/memberListServices";
import {
  useLazyGetChapterDetailsByIdQuery,
} from "../../app/services/chapterServices";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { IconButton } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import ReactPaginate from "react-paginate";
import '../../style/css/pagination.css'; // Import CSS file for pagination styles
import { toast } from "react-toastify";
import Spinner from "../../Component/spinner/spinner"
import Tooltip from "@mui/material/Tooltip";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useLazyGetSIGGroupByIdQuery } from "../../app/services/SIGGroupServices";

const ChapterName = ({ id }) => {
  const [chapterDetails, setChapterDetails] = useState(null);
  const [getChapterDetailsById] = useLazyGetChapterDetailsByIdQuery();

  useEffect(() => {
    getChapterDetailsById(id)
      .unwrap()
      .then((res) => {
        setChapterDetails(res.data.title);
      })
      .catch((error) => {
        console.error("Error fetching chapter details:", error);
      });
  }, [id, getChapterDetailsById]);
  return chapterDetails ? chapterDetails : '-----';
};

const SigName = ({ id }) => {
  const [sigDetails, setSigDetails] = useState(null);
  const [getSigDetailById] = useLazyGetSIGGroupByIdQuery()
  useEffect(() => {
    getSigDetailById(id)
      .unwrap()
      .then((res) => {
        setSigDetails(res.data.groupName);
      })
      .catch((error) => {
        console.error("Error fetching sig details:", error);
      });
  }, [id, getSigDetailById]);
  return sigDetails ? sigDetails : '-----';
};

export default function AllMemberList() {
  const [loading, setLoading] = useState(true);
  const [getAll, setGetAll] = useState([]);
  const [page, setPage] = React.useState(0);//state for set page
  const rowsPerPage = 5; //state for item per page
  let [totalCount, setTotalCount] = useState(0);
  const [getAllMemberList] = useGetAllMemberListMutation();
  const [type, setType] = React.useState('');
  const [selectedOpt, setSelectedOpt] = useState(0);


  //get change value
  const getChangeValue = (eve) => {
    setPage(0)
    setType(eve.target.value);
    getAllPagination(eve.target.value);
    setLoading(true)
  };



  //get all pagination for testimonial
  const getAllPagination = useCallback((types) => {
    var data = {
      page: page + 1,
      itemsPerPage: rowsPerPage,
      type: (types === undefined && (type === null || type === undefined || type === '')) ? '0' : types ? types : type
    }
    getAllMemberList(data).then((response) => {
      setLoading(false);
      setGetAll(response.data?.data?.memberDetails || []);
      setTotalCount(response.data?.data?.totalCount || 0);

    })
      .catch((error) => {
        setLoading(false);
        console.error("Error:", error);
      });
  }, [getAllMemberList, page, rowsPerPage, type])

  //useEffect
  useEffect(() => {
    getAllPagination();
  }, [getAllPagination]);

  //handlepage
  const handlePageClick = ({ selected }) => {
    setPage(selected);
  };

  //sliced MemberList
  const slicedMemberList = getAll.slice(0, rowsPerPage); // Adjust slicing

  //calculate page count
  const pageCount = Math.ceil(totalCount / rowsPerPage);

  const [isEnabledDisabled] = useEnableDisableMemberListMutation();

  //handlrtoggle
  const handleToggle = (user) => {
    const id = user._id;
    if (id) {
      setLoading(true);
      isEnabledDisabled(id).unwrap().then((response) => {
        setLoading(false);
        if (response?.status) {
          if (response?.data?.isDeleted === 0) {
            toast.success("Member Enabled")
            getAllPagination(type);
          }
          else {
            toast.error("Member Disabled")
            getAllPagination(type);
          }
        }
        else {
          setLoading(false);
          toast.error(response?.err?.message)
        }
      })
        .catch((error) => {
          toast.error(error)
        });
    }
  };

  function EnhancedTableHead() {
    let headCells
    if (type === '0' || type === '1' || type === undefined || type === null || type === '') {
      headCells = [
        {
          id: "Name",
          numeric: false,
          disablePadding: true,
          label: "Name",
        },
        {
          id: "email",
          numeric: false,
          disablePadding: true,
          label: "Email ID",
        },
        {
          id: "phoneNumber",
          numeric: false,
          disablePadding: true,
          label: "Phone Number",
        },
        {
          id: "chapter",
          numeric: false,
          disablePadding: true,
          label: "Chapter",
        },
        {
          id: "SIG",
          numeric: false,
          disablePadding: true,
          label: "SIG",
        },
        {
          id: "Address",
          numeric: false,
          disablePadding: true,
          label: "Address",
        },
        { id: "actions", numeric: true, disablePadding: false, label: "Actions" },
      ];
    }
    else if (type === '2') {
      headCells = [
        {
          id: "Institution",
          numeric: false,
          disablePadding: true,
          label: "Institution",
        },
        {
          id: "email",
          numeric: false,
          disablePadding: true,
          label: "Email ID",
        },
        {
          id: "phoneNumber",
          numeric: false,
          disablePadding: true,
          label: "Phone Number",
        },
        {
          id: "chapter",
          numeric: false,
          disablePadding: true,
          label: "Chapter",
        },
        {
          id: "SIG",
          numeric: false,
          disablePadding: true,
          label: "SIG",
        },
        {
          id: "Address",
          numeric: false,
          disablePadding: true,
          label: "Address",
        },
        { id: "actions", numeric: true, disablePadding: false, label: "Actions" },
      ];
    } else if (type === '3') {
      headCells = [
        {
          id: "Organisation",
          numeric: false,
          disablePadding: true,
          label: "Organisation",
        },
        {
          id: "email",
          numeric: false,
          disablePadding: true,
          label: "Email ID",
        },
        {
          id: "phoneNumber",
          numeric: false,
          disablePadding: true,
          label: "Phone Number",
        },
        {
          id: "chapter",
          numeric: false,
          disablePadding: true,
          label: "Chapter",
        },
        {
          id: "SIG",
          numeric: false,
          disablePadding: true,
          label: "SIG",
        },
        {
          id: "Address",
          numeric: false,
          disablePadding: true,
          label: "Address",
        },
        { id: "actions", numeric: true, disablePadding: false, label: "Actions" },
      ];
    }

    return (
      <>
        <TableHead>
          <TableRow className="menus-color">
            {headCells && headCells.map((headCell) => (
              <TableCell
                sx={{ textAlign: "center", color: "#ffff" }}
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "normal"}
              >
                {headCell.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
      </>
    );
  }

  return (
    <>
      <TopHeader />
      <div className='pt-1 '>
            <div className='p-0'>
              <div className=' Architects-header d-flex align-items-center justify-content-center '>
                <h2 className='signika-fontOEC text-center '>Member List</h2>
              </div>
            </div>
          </div>

      {loading ? (
        <Spinner />
      ) : (
        <Box sx={{ width: "80%" }} className="memberTable">
          <div className="mb-2" style={{
            display: "flex",
            justifyContent: "start",
            marginBottom: "20px",
          }}>
            <FormControl >
              <InputLabel shrink={true} id="type-label">Type</InputLabel>
              <Select
                labelId="type-label"
                id="type"
                label="type"
                required
                value={selectedOpt}
                onChange={getChangeValue}
              >
                <MenuItem onClick={() => setSelectedOpt('0')} value={'0'}>Teacher</MenuItem>
                <MenuItem onClick={() => setSelectedOpt('1')} value={'1'}>Student</MenuItem>
                <MenuItem onClick={() => setSelectedOpt('2')} value={'2'}>Educational Institution</MenuItem>
                <MenuItem onClick={() => setSelectedOpt('3')} value={'3'}>Corporate</MenuItem>
              </Select>
            </FormControl>
          </div>
          <Paper sx={{ width: "100%", mb: 2 }}>

            <TableContainer>
              <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                <EnhancedTableHead />
                <TableBody>
                  {slicedMemberList.length > 0 ? (
                    slicedMemberList.map((row) => (
                      <TableRow key={row.id}>
                        <TableCell align="center">{row.fullName ? row.fullName : row.organisationName ? row.organisationName : row.institution ? row.institution : '---'}</TableCell>
                        <TableCell align="center">{row.email}</TableCell>
                        <TableCell align="center">{row.mobileNumber}</TableCell>
                        <TableCell align="center">
                          {row.chapter?.length > 0 ? <ChapterName id={row.chapter} /> : <p className="text-dark">---</p>}
                        </TableCell>
                        <TableCell align="center">
                          {row.sigGroups?.length > 0 ? row.sigGroups.map(sig => (
                            <>
                              <SigName id={sig} />, <br></br>
                            </>
                          )) : <p className="text-dark">---</p>}
                        </TableCell>
                        <TableCell align="center">{row.address},&nbsp;{row.city},&nbsp;{row.pinCode}.</TableCell>
                        <TableCell align="right">

                          <IconButton onClick={() => handleToggle(row)}>
                            {row.isDeleted === 0 ? (
                              <Tooltip title="Enable">
                                <LockOpenIcon style={{ color: 'green' }} />
                              </Tooltip>

                            ) : (
                              <Tooltip title="Disable">
                                <LockIcon style={{ color: 'red' }} />
                              </Tooltip>

                            )}
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={6} align="center">
                        No Data Available
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          <div style={{ marginBottom: '100px' }}>
            {slicedMemberList.length > 0 ? (
              <ReactPaginate
                pageCount={pageCount} // Replace with the total number of pages
                pageRangeDisplayed={5}
                marginPagesDisplayed={2}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                activeClassName={"active"}
                disabledClassName={"disablebtn"}
                forcePage={page}
                previousLabel={pageCount > 1 && page >= 1 ? "Previous" : null}
                nextLabel={pageCount > 1 && page + 1 !== pageCount ? "Next" : null}
              />
            ) : (<></>)}
          </div>
        </Box>
      )}
    </>
  );
}
