import React from "react";
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

const PersonalInfo = () => {
  const { control } = useFormContext();

  return (
    <>
      <Stack spacing={5} direction={"row"} width={"100%"}>
        <Controller
          control={control}
          name="firstName"
          render={({ field, formState }) => (
            <TextField
              label={
                <Typography className="text-dark">
                  First Name <span style={{ color: "red" }}>*</span>
                </Typography>
              }
              id="firstName"
              fullWidth
              value={field.value}
              error={!!formState?.errors?.firstName}
              helperText={formState?.errors?.firstName?.message}
              placeholder="Enter Your First Name"
              {...field}
            />
          )}
          rules={{ required: "Please Enter Your First name" }}
        />
        <Controller
          control={control}
          name="lastName"
          render={({ field, formState }) => (
            <TextField
              label={
                <Typography className="text-dark">
                  Last Name <span style={{ color: "red" }}>*</span>
                </Typography>
              }
              id="lastName"
              fullWidth
              value={field.value}
              error={!!formState?.errors?.lastName}
              helperText={formState?.errors?.lastName?.message}
              placeholder="Enter Your Last Name"
              {...field}
            />
          )}
          rules={{ required: "Please Enter Your Last name" }}
        />
      </Stack>
      <Controller
        control={control}
        name="email"
        render={({ field, formState }) => (
          <TextField
            fullWidth
            label={
                <Typography className="text-dark">
                  Email <span style={{ color: "red" }}>*</span>
                </Typography>
              }
            id="email"
            value={field.value}
            error={!!formState?.errors?.email}
            helperText={formState?.errors?.email?.message}
            placeholder="Enter your Email"
            {...field}
          />
        )}
        rules={{
          required: "Please Enter Your Email",
          pattern: {
            value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
            message: "Please Enter a Valid E-Mail",
          },
        }}
      />
      <Controller
        control={control}
        name="phone"
        render={({ field, fieldState }) => (
          <TextField
            type="tel"
            fullWidth
            label={
                <Typography className="text-dark">
                  Phone Number <span style={{ color: "red" }}>*</span>
                </Typography>
              }
            id="phoneNumber"
            value={field.value}
            error={!!fieldState?.error}
            helperText={fieldState?.error?.message}
            placeholder="Enter Your Phone Number"
            {...field}
            onChange={(e) => {
              const newValue = e.target.value.replace(/[^0-9]/g, ''); 
              field.onChange(newValue);
            }}
          />
        )}
        rules={{
          required: "Please Enter Your Phone Number",
          validate: (value) => {
            if (value.length !== 10) {
              return "Please enter a valid 10-digit phone number";
            }
            return true; // Validation passed
          },
        }}
      />
      <Controller
        control={control}
        name="gender"
        render={({ field, formState }) => (
          <FormControl {...field} error={!!formState.errors.gender}>
            <FormLabel id="demo-radio-buttons-group-label">Gender <span style={{ color: "red" }}>*</span></FormLabel>
            <RadioGroup
              value={field.value ? field.value : ""}
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
            >
              <FormControlLabel
                className="text-black"
                value="0"
                control={<Radio />}
                label="Male"
              />
              <FormControlLabel
                className="text-black"
                value="1"
                control={<Radio />}
                label="Female"
              />
              <FormControlLabel
                className="text-black"
                value="2"
                control={<Radio />}
                label="Other"
              />
            </RadioGroup>
            <FormHelperText>
              {formState?.errors?.gender?.message}
            </FormHelperText>
          </FormControl>
        )}
        rules={{ required: "Please Select Your Gender" }}
      />
      <Controller
        control={control}
        name="address"
        render={({ field, fieldState }) => (
          <>
            <TextField
              {...field}
              value={field.value}
              id="address"
              error={!!fieldState?.invalid}
              helperText={fieldState?.error?.message}
              label={
                <Typography className="text-dark">
                  Address <span style={{ color: "red" }}>*</span>
                </Typography>
              }
              placeholder="Enter Your address"
            ></TextField>
          </>
        )}
        rules={{ required: "Please Enter your address" }}
      />
      <Stack direction={"row"} spacing={2}>
        <Controller
          control={control}
          name="city"
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              value={field.value}
              error={!!fieldState?.invalid}
              helperText={fieldState?.error?.message}
              label={
                <Typography className="text-dark">
                  City <span style={{ color: "red" }}>*</span>
                </Typography>
              }      
              placeholder="Enter Your city name"
            ></TextField>
          )}
          rules={{ required: "Please Enter Your City Name" }}
        />
        <Controller
          control={control}
          name="state"
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              value={field.value}
              error={!!fieldState?.invalid}
              helperText={fieldState?.error?.message}
              label={
                <Typography className="text-dark">
                 State <span style={{ color: "red" }}>*</span>
                </Typography>
              }
              placeholder="Enter Your state name"
            ></TextField>
          )}
          rules={{ required: "Please Enter Your state Name" }}
        />
        <Controller
          control={control}
          name="zipcode"
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              value={field.value}
              error={!!fieldState?.invalid}
              helperText={fieldState?.error?.message}
              label={
                <Typography className="text-dark">
                  Zipcode <span style={{ color: "red" }}>*</span>
                </Typography>
              }
              onChange={(e) => {
                const newValue = e.target.value.replace(/[^0-9]/g, ''); 
                field.onChange(newValue);
              }}
              placeholder="Enter Your zipcode"
            ></TextField>
          )}
          rules={{ required: "Please Enter Your zipcode", validate: (value) => {
            if (value.length !== 6) {
              return "Please enter a 6-digit zip code";
            }
            return true; // Validation passed
          },
         }}          
        />
      </Stack>
    </>
  );
};

export default PersonalInfo;
