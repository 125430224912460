import { api } from "./api";
 
const forumCommentsServices = api.injectEndpoints({
  tagTypes:['forumCommentsServices'],
  endpoints: (builder) => ({

    //saveCommentsForum
    saveCommentsForum: builder.mutation({
      query: (newForumCommentsData) => ({
        url: "forumComments/saveComments",
        method: "POST",
        body: newForumCommentsData,
      }),
      invalidatesTags: ["forumCommentsServices"],
    }),

    //getAllCommentsForumById
    getAllCommentsForumById: builder.query({
      query: (id) => `forumComments/getForumCommentsReplyByForumId/${id}`,
    }),  

    //updatecomentsForum
    updateCommentsForum: builder.mutation({
      query: (updateForumCommentsData) => ({
        url: `forumComments/updateCommentAndReply`,
        method: "PUT",
        body: updateForumCommentsData,
      }),
      invalidatesTags: ["forumCommentsServices"],
    }),
  }),
  overrideExisting: false,
});
 
export const {
  useSaveCommentsForumMutation,
  useGetAllCommentsForumByIdQuery,
  useUpdateCommentsForumMutation,
} = forumCommentsServices;