import { api } from "./api";
 
const memberListServices = api.injectEndpoints({
  endpoints: (builder) => ({
    //getAllMember
    getAllMember:builder.query({
      query: () => "member/getall",
            providesTags:['Member']
    }),

    //GetAllMemberList
    getAllMemberList: builder.mutation({
      query: (newMemberListData) => ({
        url: "member/getAllMemberPagination",
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: newMemberListData,
      }),
    }),

    //enableDisable Member
    enableDisableMemberList:builder.mutation({
      query:(id)=>({
        url:`member/enableDisable/`+id,
        method:"PUT",
        headers: { "Content-Type": "application/json" },
      }),
      invalidatesTags:["Member"],
    }),

    //UpdateMember
    updateMember: builder.mutation({
      query: ({ id, data }) => ({
        url: `member/updatemember/${id}`,
        method: "PUT",
        // headers: { "Content-Type": "application/json" },
        body: data,
      }),
    }),

    //getPendingMemberById
    getPendingMemberById: builder.query({
      query: (id) => `member/get/${id}`,
    }),
  }),
  overrideExisting: false,
});
 
export const {
  useLazyGetAllMemberQuery,
  useGetAllMemberListMutation,
  useEnableDisableMemberListMutation,
  useUpdateMemberMutation,
  useLazyGetPendingMemberByIdQuery
} = memberListServices;